import React, { useEffect, useState } from "react";
import Loader from "../loader/loader";
import { ImgTopBtn, SmallPlainInsetBtnEventListener } from "../buttons";
import BlinkingLight from "../blinkingLight/blinkingLight";
import { CONTEXT_TYPES } from "../../constants/contextTypes";
import { CellPhoneIcon } from "../icons";
import "./verify-modal.scss";

const PhoneVerification = ({
  onRequestResend,
  userId,
  onCancel,
  onMobileVerification,
  onSkipVerification,
  contextType,
}) => {
  const [codeValue, setCodeValue] = useState("");
  const [phoneVerifyFocus, setPhoneVerifyFocus] = useState("");
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [time, setTime] = useState(30);
  const isButtonDisabled = time !== 0;

  // Handle countdown timer or other effects
  // useEffect(() => {
  //   // Countdown logic or other effects to run on mount or when userId changes
  //   console.log("PhoneVerification - UserID on mount:", userId);
  // }, [userId]);
  // localStorage.removeItem(
  //   getStorageKey("phoneNumberValue", currentContext)
  // );

  // function: handle request new OTP code
  const handleRequestNewCode = async (e) => {
    e.preventDefault();
    // show request new code loader
    setLoading(true);
    if (time === 0) {
      try {
        await onRequestResend();
        // hide request new code loader
        setLoading(false);
      } catch (err) {
        console.log(err);
        // hide request new code loader if any error
        setLoading(false);
      }
      setTime(30);
    }
    // show request new code loader
    setLoading(true);
  };

  // side effect: handle the the length of code is active
  useEffect(() => {
    if (codeValue.length === 6) {
      return setActive(true);
    }
    return setActive(false);
  }, [codeValue]);

  // side effect: handle the count down
  useEffect(() => {
    let timerId;
    if (time > 0) {
      timerId = setTimeout(() => setTime(time - 1), 1000);
    }
    return () => clearTimeout(timerId);
  }, [time]);

  return (
    <div className="verify-modal-content slideDown">
      <div className="verify-modal-body">
        <form action="" className="verify-modal-form">
          {/* <!-- start --> */}
          <div className="verification-container">
            <div className="header-grid blinker-wrapper">
              <div className="verify-modal-header">
                {/* <!-- <div class="img-div"></div> --> */}
                <CellPhoneIcon />
                <h1>Verify Mobile</h1>
              </div>
              <p
                className={`verify-modal-timer ${time === 0 ? "request-code-allowed " : ""}`}
                id="timer"
              >
                {time} Seconds
              </p>
              <p className="verify-modal-timer-text">
                Must wait 30 seconds to skip/request new code
              </p>
              <p className="responsiveBtnText">Enter Code Sent to Mobile* </p>
              <input
                id="verify-input"
                type="text"
                minLength="6"
                maxLength="6"
                placeholder="# # # # # #"
                value={codeValue}
                onChange={(e) => setCodeValue(e.target.value)}
                onFocus={() => setPhoneVerifyFocus(true)}
                onBlur={() => setPhoneVerifyFocus(false)}
                className={`${phoneVerifyFocus ? "focused" : ""} ${
                  codeValue ? "has-value" : ""
                }`}
              />
              {!(phoneVerifyFocus || codeValue) && (
                <span className="blinking-cursor-gold-color verificationCursor">
                  |
                </span>
              )}
            </div>
          </div>
          {loading && <Loader className="mobileVerifySignup-loader-position" />}
          {/* we need this class signup-btn-wrapper to position the svg inside the button */}
          <div className="signup-btn-wrapper confirm-btn-wrapper position-relative">
            {/* Sign Up button component */}
            <ImgTopBtn
              image={
                <BlinkingLight
                  blinkerLightStyles={{
                    marginRight: "10px", // Or 'left: "10px"' for left positioning
                  }}
                />
              }
              imgTopBtnWidth="100%"
              imgTopBtnTextFontSize="1.3rem"
              className="responsiveBtnText"
              imgTopBtnFontColor="#b03a2e"
              imgTopBtnText="Cofirm"
              textClassName="responsiveBtnText"
              onClick={(e) => {
                e.preventDefault();
                console.log(
                  "PhoneVerification - Confirm button clicked, UserID:",
                  userId
                );
                onMobileVerification(userId); // Pass userId to the callback function
                // Calling the callback with userId
              }}
            />
          </div>
          <div
            style={{
              width: "100%",
              fontSize: "13px",
              color: "red",
              textAlign: "center",
            }}
          >
            {/* {error} */}
          </div>
          <div className="link-flex">
            <SmallPlainInsetBtnEventListener
              text=" X Cancel"
              onClick={onCancel}
            />
            <SmallPlainInsetBtnEventListener
              text="Request New Code"
              onClick={handleRequestNewCode}
              disabled={isButtonDisabled} // Pass the disabled state based on your logic
            />
            {contextType !== CONTEXT_TYPES.RESET_PASSWORD && (
              <SmallPlainInsetBtnEventListener
                text="Skip >"
                type="button"
                onClick={(e) => onSkipVerification(e, userId)}
                // Pass both event and userId
                disabled={isButtonDisabled} // Pass the disabled state based on your logic
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
export default PhoneVerification;
