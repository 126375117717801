import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Loader from "../loader/loader";
import {
  IIcon,
  StarIcon,
  UploadCloudIcon,
  RemoteIcon,
  PowerIcon,
  RedCircleIcon,
  HomeIcon,
  ColorPalleteIcon,
} from "../icons";
import BlinkingXIcon from "../blinkingLight/blinkingXIcon";
import {
  SquareFlatBtns,
  ThemedButtonColorPalette,
  RoundFlatBtns,
} from "../buttons";
import { themeContainerStyleColors } from "../../constants/globalColorParameters";
import { useAuth } from "../context/authContext";
import { channelToCategoryMap, channels } from "../../constants/categories";
import "../../components/remote/remote.scss";

const Remote = ({
  onUploadClick,
  remoteClassName,
  colorPaletteVisible,
  toggleColorPaletteVisibility,
  showChannels = true,
  onChannelChange, // call back that updates the active category in the top skits list page
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loader state
  const { currentUser, remoteColor, remoteColorUserSetting, handleLogout } =
    useAuth();
  const [theme, setTheme] = useState(remoteColor);


  useEffect(() => {
    setTheme(remoteColor); // Update theme based on remoteColor from context
  }, [remoteColor]);

  const [activeChannel, setActiveChannel] = useState(0);
  const [titleColor, setTitleColor] = useState("var(--color-gold)");
  const [subTitleColor, setSubTitleColor] = useState("#fff");
  const [iconColor, setIconColor] = useState("#000");
  const [pressedBtnIndicator, setPressedBtnIndicator] = useState("");
  const [visible, setRemoteBottomVisible] = useState(() => {
    const saved = localStorage.getItem("visible");
    return saved ? JSON.parse(saved) : true;
  });

  // When a channel is selected, the handleChannelChange function in Remote is called,
  // which in turn calls the onChannelChange callback passed from TopSkitsListsPage.
  // This callback updates the active category and resets the offsets.
  const handleChannelChange = (id) => {
    const currentChannel = channels.find((channel) => channel.id === id);
    if (currentChannel) {
      setActiveChannel(currentChannel);
      const categoryId = channelToCategoryMap[id];
      if (categoryId) {
        onChannelChange(categoryId); // Inform parent of the selected category
      }
      triggerLightBeam(currentChannel.name);
    }
  };

  // Effect to update local storage whenever 'visible' changes
  useEffect(() => {
    localStorage.setItem("visible", visible.toString());
  }, [visible]);

  // Function to toggle visibility
  const toggleVisibility = () => {
    setRemoteBottomVisible((prevVisible) => !prevVisible); // This toggles the state between true and false
  };
  // Logic to determine active link based on current path
  const currentPage = {
    home: location.pathname === "/skitslist",
    popular: location.pathname === "/top-skits",
    info: location.pathname === "/information",
    profile: location.pathname.includes(`/user-profile/${currentUser}`),
  };

  // Style variables
  const activeLinkColor = "var(--color--ivory)";
  const hoverFillLinkColor = "#fdfd96";
  const hoverFillSvgColor = "#815121";
  const activeIconColor = "#000";

  // Update this function to adjust title, subtitle, and icon colors independently
  const updateThemeAndColors = (newTheme) => {
    setTheme(newTheme); // Update the theme as before
    if (currentUser) {
      remoteColorUserSetting(currentUser, newTheme); // Update the remote color in the backend
    }
    // Define your logic here for setting colors based on the new theme
    switch (newTheme) {
      case "glass":
        setTitleColor("#c79f27");
        setIconColor("#000000");
        setSubTitleColor("#ffffff");
        break;
      case "blue":
        setIconColor("#eee");
        setSubTitleColor("#eee");
        setTitleColor("#c79f27");
        break;
      case "gum":
        setTitleColor("#1c1c1c");
        setIconColor("#1c1c1c");
        setSubTitleColor("#1c1c1c");
        break;
      case "white":
        setTitleColor("#000");
        setIconColor("#000");
        setSubTitleColor("#000");
        break;
      case "ivory":
        setTitleColor("#1c1c1c");
        setIconColor("#1c1c1c");
        setSubTitleColor("#1c1c1c");
        break;
      case "offWhite": // cream
        setTitleColor("#1c1c1c");
        setIconColor("#1c1c1c");
        setSubTitleColor("#1c1c1c");
        break;
      case "pink":
        setTitleColor("#eee");
        setIconColor("#eee");
        setSubTitleColor("#eee");
        break;
      case "brown":
        setTitleColor("#eee");
        setIconColor("#eee");
        setSubTitleColor("#ffff00");
        break;
      case "maroon":
        setTitleColor("#C79F27");
        setIconColor("#eee");
        setSubTitleColor("#eee");
        break;
      case "redish":
        setTitleColor("#c79f27");
        setIconColor("#ffffff");
        setSubTitleColor("#ffffff");
        break;
      case "bloodred":
        setTitleColor("#c79f27");
        setIconColor("#ffffff");
        setSubTitleColor("#ffffff");
        break;
      case "purple":
        setTitleColor("#fff");
        setIconColor("#fff");
        setSubTitleColor("#fff");
        break;
      case "orange":
        setTitleColor("#1c1c1c");
        setIconColor("#1c1c1c");
        setSubTitleColor("#1c1c1c");
        break;
      case "yellow":
        setTitleColor("rgb(91 54 10)");
        setIconColor("#1c1c1c");
        setSubTitleColor("#1c1c1c");
        break;
      case "yellowgold": // golden
        setTitleColor("rgb(91 54 10)");
        setIconColor("#1c1c1c");
        setSubTitleColor("#1c1c1c");
        break;
      case "military":
        setTitleColor("#C79F27");
        setIconColor("#eee");
        setSubTitleColor("#eee");
        break;
      case "corveteRed":
        setTitleColor("#000000");
        setIconColor("#eee");
        setSubTitleColor("#eee");
        break;
      case "transparent": // dark
        setTitleColor("#c79f27");
        setIconColor("#ffffff");
        setSubTitleColor("#ffffff");
        break;
      case "beige":
        setTitleColor("#167ac6");
        setIconColor("#167ac6");
        setSubTitleColor("#167ac6");
        break;
      case "teal":
        setTitleColor("#fff");
        setIconColor("#fff");
        setSubTitleColor("#fff");
        break;
      case "burgundy":
        setTitleColor("#fff");
        setIconColor("#fff");
        setSubTitleColor("#fff");
        break;
      case "steal":
        setTitleColor("#C79F27");
        setIconColor("#000");
        setSubTitleColor("#000");
        break;
      case "jean":
        setTitleColor("#233f5f");
        setIconColor("#000");
        setSubTitleColor("#000");
        break;
      default:
        setTitleColor("#000000");
        setIconColor("#ffffff");
        setSubTitleColor("#000000");
        break;
    }
  };
  // Determine if the current theme is a gradient (and thus needs a class to apply it)
  const isGradientTheme = ["steal", "jean", "greyish"].includes(theme);
  // The component's className based on the theme
  // If it's a gradient theme, use the class; otherwise, apply no additional class
  const themeClassName = isGradientTheme
    ? themeContainerStyleColors[theme]
    : "";
  const toggleLightClass = () => {
    var redbeam = document.getElementById("redbeam2");
    if (redbeam) {
      // Check if the element exists
      redbeam.classList.toggle("redbeam-active");
    } else {
      console.error("Element with ID 'redbeam2' was not found.");
    }
  };
  const toggleButtonActive = (name) => {
    var button = document.querySelector(`div[name="${name}"`);
    button?.classList.toggle("btn-active");
    setTimeout(() => {
      button?.classList.toggle("btn-active");
    }, 500);
  };
  const triggerLightBeam = (name) => {
    toggleLightClass();
    toggleButtonActive(name);
    setTimeout(toggleLightClass, 300);
  };

  const togglePressedBtnIndicator = (name) => {
    setPressedBtnIndicator(name);
  };

  useEffect(() => {
    // Update visibility based on the current page
    if (currentPage.popular || currentPage.profile) {
      setRemoteBottomVisible(false);
    } else {
      setRemoteBottomVisible(true);
    }
  }, [location, currentPage.popular, currentPage.profile]);

  // hard coded but for code extensibility will update it with parameters
  // if i want to hide the bottom on the addtional pages on mount this is a way to do it dynamically 
  // useEffect(() => {
  //   // Check if the current location includes the skitsList page
  //   if (location.pathname.includes("/top-skits")) {
  //     setRemoteBottomVisible(false);
  //   }
  //   // Optionally, reset visibility based on other routes
  // }, [location]);

  const scrollWithOffset = (el, additionalOffset = 0) => {
    setLoading(true);
    // Stop loader after a fallback timeout
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust timeout as needed
    // a hash id is needed with this on the component as this will calculate how far from the header it will scroll down from to the id, the key is the offset property with the scroll
    // so that it scrolls at the right position and moves from the header
    const offset = 100; // Adjust this value to match your fixed header height
    const elementPosition = el.offsetTop;
    const offsetPosition = elementPosition - offset - additionalOffset;
    console.log("Scrolling to:", offsetPosition);
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };


  const onLogoutClick = () => {
    handleLogout();
    navigate("/");
  };

  return (
    <div
      className={`remote-container ${themeClassName} ${remoteClassName}`}
      style={{
        backgroundColor:
          themeContainerStyleColors[theme] ||
          themeContainerStyleColors["glass"],
      }} // Uses the theme state to determine the background color
    >
      {loading && <Loader />}
      <div id="redbeam-container">
        <div id="redbeam2"></div>
      </div>
      <div className="remote-power">
        <div className="current-channel">
          <div className="current-div">
            <h5
              id="skits-remote-current-channel-title"
              style={{ color: titleColor, marginTop: "5px" }}
            >
              {activeChannel ? activeChannel.name : "No Channel Selected"}
            </h5>
            {/* If your Icon component can accept a color prop for dynamic coloring */}
            {activeChannel.Icon &&
              React.createElement(activeChannel.Icon, {
                fillColor: iconColor,
              })}
          </div>
          <h4 id="current-channel-subtitle" style={{ color: subTitleColor }}>
            Current Channel
          </h4>
        </div>
        <div
          className="top-right-container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Wrap the button in a div for consistent layout */}
          <div>
            <SquareFlatBtns
              name="show"
              className="space-left remote-btn2"
              buttonWidth="50px"
              iconWidth="30px"
              iconHeight="30px"
              onClick={() => {
                toggleVisibility();
                triggerLightBeam("showremotetxt2");
              }}
              Icon={RemoteIcon}
              title={visible ? "Hide" : "Show"} // The button text changes based on the visibility
              titleStyle={{
                fontSize: ".6rem",
                fontWeight: "800",
                color: "var(--color-gold)",
              }}
            />
          </div>
          {/* Wrap the second button as well for consistent layout */}
          <div style={{ flex: 1 }}>
            <SquareFlatBtns
              name="power-btn"
              className="power-btn"
              buttonWidth="50px"
              iconWidth="30px"
              iconHeight="30px"
              SqaureBtnbackgroundColor="var(--color-bloodred)"
              onClick={() => {
                onLogoutClick();
              }}
              Icon={PowerIcon}
              title="Logout"
              titleStyle={{
                fontSize: ".6rem",
                fontWeight: "800",
              }}
            />
          </div>
        </div>
      </div>
      <div
        key={visible.toString()}
        className={`show-hide ${visible ? "show" : "hide"}`}
      >
        {showChannels && (
          <div
            key={visible.toString()}
            className={`show-hide ${visible ? "show" : "hide"}`}
          >
            <div className="top-btns">
              {channels.slice(0, 4).map((channel) => (
                <SquareFlatBtns
                  key={channel.id}
                  name={channel.name}
                  onClick={() => handleChannelChange(channel.id)}
                  Icon={channel.Icon}
                  title={channel.name}
                  className="remote-btn2"
                  buttonWidth="50px"
                  iconWidth="30px"
                  iconHeight="30px"
                  titleStyle={{
                    fontSize: ".6rem",
                    fontWeight: "800",
                    color:
                      activeChannel?.name === channel.name
                        ? "var(--color-ivory)"
                        : "var(--color-gold)",
                  }}
                />
              ))}
            </div>
            <div className="btm-btns">
              {channels.slice(4).map((channel) => (
                <SquareFlatBtns
                  key={channel.id}
                  name={channel.name}
                  onClick={() => handleChannelChange(channel.id)}
                  Icon={channel.Icon}
                  title={channel.name}
                  className="remote-btn2"
                  buttonWidth="50px"
                  iconWidth="30px"
                  iconHeight="30px"
                  titleStyle={{
                    fontSize: ".6rem",
                    fontWeight: "800",
                    color:
                      activeChannel?.name === channel.name
                        ? "var(--color-ivory)"
                        : "var(--color-gold)",
                  }}
                />
              ))}
            </div>
          </div>
        )}
        <div className="roundBtns-top">
          <HashLink
            to={`/user-profile/${currentUser}#userProfilePage`}
            scroll={(el) => scrollWithOffset(el, 50)}
            style={{
              textDecoration: "none",
              color: currentPage.profile
                ? activeLinkColor
                : "var(--color-gold)",
            }}
          >
            <RoundFlatBtns
              name="profile-btn"
              Icon={RedCircleIcon}
              onClick={() => {
                triggerLightBeam("profile-btn");
              }}
              iconProps={{ height: "20", width: "20" }}
              title="Profile"
              titleStyle={{
                fontSize: ".6rem",
                margin: "0",
                textAlign: "center",
                fontWeight: "800",
              }}
              extraStyles={{
                width: "40px",
                height: "40px",
                padding: "1px",
              }}
            />
          </HashLink>
          <RoundFlatBtns
            name="upload-btn"
            onClick={() => {
              triggerLightBeam("upload-btn");
              togglePressedBtnIndicator("upload-btn");
              onUploadClick(); // Call the function directly here
            }}
            Icon={UploadCloudIcon}
            iconProps={{ height: "20", width: "20" }}
            title="Upload Skit"
            titleStyle={{
              fontSize: ".6rem",
              margin: "0",
              textAlign: "center",
              fontWeight: "800",
              color:
                pressedBtnIndicator === "upload-btn"
                  ? "var(--color-ivory)"
                  : "var(--color-gold)",
            }}
            btnRndClassName=""
            extraStyles={{
              width: "40px",
              height: "40px",
              padding: "1px",
            }}
          />
          <HashLink
            to="/information#infoTop"
            scroll={(el) => scrollWithOffset(el, 150)}
            className="hash-link-wrapper "
            style={{
              color: currentPage.info ? activeLinkColor : "var(--color-gold)",
              textDecoration: "none",
            }}
          >
            <RoundFlatBtns
              name="info-btn"
              Icon={IIcon}
              iconProps={{ fillColor: "var(--color-gold)" }}
              onClick={() => {
                triggerLightBeam("info-btn");
              }}
              title="Info"
              titleStyle={{
                fontSize: ".6rem",
                margin: "0",
                textAlign: "center",
                fontWeight: "800",
              }}
              extraStyles={{
                width: "40px",
                height: "40px",
                padding: "1px",
              }}
            />
          </HashLink>
        </div>
        <div className="roundBtns-bottom">
          <HashLink
            to="/skitslist#skitsListingPage"
            scroll={(el) => scrollWithOffset(el, 60)}
            style={{
              textDecoration: "none",
              color: currentPage.home ? activeLinkColor : "var(--color-gold)",
            }}
          >
            <RoundFlatBtns
              name="home-btn"
              Icon={HomeIcon}
              onClick={() => {
                triggerLightBeam("home-btn");
                setRemoteBottomVisible(!visible);
              }}
              iconProps={{ height: "20", width: "20" }}
              title="Home"
              titleStyle={{
                fontSize: ".6rem",
                margin: "0",
                textAlign: "center",
                fontWeight: "800",
              }}
              extraStyles={{
                width: "40px",
                height: "40px",
                padding: "1px",
              }}
            />
          </HashLink>
          <HashLink
            to="/top-skits#topSkitsListingPage"
            scroll={(el) => scrollWithOffset(el, 60)}
            className="hash-link-wrapper "
            style={{
              color: currentPage.popular
                ? activeLinkColor
                : "var(--color-gold)",
              textDecoration: "none", // Removes the underline
            }}
          >
            <RoundFlatBtns
              name="popular-btn"
              Icon={StarIcon}
              onClick={() => {
                triggerLightBeam("popular-btn");
              }}
              iconProps={{ height: "20", width: "20" }}
              title="Top"
              titleStyle={{
                fontSize: ".6rem",
                margin: "0",
                fontWeight: "800",
              }}
              extraStyles={{
                width: "40px",
                height: "40px",
                padding: "1px",
              }}
            />
          </HashLink>
          <RoundFlatBtns
            name="colorpallete-btn"
            onClick={() => {
              triggerLightBeam("colorpallete-btn");
              toggleColorPaletteVisibility();
            }}
            Icon={colorPaletteVisible ? BlinkingXIcon : ColorPalleteIcon}
            iconProps={{
              height: "20",
              width: "20",
              fillColor: colorPaletteVisible
                ? "var(--color-bloodred) "
                : "var(--color-gold)",
            }}
            title={colorPaletteVisible ? "Close" : "Color"}
            titleStyle={{
              fontSize: ".6rem",
              margin: "0",
              textAlign: "center",
              fontWeight: "800",
              color: colorPaletteVisible
                ? "var(--color-lightyellow)"
                : "var(--color-gold)",
            }}
            extraStyles={{
              width: "40px",
              height: "40px",
              padding: "1px",
            }}
          />
        </div>
        {colorPaletteVisible && (
          <ThemedButtonColorPalette
            setTheme={updateThemeAndColors}
            className={theme}
            isGradientTheme={isGradientTheme}
            remoteColorUserSetting={remoteColorUserSetting}
            currentUser={currentUser}
          />
        )}
      </div>
    </div>
  );
};
export default Remote;
