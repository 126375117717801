import { validateUserNameValue } from "../../constants/validate.js";

import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";

export const emailFieldWatcher = (
  emailValue,
  emailValueError,
  setEmailValue,
  setEmailValueError,
  currentContext
) => {
  const key = getStorageKey("emailValue", currentContext); // Generate storage key
  setEmailValue(emailValue); // Update state with new value
  localStorage.setItem(key, emailValue); // Store updated email in local storage

  if (emailValueError) {
    setEmailValueError(""); // Clear error if one is present
  }
};

export const usernameFieldWatcher = (
  e, // Receives event here
  setUserNameValue,
  currentContext,
  userNameValueError,
  setUsernameError,
  setUserNameValidationRules,
  setUserNameToolTipVisible
) => {
  const value = e.target.value; // Extracts value from event
  setUserNameValue(value);
  const key = getStorageKey("userNameValue", currentContext);
  localStorage.setItem(key, value);
  const userNameValidationResults = validateUserNameValue(value);
  setUserNameValidationRules(userNameValidationResults); // Update validation rules state
  setUserNameToolTipVisible(
    !userNameValidationResults.every((rule) => rule.isValid)
  ); // Update tooltip visibility
  if (userNameValueError) {
    setUsernameError("");
  }
};

export const onUserNameFocus = (
  userNameValue,
  validateUserNameValue,
  setUserNameFieldFocused,
  setUserNameValidationRules,
  setUserNameToolTipVisible
) => {
  setUserNameFieldFocused(true);

  // Validate on focus to show tooltip if criteria are not met
  const userNameRules = validateUserNameValue(userNameValue).map((rule) => ({
    ...rule,
    isValid: rule.isValid,
  }));
  setUserNameValidationRules(userNameRules);
  setUserNameToolTipVisible(userNameRules.some((rule) => !rule.isValid));
};

export const onUserNameBlur = (
  setUserNameFieldFocused,
  setUserNameToolTipVisible
) => {
  setUserNameFieldFocused(false);
  setUserNameToolTipVisible(false); // Hide tooltip on blur
};

export const phoneFieldWatcher = (
  value,
  phoneNumberSuggestionError,
  phoneNumberValueError,
  setPhoneNumberValue,
  setPhoneNumberValueError,
  setPhoneNumberSuggestionError,
  currentContext
) => {
  setPhoneNumberValue(value);
  const Key = getStorageKey("phoneNumberValue", currentContext);
  localStorage.setItem(Key, value);
  if (phoneNumberValueError) {
    setPhoneNumberValueError("");
  } else if (phoneNumberSuggestionError) {
    setPhoneNumberSuggestionError("");
  }
};
