import { getStorageKey } from "../utils/localStorageUniqueKeyGenerator";
import { checkoutErrorMessages } from "../constants/errorMessages";
import {
  convertPointsToDollars,
  convertDollarsToPoints,
} from "../constants/rewardConstants";
/**
 * Generic validation function to validate fields from localStorage.
 */
export const validateFieldFromLocalStorage = (
  fieldKey,
  errorKey,
  errorMessage,
  contextType,
  setErrors
) => {
  const fieldValue = localStorage.getItem(getStorageKey(fieldKey, contextType));
  const error = fieldValue ? "" : errorMessage;

  // Update errors if the setter is provided
  if (setErrors) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [errorKey]: error,
    }));
  }
  return !error; // Return true if valid
};

/**
 * Specific field validation functions leveraging checkoutErrorMessages.
 */
export const validatePaymentMethod = (contextType, setErrors) => {
  const paymentMethod = localStorage.getItem(
    getStorageKey("paymentMethod", contextType)
  );

  if (!paymentMethod) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      paymentMethod: checkoutErrorMessages.paymentMethod,
    }));
    return false; // Validation failed
  }

  setErrors((prevErrors) => ({
    ...prevErrors,
    paymentMethod: null, // Clear error if validation passes
  }));
  return true; // Validation passed
};

export const validateExpirationMonth = (contextType, setErrors) => {
  return validateFieldFromLocalStorage(
    "ccExpMonth",
    "expirationMonth",
    checkoutErrorMessages.expirationMonth,
    contextType,
    setErrors
  );
};

export const validateExpirationYear = (contextType, setErrors) => {
  return validateFieldFromLocalStorage(
    "ccExpYear",
    "expirationYear",
    checkoutErrorMessages.expirationYear,
    contextType,
    setErrors
  );
};

export const validateCreditCardNumber = (contextType, setErrors) => {
  return validateFieldFromLocalStorage(
    "creditCardNumber",
    "creditCardNumber",
    checkoutErrorMessages.creditCardNumber,
    contextType,
    setErrors
  );
};
export const validateFirstName = (contextType, setErrors) => {
  return validateFieldFromLocalStorage(
    "firstName",
    "firstName",
    checkoutErrorMessages.firstName,
    contextType,
    setErrors
  );
};

export const validateLastName = (contextType, setErrors) => {
  return validateFieldFromLocalStorage(
    "lastName",
    "lastName",
    checkoutErrorMessages.lastName,
    contextType,
    setErrors
  );
};

export const validateAddress1 = (contextType, setErrors) => {
  return validateFieldFromLocalStorage(
    "address1",
    "address1",
    checkoutErrorMessages.address1,
    contextType,
    setErrors
  );
};

export const validateCity = (contextType, setErrors) => {
  return validateFieldFromLocalStorage(
    "city",
    "city",
    checkoutErrorMessages.city,
    contextType,
    setErrors
  );
};

export const validatePostalCode = (contextType, setErrors) => {
  return validateFieldFromLocalStorage(
    "postalCode",
    "postalCode",
    checkoutErrorMessages.postalCode,
    contextType,
    setErrors
  );
};

export const validateCountry = (contextType, setErrors) => {
  return validateFieldFromLocalStorage(
    "country",
    "country",
    checkoutErrorMessages.country,
    contextType,
    setErrors
  );
};

export const validateSelectedState = (contextType, setErrors) => {
  return validateFieldFromLocalStorage(
    "selectedState",
    "selectedState",
    checkoutErrorMessages.selectedState,
    contextType,
    setErrors
  );
};

export const calculateValues = ({
  totalBudget,
  paymentType,
  costPerImpression,
  costPerClick,
  merchantFee,
  pointsToUse,
  userPointsBalance,
}) => {
  let impressionsOrClicks = 0; // use let here because impressionsOrClicks needs to change depending on the paymentType, so const won't work.

  if (paymentType === "impressions") {
    impressionsOrClicks = Math.floor(totalBudget / costPerImpression);
  } else if (paymentType === "clicks") {
    impressionsOrClicks = Math.floor(totalBudget / costPerClick);
  }
  // using const below to recalculate the arguments giving and it is not re-assigning or binding to a new variable just recalculating
  const orderSubtotal = totalBudget;
  const merchantFeeAmount = orderSubtotal * merchantFee;
  const orderGrandTotal = orderSubtotal + merchantFeeAmount;

  const maxOrderPoints = Math.min(
    userPointsBalance,
    convertDollarsToPoints(orderGrandTotal) // Convert grand total dollars to points
  );

  const clampedPoints = Math.min(pointsToUse, maxOrderPoints);
  const redeemedDollars = convertPointsToDollars(clampedPoints); // Calculate total due
  const orderTotalDue = Math.max(orderGrandTotal - redeemedDollars, 0); // Subtract redeemed points in dollars

  return {
    impressionsOrClicks,
    orderSubtotal,
    merchantFeeAmount,
    orderGrandTotal,
    maxOrderPoints,
    clampedPoints,
    orderTotalDue,
  };
};
