import Axios, { REQUEST_METHOD } from "../axios";

// Create a comment function using the custom Axios instance
export function apiCreateComment({ relatedId, sourceId, message }) {
  return Axios.fetch({
    url: `memberComment/member-comment/${sourceId}`,
    method: REQUEST_METHOD.POST,
    data: {
      relatedId,
      message,
    },
  });
}

export function apiFetchComments(relatedId, lastFetchedDate, limit = 10) {
  const params = { limit };
  if (lastFetchedDate) params.lastFetchedDate = lastFetchedDate;
  return Axios.fetch({
    url: `memberComment/comments/${relatedId}`,
    method: REQUEST_METHOD.GET,
    params,
  });
}

// Create a thread
export function apiCreateThread({ commentId, sourceId, message }) {
  return Axios.fetch({
    url: `memberThreads/member-respond/${commentId}`,
    method: REQUEST_METHOD.POST,
    data: {
      sourceId,
      message,
    },
  });
}
export function apiFetchThreads(commentId) {
  return Axios.fetch({
    url: `memberThreads/threads/${commentId}`, // removed the Included offset and limit in the query parameters
    method: REQUEST_METHOD.GET,
  });
}

export function apiCreateResponseToThread({
  threadId,
  message,
  responderId,
  sourceId,
}) {
  return Axios.fetch({
    url: `memberThreads/member-respond-to-thread/${threadId}`,
    method: REQUEST_METHOD.POST,
    data: { message, responderId, sourceId},
  });
}

export function apiFetchThreadResponses(threadId) {
  return Axios.fetch({
    url: `memberThreads/responses/${threadId}`,
    method: REQUEST_METHOD.GET,
  });
}

// Heart a comment
export function apiHeartComment(commentId, sourceId) {
  return Axios.fetch({
    url: `memberComment/comments/${commentId}/heart`,
    method: REQUEST_METHOD.POST,
    data: { sourceId },
  });
}
// Unheart a comment
export function apiUnheartComment(commentId, sourceId) {
  return Axios.fetch({
    url: `memberComment/comments/${commentId}/unheart`,
    method: REQUEST_METHOD.POST,
    data: { sourceId },
  });
}

// Heart a thread response
export function apiHeartThreadResponse(responseId, sourceId) {
  return Axios.fetch({
    url: `memberThreads/responses/${responseId}/heart`,
    method: REQUEST_METHOD.POST,
    data: { sourceId },
  });
}
// Unheart a thread response
export function apiUnheartThreadResponse(responseId, sourceId) {
  return Axios.fetch({
    url: `memberThreads/responses/${responseId}/unheart`,
    method: REQUEST_METHOD.POST,
    data: { sourceId },
  });
}

// Heart a thread
export function apiHeartThread(threadId, sourceId) {
  return Axios.fetch({
    url: `memberThreads/threads/${threadId}/heart`,
    method: REQUEST_METHOD.POST,
    data: { sourceId },
  });
}
// Unheart a thread
export function apiUnheartThread(threadId, sourceId) {
  return Axios.fetch({
    url: `memberThreads/threads/${threadId}/unheart`,
    method: REQUEST_METHOD.POST,
    data: { sourceId },
  });
}

export const apiCheckMessageOwnership = (userId) => {
  return Axios.fetch({
    url: `memberComment/ownership/${userId}`,
    method: REQUEST_METHOD.GET,
    params: { userId },
  });
};

export function apiDeleteSkitComment({ commentId }) {
  return Axios.fetch({
    url: `memberComment/delete-comment/${commentId}`,
    method: REQUEST_METHOD.DELETE,
  });
}

export function apiDeleteCommentThread({ threadId }) {
  return Axios.fetch({
    url: `memberThreads/delete-thread/${threadId}`,
    method: REQUEST_METHOD.DELETE,
  });
}

export function apiDeleteThreadMemberResponse({ responseId }) {
  return Axios.fetch({
    url: `memberThreads/delete-response/${responseId}`,
    method: REQUEST_METHOD.DELETE,
  });
}
// Check hearted items
// API to fetch all hearted items for a user
export function apiCheckCurrentUserHeartedItems(sourceId) {
  return Axios.fetch({
    url: `/activity/get-all-hearted-items-by-sourceId`,
    method: REQUEST_METHOD.GET,
    params: { sourceId },
  });
}

export default {
  apiCheckCurrentUserHeartedItems,
  apiCheckMessageOwnership,
  apiHeartThread,
  apiUnheartThread,
  apiHeartThreadResponse,
  apiUnheartThreadResponse,
  apiHeartComment,
  apiUnheartComment,
  apiCreateResponseToThread,
  apiFetchThreadResponses,
  apiFetchThreads,
  apiCreateComment,
  apiFetchComments,
  apiCreateThread,
};

// The primary reason to avoid sending the items array in the data field of a GET request is due to the
// convention and limitations associated with GET requests. GET requests should not have a body according to the HTTP specification,
// and some servers and clients do not support or handle it correctly. Instead, data should be sent as query parameters.
