import React, { useState, useEffect } from "react";
import { renderUserThumbnail } from "../../controller/userThumbnail";
import { SmallPlainInsetBtnEventListener, PlainBtn } from "../buttons";
import CurrentUserReply from "./currentUserReply";
import { SmileyBubblyChat, XIcon } from "../icons/index";
import { renderCommentWithEmotes } from "../../utils/emoteUtils";
import {
  apiHeartThreadResponse,
  apiUnheartThreadResponse,
} from "../../api/backend/comment";
import "./responseThread.scss";

const ResponseThreadResults = ({
  response,
  currentUser,
  activeResponseId,
  setActiveResponseId,
  handleCancelReply,
  handleCreateResponseToThread,
  replyValue,
  setReplyValue,
  replyingTo,
  setReplyingTo,
  responseError,
  setResponseError,
  isHearted, // linked to item which is comment type and a boolean that checks the list from the backend and is defined with this prop which sets state here to help control the heart button UI for the user
  deleteMessagePermissions,
  deleteThreadMemberResponse,
  formattedTime,
}) => {
  const [hearted, setHearted] = useState(isHearted);
  const [heartCount, setHeartCount] = useState(response.heartCount); // Initialize based on backend data

  // Sync `hearted` state with `isHearted` prop
  useEffect(() => {
    console.log(
      `ResponseThreadResults: Updating hearted state for response ${response.id}`
    );
    setHearted(isHearted);
  }, [isHearted, response.id]);

  const handleHeartClick = async () => {
    try {
      if (hearted) {
        const res = await apiUnheartThreadResponse(response.id, currentUser);
        if (res.data.success) {
          setHeartCount(res.data.heartCount);
          setHearted(false);
        }
      } else {
        const res = await apiHeartThreadResponse(response.id, currentUser);
        if (res.data.success) {
          setHeartCount(res.data.heartCount);
          setHearted(true);
        }
      }
    } catch (error) {
      console.error("Error updating heart status:", error);
      setResponseError("Failed to update heart status. Please try again.");
    }
  };
  if (!response || !response.target) {
    console.error("Response or Response target is undefined", response);
    return null;
  }

  const handleCancel = () => {
    handleCancelReply("response", response.id);
    setReplyValue("");
    setResponseError("");
  };
  const renderMessage = () => {
    const parts = renderCommentWithEmotes(response.message);
    return parts.map((part, index) => (
      <span key={index} className="message-part">
        {part}
      </span>
    ));
  };

  return (
    <div key={response.id} id="skit-response" className="response-container">
      <div className="bubble-container">
        <div className="bubble-wrapper">
          {renderUserThumbnail(response.target)}
          <div className="bubble">
            <span className="messageValue">
              <span className="addressing-username">
                {response.target.username}, &nbsp;
              </span>
              &nbsp;{renderMessage()} &nbsp; &nbsp;{" "}
              <span className="username">
                {" "}
                - {response.responder.username}&nbsp;
                <span className="formatted-time">·{formattedTime}</span>
              </span>
            </span>
          </div>
        </div>
        <div className="reply-actions-wrapper">
          <SmallPlainInsetBtnEventListener
            className="reply-response-btn"
            image={
              <SmileyBubblyChat
                fillColor="#b03a2e"
                style={{ position: "relative", bottom: "-7px" }}
              />
            }
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "1.3rem",
              fontWeight: "900",
              lineHeight: "14px",
            }}
            width="fit-content"
            text="Reply"
            onClick={() => {
              setActiveResponseId(response.id);
              setReplyingTo(response.target);
            }}
          />
          {deleteMessagePermissions && (
            <SmallPlainInsetBtnEventListener
              image={
                <XIcon
                  fillColor="#b03a2e"
                  style={{ position: "relative", paddingRight: "5px" }}
                />
              }
              style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "1.3rem",
                fontWeight: "900",
                lineHeight: "14px",
              }}
              width="fit-content"
              className="delete-comment-btn"
              text="Delete" // allows the current user who as permissions to delete the comment, and all thread and thread response children
              onClick={() => deleteThreadMemberResponse(response.id)}
            />
          )}
          <PlainBtn
            plainBtnText={hearted ? "Unheart" : "Heart"}
            plainBtnTextFontSize="1.3rem"
            plainBtnHeight="fit-content"
            plainBtnFontFamily="Roboto"
            plainBtnFontWeight="bold"
            plainBtnFontColor="#b03a2e"
            plainBtnBorderRadius="30px"
            onClick={handleHeartClick}
            image={
              <span
                role="img"
                aria-label="heart"
                className={hearted ? "broken-heart" : "heart-beat"}
              >
                {hearted ? "💔" : "❤️"}
              </span>
            }
            imageOnTop={false}
            extraStyles={{
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
          />
          {heartCount > 0 && (
            <span className="heart-count"> ❤️ {heartCount}</span>
          )}
        </div>
        {activeResponseId === response.id && (
          <CurrentUserReply
            currentUser={currentUser}
            type="response"
            id={response.id}
            replyingTo={replyingTo}
            onSubmitReply={() =>
              handleCreateResponseToThread(
                response.threadId,
                response.target.id
              )
            }
            onCancel={handleCancel}
            value={replyValue}
            setValue={setReplyValue}
            placeholder="Write a reply message..."
            responseError={responseError}
            setResponseError={setResponseError}
          />
        )}
      </div>
    </div>
  );
};

export default ResponseThreadResults;
