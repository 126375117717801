import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/material.css";
import { EyeSlashIcon, EyeIcon, LockIcon } from "../icons/index.jsx";
import KeyLockIcon from "../keyLockIconSwitches/keyLockIconSwitches.jsx";
import { GlassBtn, ImgTopBtn } from "../buttons/index.jsx";
import ToolTip from "../tooltip/tooltip.jsx";
import { handleResetPassWordErrors } from "../../controller/errorHandlers/handleResetPassWordErrors.js";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import { validatePasswordValue } from "../../constants/validate.js";
import { apiCurrentUserPswrdReset } from "../../api/backend/auth.js";
import { errorMessages } from "../../constants/errorMessages.js";
import Loader from "../loader/loader.jsx";
import {
  handleResetPasswordValueChange,
  onResetPasswordValueFocused,
  onResetPasswordValueBlur,
  handleConfirmResetPasswordChange,
} from "../../controller/watcherAndClearFunctions/resetPasswordClearsWatchersFn.js";
import "./resetPassword.scss";

const ResetPassword = ({ onUpdateAccount, onCancel, userId }) => {
  const currentContext = "resetPasswordSignin";
  const [resetPasswordFocused, setResetPasswordFocused] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    updatePasswordSigninFlowAttempted,
    setUpdatePasswordSigninFlowAttempted,
  ] = useState(false);
  const [resetPasswordTooltipVisible, setResetPasswordTooltipVisible] =
    useState(false);
  const [passwordValidationRules, setPasswordValidationRules] = useState([
    false,
  ]);
  const [showConfirmResetPassword, setShowConfirmResetPassword] =
    useState(false);
  const [confirmResetPasswordFocused, setConfirmResetPasswordFocused] =
    useState(false);
  const [resetPasswordValue, setResetPasswordValue] = useState(
    localStorage.getItem(getStorageKey("resetPasswordValue", currentContext)) ||
      ""
  );
  const [confirmResetPasswordValue, setConfirmResetPasswordValue] = useState(
    localStorage.getItem(
      getStorageKey("confirmPasswordValue", currentContext)
    ) || ""
  );
  const [resetPasswordError, setResetPassWordError] = useState("");
  const [confirmResetPasswordError, setConfirmResetPasswordError] =
    useState("");
  const [backendError, setBackendError] = useState(""); // State to handle backend error

  const onResetPassword = () => {
    setUpdatePasswordSigninFlowAttempted(true); // Trigger validation
  };

  useEffect(() => {
    const resetPassword = async () => {
      if (updatePasswordSigninFlowAttempted) {
        const { errors } = handleResetPassWordErrors(
          resetPasswordValue,
          confirmResetPasswordValue,
          currentContext,
          setResetPasswordTooltipVisible,
          setResetPassWordError,
          setConfirmResetPasswordError
        );

        if (Object.keys(errors).length === 0) {
          setLoading(true);
          setBackendError(""); // Clear backend error before making the request

          try {
            const response = await apiCurrentUserPswrdReset(
              userId,
              resetPasswordValue
            );
            setLoading(false);
            if (response.status === 200) {
              localStorage.clear();
              onUpdateAccount();
            }
          } catch (error) {
            setLoading(false);
            const errorMessage =
              error.response?.data?.message || "Password reset failed";
            setBackendError(errorMessage); // Set the backend error message
          }
        }

        setUpdatePasswordSigninFlowAttempted(false);
      }
    };

    resetPassword();
  }, [
    updatePasswordSigninFlowAttempted,
    resetPasswordValue,
    confirmResetPasswordValue,
  ]);

  return (
    <div className="reset-password-wrapper">
      <form id="reset-password-form">
        <h1 className="reset-password-header">Reset Password</h1>
        <div className="reset-input-group-with-tooltip">
          <div className="reset-input-group blinker-wrapper">
            <input
              type={showResetPassword ? "text" : "password"}
              id="newPassword"
              autoComplete="off"
              placeholder={
                resetPasswordError
                  ? resetPasswordError
                  : "...Enter a new password here"
              }
              className={`${resetPasswordFocused ? "focused" : ""} ${
                resetPasswordError ? "field-background-color-error" : ""
              }`}
              value={resetPasswordValue}
              onChange={(e) =>
                handleResetPasswordValueChange(
                  e.target.value,
                  currentContext,
                  resetPasswordError,
                  setResetPasswordValue,
                  validatePasswordValue,
                  setPasswordValidationRules,
                  setResetPasswordTooltipVisible,
                  setResetPassWordError
                )
              }
              onFocus={() => {
                if (resetPasswordError) setResetPassWordError("");
                if (backendError) setBackendError("");
                onResetPasswordValueFocused(
                  resetPasswordValue,
                  setResetPasswordFocused,
                  validatePasswordValue,
                  setPasswordValidationRules,
                  setResetPasswordTooltipVisible
                );
              }}
              onBlur={() =>
                onResetPasswordValueBlur(
                  setResetPasswordFocused,
                  setResetPasswordTooltipVisible
                )
              }
            />
            {!(resetPasswordFocused || resetPasswordValue) && (
              <>
                <LockIcon
                  width="20"
                  height="20"
                  className="lock-icon"
                  fillColor="var(--color-white)"
                  locked={!showResetPassword}
                  style={{
                    opacity: 0.68,
                    transition: "opacity 0.2s ease-in-out",
                  }}
                />
                <span className="blinking-cursor-black-color signup-resetpassword-cursor">
                  |
                </span>
              </>
            )}
            {resetPasswordValue && resetPasswordValue !== "" && (
              <div
                className="show-hide-password-toggle"
                onClick={() => setShowResetPassword((prev) => !prev)}
                style={{
                  position: "absolute",
                  right: "10px", // Position it on the right inside the input field
                  top: "50%",
                  transform: "translateY(-50%)", // Center it vertically
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {showResetPassword ? (
                  <EyeSlashIcon width="20" height="15" />
                ) : (
                  <EyeIcon width="20" height="15" />
                )}
                {showResetPassword ? "Hide" : "Show"}
              </div>
            )}
          </div>
          {(resetPasswordFocused || resetPasswordError) && (
            <ToolTip
              rules={passwordValidationRules}
              toolTipVisible={resetPasswordTooltipVisible}
              setToolTipVisible={setResetPasswordTooltipVisible}
            />
          )}
        </div>
        <div className="reset-input-group blinker-wrapper">
          <input
            type={showConfirmResetPassword ? "text" : "password"}
            id="confirmPassword"
            autoComplete="off"
            placeholder={
              confirmResetPasswordError
                ? confirmResetPasswordError
                : "...Re-enter your password here"
            }
            className={`${confirmResetPasswordFocused ? "focused" : ""} ${
              confirmResetPasswordError ? "field-background-color-error" : ""
            }`}
            value={confirmResetPasswordValue}
            onChange={(e) =>
              handleConfirmResetPasswordChange(
                e.target.value,
                confirmResetPasswordError,
                currentContext,
                setConfirmResetPasswordValue,
                setConfirmResetPasswordError
              )
            }
            onFocus={() => {
              setConfirmResetPasswordFocused(true);
              setBackendError("");
            }}
            onBlur={() => setConfirmResetPasswordFocused(false)}
          />
          {confirmResetPasswordError &&
            confirmResetPasswordError !== errorMessages.confirmPassword && (
              <div className="reset-error-message">
                {confirmResetPasswordError}
              </div>
            )}
          {!(confirmResetPasswordFocused || confirmResetPasswordValue) && (
            <>
              <KeyLockIcon
                className="lock-icon"
                fillColor="var(--color-white)"
                locked={!showConfirmResetPassword}
                style={{
                  opacity: 0.68,
                  transition: "opacity 0.2s ease-in-out",
                }}
              />
              <span className="blinking-cursor-black-color signup-resetpassword-cursor">
                |
              </span>
            </>
          )}
          {confirmResetPasswordValue && confirmResetPasswordValue !== "" && (
            <div
              className="show-hide-password-toggle"
              onClick={() => setShowConfirmResetPassword((prev) => !prev)}
              style={{
                position: "absolute",
                right: "10px", // Position it on the right inside the input field
                top: "30%",
                transform: "translateY(-50%)", // Center it vertically
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              {showConfirmResetPassword ? (
                <EyeSlashIcon width="20" height="15" />
              ) : (
                <EyeIcon width="20" height="15" />
              )}
              {showConfirmResetPassword ? "Hide" : "Show"}
            </div>
          )}
        </div>
        {loading && <Loader className="resetpswrd-loader-position" />}
        {backendError && (
          <div className="reset-error-message">{backendError}</div>
        )}
        <div>
          <GlassBtn
            onClick={(e) => {
              e.preventDefault();
              onResetPassword();
            }}
            glassBtnText="Update Password"
            glassBtnWidth="fit-content"
            glassBtnHeight="20px"
            glassBtnTextFontSize="1.3rem"
            sparkle="true"
            top="6px"
            fullwidthGlassHi={true}
            textClassName="responsiveBtnText"
            textLocalStyle={{
              color: "var(--color-yellowgold)",
              textDecoration: "underline",
              textDecorationColor: "var(--color-yellowgold)",
            }}
            hi2Style={{
              transform: "rotate(90deg) translate(-30px, 100px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-30px, -97px)",
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ImgTopBtn
            imgTopBtnText="X Cancel"
            imgTopBtnWidth="150px"
            imgTopBtnFontColor="#b03a2e"
            imgTopBtnTextFontSize="1.3rem"
            textClassName="responsiveBtnText"
            onClick={onCancel}
          />
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
