export const errorMessages = {
  username: "Username is required",
  phone: "A U.S. phone number is required",
  email: "Email is required",
  password: "Password is required",
  confirmPassword: "Re-enter password is required",
  firstName: "First name is required",
  lastName: "Last name is required",
};

export const campaignErrorMessages = {
  campaignName: "Campaign name is required.",
  expirationDate: "Expiration date is required.",
  fileUpload: "An image or video is required.",
  videoDuration: "Video duration must be between 2 and 30 minutes.",
  existingFile: "This file has already been uploaded.",
};

export const campaignAmountTypeErrorMessages = {
  campaignBudget: "Campaign budget is required",
  paymentType: "Campaign type is required",
  paymentSubscription: "Budget Period is required",
};

export const checkoutErrorMessages = {
  paymentMethod: "Select Payment Method Required", // "Required Payment method is required.
  expirationMonth: "Required.", // "Expiration month is required."
  expirationYear: "Required.", // "Expiration year is required.",
  creditCardNumber: "Credit card number is required..", 
  firstName: "First name is required for billing.",
  lastName: "Last name is required for billing.",
  address1: "Address Line 1 is required.",
  city: "City is required.",
  postalCode: "Postal code is required.",
  country: "Country is required.",
  selectedState: "State selection is required.",
};
