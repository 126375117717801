export const formatTime = (seconds) => {
    const pad = (num, size) => ("000" + num).slice(size * -1);
    const time = parseFloat(seconds).toFixed(3);
    const hours = Math.floor(time / 60 / 60);
    const minutes = Math.floor(time / 60) % 60;
    const secondsRemaining = Math.floor(time - minutes * 60);
    return `${hours ? pad(hours, 2) + ':' : ''}${pad(minutes, 2)}:${pad(secondsRemaining, 2)}`;
  };

  
  export const formatDuration = (durationInSeconds) => {
    if (durationInSeconds < 60) {
      // For durations under 1 minute, show only seconds
      return `${Math.floor(durationInSeconds)} seconds`;
    } else {
      // For durations 1 minute or longer, show HH:MM:SS
      const pad = (num) => (num < 10 ? "0" + num : num); // Pad single digits
      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.floor((durationInSeconds % 3600) / 60);
      const seconds = Math.floor(durationInSeconds % 60);
  
      return `${hours > 0 ? pad(hours) + ":" : ""}${pad(minutes)}:${pad(seconds)}`;
    }
  };
  