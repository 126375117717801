import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../components/context/authContext";
import eventBus from "../../utils/eventBus";
import SkitAdCard from "../../components/skitAd/skitAdCard";
import Remote from "../../components/remote/remote";
import {
  apiFetchSkitsForSkitsListingPage,
  apiDeleteSkitIdFromSourceIdAction,
} from "../../api/backend/skits";
import {
  apiRecordUserSkitNotInterested,
  apiFetchUserSkitNotInterested,
} from "../../api/backend/user";
import ClapperBoard from "../../components/clapperBoard/animatedClapperBoard";
import SmallPlainInsetBtn from "../../components/buttons/SmallPlainInsetBtn"; // Ensure this is the correct import path
import { MegaphoneIcon } from "../../components/icons";
import UploadVideoForm from "../../components/forms/uploadVideoForm";
import { useVideoFormStateCallBackController } from "../../controller/videoFormCallBackController";
import SearchBar from "../../components/search/search";
import Loader from "../../components/loader/loader";
import "./skitGrid.scss";

const Skits = () => {
  const { currentUser, handleLogout } = useAuth();
  const [activeCategory, setActiveCategory] = useState(null);
  const [isClapping, setIsClapping] = useState(false);
  const [skitsData, setSkitsData] = useState([]);
  const [colorPaletteVisible, setColorPaletteVisible] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [notInterested, setNotInterested] = useState([]);
  const initialOffset =
    parseInt(localStorage.getItem("skitsListingOffset")) || 0;
  const [offset, setOffset] = useState(initialOffset); // the number of items recieved
  const maxSkitsPerCategory = 6; // for each offset limit
  const onLogoutClick = () => {
    handleLogout();
  };

  console.log("Skits component rendered");
  // Scroll event logging
  useEffect(() => {
    const handleScroll = () => {
      console.log("Scroll event triggered");
      const scrollTop = window.scrollY;
      setSticky(scrollTop > 100);
    };

    console.log("Adding scroll event listener...");
    window.addEventListener("scroll", handleScroll);

    return () => {
      console.log("Removing scroll event listener...");
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Define the fetch function with useCallback
  const fetchCurrentUserNotInterestedActivityLogs = useCallback(async () => {
    console.log("Fetching 'not interested' logs...");
    setLoading(true);
    try {
      const response = await apiFetchUserSkitNotInterested({
        sourceId: currentUser,
      });
      console.log("Fetched 'not interested' logs:", response.data);
      if (response && response.data) {
        setNotInterested(response.data.map((log) => log.relatedId));
      }
    } catch (error) {
      console.error("Failed to fetch 'not interested' logs:", error);
      setError("Failed to load 'not interested' logs");
    } finally {
      setLoading(false);
    }
  }, [currentUser]);
  // // Use useEffect to call the function on mount or when currentUser changes
  useEffect(() => {
    console.log("notInterested updated", notInterested);
  }, [notInterested]);

  const handleNotInterested = useCallback(
    async (skit) => {
      console.log("Marking skit as 'not interested':", skit);
      try {
        const response = await apiRecordUserSkitNotInterested({
          sourceId: currentUser,
          activity: "notInterested",
          relatedId: skit.id,
          targetId: skit.sourceId,
        });
        console.log("Marked as 'not interested' response:", response);
        if (response.data && response.data.success) {
          setNotInterested((prev) => [...prev, skit.id]);
        }
      } catch (error) {
        console.error("Error marking skit as 'not interested':", error);
        setError("Failed to mark skit as 'not interested'");
      }
    },
    [currentUser]
  );

  const { openSkitUploadForm, setOpenSkitUploadForm, openVideoForm } =
    useVideoFormStateCallBackController();

  const fetchSkits = async (offset, categoryId = null) => {
    console.log("Fetching skits with offset and categoryId:", {
      offset,
      categoryId,
    });
    setLoading(true);
    try {
      const response = await apiFetchSkitsForSkitsListingPage(
        offset,
        maxSkitsPerCategory,
        categoryId
      );
      console.log("Fetched skits response:", response);
      if (response.status === 200 && response.data.success) {
        setSkitsData((prevSkits) =>
          offset === 0
            ? response.data.data
            : [...prevSkits, ...response.data.data]
        );
      }
    } catch (error) {
      console.error("Error fetching skits:", error);
      setError("Failed to fetch skits");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrentUserNotInterestedActivityLogs();
  }, [fetchCurrentUserNotInterestedActivityLogs]);

  // The duplicate key warning error is likely happening because the afterUploadCloseModalRerender
  // function is fetching skits without resetting the offset, which may result in adding the
  // newly uploaded skit to the existing list without clearing the previous skits.
  const afterUploadCloseModalRerender = () => {
    console.log("After upload close modal triggered");
    setOpenSkitUploadForm(false); // Close the form
    setOffset(0); // Reset the offset
    setSkitsData([]); // Clear the current skits data
    fetchSkits(0, activeCategory); // Fetch skits from the beginning
  };

  const handleDeleteSourceIdRelatedIdSkit = (skitId) => {
    console.log("Deleting skit with ID:", skitId);
    apiDeleteSkitIdFromSourceIdAction({ skitId })
      .then((response) => {
        console.log("Delete response:", response);
        if (response.status === 200) {
          const updatedSkitsData = skitsData.filter(
            (skit) => skit.id !== skitId
          );
          setSkitsData(updatedSkitsData);
        } else {
          alert("Failed to delete the skit");
        }
      })
      .catch((error) => {
        console.error("Error deleting skit:", error);
        alert("An error occurred while deleting the skit");
      });
  };

  const toggleColorPaletteVisibility = () => {
    setColorPaletteVisible(!colorPaletteVisible);
  };

  useEffect(() => {
    fetchSkits(offset, activeCategory);
  }, [offset, activeCategory]);
  const handleViewMore = async () => {
    setIsClapping(true);
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 500)); // Simulate delay for animation
      const newOffset = offset + maxSkitsPerCategory;
      setOffset(newOffset);
    } catch (error) {
      console.error("Failed to load more skits:", error);
    } finally {
      setIsClapping(false);
      setLoading(false);
    }
  };

  const updateSkitsData = useCallback(async () => {
    setLoading(true);
    try {
      const updatedSkits = await apiFetchSkitsForSkitsListingPage(
        0,
        maxSkitsPerCategory,
        activeCategory
      );
      if (updatedSkits.status === 200 && updatedSkits.data.success) {
        setSkitsData(updatedSkits.data.data);
      }
    } catch (error) {
      console.error("Error updating skits:", error);
    } finally {
      setLoading(false);
    }
  }, [maxSkitsPerCategory, activeCategory]);

  useEffect(() => {
    const handleSkitPromotion = (promotedSkit) => {
      console.log("Skit promoted event triggered:", promotedSkit);
      setSkitsData((prevSkits) => {
        const updatedSkits = [
          promotedSkit,
          ...prevSkits.filter((skit) => skit.id !== promotedSkit.id),
        ];
        return updatedSkits;
      });
    };

    console.log("Adding skitPromoted listener...");
    eventBus.on("skitPromoted", handleSkitPromotion);

    return () => {
      console.log("Removing skitPromoted listener...");
      eventBus.off("skitPromoted", handleSkitPromotion);
    };
  }, []);

  return (
    <div id="skitsListingPage">
      <div
        className={`${colorPaletteVisible ? "glassHolder" : "searchRemoteWrapper"} ${
          sticky ? "sticky" : "searchRemoteWrapper"
        }`}
      >
        <Remote
          onUploadClick={openVideoForm}
          toggleColorPaletteVisibility={toggleColorPaletteVisibility}
          colorPaletteVisible={colorPaletteVisible}
          setColorPaletteVisible={setColorPaletteVisible}
          onChannelChange={(categoryId) => {
            setActiveCategory(categoryId);
            setOffset(0);
            setSkitsData([]);
            fetchSkits(0, categoryId);
          }}
          onClick={onLogoutClick}
        />
        <SearchBar />
      </div>
      {openSkitUploadForm && (
        <UploadVideoForm
          key={Date.now()} // Each time the button is clicked, a new key is generated
          isUploadVideoFormOpen={openSkitUploadForm}
          closeModal={() => setOpenSkitUploadForm(false)} // Directly setting state to close
          currentUser={currentUser}
          afterModalClose={afterUploadCloseModalRerender} // Pass the callback prop in as a parameter and then set the fuction in the child when it is invoked in the child it will activiate the function here
        />
      )}
      <div className="skits-grid">
        {skitsData
          .filter((skit) => !notInterested.includes(skit.id)) // Filter out not interested skits
          .map((skit) => (
            <SkitAdCard
              key={skit.id}
              specificSkitData={skit}
              currentUser={currentUser}
              onDelete={() => handleDeleteSourceIdRelatedIdSkit(skit.id)} // Passing skit.id to the delete handler
              onNotInterested={() => handleNotInterested(skit)}
              updateSkitsData={updateSkitsData}
              isUploadVideoFormOpen={openSkitUploadForm} // Pass the prop here to manage z-index in Skit
            />
          ))}
      </div>
      {skitsData.length > 0 && (
        <div className="View-More-Action">
          {loading && <Loader className="View-More-Action" />}
          <ClapperBoard isClapping={isClapping} />
          <SmallPlainInsetBtn
            image={
              <MegaphoneIcon
                height="20px"
                width="20px"
                style={{ marginRight: "3px" }}
              />
            }
            text="View More Action"
            width="188px"
            style={{
              fontSize: "1.25rem",
            }}
            onClick={handleViewMore}
          />
        </div>
      )}
    </div>
  );
};
export default Skits;
