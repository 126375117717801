export const checkFile = (fileName, mimeType, fileSize) => {
  // Define allowedMimeTypes within the function
  const allowedMimeTypes = ['image/jpeg', 'image/png'];
  const allowedExtensions = {
    'image/jpeg': ['jpg', 'jpeg'],
    'image/png': ['png'],
  };
  const extension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
  if (!allowedMimeTypes.includes(mimeType)) {
    throw new Error('Unsupported media type for file');
  }
  // Ensure the MIME type's allowed extensions include the file's extension
  if (!allowedExtensions[mimeType]?.includes(extension)) {
    throw new Error('File extension does not match MIME type');
  }
  // Check if the file size exceeds the predefined limit
  if (fileSize > 250000) { // Assuming the limit is 250kB
    throw new Error('File size exceeds 250kB');
  }
};
