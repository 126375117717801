import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/authContext";
import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator";
import { checkFile } from "../../../utils/checkFile";
import { checkVideoFile } from "../../../utils/checkVideoFile";
import { uploadFileToS3 } from "../../../api/backend/uploadToS3";
import { apiFetchCurrentUserDetails } from "../../../api/backend/user";
import {
  getPresignedAdVideoUrl,
  getPresignedAdImgUrl,
} from "../../../api/backend/campaign";
import { apiCampaignPayment } from "../../../api/backend/auth";
import { periodChargeThresholdType } from "../../../constants/dropdownOptions";
import {
  handleFieldChange,
  handlePaymentMethodChange,
  onClearAllBillingAndPaymentFields,
  onClearState,
} from "../../../controller/watcherAndClearFunctions/billingWatchersAndClearFunctions";
import {
  validatePaymentMethod,
  validateExpirationMonth,
  validateExpirationYear,
  validateCreditCardNumber,
  validateFirstName,
  validateLastName,
  validateAddress1,
  validateCity,
  validatePostalCode,
  validateCountry,
  validateSelectedState,
  calculateValues,
} from "../../../controller/paymentsController";
import {
  pointsValueInCentsPerDollar,
  pointsPerDollar,
} from "../../../constants/rewardConstants";
import PlaceOrderSummary from "./placeOrderSummary";
import BillingForm from "./billingForm";
import "./checkout.scss";

const Checkout = ({
  contextType = "createCampaign",
  onResetStateOnCampaignSuccess, // Callback
}) => {
  // const contextType = "createCampaign"; // create campaign state used for the key context of local storage
  const checkoutRef = useRef(null); // Create a reference for the container
  const { currentUser } = useAuth(); // source id = current user for the campaign and updated globally
  const cloudFrontDomain = "media.skitsolympic.com"; // Your CloudFront domain

  // Core user inputs
  const [paymentMethod, setPaymentMethod] = useState(""); // UseState to manage payment method directly
  const [expirationMonth, setExpirationMonth] = useState(() => {
    const storedCreditCardExpirationMonth = localStorage.getItem(
      getStorageKey("ccExpMonth", contextType)
    );
    return storedCreditCardExpirationMonth || ""; // Initialize from localStorage or default to empty
  });

  const [expirationYear, setExpirationYear] = useState(() => {
    // Initialize from localStorage or default to empty
    const storedCreditCardExpirationYear = localStorage.getItem(
      getStorageKey("ccExpYear", contextType)
    );
    return storedCreditCardExpirationYear || ""; // Initialize from localStorage or default to empty
  });
  const [selectedState, setSelectedState] = useState(() => {
    const storedState = localStorage.getItem(
      getStorageKey("selectedState", contextType)
    );
    console.log("Loaded selectedState from localStorage:", storedState);
    return storedState || "";
  });

  const [billingDetails, setBillingDetails] = useState(() => {
    const details = {
      firstName:
        localStorage.getItem(getStorageKey("firstName", contextType)) || "",
      lastName:
        localStorage.getItem(getStorageKey("lastName", contextType)) || "",
      address1:
        localStorage.getItem(getStorageKey("address1", contextType)) || "",
      address2:
        localStorage.getItem(getStorageKey("address2", contextType)) || "",
      city: localStorage.getItem(getStorageKey("city", contextType)) || "",
      postalCode:
        localStorage.getItem(getStorageKey("postalCode", contextType)) || "",
      country:
        localStorage.getItem(getStorageKey("country", contextType)) || "",
    };
    console.log("Loaded billingDetails from localStorage:", details);
    return details;
  });
  const [paymentType, setPaymentType] = useState(""); // impressions or clicks from last form
  const [creditCardNumber, setCreditCardNumber] = useState(() => {
    const storedCC = localStorage.getItem(
      getStorageKey("creditCardNumber", contextType)
    );
    console.log("Loaded creditCardNumber from localStorage:", storedCC);
    return storedCC || "";
  });
  const [totalBudget, setTotalBudget] = useState(() => {
    const storedTotalBudget = localStorage.getItem(
      getStorageKey("totalBudget", contextType)
    );
    console.log("Loaded totalBudget from localStorage:", storedTotalBudget);
    return storedTotalBudget ? parseFloat(storedTotalBudget) : 0; // Parse to float if value exists, default to 0 otherwise
  });
  const [costPerImpression, setCostPerImpression] = useState(0); // collected from last form
  const [costPerClick, setCostPerClick] = useState(0); // collected from last form
  const [selectedPeriod, setSelectedPeriod] = useState(""); // collected from last form
  const [impressionsOrClicks, setImpressionsOrClicks] = useState(0);
  const [selectedPeriodName, setSelectedPeriodName] = useState(""); // For period name
  const [fileUpload, setFileUpload] = useState(null); // collected from first form
  const [presignedUrlResponse, setPresignedUrlResponse] = useState(null); // collected from first form

  const [loading, setLoading] = useState(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState("");

  // Metadata and auxiliary states
  const [imageMetaData, setImageMetaData] = useState({
    width: 0,
    height: 0,
    size: 0,
  });
  const [videoMetadata, setVideoMetadata] = useState({
    width: 0,
    height: 0,
    size: 0,
  });

  // Points and totals
  const [orderGrandTotal, setOrderGrandTotal] = useState(0); // Total before points
  const [orderTotalDue, setOrderTotalDue] = useState(0); // Total after points
  const [orderSubtotal, setOrderSubtotal] = useState(0); // Items cost
  const [userPointsBalance, setUserPointsBalance] = useState(0); // Backend points
  const [pointsToUse, setPointsToUse] = useState(0); // Points applied
  const [currentAvailablePoints, setCurrentAvailablePoints] = useState(0); // Fetched points

  // Derived values
  const maxOrderPoints = Math.min(
    userPointsBalance,
    Math.floor(orderGrandTotal * pointsPerDollar)
  );

  const [errors, setErrors] = useState({}); // Errors should be dynamic and tied to local storage, initialize it as an empty object
  const [merchantFee, setMerchantFee] = useState(() => {
    const storedFee = parseFloat(
      localStorage.getItem(getStorageKey("merchantFee", contextType))
    );
    console.log("Loaded merchantFee from localStorage:", storedFee);
    return storedFee || 0;
  });

  // payment check box terms and conditions agreement states
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);

  // using state to reset state, close the component, call back to the parent, placed in the success response
  const [campaignCreated, setCampaignCreated] = useState(false); // set to false so it won't trigger the useEffect on mount

  // Utility dropdown states
  const [isStateDropdownOpen, setStateDropdownOpen] = useState(false);
  const [isPaymentMethodOpen, setPaymentMethodOpen] = useState(false);
  const [isYearDropdownOpen, setYearDropdownOpen] = useState(false);
  const [isMonthDropdownOpen, setMonthDropdownOpen] = useState(false);
  const [resetMonthDropdown, setResetMonthDropdown] = useState(false);
  const [resetYearDropdown, setResetYearDropdown] = useState(false);
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: 10 },
    (_, index) => currentYear + index
  ); // Generates years for the next 10 years

  const clearAllBillingAndPaymentFields = () => {
    onClearAllBillingAndPaymentFields(
      setCreditCardNumber,
      setExpirationMonth,
      setExpirationYear,
      setBillingDetails,
      contextType,
      getStorageKey
    );
  };

  // Updated useEffect with calculateValues
  useEffect(() => {
    const initialTotalBudget =
      parseFloat(
        localStorage.getItem(getStorageKey("totalBudget", "createCampaign"))
      ) || 0;
    const initialPaymentType =
      localStorage.getItem(getStorageKey("paymentType", "createCampaign")) ||
      "";
    const initialCostPerImpression =
      parseFloat(
        localStorage.getItem(
          getStorageKey("costPerImpression", "createCampaign")
        )
      ) || 0;
    const initialCostPerClick =
      parseFloat(
        localStorage.getItem(getStorageKey("costPerClick", "createCampaign"))
      ) || 0;
    const initialSelectedPeriod =
      localStorage.getItem(
        getStorageKey("paymentSubscription", "createCampaign")
      ) || "";

    setTotalBudget(initialTotalBudget);
    setPaymentType(initialPaymentType);
    setCostPerImpression(initialCostPerImpression);
    setCostPerClick(initialCostPerClick);
    setSelectedPeriodName(
      periodChargeThresholdType.find(
        (period) => period.value === initialSelectedPeriod
      )?.name || ""
    );

    const derivedValues = calculateValues({
      totalBudget: initialTotalBudget,
      paymentType: initialPaymentType,
      costPerImpression: initialCostPerImpression,
      costPerClick: initialCostPerClick,
      merchantFee,
      userPointsBalance,
      pointsToUse,
      pointsValueInCentsPerDollar,
    });

    setImpressionsOrClicks(derivedValues.impressionsOrClicks);
    setOrderSubtotal(derivedValues.orderSubtotal);
    setOrderGrandTotal(derivedValues.orderGrandTotal);
    setOrderTotalDue(derivedValues.orderTotalDue);
    setPointsToUse(derivedValues.clampedPoints);
  }, [merchantFee, pointsToUse, userPointsBalance]);

  const handleSliderChange = (value) => {
    setErrors({});
    const updatedValues = calculateValues({
      totalBudget,
      paymentType,
      costPerImpression,
      costPerClick,
      merchantFee,
      userPointsBalance,
      pointsToUse: value,
      pointsValueInCentsPerDollar,
    });

    setPointsToUse(updatedValues.clampedPoints);
    setOrderTotalDue(updatedValues.orderTotalDue);

    if (parseFloat(updatedValues.orderTotalDue.toFixed(2)) === 0) {
      setMerchantFee(0);
      setPaymentMethod("points");
      clearState(); // Reset selectedState and billingDetails
      clearAllBillingAndPaymentFields(); // Clear all other billing and payment fields
      setResetMonthDropdown(true); // Trigger dropdown reset flag, like pulling a trigger on the gun to fire which resets state to true
      setResetYearDropdown(true);
    }
  };
  const onMonthDropdownReset = () => {
    setResetMonthDropdown(false); // Reset the flag after dropdown has been cleared or set it back to false, so Release the Trigger from the gun fire back to inital state, since we have no click we have to manually do it..
  };
  const onYearDropdownReset = () => {
    setResetYearDropdown(false); // Reset the flag after dropdown has been cleared or set it back to false, so Release the Trigger from the gun fire back to inital state, since we have no click we have to manually do it..
  };

  const onFieldChange = (fieldName, value) => {
    // Explicitly define contextType inside the function
    const contextType = "createCampaign";
  
    // Log the contextType for debugging
    console.log(`onFieldChange - ContextType: ${contextType}`);
    
    // Generate the storage key using the contextType
    const storageKey = getStorageKey(fieldName, contextType);
  
    // Update the billing details state
    setBillingDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  
    // Update localStorage with the new value
    console.log(`Updating localStorage: Key = ${storageKey}, Value = ${value}`);
    localStorage.setItem(storageKey, value);
  };
  
  

  const clearState = () => {
    onClearState(
      getStorageKey,
      contextType,
      setSelectedState,
      setBillingDetails,
      setStateDropdownOpen
    );
  };
  const handleFileUpload = async () => {
    setLoading(true);
    try {
      const adBlobString = localStorage.getItem("createCampaign_adBlob");
      if (!adBlobString) {
        throw new Error("No file information found in local storage.");
      }

      const fileDetails = JSON.parse(adBlobString);
      const { fileName, mimeType, fileSize, sourceId, previewUrl } =
        fileDetails;

      let presignedUrlResponse;
      if (mimeType.startsWith("image/")) {
        checkFile(fileName, mimeType, fileSize);
        presignedUrlResponse = await getPresignedAdImgUrl(
          fileName,
          mimeType,
          fileSize,
          sourceId
        );
      } else if (mimeType.startsWith("video/")) {
        checkVideoFile(fileName, mimeType, fileSize);
        presignedUrlResponse = await getPresignedAdVideoUrl(
          fileName,
          mimeType,
          fileSize,
          sourceId
        );
      } else {
        throw new Error("Unsupported file type");
      }

      if (presignedUrlResponse) {
        const fileBlob = await fetch(previewUrl).then((r) => r.blob());
        await uploadFileToS3(
          presignedUrlResponse.presignedImgUrl ||
            presignedUrlResponse.presignedVideoUrl,
          fileBlob,
          async () => {
            // Successful upload, now handle success
            await handleUploadSuccess(fileDetails);
          },
          (error) => {
            console.error("Upload Error:", error);
            setBackendErrorMessage(
              "An error occurred during the upload process."
            );
            setLoading(false);
          }
        );
      }
    } catch (err) {
      console.error("Error during file upload process:", err);
      setBackendErrorMessage("An error occurred during the upload process.");
      setLoading(false);
    }
  };
  // Function to construct CloudFront URL based on file type
  const constructCloudFrontUrl = (fileName, sourceId, isVideo) => {
    const filePath = isVideo
      ? `videos/${sourceId}/${fileName}`
      : `images/${sourceId}/${fileName}`;
    return `https://${cloudFrontDomain}/${filePath}`;
  };

  // Function to handle upload success and record ad campaign attributes
  // Function to handle upload success and generate CloudFront URL
  const handleUploadSuccess = async (fileDetails) => {
    const cloudFrontUrl = constructCloudFrontUrl(
      fileDetails.fileName,
      fileDetails.sourceId,
      fileDetails.mimeType.startsWith("video/")
    );
    console.log("File uploaded successfully:", fileDetails.fileName);
    console.log("CloudFront URL:", cloudFrontUrl);

    // No additional ad campaign attributes are recorded here.
    setLoading(false);
  };
  const onPlaceOrder = async () => {
    // Validate terms agreement
    if (!agreeToTerms) {
      setTermsError(true); // Show error if terms are not agreed
      console.error("User did not agree to terms.");
      return; // Stop further execution
    }
    setTermsError(false); // Reset error if terms are agreed
    setAgreeToTerms(true);
    // Mark the function as async so I can use an await inside the function below to pass data to the next function
    // Convert grandTotal and other values into display format (dollars)
    const contextType = "createCampaign"; // Example context
    const grandTotalUI = parseFloat(orderGrandTotal).toFixed(2); // Ensure consistent decimal formatting
    const subtotalUI = parseFloat(orderSubtotal).toFixed(2);
    const totalDueUI = parseFloat(orderTotalDue).toFixed(2);

    let paymentMethodType = "";
    if (pointsToUse > 0 && totalDueUI === "0.00") {
      paymentMethodType = "points";
    } else if (pointsToUse > 0 && parseFloat(totalDueUI) > 0) {
      paymentMethodType = "splitPayment";
    } else if (pointsToUse === 0 && parseFloat(totalDueUI) > 0) {
      paymentMethodType = "card";
    } else {
      console.error("Invalid payment method type");
      return;
    }

    console.log("Payment Method Type:", paymentMethodType);

    localStorage.setItem(
      getStorageKey("paymentMethodType", contextType),
      paymentMethodType
    );

    console.log("Values stored in localStorage:", {
      grandTotal: grandTotalUI,
      orderSubtotal: subtotalUI,
      orderTotalDue: totalDueUI,
      pointsToUse,
      paymentMethodType,
    });

    // Skip unnecessary validations for "points, all fields should be empty from the slider function anyways"
    if (paymentMethodType === "points") {
      clearState(); // Reset selectedState and billingDetails
      clearAllBillingAndPaymentFields(); // Clear all other billing and payment fields
      setResetMonthDropdown(true); // Trigger dropdown reset flag, like pulling a trigger on the gun to fire which resets state to true
      setResetYearDropdown(true);
      console.log("Points-only payment. Skipping credit card validation...");
    } else {
      // Validate fields for card or split payment method types
      const isPaymentMethodValid = validatePaymentMethod(
        contextType,
        setErrors
      );
      const isCreditCardNumberValid = validateCreditCardNumber(
        contextType,
        setErrors
      );
      const isExpirationMonthValid = validateExpirationMonth(
        contextType,
        setErrors
      );
      const isExpirationYearValid = validateExpirationYear(
        contextType,
        setErrors
      );
      const isFirstNameValid = validateFirstName(contextType, setErrors);
      const isLastNameValid = validateLastName(contextType, setErrors);
      const isAddress1Valid = validateAddress1(contextType, setErrors);
      const isCityValid = validateCity(contextType, setErrors);
      const isPostalCodeValid = validatePostalCode(contextType, setErrors);
      const isCountryValid = validateCountry(contextType, setErrors);
      const isSelectedStateValid = validateSelectedState(
        contextType,
        setErrors
      );

      console.log("Validation Results:", {
        isPaymentMethodValid,
        isExpirationMonthValid,
        isExpirationYearValid,
        isCreditCardNumberValid,
        isFirstNameValid,
        isLastNameValid,
        isAddress1Valid,
        isCityValid,
        isPostalCodeValid,
        isCountryValid,
        isSelectedStateValid,
      });

      // Check if any validation failed
      if (
        !isPaymentMethodValid ||
        !isExpirationMonthValid ||
        !isExpirationYearValid ||
        !isCreditCardNumberValid ||
        !isFirstNameValid ||
        !isLastNameValid ||
        !isAddress1Valid ||
        !isCityValid ||
        !isPostalCodeValid ||
        !isCountryValid ||
        !isSelectedStateValid
      ) {
        console.log("Validation failed for one or more fields.");
        return;
      }

      console.log("Validation successful for all fields. Proceeding...");
    }
    handleFileUpload();
    // Pass calculated values to `processCampaignPayment`
    await processCampaignPayment({
      orderGrandTotal: grandTotalUI,
      orderSubtotal: subtotalUI,
      orderTotalDue: totalDueUI,
      enableAutoPay: agreeToTerms,
      pointsToUse,
      paymentMethodType,
      currentAvailablePoints,
    });
  };

  const processCampaignPayment = async ({
    orderGrandTotal,
    orderSubtotal,
    orderTotalDue,
    pointsToUse,
    paymentMethodType,
    currentAvailablePoints,
    enableAutoPay,
  }) => {
    try {
      console.log("Starting processCampaignPayment...");
      if (paymentMethodType === "points") {
        localStorage.setItem(
          getStorageKey("paymentMethod", "createCampaign"),
          "points"
        );
        localStorage.setItem(
          getStorageKey("paymentMethodType", "createCampaign"),
          "points"
        );
        console.log(
          "Payment method explicitly set to 'points' for points-only payment."
        );
      }

      // Step 1: Clear old localStorage values for calculated fields
      const keysToClear = [
        getStorageKey("orderGrandTotal", "createCampaign"),
        getStorageKey("orderSubtotal", "createCampaign"),
        getStorageKey("orderTotalDue", "createCampaign"),
      ];
      keysToClear.forEach((key) => localStorage.removeItem(key));

      // Step 2: Store UI-calculated values directly into localStorage
      localStorage.setItem(
        getStorageKey("orderGrandTotal", "createCampaign"),
        orderGrandTotal.toString() // Pass directly from the state without conversion
      );
      localStorage.setItem(
        getStorageKey("orderSubtotal", "createCampaign"),
        orderSubtotal.toString() // Pass directly from the state without conversion
      );
      localStorage.setItem(
        getStorageKey("orderTotalDue", "createCampaign"),
        orderTotalDue.toString() // Pass directly from the state without conversion
      );
      localStorage.setItem(
        getStorageKey("pointsToUse", "createCampaign"),
        pointsToUse.toString()
      );
      localStorage.setItem(
        getStorageKey("paymentMethodType", "createCampaign"),
        paymentMethodType
      );

      // Step 3: Retrieve and parse additional necessary data
      const adBlobString = localStorage.getItem("createCampaign_adBlob");
      if (!adBlobString)
        throw new Error("Missing adBlobString in localStorage.");

      const fileDetails = JSON.parse(adBlobString);
      if (!fileDetails.sourceId)
        throw new Error("sourceId missing in fileDetails.");

      const adCategoryPromoString = localStorage.getItem(
        "createCampaign_adCategoryPromo"
      );
      if (!adCategoryPromoString) throw new Error("Missing Ad Category Promo.");

      const adCategoryPromoArray = JSON.parse(adCategoryPromoString);

      const selectedCategoryString = localStorage.getItem(
        "createCampaign_selectedCategory"
      );
      const selectedCategory = selectedCategoryString
        ? JSON.parse(selectedCategoryString)
        : null;

      const rawCreditCardNumber = localStorage.getItem(
        "createCampaign_creditCardNumber"
      );
      const creditCardNumber = String(rawCreditCardNumber); // Ensure it's a string
      // Declare necessary variables outside the campaignData object
      const costPerImpression = parseFloat(
        localStorage.getItem(
          getStorageKey("costPerImpression", "createCampaign")
        )
      );
      const costPerClick = parseFloat(
        localStorage.getItem(getStorageKey("costPerClick", "createCampaign"))
      );
      const dailyCostLimit = parseFloat(
        localStorage.getItem(getStorageKey("dailyCostLimit", "createCampaign"))
      );
      const dailyClicksLimit = parseInt(
        localStorage.getItem(
          getStorageKey("dailyClicksLimit", "createCampaign")
        ),
        10
      );
      const dailyImpressionsLimit = parseInt(
        localStorage.getItem(
          getStorageKey("dailyImpressionsLimit", "createCampaign")
        ),
        10
      );
      const totalBudgetClicks = parseInt(
        localStorage.getItem(
          getStorageKey("totalBudgetClicks", "createCampaign")
        ),
        10
      );
      const totalBudgetImpressions = parseInt(
        localStorage.getItem(
          getStorageKey("totalBudgetImpressions", "createCampaign")
        ),
        10
      );

      // Step 4: Build campaign data
      const campaignData = {
        campaignName: localStorage.getItem("createCampaign_campaignName"),
        totalBudget: parseFloat(
          localStorage.getItem("createCampaign_totalBudget")
        ),
        paymentType: localStorage.getItem("createCampaign_paymentType"),
        campaignExpirationDate: new Date(
          localStorage.getItem("createCampaign_campaignExpirationDate")
        ),
        costPerImpression,
        costPerClick,
        dailyCostLimit,
        dailyClicksLimit,
        dailyImpressionsLimit,
        totalBudgetClicks,
        totalBudgetImpressions,
        costPerImpression: parseFloat(
          localStorage.getItem("createCampaign_costPerImpression")
        ),
        paymentSubscription: localStorage.getItem(
          "createCampaign_paymentSubscription"
        ),

        creditCardNumber,
        relationalSelectedCategoryId: selectedCategory?.categoryId || null,
        relationalSelectedCategoryName: selectedCategory?.categoryName || null,
        relationalAdPromoCategoryId:
          adCategoryPromoArray?.subcategoryId || null,
        relationalAdPromoName: adCategoryPromoArray?.subcategoryName || null,
        mediaAdUrl: `https://${cloudFrontDomain}/${
          fileDetails.mimeType.startsWith("video/") ? "videos" : "images"
        }/${fileDetails.sourceId}/${encodeURIComponent(fileDetails.fileName)}`,
        commercialType: fileDetails.mimeType.startsWith("video/")
          ? "video"
          : "image",
        campaignStatus: "live",
        endDate: new Date(
          localStorage.getItem("createCampaign_campaignExpirationDate")
        ),
        billingDetails: {
          billingFirstName: localStorage.getItem(
            "createCampaign_firstName"
          ),
          billingLastName: localStorage.getItem(
            "createCampaign_lastName"
          ),
          billingAddressLine1: localStorage.getItem(
            "createCampaign_address1"
          ),
          billingAddressLine2: localStorage.getItem(
            "createCampaign_address2"
          ),
          billingCity: localStorage.getItem("createCampaign_city"),
          billingState: localStorage.getItem(
            "createCampaign_selectedState"
          ),
          billingZipCode: localStorage.getItem(
            "createCampaign_postalCode"
          ),
          billingCountry: localStorage.getItem("createCampaign_country"),
        },
      };

      console.log("Campaign Data:", campaignData);

      // Step 5: Build payment data
      const paymentData = {
        orderGrandTotal,
        orderSubtotal,
        orderTotalDue,
        pointsToUse,
        currentAvailablePoints,
        enableAutoPay,
        paymentMethodType,
        paymentMethod:
          paymentMethodType === "points"
            ? "points"
            : localStorage.getItem(
                getStorageKey("paymentMethod", "createCampaign")
              ), // Explicitly set 'points' if paymentMethodType is 'points'
        sourceId: fileDetails.sourceId,
      };

      console.log("Payment Data:", paymentData);

      // Step 6: Combine into final payload
      const payload = {
        ...campaignData,
        ...paymentData,
      };
      console.log("Payload being sent to the server:", payload);

      // Step 7: API call
      const response = await apiCampaignPayment(payload);
      console.log("API Response:", response);
      if (response && response.success) {
        // Ensure 'response' exists and has 'success'
        console.log("Campaign payment processed successfully:", response);
      }
      setCampaignCreated(true); // Trigger state change
    } catch (error) {
      console.error("Error processing payment:", error.message);
    }
  };
  useEffect(() => {
    if (campaignCreated) {
      console.log("campaignCreated is true, invoking parent callback...");
      if (onResetStateOnCampaignSuccess) {
        onResetStateOnCampaignSuccess();
      }
    }
  }, [campaignCreated, onResetStateOnCampaignSuccess]);

  useEffect(() => {
    let calculatedImpressionsOrClicks = 0;

    // Calculate Impressions or Clicks based on the payment type
    if (paymentType === "impressions") {
      calculatedImpressionsOrClicks = Math.floor(
        totalBudget / costPerImpression
      );
    } else if (paymentType === "clicks") {
      calculatedImpressionsOrClicks = Math.floor(totalBudget / costPerClick);
    }

    setImpressionsOrClicks(calculatedImpressionsOrClicks);
    setSelectedPeriodName(periodChargeThresholdType[selectedPeriod] || ""); // Map period value to human-readable name
  }, [
    paymentType,
    totalBudget,
    costPerImpression,
    costPerClick,
    selectedPeriod,
  ]);

  const handleFetchUserPoints = async () => {
    try {
      const response = await apiFetchCurrentUserDetails(currentUser);
      const pointsBalance = response?.data?.currentPointsBalance || 0;

      setUserPointsBalance(pointsBalance); // Save backend points
      setCurrentAvailablePoints(pointsBalance); // Display total available points

      // If grandTotal is already calculated, update maxOrderPoints
      if (orderGrandTotal > 0) {
        const maxRedeemablePoints = Math.min(
          pointsBalance,
          Math.floor(orderGrandTotal * pointsPerDollar)
        );
        setPointsToUse(maxRedeemablePoints); // Set default points to max
      }
    } catch (error) {
      console.error("Error fetching user points:", error);
    }
  };

  const onPaymentMethodChange = (e) => {
    handlePaymentMethodChange(
      e,
      setPaymentMethod,
      setMerchantFee,
      contextType,
      getStorageKey
    );
  };

  useEffect(() => {
    handleFetchUserPoints();
  }, []);

  return (
    <div id="checkout" className="checkout-container" ref={checkoutRef}>
      <BillingForm
        billingDetails={billingDetails}
        setBillingDetails={setBillingDetails}
        onFieldChange={onFieldChange}
        paymentMethod={paymentMethod}
        onPaymentMethodChange={onPaymentMethodChange}
        isPaymentMethodOpen={isPaymentMethodOpen}
        setPaymentMethodOpen={setPaymentMethodOpen}
        isMonthDropdownOpen={isMonthDropdownOpen}
        setMonthDropdownOpen={setMonthDropdownOpen}
        errors={errors}
        expirationMonth={expirationMonth}
        setExpirationMonth={setExpirationMonth}
        expirationYear={expirationYear}
        setExpirationYear={setExpirationYear}
        resetMonthDropdown={resetMonthDropdown}
        resetYearDropdown={resetYearDropdown}
        onMonthDropdownReset={onMonthDropdownReset}
        onYearDropdownReset={onYearDropdownReset}
        creditCardNumber={creditCardNumber}
        setCreditCardNumber={setCreditCardNumber}
        selectedState={selectedState}
        clearState={clearState}
        setSelectedState={setSelectedState}
        isYearDropdownOpen={isYearDropdownOpen}
        setYearDropdownOpen={setYearDropdownOpen}
        isStateDropdownOpen={isStateDropdownOpen}
        setStateDropdownOpen={setStateDropdownOpen}
        yearOptions={yearOptions}
        contextType={contextType}
      />
      <PlaceOrderSummary
        orderSubtotal={orderSubtotal}
        agreeToTerms={agreeToTerms}
        setAgreeToTerms={setAgreeToTerms}
        termsError={termsError}
        merchantFee={merchantFee}
        orderGrandTotal={orderGrandTotal}
        pointsToUse={pointsToUse}
        maxOrderPoints={maxOrderPoints}
        setTermsError={setTermsError}
        currentAvailablePoints={currentAvailablePoints}
        pointsValueInCentsPerDollar={pointsValueInCentsPerDollar}
        userPointsBalance={userPointsBalance}
        onSliderChange={handleSliderChange}
        onPlaceOrder={onPlaceOrder}
      />
    </div>
  );
};
export default Checkout;
