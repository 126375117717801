import Axios, { REQUEST_METHOD, config } from "../axios"; // Adjust the import path as necessary

// Generating the Pre-signed URL: Using these details, the backend uses AWS SDK to generate
// a pre-signed URL for the S3 bucket where the file should be uploaded.
// The URL generation is based on the file name, MIME type, and intended operations
// (like PUT for upload).
export const getPresignedAdImgUrl = async (
  fileName,
  mimeType,
  fileSize,
  sourceId
) => {
  // Prepare the request payload
  const data = {
    fileName,
    mimeType,
    fileSize,
    sourceId, // Include the userId in the request payload
  };
  // Construct the request configuration, utilizing the custom Axios instance and REQUEST_METHOD
  try {
    const response = await Axios.fetch({
      url: "/uploads/generate-presigned-ad-img-url", // Ensure the endpoint matches your server's route
      method: REQUEST_METHOD.POST, // Using the POST method as defined in your REQUEST_METHOD object
      data, // Passing the payload
      headers: {
        "Content-Type": "application/json", // Setting the Content-Type as application/json
      },
    });
    // Log the response data for debugging
    console.log("Pre-signed URL generated:", response.data);
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error generating pre-signed URL:", error);
    throw error; // Propagate the error for handling upstream
  }
};

// (like PUT for upload).
export const getPresignedAdVideoUrl = async (
  fileName,
  mimeType,
  fileSize,
  sourceId
) => {
  // Prepare the request payload
  const data = {
    fileName,
    mimeType,
    fileSize,
    sourceId, // Add this line to include the userId in the request payload
  };
  // Construct the request configuration, utilizing the custom Axios instance and REQUEST_METHOD
  try {
    const response = await Axios.fetch({
      url: "/uploads/generate-presigned-ad-video-url", // Ensure the endpoint matches your server's route
      method: REQUEST_METHOD.POST, // Using the POST method as defined in your REQUEST_METHOD object
      data, // Passing the payload
      headers: {
        "Content-Type": "application/json", // Setting the Content-Type as application/json
      },
    });
    // Log the response data for debugging
    console.log("Pre-signed URL generated:", response.data);
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error generating pre-signed URL:", error);
    throw error; // Propagate the error for handling upstream
  }
};

export function apiRecordCampaignDatabase(campaignData) {
  console.log("ad data to record:", campaignData);
  return Axios.fetch({
    url: "/campaign/record-member-campaign", // Make sure the path is correct
    method: REQUEST_METHOD.POST,
    data: campaignData, // Correctly passing adData as the request payload
  });
}

export const apiFetchLiveVideoAdCampaigns = async (
  lastId = null,
  limit = 10
) => {
  try {
    const response = await Axios.fetch({
      url: "/campaign/fetch-live-video-ad-campaigns", // Fetch campaigns globally
      method: REQUEST_METHOD.GET,
      params: { lastId, limit }, // Use query params for pagination
    });

    return response.data.campaigns; // Extract campaigns from the response
  } catch (error) {
    console.error("Error fetching ad campaigns:", error);
    throw error;
  }
};

export default {
  apiFetchLiveVideoAdCampaigns,
  apiRecordCampaignDatabase,
  getPresignedAdImgUrl,
  getPresignedAdVideoUrl,
};
