// Corrected import statement
import Axios, { REQUEST_METHOD } from "../axios";

export function apiDeleteSkitIdFromSourceIdAction({ skitId }) {
  console.log("API call to delete skit with ID:", skitId); // Log skitId here
  return Axios.fetch({
    url: `skits/delete-skitid-from-sourceid/${skitId}`, // The skitId is interpolated into the URL so since it is dynamic put it at the end This way, the :skitId is a dynamic segment in the URL that will match the ID of the skit you want to delete.
    method: REQUEST_METHOD.DELETE,
  });
}

export function apiFetchSkitById({ skitId }) {
  return Axios.fetch({
    url: `skits/fetch-skitid/${skitId}`, // The skitId is interpolated into the URL so since it is dynamic put it at the end This way, the :skitId is a dynamic segment in the URL that will match the ID of the skit you want to delete.
    method: REQUEST_METHOD.GET,
  });
}
export const fetchRatedSkitsForUser = async (userId) => {
  try {
    const response = await Axios.fetch({
      url: `user/${userId}/rated-skits`,
      method: REQUEST_METHOD.GET,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch rated skits:", error);
    throw error;
  }
};
export const apiFetchRatedSkitsForCurrentUserDashboard = async (targetId) => {
  try {
    const response = await Axios.fetch({
      url: `activity/${targetId}/rated-skits`,
      method: REQUEST_METHOD.GET,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch rated skits:", error);
    throw error;
  }
};

export function apiFetchTrendingSkits(offset = 0, limit = 6, categoryId) {
  return Axios.fetch({
    url: "skits/trending-skits",
    method: REQUEST_METHOD.GET,
    params: { offset, limit, categoryId },
  });
}

export function apiFetchPopularSkits(offset = 0, limit = 6, categoryId) {
  return Axios.fetch({
    url: "skits/popular-skits",
    method: REQUEST_METHOD.GET,
    params: { offset, limit, categoryId },
  });
}
export function apiRewardSkitSubscriberPromo({ sourceId, targetId }) {
  return Axios.fetch({
    url: `/activity/subscribe/${sourceId}`,
    method: REQUEST_METHOD.POST,
    data: { targetId },
  });
}

export function apiFetchSkitsForSkitsListingPage(offset = 0, limit = 6, categoryId = null) {
  return Axios.fetch({
    url: "skits/skits-listing-page",
    method: REQUEST_METHOD.GET,
    params: { offset, limit, categoryId },
  });
}

export default {
  apiFetchPopularSkits,
  apiFetchTrendingSkits,
  apiFetchSkitsForSkitsListingPage,
  apiRewardSkitSubscriberPromo,
  apiFetchRatedSkitsForCurrentUserDashboard,
  apiDeleteSkitIdFromSourceIdAction,
  apiFetchSkitById,
};
