import React from "react"; // Import React library
import { FormType } from "../../../constants/contextState"; // Import FormType constants
import {
  CampaignAdModal,
  CategoryNameFormatSearch,
  RelationalCategoryMenuPopup,
  CampaignAmount,
  Checkout,
} from "../index"; // Import the components used in the form

// Purpose: This component handles the rendering of different forms 
// based on the formType and currentForm props. 
// It manages the flow for creating or updating campaigns.


const CampaignStateManagerFlowForms = ({
  currentForm, // Current form to display
  setCurrentForm, // Function to set the current form
  formType, // Type of the form being displayed
  setFormType, // Function to set the form type
  updateCategoryNameFormatSearch, // Update function for CategoryNameFormatSearch form
  updateCampaignAdModal, // Update function for CampaignAdModal form
  updateRelationalCategoryMenuPopup, // Update function for RelationalCategoryMenuPopup form
  updateCampaignAmount, // Update function for CampaignAmount form
  cancelCategoryBackToParentCat, // Function to cancel and go back to parent category
  onResetStateOnCampaignSuccess, //call back to the parent to clear state
  categories,
  handleSubcategoryClick, // Handles actions when a subcategory is clicked.
  handlePromoteAllClick,
  subCatMenuData,
  contextType,
  setContextType,
  updateCampaign,
  setErrorMessage,
}) => {

  return (
    <div>
      {/* Display CampaignAdModal form if formType and currentForm match */}
      {formType === FormType.CampaignAdModal &&
        currentForm === FormType.CampaignAdModal && (
          <CampaignAdModal
            setCurrentForm={setCurrentForm}
            formType={formType}
            setFormType={setFormType}
            onSave={updateCampaignAdModal}
            contextType={contextType}
            setContextType={setContextType}
            updateCampaign={updateCampaign}
          />
        )}
      {/* Display CategoryNameFormatSearch form if formType and currentForm match */}
      {formType === FormType.CategoryNameFormatSearch &&
        currentForm === FormType.CategoryNameFormatSearch && (
          <div className="CategoryContainer">
            {categories.map((category, index) => (
              <CategoryNameFormatSearch
                key={index}
                categoryName={category.name}
                setCurrentForm={setCurrentForm}
                formType={formType}
                setFormType={setFormType}
                contextType={contextType}
                setContextType={setContextType}
                categoryId={category.id}
                handleCategoryClick={updateCategoryNameFormatSearch}
              />
            ))}
          </div>
        )}
      {/* Display RelationalCategoryMenuPopup form if formType and currentForm match */}
      {formType === FormType.RelationalCategoryMenuPopup &&
        currentForm === FormType.RelationalCategoryMenuPopup && (
          <RelationalCategoryMenuPopup
          onCancelCategory={cancelCategoryBackToParentCat}
          subCatMenuData={subCatMenuData}
          handleSubcategoryClick={handleSubcategoryClick} // Pass this correctly
          handlePromoteAllClick={handlePromoteAllClick}
          setCurrentForm={setCurrentForm}
          formType={formType}
          setFormType={setFormType}
          contextType={contextType}
          setContextType={setContextType}
          updateRelationalCategoryMenuPopup={updateRelationalCategoryMenuPopup}
          />
        )}
      {/* Display CampaignAmount form if formType and currentForm match */}
      {formType === FormType.CampaignAmount &&
        currentForm === FormType.CampaignAmount && (
          <CampaignAmount
            setCurrentForm={setCurrentForm}
            formType={formType}
            setFormType={setFormType}
            contextType={contextType}
            setContextType={setContextType}
            onSaveAmount={updateCampaignAmount} // Correct prop passed
            setErrorMessage={setErrorMessage}
          />
        )}
      {/* Display Checkout form if formType and currentForm match */}
      {formType === FormType.Checkout && currentForm === FormType.Checkout && (
        <Checkout
          setCurrentForm={setCurrentForm}
          formType={formType}
          setFormType={setFormType}
          contextType={contextType}
          setContextType={setContextType}
          onResetStateOnCampaignSuccess={onResetStateOnCampaignSuccess}
        />
      )}
    </div>
  );
};
// Export the component
export default CampaignStateManagerFlowForms;
