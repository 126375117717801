import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import {
  apiSigninUsername,
  apiSigninMobile,
  apiSigninEmail,
} from "../../api/backend/auth";
import { useAuth } from "../context/authContext";
import { formatPhoneNumber } from "../../utils/formatNumber.js";
import ToolTip from "../tooltip/tooltip";
import Loader from "../loader/loader";
import { errorMessages } from "../../constants/errorMessages.js";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import {
  StripeAnimationBtn,
  InsetButton,
  ImgTopBtn,
  FieldButton,
} from "../buttons";
import {
  LineIntersectingCircleIcon,
  PeopleIcon,
  SkitsOlympicLogoIcon,
  EyeSlashIcon,
  EyeIcon,
  PaperPlaneIcon,
  CellPhoneIcon,
} from "../icons";
import KeyLockIcon from "../keyLockIconSwitches/keyLockIconSwitches.jsx";
import BlinkingLight from "../blinkingLight/blinkingLight";
import {
  signinPasswordWatcher,
  onSignInFocusPassword,
  onSignInBlurPassword,
  userNameInputWatcher,
  phoneInputWatcher,
  emailInputWatcher,
  resetFormState,
} from "../../controller/watcherAndClearFunctions/signinWatcherFunctions";
import { validatePasswordValue } from "../../constants/validate";
import "react-phone-input-2/lib/material.css";
import "./loginForm.scss";

const LoginForm = ({
  onSignUpClick,
  onResetPasswordClick,
  onVerifyAccount,
  setContextType,
  contextType = "signIn",
}) => {
  const navigate = useNavigate(); // Hook for navigation
  const { setUserInfo } = useAuth();
  // Initialize currentContext state based on contextType prop
  const [currentContext, setCurrentContext] = useState(
    contextType === "signIn" ? "signIn" : "completeSignup"
  ); // Determine current context
  const [showSigninPassword, setShowSigninPassword] = useState(false);
  const [signinPasswordFocused, setSigninPasswordFocused] = useState(false);
  const [passwordValidationRules, setPasswordValidationRules] = useState([]);
  const [credentialValueError, setCredentialValueError] = useState("");
  const [toolTipVisible, setToolTipVisible] = useState(false);
  const [backendSigninError, setBackendSigninError] = useState("");
  const [signInPasswordError, setSignInPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [credentialChoice, setCredentialChoice] = useState("username");
  const [userNameFocused, setUserNameFocused] = useState(false); // Track focus state for username
  const [emailFocused, setEmailFocused] = useState(false); // Track focus state for username
  const [phoneNumberFocused, setPhoneNumberFocused] = useState(false); // Track focus state for username
  const [password, setPassword] = useState(
    localStorage.getItem(getStorageKey("passwordValue", currentContext)) || ""
  );
  const [userNameValue, setUserNameValue] = useState(
    localStorage.getItem(getStorageKey("userNameValue", currentContext)) || ""
  );
  const [emailValue, setEmailValue] = useState(
    localStorage.getItem(getStorageKey("emailValue", currentContext)) || ""
  );
  const [phoneNumberValue, setPhoneNumberValue] = useState(
    localStorage.getItem(getStorageKey("verifyAccountValue", currentContext)) ||
      ""
  );
  localStorage.setItem(
    getStorageKey("credentialChoice", currentContext),
    credentialChoice
  );

  useEffect(() => {
    localStorage.setItem(
      getStorageKey("credentialChoice", currentContext),
      credentialChoice
    );
    if (credentialChoice === "username") {
      localStorage.removeItem(
        getStorageKey("verifyAccountValue", currentContext)
      );
      localStorage.removeItem(getStorageKey("emailValue", currentContext));
    } else if (credentialChoice === "email") {
      localStorage.removeItem(
        getStorageKey("verifyAccountValue", currentContext)
      );
      localStorage.removeItem(getStorageKey("userNameValue", currentContext));
    } else if (credentialChoice === "phone") {
      localStorage.removeItem(getStorageKey("emailValue", currentContext));
      localStorage.removeItem(getStorageKey("userNameValue", currentContext));
    }
  }, [credentialChoice, currentContext]);

  const validateForm = () => {
    let valid = true;
    console.log("Validating form...");
    // Check for empty fields based on the credential choice
    if (
      (credentialChoice === "username" && !userNameValue) ||
      (credentialChoice === "phone" && !phoneNumberValue) ||
      (credentialChoice === "email" && !emailValue)
    ) {
      // Set the error message based on the credential choice
      setCredentialValueError(errorMessages[credentialChoice]);
      console.log(
        "Setting credentialValueError:",
        errorMessages[credentialChoice]
      );
      valid = false;
    } else {
      setCredentialValueError(""); // Clear error if the input is valid
      console.log("Clearing credentialValueError.");
    }
    // Additional validation for phone number if the user chose phone as their credential
    if (credentialChoice === "phone") {
      let formattedPhoneNumber = formatPhoneNumber(phoneNumberValue); // Format the phone number as needed
      // Remove non-digit characters
      formattedPhoneNumber = formattedPhoneNumber.replace(/\D/g, "");
      console.log("Formatted phone number:", formattedPhoneNumber);
      // Check if the phone number has exactly 10 digits after formatting
      // Check if the phone number is provided and has less than 10 digits
      if (phoneNumberValue && formattedPhoneNumber.length < 10) {
        setCredentialValueError("Please enter a valid 10-digit phone number.");
        console.log(
          "Setting credentialValueError: Please enter a valid 10-digit phone number."
        );
        valid = false;
      } else if (phoneNumberValue && formattedPhoneNumber.length === 10) {
        setCredentialValueError(""); // Clear error if the input is valid
        console.log(
          "Clearing credentialValueError after phone number validation."
        );
      }
    }
    // Validate the password field
    if (!password) {
      setSignInPasswordError("Password is Required");
      console.log("Setting signInPasswordError: Password is Required");
      valid = false;
    } else {
      const passwordValidationResults = validatePasswordValue(password);
      setPasswordValidationRules(passwordValidationResults);
      const passwordIsValid = passwordValidationResults.every(
        (rule) => rule.isValid
      );
      if (!passwordIsValid) {
        setToolTipVisible(true); // Show tooltip if password is invalid
        console.log("Password is not valid, showing tooltip.");
        valid = false;
      } else {
        setSignInPasswordError("");
        console.log("Password is valid, clearing signInPasswordError.");
      }
    }
    console.log("Validation result:", valid);
    return valid; // Only return true if all validations pass
  };

  // Handle login success based on whether the signup is complete or not
  const handleLoginSuccess = (user) => {
    console.log("Handling login success. User:", user); // Log the user object
    if (user && user.signupCompleted) {
      console.log("Signup is complete. User ID:", user.id);
      setUserInfo(user.id); // Update context with user ID this sets it into cookies from the auth componet setter function
      // Clear local storage after successful sign-in
      localStorage.removeItem(
        getStorageKey("credentialChoice", currentContext)
      );
      localStorage.removeItem(
        getStorageKey("verifyAccountValue", currentContext)
      );
      localStorage.removeItem(getStorageKey("emailValue", currentContext));
      localStorage.removeItem(getStorageKey("passwordValue", currentContext));
      localStorage.removeItem(getStorageKey("userNameValue", currentContext));
      navigate("/skitslist"); // Redirect on success
    } else {
      console.warn(
        "Signup is incomplete. User ID:",
        user ? user.id : "No user ID"
      );
      if (user && user.id) {
        console.log("Triggering verification process for user ID:", user.id);
        onVerifyAccount(user, "completeSignup"); // Trigger verification process with user object
        setContextType("completeSignup"); // Set context for incomplete signup
        setCurrentContext("completeSignup"); // Update the current context
      } else {
        console.error("User object is not provided or userId is missing.");
      }
    }
  };

  const signInUser = async (e) => {
    e.preventDefault(); // Prevent default form action
    console.log("Starting signInUser...");
    if (!validateForm()) {
      console.log("Validation failed, exiting signInUser.");
      return;
    }
    setLoading(true);
    setBackendSigninError(""); // Clear backend error before making the request
    console.log("Making backend request...");

    try {
      let response;
      if (credentialChoice === "username") {
        response = await apiSigninUsername(userNameValue, password);
      } else if (credentialChoice === "phone") {
        response = await apiSigninMobile(
          formatPhoneNumber(phoneNumberValue),
          password
        );
      } else if (credentialChoice === "email") {
        response = await apiSigninEmail(emailValue, password);
      }
      console.log("API Response:", response); // Log the entire response object
      if (response.status === 200 && response.data.status === "OK") {
        console.log(
          "Login successful, handling success with user:",
          response.data.user
        );
        handleLoginSuccess(response.data.user); // Pass the mapped user object
      } else if (response.data.status === "IncompleteSignup") {
        console.log(
          "Incomplete signup, handling with user:",
          response.data.user
        );
        handleLoginSuccess(response.data.user); // Handle incomplete signup scenario
      } else {
        console.error("Login failed with message:", response.data.message);
        setBackendSigninError(response.data.message); // Display the error message
      }
    } catch (error) {
      console.error("Login request failed", error);
      setBackendSigninError(error.response?.data?.message || "Login failed"); // Set the backend error message
    } finally {
      setLoading(false);
    }
  };
  // Function to handle credential change
  const handleCredentialChange = (choice) => {
    resetFormState(
      setUserNameValue,
      setPhoneNumberValue,
      setEmailValue,
      setCredentialValueError,
      setUserNameFocused,
      setPhoneNumberFocused,
      setEmailFocused
    );
    setCredentialChoice(choice); // Set the new credential method
  };

  return (
    <div id="SignInForm" className="client-form">
      <form>
        <div id="logo-header">
          <SkitsOlympicLogoIcon fillColor="var(--color-darkGold)" />
          <h3 className="logo-header">Skits Olympic</h3>
        </div>
        <div>
          <div className="centered-header responsiveBtnText">
            <h3>Select login method:</h3>
          </div>
          <div className="credential-button-container">
            <StripeAnimationBtn
              stripeAnimationBtnText="Username"
              stripeAnimationBtnImage={
                <PeopleIcon
                  fillColor={
                    credentialChoice === "username" ? "black" : "#b03a2e"
                  }
                  className="marginRightTenPx"
                />
              }
              className={`credential-button ${
                credentialChoice === "username" ? "active" : ""
              }`}
              textClassName="responsiveBtnText"
              extraStyles={
                credentialChoice === "username"
                  ? {
                      width: "150px",
                      color: "black",
                      cursor: "not-allowed",
                      backgroundColor: "rgb(216, 217, 219)",
                      fontSize: "1.25rem",
                      lineHeight: "14px",
                      fontWeight: "700",
                      fontFamily: "Roboto, sans-serif",
                      borderRadius: "30px",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30px",
                      padding: "5px",
                    }
                  : {
                      width: "150px",
                      color: "rgb(176, 58, 46)",
                      textShadow: "black 0px 0px",
                      fontSize: "1.25rem",
                      lineHeight: "14px",
                      fontWeight: "700",
                      fontFamily: "Roboto, sans-serif",
                      textDecoration: "none",
                      borderWidth: "1.5px",
                      borderStyle: "solid",
                      borderColor: "var(--color-gold)",
                      borderRadius: "30px",
                      whiteSpace: "nowrap",
                      backgroundImage:
                        "linear-gradient(0deg, rgb(216, 217, 219) 0px, rgb(255, 255, 255) 80%, rgb(253, 253, 253))",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      height: "30px",
                      padding: "5px",
                    }
              }
              onClick={() => handleCredentialChange("username")}
            />
            <StripeAnimationBtn
              stripeAnimationBtnText="Mobile"
              stripeAnimationBtnImage={
                <CellPhoneIcon
                  fillColor={credentialChoice === "phone" ? "black" : "#b03a2e"}
                  className="marginRightTenPx"
                />
              }
              className={`credential-button ${
                credentialChoice === "phone" ? "active" : ""
              }`}
              textClassName="responsiveBtnText"
              extraStyles={
                credentialChoice === "phone"
                  ? {
                      width: "150px",
                      color: "black",
                      cursor: "not-allowed",
                      backgroundColor: "rgb(216, 217, 219)",
                      fontSize: "1.25rem",
                      lineHeight: "14px",
                      fontWeight: "700",
                      fontFamily: "Roboto, sans-serif",
                      borderRadius: "30px",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30px",
                      padding: "5px",
                    }
                  : {
                      width: "150px",
                      color: "rgb(176, 58, 46)",
                      textShadow: "black 0px 0px",
                      fontSize: "1.25rem",
                      lineHeight: "14px",
                      fontWeight: "700",
                      fontFamily: "Roboto, sans-serif",
                      textDecoration: "none",
                      borderWidth: "1.5px",
                      borderStyle: "solid",
                      borderColor: "var(--color-gold)",
                      borderRadius: "30px",
                      whiteSpace: "nowrap",
                      backgroundImage:
                        "linear-gradient(0deg, rgb(216, 217, 219) 0px, rgb(255, 255, 255) 80%, rgb(253, 253, 253))",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      height: "30px",
                      padding: "5px",
                    }
              }
              onClick={() => handleCredentialChange("phone")}
            />
            <StripeAnimationBtn
              stripeAnimationBtnText="Email"
              stripeAnimationBtnImage={
                <PaperPlaneIcon
                  fillColor={credentialChoice === "email" ? "black" : "#b03a2e"}
                  className="marginRightTenPx"
                />
              }
              className={`credential-button ${
                credentialChoice === "email" ? "active" : ""
              }`}
              textClassName="responsiveBtnText"
              extraStyles={
                credentialChoice === "email"
                  ? {
                      width: "150px",
                      color: "black",
                      cursor: "not-allowed",
                      backgroundColor: "rgb(216, 217, 219)",
                      fontSize: "1.25rem",
                      lineHeight: "14px",
                      fontWeight: "700",
                      fontFamily: "Roboto, sans-serif",
                      borderRadius: "30px",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30px",
                      padding: "5px",
                    }
                  : {
                      width: "150px",
                      color: "rgb(176, 58, 46)",
                      textShadow: "black 0px 0px",
                      fontSize: "1.25rem",
                      lineHeight: "14px",
                      fontWeight: "700",
                      fontFamily: "Roboto, sans-serif",
                      textDecoration: "none",
                      borderWidth: "1.5px",
                      borderStyle: "solid",
                      borderColor: "var(--color-gold)",
                      borderRadius: "30px",
                      whiteSpace: "nowrap",
                      backgroundImage:
                        "linear-gradient(0deg, rgb(216, 217, 219) 0px, rgb(255, 255, 255) 80%, rgb(253, 253, 253))",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      height: "30px",
                      padding: "5px",
                    }
              }
              onClick={() => handleCredentialChange("email")}
            />
          </div>
          <div className="input-grid2 input-field">
            <div className="input-group-with-tooltip">
              <div className="input-group blinker-wrapper">
                {credentialChoice === "username" && (
                  <div className="input-wrapper">
                    <input
                      id="userName"
                      autoComplete="off"
                      type="text"
                      placeholder={
                        credentialValueError
                          ? credentialValueError
                          : "...Enter username here"
                      }
                      className={`${userNameFocused ? "focused" : ""} ${
                        credentialValueError
                          ? "required-placeholder border-error field-background-color-error"
                          : ""
                      }`}
                      value={userNameValue}
                      onChange={(e) =>
                        userNameInputWatcher(
                          e.target.value,
                          setUserNameValue,
                          "signIn", // current context
                          credentialValueError,
                          setCredentialValueError
                        )
                      }
                      onFocus={() => setUserNameFocused(true)}
                      onBlur={() => setUserNameFocused(false)}
                    />
                    {userNameValue && (
                      <FieldButton
                        onClick={() => {
                          setUserNameValue(""); // Clear the username input field in the state

                          // Explicitly remove the item from local storage
                          const usernameStorageKey = getStorageKey(
                            "userNameValue",
                            "signIn"
                          ); // Using "signIn" as the context
                          localStorage.removeItem(usernameStorageKey); // Remove the username from local storage
                        }}
                      >
                        Clear
                      </FieldButton>
                    )}
                    {!(userNameFocused || userNameValue) && (
                      <>
                        <PeopleIcon
                          className="input-icon"
                          fillColor="var(--color-white)"
                          style={{
                            opacity: 0.68,
                            transition: "opacity 0.2s ease-in-out",
                          }}
                        />
                        <span className="blinking-cursor-black-color login-username-cursor">
                          |
                        </span>
                      </>
                    )}
                  </div>
                )}
                {credentialChoice === "phone" && (
                  <>
                    <div
                      className="input-wrapper"
                      style={{ position: "relative" }}
                    >
                      <PhoneInput
                        id="signInMobile"
                        type="text"
                        country={"us"}
                        onlyCountries={["us"]}
                        disableDropdown={true}
                        autoComplete="off"
                        placeholder={
                          credentialValueError
                            ? credentialValueError
                            : phoneNumberFocused ||
                                (phoneNumberValue && phoneNumberValue !== "1")
                              ? ""
                              : "...Enter Phone Number Here"
                        }
                        className={`${phoneNumberFocused ? "focused" : ""} ${
                          credentialValueError ? "required-placeholder" : ""
                        }`}
                        value={phoneNumberValue}
                        onChange={(value) =>
                          phoneInputWatcher(
                            value,
                            setPhoneNumberValue,
                            "signIn", // current context
                            credentialValueError,
                            setCredentialValueError
                          )
                        }
                        inputClass={`control-phonelibrary-placeholder ${
                          credentialValueError
                            ? "custom-required-placeholder-overide-phonelibrary-style field-background-color-error"
                            : "control-phonelibrary-placeholder"
                        } ${phoneNumberFocused ? "focused" : ""}`}
                        inputStyle={{
                          width: "100%",
                          borderColor: credentialValueError ? "red" : "",
                        }}
                        onFocus={() => setPhoneNumberFocused(true)}
                        onBlur={() => setPhoneNumberFocused(false)}
                        disableCountryCode={true} // Disable the country code
                      />
                      {phoneNumberValue && (
                        <FieldButton
                          onClick={() => {
                            setPhoneNumberValue(""); // Clear the phone number input field in the state

                            // Explicitly remove the item from local storage
                            const phoneStorageKey = getStorageKey(
                              "verifyAccountValue",
                              "signIn"
                            ); // Using "signIn" as the context
                            localStorage.removeItem(phoneStorageKey); // Remove the phone number from local storage
                          }}
                        >
                          Clear
                        </FieldButton>
                      )}
                      {!(phoneNumberFocused || phoneNumberValue) && (
                        <>
                          <CellPhoneIcon
                            fillColor="var(--color-white)"
                            style={{
                              opacity: 0.68,
                              transition: "opacity 0.2s ease-in-out",
                              position: "absolute",
                              left: "50px", // Position relative to the input field
                              top: "50%",
                              transform: "translateY(-50%)", // Center the icon vertically
                            }}
                          />
                          <span
                            className="blinking-cursor-black-color sigin-mobile-cursor"
                            style={{
                              position: "absolute",
                              left: "77px", // Position relative to the input field
                              top: "50%",
                              transform: "translateY(-50%)", // Center the cursor vertically
                            }}
                          >
                            |
                          </span>
                        </>
                      )}
                    </div>
                  </>
                )}
                {credentialValueError &&
                  credentialValueError !== errorMessages.phone &&
                  credentialValueError !== errorMessages.email &&
                  credentialValueError !== errorMessages.username && (
                    <div className="signin-error-message">
                      {credentialValueError}
                    </div>
                  )}

                {credentialChoice === "email" && (
                  <>
                    <div className="input-wrapper">
                      <input
                        id="userEmail"
                        autoComplete="off"
                        type="email"
                        placeholder={
                          credentialValueError
                            ? credentialValueError
                            : "...Enter Email Here"
                        }
                        className={`${emailFocused ? "focused" : ""} ${
                          credentialValueError
                            ? "required-placeholder border-error field-background-color-error"
                            : ""
                        }`}
                        value={emailValue}
                        onChange={(e) =>
                          emailInputWatcher(
                            e.target.value,
                            setEmailValue,
                            "signIn", // current context
                            credentialValueError,
                            setCredentialValueError
                          )
                        } // Update state on change// Update state on change
                        onFocus={() => setEmailFocused(true)} // Set focused state on focus
                        onBlur={() => setEmailFocused(false)} // Unset focused state on blur
                      />
                      {emailValue && (
                        <FieldButton
                          onClick={() => {
                            setEmailValue(""); // Clear the email input field in the state
                            // Explicitly remove the item from local storage
                            const emailStorageKey = getStorageKey(
                              "emailValue",
                              "signIn"
                            ); // Using "signIn" as the context
                            localStorage.removeItem(emailStorageKey); // Remove the email from local storage
                          }}
                        >
                          Clear
                        </FieldButton>
                      )}
                      {!(emailFocused || emailValue) && (
                        <>
                          <PaperPlaneIcon
                            className="input-icon"
                            fillColor="var(--color-white)"
                            style={{
                              opacity: 0.68,
                              transition: "opacity 0.2s ease-in-out",
                            }}
                          />
                          <span className="blinking-cursor-black-color signup-username-cursor">
                            |
                          </span>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="blinker-wrapper">
            <div className="input-grid2 input-group input">
              <input
                type={showSigninPassword ? "text" : "password"}
                id="signInPassword"
                autoComplete="off"
                placeholder={
                  signInPasswordError
                    ? signInPasswordError
                    : "...Enter Password Here"
                }
                className={`${signinPasswordFocused ? "focused" : ""} ${
                  signInPasswordError
                    ? "required-placeholder border-error field-background-color-error"
                    : ""
                }`}
                value={password} // Bind input to signInPassword state
                onChange={(e) => {
                  setBackendSigninError("");
                  signinPasswordWatcher(
                    e.target.value,
                    setPassword,
                    currentContext,
                    validatePasswordValue,
                    setPasswordValidationRules,
                    setToolTipVisible,
                    signInPasswordError,
                    setSignInPasswordError
                  );
                }}
                onFocus={() =>
                  onSignInFocusPassword(
                    password,
                    setSigninPasswordFocused,
                    setPasswordValidationRules,
                    setToolTipVisible
                  )
                }
                onBlur={() =>
                  onSignInBlurPassword(
                    setSigninPasswordFocused,
                    setToolTipVisible
                  )
                }
              />
              {!(signinPasswordFocused || password) && (
                <>
                  <KeyLockIcon
                    className="lock-icon"
                    fillColor="var(--color-white)"
                    locked={!showSigninPassword}
                    style={{
                      opacity: 0.68,
                      transition: "opacity 0.2s ease-in-out",
                    }}
                  />
                  <span className="blinking-cursor-black-color login-password-cursor">
                    |
                  </span>
                </>
              )}
            </div>
            {password && (
              <div
                className="show-hide-password-toggle"
                onClick={() => setShowSigninPassword((prev) => !prev)}
                style={{
                  position: "absolute",
                  right: "10px", // Position it on the right inside the input field
                  top: "50%",
                  transform: "translateY(-50%)", // Center it vertically
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {showSigninPassword ? (
                  <EyeSlashIcon width="20" height="15" />
                ) : (
                  <EyeIcon width="20" height="15" />
                )}
                <span className="show-hide-label" style={{ marginLeft: "5px" }}>
                  {showSigninPassword ? "Hide" : "Show"}
                </span>
              </div>
            )}
            {(signinPasswordFocused ||
              signInPasswordError ||
              toolTipVisible) && ( // tooltip visible will be applied on the event listener for sign in, user will see it if criteria is not met.
              <ToolTip
                rules={passwordValidationRules}
                toolTipVisible={toolTipVisible}
                setToolTipVisible={setToolTipVisible}
              />
            )}
            {backendSigninError && (
              <div className="signin-error-message">{backendSigninError}</div>
            )}
          </div>
        </div>
        <ImgTopBtn
          image={<LineIntersectingCircleIcon />}
          imgTopBtnWidth="100%"
          imgTopBtnTextFontSize="1.3rem"
          imgTopBtnFontColor="#b03a2e"
          imgTopBtnText="Log In to Televise "
          onClick={(e) => signInUser(e)} // Pass the event to the handler
        />
        {loading && <Loader className="login-loader-position" />}
        <div className="seprator-icon">
          <div className="and-or horizontal-bar vertical-icon">
            <span>OR</span>
          </div>
        </div>
        <ImgTopBtn
          image={
            <BlinkingLight
              blinkerLightStyles={{
                marginRight: "10px", // Or 'left: "10px"' for left positioning
              }}
            />
          }
          imgTopBtnWidth="100%"
          imgTopBtnFontColor="#b03a2e"
          imgTopBtnTextFontSize="1.3rem"
          imgTopBtnText="Create a New Account"
          onClick={() => {
            // localStorage.clear();
            onSignUpClick();
          }}
        />
        <InsetButton
          btnText="Reset Password"
          className="signup-btn"
          style={{
            width: "190px",
            fontSize: "1.3rem",
          }}
          onClick={() => {
            localStorage.clear();
            onResetPasswordClick();
          }}
        />
      </form>
    </div>
  );
};

export default LoginForm;
