import React from "react";
import { useNavigate } from "react-router-dom";
import { MemberSlider, ImgTopBtn } from "../buttons";
import { EyeSlashIcon } from "../icons";

const SubscribersList = ({ subscribers, hasMore, handleViewMore, loading, message }) => {
  const navigate = useNavigate();

  const handleProfileThumbnailSlideEnd = (userId) => {
    if (userId) {
      console.log("Navigating to user profile:", userId);
      navigate(`/user-profile/${userId}`);
    } else {
      console.log("User ID is undefined, not navigating.");
    }
  };

  if (message && !subscribers.length) return <div>{message}</div>;

  return (
    <div>
      {subscribers.map((subscriber) => (
        <div key={subscriber.source.id} className="subscriber-info">
          <MemberSlider
            user={subscriber.source}
            slideBtnText="Slide to View Profile"
            onHandleSlideEnd={() => {
              console.log("User ID being passed:", subscriber.source.id);
              handleProfileThumbnailSlideEnd(subscriber.source.id);
            }}
          />
          <div className="user-details">
            <span className="username">{subscriber.source.username}</span>
          </div>
        </div>
      ))}
      {hasMore && !loading && (
        <ImgTopBtn
          className="view-more-btn"
          imgTopBtnBorderRadius="25px"
          imgTopBtnWidth="fit-content"
          imgTopBtnTextFontSize="1.3rem"
          imgTopBtnFontFamily="Roboto-Slab"
          imgTopBtnFontWeight="900"
          imgTopBtnFontColor="var(--color-bloodred)"
          paddingRight="5px"
          paddingLeft="5px"
          extraImgTopBtnStyles={{
            margin: "20px auto",
          }}
          onClick={handleViewMore}
          imgTopBtnText="View More Subscribers"
          image={<EyeSlashIcon fillColor="var(--color-bloodred)" />}
        />
      )}
    </div>
  );
};
export default SubscribersList;
