import { useState, useEffect } from "react";
import { useAuth } from "../context/authContext";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator"; // For unique storage keys
import PhoneInput from "react-phone-input-2"; // Import if using a phone input library
import {
  emailFieldWatcher,
  usernameFieldWatcher,
  phoneFieldWatcher,
  onUserNameFocus,
  onUserNameBlur,
} from "../../controller/watcherAndClearFunctions/accountHolderUpdateClearsWatcherFn";
import {
  handleEmailSubmit,
  handleUsernameSubmit,
  handlePhoneNumberSubmit,
} from "../../controller/errorHandlers/accountHolderUpdateErrorHandler";
import { apiDeleteProfileImage } from "../../api/backend/auth";
import { getImageFileType, resizeFile } from "../../constants/fileUpload";
import { checkFile } from "../../utils/checkFile";
import { formatPhoneNumber } from "../../utils/formatNumber";
import { validateUserNameValue } from "../../constants/validate.js";
import {
  apiRecordThumbnailEmotionconId,
  getPresignedImgUrl,
  apiRecordProfileImageUrlCrud,
  apiRecordThumbnailPreference,
} from "../../api/backend/user";
import { uploadFileToS3 } from "../../api/backend/uploadToS3";
import ToolTip from "../tooltip/tooltip";
import EmotionEmoji from "../emoji/emoji";
import {
  SmallPlainInsetBtn,
  InsetBlendTVButton,
  StripeAnimationBtn,
  GlassBtn,
  FieldButton,
} from "../buttons";
import RadioHeadBtn from "../../components/radioHead/radioHead";
import {
  XIcon,
  SearchGlassIcon,
  CurveUpArrow,
  PeopleIcon,
  PhoneIcon,
  PaperPlaneIcon,
} from "../icons";
import "./acctHolderUpdateForm.scss";

const UpdateAccountHolderForm = ({
  profileData,
  setProfileData,
  setEditMode,
  setLoading,
  currentContext = "accountHolderUpdate",
  emailValue,
  setEmailValue,
  emailExistError,
  setEmailExistError,
  emailValueError,
  setEmailValueError,
  userNameValue,
  setUserNameValue,
  userNameValueError,
  setUsernameError,
  phoneNumberValue,
  setPhoneNumberValue,
  phoneNumberValueError,
  setPhoneNumberValueError,
  phoneNumberSuggestionError,
  setPhoneNumberSuggestionError,
  suggestedUsernames,
  setSuggestedUsernames,
  userNameValidationRules,
  setUserNameValidationRules,
  userNameToolTipVisible,
  setUserNameToolTipVisible,
  resizeError,
  setResizeError,
  fileError,
  setFileError,
  presignedImgUrlResponse,
  setPresignedImgUrlResponse,
  fileUpload,
  setFileUpload,
  imagePreviewUrl,
  setImagePreviewUrl,
  thumbnailPreference,
  setThumbnailPreference,
  showAllEmojis,
  setShowAllEmojis,
  setCurrentEmotionId,
  backendErrorMessage,
  setBackendErrorMessage,
  fileInputRef, // Receive the ref here
}) => {
  const { currentUser, setUserAttributes } = useAuth(); // Get current user and attributes

  const [emailFieldFocused, setEmailFieldFocused] = useState(false);
  const [userNameFieldFocused, setUserNameFieldFocused] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [previewImgModal, setPreviewImgModal] = useState(false);
  const [phoneNumberFocused, setPhoneNumberFocused] = useState(false);
  const [imageMetaData, setImageMetaData] = useState({
    width: 0,
    height: 0,
    size: 0, // Add this to track the file size
  });
  const triggerFileInputClick = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };

  const handleUploadImage = async (e) => {
    console.log("handleUploadImage: event triggered");
    const file = e?.target?.files[0];
    if (!file) {
      console.log("No file selected");
      return;
    }
    const fileType = getImageFileType(file); // Determines if the file is 'jpeg', 'png',
    console.log("Selected file:", file);
    console.log("File MIME type:", file.type);
    console.log("File size:", file.size);
    try {
      // Perform file validation and throw error if not valid
      checkFile(file.name, file.type, file.size);
      console.log("File validation passed, no resizing needed.");
      setThumbnailPreference("picture");
      // Assuming `checkFile` doesn't throw for valid files
      // Set metadata and upload state for valid files
      setImageMetaData((prevMetadata) => ({
        ...prevMetadata,
        size: file.size,
      }));
      setFileUpload(file);
      prepareImagePreviewAndShowModal(file);
      setResizeError(false);
      // Catch validation errors to use the response to trigger a resize function (chaining) in the if condition
    } catch (resizeError) {
      console.error("Validation error:", resizeError.message);
      // Directly check the condition for resizing here
      if (file.size > 250000) {
        // Direct check for file size exceeding limit
        console.log("Attempting to resize file due to exceeding size limit...");
        try {
          const resizedFile = await resizeFile(file, fileType);
          console.log("Resized file:", resizedFile);
          // Update the metadata and file upload state with the resized file
          setImageMetaData((prevMetadata) => ({
            ...prevMetadata,
            size: resizedFile.size,
          }));
          setFileUpload(resizedFile);
          prepareImagePreviewAndShowModal(resizedFile);
        } catch (resizeError) {
          setResizeError(resizeError); // Use the caught error from resizing attempt
          // Set the resizing error state
          console.error("Error resizing file:", resizeError);
        }
      } else {
        // General error handling for all other error types
        console.error("Error during file handling:", resizeError);
      }
    }
  };
  // Function to prepare and show the modal with the image preview
  const prepareImagePreviewAndShowModal = (file) => {
    const previewUrl = URL.createObjectURL(file);
    setImagePreviewUrl(previewUrl);
    setPreviewImgModal(true);
  };

  // Function to handle modal closure and cleanup
  const handleModalClose = () => {
    // Check if there's a preview URL to revoke
    if (imagePreviewUrl) {
      URL.revokeObjectURL(imagePreviewUrl);
      setImagePreviewUrl(null); // Clear the preview URL state
    }
    setPreviewImgModal(false); // Close the modal
  };

  const handleSubmitUpload = async () => {
    setBackendErrorMessage("");
    if (!fileUpload) {
      console.error("File validation failed");
      setFileError(true);
      return;
    }
    const formData = {
      fileName: fileUpload.name,
      mimeType: fileUpload.type,
      fileSize: fileUpload.size,
      sourceId: currentUser,
    };
    console.log("Form data constructed:", formData);
    localStorage.setItem("formData", JSON.stringify(formData));
    setLoading(true);
    try {
      // Check if the profile image exists and delete it if it does
      if (profileData && profileData.profileImageUrl) {
        console.log(
          "Deleting existing profile image:",
          profileData.profileImageUrl
        );
        const deleteResponse = await apiDeleteProfileImage(currentUser);
        if (deleteResponse.status === 200) {
          console.log("Existing profile image deleted");
          setProfileData({ ...profileData, profileImageUrl: null });
        } else {
          console.error("Failed to delete existing profile image");
          setBackendErrorMessage("Failed to delete existing profile image");
          setLoading(false);
          return;
        }
      }
      // Collecting File Details: then make a call to backend endpoint to request a pre-signed URL, passing along these file details.
      // see function for how it is done on backend by clicking on the getPresigedImgUrl
      const presignedImgUrlResponse = await getPresignedImgUrl(
        fileUpload.name,
        fileUpload.type,
        fileUpload.size,
        currentUser // Pass the currentUser as the sourceId
      );
      if (presignedImgUrlResponse.presignedImgUrl) {
        console.log(
          "Pre-signed URL obtained:",
          presignedImgUrlResponse.presignedImgUrl
        );
        setPresignedImgUrlResponse(presignedImgUrlResponse);
        // Proceed to upload the file using the obtained pre-signed URL
        await uploadFileToS3(
          presignedImgUrlResponse.presignedImgUrl,
          fileUpload,
          handleUploadSuccess,
          (error) => console.error("Upload Error:", error)
        );
      } else {
        console.error("Failed to obtain pre-signed URL");
      }
    } catch (err) {
      console.error("Error during the upload setup process:", err);

      // Check if the error is due to a duplicate file
      if (err.response && err.response.data && err.response.data.message) {
        setBackendErrorMessage(err.response.data.message); // Set error message from the backend
      } else {
        setBackendErrorMessage("An error occurred during the upload process."); // Fallback error message
      }
    } finally {
      setLoading(false);
    }
  };
  // Using the Pre-signed URL for Upload: If the response contains the pre-signed URL and indicates
  // success, you proceed to use this URL to directly upload the file to the S3 bucket:
  // The actual upload is handled in a separate step, where you make a PUT request to the
  // pre-signed URL with the file content as the request body.
  useEffect(() => {
    console.log("useEffect triggered", { presignedImgUrlResponse, fileUpload });
    if (presignedImgUrlResponse?.presignedImgUrl && fileUpload) {
      // this response will invoke the following below
      console.log(
        "Attempting upload with URL:",
        presignedImgUrlResponse.presignedImgUrl
      );
      console.log("Uploading file details:", {
        URL: presignedImgUrlResponse.presignedImgUrl,
        "File MIME type": fileUpload.type,
        File: fileUpload,
      });
      // using the file object directly
      uploadFileToS3(
        presignedImgUrlResponse.presignedImgUrl,
        fileUpload, // this is the actual file object that needs to be uploaded
        handleUploadSuccess, // Direct reference, not invoking here instead of a successs response message commented out below
        // () => console.log("Upload Success")
        (error) => console.error("Upload Error:", error)
      );
    }
  }, [presignedImgUrlResponse, fileUpload]);

  const handleUploadSuccess = async () => {
    const formDataString = localStorage.getItem("formData");
    if (formDataString) {
      let userData = JSON.parse(formDataString);
      const cloudFrontDomain = "media.skitsolympic.com";
      userData.profileImageUrl = `https://${cloudFrontDomain}/images/${userData.sourceId}/${encodeURIComponent(userData.fileName)}`;

      try {
        const response = await apiRecordProfileImageUrlCrud(userData);
        if (response.status === 200 || response.data.success) {
          // Update both profileData and userAttributes here to reflect the changes
          setProfileData((prevProfileData) => ({
            ...prevProfileData,
            profileImageUrl: userData.profileImageUrl,
          }));

          setUserAttributes((prevAttributes) => ({
            ...prevAttributes,
            profileImageUrl: userData.profileImageUrl,
          }));

          // Clear local storage and reset other states
          localStorage.removeItem("formData");
          setLoading(false);
          setFileUpload(null);
          handleModalClose();
          setUploadCompleted(false);
          setEditMode(false);
        }
      } catch (error) {
        console.error("Error recording user data:", error);
      }
    }
  };

  // Assuming fetchUserDetails is already defined and callable
  // Similarly, for handleSelectEmoji
  const handleThumbnailPreference = async (event) => {
    const newPreference = event.target.value;
    await handleThumbnailPreferenceUpdate(
      newPreference,
      currentUser,
      currentContext
    );
  };

  const handleThumbnailPreferenceUpdate = async (
    newPreference,
    currentUser,
    currentContext
  ) => {
    console.log("Updating thumbnail preference:", newPreference);

    // Generate a unique storage key using the provided context (currentUser is accessed from the Auth context)
    const storageKey = getStorageKey("thumbNailPreference", currentContext); // Pass "thumbNailPreference" as baseKey, currentContext as context
    localStorage.setItem(storageKey, newPreference); // Save the preference in local storage

    // Update `profileData` locally to reflect the change in the UI
    setProfileData((prevData) => {
      const updatedData = { ...prevData, thumbnailPreference: newPreference };
      console.log("Updated profileData:", updatedData);
      return updatedData;
    });

    // Update state for immediate UI feedback
    setThumbnailPreference(newPreference);
    setUserAttributes((prev) => ({
      ...prev,
      thumbnailPreference: newPreference,
    }));

    // Show/hide emojis based on preference
    setShowAllEmojis(newPreference === "emoji");

    // Send preference to the server
    try {
      await apiRecordThumbnailPreference(currentUser, newPreference);
      console.log(
        "API call successful - thumbnailPreference updated on server."
      );
    } catch (error) {
      console.error("Error updating thumbnail preference:", error);
    }
  };

  // Handler to select and save emoji

  // Similarly, for handleSelectEmoji
  // In UserProfilePage component
  const handleSelectEmoji = async (emotionconId, currentUser) => {
    if (emotionconId !== profileData.emotionconId) {
      console.log("Updating emoji selection:");
      console.log("Selected Emoji ID:", emotionconId);
      console.log("Current Emotion ID:", profileData.emotionconId);

      // Update `profileData` locally to reflect the change in the UI
      setProfileData((prevData) => {
        const updatedData = { ...prevData, emotionconId };
        console.log("Updated profileData:", updatedData);
        return updatedData;
      });

      setCurrentEmotionId(emotionconId);
      console.log("Updated currentEmotionId:", emotionconId);

      try {
        // API call to update the backend
        await apiRecordThumbnailEmotionconId(currentUser, emotionconId);
        console.log("API call successful - emotionconId updated on server.");
      } catch (error) {
        console.error("Error updating emotionconId:", error);
      }
    } else {
      console.log("Emoji selection is the same as current. No update needed.");
    }
  };

  const handleClearUsername = () => {
    setUserNameValue("");
    setUserNameValidationRules([]);
    setUserNameToolTipVisible(false);

    // Generate the key for username in local storage
    const key = getStorageKey("userNameValue", currentContext);
    localStorage.removeItem(key); // Remove username from local storage
  };

  useEffect(() => {
    console.log(
      "Form Rendered - Displayed emotionconId:",
      profileData.emotionconId
    );
  }, [profileData.emotionconId]);

  return (
    <div id="acctHldrUpdateForm" className="modalContent">
      <RadioHeadBtn
        label="Select Emoji Profile Thumbnail"
        name="thumbnailPreference"
        value="emoji"
        checked={thumbnailPreference === "emoji"}
        onChange={handleThumbnailPreference}
      />
      {!showAllEmojis && (
        <SmallPlainInsetBtn
          image={
            <CurveUpArrow
              height="20px"
              width="20px"
              style={{ marginRight: "3px" }}
            />
          }
          text="Change Emoji"
          style={{
            fontSize: "1rem",
          }}
          width="188px"
          onClick={() => {
            if (thumbnailPreference !== "emoji") {
              handleThumbnailPreference({
                target: { value: "emoji" },
              });
            }
            setShowAllEmojis(true);
          }}
        />
      )}
      <RadioHeadBtn
        label="Select Picture Profile Thumbnail"
        name="thumbnailPreference"
        value="picture"
        checked={thumbnailPreference === "picture"}
        onChange={handleThumbnailPreference}
      />
      {thumbnailPreference === "emoji" && (
        <>
          {showAllEmojis && (
            <div className="showAllEmojisWrapper">
              <EmotionEmoji
                onSelectEmoji={(emotionconId) =>
                  handleSelectEmoji(emotionconId, currentUser)
                }
                currentEmotionId={profileData.emotionconId} // Pass updated emotionconId
                showAllEmojis={showAllEmojis}
              />
              <SmallPlainInsetBtn
                image={
                  <XIcon
                    height="20px"
                    width="20px"
                    style={{ marginRight: "3px" }}
                  />
                }
                text="Cancel"
                style={{
                  fontSize: "1rem",
                }}
                width="88px"
                onClick={() => setShowAllEmojis(false)}
              />
            </div>
          )}
        </>
      )}
      {backendErrorMessage && (
        <div className="error-message">{backendErrorMessage}</div>
      )}
      {thumbnailPreference === "picture" && (
        <>
          <InsetBlendTVButton
            image={<SearchGlassIcon />}
            btnText="CHOOSE Profile Pic"
            textStyles={{
              color: "var(--color-bloodred)",
              fontFamily: "Roboto Slab",
              fontWeight: "800",
              fontSize: "1.3rem",
            }}
            onClick={triggerFileInputClick} // Keep the original handler to trigger file selection
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/jpeg, image/png"
            onChange={handleUploadImage}
          />
          {imagePreviewUrl && (
            <div>
              <img
                src={imagePreviewUrl}
                alt="Profile Preview"
                style={{ maxWidth: "200px", maxHeight: "200px" }}
              />
              <GlassBtn
                glassBtnImage={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="23.7"
                    viewBox="0 0 30 23.7"
                  >
                    <g
                      id="Group_17"
                      data-name="Group 17"
                      transform="translate(16851 16085)"
                    >
                      <g id="Camera-03" transform="translate(-16851 -16085)">
                        <path
                          id="Path_30"
                          data-name="Path 30"
                          d="M30,22.5a1.272,1.272,0,0,1-1.4,1.2H1.4A1.272,1.272,0,0,1,0,22.5V2.5A1.272,1.272,0,0,1,1.4,1.3H28.5a1.272,1.272,0,0,1,1.4,1.2v20Z"
                          fill="#fff"
                        />
                        <path
                          id="Path_31"
                          data-name="Path 31"
                          d="M2.5,1.8V.3A.319.319,0,0,1,2.8,0H6.2a.265.265,0,0,1,.3.3V1.8Z"
                        />
                        <path
                          id="Path_32"
                          data-name="Path 32"
                          d="M17.4,4.8a.319.319,0,0,1-.3.3H12.8a.319.319,0,0,1-.3-.3V2a.319.319,0,0,1,.3-.3h4.3a.319.319,0,0,1,.3.3Z"
                        />
                        <path
                          id="Path_33"
                          data-name="Path 33"
                          d="M16.9,4.2a.319.319,0,0,1-.3.3H13.4a.319.319,0,0,1-.3-.3V2.6a.319.319,0,0,1,.3-.3h3.2a.319.319,0,0,1,.3.3Z"
                          fill="#fff"
                        />
                        <path
                          id="Path_34"
                          data-name="Path 34"
                          d="M15.9,3.7a.319.319,0,0,1-.3.3H14.5a.319.319,0,0,1-.3-.3V3.1a.319.319,0,0,1,.3-.3h1.1a.319.319,0,0,1,.3.3Z"
                        />
                        <g id="Group_13" data-name="Group 13">
                          <path
                            id="Path_35"
                            data-name="Path 35"
                            d="M28.1,3.1v2h-4v-2h4m.1-.4H24a.319.319,0,0,0-.3.3V5.2a.319.319,0,0,0,.3.3h4.2a.319.319,0,0,0,.3-.3V2.9c0-.1-.1-.2-.3-.2Z"
                          />
                        </g>
                        <rect
                          id="Rectangle_43"
                          data-name="Rectangle 43"
                          width="30"
                          height="15.6"
                          transform="translate(0 6.9)"
                          fill="#fdfd96"
                        />
                        <circle
                          id="Ellipse_1"
                          data-name="Ellipse 1"
                          cx="1"
                          cy="1"
                          r="1"
                          transform="translate(2.4 9.2)"
                          fill="#fff"
                        />
                        <circle
                          id="Ellipse_2"
                          data-name="Ellipse 2"
                          cx="0.6"
                          cy="0.6"
                          r="0.6"
                          transform="translate(2.8 9.6)"
                          fill="#d14527"
                        />
                        <g id="Group_16" data-name="Group 16">
                          <g id="Group_14" data-name="Group 14">
                            <path
                              id="Path_36"
                              data-name="Path 36"
                              d="M8.7,19.2A8.042,8.042,0,0,1,10,8,7.951,7.951,0,0,1,21.2,9.3"
                              fill="#fff"
                            />
                            <path
                              id="Path_37"
                              data-name="Path 37"
                              d="M21.2,9.3A7.973,7.973,0,1,1,8.7,19.2"
                              fill="#fff"
                            />
                          </g>
                          <g id="Group_15" data-name="Group 15">
                            <path
                              id="Path_38"
                              data-name="Path 38"
                              d="M15,6.9A7.2,7.2,0,1,1,7.8,14,7.149,7.149,0,0,1,15,6.9m0-1.3a8.5,8.5,0,1,0,8.5,8.5A8.623,8.623,0,0,0,15,5.6Z"
                            />
                          </g>
                          <circle
                            id="Ellipse_3"
                            data-name="Ellipse 3"
                            cx="4.4"
                            cy="4.4"
                            r="4.4"
                            transform="translate(10.9 9.7)"
                          />
                          <path
                            id="Path_39"
                            data-name="Path 39"
                            d="M.9,0A.9.9,0,1,1,0,.9.9.9,0,0,1,.9,0Z"
                            transform="translate(12.9 13.7)"
                            fill="#fff"
                          />
                          <circle
                            id="Ellipse_5"
                            data-name="Ellipse 5"
                            cx="0.8"
                            cy="0.8"
                            r="0.8"
                            transform="translate(14.5 15.1)"
                            fill="#fff"
                          />
                        </g>
                        <rect
                          id="Rectangle_44"
                          data-name="Rectangle 44"
                          width="3.7"
                          height="0.8"
                          transform="translate(24.3 3.2)"
                        />
                      </g>
                    </g>
                  </svg>
                }
                //  passing the string "false" which is a truthy value.
                // therefore a string will not work because it needs to check the truthy value
                onClick={handleSubmitUpload}
                glassRefClassName="profileSubmit-glassref"
                glassBtnFontFamily="roboto-slab"
                glassBtnFontWeight="800"
                glassBtnText="Submit"
                glassBtnFontColor="#FDFD96"
                glassBtnTextDecorationColor="#FDFD96"
                glassBtnBorderColor="964b00"
                glassBtnTextFontSize="1.3rem"
                glassBtnMarginTop="20px"
                hi2Style={{
                  transform: "rotate(90deg) translate(-35px, 100px)",
                }}
                hi3Style={{
                  transform: "rotate(90deg) translate( -35px, -100px)",
                }}
              ></GlassBtn>
            </div>
          )}
        </>
      )}
      {/* Email Input */}
      <div className="input-group blinker-wrapper">
        {/* <p>Account Email: {profileData.email}</p> */}
        <input
          type="email"
          id="email"
          value={emailValue}
          onChange={(e) =>
            emailFieldWatcher(
              e.target.value,
              emailValueError,
              setEmailValue,
              setEmailValueError,
              currentContext
            )
          }
          placeholder={
            emailValueError ? emailValueError : "Enter your email here"
          }
          className={`${emailValueError ? "field-background-color-error" : ""}`}
          onFocus={() => setEmailFieldFocused(true)}
          onBlur={() => setEmailFieldFocused(false)}
        />
        {emailValue && (
          <FieldButton
            onClick={() => {
              const key = getStorageKey("emailValue", currentContext); // Generate storage key
              setEmailValue(""); // Clear the email field
              localStorage.removeItem(key); // Remove email from local storage
            }}
          >
            Clear
          </FieldButton>
        )}
        {emailExistError && (
          <div className="error-message">{emailExistError}</div>
        )}
        <StripeAnimationBtn
          stripeAnimationBtnText="Update Email"
          stripeAnimationBtnImage={
            <PaperPlaneIcon
              fillColor="#b03a2e"
              className="marginRightFivePx"
              width="20px"
            />
          }
          extraStyles={{
            marginTop: "10px",
            width: "100%",
            color: "rgb(176, 58, 46)",
            textShadow: "black 0px 0px",
            fontSize: "1.25rem",
            lineHeight: "14px",
            fontWeight: "700",
            fontFamily: "Roboto, sans-serif",
            textDecoration: "none",
            border: "1.5px solid var(--color-gold)",
            borderRadius: "30px",
            whiteSpace: "nowrap",
            backgroundImage:
              "linear-gradient(0deg, rgb(216, 217, 219) 0px, rgb(255, 255, 255) 80%, rgb(253, 253, 253))",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            height: "30px",
            padding: "5px",
          }}
          onClick={() =>
            handleEmailSubmit({
              emailValue,
              setEmailValueError,
              setEmailExistError,
              setEmailValue,
              setLoading,
              setProfileData,
              currentUser,
              setUserAttributes, // Pass setUserAttributes here
              currentContext,
            })
          }
        />
        {!(emailValue || emailFieldFocused) && (
          <span className="blinking-cursor-white">|</span>
        )}
      </div>
      {/* Username Input */}
      <div className="input-group blinker-wrapper">
        {/* <p>Account UserName: {profileData.username}</p> */}
        <div className="input-wrapper">
          <input
            id="username"
            autoComplete="off"
            type="text"
            placeholder={
              userNameValueError ? userNameValueError : "Enter username here"
            }
            className={`${userNameValueError ? "field-background-color-error" : ""}`}
            value={userNameValue}
            onChange={(e) =>
              usernameFieldWatcher(
                e,
                setUserNameValue,
                currentContext,
                userNameValueError,
                setUsernameError,
                setUserNameValidationRules,
                setUserNameToolTipVisible
              )
            }
            onFocus={() =>
              onUserNameFocus(
                userNameValue,
                validateUserNameValue,
                setUserNameFieldFocused,
                setUserNameValidationRules,
                setUserNameToolTipVisible
              )
            }
            onBlur={() =>
              onUserNameBlur(setUserNameFieldFocused, setUserNameToolTipVisible)
            }
          />
          {userNameValue && (
            <FieldButton onClick={handleClearUsername}>Clear</FieldButton>
          )}
          {!(userNameValue || userNameFieldFocused) && (
            <span className="blinking-cursor-white">|</span>
          )}
        </div>
        {/* Add a wrapper specifically for the tooltip */}
        <div className="tooltip-wrapper">
          {(userNameValueError || userNameToolTipVisible) && (
            <ToolTip
              rules={userNameValidationRules}
              toolTipVisible={userNameToolTipVisible}
              setToolTipVisible={setUserNameToolTipVisible}
            />
          )}
        </div>
      </div>
      {suggestedUsernames.length > 0 && (
        <div className="suggestions">
          <p className="userNameMessage">
            Username is taken. Suggested usernames:
          </p>
          <ul>
            {suggestedUsernames.map((name) => (
              <li key={name} onClick={() => setUserNameValue(name)}>
                {name}
              </li>
            ))}
          </ul>
        </div>
      )}
      <StripeAnimationBtn
        stripeAnimationBtnText="Update User Name"
        stripeAnimationBtnImage={
          <PeopleIcon
            fillColor="#b03a2e"
            className="marginRightFivePx"
            width="20px"
          />
        }
        extraStyles={{
          marginTop: "10px",
          marginBottom: "10px",
          width: "100%",
          color: "rgb(176, 58, 46)",
          textShadow: "black 0px 0px",
          fontSize: "1.25rem",
          lineHeight: "14px",
          fontWeight: "700",
          fontFamily: "Roboto, sans-serif",
          textDecoration: "none",
          border: "1.5px solid var(--color-gold)",
          borderRadius: "30px",
          whiteSpace: "nowrap",
          backgroundImage:
            "linear-gradient(0deg, rgb(216, 217, 219) 0px, rgb(255, 255, 255) 80%, rgb(253, 253, 253))",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          height: "30px",
          padding: "5px",
        }}
        onClick={() =>
          handleUsernameSubmit({
            userNameValue,
            setUsernameError,
            setUserNameValue,
            setSuggestedUsernames,
            setLoading,
            setProfileData,
            currentUser,
            setUserAttributes, // Pass setUserAttributes here
            currentContext,
          })
        }
      />
      {/* Phone Number Input */}
      <div className="input-wrapper blinker-wrapper">
        <PhoneInput
          id="updateMobile"
          type="text"
          country={"us"}
          onlyCountries={["us"]}
          disableDropdown={true}
          autoComplete="off"
          placeholder={
            phoneNumberValueError
              ? phoneNumberValueError
              : phoneNumberFocused || phoneNumberValue
                ? ""
                : "...Enter your U.S. mobile here"
          }
          value={phoneNumberValue}
          className={phoneNumberValueError ? "red-placeholder" : ""}
          onChange={(value) =>
            phoneFieldWatcher(
              value,
              phoneNumberSuggestionError,
              phoneNumberValueError,
              setPhoneNumberValue,
              setPhoneNumberValueError,
              setPhoneNumberSuggestionError,
              currentContext
            )
          }
          inputClass={`mobileUpdate-control-phonelibrary-placeholder ${
            phoneNumberValueError ? "field-background-color-error" : ""
          } ${phoneNumberFocused ? "focused" : ""}`}
          inputStyle={{
            borderColor: phoneNumberValueError ? "red" : "",
            width: "100%",
          }}
          onFocus={() => {
            setPhoneNumberFocused(true);
            setPhoneNumberSuggestionError("");
          }}
          onBlur={() => setPhoneNumberFocused(false)}
          error={!!phoneNumberValueError}
          disableCountryCode={true} // Disable the country code
          helperText={phoneNumberValueError && "Invalid phone number"}
        />
        {!(phoneNumberValue || phoneNumberFocused) && (
          <span className="blinking-cursor-white update-phone-number-cursor">
            |
          </span>
        )}
        {phoneNumberSuggestionError && (
          <div className="update-phone-error-message">
            {phoneNumberSuggestionError}
          </div>
        )}
      </div>
      <StripeAnimationBtn
        stripeAnimationBtnText="Update Phone Number"
        stripeAnimationBtnImage={
          <PhoneIcon
            fillColor="#b03a2e"
            className="marginRightFivePx"
            width="20px"
          />
        }
        extraStyles={{
          marginTop: "10px",
          width: "100%",
          color: "rgb(176, 58, 46)",
          textShadow: "black 0px 0px",
          fontSize: "1.25rem",
          lineHeight: "14px",
          fontWeight: "700",
          fontFamily: "Roboto, sans-serif",
          textDecoration: "none",
          border: "1.5px solid var(--color-gold)",
          borderRadius: "30px",
          whiteSpace: "nowrap",
          backgroundImage:
            "linear-gradient(0deg, rgb(216, 217, 219) 0px, rgb(255, 255, 255) 80%, rgb(253, 253, 253))",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          height: "30px",
          padding: "5px",
        }}
        onClick={() =>
          handlePhoneNumberSubmit({
            phoneNumberValue,
            setPhoneNumberValueError,
            setPhoneNumberSuggestionError,
            setPhoneNumberValue,
            setLoading,
            setProfileData,
            currentUser,
            formatPhoneNumber,
            setUserAttributes,
            currentContext,
          })
        }
      />
    </div>
  );
};

export default UpdateAccountHolderForm;
