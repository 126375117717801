import "./animatedHeartEmoji.scss";

const AnimatedHeartEmoji = ({ rating }) => {
  const renderExpression = () => {
    switch (rating) {
      case 5:
        return (
          <div className="heart-happy-face">
            <div className="heart-happy-expression">
              <div className="eyeBrow">
                <div className="underBrow">
                  <div className="left-brow"></div>
                  <div className="right-brow"></div>
                </div>
              </div>
              <div className="heart-eyes">
                <div className="eye eye-left-angletwich">❤️</div>
                <div className="eye eye-right-angletwich">❤️</div>
              </div>
              <div className="happy-heart-mouth"></div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="content-heart-face">
            <div className="heart-content-expression">
              <div className="content-heart-eyes">
                <div className="eye eye-left"></div>
                <div className="eye eye-right"></div>
              </div>
              <div className="small-heart"></div>
              <div className="content-heart-mouth"></div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="heart-grin-face">
            <div className="heart-grin-expression">
              <div className="heart-grin-eye eye-left"></div>
              <div className="heart-grin-eye eye-right"></div>
              <div className="heart-grin-mouth"></div>
              {/* Other facial features if needed */}
            </div>
          </div>
        );

      case 2:
        return (
          <div className="heart-pout-face">
            <div className="heart-pout-expression">
              <div className="heart-pout-eyes left-pout-eye"></div>
              <div className="heart-pout-eyes right-pout-eye"></div>
              {/* SVG for the mouth animation */}
              <svg
                width="100"
                height="50"
                viewBox="0 0 100 50"
                className="heart-pout-mouth"
              >
                <path
                  fill="none"
                  stroke="black"
                  strokeWidth="4"
                  d="M 10,25 L 90,25"
                >
                  <animate
                    attributeName="d"
                    attributeType="XML"
                    begin="0s"
                    dur="3s"
                    values="M 10,25 L 90,25;
                                   M 10,30 Q 50,0 90,30;
                                   M 10,25 L 90,25"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="heart-sad-face">
            <div className="heart-sad-expression">
              <div className="heart-sad-eye eye-left"></div>
              <div className="heart-sad-eye eye-right"></div>
              <div className="tear-drops left-tear"></div>
              <div className="tear-drops right-tear"></div>
              <div className="heart-sad-mouth"></div>
              {/* Other facial features if needed */}
            </div>
          </div>
        );
      case 0:
      default:
        return (
          <div className="heart-default-face">
            <div className="heart-default-expression">
              <div className="eyeBrow">
                <div className="underBrow">
                  <div className="left-brow"></div>
                  <div className="right-brow"></div>
                </div>
              </div>
              <div className="eyes">
                <div className="pupils">
                  <div className="left-eye"></div>
                  <div className="right-eye"></div>
                </div>
              </div>
              <div className="right-expression-eye"></div>
              <div className="kiss-mouth">
                <div className="ballotEmoji-floating-heart">❤️</div>
                <div className="lip">
                  <div className="upper-lip"></div>
                  <div className="lower-lip"></div>
                </div>
              </div>
            </div>
          </div>
        );
      // or
      // return null; // Default to no face
    }
  };
  return (
    <div className={`heart rank-${rating}`} key={`rating-${rating}`}>
      <div id={`rating-${rating}`}></div>
      {renderExpression()}
      {/* This element will receive the fade-in effect */}
    </div>
  );
};
export default AnimatedHeartEmoji;
