import React, { useState, useEffect, useRef } from "react";
import { ImgTopBtn, RectangleNeumorphicBtn } from "../buttons";
import { BookMarkIcon, CameraIcon, SkitsOlympicLogoIcon } from "../icons/index";
import NeumorphismField from "../neumorphismField/neumorphismField";
import DatePicker from "../datePicker/datePicker";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";
import {
  getImageFileType,
  resizeFile,
  getVideoFileType,
} from "../../constants/fileUpload";
import { campaignErrorMessages } from "../../constants/errorMessages";
import { checkFile } from "../../utils/checkFile";
import { checkVideoFile } from "../../utils/checkVideoFile";
import { useAuth } from "../context/authContext";
import "./campaignAdModal.scss";

const CampaignAdModal = ({ onSave, contextType }) => {
  const { currentUser } = useAuth();
  const formKey = getStorageKey("campaignName", contextType);
  const adBlobKey = getStorageKey("adBlob", contextType);
  const expirationDateKey = getStorageKey(
    "campaignExpirationDate",
    contextType
  ); // Key for expiration date
  const [campaignExpirationDate, setCampaignExpirationDate] = useState(
    localStorage.getItem(expirationDateKey) || ""
  );
  const [campaignName, setCampaignName] = useState(
    localStorage.getItem(formKey) || ""
  );
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [videoPreviewUrl, setVideoPreviewUrl] = useState("");
  const [fileError, setFileError] = useState(false);
  const [fileTypeErrorMessage, setFileTypeErrorMessage] = useState("");
  const [fileUploadError, setFileUploadError] = useState("");
  const [campaignNameError, setCampaignNameError] = useState(false);
  const [expirationDateError, setExpirationDateError] = useState(false);
  const [durationError, setDurationError] = useState("");
  const [existingFileError, setExistingFileError] = useState(false);
  const [campaignNameFocus, setCampaignNameFocus] = useState(false);
  const [expirationDateFocus, setExpirationDateFocus] = useState(false);
  const fileInputRef = useRef(null);
  const videoFileInputRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [videoMetadata, setVideoMetadata] = useState({
    duration: 0,
    width: 0,
    height: 0,
  });
  const cloudFrontDomain = "media.skitsolympic.com"; // Your CloudFront domain
  useEffect(() => {
    localStorage.setItem(formKey, campaignName);
  }, [campaignName, formKey]);

  useEffect(() => {
    localStorage.setItem(
      getStorageKey("campaignExpirationDate", contextType),
      campaignExpirationDate
    );
  }, [campaignExpirationDate]);

  useEffect(() => {
    const adBlobString = localStorage.getItem(adBlobKey);
    if (adBlobString) {
      const adBlob = JSON.parse(adBlobString);
      if (adBlob.mimeType.includes("image")) {
        setImagePreviewUrl(adBlob.previewUrl);
        setVideoPreviewUrl("");
      } else if (adBlob.mimeType.includes("video")) {
        setVideoPreviewUrl(adBlob.previewUrl);
        setImagePreviewUrl("");
      }
    }
  }, [adBlobKey]);

  const handleUploadAdImage = async (file) => {
    console.log("handleUploadImage: event triggered");
    const fileType = getImageFileType(file);
    console.log("Selected file:", file);
    console.log("File MIME type:", file.type);
    console.log("File size:", file.size);

    try {
      checkFile(file.name, file.type, file.size);
      let fileToUpload = file;
      // Check file size and resize if necessary
      if (file.size > 250000) {
        console.log("File size exceeds limit, resizing...");
        fileToUpload = await resizeFile(file, fileType);
        console.log("Resized file:", fileToUpload);
      }
      const previewUrl = URL.createObjectURL(fileToUpload);
      const adBlob = {
        fileName: fileToUpload.name,
        mimeType: fileToUpload.type,
        fileSize: fileToUpload.size,
        sourceId: currentUser,
        previewUrl,
      };
      localStorage.setItem(adBlobKey, JSON.stringify(adBlob));
      setFile(fileToUpload);
      setImagePreviewUrl(previewUrl);
      setVideoPreviewUrl(""); // Clear video preview if an image is uploaded
    } catch (error) {
      // Handle file size error and resize the file
      if (error.message.includes("File size exceeds")) {
        console.log("Caught file size error, resizing file...");
        try {
          const resizedFile = await resizeFile(file, fileType);
          console.log("Resized file:", resizedFile);

          const previewUrl = URL.createObjectURL(resizedFile);
          const adBlob = {
            fileName: resizedFile.name,
            mimeType: resizedFile.type,
            fileSize: resizedFile.size,
            sourceId: currentUser,
            previewUrl,
          };
          localStorage.setItem(adBlobKey, JSON.stringify(adBlob));
          setFile(resizedFile);
          setImagePreviewUrl(previewUrl);
          setVideoPreviewUrl(""); // Clear video preview if an image is uploaded
        } catch (resizeError) {
          console.error("Error during resizing:", resizeError);
          setFileError(true);
        }
      } else {
        console.error("Error during file handling:", error);
        setFileError(true);
      }
    }
  };

  const handleUploadVideo = async (file) => {
    console.log("handleUploadVideo: event triggered");
    setFileError(false);
    setDurationError("");

    try {
      console.log("File received:", file);

      // Get and validate the file type
      const fileType = getVideoFileType(file);
      console.log("Determined file type:", fileType);
      if (!fileType) {
        console.error("Unsupported file type:", file.type);
        throw new Error("Unsupported file type.");
      }

      // Validate video file properties
      checkVideoFile(file.name, file.type, file.size);
      console.log("File passed validation:", {
        name: file.name,
        type: file.type,
        size: file.size,
      });

      // Create a video element to load metadata
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.src = URL.createObjectURL(file);
      console.log("Video element created and metadata loading started.");

      // Event listener for metadata loading
      videoElement.onloadedmetadata = () => {
        console.log("Video metadata loaded.");
        const duration = videoElement.duration;
        console.log("Video duration (seconds):", duration);

        // Validate video duration (2 to 30 minutes)
        // if (duration < 120 || duration > 1800) {
        // Validate duration (at least 1 second for testing)
        if (duration < 1 || duration > 1800) {
          const durationErrorMsg =
            "Video duration must be between 2 and 30 minutes.";
          console.error(durationErrorMsg);
          setDurationError(durationErrorMsg);
          setFileError(true);
          return;
        }

        // Save file metadata to localStorage
        const adBlob = {
          fileName: file.name,
          mimeType: file.type,
          fileSize: file.size,
          sourceId: currentUser,
          videoMetadata: { duration },
        };
        try {
          localStorage.setItem(adBlobKey, JSON.stringify(adBlob));
          console.log("Video metadata saved to localStorage:", adBlob);
        } catch (storageError) {
          console.error("Error saving to localStorage:", storageError);
          setFileError(true);
        }

        setFile(file); // Save the file reference
        console.log("File state updated:", file);
      };

      // Error handling for video loading
      videoElement.onerror = (event) => {
        console.error("Error loading video metadata:", event);
        setFileError(true);
      };
    } catch (error) {
      console.error("Error during video handling:", error);
      setFileTypeErrorMessage(error.message); // Set the error message from the caught error
      setFileError(true);
    }
  };

  const handleFileChange = (e) => {
    console.log("handleFileChange: event triggered");
    const file = e.target.files[0];
    if (file) {
      const adBlobString = localStorage.getItem(adBlobKey);
      if (adBlobString) {
        localStorage.removeItem(adBlobKey); // Clear the key
        const adBlob = JSON.parse(adBlobString);
        setImagePreviewUrl(adBlob.previewUrl);
        if (adBlob.fileName === file.name) {
          setExistingFileError(true);
          return;
        }
      }
      setFileUploadError(""); // Clear the error on successful file upload
      setExistingFileError(false);
      handleUploadAdImage(file);
    }
    e.target.value = null; // Reset the file input to ensure changes are detected
  };

  const handleVideoFileChange = (e) => {
    console.log("handleVideoFileChange triggered");
    const file = e.target.files[0];
    if (file) {
      // Remove existing adBlobKey to avoid conflicts
      localStorage.removeItem(adBlobKey);

      handleUploadVideo(file);
    }
    e.target.value = null; // Ensure input resets for consecutive uploads
  };

  const handleCampaignNameChange = (e) => {
    const newCampaignName = e.target.value;
    setCampaignName(newCampaignName);
  };

  const triggerFileInputClick = () => {
    setVideoPreviewUrl(""); // Clear the video preview
    fileInputRef.current.click();
  };

  const triggerVideoFileInputClick = () => {
    setImagePreviewUrl(""); // Clear the image preview
    videoFileInputRef.current.click();
  };

  const handleDateChangeFromPicker = (dateValue) => {
    const formattedDate =
      dateValue instanceof Date ? dateValue.toISOString() : dateValue;
    setCampaignExpirationDate(formattedDate);
    setSelectedDate(formattedDate);
  };

  const saveAdCampaign = () => {
    let hasError = false;
    // Retrieve values directly from localStorage
    const storedCampaignName =
      localStorage.getItem(getStorageKey("campaignName", contextType)) || "";
    const storedCampaignExpirationDate =
      localStorage.getItem(
        getStorageKey("campaignExpirationDate", contextType)
      ) || "";
    const adBlobString = localStorage.getItem(adBlobKey);
    // Validate campaign name from localStorage
    if (!storedCampaignName) {
      setCampaignNameError(campaignErrorMessages.campaignName);
      hasError = true;
    } else {
      setCampaignNameError(""); // Clear the error if there's no issue
    }
    // Validate expiration date from localStorage
    if (!storedCampaignExpirationDate) {
      setExpirationDateError(campaignErrorMessages.expirationDate);
      hasError = true;
    } else {
      setExpirationDateError(""); // Clear the error if there's no issue
    }
    // Validate file upload
    if (!adBlobString) {
      setFileUploadError(campaignErrorMessages.fileUpload);
      hasError = true;
    } else {
      setFileUploadError(""); // Clear the error if file is uploaded
    }

    // Save if no errors
    if (!hasError && adBlobString) {
      const adBlob = JSON.parse(adBlobString);
      onSave(adBlob);
    }
  };

  const handleCampaignNameFocus = () => {
    console.log("Focus event triggered");
    setCampaignNameFocus(true);
    setCampaignNameError(""); // Clear the error when focused
  };

  const handleCampaignNameBlur = () => {
    console.log("Blur event triggered");
    setCampaignNameFocus(false);
  };
  const handleExpirationDateFocus = () => {
    setExpirationDateFocus(true); // Clear the error on focus
    setExpirationDateError(""); // Clear error on focus
  };
  const handleExpirationDateBlur = () => {
    setExpirationDateFocus(false); // Ensure the state resets on blur
  };
  const inputStyle = campaignNameError
    ? { color: "var(--color-hotRed)" }
    : { color: "var(--color-grey)" };

  return (
    <div id="adCampaign" className="popup">
      <div className="popup-content">
        <NeumorphismField
          // label="Enter Campaign Name"
          placeholder={
            campaignNameError
              ? campaignErrorMessages.campaignName
              : "Enter campaign name"
          }
          type="text"
          value={campaignName}
          onChange={handleCampaignNameChange}
          className={`campaign-name-input ${campaignNameError ? "error error-border" : ""}`} // Dynamically apply "error" class
          onFocus={handleCampaignNameFocus}
          onBlur={handleCampaignNameBlur}
        />
        <DatePicker
          showStartDate={false}
          showEndDate={false}
          showAdditionalDate={true}
          placeholder={
            expirationDateError
              ? expirationDateError
              : "Click Here to Pick an Expiration Date"
          }
          numYears={10}
          setCampaignExpirationDate={setCampaignExpirationDate}
          campaignExpirationDate={campaignExpirationDate}
          useCurrentYearOn={true}
          startFromTomorrow={true} // Ensure this is passed
          onAdditionalDateChange={handleDateChangeFromPicker}
          onFocus={handleExpirationDateFocus}
          onBlur={handleExpirationDateBlur}
          dateInputClassName={`campaign-expiry-input ${expirationDateError ? "error-border" : ""}`}
          errorMessage={!expirationDateFocus ? expirationDateError : ""}
          className="campaign-expiry-date-picker"
        />
        <div className="popup-actions-wrapper">
          <p className="ad-upload-instructions">
            Upload a campaign advertisement
          </p>
          <div
            className={`upload-buttons-wrapper ${fileUploadError ? "error-border" : ""}`}
          >
            <div className="upload-buttons">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <RectangleNeumorphicBtn
                image={<CameraIcon width="20" height="20" />}
                textRectangleNeumorphicBtn="Upload Image"
                onClick={triggerFileInputClick}
              />
              <input
                type="file"
                accept="video/*"
                onChange={handleVideoFileChange}
                style={{ display: "none" }}
                ref={videoFileInputRef}
              />
              <RectangleNeumorphicBtn
                image={
                  <SkitsOlympicLogoIcon
                    width="20"
                    height="20"
                    fillColor="var(--color-gold)"
                  />
                }
                textRectangleNeumorphicBtn="Upload Video"
                onClick={triggerVideoFileInputClick}
              />
            </div>
            <div>
              {fileUploadError && (
                <div className="error-message">{fileUploadError}</div>
              )}{" "}
            </div>
          </div>

          <div>
            {existingFileError && (
              <div className="error-message">File already uploaded.</div>
            )}
            {imagePreviewUrl && (
              <div>
                <img
                  src={imagePreviewUrl}
                  alt="Image Preview"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
              </div>
            )}
            {fileTypeErrorMessage && (
              <div className="error-message">{fileTypeErrorMessage}</div>
            )}
            {durationError && (
              <div className="error-message">{durationError}</div>
            )}
            {videoPreviewUrl && (
              <div>
                <video
                  src={videoPreviewUrl}
                  controls
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
              </div>
            )}
          </div>
          <div className="popup-actions">
            <ImgTopBtn
              image={<BookMarkIcon style={{ paddingRight: "5px" }} />}
              imgTopBtnText="Create & Save"
              onClick={saveAdCampaign}
              imgTopBtnWidth="200px"
              imgTopBtnFontColor="var(--color-bloodred)"
              position="relative"
              extraImgTopBtnStyles={{
                backgroundImage:
                  "linear-gradient(120deg, #e0e0e0 0%, #fefefe 80%)",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CampaignAdModal;
