import { useNavigate } from "react-router-dom";
import { MemberSlider, ImgTopBtn } from "../buttons";
import { EyeSlashIcon } from "../icons";
import "./votedUsersSkitList.scss";

const UsersWhoRatedList = ({
  usersWhoRated,
  hasMore,
  handleViewMore,
  loading,
  error
}) => {
  const navigate = useNavigate();
  const handleProfileThumbnailSlideEnd = (userId) => {
    if (userId) {
      console.log("Navigating to user profile:", userId);
      navigate(`/user-profile/${userId}`);
    } else {
      console.log("User ID is undefined, not navigating.");
    }
  };
  if (error && usersWhoRated.length === 0) return <div>Error: {error}</div>;

  return (
    <div>
      {usersWhoRated.map((user) => (
        <div key={user.source.id} className="user-info">
          <MemberSlider
            user={user.source}
            slideBtnText="Slide to View Profile"
            className="slider-btn-spacer"
            onHandleSlideEnd={() => {
              console.log("User ID being passed:", user.source.id);
              handleProfileThumbnailSlideEnd(user.source.id);
            }}
            style={{ marginTop: "10px" }}
            slidBtnFontSize="16px"
            slideBtnWidth="260px"
          />
          <div className="user-details">
            <span className="username">{user.source.username}</span>
          </div>
        </div>
      ))}
      {hasMore && !loading && (
        <ImgTopBtn
          className="view-more-btn"
          imgTopBtnBorderRadius="25px"
          imgTopBtnWidth="fit-content"
          imgTopBtnTextFontSize="1.3rem"
          imgTopBtnFontFamily="Roboto-Slab"
          imgTopBtnFontWeight="900"
          imgTopBtnFontColor="var(--color-bloodred)"
          paddingRight="5px"
          paddingLeft="5px"
          extraImgTopBtnStyles={{
            margin: "20px auto",
          }}
          onClick={handleViewMore}
          imgTopBtnText="View More Raters"
          image={<EyeSlashIcon fillColor="var(--color-bloodred)" />}
        />
      )}
    </div>
  );
};

export default UsersWhoRatedList;
