import React, { useEffect, useState } from "react";
import { formatTime } from "../../utils/formatTime";

const TimeRemainingAd = ({ videoRef, isAdPlaying, adDuration }) => {
  const [timeRemaining, setTimeRemaining] = useState(null); // Start with null

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!isAdPlaying || !videoElement || !adDuration) return;

    const handleTimeUpdate = () => {
      const remainingTime = adDuration - videoElement.currentTime;
      setTimeRemaining(Math.max(0, remainingTime)); // Ensure no negative values
    };

    videoElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [videoRef, isAdPlaying, adDuration]);

  // Do not render anything if `timeRemaining` is null (initial state)
  if (!isAdPlaying || timeRemaining === null) return null;

  return (
    <div className="ad-timer">
      <span>Ad Time Remaining: {formatTime(timeRemaining)}</span>
    </div>
  );
};

export default TimeRemainingAd;
