import React, { useState, useEffect } from "react";
import { useAuth } from "../../components/context/authContext";
import { useNavigate } from "react-router-dom";
import {
  apiFetchInitialTransactionHistory,
  apiFetchMoreTransactionHistory,
} from "../../api/backend/auth";
import { LeftArrow } from "../../components/icons";
import { SolidColorButton, SmallPlainInsetBtn } from "../../components/buttons";
import { EyeIcon, EyeSlashIcon } from "../../components/icons";
import Loader from "../../components/loader/loader";
import TransactionHistoryCard from "../../components/transactionHistoryCard/transactionHistoryCard";
import "./transactionHistoryPage.scss";

const TransactionHistoryPage = () => {
  const { currentUser } = useAuth(); // Fetch current user from context
  let navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [lastCreatedAt, setLastCreatedAt] = useState(null); // Tracks the last createdAt value
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  // Fetch initial transactions
  const fetchInitialTransactions = async () => {
    setLoading(true);
    try {
      console.log("Fetching initial transactions for user:", currentUser);
      const response = await apiFetchInitialTransactionHistory(currentUser, 20); // Initial limit
      console.log("Initial transactions response:", response);

      const transactions = response?.data?.data; // Access the array of transactions
      const pagination = response?.data?.pagination; // Access the pagination object

      if (transactions) {
        setTransactions(transactions); // Set the transactions
      }

      if (pagination?.lastCreatedAt) {
        setLastCreatedAt(pagination.lastCreatedAt); // Store the lastCreatedAt value
      } else {
        console.warn("No pagination.lastCreatedAt found in the response");
      }

      setHasMore(transactions && transactions.length > 0); // Check if there are transactions
    } catch (err) {
      setError("Failed to fetch initial transaction history.");
      console.error("Error fetching initial transactions:", err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch more transactions (pagination)
  const fetchMoreTransactions = async () => {
    if (loading || !hasMore) return; // Prevent duplicate fetches
    setLoading(true);
    try {
      const response = await apiFetchMoreTransactionHistory(
        currentUser,
        lastCreatedAt, // in the structure we store the last created time
        //stamp from the last index in the array, so we can fetch the
        //  next set in the database from that time stamp
        20 // Fetch up to 20 transactions
      );
      const newTransactions = response?.data?.data || []; // Default to empty array
      // Append the new transactions to the list
      if (newTransactions.length > 0) {
        setTransactions((prev) => [...prev, ...newTransactions]);
        setLastCreatedAt(newTransactions[newTransactions.length - 1].createdAt);
      }
      // Update pagination logic: stop fetching if fewer than 20 returned
      if (newTransactions.length < 20) {
        setHasMore(false); // No more transactions available
      }
      // Update the lastCreatedAt for pagination
      const pagination = response?.data?.pagination;
      if (pagination?.lastCreatedAt) {
        setLastCreatedAt(pagination.lastCreatedAt);
      } else {
        setHasMore(false); // Stop if no pagination data exists
      }
    } catch (err) {
      setError("Failed to fetch more transaction history.");
      console.error("Error fetching more transactions:", err.message);
      setHasMore(false); // Stop further calls on error
    } finally {
      setLoading(false);
    }
  };

  // Initial load
  useEffect(() => {
    if (currentUser) {
      fetchInitialTransactions();
    }
  }, [currentUser]);

  // Render the transactions
  if (loading && transactions.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p style={{ color: "red", textAlign: "center" }}>{error}</p>;
  }
  return (
    <div id="paymentTransactionsTop" className="transaction-history-page">
      <h1 className="pageHeader">Transaction History</h1>
      <SolidColorButton
        solidColorBtnImage={
          <LeftArrow
            height="15px"
            width="15px"
            fillColor="#964b00"
            strokeWidth="4"
            strokeColor="#964b00"
          />
        }
        className="gold-btn"
        solidColorBtnText="Back"
        solidColorBtnFontWeight="800"
        solidColorBtnFontFamily="helvetica"
        solidColorBtnTextFontSize=".75rem"
        solidColorBtnFontColor="#964b00"
        solidColorBtnWidth="100px"
        solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
        onClick={() => navigate(-1)}
      />
      {transactions.length > 0 ? (
        <div className="transaction-history-container">
          {transactions.map((transaction) => (
            <TransactionHistoryCard
              key={transaction.id}
              transaction={transaction}
            />
          ))}
          {loading && <Loader />}
          {hasMore && (
            <SmallPlainInsetBtn
              image={
                hasMore ? (
                  <EyeIcon
                    height="15px"
                    width="15px"
                    style={{ marginRight: "3px" }}
                    fillColor="var(--color-bloodred)"
                  />
                ) : (
                  <EyeSlashIcon
                    height="15px"
                    width="15px"
                    style={{ marginRight: "3px" }}
                    fillColor="var(--color-bloodred)"
                  />
                )
              }
              className="load-more-button"
              text={hasMore ? "View More" : "Hide More"}
              onClick={fetchMoreTransactions}
            />
          )}
        </div>
      ) : (
        !loading && <p>No transactions found.</p>
      )}
    </div>
  );
};

export default TransactionHistoryPage;
