export const pointsValueInCentsPerDollar = 0.01; // Each point is worth $0.01
export const pointsPerDollar = 100; // Example: 100 points = $1.00 (adjust as necessary), 
//so it takes .01 times 100 points to make $1.00

export const convertPointsToDollars = (points) => {
    const pointsPerDollar = 100; // 100 points = $1 since it is .01 penny per dollar 
    return points / pointsPerDollar; // Divide points by pointsPerDollar,
    //  which will give us 1 penny 
  };
  
  // export const convertPointsToDollars = (points) => points * 0.01;


  export const convertDollarsToPoints = (dollars) => Math.round(dollars / 0.01);

  

  // earned points is .01 penny times 1 point times reward level (rater)