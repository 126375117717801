import { useEffect, useState, useRef } from "react";
import { rewardLevelIcons } from "../../constants/rewardLevelIcons";
import { HashLink } from "react-router-hash-link";
import { commaNumberFormat } from "../../utils/commaNumberFormat";
import "./rewardsCircle.scss";

// initializing at 0 from the other component using absoulute definition
// providing msgLevel as a call back prop to be passed to other components to communicate
const PointsCircle = ({
  className,
  pointsBalance,
  unlockLevelStyle,
  pointsToGoStyle,
  NameLevelValueStyle,
  CurrentPntsBalStyle,
}) => {
  const [paramLevel, setParamsLevel] = useState({}); // local to PointsCircle component and is used to update the display parameters of the circle (paramLevel state) based on the current value of pointsBalance. It determines the circle's color, level name, icon, etc.
  const prevPointsBalance = useRef(pointsBalance); // Initialize as the first pointsBalance passed
  const circle1 = useRef(0);
  const circle2 = useRef(0);
  const circle3 = useRef(0);
  const circle4 = useRef(0);
  const circle5 = useRef(0);
  useEffect(() => {
    console.log("Points Balance:", pointsBalance);
    console.log("Param Level:", paramLevel);
    calculateCircle();
  }, [pointsBalance]);

  const calculateCircle = () => {
    const radius = 35;
    const offset = 219.911485;
    let c = Math.PI * (radius * 2);
    let pct = 0;
    let calPointsBalance = pointsBalance;
    if (pointsBalance >= 0 && pointsBalance <= 999) {
      if (pointsBalance >= 990 && pointsBalance <= 999) {
        calPointsBalance = 990;
      }
      pct = ((1000 - calPointsBalance) / 1000) * c;
      setParamsLevel((state) => ({
        ...state,
        stroke: "url(#linearForStar)",
        level: "Star",
        iconLevel: "star",
        upToNextLevel: (1000 - pointsBalance)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        circle: "circle1",
      }));
      if (prevPointsBalance >= 1000) {
        circle1.current.style.strokeDashoffset = 0;
      }
      setTimeout(() => {
        circle1.current.style.strokeDashoffset = pct;
      }, 300);
      circle2.current.style.strokeDashoffset = offset;
    } else if (pointsBalance >= 1000 && pointsBalance <= 4999) {
      if (pointsBalance >= 4980 && pointsBalance <= 4999) {
        calPointsBalance = 4980;
      }
      pct = ((4000 - (calPointsBalance - 1000)) / 4000) * c;
      setParamsLevel((state) => ({
        ...state,
        stroke: "url(#linearForStar)",
        level: "Silver",
        iconLevel: "silver",
        upToNextLevel: (5000 - pointsBalance)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        circle: "circle2",
      }));
      if (prevPointsBalance >= 5000) {
        circle2.current.style.strokeDashoffset = 0;
      }
      setTimeout(() => {
        circle2.current.style.strokeDashoffset = pct;
      }, 300);
      circle1.current.style.strokeDashoffset = offset;
      circle3.current.style.strokeDashoffset = offset;
    } else if (pointsBalance >= 5000 && pointsBalance <= 9999) {
      if (pointsBalance >= 9985 && pointsBalance <= 9999) {
        calPointsBalance = 9985;
      }
      pct = ((5000 - (calPointsBalance - 5000)) / 5000) * c;
      setParamsLevel((state) => ({
        ...state,
        stroke: "url(#linearForGold)",
        level: "Gold",
        iconLevel: "gold",
        upToNextLevel: (10000 - pointsBalance)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        circle: "circle3",
      }));
      if (prevPointsBalance >= 10000) {
        circle3.current.style.strokeDashoffset = 0;
      }
      setTimeout(() => {
        circle3.current.style.strokeDashoffset = pct;
      }, 300);
      circle2.current.style.strokeDashoffset = offset;
      circle4.current.style.strokeDashoffset = offset;
    } else if (pointsBalance >= 10000 && pointsBalance < 15000) {
      if (pointsBalance >= 14970 && pointsBalance <= 14999) {
        calPointsBalance = 14970;
      }
      pct = ((5000 - (calPointsBalance - 10000)) / 5000) * c;
      setParamsLevel((state) => ({
        ...state,
        stroke: "url(#linearForStar)",
        level: "Platinum",
        iconLevel: "platinum",
        upToNextLevel: (15000 - pointsBalance)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        circle: "circle4",
      }));
      if (prevPointsBalance >= 15000) {
        circle4.current.style.strokeDashoffset = 0;
      }
      setTimeout(() => {
        circle4.current.style.strokeDashoffset = pct;
      }, 300);
      circle3.current.style.strokeDashoffset = offset;
      circle5.current.style.strokeDashoffset = offset;
    } else if (pointsBalance >= 15000 && pointsBalance < 25000) {
      if (pointsBalance >= 24950 && pointsBalance <= 24999) {
        calPointsBalance = 24950;
      }
      pct = ((10000 - (calPointsBalance - 15000)) / 10000) * c;
      setParamsLevel((state) => ({
        ...state,
        stroke: "url(#linearForBlackdiamond)",
        level: "Black Diamond",
        iconLevel: "diamond",
        upToNextLevel: (25000 - pointsBalance)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        circle: "circle5",
      }));
      if (prevPointsBalance >= 25000) {
        circle5.current.style.strokeDashoffset = 0;
      }
      setTimeout(() => {
        circle5.current.style.strokeDashoffset = pct;
      }, 300);
      circle4.current.style.strokeDashoffset = offset;
    } else {
      setParamsLevel((state) => ({
        ...state,
        stroke: "#000000",
        dashOffset: offset,
        level: "Black Diamond",
        iconLevel: "diamond",
        upToNextLevel: 0,
        circle: "circle5",
      }));
      circle5.current.style.strokeDashoffset = 0; // Completely close the circle
    }
  };
  return (
    <>
      <div id="circleContainer">
        <svg
          id="svg"
          width="150" // this will make the circle bigger for all of them it is the parent css
          height="150" // this will make the circle bigger for all of them it is the parent css
          viewBox="0 0 200 200"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              id="linearForStar"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0" stopColor="#616468 " />
              <stop offset=".5" stopColor="#f8f8f8" />
              <stop offset="1" stopColor="#606367" />
            </linearGradient>
          </defs>
          {/* <!-- gold level--> */}
          <defs>
            <linearGradient
              id="linearForGold"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" stopColor="#fdc500" />
              <stop offset="50%" stopColor="#ffe97f" />
              <stop offset="100%" stopColor="#fdc500" />
            </linearGradient>
          </defs>
          {/* <!-- Black diamond level--> */}
          <defs>
            <linearGradient
              id="linearForBlackdiamond"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="100%" stopColor="#000000" />
            </linearGradient>
          </defs>
          <circle
            id="circle1"
            r="35"
            cx="100"
            cy="100"
            fill="transparent"
            strokeDasharray="219.911485"
            strokeDashoffset="0"
          ></circle>
          <circle
            id="bar"
            r="35"
            cx="100"
            cy="100"
            stroke={paramLevel.stroke}
            fill="transparent"
            strokeDasharray="219.911485"
            strokeDashoffset="219.911485"
            transform="rotate(-90 100 100)" // Rotate the circle by 90 degrees around its center
            style={{
              display:
                paramLevel.circle === "circle1" && pointsBalance <= 999
                  ? "block"
                  : "none",
            }}
            ref={circle1}
          ></circle>
          <circle
            id="barSilver"
            r="35"
            cx="100"
            cy="100"
            stroke={paramLevel.stroke}
            fill="transparent"
            strokeDasharray="219.911485"
            strokeDashoffset="219.911485"
            transform="rotate(-90 100 100)" // Rotate the circle by 90 degrees around its center
            style={{
              display: paramLevel.circle === "circle2" ? "block" : "none",
            }}
            ref={circle2}
          ></circle>
          <circle
            id="barGold"
            r="35"
            cx="100"
            cy="100"
            stroke={paramLevel.stroke}
            fill="transparent"
            strokeDasharray="219.911485"
            strokeDashoffset="219.911485"
            transform="rotate(-90 100 100)" // Rotate the circle by 90 degrees around its center
            style={{
              display: paramLevel.circle === "circle3" ? "block" : "none",
            }}
            ref={circle3}
          ></circle>
          <circle
            id="barPlatinam"
            r="35"
            cx="100"
            cy="100"
            stroke={paramLevel.stroke}
            fill="transparent"
            strokeDasharray="219.911485"
            strokeDashoffset="219.911485"
            transform="rotate(-90 100 100)" // Rotate the circle by 90 degrees around its center
            style={{
              display: paramLevel.circle === "circle4" ? "block" : "none",
            }}
            ref={circle4}
          ></circle>
          <circle
            id="barDiamond"
            r="35"
            cx="100"
            cy="100"
            stroke={paramLevel.stroke}
            fill="transparent"
            strokeDasharray="219.911485"
            strokeDashoffset="219.911485"
            transform="rotate(-90 100 100)" // Rotate the circle by 90 degrees around its center
            style={{
              display: paramLevel.circle === "circle5" ? "block" : "none",
            }}
            ref={circle5}
          ></circle>
        </svg>
        <div
          className="icon-level"
          style={{
            backgroundImage: `url(${rewardLevelIcons(paramLevel.iconLevel)})`,
            backgroundPosition: "center",
            backgroundSize: "70%", // or I can use contain(contains all of the original icons sizes) to cover the full inner circle, since the icons are big the circle would have to be bigger
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      {/* Ternary for points balance */}
      {pointsBalance < 25000 ? (
        <>
          <div className={className}>
            <div style={unlockLevelStyle}>
              Unlock {""}
              <span style={NameLevelValueStyle}>{paramLevel.level}</span>
            </div>
            <div style={pointsToGoStyle}>
              <span style={CurrentPntsBalStyle}>
                {paramLevel.upToNextLevel} {""} points to go!
              </span>
            </div>
            <div className="balanceBelowCircle">
              <p>Current points balance</p>
              <span className="currentRewardPointsBalance">
              {commaNumberFormat(pointsBalance)}
              </span>
            </div>
            <div id="pointsTermsLinkContainer">
              <HashLink
                smooth
                to="/terms-and-conditions#top"
                style={{
                  color: "var(--color-yellow)", // Inline styling with CSS variable
                  textDecoration: "underline", // Apply underline style
                }}
              >
                View Terms and Conditions for Reward Points
              </HashLink>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={className}>
            <div style={unlockLevelStyle}>
              <span style={NameLevelValueStyle}>Black Diamond</span>
            </div>
            <div className="balanceBelowCircle">
              <p>Current points balance</p>
              <span className="currentRewardPointsBalance">
              {commaNumberFormat(pointsBalance)}
              </span>
            </div>
            <div id="pointsTermsLinkContainer">
              <HashLink
                smooth
                to="/terms-and-conditions#top"
                style={{
                  color: "var(--color-yellow)", // Inline styling with CSS variable
                  textDecoration: "underline", // Apply underline style
                }}
              >
                View Terms and Conditions for Reward Points
              </HashLink>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default PointsCircle;
