import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TooltipDots from "../toolTipDots/toolTipDots";
import "./videoPlayer.scss";

const VideoMonitor = ({
  videoRef,
  src,
  title,
  skitId, // this will be used to take the user to the skit url component, which is the skit home or profile page
  autoPlay,
  onLoadedMetadata,
  className = "",
  showTitleOverlayLogic = true, // New prop to toggle title logic
  isAd = false, // New prop to identify if the video is an ad
}) => {
  const navigate = useNavigate();
  const [showTitleOverlay, setShowTitleOverlay] = useState(true);

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleTimeUpdate = () => {
      const currentTime = videoElement.currentTime;
      // Dynamically hide/show title based on playback
      setShowTitleOverlay(currentTime === 0);
    };
    const handleMetadataLoaded = () => {
      if (videoElement && videoElement.duration) {
        // make sure we pass the prop the warning will mean we have to pass it
        if (typeof onLoadedMetadata === "function") {
          onLoadedMetadata(videoElement.duration);
        } else {
          console.warn(
            "onLoadedMetadata callback is not provided or not a function."
          );
        }
      } else {
        console.warn("Metadata loaded but duration is not available.");
      }
    };

    if (videoElement && src) {
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
      videoElement.addEventListener("loadedmetadata", handleMetadataLoaded);
      return () => {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        videoElement.removeEventListener(
          "loadedmetadata",
          handleMetadataLoaded
        );
      };
    }
  }, [videoRef, onLoadedMetadata, src]);
  // Navigate to skit detail page
  const skitUrl = () => {
    if (!isAd && skitId) {
      navigate(`/skit/${skitId}`);
    } else {
      console.error("Skit ID is missing, cannot navigate to detail page.");
    }
  };

  return (
    <div
      className={`video-monitor-container ${className}`}
      onClick={skitUrl} // Navigate when clicking the monitor container
      style={{
        position: "relative",
        width: "100%",
        height: 0,
        paddingBottom: "62.25%",
        cursor: isAd ? "default" : "pointer", // Disable pointer for ads
      }}
    >
      {showTitleOverlayLogic && showTitleOverlay && !isAd && (
        <div
          style={{
            position: "absolute",
            top: 0,
            marginTop: "10px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "var(--color-offwhite)",
            zIndex: 1,
            padding: "30px 0",
            width: "50%",
            fontSize: "1rem",
            textAlign: "center",
            WebkitBackdropFilter: "blur(10px)",
            backdropFilter: "blur(10px)",
            background: "rgba(0, 0, 0, 0.5)",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <TooltipDots text={title} maxLength={12} />
        </div>
      )}
      <video
        ref={videoRef}
        src={src}
        autoPlay={autoPlay}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        // The onError handler on the <video> tag logs errors if the src is invalid
        // The logic ensures that invalid URLs do not crash the app but provide meaningful errors
        onError={(e) => {
          console.error("Error loading video:", e.target.error);
        }}
      />
    </div>
  );
};

export default React.memo(VideoMonitor);
