import React, { useState, useEffect } from "react"; // Import necessary hooks from React
import { DownArrowIcon, UpArrowIcon } from "../icons"; // Import arrow icons for the dropdown
import "./neumorphicDropDwn.scss"; // Import the corresponding stylesheet

// This NeumorphicDropDwn component will be used with a constant file called dropdown dropdownOptions
const NeumorphicDropDwn = ({
  options, // Array of options to display in the dropdown
  selectedOption, // Currently selected option
  onChange, // Function to handle changes
  placeholder, // Placeholder variable used for dynamic text in this dropdown component
  isOpen, // Boolean state indicating if the dropdown is open
  setOpen, // Function to set the open state
  className, // Receive className prop for error styling
  onFocus,
  error,
}) => {
  const [currentSelection, setCurrentSelection] = useState(placeholder); // State to hold the current selection or placeholder
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // State to hold the index of the highlighted option

  // Function to handle selecting an option
  const handleShowChoice = (e, choice) => {
    e.stopPropagation(); // Prevent the click event from bubbling up
    setOpen(false); // Close the dropdown
    setCurrentSelection(choice.label); // Set the current selection to the label of the chosen option
    onChange({ target: { value: choice.value } }); // Trigger the onChange function with the selected value
    setHighlightedIndex(-1); // Reset the highlighted index
  };

  // Function to handle keyboard navigation
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault(); // Prevent default behavior of arrow down key
      setHighlightedIndex(
        (prevIndex) => (prevIndex < options.length - 1 ? prevIndex + 1 : 0) // Move highlight down or loop back to top
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault(); // Prevent default behavior of arrow up key
      setHighlightedIndex(
        (prevIndex) => (prevIndex > 0 ? prevIndex - 1 : options.length - 1) // Move highlight up or loop to bottom
      );
    } else if (e.key === "Enter" && highlightedIndex !== -1) {
      e.preventDefault(); // Prevent default behavior of enter key
      handleShowChoice(e, options[highlightedIndex].value); // Select the highlighted option
    } else if (e.key === "Escape") {
      setOpen(false); // Close the dropdown on escape key
    }
  };

  // Function to handle mouse entering an option
  const handleMouseEnter = (index) => {
    setHighlightedIndex(index); // Set the highlighted index to the hovered option
  };

  // Function to handle mouse leaving an option
  const handleMouseLeave = () => {
    setHighlightedIndex(-1); // Reset the highlighted index when mouse leaves
  };

  // Effect to handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".neumorphicDropDwn")) {
        setOpen(false); // Close the dropdown if click is outside the component
      }
    };

    document.addEventListener("click", handleOutsideClick); // Add event listener for clicks
    return () => {
      document.removeEventListener("click", handleOutsideClick); // Clean up the event listener
    };
  }, [setOpen]);

  // Effect to update the current selection when the selected option or placeholder changes
  useEffect(() => {
    if (selectedOption) {
      const selected = options.find(
        (option) => option.value === selectedOption
      ); // Find the selected option
      setCurrentSelection(selected ? selected.label : placeholder); // Update current selection to selected option's label or placeholder
    } else {
      setCurrentSelection(placeholder); // Update current selection to placeholder
    }
  }, [selectedOption, placeholder, options]); // Run effect when selectedOption, placeholder, or options change
  const handleFocus = () => {
    if (onFocus) onFocus();
  };



  return (
    <div
      className="neumorphicDropDwn" // Root div with class for styling
      onClick={() => setOpen(!isOpen)} // Toggle dropdown open state on click
      onKeyDown={handleKeyDown} // Handle keyboard navigation
      tabIndex="0" // Make div focusable for keyboard navigation
      onFocus={handleFocus}
    >
      <input
        type="text"
        className={`textBox ${className} ${error ? "error-message-style" : ""}`}  // Apply dynamic class for error styling on the input
        // Input field with class for styling
        placeholder={error || placeholder} // Set placeholder to error if present, otherwise use default placeholder        // Set placeholder text
        value={error ? error : currentSelection} // Set value to current selection, see notes below component on why it works
        readOnly // Make input read-only
      />
      <div className="dropdown-icon">
        {isOpen ? (
          <UpArrowIcon fillColor="var(--color-bloodred)" /> // Show up arrow if dropdown is open
        ) : (
          <DownArrowIcon fillColor="var(--color-bloodred)" /> // Show down arrow if dropdown is closed
        )}
      </div>
      <div className={`options ${isOpen ? "open" : "close"}`}>
        {options.map((option, index) => (
          <div
            key={index} // Unique key for each option
            onClick={(e) => handleShowChoice(e, option)} // Handle option click
            onMouseEnter={() => handleMouseEnter(index)} // Handle mouse enter
            onMouseLeave={handleMouseLeave} // Handle mouse leave
            className={`option ${
              option.value === selectedOption ? "active-dropdown" : "" // Apply active class to selected option
            } ${highlightedIndex === index ? "highlighted-option" : ""}`} // Apply highlighted class to hovered option
          >
            {option.label} {/* Display option label */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NeumorphicDropDwn; // Export the component as default

// currentSelection State Management:

// Inside the NeumorphicDropDwn component, the 
// state variable currentSelection is used to manage 
// what is displayed in the input field.
// This state is initialized to the placeholder value 
// and then updated whenever a selection is made or 
// when the selectedOption changes. The useEffect ensures 
// that any change to selectedOption or placeholder reflects in the currentSelection.