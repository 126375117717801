import { validatePasswordValue } from "../../constants/validate.js";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
export const onSignInFocusPassword = (
  password,
  setSigninPasswordFocused,
  setPasswordValidationRules,
  setToolTipVisible
) => {
  setSigninPasswordFocused(true);
  const signinPasswordValidationResults = validatePasswordValue(password).map(
    (rule) => ({
      ...rule,
      isValid: rule.isValid,
    })
  );
  setPasswordValidationRules(signinPasswordValidationResults);
  setToolTipVisible(
    signinPasswordValidationResults.some((rule) => !rule.isValid) // only show the tool tip on focus if any rule is not valid
  );
};
export const onSignInBlurPassword = (
  setSigninPasswordFocused,
  setToolTipVisible
) => {
  setSigninPasswordFocused(false);
  setToolTipVisible(false);
};

// Function to reset all form states
export const resetFormState = (
  setUserNameValue,
  setPhoneNumberValue,
  setEmailValue,
  setCredentialValueError,
  setUserNameFocused,
  setPhoneNumberFocused,
  setEmailFocused
) => {
  setUserNameValue("");
  setPhoneNumberValue("");
  setEmailValue("");
  setCredentialValueError("");
  setUserNameFocused(false);
  setPhoneNumberFocused(false);
  setEmailFocused(false);
};

export const signinPasswordWatcher = (
  value,
  setSignInPasswordValue,
  currentContext,
  validatePasswordValue,
  setPasswordValidationRules,
  setToolTipVisible,
  signInPasswordError,
  setSignInPasswordError
) => {
  setSignInPasswordValue(value); // Update state
  const signInPasswordKey = getStorageKey("passwordValue", currentContext);
  localStorage.setItem(signInPasswordKey, value);
  const validationResults = validatePasswordValue(value);
  setPasswordValidationRules(validationResults);
  setToolTipVisible(!validationResults.every((rule) => rule.isValid)); // close the tooltip when all the rules are valid
  if (signInPasswordError) {
    setSignInPasswordError("");
  }
};

export const phoneInputWatcher = (
  value,
  setPhoneNumberValue,
  currentContext,
  credentialValueError,
  setCredentialValueError
) => {
  setPhoneNumberValue(value);
  const signinPhoneNumberKey = getStorageKey(
    "verifyAccountValue",
    currentContext
  );
  localStorage.setItem(signinPhoneNumberKey, value);
  if (credentialValueError) {
    setCredentialValueError("");
  }
};

export const emailInputWatcher = (
  value,
  setEmailValue,
  currentContext,
  credentialValueError,
  setCredentialValueError
) => {
  setEmailValue(value);
  const signinEmailKey = getStorageKey("emailValue", currentContext);
  localStorage.setItem(signinEmailKey, value);
  if (credentialValueError) {
    setCredentialValueError("");
  }
};

export const userNameInputWatcher = (
  value,
  setUserNameValue,
  currentContext,
  credentialValueError,
  setCredentialValueError
) => {
  setUserNameValue(value);
  const signinUserNameKey = getStorageKey("userNameValue", currentContext);
  localStorage.setItem(signinUserNameKey, value);
  if (credentialValueError) {
    setCredentialValueError("");
  }
};
