import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UsersWhoRatedList from "../../components/votedUsersSkitList/votedUsersSkitList";
import { LeftArrow } from "../../components/icons";
import { SolidColorButton } from "../../components/buttons";
import { SingularPlural } from "../../utils/wordUtils";
import { apiFetchUserRatedSkitActivity } from "../../api/backend/user";
import Loader from "../../components/loader/loader";

function UsersWhoVotedListingPage() {
  let navigate = useNavigate();
  let { skitId } = useParams();
  const [usersWhoRated, setUsersWhoRated] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;

  useEffect(() => {
    const fetchUsersWhoRated = async () => {
      setLoading(true);
      try {
        const response = await apiFetchUserRatedSkitActivity({
          relatedId: skitId,
          offset,
          limit,
        });
        if (response.length) {
          setUsersWhoRated((prev) => [...prev, ...response]);
          setError("");
          if (response.length < limit) setHasMore(false);
        } else {
          setError("No users found who rated this skit.");
          setHasMore(false);
        }
      } catch (err) {
        console.error("Failed to fetch users who rated the skit:", err);
        setError("Failed to load user ratings");
      } finally {
        setLoading(false);
      }
    };

    fetchUsersWhoRated();
  }, [skitId, offset]);

  const handleViewMore = () => {
    setOffset((prevOffset) => prevOffset + limit);
  };

  return (
    <div id="skitRatersTop">
      <SolidColorButton
        solidColorBtnImage={
          <LeftArrow
            height="15px"
            width="15px"
            fillColor="#964b00"
            strokeWidth="4"
            strokeColor="#964b00"
          />
        }
        className="gold-btn"
        solidColorBtnText="Back"
        solidColorBtnFontWeight="800"
        solidColorBtnFontFamily="helvetica"
        solidColorBtnTextFontSize=".75rem"
        solidColorBtnFontColor="#964b00"
        solidColorBtnWidth="100px"
        solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
        onClick={() => navigate(-1)}
      />
      {usersWhoRated.length > 0 && (
        <h2>{usersWhoRated.length} {SingularPlural(usersWhoRated.length, "Rater")}</h2>
      )}
      {loading && offset === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <UsersWhoRatedList
          usersWhoRated={usersWhoRated}
          hasMore={hasMore}
          handleViewMore={handleViewMore}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
}
export default UsersWhoVotedListingPage;
