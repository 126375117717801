import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import {
  apiCanceledSignupDeleteUserRecord,
  apiSkipVerification,
} from "../../api/backend/auth.js";
import {
  LoginForm,
  SignupForm,
  PhoneVerification,
  AccountVerification,
  ResetPassword,
  BuildProfileSignupForm,
  UpdateAccountHolderForm,
} from "./index";
import { CONTEXT_TYPES } from "../../constants/contextTypes";

// form flow control and security
const AuthorizationForm = () => {
  const navigate = useNavigate();
  const { currentUser, setUserInfo, userAttributes, setUserAttributes } =
    useAuth();
  const [currentForm, setCurrentForm] = useState(CONTEXT_TYPES.SIGN_IN);
  const [contextType, setContextType] = useState(CONTEXT_TYPES.SIGN_IN);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    console.log("AuthorizationForm mounted with currentUser:", currentUser);
    if (currentUser) {
      setUserId(currentUser);
    }
  }, [currentUser]);

  const cancelSignupFlow = async (e) => {
    e.preventDefault();
    console.log("Cancel button clicked");
    // const userId = localStorage.getItem("userId");
    const userId = currentUser; // Use currentUser from context
    console.log("User ID:", userId);
    localStorage.clear();
    setCurrentForm(CONTEXT_TYPES.SIGN_IN);
    setContextType(CONTEXT_TYPES.SIGN_UP);

    if (userId) {
      try {
        const response = await apiCanceledSignupDeleteUserRecord(userId);
        console.log("Cancel API Response:", response);
        if (response.data.status !== "OK") {
          console.error("Failed to delete user record:", response.data);
        }
      } catch (error) {
        console.error("Error canceling the signup process:", error);
      }
    }
  };

  const cancelResetFlow = async (e) => {
    e.preventDefault();
    console.log("Cancel button clicked");
    // const userId = localStorage.getItem("userId");
    const userId = currentUser; // Use currentUser from context
    console.log("User ID:", userId);
    localStorage.clear();
    setCurrentForm(CONTEXT_TYPES.SIGN_IN);
    setContextType(CONTEXT_TYPES.RESET_PASSWORD);
  };

  const onVerifyAccount = async (user, contextType) => {
    try {
      if (user && user.id) {
        const userId = user.id;
        setUserId(userId);
        setUserInfo(userId); // Store user ID in cookies via Auth context
        // Store missing fields in local storage for pre-filling the form
        localStorage.setItem(
          getStorageKey("firstNameValue", "signup"),
          user.firstName || ""
        );
        localStorage.setItem(
          getStorageKey("lastNameValue", "signup"),
          user.lastName || ""
        );
        localStorage.setItem(
          getStorageKey("emailValue", "signup"),
          user.email || ""
        );
        if (
          contextType === CONTEXT_TYPES.COMPLETE_SIGNUP ||
          contextType === CONTEXT_TYPES.SIGN_UP
        ) {
          if (!user.otpVerified) {
            setCurrentForm(CONTEXT_TYPES.VERIFY_ACCOUNT);
            setContextType(CONTEXT_TYPES.COMPLETE_SIGNUP);
          } else if (!user.firstName || !user.lastName || !user.email) {
            setCurrentForm(CONTEXT_TYPES.BUILD_PROFILE);
            setContextType(CONTEXT_TYPES.COMPLETE_SIGNUP);
          } else {
            setCurrentForm(CONTEXT_TYPES.SIGN_IN);
            setContextType(CONTEXT_TYPES.SIGN_IN);
          }
        } else if (contextType === CONTEXT_TYPES.SIGN_UP) {
          setCurrentForm(CONTEXT_TYPES.VERIFY_ACCOUNT);
          setContextType(CONTEXT_TYPES.SIGN_UP);
        } else if (contextType === CONTEXT_TYPES.RESET_PASSWORD) {
          setCurrentForm(CONTEXT_TYPES.VERIFY_ACCOUNT);
          setContextType(CONTEXT_TYPES.RESET_PASSWORD);
        } else {
          setCurrentForm(CONTEXT_TYPES.SIGN_IN);
          setContextType(CONTEXT_TYPES.SIGN_IN);
        }
      } else {
        console.error("User object is not provided or userId is missing.");
        setCurrentForm(CONTEXT_TYPES.VERIFY_ACCOUNT);
        setContextType(CONTEXT_TYPES.SIGN_UP);
      }
    } catch (error) {
      console.error(
        "Error during verification or determining the next step:",
        error
      );
      setCurrentForm(CONTEXT_TYPES.SIGN_IN);
      setContextType(CONTEXT_TYPES.SIGN_IN);
    }
  };

  useEffect(() => {
    console.log("Current form updated to:", currentForm);
  }, [currentForm]);

  const performSkipVerification = async (e, userId) => {
    e.preventDefault();
    try {
      console.log("Before API call");
      const response = await apiSkipVerification(userId);
      console.log("After API call", response);

      if (response.data.status === "OK") {
        console.log("Verification skipped successfully:", response.data.user);
        setUserId(userId);
        setUserInfo(userId); // Store user ID in cookies
        setCurrentForm(CONTEXT_TYPES.BUILD_PROFILE);
        setContextType(CONTEXT_TYPES.COMPLETE_SIGNUP);
      } else {
        console.error("Verification was not successful:", response);
      }
    } catch (error) {
      console.error("Request failed", error);
    }
  };

  const formKey = `${currentForm}-${userId || "none"}`;

  return (
    <div>
      {currentForm === CONTEXT_TYPES.SIGN_IN && (
        <LoginForm
          key={formKey}
          userId={userId}
          setContextType={setContextType}
          onVerifyAccount={onVerifyAccount}
          onSignUpClick={() => {
            setCurrentForm(CONTEXT_TYPES.SIGN_UP);
            setContextType(CONTEXT_TYPES.SIGN_UP);
          }}
          onResetPasswordClick={() => {
            setCurrentForm(CONTEXT_TYPES.ACCOUNT_VERIFICATION);
            setContextType(CONTEXT_TYPES.RESET_PASSWORD);
          }}
          onCancel={cancelSignupFlow}
        />
      )}
      {currentForm === CONTEXT_TYPES.SIGN_UP && (
        <SignupForm
          key={formKey}
          userId={userId}
          setContextType={setContextType}
          onVerifyAccount={onVerifyAccount}
          onCancel={cancelSignupFlow}
        />
      )}
      {currentForm === CONTEXT_TYPES.VERIFY_ACCOUNT && (
        <PhoneVerification
          key={formKey}
          userId={userId}
          contextType={contextType} // Pass contextType as a prop
          onMobileVerification={(userId) => {
            console.log(
              "PhoneVerification - Before calling onVerifyAccount, UserID:",
              userId
            );
            // Check the contextType to determine the next form
            if (contextType === CONTEXT_TYPES.RESET_PASSWORD) {
              // Navigate to the reset password form
              setCurrentForm(CONTEXT_TYPES.RESET_PASSWORD);
              setContextType(CONTEXT_TYPES.RESET_PASSWORD);
            } else if (
              contextType === CONTEXT_TYPES.COMPLETE_SIGNUP ||
              contextType === CONTEXT_TYPES.SIGN_UP
            ) {
              // Navigate to the build profile form
              setCurrentForm(CONTEXT_TYPES.BUILD_PROFILE);
              setContextType(CONTEXT_TYPES.COMPLETE_SIGNUP);
            }
          }}
          onSkipVerification={(e) => performSkipVerification(e, userId)}
          onCancel={
            contextType === CONTEXT_TYPES.RESET_PASSWORD
              ? cancelResetFlow
              : cancelSignupFlow
          }
        />
      )}
      {currentForm === CONTEXT_TYPES.ACCOUNT_VERIFICATION && (
        <AccountVerification
          key={formKey}
          userId={userId}
          setContextType={setContextType}
          onVerifyAccount={onVerifyAccount}
          contextType={contextType} // Pass the correct context type here
          onCancel={cancelResetFlow}
        />
      )}
      {currentForm === CONTEXT_TYPES.RESET_PASSWORD && (
        <ResetPassword
          key={formKey}
          userId={userId}
          onUpdateAccount={() => {
            setUserId(userId);
            setUserInfo(userId);
            setContextType(CONTEXT_TYPES.RESET_PASSWORD);
            navigate("/skitslist");
          }}
          onCancel={cancelResetFlow}
        />
      )}
      {currentForm === CONTEXT_TYPES.BUILD_PROFILE && (
        <BuildProfileSignupForm
          key={formKey}
          onCompleteProfile={() => {
            setUserId(userId); // Set the user ID in the state or context
            setUserInfo(userId); // calling user id to hit the next line
            navigate("/skitslist"); // redirect user to page
            setContextType(CONTEXT_TYPES.SIGN_IN); // Transition to the SIGN_IN state
          }}
          onCancel={cancelSignupFlow}
        />
      )}
      {currentForm === CONTEXT_TYPES.ACCOUNT_HOLDER_UPDATE && (
        <UpdateAccountHolderForm
          key={formKey}
          profileData={userAttributes}
          currentUser={userId}
          setUserAttributes={setUserAttributes} // Pass the setter directly
        />
      )}
    </div>
  );
};
export default React.memo(AuthorizationForm);
