// getLocalStorageValues: Fetches multiple values from localStorage in a single call by looping over a list of keys.
export const getStorageKey = (baseKey, currentContext) =>
  `${currentContext}_${baseKey}`; // generating a unique key for local storage key value pairs solutions, else it will be global and cause filling issues

// Dynamic Key Management:
// Avoids key collisions in localStorage by scoping data to a specific context.
// Makes it easier to debug and manage stored values (e.g., when clearing keys).
// Efficient Retrieval:
// Reduces boilerplate code when accessing multiple keys by centralizing the logic.

export const getLocalStorageValues = (keys, currentContext) => {
  return keys.reduce((values, key) => {
    const storageKey = getStorageKey(key, currentContext);
    values[key] = localStorage.getItem(storageKey) || "";
    return values;
  }, {});
};

export const setLocalStorageValues = (values, currentContext) => {
  Object.entries(values).forEach(([key, value]) => {
    const storageKey = getStorageKey(key, currentContext);
    localStorage.setItem(storageKey, value);
  });
};
