import {
  apiRecordThumbnailEmotionconId,
} from "../api/backend/user";

/**
 * Function to fetch the current or source user's details.
 * @param {Object} params - Parameters to fetch user details.
 * @param {string} params.currentUser - The ID of the currently logged-in user.
 * @param {string} params.userId - The ID of the user to fetch details for.
 * @param {function} params.setProfileData - State setter for updating profile data.
 * @param {function} params.setAccountHolder - State setter for defining account ownership.
 * @param {function} params.setThumbnailPreference - State setter for updating thumbnail preference.
 * @param {function} params.setCurrentEmotionId - State setter for updating selected emoji.
 * @param {function} params.setLoading - State setter to toggle loading status.
 * @param {function} params.fetchDetailsOnProfileClick - Function to fetch details of the current user.
 * @param {function} params.getSourceAccountHolderDetailsById - Function to fetch details of another user by ID.
 * @param {function} params.setError - State setter for managing error status.
 * @param {function} params.setSkitsData - State setter for resetting skits data.
 * @param {function} params.setNoSkitsAvailable - State setter for handling skits availability.
 */
export const fetchUserDetails = async ({
  currentUser,
  userId,
  setProfileData,
  setAccountHolder,
  setThumbnailPreference,
  setCurrentEmotionId,
  setLoading,
  fetchDetailsOnProfileClick,
  getSourceAccountHolderDetailsById,
  setError,
  setSkitsData,
  setNoSkitsAvailable,
}) => {
  try {
    // Clear error and reset states
    setError(null);
    setSkitsData([]);
    setNoSkitsAvailable(false);

    let userDetails = null;

    // Check if the current user is accessing their own profile or another user's profile
    if (userId === currentUser) {
      // Fetch details of the current user
      userDetails = await fetchDetailsOnProfileClick();
      setAccountHolder(true);
    } else {
      // Fetch details of another user by ID
      userDetails = await getSourceAccountHolderDetailsById(userId);
      setAccountHolder(false);
    }

    // Check if userDetails is defined before accessing properties
    if (userDetails) {
      setProfileData(userDetails);
      setThumbnailPreference(userDetails.thumbnailPreference || "default"); // Provide a default value if undefined
      setCurrentEmotionId(userDetails.emotionconId || null);
    } else {
      setError("Failed to load user details.");
      console.error("User details are undefined.");
    }

  } catch (error) {
    console.error("Error fetching user details:", error);
    setError("An error occurred while loading user details.");
  } finally {
    setLoading(false); // Ensure loading is turned off regardless of success or failure
  }
};

/**

/**
 * Selects an emoji and updates it in the user profile, then refreshes user details.
 * @param {Object} params - Parameters for handling emoji selection.
 */
export const handleSelectEmoji = async ({
  userId,
  emotionconId,
  currentEmotionId,
  setCurrentEmotionId,
  setShowAllEmojis,
  fetchUserDetails,
}) => {
  if (emotionconId !== currentEmotionId) {
    setCurrentEmotionId(emotionconId);
    setShowAllEmojis(false);
  }
  try {
    await apiRecordThumbnailEmotionconId(userId, emotionconId);
    fetchUserDetails();
  } catch (error) {
    console.error("Error updating emotionconId:", error);
  }
};
