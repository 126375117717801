import { CheckMarkIcon } from "../icons";
import {ImgTopBtn } from "../buttons";
import "./tooltip.scss";

const ToolTip = ({ 
  rules = [], 
  setToolTipVisible, 
  toolTipVisible 
}) => {
  return (
    toolTipVisible && (
      <div className="tooltip-container">
        <ImgTopBtn
          imgTopBtnText="X Close"
          marginLeft="70%"
          imgTopBtnTextFontSize=".7rem"
          imgTopBtnWidth="70px"
          imgTopBtnFontColor="#b03a2e"
          className="close-button"
          onClick={() => setToolTipVisible(false)}
        ></ImgTopBtn>
        <ul>
          {rules.map((rule, index) => (
            <li key={`${rule.message}-${index}`}>
            {rule.isValid ? (
                <CheckMarkIcon />
              ) : (
                <span className="error-text">X</span>
              )}
              <span style={{ fontFamily: "roboto-slab", fontWeight: "900" }}>
                {rule.message}
              </span>
            </li>
          ))}
        </ul>
      </div>
    )
  );
};
export default ToolTip;
