import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Remote from "../../components/remote/remote";
import UploadVideoForm from "../../components/forms/uploadVideoForm";
import { useAuth } from "../../components/context/authContext";
import { useVideoFormStateCallBackController } from "../../controller/videoFormCallBackController";
import "./infoPage.scss";

const InfoPage = () => {
  const { currentUser, handleLogout } = useAuth();
  const navigate = useNavigate();
  const [colorPaletteVisible, setColorPaletteVisible] = useState(false);
  const [sticky, setSticky] = useState(false);
  const {
    openSkitUploadForm,
    setOpenSkitUploadForm,
    openVideoForm,
    afterUploadCloseModal,
  } = useVideoFormStateCallBackController();
  const toggleColorPaletteVisibility = () => {
    setColorPaletteVisible(!colorPaletteVisible);
  };
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setSticky(scrollTop > 100); // You might adjust the scroll threshold as needed
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const onLogoutClick = () => {
    handleLogout();
    navigate("/");
  };

  return (
    <div id="infoTop">
      <h1>Business and Site Info</h1>
      <Remote
        showChannels={false}
        onUploadClick={openVideoForm}
        toggleColorPaletteVisibility={toggleColorPaletteVisibility}
        colorPaletteVisible={colorPaletteVisible}
        setColorPaletteVisible={setColorPaletteVisible}
        onClick={onLogoutClick}
      />
      {openSkitUploadForm && (
        <UploadVideoForm
          key={Date.now()} // Each time the button is clicked, a new key is generated
          isUploadVideoFormOpen={openSkitUploadForm}
          closeModal={() => setOpenSkitUploadForm(false)} // Directly setting state to close
          currentUser={currentUser}
          afterModalClose={afterUploadCloseModal}
        />
      )}
    </div>
  );
};
export default InfoPage;
