// src/pages/searchAccountsPage/searchAccountsPage.js
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LeftArrow } from "../../components/icons";
import { SolidColorButton, MemberSlider } from "../../components/buttons";
import { apiSearchMembersByUsername } from "../../api/backend/user";
import SearchBar from "../../components/search/search";
import "./searchAccountsPage.scss";

const SearchAccountsPage = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const userNameValueQuery = location.state?.query || "";
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = async (searchQuery) => {
    console.log("Search for:", searchQuery);
    try {
      const users = await apiSearchMembersByUsername({
        membersUserName: searchQuery,
      });
      setSearchResults(users);
    } catch (error) {
      console.error("Error searching users:", error);
    }
  };
  const handleProfileThumbnailSlideEnd = (userId) => {
    if (userId) {
      console.log("Navigating to user profile:", userId);
      navigate(`/user-profile/${userId}`);
    } else {
      console.log("User ID is undefined, not navigating.");
      // Handle the undefined case, e.g., show a message or do nothing
    }
  };

  return (
    <div className="search-accounts-page">
      <SolidColorButton
        solidColorBtnImage={
          <LeftArrow
            height="15px"
            width="15px"
            fillColor="#964b00"
            strokeWidth="4"
            strokeColor="#964b00"
          />
        }
        className="gold-btn"
        solidColorBtnText="Back"
        solidColorBtnFontWeight="800"
        solidColorBtnFontFamily="helvetica"
        solidColorBtnTextFontSize=".75rem"
        solidColorBtnFontColor="#964b00"
        solidColorBtnWidth="100px"
        solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
        onClick={() => navigate(-1)}
      />
      <SearchBar onSearch={handleSearch} initialQuery={userNameValueQuery} />
      <div className="search-results">
        {searchResults.length > 0 ? (
          searchResults.map((user) => (
            <div key={user.id} className="user-entry">
              <MemberSlider
                user={user}
                slideBtnText="View Profile"
                onHandleSlideEnd={handleProfileThumbnailSlideEnd}
              />
              <div className="user-details">
                <span className="username">{user.username}</span>
                <span className="fullname">
                  {user.firstName} {user.lastName}
                </span>
              </div>
            </div>
          ))
        ) : (
          <p>No users found</p>
        )}
      </div>
      {/* Add search results display here */}
    </div>
  );
};

export default SearchAccountsPage;
