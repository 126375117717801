import { convertToSlug } from "../../../utils/stringUtils.js";
import CatIcons from "../../icons/categoryIcons.jsx";
import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator.js";
import "./catVertical.scss";

// this component is used to display parent categories but can be easily used for the child (code extensiblity philosphy) for the future
// any submit or other buttons will need to be placed on the parent component that this is nested on since this is used for mapping,
// or it will show the button on every category if placed here, only place a button here if that is the intended result
const CategoryNameFormatSearch = ({
  categoryName,
  categoryId,
  handleCategoryClick,
  contextType,
}) => {
  const handleCategorySelect = () => {
    const key = getStorageKey("selectedCategory", contextType);
    localStorage.setItem(key, JSON.stringify({ categoryName, categoryId }));
    handleCategoryClick(categoryName, categoryId);
  };
  // This component takes itemName as a prop and uses it to
  //  display the corresponding icon and formatted name.
  // This function to get the width for every service name to always two lines
  function getWidth(categoryname) {
    let result = "";
    switch (
      categoryname // First, check for specific names
    ) {
      case "Graphic & Design":
        result = "97px";
        break;
      case "Tech & Programming":
        result = "108px";
        break;
      case "Psychologist Social Services":
        result = "108px";
        break;
      case "Book-ebook Publishing":
        result = "123px";
        break;
      case "Podcasting Services":
        result = "112px";
        break;
      case "Math & Computers":
        result = "100px";
        break;
      case "Food Services":
        result = "79px";
        break;
      case "Protective Services":
        result = "102px";
        break;
      case "Hospitality & Care":
        result = "100px";
        break;
      case "Wellness Services":
        result = "94px";
        break;
      case "Legal Services":
        result = "74px";
        break;
      case "Babies & Kids":
        result = "75px";
        break;
    }
    // If result is still empty, check based on length
    if (!result) {
      switch (categoryname.length) {
        case 13:
          result = "76px";
          break;
        case 14:
          result = "80px";
          break;
        case 16:
          result = "89px";
          break;
        case 18:
          result = "96px";
          break;
        case 19:
          result = "100px";
          break;
        case 15:
          result = "89px";
          break;
        case 12:
          result = "86px";
          break;
        case 25:
          result = "145px";
          break;
        case 21:
          result = "118px";
          break;
        case 20:
          result = "100px";
          break;
        case 26:
          result = "144px";
          break;
        case 24:
          result = "130px";
          break;
        case 28:
          result = "40%";
          break;
        case 17:
          result = "100px";
          break;
        case 30:
          result = "140px";
          break;
        case 22:
          result = "121px";
          break;
        // default:
        //   result = "40%";
      }
    }
    return result;
  }

  function formatName(categoryname) {
    if (categoryname === "Legal Services") return "Legal\nServices";
    return categoryname.replace(" & ", "\xa0&\n");
  }

  return (
    <div className="CategoryServiceWrapper">
      <div className="CategoryService" onClick={handleCategorySelect}>
        <div className="center-menu-link">
          {/* Icon Mapping Object: This object (CatIcons) contains mappings from category 
        name slugs to their corresponding SVG icons. It's imported and used in your component 
        to dynamically render icons based on the category name. The convertToSlug function usually 
        removes special characters, replaces spaces with hyphens, and converts the string to lowercase. 
        This way, you can use the category name to look up the corresponding SVG 
        icon in the CatIcons object. */}
          {CatIcons[convertToSlug(categoryName)]}
          <span style={{ width: getWidth(categoryName) }}>
            {formatName(categoryName)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CategoryNameFormatSearch;
