import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MemberSlider from "../memberSlider/memberSlider";
import { NoBellIcon, EyeSlashIcon } from "../icons";
import { StripeAnimationBtn, ImgTopBtn } from "../buttons";
import SubscriptionsSearchBar from "../search/subscriptionSearchBar";
import Loader from "../loader/loader";
import "./subscriptionsList.scss";

const SubscriptionsList = ({
  subscriptions,
  setSubscriptions,
  filteredSubscriptions,
  setFilteredSubscriptions,
  setTotalSubscriptions,
  currentUser,
  message,
  offset,
  handleUnsubscribeClick,
  handleViewMore,
  loading,
  hasMore,
}) => {
  const navigate = useNavigate();

  const handleProfileThumbnailSlideEnd = (userId) => {
    if (userId) {
      console.log("Navigating to user profile:", userId);
      navigate(`/user-profile/${userId}`);
    } else {
      console.log("User ID is undefined, not navigating.");
    }
  };

  const handleSearch = (searchQuery) => {
    if (!searchQuery) {
      // Reset the filtered list when the search query is empty
      setFilteredSubscriptions(subscriptions);
      return;
    }
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = subscriptions.filter((subscription) =>
      subscription.target.username.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredSubscriptions(filtered);
  };

  useEffect(() => {
    setFilteredSubscriptions(subscriptions);
  }, [subscriptions]);

  if (message && offset === 0) return <div>{message}</div>;

  return (
    <div>
      <SubscriptionsSearchBar onSearch={handleSearch} initialQuery="" />
      {filteredSubscriptions.map((subscription) => (
        <div key={subscription.target.id} className="subscription-info">
          <MemberSlider
            user={subscription.target}
            slideBtnText="Slide to View Profile"
            onHandleSlideEnd={() => {
              console.log("User ID being passed:", subscription.target.id);
              handleProfileThumbnailSlideEnd(subscription.target.id);
            }}
          />
          <div className="user-details">
            <span className="subscription-username">
              {subscription.target.username}
            </span>
          </div>
          <StripeAnimationBtn
            stripeAnimationBtnImage={
              <NoBellIcon
                height="20px"
                width="20px"
                style={{ marginRight: "3px" }}
              />
            }
            stripeAnimationBtnText="Unsubscribe"
            onClick={() => handleUnsubscribeClick(subscription.target.id)}
            extraStyles={{
              width: "235px",
              color: "rgb(176, 58, 46)",
              textShadow: "black 0px 0px",
              fontSize: "1.25rem",
              lineHeight: "14px",
              fontWeight: "700",
              fontFamily: "Roboto, sans-serif",
              textDecoration: "none",
              border: "1.5px solid var(--color-gold)",
              borderRadius: "30px",
              whiteSpace: "nowrap",
              backgroundImage:
                "linear-gradient(0deg, rgb(216, 217, 219) 0px, rgb(255, 255, 255) 80%, rgb(253, 253, 253))",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              height: "30px",
              padding: "5px",
            }}
          />
        </div>
      ))}
      {hasMore && !loading && (
        <ImgTopBtn
          className="view-more-btn"
          imgTopBtnBorderRadius="25px"
          imgTopBtnWidth="fit-content"
          imgTopBtnTextFontSize="1.3rem"
          imgTopBtnFontFamily="Roboto-Slab"
          imgTopBtnFontWeight="900"
          imgTopBtnFontColor="var(--color-bloodred)"
          paddingRight="5px"
          paddingLeft="5px"
          extraImgTopBtnStyles={{
            margin: "20px auto",
          }}
          onClick={handleViewMore}
          imgTopBtnText="View More Subscriptions"
          image={<EyeSlashIcon fillColor="var(--color-bloodred)" />}
        />
      )}
    </div>
  );
};

export default SubscriptionsList;
