import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";

export const handleFieldChange = (
  fieldName,
  value,
  setBillingDetails,
  contextType
) => {
  // Update billing details state
  setBillingDetails((prevState) => ({
    ...prevState,
    [fieldName]: value,
  }));

  // Update local storage
  localStorage.setItem(getStorageKey(fieldName, contextType), value);
};

export const handlePaymentMethodChange = (
  e,
  setPaymentMethod,
  setMerchantFee,
  contextType,
  getStorageKey
) => {
  const method = e.target.value;
  console.log("Payment method changed to:", method);

  // Update the payment method in state
  setPaymentMethod(method);

  // Calculate and update the merchant fee
  let fee = 0; // need to use let here since the variable needs to be re-assigned multiple times 
  switch (method) {
    case "visa":
      fee = 0.03;
      break;
    case "mastercard":
      fee = 0.06;
      break;
    case "americanExpress":
      fee = 0.07;
      break;
    case "discover":
      fee = 0.04;
      break;
    default:
      break;
  }

  setMerchantFee(fee);
  console.log("Merchant fee updated to:", fee);

  // Persist to localStorage
  localStorage.setItem(getStorageKey("paymentMethod", contextType), method);
  localStorage.setItem(
    getStorageKey("merchantFee", contextType),
    fee.toString()
  );
};

export const onClearAllBillingAndPaymentFields = (
  setCreditCardNumber,
  setExpirationMonth,
  setExpirationYear,
  setBillingDetails,
  contextType,
  getStorageKey
) => {
  // Clear credit card and billing details state
  setCreditCardNumber("");
  setExpirationMonth("");
  setExpirationYear("");
  setBillingDetails({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    country: "",
  });

  // Remove from local storage
  const keysToClear = [
    "creditCardNumber",
    "ccExpYear",
    "firstName",
    "lastName",
    "address1",
    "address2",
    "city",
    "postalCode",
    "country",
    "merchantFee", // Add merchant fee key
    // "paymentMethodType", when i get back we need to set paymentMethodType for points in local storage in the success part
    "ccExpMonth", // Add expiration month key
  ];

  keysToClear.forEach((key) => {
    const fullKey = getStorageKey(key, contextType); // Generate the full key
    console.log(`Removing key: ${fullKey}`); // Verify Generated Keys: Log the keys generated by getStorageKey and compare them with what is stored in localStorage.
    localStorage.removeItem(fullKey);
  });

  console.log("Cleared all billing and payment fields.");
};

export const onClearState = (
  getStorageKey,
  contextType,
  setSelectedState,
  setBillingDetails,
  setStateDropdownOpen
) => {
  const stateKey = getStorageKey("selectedState", contextType);
  localStorage.removeItem(stateKey); // Remove from localStorage
  setSelectedState(""); // Clear state in the dropdown
  setBillingDetails((prevDetails) => ({
    ...prevDetails,
    state: "", // Clear state in billingDetails
  }));
  setStateDropdownOpen(false); // Close the dropdown
};
