import React, { useState, useEffect, useCallback, useRef } from "react";
import { useAuth } from "../context/authContext.jsx";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import {
  handlefirstNameValueChange,
  handleLastNameChange,
  handleEmailChange,
} from "../../controller/watcherAndClearFunctions/signupSettingsClearsWatchersFunctions.js";
import { signupRemainingFieldsErrorHandler } from "../../controller/errorHandlers/signupRemainingFieldsErrorHandler.js";
import { PaperPlaneIcon, PaperPenWritingIcon } from "../icons/index.jsx";
import {
  InsetButtonEventListener,
  ImgTopBtn,
  SmallPlainInsetBtn,
  InsetBlendTVButton,
  FieldButton,
} from "../buttons/index.jsx";
import BlinkingLight from "../blinkingLight/blinkingLight.jsx";
import Loader from "../loader/loader.jsx";
import { apiAddAcctAtributes, apiCheckEmail } from "../../api/backend/auth.js";
import { uploadFileToS3 } from "../../api/backend/uploadToS3.js";
import {
  getPresignedImgUrl,
  apiRecordProfileImageUrlCrud,
} from "../../api/backend/user.js";
import { getImageFileType, resizeFile } from "../../constants/fileUpload";
import { checkFile } from "../../utils/checkFile";
import { CurveUpArrow, XIcon, SearchGlassIcon } from "../icons/index.jsx";
import EmotionEmoji from "../emoji/emoji.jsx";
import RadioHeadBtn from "../radioHead/radioHead.jsx";
import { errorMessages } from "../../constants/errorMessages.js";
import "./buildProfileSignupForm.scss";

const BuildProfileSignupForm = ({
  onCompleteProfile,
  onCancel,
  setContextType,
}) => {
  const { setUserInfo, currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const currentContext = "signup";
  // Fetch values from local storage
  const localStorageKeys = [
    "signup_firstNameValue",
    "signup_lastNameValue",
    "signup_emailValue",
    "signup_thumbnailPreference",
    "signup_currentEmotionId",
    "profileImagePreviewUrl",
  ];

  const localStorageValues = localStorageKeys.reduce((acc, key) => {
    acc[key] = localStorage.getItem(key);
    return acc;
  }, {});

  const [firstNameValue, setFirstNameValue] = useState(
    localStorageValues[getStorageKey("firstNameValue", currentContext)] || ""
  );
  const [lastNameValue, setLastNameValue] = useState(
    localStorageValues[getStorageKey("lastNameValue", currentContext)] || ""
  );
  const [emailValue, setEmailValue] = useState(
    localStorageValues[getStorageKey("emailValue", currentContext)] || ""
  );
  const [thumbnailPreference, setThumbnailPreference] = useState(
    localStorageValues[getStorageKey("thumbnailPreference", currentContext)] ||
      "emoji"
  );
  const [currentEmotionId, setCurrentEmotionId] = useState(
    localStorageValues[getStorageKey("currentEmotionId", currentContext)] || "0"
  );
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    localStorageValues["profileImagePreviewUrl"] || ""
  );
  const [firstNameValueError, setFirstNameValueError] = useState("");
  const [lastNameValueError, setLastNameValueError] = useState("");
  const [emailValueError, setEmailValueError] = useState("");
  const [firstNameFocused, setFirstNameFocused] = useState(false);
  const [lastNameFocused, setLastNameFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailResponseError, setEmailResponseError] = useState("");
  const [fileUpload, setFileUpload] = useState(null);
  const [resizeError, setResizeError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [presignedImgUrlResponse, setPresignedImgUrlResponse] = useState(null);
  const [previewImgModal, setPreviewImgModal] = useState(false);
  const [imageMetaData, setImageMetaData] = useState({
    width: 0,
    height: 0,
    size: 0, // Add this to track the file size
  });
  const [showAllEmojis, setShowAllEmojis] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    localStorage.setItem(
      getStorageKey("firstNameValue", currentContext),
      firstNameValue
    );
    localStorage.setItem(
      getStorageKey("lastNameValue", currentContext),
      lastNameValue
    );
    localStorage.setItem(
      getStorageKey("emailValue", currentContext),
      emailValue
    );
    localStorage.setItem(
      getStorageKey("thumbnailPreference", currentContext),
      thumbnailPreference
    );
    localStorage.setItem(
      getStorageKey("currentEmotionId", currentContext),
      currentEmotionId
    );
  }, [
    firstNameValue,
    lastNameValue,
    emailValue,
    thumbnailPreference,
    currentEmotionId,
  ]);

  const handleThumbnailPreference = (newPreference) => {
    setThumbnailPreference(newPreference);
    if (newPreference === "emoji") {
      setShowAllEmojis(true);
    } else {
      setShowAllEmojis(false);
    }
  };

  const checkEmail = useCallback(async (email) => {
    try {
      const response = await apiCheckEmail(email);

      if (response && response.exists) {
        if (!response.signupCompleted) {
          // If the email exists but signup is incomplete, proceed to complete signup
          setContextType("completeSignup"); // Update context type to complete signup
          return true; // Indicate success for proceeding with the signup process
        } else {
          // If signup is complete, show the error message
          setEmailValueError("Account already registered with this email");
          setLoading(false);
          return false;
        }
      } else if (!response.exists) {
        // If the email does not exist, continue with the signup process
        return true;
      } else {
        // Handle unexpected cases
        setEmailValueError(
          "Unexpected response structure. Please try again later."
        );
        setLoading(false);
        return false;
      }
    } catch (error) {
      console.error("Error checking email:", error);
      setEmailValueError("Error checking email. Please try again later.");
      setLoading(false);
      return false;
    }
  }, []);

  const handleSelectEmoji = useCallback((emotionconId) => {
    setCurrentEmotionId(emotionconId);
    localStorage.setItem(
      getStorageKey("currentEmotionId", currentContext),
      emotionconId
    );
    setShowAllEmojis(false);
  }, []);

  const triggerFileInputClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  useEffect(() => {
    localStorage.setItem(
      getStorageKey("thumbnailPreference", currentContext),
      thumbnailPreference
    );
    if (thumbnailPreference === "emoji") {
      localStorage.setItem(
        getStorageKey("currentEmotionId", currentContext),
        currentEmotionId
      );
      localStorage.removeItem("profileImagePreviewUrl");
    } else if (thumbnailPreference === "picture") {
      localStorage.removeItem(
        getStorageKey("currentEmotionId", currentContext)
      );
    }
  }, [thumbnailPreference, currentEmotionId, imagePreviewUrl]);

  const prepareImagePreviewAndShowModal = (file) => {
    const previewUrl = URL.createObjectURL(file);
    setImagePreviewUrl(previewUrl); // Set state to display the preview
  };

  const handleUploadImage = async (e) => {
    console.log("handleUploadImage: event triggered");
    const file = e?.target?.files[0];
    if (!file) {
      console.log("No file selected");
      return;
    }
    const fileType = getImageFileType(file); // Determines if the file is 'jpeg', 'png',
    console.log("Selected file:", file);
    console.log("File MIME type:", file.type);
    console.log("File size:", file.size);
    try {
      checkFile(file.name, file.type, file.size); // Perform file validation and throw error if not valid
      console.log("File validation passed, no resizing needed.");
      setThumbnailPreference("picture");
      setImageMetaData((prevMetadata) => ({
        ...prevMetadata,
        size: file.size,
      }));
      setFileUpload(file);
      prepareImagePreviewAndShowModal(file);
      setResizeError(false);
    } catch (resizeError) {
      console.error("Validation error:", resizeError.message);
      if (file.size > 250000) {
        console.log("Attempting to resize file due to exceeding size limit...");
        try {
          const resizedFile = await resizeFile(file, fileType);
          console.log("Resized file:", resizedFile);
          setImageMetaData((prevMetadata) => ({
            ...prevMetadata,
            size: resizedFile.size,
          }));
          setFileUpload(resizedFile);
          prepareImagePreviewAndShowModal(resizedFile);
        } catch (resizeError) {
          setResizeError(resizeError); // Use the caught error from resizing attempt
          console.error("Error resizing file:", resizeError);
        }
      } else {
        console.error("Error during file handling:", resizeError);
      }
    }
  };

  const handleSubmitUpload = async () => {
    if (!fileUpload) {
      console.error("File validation failed");
      setFileError(true);
      return;
    }
    const formData = {
      fileName: fileUpload.name,
      mimeType: fileUpload.type,
      fileSize: fileUpload.size,
      sourceId: localStorage.getItem("userId"),
    };
    console.log("Form data constructed:", formData);
    localStorage.setItem("formData", JSON.stringify(formData));
    setLoading(true);
    try {
      const presignedImgUrlResponse = await getPresignedImgUrl(
        fileUpload.name,
        fileUpload.type,
        fileUpload.size,
        formData.sourceId
      );
      if (presignedImgUrlResponse.presignedImgUrl) {
        console.log(
          "Pre-signed URL obtained:",
          presignedImgUrlResponse.presignedImgUrl
        );
        setPresignedImgUrlResponse(presignedImgUrlResponse);
        await uploadFileToS3(
          presignedImgUrlResponse.presignedImgUrl,
          fileUpload,
          handleUploadSuccess,
          (error) => console.error("Upload Error:", error)
        );
      } else {
        console.error("Failed to obtain pre-signed URL");
      }
    } catch (err) {
      console.error("Error during the upload setup process:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("useEffect triggered", { presignedImgUrlResponse, fileUpload });
    if (presignedImgUrlResponse?.presignedImgUrl && fileUpload) {
      // this response will invoke the following below
      console.log(
        "Attempting upload with URL:",
        presignedImgUrlResponse.presignedImgUrl
      );
      console.log("Uploading file details:", {
        URL: presignedImgUrlResponse.presignedImgUrl,
        "File MIME type": fileUpload.type,
        File: fileUpload,
      });
      // using the file object directly
      uploadFileToS3(
        presignedImgUrlResponse.presignedImgUrl,
        fileUpload, // this is the actual file object that needs to be uploaded
        handleUploadSuccess, // Direct reference, not invoking here instead of a successs response message commented out below
        (error) => console.error("Upload Error:", error)
      );
    }
  }, [presignedImgUrlResponse, fileUpload]);

  const handleUploadSuccess = async () => {
    const formDataString = localStorage.getItem("formData");
    const sourceId = localStorage.getItem("userId"); // Bind sourceId to userId
    const cloudFrontDomain = "media.skitsolympic.com"; // Define CloudFront domain

    if (formDataString) {
      let userData = JSON.parse(formDataString);
      userData.profileImageUrl = `https://${cloudFrontDomain}/images/${sourceId}/${encodeURIComponent(userData.fileName)}`;
      localStorage.setItem("profileImageUrl", userData.profileImageUrl);
      delete userData.imageMetaData; // Assuming you want to remove this before sending
      console.log("Constructed CloudFront URL:", userData.profileImageUrl);
      try {
        const response = await apiRecordProfileImageUrlCrud(userData);
        if (response.status === 200 || response.data.success) {
          console.log("Profile URL data recorded successfully:", response.data);
          localStorage.removeItem("formData");
          setLoading(false);
          setFileUpload(null);
          handleModalClose();
          finalizeProfileCompletion(); // Call without parameters
        } else {
          console.error("Failed to record user data:", response.data);
        }
      } catch (error) {
        console.error("Error recording user data:", error);
      }
    } else {
      console.error("No profile data found in local storage.");
    }
  };

  const handleModalClose = () => {
    if (imagePreviewUrl) {
      URL.revokeObjectURL(imagePreviewUrl);
      setImagePreviewUrl(null);
    }
    setPreviewImgModal(false);
  };
  const finalizeProfileCompletion = async () => {
    try {
      // const userId = localStorage.getItem("userId");
      const userId = currentUser;
      const payload = {
        userId,
        firstName: firstNameValue,
        lastName: lastNameValue,
        email: emailValue,
        thumbnailPreference,
      };
      if (thumbnailPreference === "emoji") {
        const emotionconId = localStorage.getItem("signup_currentEmotionId");
        if (emotionconId !== "0") {
          payload.emotionconId = emotionconId;
        }
      }
      const result = await apiAddAcctAtributes(payload);
      if (result.data.status === "OK") {
        setUserInfo(userId, {
          firstNameValue,
          lastNameValue,
          emailValue,
          thumbnailPreference,
        });
        onCompleteProfile(); // call back to redirect and update the authorization form (code flow and form control)
        localStorage.removeItem(
          getStorageKey("firstNameValue", currentContext)
        );
        localStorage.removeItem(getStorageKey("lastNameValue", currentContext));
        localStorage.removeItem(getStorageKey("emailValue", currentContext));
        localStorage.removeItem(
          getStorageKey("thumbnailPreference", currentContext)
        );
        localStorage.removeItem(
          getStorageKey("currentEmotionId", currentContext)
        );
      } else {
        setEmailResponseError(
          result.data.message || "An error occurred during profile completion."
        );
      }
      setLoading(false);
    } catch (error) {
      console.error("Profile completion error:", error);
      if (error.response?.status === 409) {
        setEmailResponseError("Account already registered with this email");
      } else {
        setErrors(
          error.response?.data?.message ||
            "An error occurred during profile completion."
        );
      }
      setLoading(false);
    }
  };

  const onProfileComplete = async (e) => {
    e.preventDefault();
    setLoading(true);
    const validation = signupRemainingFieldsErrorHandler(
      currentContext,
      setFirstNameValueError,
      setLastNameValueError,
      setEmailValueError
    );
    if (Object.keys(validation.errors).length > 0) {
      setLoading(false);
      return;
    }
    const emailCheckResponse = await checkEmail(emailValue);
    if (emailCheckResponse.exists) {
      if (!emailCheckResponse.signupCompleted) {
        // If signup is incomplete, proceed without setting an error
        // This might be where you update the context to resume signup if needed
        setContextType("completeSignup");
      } else {
        // If signup is complete, show the error message and stop the process
        setEmailValueError("Account already registered with this email");
        setLoading(false);
        return;
      }
    }

    const thumbnailPreference = localStorage.getItem(
      "signup_thumbnailPreference"
    );
    if (thumbnailPreference === "picture") {
      // Call handleSubmitUpload and exit early if upload fails
      const uploadSuccess = await handleSubmitUpload(); // Ensure the file upload process completes
      if (!uploadSuccess) {
        setLoading(false);
        return; // Return if the upload fails
      }
    } else {
      // Directly finalize profile completion if no upload is needed
      finalizeProfileCompletion();
    }
  };
  return (
    <div
      id="buildProfileForm"
      className="complete-profile-modal-content slideDown"
    >
      <div className="complete-profile-modal-body">
        <form action="" style={{ position: "relative" }}>
          <div className="client-form">
            <div id="logo-header">
              <h3>Complete Your Profile</h3>
            </div>
            <div className="input-field">
              <div className="input-group blinker-wrapper">
                {emailResponseError && (
                  <div className="email-signup-error-message">
                    {emailResponseError}
                  </div>
                )}
                <div className="input-wrapper">
                  <input
                    type="email"
                    id="userEmail"
                    autoComplete="off"
                    placeholder={
                      emailValueError
                        ? emailValueError
                        : "Enter your email here"
                    }
                    className={`${emailFocused ? "focused" : ""} ${
                      emailValueError ? "field-background-color-error" : ""
                    }`}
                    value={emailValue}
                    onChange={(e) =>
                      handleEmailChange(
                        e.target.value,
                        setEmailValue,
                        currentContext,
                        getStorageKey,
                        emailValueError,
                        setEmailValueError
                      )
                    }
                    onFocus={() => {
                      setEmailFocused(true);
                      setEmailValueError(""); // Clear error on focus
                    }}
                    onBlur={() => setEmailFocused(false)}
                  />
                  {emailValue && (
                    <FieldButton onClick={() => setEmailValue("")}>
                      Clear
                    </FieldButton>
                  )}
                </div>
                {!(emailFocused || emailValue) && (
                  <>
                    <PaperPlaneIcon
                      className="input-icon"
                      fillColor="var(--color-white)"
                      style={{
                        opacity: 0.68,
                        transition: "opacity 0.2s ease-in-out",
                      }}
                    />
                    <span className="blinking-cursor-black-color signup-email-cursor">
                      |
                    </span>
                  </>
                )}
              </div>
              {emailValueError && emailValueError !== errorMessages.email && (
                <div className="build-profile-error-message">
                  {emailValueError}
                </div>
              )}
              <div className="input-label"></div>
              <div className="input-grid2 blinker-wrapper">
                <section className="full-name-section">
                  <div className="input-group blinker-wrapper">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        id="firstName"
                        autoComplete="given-name"
                        placeholder={
                          firstNameValueError
                            ? firstNameValueError
                            : "Enter Your Legal First Name Here"
                        }
                        className={`${firstNameFocused ? "focused" : ""} ${
                          firstNameValueError
                            ? "field-background-color-error"
                            : ""
                        }`}
                        value={firstNameValue}
                        onChange={(e) =>
                          handlefirstNameValueChange(
                            e.target.value,
                            currentContext,
                            setFirstNameValue,
                            firstNameValueError,
                            setFirstNameValueError
                          )
                        }
                        onFocus={() => setFirstNameFocused(true)}
                        onBlur={() => setFirstNameFocused(false)}
                      />
                      {firstNameValue && (
                        <FieldButton onClick={() => setFirstNameValue("")}>
                          Clear
                        </FieldButton>
                      )}
                      {!(firstNameFocused || firstNameValue) && (
                        <>
                          <PaperPenWritingIcon
                            className="input-icon"
                            fillColor="var(--color-white)"
                            style={{
                              opacity: 0.68,
                              transition: "opacity 0.2s ease-in-out",
                            }}
                          />
                          <span className="blinking-cursor-black-color signup-firstname-cursor">
                            |
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="input-group blinker-wrapper">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        id="lastName"
                        autoComplete="family-name"
                        placeholder={
                          lastNameValueError
                            ? lastNameValueError
                            : "Enter Your Legal Last Name Here"
                        }
                        className={`${lastNameFocused ? "focused" : ""} ${
                          lastNameValueError
                            ? "field-background-color-error"
                            : ""
                        }`}
                        value={lastNameValue}
                        onChange={(e) =>
                          handleLastNameChange(
                            e.target.value,
                            lastNameValueError,
                            currentContext,
                            setLastNameValue,
                            getStorageKey,
                            setLastNameValueError
                          )
                        }
                        onFocus={() => setLastNameFocused(true)}
                        onBlur={() => setLastNameFocused(false)}
                      />
                      {lastNameValue && (
                        <FieldButton onClick={() => setLastNameValue("")}>
                          Clear
                        </FieldButton>
                      )}
                      {!(lastNameFocused || lastNameValue) && (
                        <>
                          <PaperPenWritingIcon
                            className="input-icon"
                            fillColor="var(--color-white)"
                            style={{
                              opacity: 0.68,
                              transition: "opacity 0.2s ease-in-out",
                            }}
                          />
                          <span className="blinking-cursor-black-color signup-lastname-cursor">
                            |
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </div>
              <div className="profile-pic-upload-section">
                <RadioHeadBtn
                  label="Select Picture Profile Thumbnail"
                  name="thumbnailPreference"
                  value="picture"
                  checked={thumbnailPreference === "picture"}
                  onChange={(e) =>
                    handleThumbnailPreference(
                      e.target.value,
                      "thumbnailPreference"
                    )
                  }
                />
                {thumbnailPreference === "picture" && (
                  <>
                    <InsetBlendTVButton
                      image={<SearchGlassIcon />}
                      btnText="CHOOSE Profile Pic"
                      textStyles={{
                        color: "var(--color-bloodred)",
                        fontFamily: "Roboto Slab",
                        fontWeight: "800",
                        fontSize: "1.3rem",
                      }}
                      onClick={(e) => triggerFileInputClick(e)} // Prevent default form action and trigger file input
                    />
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      accept="image/jpeg, image/png"
                      onChange={handleUploadImage}
                    />
                    {imagePreviewUrl && (
                      <div>
                        <img
                          src={imagePreviewUrl}
                          alt="Profile Preview"
                          style={{ maxWidth: "200px", maxHeight: "200px" }}
                        />
                      </div>
                    )}
                  </>
                )}
                <div className="seprator-icon">
                  <div className="and-or horizontal-bar vertical-icon">
                    <span>OR</span>
                  </div>
                </div>
                <RadioHeadBtn
                  label="Select Emoji Profile Thumbnail (Default)"
                  name="thumbnailPreference"
                  value="emoji"
                  checked={thumbnailPreference === "emoji"}
                  onChange={(e) =>
                    handleThumbnailPreference(
                      e.target.value,
                      "thumbnailPreference"
                    )
                  }
                />
                {thumbnailPreference === "emoji" && (
                  <>
                    {!showAllEmojis && (
                      <SmallPlainInsetBtn
                        image={
                          <CurveUpArrow
                            height="20px"
                            width="20px"
                            style={{ marginRight: "3px" }}
                          />
                        }
                        text="Change Emoji"
                        style={{
                          fontSize: "1rem",
                        }}
                        width="188px"
                        onClick={() => {
                          if (thumbnailPreference !== "emoji") {
                            handleThumbnailPreference({
                              target: { value: "emoji" },
                            });
                          }
                          setShowAllEmojis(true);
                        }}
                      />
                    )}
                    <div className="selected-emoji">
                      <EmotionEmoji currentEmotionId={currentEmotionId} />
                    </div>
                    {showAllEmojis && (
                      <div className="showAllEmojisWrapper">
                        <EmotionEmoji
                          onSelectEmoji={handleSelectEmoji}
                          currentEmotionId={currentEmotionId}
                          showAllEmojis={showAllEmojis}
                        />
                        <SmallPlainInsetBtn
                          image={
                            <XIcon
                              height="20px"
                              width="20px"
                              style={{ marginRight: "3px" }}
                            />
                          }
                          text="Cancel emote change"
                          style={{
                            fontSize: "1rem",
                          }}
                          width="188px"
                          onClick={() => setShowAllEmojis(false)}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="position-relative">
              <ImgTopBtn
                image={
                  <BlinkingLight blinkerLightStyles={{ marginRight: "10px" }} />
                }
                imgTopBtnWidth="100%"
                imgTopBtnFontColor="#b03a2e"
                imgTopBtnText="Login"
                textClassName="responsiveBtnText"
                imgTopBtnTextFontSize="1.3rem"
                onClick={onProfileComplete}
              />
            </div>
            {loading && <Loader className="complete-profile-loader-position" />}
            <InsetButtonEventListener
              btnText="X Cancel"
              className="white-gold"
              textClassName="responsiveBtnText"
              style={{
                width: "150px",
                fontSize: "1.3rem",
                fontFamily: "roboto-slab",
                fontWeight: "600",
              }}
              onClick={onCancel}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default BuildProfileSignupForm;
