import React, { useState, useEffect } from "react";

const BlinkingSidewaysRightTriangleIcon = ({
  width = "25",
  height = "25",
  style = {},
  className = "",
}) => {
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    // Start blinking
    const intervalId = setInterval(() => {
      setIsOn((prev) => !prev); // Toggle the light state
    }, 1000); // Change the light state every 1000ms (1 second)
    // Return a cleanup function that clears the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array means this effect runs only once on mount

  const fillColor = isOn ? "var(--color-redish)" : "var(--color-corveteRed)";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`sideways-right-triangle ${className}`.trim()}
      width={width}
      height={height}
      viewBox="-0.5 0 7 7" // Preserving original viewBox
      style={style}
    >
      <g
        id="playbtn"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="play"
          transform="translate(-347.000000, -3766.000000)"
          fill={fillColor}
        >
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path
              d="M296.494737,3608.57322 L292.500752,3606.14219 C291.83208,3605.73542 291,3606.25002 291,3607.06891 L291,3611.93095 C291,3612.7509 291.83208,3613.26444 292.500752,3612.85767 L296.494737,3610.42771 C297.168421,3610.01774 297.168421,3608.98319 296.494737,3608.57322"
              id="play-[#1003]"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default BlinkingSidewaysRightTriangleIcon;
