import { Routes, Route, useLocation } from "react-router-dom"; // routes =mapping a URL path to a component, route=replaces switch cases
import Layout from "./components/layout/layout";
import NotificationDropdown from "./components/notifications/notificationDropdown ";
// Direct imports
import LandingPage from "./pages/landing/landing";
import SkitURL from "./pages/skitsUrl/skitsUrl";
import Skits from "./pages/skitsLists/skitsListPage";
import UsersWhoVotedListingPage from "./pages/usersWhoVotedListingPage/usersWhoVotedListingPage";
import TermsAndConditions from "./pages/rewardPointTerms/termsAndConditions";
import TopSkitsListsPage from "./pages/topSkistLists/topSkistListsPage";
import UserProfilePage from "./pages/profile/userProfilePage";
import SubscribersListPage from "./pages/subscriptionsPage/subscriberListPage";
import SubscriptionListPage from "./pages/subscriptionsPage/subscriptionListPage";
import InfoPage from "./pages/information/infoPage";
import CurrentUserSkitDashboard from "./pages/skitDashboard/skitDashboard";
import CurrentUserAdsManagerDashboard from "./pages/adsManagerDashboard/adsManagerDashboard";
import SearchAccountsPage from "./pages/searchAccountsPage/searchAccountsPage";
import TransactionHistoryPage from "./pages/transactionHistoryPage/transactionHistoryPage";
import "./index.scss"; // Global styles for color is also in this file
import "./styles/blinkingCursors.scss";
import "./styles/sharedGlobalStyles.scss";

const App = () => {
  const location = useLocation();
  let showNotificationBell = false;

  // Explicitly check which paths should show the notification bell

  // Expression: location.pathname is the expression being evaluated in the switch statement.
  // It contains the current URL path, such as /, /home, /about, etc.
  
  // Case "/": If location.pathname is / (the root path), the case "/" block runs.
  // Here, showNotificationBell is set to false (no notification bell for the landing page).
  // Default:

  // If location.pathname does not match any case value, the default block runs.
  // This sets showNotificationBell to true for all other routes.

  switch (location.pathname) {
    case "/":
      // Landing Page - No bell
      showNotificationBell = false;
      break;
    default:
      // All other routes - Show bell
      showNotificationBell = true;
      break;
  }

  return (
    <>
      {/* Notification Dropdown, Wrapped the app with the NotificationDropdown component in the index.js and setting it outside the Routes component, so it renders consistently across all routes.
       */}
      {/* Render the Notification Bell based on the logic */}
      {showNotificationBell && <NotificationDropdown />}

      {/* Routes */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route exact path="skitslist" element={<Skits />} />
          <Route path="/skit/:skitId" element={<SkitURL />} />
          <Route
            path="/skit/:skitId/ratings"
            element={<UsersWhoVotedListingPage />}
          />
          <Route path="top-skits" element={<TopSkitsListsPage />} />
          <Route path="/user-profile/:userId" element={<UserProfilePage />} />
          <Route
            path="/user/:userId/subscribers"
            element={<SubscribersListPage />}
          />
          <Route path="/user/subscribers" element={<SubscriptionListPage />} />
          <Route path="/information" element={<InfoPage />} />
          <Route
            path="/user-profile/:userId/skit-dashboard"
            element={<CurrentUserSkitDashboard />}
          />
          <Route
            path="/user-profile/:userId/ads-dashboard"
            element={<CurrentUserAdsManagerDashboard />}
          />
          <Route path="/search-accounts" element={<SearchAccountsPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route
            path="/user/:userId/transactionHistoryPage"
            element={<TransactionHistoryPage />}
          />
        </Route>
      </Routes>
    </>
  );
};
export default App;

{
  /* This is your new route */
}
{
  /* if user is not authenticated then user is redirected to the landing page
        {/* <Route
          path="skitslist"
          element={isAuthenticated() ? <Skits /> : <Navigate to="/" replace />}
        />
        <Route
          path="/skit/:skitId"
          element={
            isAuthenticated() ? <SkitURL /> : <Navigate to="/" replace />
          }
        />
        <Route
          path="/skit/:skitId/ratings"
          element={
            isAuthenticated() ? (
              <UsersWhoVotedListingPage />
            ) : (
              <Navigate to="/" replace />
            )
          }
        /> */
}

// Why Use Lazy Loading?
// Performance Improvement: Particularly beneficial for
// larger applications with many components. It reduces the
// initial load time by splitting the bundle into smaller chunks
// and only loading them when needed.
// Bandwidth Conservation: Useful in bandwidth-constrained
// scenarios, as it minimizes the amount of data transferred until it is necessary.
// User Experience: Enhances user experience by speeding up the initial
// page load, which can help retain users who might leave a site that takes too long to load.
