import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/context/authContext";
import {
  apiFetchUserSubscriptions,
  apiUnSusbcribeUser,
} from "../../api/backend/user";
import SubscriptionsList from "../../components/subscriptions/subscriptionList";
import { LeftArrow } from "../../components/icons";
import { SolidColorButton } from "../../components/buttons";
import Loader from "../../components/loader/loader";
import { SingularPlural } from "../../utils/wordUtils";

const SubscriptionsListPage = () => {
  let navigate = useNavigate();
  const { currentUser } = useAuth(); // Get currentUser from context
  const [totalSubscriptions, setTotalSubscriptions] = useState(0); // State to hold the total number of subscriptions
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]); // State to hold the subscriptions
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]); // State to hold the filtered subscriptions
  const limit = 10; // Number of subscriptions to fetch per request

  useEffect(() => {
    console.log("SubscribersListPage currentUser:", currentUser); // Debugging line
    setLoading(false);
  }, [currentUser]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true);
      try {
        const response = await apiFetchUserSubscriptions({
          sourceId: currentUser,
          offset,
          limit,
        });
        console.log("API Response:", response);

        if (response.data && response.data.length > 0) {
          setSubscriptions((prev) => [...prev, ...response.data]);
          setFilteredSubscriptions((prev) => [...prev, ...response.data]);
          setMessage("");
          setTotalSubscriptions((prev) => prev + response.data.length);
          if (response.data.length < limit) setHasMore(false);
        } else {
          setSubscriptions([]);
          setFilteredSubscriptions([]);
          setMessage("Build your tribe, Find Users and Subscribe!");
          setTotalSubscriptions(0);
          setHasMore(false);
        }
      } catch (err) {
        console.error("Failed to fetch subscriptions:", err);
        setMessage("Failed to load subscriptions");
        setTotalSubscriptions(0);
      } finally {
        setLoading(false);
      }
    };
    fetchSubscriptions();
  }, [currentUser, offset, setTotalSubscriptions]);

  const handleUnsubscribeClick = async (targetId) => {
    try {
      const response = await apiUnSusbcribeUser({
        sourceId: currentUser,
        activity: "unsubscribed",
        targetId,
      });
      if (response.data && response.data.success) {
        setSubscriptions((prev) =>
          prev.filter((sub) => sub.target.id !== targetId)
        );
        setFilteredSubscriptions((prev) =>
          prev.filter((sub) => sub.target.id !== targetId)
        );
        setTotalSubscriptions((prev) => prev - 1);
        console.log("Unsubscribed successfully");
      } else {
        console.error("Failed to unsubscribe:", response.message);
      }
    } catch (error) {
      console.error("Error unsubscribing:", error);
    }
  };

  const handleViewMore = () => {
    setOffset((prevOffset) => prevOffset + limit);
  };

  return (
    <div id="subscriptionsTop">
      <SolidColorButton
        solidColorBtnImage={
          <LeftArrow
            height="15px"
            width="15px"
            fillColor="#964b00"
            strokeWidth="4"
            strokeColor="#964b00"
          />
        }
        className="gold-btn"
        solidColorBtnText="Back"
        solidColorBtnFontWeight="800"
        solidColorBtnFontFamily="helvetica"
        solidColorBtnTextFontSize=".75rem"
        solidColorBtnFontColor="#964b00"
        solidColorBtnWidth="100px"
        solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
        onClick={() => navigate(-1)}
      />
        {totalSubscriptions > 0 && (
        <h2>
          {totalSubscriptions}{" "}
          {SingularPlural(totalSubscriptions, "Subscription")}
        </h2>
      )}
      {loading && offset === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <SubscriptionsList
            subscriptions={subscriptions}
            setSubscriptions={setSubscriptions}
            filteredSubscriptions={filteredSubscriptions}
            setFilteredSubscriptions={setFilteredSubscriptions}
            setTotalSubscriptions={setTotalSubscriptions}
            currentUser={currentUser}
            handleUnsubscribeClick={handleUnsubscribeClick}
            handleViewMore={handleViewMore}
            loading={loading}
            hasMore={hasMore}
            message={message}
            offset={offset}
          />
        </>
      )}
    </div>
  );
};

export default SubscriptionsListPage;
