import React, { useState } from "react";
import { SmallPlainInsetBtn, ToggleSlider } from "../buttons";
import { PencilIcon, TrashCanIcon, EyeIcon, EyeSlashIcon } from "../icons";

const CampaignMetricsCard = ({
  campaign,
  onEdit,
  onDelete,
  onAutoPayToggle,
}) => {
  const [expanded, setExpanded] = useState(false);
  // Dynamic labels
  // Dynamic labels based on paymentType
  const dailyAllowableReachLimitLabel =
    campaign.paymentType === "impressions"
      ? "Daily Impressions Limit"
      : "Daily Clicks Limit";

  const totalBudgetAllowableReachLimitLabel =
    campaign.paymentType === "impressions"
      ? "Total Budget Impressions"
      : "Total Budget Clicks";

  const currentReachLabel =
    campaign.paymentType === "impressions"
      ? "Current Impressions"
      : "Current Clicks";

  const dailyCostLimitLabel =
    campaign.paymentType === "impressions"
      ? "Daily Impressions Cost Limit"
      : "Daily Clicks Cost Limit";

  // Derived values
  const currentReach =
    campaign.paymentType === "impressions"
      ? campaign.currentImpressions
      : campaign.currentClicks;

  const costPerResult =
    campaign.paymentType === "impressions"
      ? (campaign.costPerImpression * campaign.currentImpressions).toFixed(2)
      : (campaign.costPerClick * campaign.currentClicks).toFixed(2);
  //       Explanation:
  // For impressions:

  // Multiply costPerImpression by currentImpressions.
  // Example: If costPerImpression = 0.01 and currentImpressions = 100, then:
  // sql
  // Copy code
  // Cost Per Result = 0.01 * 100 = 1.00
  // For clicks:

  // Multiply costPerClick by currentClicks.
  // Example: If costPerClick = 0.50 and currentClicks = 10, then:
  // sql
  // Copy code
  // Cost Per Result = 0.50 * 10 = 5.00

  return (
    <div className="campaign-metrics-card">
      <div className="campaign-header">
        <h3>{campaign.title}</h3>
        <div className="metric-action-buttons">
          <SmallPlainInsetBtn
            image={
              <PencilIcon
                height="15px"
                width="15px"
                style={{ marginRight: "3px" }}
              />
            }
            text="Edit"
            onClick={() => onEdit(campaign)}
          />
          <SmallPlainInsetBtn
            image={
              <TrashCanIcon
                height="15px"
                width="15px"
                style={{ marginRight: "3px" }}
              />
            }
            text="Delete"
            onClick={() => onDelete(campaign.id)}
          />
          <SmallPlainInsetBtn
            image={
              expanded ? (
                <EyeSlashIcon
                  height="15px"
                  width="15px"
                  style={{ marginRight: "3px" }}
                  fillColor="var(--color-bloodred)"
                />
              ) : (
                <EyeIcon
                  height="15px"
                  width="15px"
                  style={{ marginRight: "3px" }}
                  fillColor="var(--color-bloodred)"
                />
              )
            }
            text={expanded ? "Hide Metrics" : "View Metrics"}
            onClick={() => setExpanded((prev) => !prev)}
          />
          <ToggleSlider
            checked={campaign.autoPay}
            handleChangeSlider={() => onAutoPayToggle(campaign.id)}
            content={campaign.autoPay ? "ON" : "OFF"}
            sliderWrapperStyle={{
              display: "inline-block",
              marginLeft: "10px",
            }}
          />
        </div>
      </div>
      {expanded && (
        <div className="campaign-details">
          <p>
            <strong>Campaign Name:</strong> {campaign.campaignName}
          </p>
          <p>
            <strong>Advertising Type:</strong> {campaign.commercialType}
          </p>
          <p>
            <strong>Ad Category:</strong>{" "}
            {campaign.relationalSelectedCategoryName}
          </p>
          <p>
            <strong>Category Ad Promo:</strong> {campaign.relationalAdPromoName}
          </p>
          <p>
            <strong>Status:</strong> {campaign.campaignStatus}
          </p>
          <p>
            <strong>Total Budget:</strong> ${campaign.totalBudget}
          </p>
          <p>
            <strong>Period:</strong>{" "}
            {campaign.paymentSubscription === "1"
              ? "Daily"
              : "Lifetime (One-Time)"}
          </p>

          {/* Conditional rendering based on subscription type */}
          {campaign.paymentSubscription === "1" && (
            <>
              <p>
                <strong>{dailyCostLimitLabel}:</strong> $
                {campaign.dailyCostLimit}
              </p>
              <p>
                <strong>{dailyAllowableReachLimitLabel}:</strong>{" "}
                {campaign.paymentType === "impressions"
                  ? campaign.dailyImpressionsLimit
                  : campaign.dailyClicksLimit}
              </p>
            </>
          )}

          <p>
            <strong>{totalBudgetAllowableReachLimitLabel}:</strong>{" "}
            {campaign.paymentType === "impressions"
              ? campaign.totalBudgetImpressions
              : campaign.totalBudgetClicks}
          </p>
          <p>
            <strong>{currentReachLabel}:</strong> {currentReach}
          </p>
          <p>
            <strong>Cost Per Result:</strong> ${costPerResult}
          </p>
          <p>
            <strong>Started At:</strong>{" "}
            {new Date(campaign.startedAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        </div>
      )}
    </div>
  );
};

export default CampaignMetricsCard;
