import EmotionEmoji from "../components/emoji/emoji";

export const renderUserThumbnail = (user) => {
  // Log the user object to verify its structure
  console.log("User object:", user);
  // Check for a profile picture preference and existence of URL
  if (user.thumbnailPreference === "picture" && user.profileImageUrl) {
    return (
      <div className="userThumbnail profileImage">
        <img src={user.profileImageUrl} />
      </div>
    );
  }
  // Fallback to rendering an emoji if no profile image URL is available
  // or if the preference is not for a picture.
  return <EmotionEmoji currentEmotionId={user.emotionconId} />;
};
