import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNotifications } from "../../components/context/notificationContext";
import { useParams, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useAuth } from "../../components/context/authContext";
import { useAdCampaigns } from "../../components/hooks/useAdCampaigns";
import {
  apiFetchSkitById,
  apiDeleteSkitIdFromSourceIdAction,
} from "../../api/backend/skits";
import {
  SmallPlainInsetBtn,
  MemberSlider,
  SolidColorButton,
} from "../../components/buttons/index";
import BallotModal from "../../components/ballotModal/ballotModal";
import {
  BallotIcon,
  WhistleIcon,
  TrashCanIcon,
  NotInterestedIcon,
  LeftArrow,
} from "../../components/icons/index";
import useSourceIdDeletePermission from "../../components/hooks/useSourceIdDeletePermission";
import VideoMonitor from "../../components/videoPlayer/videoMonitor";
import VideoPlayerControls from "../../components/videoPlayer/videoPlayerControls";
import {
  apiRecordSourceIDActivityView,
  apiFetchUserViewedSkitActivity,
  apiRecordSourceIDActivityRatings,
} from "../../api/backend/user";
import { cancelVoteTrasnaction } from "../../controller/cancelBallotModalTrasaction";
import CommentThreads from "../../components/memberComments/commentThreads";
import TooltipDots from "../../components/toolTipDots/toolTipDots";
import { scrollWithOffset } from "../../utils/scrollUtils";
import { SingularPlural } from "../../utils/wordUtils";
import TimeRemainingAd from "../../components/skitAd/timeRemainingAd";
import { saveSkitTime, getSkitTime } from "../../utils/skitAd";
import "./skitsUrl.scss";

const SkitURL = ({ onNotInterested, autoPlay=true, }) => {
  const { fetchNotifications } = useNotifications();
  const { currentUser, playCount, setPlayCount,  resetPlayCount  } = useAuth();
  const { getNextAd } = useAdCampaigns(); // Hook for fetching next ad
  const [skit, setSkit] = useState(null);
  // const [isSkitPlaying, setIsSkitPlaying] = useState(autoPlay);
  const [isAdPlaying, setIsAdPlaying] = useState(autoPlay); // State to track if an ad is playing
  const [isSkitPlaying, setIsSkitPlaying] = useState(false);
  const [adDuration, setAdDuration] = useState(null); // Ad duration will be updated dynamically
  const [skitDuration, setSkitDuration] = useState(skit?.duration || 1);
  const duration = isAdPlaying ? adDuration : skitDuration;
  const [currentMediaUrl, setCurrentMediaUrl] = useState(
    skit?.mediaUrl || null // Start with `null` // Initialize with skit URL
  );
  const videoRef = useRef(null); // Reference to the video element
  const navigate = useNavigate();
  const { skitId } = useParams();
  const updateProgressRef = useRef(0);
  console.log("Skit Url Retrieved skitId:", skitId);
  console.log("At render, currentUser is", currentUser);

  const [volume, setVolume] = useState(5);
  const [views, setViews] = useState(skit?.views || 0);
  const [rating, setRating] = useState(skit?.weightedAverageRating || 0);
  const [votes, setVotes] = useState(skit?.ratingCount || 0);
  const [viewError, setViewError] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [showBallotModal, setShowBallotModal] = useState(false);
  const [notInterestedError, setNotInterestedError] = useState("");
  const cancelVote = cancelVoteTrasnaction(setShowBallotModal);
  const handleNotInterestedClick = () => {
    onNotInterested(skit.id); // Pass the skit id to the handler
  };

  // Initialize hasDeletePermission here and update it only after skit is set
  // Move the declaration of useSourceDeletePermission hook outside of any function or condition
  useEffect(() => {
    if (!skitId) return;

    const fetchSkitDetails = async () => {
      try {
        const response = await apiFetchSkitById({ skitId });
        if (response.data) {
          setSkit(response.data);
          setViews(response.data.views);
          setVotes(response.data.ratingCount);
          setRating(response.data.weightedAverageRating);
        }
      } catch (error) {
        console.error("Failed to fetch skit details:", error);
      }
    };

    fetchSkitDetails();
  }, [skitId]);
  const savedTime = getSkitTime(skit?.id) || 0;

  const hasDeletePermission = useSourceIdDeletePermission(
    currentUser,
    skit?.sourceId // Use optional chaining to avoid null reference errors
  );

  // Fetch skit details on mount or when skitId changes
  useEffect(() => {
    const fetchSkitDetails = async () => {
      if (!skitId) return;
      try {
        const response = await apiFetchSkitById({ skitId });
        if (response.data) {
          setSkit(response.data);
          setViews(response.data.views);
          setVotes(response.data.ratingCount);
          setRating(response.data.weightedAverageRating);
          setSkitDuration(response.data.duration || 1);
          setCurrentMediaUrl(response.data.mediaUrl);
        }
      } catch (error) {
        console.error("Failed to fetch skit details:", error);
      }
    };

    fetchSkitDetails();
  }, [skitId]);

 // Inside SkitURL or the component using VideoMonitor
const handleVideoMonitorClick = () => {
  resetPlayCount(); // Reset playCount in both context and localStorage
  const nextAd = getNextAd(); // Fetch the next ad

  if (nextAd) {
    setIsAdPlaying(true);
    setCurrentMediaUrl(nextAd.mediaAdUrl); // Set the ad URL to play
    saveSkitTime(skit?.id, videoRef.current?.currentTime || 0); // Save the skit progress
  } else {
    console.warn("No ads available to play.");
  }
};


  // Handle ad end logic
  const handleAdEnd = () => {
    setIsAdPlaying(false);
    setCurrentMediaUrl(skit?.mediaUrl || "");
    if (videoRef.current) {
      videoRef.current.currentTime = savedTime || 0;
      videoRef.current.play();
    }
  };

  // Handle play count and switch to ad if needed
  const handlePlayTypeLogic = () => {
    if (isAdPlaying) return;

    setPlayCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount % 3 === 0) {
        const nextAd = getNextAd();
        if (nextAd) {
          setIsAdPlaying(true);
          setCurrentMediaUrl(nextAd.mediaUrl);
          saveSkitTime(skit?.id, videoRef.current?.currentTime || 0);
        }
        return 0;
      }
      return newCount;
    });

    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  // Add metadata and end event listeners
  useEffect(() => {
    const videoElement = videoRef.current;

    const handleMetadataLoaded = () => {
      if (isAdPlaying) {
        setAdDuration(videoElement?.duration || 30);
      } else {
        setSkitDuration(videoElement?.duration || 1);
      }
    };

    const handleEnded = () => {
      if (isAdPlaying) handleAdEnd();
    };

    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", handleMetadataLoaded);
      videoElement.addEventListener("ended", handleEnded);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadedmetadata", handleMetadataLoaded);
        videoElement.removeEventListener("ended", handleEnded);
      }
    };
  }, [isAdPlaying]);

  const handleVote = useCallback(
    async (skitId, ratings, categoryid, scores) => {
      if (!currentUser || !skit) {
        console.error("Missing data: currentUser or skit data");
        setRatingError("Missing user or skit data.");
        return;
      }
      setRatingError(""); // Clear any existing errors related to voting eligibility
      try {
        const recordSkitRating = {
          sourceId: currentUser,
          activity: "rated",
          relatedId: skitId,
          targetId: skit.sourceId,
          ratings,
          categoryid,
          scores,
        };
        const responseData =
          await apiRecordSourceIDActivityRatings(recordSkitRating);
        console.log("Response data check:", responseData);
        if (responseData.success) {
          console.log("New rating average:", responseData.rating);
          setRating(responseData.rating); // Update the average rating displayed
          setVotes(responseData.votes); // Update the votes count displayed
          fetchNotifications(); // Re-fetch notifications to update the notifications list
          setShowBallotModal(false); // Close the modal on successful update
          localStorage.removeItem("voteScore");
          localStorage.removeItem("ratings");
        } else {
          console.error("Rating update failed:", responseData.message);
          setRatingError(responseData.message); // Set error message from backend response
        }
      } catch (error) {
        console.error("Error updating rating count:", error);
        setRatingError("You can only vote once per skit."); // Set error message
      }
    },
    [currentUser, skit]
  );
  const handleVolumeChange = (newVolume) => {
    // console.log("Volume Change Triggered:", newVolume);
    const video = videoRef.current;
    if (video) {
      video.volume = newVolume / 100; // Convert from 0-100 to 0-1
      console.log("Video Volume Set To:", video.volume);
    }
    setVolume(newVolume); // Update the volume state
  };

  // Inside your component or logic file
  const logView = useCallback(async () => {
    if (!currentUser || !skit) {
      console.error("Missing data: currentUser or skitData");
      return;
    }
    setViewError(""); // Clear any existing errors
    try {
      const responseData = await apiRecordSourceIDActivityView(
        currentUser,
        "viewed",
        skitId,
        skit.sourceId
      );
      if (responseData && responseData.views !== undefined) {
        setViews(responseData.views);
      }
    } catch (error) {
      console.error("Error updating view count:", error);
      setViewError("Failed to update view count due to server error.");
    }
  }, [currentUser, skitId, skit, setViews]);

  if (!skit) return <div>Loading...</div>;
  // Handler for vote button click
  const placeVote = async (event) => {
    event.preventDefault(); // Prevent form submit default action
    event.stopPropagation(); // Stop propagation to parent elements
    try {
      const result = await apiFetchUserViewedSkitActivity({
        sourceId: currentUser, // Assuming currentUser contains the sourceId
        activity: "viewed",
        relatedId: skit.id, // Ensure this is the correct ID
      });

      if (result.success) {
        setShowBallotModal(true); // Open the ballot modal if check is successful
        setViewError(""); // Clear any previous errors if the check is successful
      } else {
        setViewError(
          result.message || "You must watch the full video before voting."
        ); // Set the error message from the server or a default one
      }
    } catch (error) {
      console.error("Error checking voting eligibility:", error);
      let errorMessage = "Error checking voting eligibility."; // Default error message

      if (error.response && error.response.status === 403) {
        errorMessage = "Watching the full video is required to vote."; // Specific error for 403 status
      } else if (error.message) {
        errorMessage = error.message; // Use the error message from the exception if available
      }
      setViewError(errorMessage); // Update the viewError state with the appropriate message
    }
  };

  // Handler for report button click
  const handleReport = () => {
    // Logic to handle report action
    alert("Reported"); // Placeholder action
  };
  if (!skit) {
    console.log("Skit is null, rendering loading state.");
    return <div>Loading...</div>;
  }

  const handleProfileThumbnailSlideEnd = (userId) => {
    if (userId) {
      console.log("Navigating to user profile:", userId);
      navigate(`/user-profile/${userId}`);
    } else {
      console.log("User ID is undefined, not navigating.");
      // Handle the undefined case, e.g., show a message or do nothing
    }
  };

  const handleDragUpdate = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time; // Sync video position with drag
    }
  };

  const handleCommentCreated = () => {
    console.log("Comment Created");
  };

  const handleDelete = async (skitId) => {
    try {
      await apiDeleteSkitIdFromSourceIdAction({ skitId });
      console.log("Skit deleted successfully");
      navigate("/skitslist");
      // Scroll to the top after navigation
      setTimeout(() => {
        const element = document.getElementById("skitslist");
        if (element) {
          scrollWithOffset(element, 0);
        }
      }, 0);
    } catch (error) {
      console.error("Error deleting skit:", error);
    }
  };


  return (
    <div id="skitUrlWrapper">
      <SolidColorButton
        className="gold-btn custom-style"
        solidColorBtnImage={
          <LeftArrow
            height="15px"
            width="15px"
            fillColor="#964b00"
            strokeWidth="4"
            strokeColor="#964b00"
          />
        }
        solidColorBtnText="Back"
        onClick={() => navigate(-1)}
        solidColorBtnFontWeight="800"
        solidColorBtnFontFamily="helvetica"
        solidColorBtnTextFontSize=".75rem"
        solidColorBtnFontColor="#964b00"
        solidColorBtnWidth="100px"
        solidColorBtnBoxShadow="rgba(0, 0, 0, 0.733) 0px 4px 3px 1px, rgba(0, 0, 0, 0.733) 0px 6px 8px, rgba(0, 0, 0, 0.733) 0px -4px 4px, rgba(0, 0, 0, 0.733) 0px -6px 4px, rgba(0, 0, 0, 0.733) 0px 0px 3px 0px inset"
      />

      <div id="skitUrlContainer">
        <VideoMonitor
          videoRef={videoRef}
          src={currentMediaUrl}
          autoPlay={isAdPlaying}
          title={isAdPlaying ? "" : skit?.title}
          skitId={isAdPlaying ? null : skit?.id}
          isAd={isAdPlaying}
          onLoadedMetadata={(duration) => {
            if (isAdPlaying) setAdDuration(duration);
            else setSkitDuration(duration);
          }}
          onClick={() => {
            if (isAdPlaying) {
              // Prevent navigation on ad click
              console.warn("Ad is playing, navigation disabled.");
            } else {
              // Trigger ad logic
              handleVideoMonitorClick();
            }
          }}
        />

        {isAdPlaying && (
          <div>
            <TimeRemainingAd
              videoRef={videoRef}
              isAdPlaying={isAdPlaying}
              adDuration={adDuration}
            />
            <div className="sponsored-skitUrl-container">
              <div className="sponsored-skitUrl-blur"></div>
              <span className="sponsored-skitUrl-text">Sponsored</span>
            </div>
          </div>
        )}
        {!isAdPlaying && (
          <VideoPlayerControls
            videoRef={videoRef}
            duration={duration}
            playCount={playCount}
            setPlayCount={setPlayCount}
            autoPlay={isAdPlaying}
            onDragUpdate={handleDragUpdate}
            onPlayTypeChange={handlePlayTypeLogic}
            logView={logView}
            onVolumeChange={handleVolumeChange}
            isSkitPlaying={isSkitPlaying}
            setIsSkitPlaying={setIsSkitPlaying}
            savedTime={getSkitTime(skit.id)}
            updateProgressCallback={(currentTime, totalDuration) => {
              console.log(`Progress: ${currentTime}/${totalDuration}`);
            }}
          />
        )}
        <div id="detailsRow">
          <span>
            Posted:{" "}
            {skit.createdAt
              ? new Date(skit.createdAt).toLocaleDateString()
              : "Loading..."}
          </span>
          <span className="allCaps">
            <TooltipDots text={skit.title || "Loading..."} maxLength={20} />
          </span>
          {viewError && <div className="error-message">{viewError}</div>}
          <div className="viewsCountSkitUrl">
            <span>
              {views} {SingularPlural(views, "View")}
            </span>
          </div>
          <div className="ratingsLinkSkitUrl">
            <HashLink
              to={`/skit/${skit.id}/ratings#top`}
              scroll={(el) => scrollWithOffset(el, 150)}
              style={{ textDecoration: "none" }}
            >
              {ratingError && (
                <div className="error-message">{ratingError}</div>
              )}
              <div className="rating-link">
                {votes > 0 ? (
                  <div className="rating-link">
                    <span>
                      {(rating * 5).toFixed(1)} · rating average · {votes}{" "}
                      {SingularPlural(votes, "vote")}
                    </span>
                  </div>
                ) : null}
              </div>
            </HashLink>
          </div>
        </div>
        <div id="controlsAndActionsRow">
          {skit.User && (
            <MemberSlider
              user={skit.User}
              slideBtnText="Slide to View Profile"
              className="slider-btn-spacer"
              onHandleSlideEnd={handleProfileThumbnailSlideEnd}
            />
          )}
          <div className="skit-actions">
            {showBallotModal && (
              <BallotModal
                skitId={skit.id}
                categoryid={skit.categoryid}
                onVote={handleVote}
                onClose={cancelVote}
              />
            )}
            {hasDeletePermission && (
              <SmallPlainInsetBtn
                image={<TrashCanIcon height="20px" width="20px" />}
                text="Delete"
                width="88px"
                onClick={() => handleDelete(skit.id)}
              />
            )}
            <SmallPlainInsetBtn
              image={<BallotIcon height="20px" width="20px" />}
              text="Vote"
              width="88px"
              onClick={placeVote}
            />
            <SmallPlainInsetBtn
              image={<WhistleIcon height="20px" width="20px" />}
              text="Report"
              width="88px"
              onClick={handleReport}
            />
            {!hasDeletePermission && (
              <SmallPlainInsetBtn
                image={
                  <NotInterestedIcon
                    width="30px"
                    fillColor="var(--color-bloodred)"
                  />
                }
                text="Hide"
                width="88px"
                style={{ fontSize: "1rem" }}
                onClick={handleNotInterestedClick}
              />
            )}
            {notInterestedError && (
              <div className="error-message">{notInterestedError}</div>
            )}
          </div>
        </div>
      </div>
      <CommentThreads
        skitId={skit.id}
        onCommentCreated={handleCommentCreated}
      />
    </div>
  );
};
export default SkitURL;
