import { useCallback, useState } from 'react';
import { fetchSourceUserDetailsById} from '../../api/backend/user';

// this is used for permissions, only the profile owner can edit
// This hook, `useUserSourceDetails`, is designed to fetch and manage the details of a user profile that the currently logged-in user is viewing but does not own. 
// It specifically handles profiles viewed by the current user that belong to other users, identified by a sourceId. This distinguishes between the account holder's 
// own profile details and those of other users being accessed.
export const useUserSourceDetails = () => {
  const [sourceUserAcctDetails, setSourceUserAcctDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getSourceAccountHolderDetailsById = useCallback(async (userId) => {
    setLoading(true);
    try {
      const response = await fetchSourceUserDetailsById(userId);
      setSourceUserAcctDetails(response.data);
      setError(null);
    } catch (error) {
      console.error(`Failed to fetch account holder details for ID: ${userId}`, error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    sourceUserAcctDetails,
    loading,
    error,
    getSourceAccountHolderDetailsById,
  };
};
