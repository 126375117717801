import React from "react";
import "./filmStrip.scss"; // Ensure you have the appropriate CSS or SCSS file

const FilmStrip = ({ title }) => {
  return (
    <div id="top" className="film-strip">
      <div className="trail-top"></div>
      <div className="negative">
        <div className="title-container">
          <div className="flimNegativeCentered">
            <div className="filmNegative"></div>
            <h4 className="title">{title}</h4>
          </div>
        </div>
      </div>
      <div className="trail-bottom"></div>
    </div>
  );
};

export default FilmStrip;
