import { AllEmoteOptions } from "../constants/emoteOptions";
import { renderExpression } from "../components/emoji/fullEmoteChoices"; 

export const renderCommentWithEmotes = (message) => {
  return message.split(/(\w+)/).map((part, index) => {
    const emote = AllEmoteOptions.find((option) => option.name === part);
    return emote ? (
      <span key={index} className="emote">
        {renderExpression(emote.emoteChoice)}
      </span>
    ) : (
      part
    );
  });
};
