import { errorMessages } from "../../constants/errorMessages.js";
import { getLocalStorageValues } from "../../utils/localStorageUniqueKeyGenerator.js";
export const signupRemainingFieldsErrorHandler = (
  currentContext,
  setFirstNameValueError,
  setLastNameValueError,
  setEmailValueError,
) => {
  const keys = [
    "firstNameValue",
    "lastNameValue",
    "emailValue",
  ];
  const values = getLocalStorageValues(keys, currentContext);

  let errors = {};

  if (currentContext === "signup" && values.firstNameValue.length === 0) {
    errors.firstNameValueError = errorMessages.firstName;
    setFirstNameValueError(errors.firstNameValueError);
  } else {
    setFirstNameValueError("");
  }

  if (currentContext === "signup" && values.lastNameValue.length === 0) {
    errors.lastNameValueError = errorMessages.lastName;
    setLastNameValueError(errors.lastNameValueError);
  } else {
    setLastNameValueError("");
  }

  if (currentContext === "signup" && values.emailValue.length === 0) {
    errors.emailValueError = errorMessages.email;
    setEmailValueError(errors.emailValueError);
  } else {
    setEmailValueError("");
  }
  return { errors };
};
