import axios from "axios";

export const REQUEST_METHOD = {
  POST: "post",
  GET: "get",
  PATCH: "patch",
  PUT: "put",
  DELETE: "delete",
};

// for testing locally
// comment out the bottom and uncomment this
// comment the end point and it should be ready.
// remember to go to backend-->web-service-->node server.js
// const uriEndpoint = "http://localhost:3000/";

// for testing on production locally
// this end point is from a lambda fuction
// type in lambda and then cilck on the function
// press the configuration tab
// the get and post endpoints area the same that is the one to grab
const uriEndpoint = process.env.REACT_APP_LOCAL
  ? "http://localhost:3000/"
  : "https://wye5pbazwa.execute-api.us-east-2.amazonaws.com/prod/";

export const config = {
  baseURL: uriEndpoint,
};

class Axios {
  constructor() {
    const instance = axios.create(config);
    instance.interceptors.response.use(this.handleSuccess, this.handleError);
    this.instance = instance;
    // this.headers = buildHeaders();
  }

  static handleSuccess(response) {
    return response;
  }

  static handleError(error) {
    console.error(
      "Axios error:",
      error.response ? error.response.data : error.message
    );
    return Promise.reject(error);
  }

  async fetch({ url, headers, method, data, params }) {
    try {
      const defaultParams = Axios.defaultParameterAxios({
        url,
        headers,
        method,
        data,
        params,
      });
      const res = await this.instance(defaultParams);

      if ([200, 201].includes(res.status)) {
        return res;
      }

      return {
        error: "Error!",
      };
    } catch (err) {
      console.log("errAxios", err);
      throw err;
    }
  }

  static defaultParameterAxios({ method = "get", data = null, ...args }) {
    return {
      method,
      data,
      crossdomain: true,
      ...args,
    };
  }
}

export default new Axios();
