import React, { useState, useEffect } from "react";

const KeyLockIcon = ({
  lockIconColor = "#fff",
  unlockIconColor = "#fff",
  id = "",
  className = "",
  style = {},
}) => {
  const [showLockIcon, setShowLockIcon] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowLockIcon((prev) => !prev); // Toggle the icon visibility
    }, 1000); // Change 1000 to the desired interval in milliseconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  const LockIcon = (
    <svg
      id={id}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.15 37.521"
      width="16.754"
      height="25.277"
      fill={lockIconColor}
      style={style}
    >
      <g transform="translate(16.5 -24.499)">
        <g transform="translate(-16.5 24.499)">
          <path
            d="M51.705,37.52H74.445a1.706,1.706,0,0,0,1.705-1.705V16.486a1.706,1.706,0,0,0-1.705-1.705H72.739V9.664a9.664,9.664,0,1,0-19.329,0v5.116H51.705A1.706,1.706,0,0,0,50,16.486V35.815A1.706,1.706,0,0,0,51.705,37.52Zm13.075-10.8v2.276a1.705,1.705,0,1,1-3.411,0V26.717a2.842,2.842,0,1,1,3.411,0ZM56.822,9.664a6.253,6.253,0,0,1,12.507,0v5.116H56.822Z"
            transform="translate(-50)"
          />
        </g>
      </g>
    </svg>
  );

  const UnlockIcon = (
    <svg
      id={id}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.754 35.277"
      width="16.754"
      height="25.277"
      fill={unlockIconColor}
      style={style}
    >
      <path
        id="unlock-icon"
        d="M.8,16.1h2.5V10.676A10.8,10.8,0,0,1,6.34,3.151h0a10.069,10.069,0,0,1,14.583,0h0a10.8,10.8,0,0,1,3.032,7.525v.253h-4.02v-.313a6.619,6.619,0,0,0-1.853-4.594h0a6.17,6.17,0,0,0-6.87-1.41,6.519,6.519,0,0,0-3.9,6V16.1H25.95a.82.82,0,0,1,.8.83V34.473a.82.82,0,0,1-.8.83H.8a.82.82,0,0,1-.8-.83V16.938a.847.847,0,0,1,.232-.593A.8.8,0,0,1,.8,16.1Zm12.869,9.6,1.274,6.07H11.453l1.027-6.15a2.451,2.451,0,0,1-1.609-2.768,2.359,2.359,0,0,1,4.69.259,2.432,2.432,0,0,1-1.887,2.575Z"
        transform="translate(0 -0.025)"
        fillRule="evenodd"
      />
    </svg>
  );

  return showLockIcon ? LockIcon : UnlockIcon;
};

export default KeyLockIcon;
