import { rewardLevelIcons } from "../../constants/rewardLevelIcons";
import "./termsAndConditions.scss";

import React from "react";

const TermsAndConditions = () => {
  return (
    <div id="top" className="terms-container">
      <div className="rewardLevelsContainer">
        <h2>Points Reward Levels</h2>
        <ul className="rewardLevelsList">
          <li>
            <img
              src={rewardLevelIcons("star")}
              alt="Star Icon"
              className="reward-icon"
            />
            <span>Star - 1,000 points</span>
          </li>
          <li>
            <img
              src={rewardLevelIcons("silver")}
              alt="Silver Icon"
              className="reward-icon"
            />
            <span>Silver - 5,000 points</span>
          </li>
          <li>
            <img
              src={rewardLevelIcons("gold")}
              alt="Gold Icon"
              className="reward-icon"
            />
            <span>Gold - 10,000 points</span>
          </li>
          <li>
            <img
              src={rewardLevelIcons("platinum")}
              alt="Platinum Icon"
              className="reward-icon"
            />
            <span>Platinum - 15,000 points</span>
          </li>
          <li>
            <img
              src={rewardLevelIcons("diamond")}
              alt="Black Diamond Icon"
              className="reward-icon"
            />
            <span>Black Diamond - 25,000 points</span>
          </li>
        </ul>
      </div>
      <div className="earningsContainer">
        <h2>Earnings</h2>
        <ul className="earningsList">
          <li>
            <img
              src={rewardLevelIcons("star")}
              alt="Star Icon"
              className="reward-icon"
            />
            <span>Star: 1.0x points per $1.00</span>
          </li>
          <li>
            <img
              src={rewardLevelIcons("silver")}
              alt="Silver Icon"
              className="reward-icon"
            />
            <span>Silver: 1.25x points per $1.00</span>
          </li>
          <li>
            <img
              src={rewardLevelIcons("gold")}
              alt="Gold Icon"
              className="reward-icon"
            />
            <span>Gold: 1.50x points per $1.00</span>
          </li>
          <li>
            <img
              src={rewardLevelIcons("platinum")}
              alt="Platinum Icon"
              className="reward-icon"
            />
            <span>Platinum: 1.75x points per $1.00</span>
          </li>
          <li>
            <img
              src={rewardLevelIcons("diamond")}
              alt="Black Diamond Icon"
              className="reward-icon"
            />
            <span>Black Diamond: 2.0x points per $1.00</span>
          </li>
        </ul>
      </div>
      <h2 className="RedemptionBenefits">Redemption Benefits</h2>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        <li style={{ marginBottom: "15px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={rewardLevelIcons("star")}
              alt="Star Icon"
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <span>Star</span>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <span style={{ color: "var(--color-darkGold)" }}>★</span> Use 1,000
            points for a $10 bonus discount towards your campaign on Skits
            Olympic
          </div>
        </li>

        <li style={{ marginBottom: "15px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={rewardLevelIcons("silver")}
              alt="Silver Icon"
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <span>Silver</span>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <span style={{ color: "var(--color-darkGold)" }}>★</span> Use 5,000
            points for a $50 bonus discount towards your campaign on Skits
            Olympic
          </div>
        </li>

        <li style={{ marginBottom: "15px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={rewardLevelIcons("gold")}
              alt="Gold Icon"
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <span>Gold</span>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <span style={{ color: "var(--color-darkGold)" }}>★</span> Use 10,000
            points for a $100 bonus discount towards your campaign on Skits
            Olympic
          </div>
        </li>

        <li style={{ marginBottom: "15px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={rewardLevelIcons("platinum")}
              alt="Platinum Icon"
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <span>Platinum</span>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <span style={{ color: "var(--color-darkGold)" }}>★</span> Use 15,000
            points for a $150 bonus discount towards your campaign on Skits
            Olympic
          </div>
        </li>

        <li style={{ marginBottom: "15px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={rewardLevelIcons("blackDiamond")}
              alt="Black Diamond Icon"
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <span>Black Diamond</span>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <span style={{ color: "var(--color-darkGold)" }}>★</span> Use 25,000
            points for a $250 bonus discount towards your campaign on Skits
            Olympic
          </div>
        </li>
      </ul>
      <h2>FAQ</h2>
      <h3>Do points expire?</h3>
      <p>
        No, your points never expire as long as you have activity (voting or
        redeeming through your campaign payments) at least every 3 months.
      </p>

      <h3>Do Platinum and Diamond members earn points differently?</h3>
      <p>
        Yes, Platinum members earn 1.75 points per $1 spent over 15,000 points.
        Black Diamond members earn 2 points per $1 spent over 25,000 points.
      </p>
      <h3>Can I Earn Points in Other Ways on the App?</h3>
      <p>
        Absolutely! Earn points by voting on Skits. Voting counts when you rate
        all categories of a Skit. Points are awarded once per Skit.
      </p>
      <h3>How do I keep track of my points?</h3>
      <p>
        You can track your points on your dashboard. Every unique vote earns one
        point, and if you update your vote, only your rating changes, not your
        points.
      </p>
    </div>
  );
};

export default TermsAndConditions;
