import React, { useState } from "react";
import "./handClap.scss";

const HandClap = ({ onClap, claps }) => {
  const [isClapping, setIsClapping] = useState(false);
  const [showSound, setShowSound] = useState(false);
  const [animationDuration, setAnimationDuration] = useState(1);

  const handleClap = () => {
    setIsClapping(true);
    setShowSound(true);
    setTimeout(() => {
      setShowSound(false);
      setIsClapping(false);
    }, animationDuration * 1000);
    onClap();
  };

  return (
    <div className="hand-clap-container" onClick={handleClap}>
      <svg
        className={`clapping ${isClapping ? "active" : ""}`}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="-270 87 417.1 387"
        style={{ enableBackground: "new -270 87 417.1 387" }}
        xmlSpace="preserve"
      >
        <path
          className="left-hand"
          d="M-268.1,338l21.7-21.7c2.3-2.3,3.5-5.3,3.5-8.5v-55.7c0-5.6,2.2-10.9,6.2-14.9l32.4-32.4
          c2.1-2.1,5.8-1.8,7.4,0.8c2.8,4.4,5,11.4-1.4,18.9c-5.1,5.9-10.3,10.9-13.7,14.1c-2.2,2-2.2,5.4-0.1,7.5l0,0c2,2,5.3,2,7.3,0
          l87.6-87.6c4.6-4.6,12.2-4.6,16.8,0l0,0c4.6,4.6,4.6,12.2,0,16.8l-66.2,66.2c-2.4,2.4-2.4,6.4,0,8.8l0,0c2.4,2.4,6.4,2.4,8.8,0
          l74.5-75.3c4.6-4.7,12.2-4.7,16.9,0l1.8,1.8c4.6,4.6,4.6,12.1,0.1,16.8l-70.4,71.3c-2.2,2.3-2.2,5.9,0,8.2l0,0
          c2.3,2.3,5.9,2.3,8.2,0l61.2-61.2c4.6-4.6,12.2-4.6,16.8,0l0.2,0.2c4.6,4.6,4.6,12.2,0,16.8l-67.1,67.1c-1.7,1.7-1.7,4.6,0,6.3l0,0
          c1.7,1.7,4.6,1.7,6.3,0l50.7-50.7c3.9-3.9,10.1-3.9,13.9,0l0,0c3.9,3.9,3.9,10.1,0,13.9l-98.2,98.2c-1.2,1.2-2.6,2.2-4.1,3
          c-5.3,2.5-18.3,7.7-35.5,6.6l-13.7,13.6c-2.1,2.1-5.4,2.4-7.8,0.7l-63.6-44.8C-269.3,341.7-269.5,339.4-268.1,338z"
        />
        <path
          className="right-hand"
          d="M-208.1,398l21.7-21.7c2.3-2.3,3.5-5.3,3.5-8.5v-55.7c0-5.6,2.2-10.9,6.2-14.9l32.4-32.4
          c2.1-2.1,5.8-1.8,7.4,0.8c2.8,4.4,5,11.4-1.4,18.9c-5.1,5.9-10.3,10.9-13.7,14.1c-2.2,2-2.2,5.4-0.1,7.5l0,0c2,2,5.3,2,7.3,0
          l87.6-87.6c4.6-4.6,12.2-4.6,16.8,0l0,0c4.6,4.6,4.6,12.2,0,16.8l-66.2,66.2c-2.4,2.4-2.4,6.4,0,8.8l0,0c2.4,2.4,6.4,2.4,8.8,0
          l74.5-75.3c4.6-4.7,12.2-4.7,16.9,0l1.8,1.8c4.6,4.6,4.6,12.1,0.1,16.8l-70.4,71.3c-2.2,2.3-2.2,5.9,0,8.2l0,0
          c2.3,2.3,5.9,2.3,8.2,0l61.2-61.2c4.6-4.6,12.2-4.6,16.8,0l0.2,0.2c4.6,4.6,4.6,12.2,0,16.8L-55.6,356c-1.7,1.7-1.7,4.6,0,6.3l0,0
          c1.7,1.7,4.6,1.7,6.3,0l50.7-50.7c3.9-3.9,10.1-3.9,13.9,0l0,0c3.9,3.9,3.9,10.1,0,13.9l-98.2,98.2c-1.2,1.2-2.6,2.2-4.1,3
          c-5.3,2.5-18.3,7.7-35.5,6.6l-13.7,13.6c-2.1,2.1-5.4,2.4-7.8,0.7l-63.6-44.8C-209.3,401.7-209.5,399.4-208.1,398z"
        />
        <line
          className={`sound sound1 ${showSound ? "active" : ""}`}
          x1="-169.4"
          y1="170.9"
          x2="-154.9"
          y2="185.4"
        />
        <line
          className={`sound sound2 ${showSound ? "active" : ""}`}
          x1="-135"
          y1="136.8"
          x2="-120.4"
          y2="151.4"
        />
        <line
          className={`sound sound3 ${showSound ? "active" : ""}`}
          x1="-77.3"
          y1="111.1"
          x2="-77.3"
          y2="135.1"
        />
        <line
          className={`sound sound4 ${showSound ? "active" : ""}`}
          x1="11.6"
          y1="168.9"
          x2="-3"
          y2="183.4"
        />
        <line
          className={`sound sound5 ${showSound ? "active" : ""}`}
          x1="-20.9"
          y1="137.8"
          x2="-35.4"
          y2="152.4"
        />
        <line
          className={`sound sound6 ${showSound ? "active" : ""}`}
          x1="35.6"
          y1="202.9"
          x2="21"
          y2="217.4"
        />
      </svg>
      <div className="clap-count">{claps}</div> {/* Display the clap count */}
    </div>
  );
};

export default HandClap;
