export const getSkitTimeArray = () => {
    const skitTimeArray = localStorage.getItem("skitTimes");
    return skitTimeArray ? JSON.parse(skitTimeArray) : [];
  };
  
  export const saveSkitTime = (skitId, time) => {
    const skitTimes = getSkitTimeArray();
  
    if (skitTimes.length === 1) {
      skitTimes[0] = { skitId, time }; // Replace the single skit
    } else {
      skitTimes.length = 0; // Clear the array if more than 1 skit exists
      skitTimes.push({ skitId, time }); // Add the current skit
    }
  
    localStorage.setItem("skitTimes", JSON.stringify(skitTimes));
    console.log(`Saved skit ${skitId} with time ${time}`);
  };
  
  export const getSkitTime = (skitId) => {
    const skitTimes = getSkitTimeArray();
    const skitEntry = skitTimes.find((entry) => entry.skitId === skitId);
    return skitEntry ? skitEntry.time : 0;
  };
  