import React, { useRef, useEffect, useState } from "react";
import { formatTime } from "../../utils/formatTime";
import VideoPlayerBtns from "./videoPlayerBtnsControls";
import VolumeControl from "../volumeControl/volumeControl";
import ToolTipNumbers from "../tooltipNumbers/tooltipNumbers";
import "./videoRangePosition.scss";

const VideoPlayerControls = ({
  duration,
  videoRef,
  logView = () => {}, // Default to a no-op function If the parent component doesn't pass logView, this empty function will be used, and it won't break the component.
  showRawTimer = false,
  onVolumeChange,
  volume,
  showVolumeControl = true,
  isSkitPlaying,
  setIsSkitPlaying,
  onPlayTypeChange,
  adDetails = null, // Pass ad details if an ad is playing
  savedTime,
  updateProgressCallback,
}) => {
  const [toolTipTime, setToolTipTime] = useState({
    visible: false,
    time: 0,
  });
  const progressRef = useRef(0); // Tracks progress in seconds
  const adProgressRef = useRef(0); // Tracks ad progress in seconds
  const intervalRef = useRef(null); // Add this if you still need interval logic
  const [isAdPlaying, setIsAdPlaying] = useState(false); // Track ad playback
  const animationFrameRef = useRef(null);
  const progressBarRef = useRef(null); // Reference to the progress bar
  const containerRef = useRef(null); // Reference to the progress container
  const rawTimerRef = useRef(null); // Reference to the raw timer display
  const progressThumbRef = useRef(null); // Reference to the draggable thumb
  const formattedTimerRef = useRef(null); // Reference to the formatted timer display
  const [hasLoggedView, setHasLoggedView] = useState(false); // Tracks if the view was logged
  const threshold = duration * 0.9; // Calculate the 90% threshold
  const [isBusy, setIsBusy] = useState(false); // using state with time out to provide space for the function to finish the job, which prevents overlapping issues with the browser

  useEffect(() => {
    if (hasLoggedView) {
      logView(); // Trigger the log action
    }
  }, [hasLoggedView]);
  useEffect(() => {
    if (updateProgressCallback) {
      updateProgressCallback(updateProgress); // Pass the function to the parent
    }
  }, [updateProgressCallback]);

  useEffect(() => {
    const video = videoRef.current;

    // Ensure the video starts at the saved time
    if (video && savedTime > 0) {
      video.currentTime = savedTime;
      updateProgress(savedTime, duration); // Update progress bar
      console.log(`Resuming video at time: ${savedTime}`);
    }
  }, [savedTime, videoRef, duration]);

  const updateProgress = (currentTime, totalDuration) => {
    const progressBar = progressBarRef.current;
    const progressThumb = progressThumbRef.current;

    const percentage = (currentTime / totalDuration) * 100;
    const clampedPercentage = Math.min(percentage, 100);

    if (progressBar) progressBar.style.width = `${clampedPercentage}%`;
    if (progressThumb) {
      const thumbWidth = 16;
      const containerWidth = progressBar.parentElement.offsetWidth;
      const offsetPercentage = (thumbWidth / containerWidth) * 100;
      progressThumb.style.left = `calc(${clampedPercentage}% - ${offsetPercentage / 2}%)`;
    }

    updateTimer(currentTime, totalDuration);
  };

  const startProgress = () => {
    if (animationFrameRef.current) return; // Prevent multiple animation frames

    const updateProgress = (timestamp) => {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const currentTime = videoElement.currentTime; // Get the current video time
      const progressPercentage = (currentTime / duration) * 100; // Calculate progress

      // Update the progress bar width
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${progressPercentage}%`;
      }

      // Update the thumb position
      if (progressThumbRef.current) {
        const containerWidth = progressBarRef.current.parentElement.offsetWidth;
        const thumbOffset = (progressPercentage / 100) * containerWidth;
        progressThumbRef.current.style.left = `${thumbOffset}px`;
      }

      // Update the timer
      updateTimer(currentTime);

      // Log view at 90% threshold
      if (!hasLoggedView && currentTime >= threshold) {
        setHasLoggedView(true);
        logView();
      }

      // Continue if the video is still playing
      if (!videoElement.paused && !videoElement.ended) {
        animationFrameRef.current = requestAnimationFrame(updateProgress);
      }
    };

    animationFrameRef.current = requestAnimationFrame(updateProgress);
  };

  // Pause the progress bar
  const pauseProgress = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    setIsSkitPlaying(false); // Set paused state
    if (videoRef.current) {
      videoRef.current.pause(); // Pause the video
    }
  };

  // Reset the progress bar
  const resetProgress = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    progressRef.current = 0; // Reset progress to 0
    updateProgressBar(0, duration); // Sync with the new media
    updateTimer(0);
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Reset video to start
    }
  };

  // Rewind the video by 15 seconds
  const rewind = () => {
    if (isBusy) return; // Prevent overlapping actions
    setIsBusy(true); // Mark as busy
    const videoElement = videoRef.current;
    if (!videoElement) return;
    // Calculate new time, clamped to 0
    const newTime = Math.max(videoElement.currentTime - 15, 0);
    // Update video current time and progressRef
    videoElement.currentTime = newTime;
    progressRef.current = newTime;
    // Sync progress bar and timer
    updateProgress(newTime, duration);
    // Allow further actions after 300ms
    setTimeout(() => setIsBusy(false), 300);
  };

  // Fast-forward the video by 15 seconds
  const fastForward = () => {
    if (isBusy) return; // Prevent overlapping actions
    setIsBusy(true); // Mark as busy
    const videoElement = videoRef.current;
    if (!videoElement) return;
    // Calculate new time, clamped to duration
    const newTime = Math.min(videoElement.currentTime + 15, duration);
    // Update video current time and progressRef
    videoElement.currentTime = newTime;
    progressRef.current = newTime;
    // Sync progress bar and timer
    updateProgress(newTime, duration);
    // Allow further actions after 300ms
    setTimeout(() => setIsBusy(false), 300);
  };

  // Update the progress bar dynamically
  const updateProgressBar = (currentTime, totalDuration) => {
    const progressBar = progressBarRef.current;
    const progressThumb = progressThumbRef.current;

    const percentage = (currentTime / totalDuration) * 100;
    const clampedPercentage = Math.min(percentage, 100);

    if (progressBar) progressBar.style.width = `${clampedPercentage}%`;
    if (progressThumb) {
      const thumbWidth = 16;
      const containerWidth = progressBar.parentElement.offsetWidth;
      const offsetPercentage = (thumbWidth / containerWidth) * 100;
      progressThumb.style.left = `calc(${clampedPercentage}% - ${offsetPercentage / 2}%)`;
    }

    if (rawTimerRef.current)
      rawTimerRef.current.textContent = `${Math.floor(currentTime)}s / ${Math.floor(
        totalDuration
      )}s`;

    if (formattedTimerRef.current)
      formattedTimerRef.current.textContent = `${formatTime(currentTime)} / ${formatTime(
        totalDuration
      )}`;
    //   // console.log(
    //   //   "Current Time:",
    //   //   currentTime,
    //   //   "Duration:",
    //   //   duration,
    //   //   "Percentage:",
    //   //   clampedPercentage
    //   // );
  };
  // Update the timer display
  const updateTimer = (currentTime) => {
    const rawTimer = rawTimerRef.current;
    const formattedTimer = formattedTimerRef.current;

    if (rawTimer) {
      rawTimer.textContent = `${Math.floor(currentTime)}s / ${Math.floor(duration)}s`;
    }

    if (formattedTimer) {
      formattedTimer.textContent = `${formatTime(currentTime)} / ${formatTime(duration)}`;
    }
  };

  // Handle dragging the progress bar
  const handleDrag = (event) => {
    const container = containerRef.current;
    const videoElement = videoRef.current;

    if (!container || !videoElement) return; // Safety checks

    const containerWidth = container.offsetWidth;
    const clickPosition =
      event.clientX - container.getBoundingClientRect().left;

    const newWidth = Math.max(0, Math.min(clickPosition, containerWidth));
    const percentage = newWidth / containerWidth;
    const newTime = percentage * duration; // Calculate the new time

    progressRef.current = newTime; // Update the progress value

    // Update the progress bar and timer
    updateProgressBar(newTime, duration);

    // Trigger logging if the threshold is reached during drag
    if (!hasLoggedView && newTime >= threshold) {
      setHasLoggedView(true); // Trigger logging state
      logView(); // Log the view
    }

    // Show the tooltip at the dragged position
    setToolTipTime({
      visible: true,
      time: newTime,
      position: newWidth, // Set the position in pixels
    });

    // Update the video's current time
    videoElement.currentTime = newTime;
  };

  const handleDragStart = () => {
    pauseProgress(); // Pause during drag
  };

  const handleDragEnd = () => {
    const videoElement = videoRef.current;

    // Hide the tooltip
    setToolTipTime((prev) => ({
      ...prev,
      visible: false,
    }));

    if (videoElement) {
      const playPromise = videoElement.play(); // Attempt to resume playback
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            startProgress(); // Resume progress tracking
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              console.warn(
                "Suppressed 'play()' interruption error after drag."
              );
            } else {
              console.error("Error resuming playback after drag:", error);
            }
          });
      }
    } else {
      startProgress(); // Resume progress tracking even if videoRef is null
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleTimeUpdate = () => {
      const currentTime = videoElement.currentTime;
      progressRef.current = currentTime; // Keep progress in sync
      updateProgressBar(currentTime); // Sync progress bar
      updateTimer(currentTime); // Sync timer
    };
    if (videoElement) {
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [videoRef, duration]);

  useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      if (!video) return;
      const currentTime = video.currentTime;
      const totalDuration = isAdPlaying
        ? adDetails?.duration || duration
        : duration;

      // Update progress bar and timer
      updateProgress(currentTime, totalDuration);
    };

    // Add timeupdate event listener to the video
    if (video) {
      video.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (video) {
        video.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [videoRef, isAdPlaying, adDetails, duration]);

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const startAnimation = (totalDuration) => {
    const updateAnimation = (timestamp) => {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const currentTime = videoElement.currentTime;

      // Update progress bar and timers
      updateProgress(currentTime, totalDuration);

      // Continue animation
      if (!videoElement.paused && !videoElement.ended) {
        animationFrameRef.current = requestAnimationFrame(updateAnimation);
      }
    };

    animationFrameRef.current = requestAnimationFrame(updateAnimation);
  };

  useEffect(() => {
    if (adDetails && adDetails.isAdPlaying) {
      setIsAdPlaying(true);
      adProgressRef.current = 0; // Reset ad progress to 0

      const adDuration = adDetails.duration;

      const updateAdProgress = () => {
        adProgressRef.current += 1 / 60; // Increment progress (assuming 60fps)

        // Update progress bar
        updateProgress(adProgressRef.current, adDuration);

        if (adProgressRef.current < adDuration) {
          requestAnimationFrame(updateAdProgress);
        } else {
          setIsAdPlaying(false); // Ad playback complete
          adDetails.onAdComplete(); // Trigger ad completion callback
        }
      };

      const animationFrameId = requestAnimationFrame(updateAdProgress);

      // Cleanup on unmount or ad change
      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, [adDetails, updateProgress]);

  const stopAnimation = () => {
    cancelAnimationFrame(animationFrameRef.current);
  };

  const handlePlayPause = async () => {
    const video = videoRef.current;
    if (!video) return;
    // Step 1: Trigger `onPlayTypeChange` (parent handles play type logic)
    await onPlayTypeChange(isSkitPlaying); // Pass the current skit state
    // Step 2: Toggle play state for skits
    if (!isAdPlaying) {
      if (isSkitPlaying) {
        console.log("Pausing skit...");
        video.pause();
        stopAnimation();
        setIsSkitPlaying(false);
      } else {
        console.log("Playing skit...");
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) =>
            console.error("Error playing skit:", error)
          );
        }
        setIsSkitPlaying(true);
        startAnimation(duration);
      }
    }
  };

  useEffect(() => {
    const handleUnhandledRejection = (event) => {
      if (event.reason?.name === "AbortError") {
        event.preventDefault(); // Suppress the error
        console.warn("Globally suppressed 'play()' interruption error.");
      }
    };

    window.addEventListener("unhandledrejection", handleUnhandledRejection);
    return () => {
      window.removeEventListener(
        "unhandledrejection",
        handleUnhandledRejection
      );
    };
  }, []);
  useEffect(() => {
    return () => {
      cancelAnimationFrame(animationFrameRef.current); // Clean up animation
    };
  }, []);

  return (
    <div>
      {/* Progress Bar Container */}
      <div
        className="video-progress-container"
        ref={containerRef}
        onMouseDown={handleDragStart}
        onMouseMove={(e) => e.buttons === 1 && handleDrag(e)}
        onMouseUp={handleDragEnd}
      >
        <div className="progress-bar" ref={progressBarRef}></div>
        <div className="progress-thumb" ref={progressThumbRef}></div>
      </div>
      {/* Visible Timer for Users */}
      <div className="timer-container">
        <ToolTipNumbers
          visible={toolTipTime.visible}
          time={toolTipTime.time}
          position={toolTipTime.position}
        />
        <div className="timer-display" ref={formattedTimerRef}>
          0:00 / {formatTime(duration)}
        </div>
      </div>
      {/* InVisible Timer for Users, metrics are in seconds and used for positioning precision */}
      <div
        className="raw-timer-display"
        style={{ display: showRawTimer ? "block" : "none" }}
        ref={rawTimerRef}
      >
        0s / {duration}s
      </div>
      <VideoPlayerBtns
        isSkitPlaying={isSkitPlaying}
        onPlayPause={handlePlayPause} // Updated handler
        onRewind={rewind}
        onForward={fastForward}
        videoRef={videoRef}
      />
      {showVolumeControl && (
        <VolumeControl volume={volume} setVolume={onVolumeChange} />
      )}
      {/* Controls for test purposes if needed in the future */}
      {/* <button onClick={rewind}>Rewind 15s</button>
      <button onClick={startProgress}>Play</button>
      <button onClick={pauseProgress}>Pause</button>
      <button onClick={fastForward}>Fast Forward 15s</button>
      <button onClick={resetProgress}>Reset</button> */}
    </div>
  );
};

export default React.memo(VideoPlayerControls);
