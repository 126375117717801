import React, { useState, useEffect, useRef } from "react"; // useRef is a React hook that creates a mutable object with a .current property that can hold a DOM node or any value. It persists across re-renders and does not trigger a re-render when updated.
import { apiFetchCurrentUserAttributes } from "../../api/backend/user";
import { useAuth } from "../context/authContext";
import { renderUserThumbnail } from "../../controller/userThumbnail";
import { ImgTopBtn, ImgTopBtnAnimate } from "../buttons";
import { PaperPlane2Icon, XIcon } from "../icons";
import AllEmoteSelection from "../emoji/allEmoteSelection";
// Importing the emote selection component
import { renderCommentWithEmotes } from "../../utils/emoteUtils";
// Importing utility function to render comments with emotes
import "./currentUserReply.scss";

const CurrentUserReply = ({
  replyingTo,
  onSubmitReply,
  onCancel,
  value,
  setValue,
  placeholder,
  newThreadError,
  setNewThreadError,
  responseError,
  setResponseError,
  type, // prop used to define type of reply, which is comment, thread, or response to user. Used for cancel
  id, // id prop used for the id of the type of reply since this component manages all three replies. Used for cancel
}) => {
  const { currentUser } = useAuth();
  const [showEmotePicker, setShowEmotePicker] = useState(false);
  const overlayRef = useRef(null); // overlayRef is used to reference the overlay div element that displays the rendered emotes.
  const inputRef = useRef(null); // inputRef is used to reference the textarea input element where the user types their comment.
  const [userReplyFocus, setUserReplyFocus] = useState(false);
  // By using refs, we can ensure that the overlay is positioned correctly relative to the input element. This is important for maintaining a consistent user experience.
  const [userAttributes, setUserAttributes] = useState({
    profileImageUrl: "",
    emotionconId: "",
    thumbnailPreference: "",
  });

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const response = await apiFetchCurrentUserAttributes(currentUser);
        if (response.data.success) {
          setUserAttributes(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching user attributes:", error);
      }
    };

    fetchUserAttributes();
  }, []);

  useEffect(() => {
    const storedEmote = localStorage.getItem("selectedEmote"); // Retrieve the stored emote from local storage
    if (storedEmote) {
      const emote = JSON.parse(storedEmote); // Log the retrieved emote for debugging
      console.log("Stored Emote Retrieved:", emote);
      // setValue((prev) => `${prev} ${emote.name}`); // Append the emote name to the message value
      localStorage.removeItem("selectedEmote");
    }
  }, [showEmotePicker, setValue]);

  const handleSelectEmote = (emoteName) => {
    console.log("Emote Selected:", emoteName);
    const replyValue = `${value} ${emoteName}`;
    setValue((prev) => `${prev} ${emoteName}`); // Append the selected emote name to the message value
    localStorage.setItem("messageValue", replyValue);
    setShowEmotePicker(false);
    console.log("Updated Message Value:", `${value} ${emoteName}`);
  };

  const handleInputChange = (e) => {
    setValue(e.target.value);
    if (newThreadError) {
      setNewThreadError("");
    } else if (responseError) {
      setResponseError("");
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setValue((prev) => prev.slice(0, -1));
    }
  };

  const currentPlaceholder = newThreadError || responseError || placeholder;

  const renderOverlay = () => {
    // This function takes the messageValue (the current text in the input field)
    // and processes it to identify and replace specific keywords with corresponding
    // emote components.
    const parts = renderCommentWithEmotes(value);
    console.log("Overlay Parts:", parts);
    // The function splits the message into parts where each part is either a string of
    // text or an emote. This allows for dynamic rendering of text and emotes within
    // the same message.
    return parts.map((part, index) => (
      <span key={index} className="overlay-part">
        {part === " " ? "\u00A0" : part}
      </span>
    ));
  };

  return (
    <div className="reply-form-container">
      <div className="reply-bubble">
        <div className="user-thumbnail">
          {" "}
          {renderUserThumbnail(userAttributes)}
        </div>
        <div className="reply-to-user-thread-bubble">
          <p className="username">- {replyingTo.username},</p>
        </div>
      </div>
      <div
        className="textarea-wrapper"
        style={{ position: "relative", width: "100%" }}
      >
        <textarea
          ref={inputRef}
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder={userReplyFocus ? "" : currentPlaceholder} // Clear placeholder on focus
          className={newThreadError || responseError ? "error-placeholder" : ""}
          style={{ color: "transparent", caretColor: "var(--color-gold)" }} // Hide the text in the input field because we would see the input and the overlay.
          onFocus={() => {
            setUserReplyFocus(true);
          }}
          onBlur={() => setUserReplyFocus(false)}
        />
        {!value && !userReplyFocus && (
          <span className="blinking-cursor-gold-color user-reply-box-cursor">
            |
          </span>
        )}
        <div
          className="overlay"
          ref={overlayRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            pointerEvents: "none",
            color: "black",
            whiteSpace: "pre-wrap", // Preserve whitespace and line breaks.
          }}
        >
          {renderOverlay()}
        </div>
      </div>
      {showEmotePicker && (
        <div className="reply-emote-picker">
          <AllEmoteSelection
            onSelectEmote={handleSelectEmote}
            showAllEmotes={showEmotePicker}
          />
        </div>
      )}
      <div className="replyControlsWrapper">
        <ImgTopBtn
          id="submit-btn"
          image={<PaperPlane2Icon fillColor="var(--color-bloodred)" />}
          imgTopBtnBorderRadius="25px"
          imgTopBtnText="Reply"
          imgTopBtnWidth="fit-content"
          imgTopBtnTextFontSize="1.3rem"
          imgTopBtnFontFamily="Roboto-Slab"
          imgTopBtnFontWeight="900"
          imgTopBtnFontColor="var(--color-bloodred)"
          paddingRight="5px"
          paddingLeft="5px"
          extraImgTopBtnStyles={{
            bottom: "20px",
            left: "10px",
          }}
          onClick={onSubmitReply}
        />
        <ImgTopBtnAnimate
          id="reaction-btn"
          image={
            showEmotePicker ? (
              <XIcon fillColor="var(--color-bloodred)" />
            ) : (
              <span role="img" aria-label="heart" className="heart-beat">
                ❤️
              </span>
            )
          }
          imgTopBtnBorderRadius="25px"
          imgTopBtnText="Reaction"
          imgTopBtnWidth="fit-content"
          imgTopBtnTextFontSize="1.3rem"
          imgTopBtnFontFamily="Roboto-Slab"
          imgTopBtnFontWeight="900"
          imgTopBtnFontColor="var(--color-bloodred)"
          paddingRight="5px"
          paddingLeft="5px"
          extraImgTopBtnStyles={{
            bottom: "20px",
            left: "10px",
          }}
          onClick={() => setShowEmotePicker(!showEmotePicker)}
        />
        <ImgTopBtn
          id="cancel-btn"
          imgTopBtnBorderRadius="25px"
          imgTopBtnText=" X Cancel"
          imgTopBtnWidth="fit-content"
          imgTopBtnTextFontSize="1.3rem"
          imgTopBtnFontFamily="Roboto-Slab"
          imgTopBtnFontWeight="900"
          imgTopBtnFontColor="var(--color-bloodred)"
          paddingRight="5px"
          paddingLeft="5px"
          extraImgTopBtnStyles={{
            bottom: "20px",
            left: "10px",
          }}
          onClick={() => onCancel(type, id)} // Pass type and id to onCancel
        />
      </div>
    </div>
  );
};

export default CurrentUserReply;
