import React, { useEffect, useState } from "react";
import { useAuth } from "../../components/context/authContext";
import { useNavigate } from "react-router-dom";
import { LeftArrow } from "../../components/icons";
import { SolidColorButton } from "../../components/buttons";
import FilmStrip from "./filmStrip";
import AnimatedPencil from "./animatedPencil";
import AnimatedTrophy from "./animatedTrophy";
import AnimatedLightBulb from "./animatedLightBulb";
import AnimatedStar from "./animatedStar";
import CurrentAveragedRating from "../../components/starRating/dashboardStarRating";
import { apiFetchRatedSkitsForCurrentUserDashboard } from "../../api/backend/skits";
import { categories } from "../../constants/categories";
import TooltipDots from "../../components/toolTipDots/toolTipDots";
import CurrentUserSkitDashboardMediumSmallDevice from "./CurrentUserSkitDashboardMediumSmallDevice";
import "./currentUserSkitDashboard.scss";

const getCategoryById = (id) => {
  return categories.find((category) => category.id === id);
};

const CurrentUserSkitDashboard = () => {
  const [ratedSkits, setRatedSkits] = useState([]);
  const { currentUser } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    const fetchRatedSkits = async () => {
      if (currentUser) {
        try {
          console.log("Fetching rated skits for user:", currentUser); // Add this line
          const targetId = currentUser;
          const skits =
            await apiFetchRatedSkitsForCurrentUserDashboard(targetId);
          console.log("Fetched skits:", skits); // Add this line
          setRatedSkits(skits);
        } catch (error) {
          console.error("Error fetching rated skits:", error);
        }
      }
    };

    fetchRatedSkits();
  }, [currentUser]);

  return (
    <>
      <div id="top" className="user-skit-dashboard-desktop-view">
        <SolidColorButton
          solidColorBtnImage={
            <LeftArrow
              height="15px"
              width="15px"
              fillColor="#964b00"
              strokeWidth="4"
              strokeColor="#964b00"
            />
          }
          className="gold-btn"
          solidColorBtnText="Back"
          solidColorBtnFontWeight="800"
          solidColorBtnFontFamily="helvetica"
          solidColorBtnTextFontSize=".75rem"
          solidColorBtnFontColor="#964b00"
          solidColorBtnWidth="100px"
          solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
          onClick={() => navigate(-1)}
        />
        <div>
          {ratedSkits.length > 0 ? (
            ratedSkits.map((skit) => {
              const category = getCategoryById(skit.categoryid);
              return (
                <div key={skit.id} className="skitDesktop-icon-wrapper">
                  <FilmStrip
                    title={
                      <TooltipDots
                        text={skit.title}
                        maxLength={33} // datavalidation for dynamic data to appear before the ...
                      />
                    }
                  />
                  <div className="skitDesktop-icon-container">
                    <div>
                      <AnimatedPencil />
                    </div>
                    <CurrentAveragedRating
                      rating={skit.storyLineAverageScore}
                    />
                    <p className="story-line">Story Line</p>
                    <p className="story-line-description">
                      (meaning, sequence, events)
                    </p>
                  </div>
                  <div className="skitDesktop-icon-container">
                    <AnimatedStar />
                    <CurrentAveragedRating
                      rating={skit.performanceAverageScore}
                    />
                    <p className="performance">Actor's/(s') Performance</p>
                    <p className="performance-description">
                      (Believable Character(s), Reactional timing)
                    </p>
                  </div>
                  <div className="skitDesktop-icon-container">
                    <AnimatedLightBulb />
                    <CurrentAveragedRating
                      rating={skit.originalityAverageScore}
                    />
                    <p className="originality">Originality</p>
                    <p className="originality-description">
                      (How original is the skit)
                    </p>
                  </div>
                  <div className="skitDesktop-icon-container">
                    <AnimatedTrophy />
                    <CurrentAveragedRating rating={skit.categoryAverageScore} />
                    <p className="category-title">{category.title}</p>
                    <p className="category-description">
                      {category.description}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <div className="skitDesktop-icon-wrapper">
                <FilmStrip title="Skit Title" />
                <h2 style={{ position: "absolute", top: "0px" }}>
                  No Skit Metrics Found..
                </h2>
                <div className="skitDesktop-icon-container">
                  <div>
                    <AnimatedPencil />
                  </div>
                  <CurrentAveragedRating rating={0} />
                  <p className="story-line">Story Line</p>
                  <p className="story-line-description">
                    (meaning, sequence, events)
                  </p>
                </div>
                <div className="skitDesktop-icon-container">
                  <AnimatedStar />
                  <CurrentAveragedRating rating={0} />
                  <p className="performance">Actor's/(s') Performance</p>
                  <p className="performance-description">
                    (Believable Character(s), Reactional timing)
                  </p>
                </div>
                <div className="skitDesktop-icon-container">
                  <AnimatedLightBulb />
                  <CurrentAveragedRating rating={0} />
                  <p className="originality">Originality</p>
                  <p className="originality-description">
                    (How original is the skit)
                  </p>
                </div>
                <div className="skitDesktop-icon-container">
                  <AnimatedTrophy />
                  <CurrentAveragedRating rating={0} />
                  <p className="category-title">Dynamic Category</p>
                  <p className="category-description">(Dynamic Description)</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <CurrentUserSkitDashboardMediumSmallDevice />
    </>
  );
};
export default CurrentUserSkitDashboard;
