import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../components/context/authContext";
import eventBus from "../../utils/eventBus";
import Remote from "../../components/remote/remote";
import FlipCounter from "../../components/flipCounter/flipCounterZero";
import SkitAdCard from "../../components/skitAd/skitAdCard";
import ClapperBoard from "../../components/clapperBoard/animatedClapperBoard";
import {
  SmallPlainInsetBtnEventListener,
  GlassBtn,
} from "../../components/buttons/"; // Ensure this is the correct import path
import {
  MegaphoneIcon,
  StarIcon,
  Filter,
  LineChartIcon,
} from "../../components/icons";
import UploadVideoForm from "../../components/forms/uploadVideoForm";
import {
  apiDeleteSkitIdFromSourceIdAction,
  apiFetchTrendingSkits,
  apiFetchPopularSkits,
} from "../../api/backend/skits";
import {
  apiRecordUserSkitNotInterested,
  apiFetchUserSkitNotInterested,
} from "../../api/backend/user";
import { useVideoFormStateCallBackController } from "../../controller/videoFormCallBackController";
import SearchBar from "../../components/search/search";
import Loader from "../../components/loader/loader";
import "./topSkistListsPage.scss";

const TopSkitsListsPage = () => {
  const { currentUser, handleLogout } = useAuth();
  const [isClapping, setIsClapping] = useState(false);
  console.log("At render, currentUser is", currentUser);
  const [trendingSkitsData, setTrendingSkitsData] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [popularSkitsData, setPopularSkitsData] = useState([]);
  const [colorPaletteVisible, setColorPaletteVisible] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [error, setError] = useState("");
  const [loadingTrending, setLoadingTrending] = useState(false);
  const [loadingPopular, setLoadingPopular] = useState(false);
  const [notInterested, setNotInterested] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const initialTrendingOffset =
    parseInt(localStorage.getItem("trendingOffset")) || 0;
  const [trendingOffset, setTrendingOffset] = useState(initialTrendingOffset);
  const initialPopularOffset =
    parseInt(localStorage.getItem("popularOffset")) || 0;
  const [popularOffset, setPopularOffset] = useState(initialPopularOffset);
  const maxSkitsPerCategory = 6;
  const { openSkitUploadForm, setOpenSkitUploadForm, openVideoForm } =
    useVideoFormStateCallBackController();

  const onLogoutClick = () => {
    handleLogout();
  };

  const fetchCurrentUserNotInterestedActivityLogs = useCallback(async () => {
    try {
      const response = await apiFetchUserSkitNotInterested({
        sourceId: currentUser,
      });
      if (response && response.data) {
        setNotInterested(response.data.map((log) => log.relatedId));
      }
    } catch (error) {
      console.error("Failed to fetch 'not interested' logs:", error);
      setError("Failed to load 'not interested' logs");
    } finally {
    }
  }, [currentUser]);

  useEffect(() => {
    fetchCurrentUserNotInterestedActivityLogs();
  }, [fetchCurrentUserNotInterestedActivityLogs]);

  const handleNotInterested = useCallback(
    async (skit) => {
      try {
        const response = await apiRecordUserSkitNotInterested({
          sourceId: currentUser,
          activity: "notInterested",
          relatedId: skit.id,
          targetId: skit.sourceId,
        });
        if (response.data && response.data.success) {
          setNotInterested((prev) => [...prev, skit.id]);
        }
      } catch (error) {
        console.error("Error marking skit as not interested:", error);
        setError("Failed to mark skit as not interested");
      }
    },
    [currentUser]
  );

  const afterUploadCloseModalRerender = () => {
    setOpenSkitUploadForm(false);
    fetchTrendingSkits(0);
    fetchPopularSkits(0);
  };

  const handleDeleteSourceIdRelatedIdSkit = (skitId) => {
    apiDeleteSkitIdFromSourceIdAction({ skitId })
      .then((response) => {
        if (response.status === 200) {
          const updatedTrendingSkits = trendingSkitsData.filter(
            (skit) => skit.id !== skitId
          );
          const updatedPopularSkits = popularSkitsData.filter(
            (skit) => skit.id !== skitId
          );
          setTrendingSkitsData(updatedTrendingSkits);
          setPopularSkitsData(updatedPopularSkits);
        } else {
          alert("Failed to delete the skit");
        }
      })
      .catch((error) => {
        console.error("Error deleting skit:", error);
        alert("An error occurred while deleting the skit");
      });
  };

  const toggleColorPaletteVisibility = () => {
    setColorPaletteVisible(!colorPaletteVisible);
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setSticky(scrollTop > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const fetchTrendingSkits = async (offset, categoryId = null) => {
    setLoadingTrending(true);
    try {
      const response = await apiFetchTrendingSkits(
        offset,
        maxSkitsPerCategory,
        categoryId
      );
      if (response.status === 200 && response.data.success) {
        const fetchedSkits = response.data.data;
        setTrendingSkitsData((prevSkits) =>
          offset === 0 ? fetchedSkits : [...prevSkits, ...fetchedSkits]
        );
      }
    } catch (error) {
      console.error("Error fetching skits:", error);
    } finally {
      setLoadingTrending(false);
    }
  };

  const fetchPopularSkits = async (offset, categoryId = null) => {
    setLoadingPopular(true);
    try {
      const response = await apiFetchPopularSkits(
        offset,
        maxSkitsPerCategory,
        categoryId
      );
      if (response.status === 200 && response.data.success) {
        const fetchedSkits = response.data.data;
        setPopularSkitsData((prevSkits) =>
          offset === 0 ? fetchedSkits : [...prevSkits, ...fetchedSkits]
        );
      }
    } catch (error) {
      console.error("Error fetching skits:", error);
    } finally {
      setLoadingPopular(false);
    }
  };
  const handleViewMoreTrending = async (event) => {
    event.preventDefault(); // Prevent default action
    setIsClapping(true);
    setLoadingTrending(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 500)); // Simulate delay for animation
      const newOffset = trendingOffset + maxSkitsPerCategory;
      setTrendingOffset(newOffset);
      await fetchTrendingSkits(newOffset, activeCategory); // Fetch more skits with the new offset
    } catch (error) {
      console.error("Failed to load more skits:", error);
    } finally {
      setIsClapping(false);
      setLoadingTrending(false);
    }
  };

  const handleViewMorePopular = async (e) => {
    e.preventDefault(); // Prevent default action
    setIsClapping(true);
    setLoadingPopular(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 500)); // Simulate delay for animation
      const newOffset = popularOffset + maxSkitsPerCategory;
      setPopularOffset(newOffset);
      await fetchPopularSkits(newOffset, activeCategory); // Fetch more skits with the new offset
    } catch (error) {
      console.error("Failed to load more skits:", error);
    } finally {
      setIsClapping(false);
      setLoadingPopular(false);
    }
  };
  const handleViewMoreAll = async (e) => {
    e.preventDefault(); // Prevent default action
    setIsClapping(true);
    setLoadingTrending(true);
    setLoadingPopular(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 500)); // Simulate delay for animation
      // Fetch more trending skits
      const newTrendingOffset = trendingOffset + maxSkitsPerCategory;
      setTrendingOffset(newTrendingOffset);
      await fetchTrendingSkits(newTrendingOffset, activeCategory);
      // Fetch more popular skits
      const newPopularOffset = popularOffset + maxSkitsPerCategory;
      setPopularOffset(newPopularOffset);
      await fetchPopularSkits(newPopularOffset, activeCategory);
    } catch (error) {
      console.error("Failed to load more skits:", error);
    } finally {
      setIsClapping(false);
      setLoadingTrending(false);
      setLoadingPopular(false);
    }
  };

  const updateSkitsData = useCallback(async () => {
    try {
      const updatedTrendingSkits = await apiFetchTrendingSkits(
        0,
        maxSkitsPerCategory,
        activeCategory
      );
      const updatedPopularSkits = await apiFetchPopularSkits(
        0,
        maxSkitsPerCategory,
        activeCategory
      );
      if (
        updatedTrendingSkits.status === 200 &&
        updatedTrendingSkits.data.success
      ) {
        setTrendingSkitsData(updatedTrendingSkits.data.data);
      }
      if (
        updatedPopularSkits.status === 200 &&
        updatedPopularSkits.data.success
      ) {
        setPopularSkitsData(updatedPopularSkits.data.data);
      }
    } catch (error) {
      console.error("Error updating skits:", error);
    } finally {
    }
  }, [maxSkitsPerCategory, activeCategory]);

  useEffect(() => {
    if (activeCategory === null) {
      // Fetch skits for all categories when no specific category is selected
      fetchTrendingSkits(trendingOffset);
      fetchPopularSkits(popularOffset);
    } else {
      // Fetch skits for the selected category
      fetchTrendingSkits(trendingOffset, activeCategory);
      fetchPopularSkits(popularOffset, activeCategory);
    }
  }, [trendingOffset, popularOffset, activeCategory]);

  useEffect(() => {
    const handleSkitPromotion = (event, promotedSkit) => {
      setTrendingSkitsData((prevSkits) => {
        const updatedSkits = [
          promotedSkit,
          ...prevSkits.filter((skit) => skit.id !== promotedSkit.id),
        ];
        return updatedSkits;
      });
      setPopularSkitsData((prevSkits) => {
        const updatedSkits = [
          promotedSkit,
          ...prevSkits.filter((skit) => skit.id !== promotedSkit.id),
        ];
        return updatedSkits;
      });
    };
    eventBus.on("skitPromoted", handleSkitPromotion);
    return () => {
      eventBus.off("skitPromoted", handleSkitPromotion);
    };
  }, []);

  const renderSkits = (skitsData, skitType) => {
    return skitsData
      .filter((skit) => !notInterested.includes(skit.id))
      .map((skit) => (
        <SkitAdCard
          key={skit.id}
          specificSkitData={skit}
          currentUser={currentUser}
          onDelete={() => handleDeleteSourceIdRelatedIdSkit(skit.id)}
          onNotInterested={() => handleNotInterested(skit)}
          updateSkitsData={updateSkitsData}
          skitType={skitType} // Pass the skit type to each Skit component
          isUploadVideoFormOpen={openSkitUploadForm} // Pass the form state to manage z-index
        />
      ));
  };
  const noChannelSelected = useCallback(async () => {
    try {
      const trendingResponse = await apiFetchTrendingSkits(
        0,
        maxSkitsPerCategory
      );
      const popularResponse = await apiFetchPopularSkits(
        0,
        maxSkitsPerCategory
      );

      if (trendingResponse.status === 200 && trendingResponse.data.success) {
        setTrendingSkitsData(trendingResponse.data.data);
      }
      if (popularResponse.status === 200 && popularResponse.data.success) {
        setPopularSkitsData(popularResponse.data.data);
      }
    } catch (error) {
      console.error("Error fetching skits when no channel is selected:", error);
    } finally {
    }
  }, [maxSkitsPerCategory]);

  useEffect(() => {
    if (activeCategory === null) {
      // Fetch skits for all categories when no specific category is selected
      noChannelSelected();
    } else {
      // Fetch skits for the selected category
      fetchTrendingSkits(trendingOffset, activeCategory);
      fetchPopularSkits(popularOffset, activeCategory);
    }
  }, [trendingOffset, popularOffset, activeCategory, noChannelSelected]);

  return (
    <div id="topSkitsListingPage">
      {/* <div className="flip-counter">
        <p>
          <FlipCounter />
        </p>
      </div> */}
      <div
        className={`${colorPaletteVisible ? "glassShelf" : "topControlsWrapper"} ${sticky ? "sticky" : "topControlsWrapper"}`}
      >
        <Remote
          onUploadClick={openVideoForm}
          toggleColorPaletteVisibility={toggleColorPaletteVisibility}
          colorPaletteVisible={colorPaletteVisible}
          setColorPaletteVisible={setColorPaletteVisible}
          onChannelChange={(categoryId) => {
            setActiveCategory(categoryId);
            setTrendingOffset(0);
            setPopularOffset(0);
          }}
          onClick={onLogoutClick}
        />
        <SearchBar />
      </div>
      {openSkitUploadForm && (
        <UploadVideoForm
          key={Date.now()}
          isUploadVideoFormOpen={openSkitUploadForm}
          closeModal={() => setOpenSkitUploadForm(false)}
          currentUser={currentUser}
          afterModalClose={afterUploadCloseModalRerender}
        />
      )}
      <div className="tabs">
        <GlassBtn
          glassBtnImage={
            <Filter
              height="25px"
              width="25px"
              fillColor={activeTab === "all" ? "#fff" : "#fdfd96"} // Dynamic fillColor
            />
          }
          extraStyles={{
            height: "20px",
          }}
          glassBtnTextFontSize="1.3rem"
          glassBtnText="All"
          glassBtnFontWeight="900"
          glassBtnFontColor={activeTab === "all" ? "#fff" : "#fdfd96"}
          glassBtnTextDecorationColor={activeTab === "all" ? "#fff" : "#fdfd96"}
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("all");
          }}
          className={activeTab === "all" ? "active-button" : "inactive-button"}
        />
        <GlassBtn
          glassBtnImage={
            <LineChartIcon
              height="25px"
              width="25px"
              fillColor={activeTab === "trending" ? "#fff" : "#e2b645"} // Dynamic fillColor
            />
          }
          className={
            activeTab === "trending" ? "active-button" : "inactive-button"
          }
          extraStyles={{
            height: "20px",
          }}
          glassBtnText="Trending"
          glassBtnTextFontSize="1.3rem"
          glassBtnFontWeight="900"
          glassBtnFontColor={activeTab === "trending" ? "#fff" : "#e2b645"}
          glassBtnTextDecorationColor={
            activeTab === "trending" ? "#fff" : "#e2b645"
          }
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("trending");
          }}
        />
        <GlassBtn
          glassBtnImage={
            <StarIcon
              height="25px"
              width="25px"
              fillColor={activeTab === "popular" ? "#fff" : "#fdfd96"} // Dynamic fillColor
            />
          }
          className={
            activeTab === "popular" ? "active-button" : "inactive-button"
          }
          extraStyles={{
            height: "20px",
          }}
          glassBtnText="Popular"
          glassBtnTextFontSize="1.3rem"
          glassBtnFontWeight="900"
          glassBtnFontColor={activeTab === "popular" ? "#fff" : "#fdfd96"}
          glassBtnTextDecorationColor={
            activeTab === "popular" ? "#fff" : "#fdfd96"
          }
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("popular");
          }}
        />
      </div>
      <div className="top-skits-grid">
        {activeTab === "all" && (
          <>
            {renderSkits(trendingSkitsData, "Trending")}
            {renderSkits(popularSkitsData, "Popular")}
            {(trendingSkitsData.length > 0 || popularSkitsData.length > 0) && (
              <div className="View-More-Action">
                {loadingTrending && loadingPopular && (
                  <Loader className=" activeTab-more-all-loader" />
                )}
                <ClapperBoard isClapping={isClapping} />
                <SmallPlainInsetBtnEventListener
                  image={
                    <MegaphoneIcon
                      height="20px"
                      width="20px"
                      style={{ marginRight: "3px" }}
                    />
                  }
                  text="View More Action"
                  width="188px"
                  style={{
                    fontSize: "1.25rem",
                  }}
                  onClick={(e) => handleViewMoreAll(e)} // Pass the event to the combined handler
                />
              </div>
            )}
          </>
        )}
        {activeTab === "trending" && (
          <>
            {renderSkits(trendingSkitsData, "Trending")}
            {trendingSkitsData.length > 0 && (
              <div className="View-More-Action">
                {loadingTrending && (
                  <Loader className=" activeTab-more-trending-loader" />
                )}
                <ClapperBoard isClapping={isClapping} />
                <SmallPlainInsetBtnEventListener
                  image={
                    <MegaphoneIcon
                      height="20px"
                      width="20px"
                      style={{ marginRight: "3px" }}
                    />
                  }
                  text="Trending Action"
                  width="188px"
                  style={{
                    fontSize: "1.25rem",
                  }}
                  onClick={(e) => handleViewMoreTrending(e)} // Pass the event to the handler
                />
              </div>
            )}
          </>
        )}
        {activeTab === "popular" && (
          <>
            {renderSkits(popularSkitsData, "Popular")}
            {popularSkitsData.length > 0 && (
              <div className="View-More-Action">
                {loadingPopular && (
                  <Loader className=" activeTab-more-popular-loader" />
                )}
                <ClapperBoard isClapping={isClapping} />
                <SmallPlainInsetBtnEventListener
                  image={
                    <MegaphoneIcon
                      height="20px"
                      width="20px"
                      style={{ marginRight: "3px" }}
                    />
                  }
                  text="Popular Action"
                  width="188px"
                  style={{
                    fontSize: "1.25rem",
                  }}
                  onClick={(e) => handleViewMorePopular(e)} // Pass the event to the handler
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TopSkitsListsPage;
