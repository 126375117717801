import { convertToSlug } from "../../utils/stringUtils";
// mapping of category names to SVG icons
const CatIcons = {
  [convertToSlug("Home & Moving")]: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
      className="category-menu-parent-icon remove-border"
    >
      <g>
        <g>
          <path
            d="M49.499,23.466L26.822,3.815c-0.568-0.492-1.411-0.492-1.979,0l-8.084,7.002V7.981
       c0-1.668-1.354-3.023-3.023-3.023c-1.668,0-3.023,1.354-3.023,3.023v8.075l-8.55,7.409c-0.63,0.547-0.699,1.503-0.15,2.135
       c0.548,0.629,1.501,0.695,2.135,0.151L25.828,6.958l21.68,18.793C47.8,26,48.147,26.12,48.501,26.12
       c0.426,0,0.84-0.178,1.14-0.519C50.196,24.969,50.123,24.02,49.499,23.466z"
          />
          <g>
            <polygon
              points="27.557,41.079 26.939,40.803 26.657,40.19 26.026,40.43 25.393,40.196 25.116,40.814
         24.502,41.096 24.744,41.728 24.511,42.361 25.127,42.637 25.409,43.25 26.039,43.009 26.675,43.244 26.95,42.626 27.563,42.344
         27.323,41.713"
            />
            <path
              d="M42.86,27.112L27.742,14.717c-0.552-0.457-1.233-0.689-1.914-0.689c-0.68,0-1.359,0.229-1.918,0.689
         L8.796,27.108c-0.701,0.576-1.107,1.43-1.107,2.341v14.813c0,1.667,1.355,3.021,3.023,3.021h3.248h5.823h12.092h3.222h5.847
         c1.667,0,3.023-1.354,3.023-3.021V29.449C43.967,28.536,43.56,27.684,42.86,27.112z M26.047,43.927
         c-1.24,0.008-2.25-0.975-2.255-2.195c-0.006-0.749,0.373-1.413,0.948-1.815l-0.045-8.098c-1.133-0.49-1.933-1.611-1.94-2.925
         c-0.007-1.236,0.688-2.312,1.711-2.853l0.02,3.172l1.481,0.844l1.474-0.862l-0.019-3.171c1.029,0.529,1.735,1.598,1.743,2.835
         c0.007,1.312-0.778,2.443-1.907,2.944l0.046,8.102c0.582,0.396,0.967,1.053,0.973,1.802
         C28.283,42.926,27.285,43.92,26.047,43.927z M31.094,29.786c-0.049,0.281-0.122,0.552-0.213,0.813l0.643,0.728l-0.619,1.086
         l-0.951-0.183c-0.091,0.106-0.186,0.213-0.286,0.311c-0.098,0.104-0.205,0.198-0.313,0.292l0.005-0.002l0.193,0.95l-1.08,0.632
         l-0.648-0.564l-0.011-1.578c1.207-0.661,2.025-1.943,2.018-3.416c-0.013-2.136-1.756-3.859-3.892-3.848
         c-2.137,0.013-3.861,1.755-3.849,3.892c0.009,1.363,0.723,2.556,1.792,3.239l0.013,1.962l-0.385,0.342l-1.085-0.621l0.184-0.95
         c-0.107-0.092-0.213-0.188-0.312-0.289c-0.102-0.099-0.198-0.202-0.293-0.308l-0.949,0.195l-0.63-1.08l0.634-0.732
         c-0.093-0.263-0.171-0.532-0.224-0.813l-0.918-0.308l-0.008-1.251l0.916-0.316c0.048-0.283,0.124-0.553,0.212-0.815l-0.64-0.728
         l0.617-1.084l0.951,0.182l-0.002,0.005c0.093-0.11,0.188-0.216,0.288-0.317c0.098-0.101,0.204-0.196,0.31-0.289l-0.195-0.949
         l1.08-0.631l0.732,0.635c0.263-0.097,0.533-0.171,0.814-0.225l0.306-0.918l1.251-0.008l0.319,0.916
         c0.28,0.05,0.552,0.123,0.813,0.215l0.729-0.645l1.085,0.622l-0.182,0.951l-0.006-0.002c0.107,0.092,0.215,0.187,0.317,0.289
         c0.1,0.098,0.198,0.203,0.289,0.312v-0.004l0.947-0.193l0.632,1.078l-0.634,0.732c0.095,0.263,0.17,0.533,0.225,0.812
         l0.916,0.309l0.009,1.251L31.094,29.786z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  [convertToSlug("Beauty & Grooming")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M25.407,1.143C19.555,1.118,14.688,7.4,14.656,15.164c-0.025,5.627,2.43,10.477,6.022,12.744
          c1.515,0.962,2.353,2.599,2.344,4.286c-0.014,3.377,0.091,5.403-1.628,12.485c-0.63,2.417,0.991,5.069,3.466,5.079
          c2.251,0.01,4.118-2.401,3.456-5.105c-1.604-6.59-1.537-9.348-1.524-12.048c0.008-1.913,1.028-3.598,2.663-4.435
          c3.835-2.121,6.501-7.118,6.526-12.969C36.071,7.491,31.316,1.112,25.407,1.143z M24.871,47.283
          c-0.787-0.004-1.458-0.907-1.396-1.976c0.004-1.125,0.627-1.967,1.414-1.964c0.788,0.003,1.46,0.907,1.398,1.976
          C26.282,46.445,25.66,47.286,24.871,47.283z M25.299,26.352c-4.051-0.017-7.52-5.096-7.493-11.173s3.537-11.126,7.588-11.109
          c4.052,0.018,7.519,5.098,7.493,11.174C32.861,21.319,29.351,26.369,25.299,26.352z"
        />
        <g>
          <rect x="22.938" y="11.015" width="7.241" height="0.477" />
          <rect
            x="20.441"
            y="12.146"
            transform="matrix(1 0.0042 -0.0042 1 0.0516 -0.0955)"
            width="5.173"
            height="0.477"
          />
        </g>
        <g>
          <rect x="22.938" y="11.015" width="7.241" height="0.477" />
          <rect
            x="20.441"
            y="12.146"
            transform="matrix(1 0.0042 -0.0042 1 0.0516 -0.0955)"
            width="5.173"
            height="0.477"
          />
        </g>
        <g>
          <rect x="20.736" y="18.691" width="7.242" height="0.478" />
          <rect
            x="25.344"
            y="17.612"
            transform="matrix(-1 -0.0043 0.0043 -1 55.7836 35.8223)"
            width="5.173"
            height="0.478"
          />
        </g>
        <g>
          <rect x="20.736" y="18.691" width="7.242" height="0.478" />
          <rect
            x="25.344"
            y="17.612"
            transform="matrix(-1 -0.0043 0.0043 -1 55.7836 35.8223)"
            width="5.173"
            height="0.478"
          />
        </g>
      </g>
    </svg>
  ),
  [convertToSlug("Crafts & Toys/Games")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M44.738,15.46H41.57v-1.979c0-1.188-0.791-1.979-1.977-1.979h-3.962V5.563h-1.977v5.939H9.899
          c-1.187,0-1.979,0.792-1.979,1.979v9.897c0,1.188,0.793,1.979,1.979,1.979v1.187c0,0.396,0.397,0.792,0.792,0.792h1.188v3.959
          h1.979v-3.959h1.188c0.397,0,0.794-0.395,0.794-0.792v-1.187c1.187,0,1.978-0.79,1.978-1.979v-1.981
          c0-1.188,0.792-1.979,1.979-1.979h5.938c1.188,0,1.98,0.791,1.98,1.979v15.836c0,1.188-0.792,1.98-1.98,1.98H7.92
          c-1.187,0-1.979,0.791-1.979,1.979v1.978c0,1.187,0.793,1.979,1.979,1.979h31.672c1.188,0,1.979-0.792,1.979-1.979V25.358h3.168
          c0.397,0,0.791-0.396,0.791-0.79v-8.316C45.532,15.856,45.136,15.46,44.738,15.46z M31.675,21.398c1.189,0,1.98,0.792,1.98,1.981
          s-0.791,1.979-1.98,1.979c-1.188,0-1.979-0.79-1.979-1.979S30.486,21.398,31.675,21.398z M31.675,27.336
          c1.189,0,1.98,0.793,1.98,1.979c0,1.188-0.791,1.98-1.98,1.98c-1.188,0-1.979-0.792-1.979-1.98
          C29.695,28.13,30.486,27.336,31.675,27.336z M39.594,38.423c0,0.395-0.397,0.792-0.791,0.792h-8.316
          c-0.396,0-0.791-0.396-0.791-0.792v-4.357c0-0.395,0.396-0.791,0.791-0.791h8.316c0.394,0,0.791,0.396,0.791,0.791V38.423
          L39.594,38.423z M37.611,31.296c-1.186,0-1.979-0.792-1.979-1.98c0-1.186,0.794-1.978,1.979-1.978c1.188,0,1.98,0.792,1.98,1.978
          C39.594,30.504,38.8,31.296,37.611,31.296z M37.611,25.358c-1.186,0-1.979-0.79-1.979-1.979s0.794-1.981,1.979-1.981
          c1.188,0,1.98,0.792,1.98,1.981S38.8,25.358,37.611,25.358z"
        />
      </g>
    </svg>
  ),
  [convertToSlug("Art & Collectibles")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M38.458,30.963c-0.188,0.657-0.281,1.407-0.563,2.158c2.064,0,4.036-1.501,4.13-3.848
          c0-3.284-0.657-6.192-1.877-8.634C39.866,23.736,39.303,27.303,38.458,30.963z"
        />
        <path
          d="M28.979,28.804c0.376-1.406,0.657-2.816,1.034-4.129c-0.095-0.375-0.188-0.844-0.188-1.22
          c0-1.315,0.657-2.535,1.595-3.285c0.281-0.751,0.563-1.502,0.845-2.158l0.281-0.658c0-0.093,0-0.093,0-0.188l0.47-2.159
          l-0.376-0.093l-0.188-0.469c-0.187-0.563-0.374-1.221-0.469-1.783c-2.628-1.313-5.537-1.877-8.539-1.784
          c-8.823,0.375-16.144,7.508-16.8,16.33C6.08,34.342,9.833,40.724,15.56,43.914c2.252,1.314,5.066-0.375,5.161-3.002
          c0-4.411,3.284-8.542,7.695-9.573C28.604,30.493,28.792,29.648,28.979,28.804z M14.809,20.357c0.751,0,1.406,0.188,1.97,0.564
          c0,0.093,1.596,0.844,1.596,3.002c0,1.971-1.596,3.565-3.565,3.565c-1.222,0-2.348-0.657-2.91-1.594l0,0
          c-0.376-0.563-0.563-1.221-0.563-1.971C11.242,21.952,12.837,20.357,14.809,20.357z M15.184,37.72c-1.22,0-2.346-0.657-2.91-1.596
          l0,0c-0.374-0.563-0.563-1.221-0.563-1.971c0-1.97,1.595-3.564,3.566-3.564c0.751,0,1.407,0.187,1.971,0.563
          c0,0.093,1.596,0.845,1.596,3.003C18.655,36.124,17.061,37.72,15.184,37.72z M23.348,20.921c-2.158,0-2.909-1.596-3.003-1.596
          c-0.375-0.563-0.563-1.221-0.563-1.971c0-2.816,3.098-4.505,5.537-3.003l0,0C28.229,16.322,26.819,20.921,23.348,20.921z"
        />
        <path
          d="M39.208,19.7l-5.724-1.313h-0.094c-0.094,0-0.094,0-0.188-0.095c-1.22,2.909-2.346,6.664-3.284,10.7
          c-1.783,7.507-2.252,14.265-1.408,17.269c0.188,0.938,0.658,1.594,1.126,1.688c2.16,0.469,5.726-7.226,7.978-17.173
          C38.458,26.645,39.115,22.89,39.208,19.7L39.208,19.7z"
        />
        <path
          d="M40.147,2.996c-0.095,0.093-0.188,0.187-0.281,0.281c-2.44,3.847-6.006,4.223-6.569,6.852
          c-0.282,1.313-0.282,2.721,0.188,3.94l4.035,0.939l3.472,0.751c0.939-0.846,1.503-2.159,1.784-3.473
          C43.339,9.846,41.555,2.151,40.147,2.996z"
        />
        <path
          d="M33.671,17.449l5.632,1.313c0.093,0,0.093,0,0.093-0.094l0.563-2.252l-2.721-0.657l-3.285-0.657
          l-0.469,2.252c0,0.094,0,0.094,0.094,0.094H33.671L33.671,17.449z"
        />
      </g>
    </svg>
  ),
  [convertToSlug("Protective Services")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M46.161,24.946c0,0-8.264-6.761-15.024,2.253c0,0-6.01-5.258-12.771,0l-0.751-12.77
          c0,0-0.332-0.457-0.847-1.122l0.411,0.042c0,0,2.661,0,3.104-0.995l1.477,1.362c0.584,0.491,1.452,0.429,1.961-0.141l3.652-4.094
          c0.442-0.496,0.479-1.233,0.087-1.771l-4.072-5.3L11.413,7.879c0,0-0.444,0.196-0.559,0.54c-0.751,2.253-6.761,24.038-6.761,25.541
          v1.744c0,0.324,0.146,0.628,0.401,0.829c0.325,0.255,1.946,0.703,2.604,1.184c2.836,2.073,7.247,5.7,12.019,6.761
          c6.761,1.502,15.024-0.751,15.024-0.751s5.258,3.005,12.019,2.253L46.161,24.946"
        />
        <path
          className="no-blue"
          fill="#FFFFFF"
          d="M13.107,34.711c-0.131,0-0.265,0.035-0.386,0.107c-0.355,0.213-0.471,0.674-0.258,1.03
          c0.033,0.054,0.934,1.546,2.863,3.047c1.924,1.499,4.9,2.998,8.983,2.998h0.01c1.226,0,2.549-0.134,3.97-0.438
          c0.405-0.087,0.664-0.486,0.577-0.892c-0.076-0.353-0.388-0.594-0.734-0.594c-0.052,0-0.104,0.005-0.158,0.017
          c-1.322,0.284-2.538,0.405-3.654,0.405c-3.715-0.002-6.342-1.342-8.071-2.682c-0.862-0.67-1.495-1.341-1.907-1.839
          c-0.206-0.248-0.357-0.454-0.455-0.594c-0.049-0.07-0.084-0.124-0.106-0.158l-0.023-0.037l-0.005-0.007
          C13.611,34.84,13.362,34.711,13.107,34.711"
        />
        <path
          className="no-blue"
          fill="#FFFFFF"
          d="M26.602,9.506l1.213,2.458c0.111,0.227,0.328,0.384,0.579,0.421l2.713,0.394
          c0.42,0.061,0.711,0.452,0.65,0.872c-0.024,0.167-0.104,0.322-0.225,0.44l-1.963,1.914c-0.181,0.177-0.264,0.431-0.221,0.681
          l0.463,2.702c0.072,0.418-0.209,0.816-0.628,0.888c-0.167,0.029-0.338,0.001-0.488-0.077l-2.426-1.275
          c-0.225-0.118-0.492-0.118-0.716,0l-2.427,1.275c-0.376,0.198-0.841,0.053-1.038-0.323c-0.079-0.149-0.106-0.321-0.078-0.488
          l0.464-2.702c0.043-0.25-0.04-0.504-0.222-0.681l-1.963-1.914c-0.304-0.296-0.31-0.784-0.013-1.087
          c0.118-0.121,0.272-0.2,0.439-0.224l2.713-0.394c0.25-0.037,0.467-0.194,0.579-0.421l1.213-2.458
          c0.188-0.381,0.649-0.538,1.03-0.35C26.403,9.231,26.526,9.354,26.602,9.506L26.602,9.506z"
        />
        <path
          d="M25.792,17.541l-2.131,1.12c-0.125,0.066-0.28,0.018-0.346-0.108c-0.026-0.05-0.035-0.107-0.026-0.162
          l0.407-2.373c0.015-0.083-0.013-0.168-0.073-0.227l-1.725-1.681c-0.102-0.099-0.104-0.261-0.005-0.362
          c0.039-0.041,0.091-0.067,0.146-0.075l2.383-0.346c0.083-0.012,0.155-0.065,0.192-0.141l1.066-2.159
          c0.063-0.127,0.216-0.179,0.343-0.116c0.051,0.025,0.092,0.066,0.116,0.116l1.065,2.159c0.038,0.076,0.11,0.128,0.193,0.141
          l2.382,0.346c0.141,0.021,0.237,0.151,0.217,0.291c-0.008,0.056-0.034,0.107-0.074,0.146l-1.724,1.68
          c-0.061,0.059-0.088,0.144-0.074,0.227l0.407,2.373c0.023,0.14-0.07,0.272-0.21,0.296c-0.056,0.01-0.112,0-0.162-0.025l-2.131-1.12
          C25.956,17.501,25.866,17.501,25.792,17.541L25.792,17.541z"
        />
      </g>
    </svg>
  ),
  [convertToSlug("Events & Parties")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M43.867,9.235h-4.323V6.09c0-0.678-0.551-1.229-1.229-1.229h-5.11c-0.68,0-1.229,0.551-1.229,1.229v3.145
          H19.494V6.09c0-0.678-0.551-1.229-1.229-1.229h-5.11c-0.678,0-1.227,0.551-1.227,1.229v3.145H7.604
          c-0.68,0-1.229,0.549-1.229,1.229V44.81c0,0.679,0.551,1.229,1.229,1.229h36.264c0.678,0,1.228-0.549,1.228-1.229V10.464
          C45.095,9.784,44.545,9.235,43.867,9.235z M34.433,7.319h2.702v4.962h-2.702V7.319z M14.385,7.319h2.701v4.962h-2.701V7.319
          L14.385,7.319z M24.556,36.703c-0.477,0.468-1.242,0.468-1.72,0l-5.209-5.06l1.721-1.77l4.374,4.226l8.451-7.912l1.67,1.77
          L24.556,36.703z M42.639,19.258H8.832v-7.567h3.097v1.819c0,0.678,0.549,1.229,1.227,1.229h5.11c0.68,0,1.229-0.551,1.229-1.229
          v-1.819h12.48v1.819c0,0.678,0.551,1.229,1.229,1.229h5.111c0.677,0,1.227-0.551,1.227-1.229v-1.819h3.097V19.258z"
        />
      </g>
    </svg>
  ),
  [convertToSlug("Pets & Animals")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M22.087,39.587c-0.833-0.603-1.319-1.215-1.488-1.87c-0.631-2.447-2.665-4.232-4.949-4.232
          c-2.282,0-4.394,1.786-5.023,4.233c-0.171,0.653-0.656,1.266-1.489,1.871c-1.015,0.737-1.478,1.875-1.21,2.976
          c0.321,1.322,1.784,2.498,3.784,2.498c0.847,0,1.65-0.218,2.322-0.629c0.447-0.274,1.056-0.42,1.615-0.42
          c0.558,0,1.089,0.145,1.541,0.419c0.675,0.414,1.474,0.633,2.313,0.633c2,0,3.474-1.169,3.796-2.5
          C23.563,41.466,23.099,40.324,22.087,39.587z"
        />
        <path
          d="M9.851,33.486c0-1.33-1.047-3.447-2.287-3.447c-1.242,0-2.289,2.117-2.289,3.447
          c0,1.267,1.027,2.297,2.289,2.297C8.824,35.783,9.851,34.752,9.851,33.486z"
        />
        <path
          d="M23.661,30.039c-1.242,0-2.289,2.118-2.289,3.447c0,1.268,1.027,2.297,2.289,2.297
          c1.261,0,2.288-1.031,2.288-2.297C25.947,32.156,24.902,30.039,23.661,30.039z"
        />
        <path
          d="M16.794,28.89c0,1.268,1.027,2.299,2.289,2.299c1.261,0,2.288-1.032,2.288-2.299
          c0-1.33-1.047-3.446-2.288-3.446C17.841,25.442,16.794,27.56,16.794,28.89z"
        />
        <path
          d="M12.14,31.188c1.262,0,2.288-1.032,2.288-2.299c0-1.33-1.047-3.446-2.288-3.446
          c-1.24,0-2.289,2.116-2.289,3.446C9.851,30.156,10.878,31.188,12.14,31.188z"
        />
        <path
          d="M40.472,19.979c-0.832-0.604-1.317-1.292-1.489-1.948c-0.629-2.445-2.74-4.155-5.022-4.155
          c-2.285,0-4.318,1.709-4.95,4.157c-0.169,0.654-0.656,1.342-1.487,1.947c-1.015,0.737-1.478,1.876-1.21,2.977
          c0.321,1.323,1.783,2.499,3.784,2.499c0.847,0,1.649-0.216,2.322-0.629c0.447-0.275,0.979-0.421,1.537-0.421
          s1.166,0.146,1.619,0.42c0.675,0.414,1.475,0.633,2.312,0.633c2.001,0,3.473-1.17,3.797-2.501
          C41.949,21.856,41.484,20.716,40.472,19.979z"
        />
        <path
          d="M28.237,13.875c0-1.33-1.048-3.447-2.29-3.447s-2.287,2.118-2.287,3.447c0,1.267,1.025,2.299,2.287,2.299
          C27.211,16.174,28.237,15.144,28.237,13.875z"
        />
        <path
          d="M42.045,10.429c-1.242,0-2.289,2.118-2.289,3.447c0,1.267,1.028,2.299,2.289,2.299
          c1.262,0,2.289-1.032,2.289-2.299C44.334,12.547,43.287,10.429,42.045,10.429z"
        />
        <path
          d="M35.18,9.279c0,1.268,1.027,2.299,2.288,2.299c1.262,0,2.29-1.031,2.29-2.299
          c0-1.329-1.047-3.446-2.29-3.446C36.226,5.833,35.18,7.951,35.18,9.279z"
        />
        <path
          d="M28.237,9.279c0,1.268,1.027,2.299,2.288,2.299c1.262,0,2.289-1.031,2.289-2.299
          c0-1.329-1.048-3.446-2.289-3.446C29.283,5.833,28.237,7.951,28.237,9.279z"
        />
      </g>
    </svg>
  ),
  [convertToSlug("Book-ebook Publishing")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M42.163,37.617V9.052l-1.09,0.091c-4.371,0.361-8.658,1.406-12.706,3.097l-1.918,0.796v28.452l1.396-0.581
          c4.188-1.743,8.623-2.821,13.144-3.196L42.163,37.617z"
        />
        <path
          d="M45.734,13.275h-2.143v25c0.001,0.373-0.284,0.684-0.656,0.714l-1.829,0.15
          c-0.527,0.044-1.055,0.098-1.579,0.162c-0.17,0.021-0.339,0.048-0.508,0.072c-0.354,0.047-0.707,0.095-1.059,0.15
          c-0.205,0.033-0.409,0.071-0.613,0.107c-0.315,0.055-0.631,0.11-0.945,0.172c-0.214,0.043-0.429,0.091-0.647,0.137
          c-0.299,0.063-0.599,0.128-0.896,0.199c-0.223,0.053-0.445,0.109-0.666,0.166c-0.289,0.072-0.577,0.149-0.864,0.229
          c-0.224,0.062-0.447,0.126-0.67,0.192c-0.286,0.084-0.567,0.17-0.85,0.26c-0.221,0.071-0.442,0.143-0.662,0.214
          c-0.282,0.095-0.563,0.194-0.844,0.295c-0.214,0.078-0.429,0.156-0.647,0.238c-0.098,0.037-0.195,0.077-0.293,0.115h16.371V13.275z
          "
        />
        <path
          d="M10.396,9.144l-1.09-0.091v28.567l1.269,0.108c4.518,0.378,8.947,1.462,13.129,3.214l1.317,0.548V13.037
          l-1.917-0.796C19.057,10.55,14.769,9.505,10.396,9.144z"
        />
        <path
          d="M5.734,13.275v28.571h16.383c-0.088-0.034-0.176-0.071-0.264-0.104c-0.205-0.078-0.411-0.152-0.616-0.227
          c-0.291-0.105-0.58-0.208-0.873-0.306c-0.211-0.071-0.423-0.141-0.636-0.208c-0.289-0.095-0.58-0.185-0.872-0.269
          c-0.214-0.063-0.429-0.125-0.643-0.186c-0.294-0.082-0.588-0.159-0.883-0.234c-0.214-0.055-0.429-0.11-0.647-0.161
          c-0.3-0.072-0.601-0.137-0.901-0.202c-0.214-0.046-0.429-0.094-0.643-0.137c-0.312-0.061-0.624-0.116-0.938-0.171
          c-0.205-0.036-0.41-0.072-0.615-0.108c-0.346-0.055-0.691-0.101-1.038-0.147c-0.175-0.023-0.349-0.052-0.523-0.071
          c-0.523-0.063-1.048-0.118-1.571-0.162l-1.923-0.163c-0.371-0.032-0.655-0.342-0.654-0.714v-25H5.734z"
        />
      </g>
    </svg>
  ),
  // [convertToSlug("Wellness Services")]: (
  //   <svg
  //     className=" category-menu-parent-icon remove-border"
  //     version="1.1"
  //     xmlns="http://www.w3.org/2000/svg"
  //     xmlnsXlink="http://www.w3.org/1999/xlink"
  //     x="0px"
  //     y="0px"
  //     width="40px"
  //     height="40px"
  //     viewBox="0 0 51.469 50.9"
  //     enableBackground="new 0 0 51.469 50.9"
  //     xmlSpace="preserve"
  //   >
  //     <g>
  //       <path d="M26.558,4.563c-3.164,3.435-2.962,5.902,0,9.192C29.722,10.318,29.519,7.851,26.558,4.563z" />
  //       <path
  //         d="M23.494,29.878v4.446l-3.453,6.413c-0.377,0.699-0.115,1.571,0.584,1.948
  // 	c0.21,0.113,0.444,0.172,0.683,0.172h5.251v4.514c-0.018,0.771,0.52,1.442,1.274,1.593c0.834,0.141,1.626-0.42,1.767-1.254
  // 	c0.016-0.086,0.022-0.171,0.022-0.257V29.878c3.409-1.691,4.803-5.822,3.113-9.231c-0.652-1.318-1.71-2.391-3.016-3.064
  // 	l-0.016-0.007c-0.904-0.438-1.985-0.279-2.725,0.4l-0.421,0.374c2.54,0,4.598,2.057,4.599,4.595
  // 	c0.002,2.16-1.501,4.029-3.609,4.492c-0.301,0.062-0.608,0.094-0.916,0.096c1.233-0.073,2.176-1.133,2.102-2.368
  // 	c-0.073-1.234-1.135-2.175-2.368-2.102c-1.234,0.074-2.175,1.134-2.102,2.368c0.07,1.191,1.065,2.117,2.259,2.106
  // 	c-2.537-0.018-4.579-2.089-4.561-4.627c0.018-2.524,2.07-4.561,4.596-4.561l-0.411-0.362c-0.745-0.685-1.835-0.838-2.741-0.388
  // 	c-2.286,1.196-3.725,3.556-3.741,6.136C19.676,26.343,21.156,28.722,23.494,29.878z M26.558,35.964v3.83h-3.064l2.128-3.83H26.558z
  // 	"
  //       />
  //       <rect x="34.982" y="12.221" width="3.063" height="1.534" />

  //       <rect
  //         x="34.984"
  //         y="4.563"
  //         transform="matrix(0.7069 -0.7073 0.7073 0.7069 6.9318 27.3876)"
  //         width="3.063"
  //         height="1.532"
  //       />
  //       <rect x="15.069" y="12.221" width="3.063" height="1.534" />

  //       <rect
  //         x="15.835"
  //         y="3.796"
  //         transform="matrix(0.7073 -0.7069 0.7069 0.7073 1.0928 13.2959)"
  //         width="1.531"
  //         height="3.064"
  //       />
  //       <path
  //         d="M27.655,13.663c3.552-0.429,4.831-2.24,5.029-6.037c-1.079,0.012-2.146,0.213-3.154,0.595
  // 	C29.887,10.006,29.266,11.735,27.655,13.663z"
  //       />
  //       <path
  //         d="M25.464,13.654c-1.592-1.942-2.219-3.667-1.892-5.423c-1.005-0.376-2.067-0.581-3.142-0.604
  // 	C20.595,11.617,22.082,13.226,25.464,13.654z"
  //       />
  //     </g>
  //   </svg>
  // ),
  [convertToSlug("Wellness Services")]: (
    <svg
      className="category-menu-parent-icon remove-border"
      xmlns="http://www.w3.org/2000/svg"
      id="icon-wellness"
      width="31.172"
      height="30.071"
      viewBox="0 0 7.172 6.071"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
      style={{ padding: "3px" }}
    >
      <g id="_x31_" transform="translate(-0.883 -5.808)">
        <g
          id="Group_8902"
          data-name="Group 8902"
          transform="translate(2.597 5.808)"
        >
          <g id="Group_8901" data-name="Group 8901">
            <path
              id="Path_14464"
              data-name="Path 14464"
              d="M20.757,6.076a.993.993,0,0,0-.687-.268,1.1,1.1,0,0,0-.772.321l-.09.09-.09-.09a1.1,1.1,0,0,0-.771-.32.993.993,0,0,0-.687.268,1.025,1.025,0,0,0-.023,1.472l1.571,1.571,1.571-1.571A1.025,1.025,0,0,0,20.757,6.076Z"
              transform="translate(-17.337 -5.808)"
              // fill="currentColor"
            />
          </g>
        </g>
        <g
          id="Group_8904"
          data-name="Group 8904"
          transform="translate(0.883 6.085)"
        >
          <g id="Group_8903" data-name="Group 8903" transform="translate(0 0)">
            <path
              id="Path_14465"
              data-name="Path 14465"
              d="M3.918,14.259V12.7a1.311,1.311,0,0,0-.9-1.358,1.012,1.012,0,0,1-.389-.275c-.215-.225-.446-.531-.744-.528h0a.393.393,0,0,0-.279.121.478.478,0,0,0,.053.671l.555.555a.276.276,0,0,1,0,.39l-.75-.75a.863.863,0,0,1-.243-.483.677.677,0,0,1,.6-.773L1.71,9.819C1.7,8.141.883,8.346.883,8.741v2.946a.687.687,0,0,0,.2.486c1.371,1.383,1.18.938,1.18,2.087Z"
              transform="translate(-0.883 -8.466)"
              // fill="currentColor"
            />
          </g>
        </g>
        <g
          id="Group_8906"
          data-name="Group 8906"
          transform="translate(5.019 6.085)"
        >
          <g id="Group_8905" data-name="Group 8905">
            <path
              id="Path_14466"
              data-name="Path 14466"
              d="M43.617,8.742c0-.395-.819-.6-.827,1.078l-.112.452a.677.677,0,0,1,.6.773.863.863,0,0,1-.243.483l-.75.75a.276.276,0,0,1,0-.39l.555-.555a.478.478,0,0,0,.053-.671.393.393,0,0,0-.279-.121h0c-.3,0-.529.3-.744.528a1.012,1.012,0,0,1-.389.275,1.311,1.311,0,0,0-.9,1.358V14.26h1.656c0-1.149-.191-.7,1.18-2.087a.687.687,0,0,0,.2-.486Z"
              transform="translate(-40.582 -8.467)"
              // fill="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  [convertToSlug("Photography & Videography")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path d="M4.388,23.451h6.17v6.992h-6.17V23.451z" />
        <path d="M4.388,39.902h6.17v6.992h-6.17V39.902z" />
        <path
          d="M4.388,31.677h6.17v6.992h-6.17V31.677z"
          style={{ opacity: "0.5" }}
        />
        <path d="M38.112,7h6.169v6.992h-6.169V7z" />
        <path d="M11.791,26.33h18.577c1.229-2.829,3.593-5.009,6.511-6.005V9.879H11.791V26.33z" />
        <path
          d="M38.112,19.986c2.061-0.437,4.203-0.267,6.169,0.489v-5.249h-6.169V19.986z"
          style={{ opacity: "0.5" }}
        />
        <path
          d="M4.388,15.226h6.17v6.991h-6.17V15.226z"
          style={{ opacity: "0.5" }}
        />
        <path d="M4.388,7h6.17v6.992h-6.17V7z" />
        <path d="M38.112,41.311v5.583h6.169v-6.072C42.315,41.578,40.173,41.747,38.112,41.311z" />
        <path
          d="M40.374,20.983c-5.337,0-9.665,4.328-9.665,9.665c0,5.338,4.328,9.665,9.665,9.665
          c5.339,0,9.666-4.327,9.666-9.665C50.033,25.313,45.71,20.989,40.374,20.983z"
        />
        <path
          d="M29.476,30.648c-0.001-1.044,0.149-2.083,0.445-3.084h-18.13v16.451h25.088v-3.042
          C32.457,39.468,29.481,35.318,29.476,30.648z"
        />
        <g>
          <path
            className="no-blue"
            fill="#FFFFFF"
            d="M33.952,28.436h-0.303c-0.415,0-0.728,0.354-0.728,0.769v4.251c-0.005,0.196,0.07,0.384,0.207,0.523
              c0.138,0.139,0.325,0.216,0.521,0.213h8.035c0.199,0.004,0.391-0.072,0.533-0.21c0.143-0.139,0.224-0.328,0.226-0.526v-4.49
              c-1.206,0.609-2.663,0.397-3.645-0.531h-1.896c-0.366,0.435-0.907,0.686-1.476,0.686C34.858,29.121,34.319,28.87,33.952,28.436z
               M36.087,30.857h2.851c0.179,0,0.323,0.144,0.323,0.323c0,0.178-0.145,0.322-0.323,0.322h-2.851c-0.18,0-0.322-0.144-0.322-0.322
              C35.765,31.001,35.907,30.857,36.087,30.857z"
          />
          <path
            className="no-blue"
            fill="#FFFFFF"
            d="M36.719,27.2c0,0.713-0.577,1.291-1.291,1.291c-0.713,0-1.291-0.578-1.291-1.291
              c0-0.713,0.578-1.291,1.291-1.291C36.142,25.909,36.719,26.486,36.719,27.2z"
          />
          <path
            className="no-blue"
            fill="#FFFFFF"
            d="M40.982,25.476c-0.357,0-0.648,0.291-0.648,0.647c0,0.358,0.291,0.649,0.648,0.649
              s0.648-0.291,0.648-0.648C41.631,25.766,41.34,25.476,40.982,25.476z"
          />
          <path
            className="no-blue"
            fill="#FFFFFF"
            d="M47.877,34.817v-6.975l-1.096,0.313c-0.091,0.028-0.147,0.114-0.143,0.207v5.935
              c-0.005,0.093,0.052,0.179,0.143,0.208L47.877,34.817z"
          />
          <path
            className="no-blue"
            fill="#FFFFFF"
            d="M40.982,23.574c-1.407,0-2.549,1.141-2.549,2.549s1.142,2.549,2.549,2.549
              c1.409,0,2.549-1.141,2.549-2.549C43.531,24.716,42.391,23.576,40.982,23.574z M40.982,27.417c-0.714,0-1.293-0.579-1.293-1.294
              s0.578-1.294,1.293-1.294c0.716,0,1.295,0.579,1.295,1.294C42.276,26.837,41.697,27.417,40.982,27.417z"
          />
          <path
            className="no-blue"
            fill="#FFFFFF"
            d="M45.994,33.716v-4.771l-2.905,1.618v1.536L45.994,33.716z"
          />
        </g>
        <path
          className="no-blue"
          fill="#FFFFFF"
          d="M28.518,19.259l-6.128,4.072c-0.103,0.067-0.222,0.103-0.345,0.103c-0.104,0-0.204-0.025-0.297-0.074
          c-0.202-0.106-0.331-0.314-0.336-0.544v-8.143c0.004-0.229,0.133-0.438,0.336-0.544c0.204-0.106,0.45-0.095,0.644,0.03l6.128,4.072
          c0.172,0.114,0.275,0.307,0.274,0.513C28.794,18.952,28.69,19.145,28.518,19.259L28.518,19.259z"
        />
      </g>
    </svg>
  ),
  // [convertToSlug("Photography & Videography home")]: (
  //   <svg
  //     className=" category-menu-parent-icon remove-border"
  //     version="1.1"
  //     xmlns="http://www.w3.org/2000/svg"
  //     xmlnsXlink="http://www.w3.org/1999/xlink"
  //     x="0px"
  //     y="0px"
  //     width="40px"
  //     height="40px"
  //     viewBox="0 0 51.469 50.9"
  //     enableBackground="new 0 0 51.469 50.9"
  //     xmlSpace="preserve"
  //   >
  //     <g>
  //       <path d="M4.388,23.451h6.17v6.992h-6.17V23.451z" />
  //       <path d="M4.388,39.902h6.17v6.992h-6.17V39.902z" />
  //       <path
  //         d="M4.388,31.677h6.17v6.992h-6.17V31.677z"
  //         style={{ opacity: "0.5" }}
  //       />
  //       <path d="M38.112,7h6.169v6.992h-6.169V7z" />
  //       <path d="M11.791,26.33h18.577c1.229-2.829,3.593-5.009,6.511-6.005V9.879H11.791V26.33z" />
  //       <path
  //         d="M38.112,19.986c2.061-0.437,4.203-0.267,6.169,0.489v-5.249h-6.169V19.986z"
  //         style={{ opacity: "0.5" }}
  //       />
  //       <path
  //         d="M4.388,15.226h6.17v6.991h-6.17V15.226z"
  //         style={{ opacity: "0.5" }}
  //       />
  //       <path d="M4.388,7h6.17v6.992h-6.17V7z" />
  //       <path d="M38.112,41.311v5.583h6.169v-6.072C42.315,41.578,40.173,41.747,38.112,41.311z" />
  //       <path
  //         d="M40.374,20.983c-5.337,0-9.665,4.328-9.665,9.665c0,5.338,4.328,9.665,9.665,9.665
  //         c5.339,0,9.666-4.327,9.666-9.665C50.033,25.313,45.71,20.989,40.374,20.983z"
  //         style={{ opacity: "0.5" }}
  //       />
  //       <path
  //         d="M29.476,30.648c-0.001-1.044,0.149-2.083,0.445-3.084h-18.13v16.451h25.088v-3.042
  //         C32.457,39.468,29.481,35.318,29.476,30.648z"
  //       />
  //       <g>
  //         <path
  //           className="no-blue"
  //           fill="#FFF"
  //           d="M33.952,28.436h-0.303c-0.415,0-0.728,0.354-0.728,0.769v4.251c-0.005,0.196,0.07,0.384,0.207,0.523
  //                     c0.138,0.139,0.325,0.216,0.521,0.213h8.035c0.199,0.004,0.391-0.072,0.533-0.21c0.143-0.139,0.224-0.328,0.226-0.526v-4.49
  //                     c-1.206,0.609-2.663,0.397-3.645-0.531h-1.896c-0.366,0.435-0.907,0.686-1.476,0.686C34.858,29.121,34.319,28.87,33.952,28.436z
  //                     M36.087,30.857h2.851c0.179,0,0.323,0.144,0.323,0.323c0,0.178-0.145,0.322-0.323,0.322h-2.851c-0.18,0-0.322-0.144-0.322-0.322
  //                     C35.765,31.001,35.907,30.857,36.087,30.857z"
  //         />
  //         <path
  //           className="no-blue"
  //           fill="#FFF"
  //           d="M36.719,27.2c0,0.713-0.577,1.291-1.291,1.291c-0.713,0-1.291-0.578-1.291-1.291
  //             c0-0.713,0.578-1.291,1.291-1.291C36.142,25.909,36.719,26.486,36.719,27.2z"
  //         />
  //         <path
  //           d="M40.982,25.476c-0.357,0-0.648,0.291-0.648,0.647c0,0.358,0.291,0.649,0.648,0.649
  //                   s0.648-0.291,0.648-0.648C41.631,25.766,41.34,25.476,40.982,25.476z"
  //           style={{ opacity: "0.5" }}
  //         />
  //         <path
  //           className="no-blue"
  //           fill="#FFF"
  //           d="M47.877,34.817v-6.975l-1.096,0.313c-0.091,0.028-0.147,0.114-0.143,0.207v5.935
  //                      c-0.005,0.093,0.052,0.179,0.143,0.208L47.877,34.817z"
  //         />
  //         <path
  //           className="no-blue"
  //           fill="#FFF"
  //           d="M40.982,23.574c-1.407,0-2.549,1.141-2.549,2.549s1.142,2.549,2.549,2.549
  //                      c1.409,0,2.549-1.141,2.549-2.549C43.531,24.716,42.391,23.576,40.982,23.574z M40.982,27.417c-0.714,0-1.293-0.579-1.293-1.294
  //                      s0.578-1.294,1.293-1.294c0.716,0,1.295,0.579,1.295,1.294C42.276,26.837,41.697,27.417,40.982,27.417z"
  //         />
  //         <path
  //           className="no-blue"
  //           fill="#FFF"
  //           d="M45.994,33.716v-4.771l-2.905,1.618v1.536L45.994,33.716z"
  //         />
  //       </g>
  //       <path
  //         className="no-blue"
  //         fill="#C8C8C6"
  //         d="M28.518,19.259l-6.128,4.072c-0.103,0.067-0.222,0.103-0.345,0.103c-0.104,0-0.204-0.025-0.297-0.074
  //                  c-0.202-0.106-0.331-0.314-0.336-0.544v-8.143c0.004-0.229,0.133-0.438,0.336-0.544c0.204-0.106,0.45-0.095,0.644,0.03l6.128,4.072
  //                  c0.172,0.114,0.275,0.307,0.274,0.513C28.794,18.952,28.69,19.145,28.518,19.259L28.518,19.259z"
  //       />
  //     </g>
  //   </svg>
  // ),
  [convertToSlug("Food Services")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            d="M13.137,46.66c0-0.243,0-0.409,0-0.576c0-2.628,0.004-5.256-0.007-7.884
              c-0.001-0.261,0.082-0.364,0.325-0.438c1.583-0.487,3.156-1.007,4.745-1.475c0.428-0.126,0.896-0.126,1.347-0.157
              c2.032-0.141,4.065-0.277,6.099-0.404c0.875-0.055,1.559,0.38,1.852,1.145c0.363,0.949-0.048,1.945-0.985,2.336
              c-0.672,0.281-1.356,0.53-2.034,0.795c-0.092,0.036-0.182,0.078-0.262,0.172c0.079,0.007,0.159,0.019,0.238,0.019
              c1.394,0.001,2.787,0.014,4.181-0.007c0.329-0.005,0.677-0.071,0.981-0.194c2.084-0.842,4.159-1.706,6.236-2.564
              c1.037-0.428,2.226-0.044,2.817,0.915c0.39,0.631,0.249,1.469-0.359,1.889c-0.406,0.281-0.85,0.511-1.289,0.741
              c-2.674,1.399-5.354,2.79-8.027,4.19c-0.525,0.275-1.067,0.425-1.665,0.429c-3.748,0.027-7.496,0.083-11.243,0.088
              c-0.877,0.001-1.672,0.144-2.389,0.657C13.545,46.445,13.372,46.525,13.137,46.66z"
          />
          <path
            d="M6.331,42.851c0-1.815-0.001-3.63,0-5.446c0.001-0.605,0.173-0.783,0.765-0.783
              c1.361-0.001,2.724-0.001,4.086,0c0.578,0.001,0.762,0.183,0.762,0.755c0.002,3.663,0.003,7.325,0.004,10.987
              c0,0.565-0.17,0.733-0.738,0.734c-1.394,0.001-2.787,0.001-4.182,0c-0.525,0-0.697-0.173-0.697-0.706
              c-0.002-1.847-0.001-3.694-0.001-5.541C6.33,42.851,6.33,42.851,6.331,42.851z"
          />
        </g>
        <g id="_06-meal">
          <g id="Glyph">
            <path
              d="M24.515,9.117c0.001-0.711,0.577-1.287,1.288-1.288h2.576c2.134-0.002,3.862-1.731,3.864-3.864V2.676
                  h-2.576v1.288c0,0.711-0.577,1.287-1.288,1.288h-2.576c-2.133,0.002-3.861,1.731-3.864,3.864v1.288h2.576V9.117z"
            />
            <path
              d="M32.243,10.405h2.576V9.117c0.001-0.711,0.577-1.287,1.288-1.288h2.576
                  c2.134-0.002,3.862-1.731,3.865-3.864V2.676h-2.577v1.288c0,0.711-0.576,1.287-1.288,1.288h-2.576
                  c-2.133,0.002-3.861,1.731-3.864,3.864V10.405z"
            />
            <path d="M14.21,31.015h30.915v2.576H14.21V31.015z" />
            <path
              d="M31.418,18.244l0.76-2.279c0.181-0.536-0.012-1.126-0.473-1.453c-0.121-0.154-0.308-0.244-0.504-0.243
                  h-3.068c-0.196-0.001-0.382,0.089-0.503,0.243c-0.461,0.327-0.653,0.917-0.473,1.453l0.76,2.279
                  c-6.126,0.772-11.05,5.414-12.184,11.482h27.867C42.468,23.657,37.543,19.016,31.418,18.244L31.418,18.244z M34.819,25.862
                  c-0.711,0-1.288-0.577-1.288-1.288s0.577-1.288,1.288-1.288c0.712,0,1.288,0.577,1.288,1.288S35.531,25.862,34.819,25.862z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  [convertToSlug("Graphic & Design")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              d="M26.16,14.451c-0.652,0-1.2,0.373-1.504,0.901c-9.474,0.211-13.133,5.101-13.422,9.905
                  c-0.532,0.305-0.909,0.855-0.909,1.511c0,0.972,0.793,1.764,1.765,1.764s1.765-0.792,1.765-1.764
                  c0-0.631-0.352-1.161-0.854-1.473c0.186-2.469,1.744-7.954,11.684-8.173c0.311,0.503,0.844,0.857,1.476,0.857
                  c0.974,0,1.764-0.792,1.764-1.763C27.924,15.243,27.134,14.451,26.16,14.451z"
            />
            <path
              d="M41.66,23.443l-8.795-7.036c-0.265-0.212-0.627-0.253-0.931-0.106c-0.305,0.147-0.499,0.455-0.499,0.793
                  v11.432c0,0.374,0.237,0.706,0.59,0.83c0.095,0.033,0.193,0.049,0.291,0.049c0.262,0,0.516-0.118,0.687-0.33l3.253-4.067h4.854
                  c0.374,0,0.705-0.236,0.83-0.588C42.063,24.069,41.95,23.676,41.66,23.443z"
            />
            <path
              d="M43.749,7.421H8.573c-1.94,0-3.519,1.578-3.519,3.518v24.624c0,1.94,1.578,3.518,3.519,3.518h12.314
                  c-0.037,1.403-0.305,3.648-1.312,4.708c-0.368,0.388-0.791,0.568-1.33,0.568c-0.486,0-0.881,0.394-0.881,0.88
                  c0,0.486,0.395,0.879,0.881,0.879h15.829c0.485,0,0.879-0.393,0.879-0.879c0-0.486-0.394-0.88-0.879-0.88
                  c-0.539,0-0.961-0.18-1.33-0.568c-1.003-1.054-1.272-3.302-1.311-4.708h12.314c1.938,0,3.518-1.578,3.518-3.518V10.938
                  C47.266,8.999,45.687,7.421,43.749,7.421z M26.16,37.326c-0.972,0-1.765-0.792-1.765-1.764c0-0.973,0.793-1.764,1.765-1.764
                  c0.974,0,1.764,0.792,1.764,1.764C27.924,36.534,27.134,37.326,26.16,37.326z M8.573,32.044V10.938h35.176l0.001,21.106H8.573z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  [convertToSlug("Lessons & Education")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <polygon
          points="12.918,7.439 12.918,10.834 14.399,10.377 14.399,8.922 44.062,8.922 44.062,27.832 20.458,27.832
          20.381,29.313 45.546,29.313 45.546,7.439 	"
        />
        <circle cx="11.652" cy="16.647" r="4.393" />
        <polygon
          points="15.382,41.455 15.382,33.633 15.382,32.896 15.382,31.896 15.661,31.896 16.069,23.958
          23.646,20.032 23.009,18.804 23.193,19.126 31.311,14.479 30.944,13.836 22.838,18.476 22.649,18.111 15.724,21.7 13.252,21.7
          11.576,23.642 9.955,21.7 6.396,22.361 6.169,30.257 7.678,30.257 7.76,31.896 7.988,31.896 7.988,32.896 7.988,33.633
          7.988,41.455 7.687,41.455 5.924,41.837 5.924,43.46 7.424,43.46 9.176,43.175 9.192,43.46 11.133,43.46 11.133,41.65
          11.133,41.455 11.133,33.633 12.237,33.633 12.237,41.455 12.237,41.65 12.237,43.46 14.178,43.46 14.194,43.175 15.946,43.46
          17.446,43.46 17.446,41.837 15.683,41.455 	"
        />
        <path d="M32.483,14.597l2.948,2.954l5.662-5.656l-0.813-0.801l-4.85,4.843l-2.146-2.147L32.483,14.597z" />
        <path d="M32.483,20.15l2.948,2.954l5.662-5.656l-0.813-0.802l-4.85,4.843l-2.146-2.147L32.483,20.15z" />
      </g>
    </svg>
  ),
  [convertToSlug("Math & Computers")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M17.153,14.256H9.481c-1.413,0-2.557,1.112-2.557,2.485v27.342c0,1.374,1.144,2.485,2.557,2.485h7.672
              c1.412,0,2.556-1.111,2.556-2.485V16.741C19.709,15.369,18.565,14.256,17.153,14.256z M10.76,39.112
              c-0.705,0-1.277-0.557-1.277-1.244c0-0.687,0.572-1.242,1.277-1.242c0.707,0,1.279,0.555,1.279,1.242
              C12.039,38.555,11.467,39.112,10.76,39.112z M17.153,24.197H9.481v-2.486h7.672V24.197L17.153,24.197z M17.153,19.226H9.481
              v-2.485h7.672V19.226L17.153,19.226z"
        />
        <path
          d="M33.773,41.597v-2.485h-7.672v2.485c-1.413,0-2.558,1.112-2.558,2.486h12.785
              C36.329,42.708,35.186,41.597,33.773,41.597z"
        />
        <path
          d="M45.278,9.284H14.596c-1.413,0-2.557,1.115-2.557,2.486h2.557h7.671h23.013v19.883H22.267v4.972h23.013
              c1.413,0,2.557-1.111,2.557-2.487V11.77C47.836,10.397,46.691,9.284,45.278,9.284z M29.938,35.383
              c-0.708,0-1.279-0.555-1.279-1.245c0-0.686,0.571-1.242,1.279-1.242c0.705,0,1.279,0.556,1.279,1.242
              C31.217,34.828,30.643,35.383,29.938,35.383z"
        />
      </g>
    </svg>
  ),
  [convertToSlug("Media & Electronics")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.521,25.238l0.003-9.06c0-4.036,3.271-7.307,7.307-7.307h8.499
          V4.5H14.481c-5.179,0-9.378,4.199-9.378,9.378v11.326L9.521,25.238z"
        />
        <path
          className="color-light"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.544,25.204V13.878c0-5.179-4.199-9.378-9.378-9.378H25.317
          v4.371h8.499c4.036,0,7.307,3.271,7.307,7.307l0.003,9.06L45.544,25.204z"
          style={{ opacity: "0.5" }}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.126,24.715l-0.003,9.059c0,4.036-3.271,7.308-7.307,7.308
          h-8.499v4.37h10.849c5.179,0,9.378-4.199,9.378-9.377V24.749L41.126,24.715z"
        />
        <path
          className="color-light"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.104,24.749v11.325c0,5.178,4.199,9.377,9.378,9.377H25.33
          v-4.37h-8.499c-4.036,0-7.307-3.272-7.307-7.308l-0.003-9.059L5.104,24.749z"
          style={{ opacity: "0.5" }}
        />
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          points="18.502,34.129 34.345,24.982 18.502,15.835 	"
        />
      </g>
    </svg>
  ),
  [convertToSlug("Writing & Translation")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              d="M45.904,20.2c0.702,0,1.271-0.569,1.271-1.271v-5.085c0-0.703-0.569-1.271-1.271-1.271
                  c-0.703,0-1.271,0.568-1.271,1.271v1.271H42.09v-1.271c0-0.703-0.568-1.271-1.271-1.271h-3.814V6.129
                  c0-0.703-0.568-1.271-1.271-1.271H15.221c-0.703,0-1.271,0.569-1.271,1.271v6.442h-3.814c-0.702,0-1.271,0.568-1.271,1.271v1.271
                  H6.321v-1.271c0-0.703-0.569-1.271-1.271-1.271c-0.703,0-1.271,0.568-1.271,1.271v5.085c0,0.703,0.568,1.271,1.271,1.271
                  c0.702,0,1.271-0.569,1.271-1.271v-1.271h2.542v1.271c0,0.101,0.035,0.189,0.058,0.284c-0.251,0.34-0.459,0.725-0.601,1.155
                  L4.05,38h42.854l-4.3-17.735c-0.132-0.396-0.326-0.756-0.565-1.078c0.019-0.086,0.052-0.167,0.052-0.258v-1.271h2.543v1.271
                  C44.633,19.631,45.201,20.2,45.904,20.2z M20.307,12.571h10.341c0.702,0,1.271,0.568,1.271,1.271
                  c0,0.703-0.569,1.271-1.271,1.271H20.307c-0.703,0-1.271-0.569-1.271-1.271C19.035,13.14,19.604,12.571,20.307,12.571z
                   M18.198,20.902L18.55,20.2h13.854l0.351,0.703c0.426,0.848-0.192,1.84-1.137,1.84H19.336
                  C18.392,22.743,17.773,21.75,18.198,20.902z M11.406,15.114h2.543v2.542h-1.981c-0.194,0-0.373,0.061-0.562,0.09V15.114z
                   M12.678,35.457c-0.702,0-1.271-0.569-1.271-1.271s0.569-1.271,1.271-1.271s1.271,0.569,1.271,1.271S13.38,35.457,12.678,35.457z
                   M15.221,30.371c-0.702,0-1.271-0.569-1.271-1.271s0.569-1.271,1.271-1.271s1.271,0.569,1.271,1.271S15.923,30.371,15.221,30.371
                  z M17.764,35.457c-0.702,0-1.271-0.569-1.271-1.271s0.569-1.271,1.271-1.271s1.271,0.569,1.271,1.271
                  S18.466,35.457,17.764,35.457z M20.307,30.371c-0.702,0-1.271-0.569-1.271-1.271s0.569-1.271,1.271-1.271
                  s1.271,0.569,1.271,1.271S21.009,30.371,20.307,30.371z M22.85,35.457c-0.703,0-1.271-0.569-1.271-1.271s0.568-1.271,1.271-1.271
                  c0.702,0,1.355,0.569,1.355,1.271S23.552,35.457,22.85,35.457z M25.477,30.371c-0.702,0-1.271-0.569-1.271-1.271
                  s0.569-1.271,1.271-1.271s1.271,0.569,1.271,1.271S26.179,30.371,25.477,30.371z M28.02,35.457c-0.702,0-1.271-0.569-1.271-1.271
                  s0.569-1.271,1.271-1.271s1.271,0.569,1.271,1.271S28.722,35.457,28.02,35.457z M30.647,30.371c-0.702,0-1.356-0.569-1.356-1.271
                  s0.654-1.271,1.356-1.271s1.271,0.569,1.271,1.271S31.35,30.371,30.647,30.371z M33.19,35.457c-0.702,0-1.271-0.569-1.271-1.271
                  s0.569-1.271,1.271-1.271s1.271,0.569,1.271,1.271S33.893,35.457,33.19,35.457z M35.732,30.371c-0.702,0-1.271-0.569-1.271-1.271
                  s0.569-1.271,1.271-1.271s1.271,0.569,1.271,1.271S36.435,30.371,35.732,30.371z M38.275,35.457
                  c-0.702,0-1.271-0.569-1.271-1.271s0.569-1.271,1.271-1.271s1.271,0.569,1.271,1.271S38.978,35.457,38.275,35.457z
                   M39.547,17.746c-0.188-0.029-0.366-0.089-0.561-0.089h-1.982v-2.542h2.543V17.746z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M3.778,40.542v3.899c0,2.103,1.711,3.814,3.813,3.814h35.77c2.104,0,3.814-1.711,3.814-3.814v-3.899
                  H3.778z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  [convertToSlug("Podcasting Services")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              d="M35.311,2.926H14.075c-2.972,0-5.39,2.417-5.39,5.39V41.71c0,2.972,2.418,5.39,5.39,5.39h21.235
                  c2.972,0,5.39-2.418,5.39-5.39V8.315C40.7,5.343,38.282,2.926,35.311,2.926z M24.693,42.952c-4.314,0-7.824-3.51-7.824-7.824
                  c0-4.314,3.51-7.824,7.824-7.824c4.313,0,7.824,3.51,7.824,7.824C32.518,39.441,29.007,42.952,24.693,42.952z M36.193,21.613
                  c0,0.919-0.748,1.667-1.667,1.667H14.859l0,0c-0.919,0-1.667-0.748-1.667-1.667V9.728c0-0.919,0.748-1.667,1.667-1.667h19.667
                  c0.919,0,1.667,0.748,1.667,1.667V21.613z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M24.668,31.107c-2.216,0-4.02,1.803-4.02,4.02c0,2.216,1.804,4.02,4.02,4.02
                  c2.217,0,4.021-1.804,4.021-4.02C28.688,32.911,26.885,31.107,24.668,31.107z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  [convertToSlug("Tech & Programming")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <path
        d="M45.166,7.166H6.304c-2.209,0-3.998,1.79-3.998,3.999v23.989
      c0,2.209,1.789,3.998,3.998,3.998h15.593v3.616h-3.154c-1.491,0-2.7,1.208-2.7,2.7c0,1.493,1.209,2.7,2.7,2.7h13.985
      c1.491,0,2.699-1.209,2.699-2.7c0-1.492-1.207-2.7-2.699-2.7h-3.155v-3.616h15.594c2.209,0,3.998-1.789,3.998-3.998V11.164
      C49.164,8.956,47.375,7.166,45.166,7.166z M22.104,25.661c0.439,0.363,0.5,1.013,0.137,1.451c-0.203,0.246-0.499,0.373-0.794,0.373
      c-0.231,0-0.466-0.077-0.657-0.237l-3.973-3.297c-0.236-0.196-0.374-0.486-0.374-0.794c0-0.306,0.138-0.596,0.374-0.793l3.973-3.296
      c0.438-0.364,1.086-0.303,1.451,0.134c0.363,0.439,0.303,1.088-0.137,1.452l-3.017,2.503L22.104,25.661z M28.556,16.904
      l-3.786,13.08c-0.132,0.452-0.542,0.746-0.989,0.746c-0.097,0-0.192-0.015-0.288-0.042c-0.548-0.159-0.863-0.729-0.703-1.277
      l3.786-13.08c0.159-0.548,0.729-0.862,1.277-0.704C28.4,15.785,28.715,16.357,28.556,16.904z M34.529,23.95l-3.972,3.297
      c-0.192,0.161-0.427,0.238-0.657,0.238c-0.295,0-0.591-0.126-0.794-0.373c-0.364-0.438-0.305-1.088,0.136-1.451l3.017-2.505
      l-3.017-2.503c-0.44-0.363-0.5-1.014-0.136-1.452c0.363-0.438,1.013-0.499,1.451-0.134l3.972,3.296
      c0.237,0.197,0.373,0.487,0.373,0.793C34.902,23.463,34.767,23.753,34.529,23.95z"
      />
    </svg>
  ),
  [convertToSlug("Gaming & Development")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <polygon
            points="15.028,23.246 15.028,21.265 12.479,21.265 12.479,23.246 10.545,23.246 10.545,25.794
              12.479,25.794 12.479,27.729 15.028,27.729 15.028,25.794 17.01,25.794 17.01,23.246 		"
          />
          <path
            d="M25.598,18.15h-5.426c0,0-1.604-0.377-2.784-2.076c-0.472-0.708-1.228-1.18-2.076-1.227
              c-1.888-0.141-5.144,0.095-6.795,2.784c-2.407,3.917-5.805,21.423,0.99,23.547c0,0,3.634,1.18,6.135-4.908l1.227-1.51
              c0,0,1.321,1.462,3.209,1.416c2.643-0.095,4.436-1.51,4.907-2.926h1.321h0.896h1.321c0.425,1.416,2.359,2.973,4.907,2.926
              c1.416,0,3.209-1.416,3.209-1.416l1.228,1.51c2.5,6.04,6.134,4.908,6.134,4.908c6.748-2.171,3.351-19.677,0.944-23.594
              c-1.652-2.643-4.86-2.878-6.796-2.784c-0.849,0.047-1.604,0.519-2.076,1.227c-1.18,1.698-2.783,2.076-2.783,2.076h-5.663
              L25.598,18.15z M13.754,28.909c-2.454,0-4.436-1.982-4.436-4.436s1.981-4.436,4.436-4.436s4.482,1.982,4.482,4.436
              S16.208,28.909,13.754,28.909z M20.69,34.052c-1.368,0-2.501-1.132-2.501-2.501c0-1.368,1.133-2.501,2.501-2.501
              s2.501,1.133,2.501,2.501C23.191,32.92,22.106,34.052,20.69,34.052z M27.25,26.502h-1.038c-0.614,0-1.086-0.472-1.086-1.085
              s0.472-1.085,1.086-1.085h1.038c0.613,0,1.085,0.472,1.085,1.085S27.815,26.502,27.25,26.502z M38.339,21.17
              c0-0.613,0.519-1.132,1.132-1.132h0.756c0.613,0,1.132,0.519,1.132,1.132v0.755c0,0.614-0.519,1.133-1.132,1.133h-0.756
              c-0.613,0-1.132-0.519-1.132-1.133V21.17z M32.582,34.052c-1.369,0-2.501-1.132-2.501-2.501c0-1.368,1.132-2.501,2.501-2.501
              c1.368,0,2.501,1.133,2.501,2.501C35.083,32.92,33.997,34.052,32.582,34.052z M37.206,26.03h-0.755
              c-0.613,0-1.133-0.519-1.133-1.132v-0.755c0-0.614,0.52-1.133,1.133-1.133h0.755c0.613,0,1.133,0.519,1.133,1.133v0.755
              C38.339,25.511,37.819,26.03,37.206,26.03z M41.312,27.871c0,0.613-0.52,1.133-1.133,1.133h-0.755
              c-0.613,0-1.133-0.52-1.133-1.133v-0.755c0-0.613,0.52-1.132,1.133-1.132h0.755c0.613,0,1.133,0.519,1.133,1.132V27.871z
               M44.284,24.898c0,0.613-0.519,1.132-1.133,1.132h-0.755c-0.613,0-1.132-0.519-1.132-1.132v-0.755
              c0-0.614,0.519-1.133,1.132-1.133h0.755c0.614,0,1.133,0.519,1.133,1.133V24.898z"
          />
        </g>
      </g>
    </svg>
  ),
  [convertToSlug("Psychologist Social Services")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <path
        d="M49.371,25.139C49.371,25.139,49.371,25.139,49.371,25.139
      c-0.463-0.44-0.926-0.88-1.389-1.32v0c-0.535-0.72-1.167-1.342-1.899-1.861l0,0c-0.045-0.048-0.089-0.096-0.134-0.145l0,0
      c-0.405-0.598-0.972-1.049-1.445-1.585l0,0l0,0c0.025-1.763,0.08-3.529-0.707-5.18c-0.428-1.11-0.815-2.24-1.575-3.185
      c-0.001,0,0,0-0.001-0.001c-0.577-1.052-1.376-1.916-2.229-2.751c-0.82-0.803-1.762-1.43-2.688-2.085l0,0
      c-2.377-1.493-4.989-2.084-7.77-2.033c-0.842,0.016-1.123,0.26-1.136,1.02c-0.014,0.777,0.318,1.101,1.118,1.145
      c1.033,0.056,2.051,0.001,3.102,0.233c1.827,0.404,3.435,1.161,4.938,2.221c1.885,1.328,3.235,3.132,4.087,5.209
      c0.703,1.714,1.033,3.588,0.973,5.48c-0.016,0.485,0.086,0.914,0.413,1.249c0.713,0.73,1.455,1.432,2.186,2.145
      c0.846,0.869,1.692,1.737,2.535,2.609c0.161,0.167,0.407,0.317,0.294,0.599c-0.122,0.301-0.383,0.392-0.7,0.388
      c-0.885-0.01-1.77-0.004-2.654-0.003c-0.99,0.001-1.341,0.344-1.342,1.317c-0.002,2.012-0.026,4.023,0.01,6.034
      c0.02,1.118-0.896,2.175-2.139,2.149c-1.458-0.031-2.918-0.01-4.376-0.006c-1.049,0.003-1.536,0.486-1.537,1.513
      c-0.001,0.91-0.025,1.821,0.009,2.729c0.02,0.503-0.148,0.66-0.652,0.655c-2.271-0.023-4.543-0.032-6.814,0.004
      c-0.629,0.01-0.748-0.22-0.74-0.788c0.027-2.25,0.008-4.501,0.014-6.752c0.001-0.441-0.061-0.859-0.329-1.22
      c-1.091-1.466-2.098-1.691-3.705-1.014c-0.962,0.405-2.303-0.035-2.777-0.963c-0.57-1.116-0.15-1.99,0.634-2.788c0,0,0,0,0,0l0,0
      c0.608-0.513,1.339-0.216,2.012-0.303l0,0l0,0c1.171,0.413,2.321,0.652,3.317-0.399c0.199-0.153,0.399-0.306,0.433-0.583v0
      c0.289-0.177,0.301-0.452,0.26-0.745l0,0v0c0.004-1.458-0.002-2.917,0.014-4.375c0.008-0.765,0.47-1.091,1.179-0.832
      c0.318,0.117,0.629,0.222,0.971,0.189c1.199,0.367,2.279,0.07,3.295-0.588l0,0l0,0c0.443-0.067,0.672-0.38,0.854-0.746h-0.001h0.001
      c0.009-0.082,0.018-0.164,0.027-0.246c0.231-0.02,0.305-0.205,0.388-0.377c0.774-1.587,0.613-3.09-0.405-4.516
      c-0.257-0.477-0.71-0.731-1.143-1.008c-0.303-0.173-0.608-0.337-0.973-0.33c-0.02,0.023-0.039,0.046-0.06,0.068
      c-0.002,0-0.004,0-0.006,0c0.024-0.018,0.048-0.037,0.065-0.068c-0.281-0.211-0.605-0.235-0.945-0.264
      c-0.711-0.061-1.375,0.173-2.033,0.342c-0.529,0.137-0.87,0.023-1.192-0.353l0,0c-0.002-2.272-0.001-4.545-0.01-6.817
      c-0.003-0.764-0.288-1.099-1.01-1.081c-2.001,0.047-4.01-0.174-6.003,0.16c-1.701,0.285-3.326,0.786-4.809,1.692
      c0.018,0.032,0.041,0.057,0.068,0.075c-0.002,0-0.004,0.001-0.007,0.001l0,0c-0.021-0.026-0.041-0.051-0.062-0.077
      c-2.165,1.158-3.899,2.773-5.166,4.884c-0.556,0.763-1.071,1.547-1.299,2.482l0,0c-0.183,0.196-0.269,0.433-0.286,0.697l0.052,0.041
      c0,0.003,0,0.006,0,0.009c-0.018-0.016-0.034-0.033-0.052-0.049c-0.733,1.619-0.753,3.335-0.688,5.063
      c-0.006,0.046-0.004,0.091,0.006,0.136c-0.508,0.505-1.046,0.983-1.462,1.573l0,0c-0.284,0.288-0.568,0.575-0.853,0.863
      c-0.953,0.721-1.689,1.673-2.595,2.445l0,0l0,0c-0.584,0.499-0.931,1.182-0.782,1.892c0.217,1.037,0.799,1.876,1.918,2.214
      c0.442,0.134,0.873,0.215,1.333,0.212c1.562-0.009,1.562-0.003,1.562,1.59c0,1.246,0.002,2.492-0.002,3.738
      c-0.001,0.411,0.02,0.812,0.293,1.151c0.341,0.821,0.694,1.631,1.566,2.044c0.364,0.369,0.834,0.532,1.312,0.683
      c0.234,0.313,0.576,0.296,0.912,0.296c1.053,0,2.107,0.029,3.158-0.012c0.555-0.021,0.697,0.179,0.677,0.703
      c-0.037,0.957-0.022,1.917-0.006,2.875c0.013,0.683,0.414,1.178,0.996,1.182c2.394,0.017,4.787,0.015,7.18,0
      c0.565-0.003,0.864-0.34,0.885-0.914c0.023-0.656-0.42-1.086-1.198-1.097c-1.22-0.017-2.441,0.017-3.661-0.013
      c-0.707-0.017-1.613,0.318-2.065-0.16c-0.414-0.438-0.127-1.329-0.117-2.017c0.035-2.277,0.108-2.59-2.603-2.596
      c-1.688-0.003-3.489,0.521-5.028-0.684c-0.447-0.526-0.535-1.161-0.537-1.815c-0.005-1.892,0.004-3.783-0.004-5.674
      c-0.004-0.927-0.212-1.13-1.151-1.145c-0.765-0.011-1.53,0.003-2.296-0.004c-0.474-0.004-0.985,0.055-1.214-0.522
      c-0.215-0.544,0.175-0.839,0.492-1.159c1.55-1.556,3.1-3.113,4.655-4.665c0.211-0.211,0.383-0.423,0.361-0.742
      c-0.11-1.687,0.122-3.334,0.581-4.954c0.102-0.213,0.212-0.423,0.304-0.641c0.502-1.188,1.097-2.318,1.964-3.286l0,0l0,0v0
      c0.084-0.053,0.161-0.108,0.196-0.181c0.707-0.65,1.415-1.301,2.122-1.952v0c1.224-0.672,2.41-1.417,3.74-1.876l0,0
      c0.169-0.113,0.357-0.16,0.56-0.155c0.366,0.006,0.735,0.027,1.02-0.273l0,0c1.519-0.132,3.039-0.152,4.563-0.158
      c0.571-0.002,0.736,0.192,0.729,0.748c-0.027,1.813-0.003,3.626-0.01,5.439c-0.002,0.618,0.205,1.107,0.757,1.421
      c0.816,0.637,1.653,0.863,2.709,0.496c1.011-0.352,2.103-0.476,3.009,0.389c0.921,1.248,0.922,2.167,0.005,3.442
      c-0.049,0.047-0.097,0.095-0.146,0.143c-1.06,0.398-2.107,0.419-3.181,0.006c-1.452-0.56-3.301,0.942-3.178,2.437
      c0.113,1.377,0.021,2.772,0.02,4.16c-0.261,0.503-0.653,0.617-1.183,0.445c-0.448-0.145-0.907-0.287-1.372-0.341
      c-1.086-0.127-2.089,0.08-2.888,0.904c-0.036,0.019-0.075,0.028-0.115,0.027l0,0c-0.4-0.015-0.584,0.305-0.778,0.555
      c-1.037,1.332-1.294,2.822-0.61,4.363c0.303,0.684,0.69,1.421,1.526,1.681c0.281,0.146,0.563,0.293,0.844,0.439h0.001
      c-0.001,0-0.001,0-0.001,0c1.061,0.49,2.142,0.335,3.199,0.036c1.077-0.306,1.386-0.107,1.386,1.034
      c0.001,2.58-0.003,5.159,0.005,7.739c0.001,0.339-0.094,0.76,0.441,0.827c0.236,0.134,0.493,0.161,0.761,0.161
      c3.225-0.003,6.45-0.003,9.675,0c0.342,0,0.671-0.015,0.912-0.306c0.311-0.234,0.298-0.575,0.298-0.91
      c0.001-0.957,0.022-1.915-0.009-2.871c-0.017-0.513,0.188-0.674,0.673-0.666c1.075,0.02,2.151,0.024,3.226,0
      c0.9-0.02,1.749-0.228,2.436-0.869l0,0l0,0c0.24-0.141,0.434-0.329,0.569-0.575l0,0c0.448-0.384,0.642-0.918,0.854-1.442l0,0l0,0
      c0.272-0.337,0.294-0.737,0.293-1.146c-0.004-1.241-0.002-2.482-0.002-3.724c0-1.611,0-1.618,1.602-1.611
      c1.056,0.004,1.955-0.315,2.646-1.153C50.451,27.272,50.354,25.919,49.371,25.139z"
      />
    </svg>
  ),
  [convertToSlug("Healthcare Practitioners")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M44.041,14.422H42.7V12.4h-6.742v2.022h-2.695v-3.906c0-1.191-0.97-2.161-2.161-2.161H20.594
          c-1.192,0-2.162,0.969-2.162,2.161v3.906h-2.697V12.4h-6.74v2.022H7.652c-1.118,0-2.029,0.911-2.029,2.029v24.254
          c0,1.121,0.911,2.03,2.029,2.03h3.365h1.348h26.964h1.349h0.674h2.689c1.118,0,2.03-0.909,2.03-2.03V16.451
          C46.07,15.333,45.159,14.422,44.041,14.422z M31.914,14.422H19.779v-3.906c0-0.448,0.365-0.813,0.814-0.813h10.508
          c0.448,0,0.813,0.365,0.813,0.813V14.422z"
        />
        <g>
          <path
            className="no-blue"
            fill="#FFFDFD"
            d="M35.927,27.87l-2.195,0.665l1.543,0.589c-1.292,2.021-3.576,3.953-6.486,6.412
              c-0.815,0.69-1.736,1.431-2.64,2.221c-0.125,0.1-0.264,0.15-0.414,0.15s-0.288-0.05-0.414-0.15
              c-0.903-0.791-1.781-1.531-2.597-2.221c-2.911-2.459-5.236-4.391-6.529-6.412l1.619-0.452l-2.271-0.803
              c-0.339-0.803-0.515-1.631-0.515-2.535c0-3.476,2.464-6.085,5.688-6.085c2.534,0,4.166,1.757,5.019,3.338
              c0.854-1.581,2.526-3.338,5.061-3.338c3.225,0,5.646,2.61,5.646,6.085C36.441,26.239,36.266,27.067,35.927,27.87z"
          />
          <path
            d="M35.927,27.87c-0.176,0.426-0.389,0.84-0.652,1.254h-4.479c-0.238,0-0.451-0.138-0.564-0.351l-0.689-1.38
              l-1.987,3.889c-0.112,0.213-0.326,0.352-0.564,0.352s-0.451-0.138-0.564-0.352l-0.69-1.38l-0.689,1.38
              c-0.214,0.427-0.916,0.427-1.13,0l-1.944-3.889l-0.69,1.38c-0.113,0.213-0.326,0.352-0.564,0.352h-4.521
              c-0.263-0.414-0.477-0.829-0.652-1.255h4.785l1.079-2.158c0.213-0.426,0.916-0.426,1.129,0l1.945,3.89l0.689-1.38
              c0.113-0.213,0.339-0.326,0.564-0.326c0.227,0,0.452,0.113,0.564,0.326l0.69,1.38l1.986-3.89c0.214-0.426,0.916-0.426,1.13,0
              l1.079,2.158H35.927L35.927,27.87z"
          />
          <path
            d="M35.927,27.87c-0.176,0.426-0.389,0.84-0.652,1.254h-4.479c-0.238,0-0.451-0.138-0.564-0.351l-0.689-1.38
              l-1.987,3.889c-0.112,0.213-0.326,0.352-0.564,0.352s-0.451-0.138-0.564-0.352l-0.69-1.38v-2.008c0.227,0,0.452,0.113,0.564,0.326
              l0.69,1.38l1.986-3.89c0.214-0.426,0.916-0.426,1.13,0l1.079,2.158H35.927L35.927,27.87z"
          />
        </g>
      </g>
    </svg>
  ),
  // [convertToSlug("Healthcare Practitioners home")]: (
  //   <svg
  //     className=" category-menu-parent-icon remove-border"
  //     style={{ padding: "2px" }}
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="35.625"
  //     height="30.28"
  //     viewBox="0 0 35.625 30.28"
  //   >
  //     <g
  //       id="Group_8900"
  //       data-name="Group 8900"
  //       transform="translate(16643.945 12550.534)"
  //     >
  //       <path
  //         id="Path_14460"
  //         data-name="Path 14460"
  //         d="M44.9,17.809H43.715V16.028H37.777v1.781H35.4V14.37a1.906,1.906,0,0,0-1.9-1.9H24.245a1.907,1.907,0,0,0-1.9,1.9v3.439H19.965V16.028H14.028v1.781H12.846A1.791,1.791,0,0,0,11.059,19.6V40.959a1.789,1.789,0,0,0,1.787,1.787H44.9a1.79,1.79,0,0,0,1.788-1.787V19.6A1.79,1.79,0,0,0,44.9,17.809Zm-10.68,0H23.527V14.368a.717.717,0,0,1,.718-.715H33.5a.716.716,0,0,1,.716.715Z"
  //         transform="translate(-16655.004 -12563)"
  //         fill="#fff"
  //       />
  //       <path
  //         id="Path_14461"
  //         data-name="Path 14461"
  //         d="M37.75,29.652l-1.934.586,1.358.52c-1.138,1.779-3.149,3.48-5.713,5.646-.718.607-1.529,1.26-2.324,1.957a.566.566,0,0,1-.729,0c-.8-.7-1.569-1.35-2.287-1.957-2.564-2.166-4.612-3.867-5.751-5.646l1.427-.4-2-.707a5.629,5.629,0,0,1-.453-2.232,5.085,5.085,0,0,1,5.01-5.359A5.1,5.1,0,0,1,28.773,25a5.158,5.158,0,0,1,4.457-2.939A5.057,5.057,0,0,1,38.2,27.421,5.66,5.66,0,0,1,37.75,29.652Z"
  //         transform="translate(-16655.004 -12563)"
  //         fill="#233f5f"
  //       />
  //       <path
  //         id="Path_14462"
  //         data-name="Path 14462"
  //         d="M37.75,29.652a7.1,7.1,0,0,1-.575,1.1H33.23a.563.563,0,0,1-.5-.311l-.607-1.215-1.75,3.426a.553.553,0,0,1-.994,0l-.608-1.215-.606,1.215a.582.582,0,0,1-1,0L25.46,29.231l-.608,1.215a.565.565,0,0,1-.5.311H20.37a7.1,7.1,0,0,1-.574-1.1h4.215l.95-1.9a.58.58,0,0,1,.994,0l1.713,3.426.607-1.217a.574.574,0,0,1,.994,0l.608,1.217,1.749-3.426a.582.582,0,0,1,1,0l.95,1.9H37.75Z"
  //         transform="translate(-16655.004 -12563)"
  //         fill="#fff"
  //       />
  //       <path
  //         id="Path_14463"
  //         data-name="Path 14463"
  //         d="M37.75,29.652a7.1,7.1,0,0,1-.575,1.1H33.23a.563.563,0,0,1-.5-.311l-.607-1.215-1.75,3.426a.553.553,0,0,1-.994,0l-.608-1.215v-1.77a.557.557,0,0,1,.5.287l.608,1.217,1.749-3.426a.582.582,0,0,1,1,0l.95,1.9H37.75Z"
  //         transform="translate(-16655.004 -12563)"
  //         fill="#fff"
  //       />
  //     </g>
  //   </svg>
  // ),
  [convertToSlug("Business & Finance")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <path
        d="M44.041,14.422h-1.342V12.4h-6.741v2.022h-2.695v-3.906c0-1.191-0.97-2.161-2.161-2.161
      H20.594c-1.192,0-2.162,0.969-2.162,2.161v3.906h-2.697V12.4h-6.74v2.022H7.652c-1.118,0-2.029,0.911-2.029,2.029v24.254
      c0,1.121,0.911,2.03,2.029,2.03h3.365h1.348h26.964h1.349h0.674h2.689c1.118,0,2.03-0.909,2.03-2.03V16.451
      C46.07,15.333,45.159,14.422,44.041,14.422z M19.779,10.516c0-0.448,0.365-0.813,0.814-0.813h10.508
      c0.448,0,0.813,0.365,0.813,0.813v3.906H19.779V10.516z M12.364,41.387h-1.348V15.771h1.348V41.387z M17.596,31.026h-2.913
      c-0.29,0-0.485-0.193-0.485-0.485v-8.738c0-0.098,0.098-0.291,0.195-0.388c0.097-0.097,0.191-0.097,0.29-0.097
      c1.069,0.097,4.368,0.29,5.146,1.067c0.195,0.098,0.195,0.291,0.195,0.388c-0.195,2.524-1.941,7.669-1.941,7.96
      C17.983,30.929,17.789,31.026,17.596,31.026z M31.672,32.579c-0.193,0.097-0.389,0.195-0.583,0.097l-2.814-2.815
      c-0.194-0.193-0.485-0.193-0.681,0c-0.192,0.196-0.192,0.485,0,0.681l2.721,2.717c-0.099,0.194-0.293,0.292-0.39,0.388
      c-0.29,0.098-0.68,0.098-0.777,0l-2.426-2.427c-0.194-0.193-0.486-0.193-0.68,0c-0.194,0.196-0.194,0.487,0,0.681l2.232,2.232
      c-0.097,0.292-0.193,0.485-0.485,0.583c-0.195,0-0.388,0.098-0.582-0.098l-1.941-1.941c-0.194-0.193-0.486-0.193-0.68,0
      c-0.194,0.195-0.194,0.486,0,0.681l1.554,1.552c-0.098,0-0.194,0-0.292,0c-0.292,0-0.873-0.193-1.164-0.387
      c-0.39-0.292-2.524-1.846-4.369-3.398c-0.291-0.194-0.681-0.68-1.067-1.067c0.484-1.36,1.164-3.885,1.553-5.827h1.94
      c-0.68,0.681-1.553,2.04-1.359,3.01c0.098,0.388,0.388,0.777,0.874,0.873c1.067,0.39,2.038,0,2.815-0.971
      c0.388,0,0.484-0.097,0.777-0.193c0-0.097,0.193-0.097,0.29-0.194c0.582,0.486,1.359,1.067,2.137,1.748
      c1.456,1.165,3.105,2.427,3.494,3.3C31.963,32.191,31.77,32.482,31.672,32.579z M32.449,30.831c-0.68-0.873-2.039-2.038-3.398-3.105
      c-0.873-0.777-1.747-1.457-2.234-1.941c-0.097,0-0.29-0.098-0.387-0.098s-0.098,0-0.194,0c-0.29,0.098-0.484,0.194-0.68,0.292
      c-0.29,0.097-0.388,0.193-0.679,0.193c-0.195,0-0.291,0.098-0.389,0.194c-0.679,1.069-1.357,0.972-1.845,0.777
      c-0.193-0.097-0.193-0.097-0.193-0.193c-0.097-0.388,0.389-1.358,1.068-2.039c1.554-1.554,2.33-1.941,4.077-1.165
      c0.776,0.292,1.649,0.68,2.621,0.971c0.485,0.873,1.65,4.174,2.33,6.115H32.449z M37.497,31.512c0,0.29-0.194,0.485-0.485,0.485
      h-2.913c-0.193,0-0.388-0.195-0.485-0.388c-0.29-1.359-2.233-6.698-2.814-7.669c-0.098-0.097-0.098-0.292-0.098-0.388
      c0.098-0.098,0.194-0.193,0.291-0.291c0.098,0,2.233-0.971,6.02-0.971c0.291,0,0.485,0.194,0.485,0.486V31.512z M40.677,41.387
      h-1.348V15.771h1.348V41.387z"
      />
    </svg>
  ),
  [convertToSlug("Legal Services")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M36.339,46.58v1.734H15.521V46.58c0-0.956,0.778-1.736,1.735-1.736h3.491
          c0.093-2.164,1.497-3.972,3.446-4.663V19.838c-0.913-0.578-1.525-1.592-1.525-2.752h1.525h3.473h1.525
          c0,1.16-0.612,2.175-1.525,2.752v20.339c1.948,0.691,3.354,2.5,3.444,4.664h3.493C35.559,44.843,36.339,45.624,36.339,46.58z
           M48.482,30.965c-1.272,3.58-4.658,6.162-8.674,6.162c-4.018,0-7.403-2.583-8.676-6.162h2.003l5.861-15.533
          c0.252-0.676,1.368-0.676,1.622,0l5.861,15.533H48.482L48.482,30.965z M44.625,30.965l-4.816-12.767l-4.817,12.767H44.625
          L44.625,30.965L44.625,30.965z M12.052,13.635c0.784,0,1.427-0.525,1.648-1.24c0.573-0.217,1.16-0.514,1.765-0.818
          c1.328-0.67,2.83-1.432,4.394-1.432c1.498,0,2.435,0.692,2.971,1.282c-0.02,0.149-0.045,0.299-0.045,0.452
          c0,1.738,1.409,3.147,3.146,3.147c1.736,0,3.145-1.41,3.145-3.147c0-0.153-0.023-0.3-0.045-0.449
          c0.535-0.589,1.472-1.285,2.973-1.285c1.562,0,3.063,0.761,4.392,1.432c0.604,0.304,1.191,0.6,1.767,0.818
          c0.222,0.713,0.863,1.237,1.647,1.237c0.968,0,1.751-0.783,1.751-1.751c0-0.967-0.783-1.751-1.751-1.751
          c-0.501,0-0.945,0.214-1.266,0.55c-0.429-0.179-0.891-0.41-1.367-0.652c-1.498-0.757-3.199-1.615-5.174-1.615
          c-1.728,0-2.933,0.656-3.748,1.365C27.888,9.373,27.418,9.07,26.886,8.9c0.648-0.347,1.102-1.009,1.102-1.79
          c0-1.138-2.057-3.791-2.057-3.791s-2.06,2.653-2.06,3.788c0,0.784,0.454,1.445,1.103,1.792c-0.534,0.169-1.002,0.474-1.368,0.877
          c-0.817-0.708-2.021-1.366-3.749-1.366c-1.976,0-3.676,0.858-5.175,1.617c-0.478,0.243-0.938,0.474-1.367,0.652
          c-0.319-0.335-0.765-0.552-1.264-0.552c-0.97,0-1.754,0.784-1.754,1.751C10.298,12.849,11.083,13.635,12.052,13.635z M12.052,37.13
          c-4.018,0-7.403-2.585-8.675-6.166h2l5.861-15.532c0.254-0.674,1.37-0.674,1.623,0l5.861,15.532h2.002
          C19.454,34.545,16.068,37.13,12.052,37.13z M16.869,30.965l-4.817-12.766L7.233,30.965H16.869L16.869,30.965L16.869,30.965z"
        />
      </g>
    </svg>
  ),
  [convertToSlug("Marketing Services")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M39.848,7.752l2.755-2.756c0.539-0.538,1.41-0.538,1.949,0c0.538,0.539,0.538,1.41,0,1.949L41.796,9.7
          c-0.538,0.539-1.41,0.539-1.948,0C39.309,9.162,39.31,8.291,39.848,7.752z"
        />
        <path
          d="M33.934,3.215c0-0.761,0.616-1.377,1.377-1.377c0.762,0,1.378,0.616,1.378,1.377v2.755
          c0,0.762-0.616,1.378-1.378,1.378c-0.761,0-1.377-0.616-1.377-1.378V3.215z"
        />
        <path
          d="M11.328,42.002l-3.896-3.896c-1.618-1.619-1.611-4.234,0-5.846l6.82-6.819l9.741,9.742l-6.819,6.819
          C15.556,43.62,12.939,43.614,11.328,42.002z"
        />
        <path
          d="M31.216,44.16c1.074,1.075,1.074,2.823,0,3.897c-1.077,1.077-2.828,1.071-3.896,0.001l-6.25-6.057
          l3.896-3.896l2.799,2.712l1.905-1.905c0.541-0.543,0.537-1.413,0-1.947l-1.662-1.665c0.714-0.6,1.449-1.161,2.218-1.678
          l1.393,1.393c1.612,1.61,1.618,4.226,0,5.845l-1.874,1.874L31.216,44.16z"
        />
        <path
          d="M39.227,26.758c-4.865,0.974-9.422,3.4-13.166,6.596l-9.979-9.979c3.196-3.745,5.56-8.366,6.531-13.23
          l0.139-0.692L39.92,26.62L39.227,26.758z"
        />
        <path
          d="M46.352,25.259c-1.075,1.075-2.822,1.075-3.896,0L24.111,6.917c-1.073-1.075-1.073-2.823,0-3.899
          c1.068-1.065,2.817-1.078,3.899,0.001l18.341,18.343C47.429,22.44,47.429,24.181,46.352,25.259z"
        />
        <path
          d="M46.333,15.614h-2.756c-0.761,0-1.377-0.616-1.377-1.377c0-0.762,0.616-1.378,1.377-1.378h2.756
          c0.762,0,1.378,0.616,1.378,1.378C47.711,14.999,47.095,15.614,46.333,15.614z"
        />
      </g>
    </svg>
  ),
  [convertToSlug("Repair & Maintenance")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M14.191,8.758l2.245,2.29c1.878-1.191,3.985-2.062,6.229-2.474l0.046-3.206c0-0.778,0.642-1.42,1.42-1.375
                   l3.848,0.046c0.778,0,1.42,0.642,1.374,1.42l-0.046,3.207c2.243,0.458,4.306,1.375,6.139,2.656l2.289-2.244
                   c0.551-0.55,1.466-0.55,2.016,0.045l2.702,2.749c0.551,0.549,0.551,1.465-0.045,2.015l-2.291,2.245
                   c1.192,1.878,2.063,3.984,2.474,6.229l3.207,0.045c0.778,0,1.42,0.642,1.374,1.42l-0.046,3.847c0,0.779-0.642,1.42-1.42,1.374
                   L42.5,29.003c-0.275,1.329-0.733,2.611-1.283,3.803l-3.665-3.665c0.366-1.099,0.551-2.291,0.551-3.527
                   c0.137-6.826-5.36-12.46-12.185-12.551c-6.825-0.137-12.46,5.36-12.551,12.185c-0.138,6.825,5.359,12.46,12.184,12.551
                   c1.191,0,2.336-0.137,3.39-0.413l3.71,3.711c-1.236,0.549-2.519,0.961-3.847,1.191l-0.047,3.206c0,0.778-0.641,1.42-1.42,1.374
                   l-3.848-0.046c-0.778,0-1.42-0.641-1.374-1.42l0.046-3.207c-2.244-0.458-4.306-1.374-6.138-2.657l-2.291,2.245
                   c-0.549,0.549-1.465,0.549-2.015-0.046l-2.703-2.748c-0.55-0.55-0.55-1.467,0.046-2.015l2.29-2.245
                   c-1.19-1.878-2.062-3.984-2.474-6.229l-3.205-0.044c-0.778,0-1.42-0.642-1.374-1.42l0.046-3.847c0-0.779,0.642-1.42,1.42-1.375
                   l3.207,0.045c0.457-2.244,1.374-4.306,2.656-6.138l-2.244-2.29c-0.55-0.55-0.55-1.466,0.046-2.016l2.748-2.703
                   C12.726,8.162,13.643,8.162,14.191,8.758z M17.627,23.462c-0.687,2.794,0.046,5.818,2.199,8.017
                   c1.786,1.786,4.169,2.61,6.505,2.427c1.374-0.091,2.702,0.367,3.664,1.329l7.283,7.283c0.505,0.504,1.283,0.504,1.786,0
                   l0.963-0.962l-8.658-8.657c-0.412-0.413-0.457-1.053-0.137-1.466c0.412-0.504,1.146-0.504,1.603-0.092l8.704,8.704l0.962-0.962
                   c0.504-0.504,0.504-1.283,0-1.787l-6.963-6.962c-1.008-1.008-1.513-2.475-1.374-3.894c0.275-2.474-0.504-5.038-2.382-6.917
                   c-2.291-2.291-5.59-2.978-8.521-2.061c-0.184,0.045-0.229,0.32-0.091,0.457l3.572,3.572c1.007,1.008,1.007,2.703,0,3.711
                   l-1.558,1.557c-1.008,1.008-2.702,1.008-3.71,0l-3.39-3.389C17.948,23.188,17.674,23.279,17.627,23.462z"
        />
      </g>
    </svg>
  ),
  [convertToSlug("Transporter Auto")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M49.584,30.708h-0.549c0.274-3.073-1.37-4.504-1.37-4.504c-1.371-0.548-13.435-1.489-13.435-1.489
                  c-2.193-4.113-4.661-5.723-4.661-5.723c-12.063-1.371-23.305-0.19-23.305-0.19c-2.193,3.564-1.92,11.394-1.92,11.394H2.701v2.163
                  c1.024,0.751,4.1,1.501,4.588,1.627l1.169-4.094c0,0,1.097-1.646,2.193-1.372l4.661,0.274c0,0,1.645,0.418,2.193,2.064l0.657,3.434
                  h17.986c0.093,0,0.182-0.14,0.272-0.139l1.099-3.916c0,0,1.097-1.681,2.193-1.407l4.661,0.256c0,0,1.645,0.266,2.193,1.91
                  l0.604,3.01c2.326-0.104,2.412-0.278,2.412-0.278C50.406,30.987,49.584,30.708,49.584,30.708z M17.231,23.859l-7.677-0.274
                  l-0.822-1.097l1.097-3.016l8.773,0.275L17.231,23.859z M22.44,24.167l0.267-4.215H22.44l0.274-0.137l-0.008,0.137h6.862
                  l2.742,4.576L22.44,24.167z M47.116,29.683l-1.646-2.706l2.469,0.531c0.273,0,0.821,2.176,0.821,2.176L47.116,29.683L47.116,29.683
                  z"
        />
        <path d="M2.427,22.512h2.192c0,0-1.097,4.098-0.822,7.171H1.878C1.878,29.683,0.781,26.098,2.427,22.512z" />
        <path
          d="M12.708,29.891c2.271,0,4.122,1.784,4.238,4.026c0.196,0.004,0.393,0.008,0.584,0.011l-0.497-2.667
                   c-0.509-1.646-2.04-1.919-2.04-1.919l-4.335-0.274c-1.021-0.274-2.04,1.371-2.04,1.371l-0.879,3.307
                   c0.214,0.004,0.465,0.009,0.738,0.014C8.67,31.592,10.489,29.891,12.708,29.891z"
        />
        <path
          d="M41.77,30.165c2.272,0,4.122,1.784,4.238,4.027c0.197,0.003,0.393,0.007,0.585,0.011l-0.497-2.667
                   c-0.51-1.645-2.04-1.92-2.04-1.92l-4.336-0.274c-1.021-0.274-2.04,1.371-2.04,1.371l-0.879,3.308
                   c0.214,0.004,0.465,0.009,0.738,0.014C37.732,31.866,39.551,30.165,41.77,30.165z"
        />
        <path
          d="M12.708,30.165c-2.196,0-3.976,1.78-3.976,3.976c0,2.196,1.779,3.976,3.976,3.976
                   c2.195,0,3.976-1.78,3.976-3.976C16.684,31.945,14.903,30.165,12.708,30.165z M12.688,36.854c-1.556,0-2.816-1.261-2.816-2.817
                   s1.261-2.817,2.816-2.817c1.557,0,2.817,1.261,2.817,2.817S14.245,36.854,12.688,36.854z"
        />
        <path
          d="M41.77,30.439c-2.195,0-3.976,1.78-3.976,3.976s1.78,3.975,3.976,3.975s3.976-1.78,3.976-3.976
                   S43.965,30.439,41.77,30.439z M41.884,37.366c-1.556,0-2.817-1.261-2.817-2.817s1.262-2.817,2.817-2.817s2.817,1.261,2.817,2.817
                   S43.439,37.366,41.884,37.366z"
        />
      </g>
    </svg>
  ),
  [convertToSlug("Fishing & Agriculture")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <path
        d="M47.598,10.571c-0.011-0.094-0.021-0.188-0.031-0.282l0,0l0,0
              c-0.091-1.24-0.561-2.362-1.068-3.474c-0.096-0.211-0.242-0.37-0.454-0.464c-1.113-0.499-2.23-0.986-3.473-1.066l-0.064-0.038
              l-0.071,0.024c-1.085-0.256-2.17-0.256-3.257-0.014c-0.091,0.012-0.183,0.024-0.274,0.036c-2.082,0.26-3.833,1.221-5.359,2.605
              c-0.902,0.818-1.727,1.724-2.611,2.562c-0.463,0.438-0.398,0.756,0.056,1.152c1.21,1.054,2.393,2.14,3.655,3.275
              c-0.896,0.869-1.749,1.698-2.604,2.525c-0.269,0.26-0.54,0.521-0.231,0.917c0.184,0.236-0.011,0.354-0.139,0.48
              c-0.614,0.605-1.154,1.208-1.722,1.811l-0.014-0.009l-1.01,0.975c-0.032,0.027-0.064,0.058-0.095,0.092l-0.815,0.787
              c-0.028,0.002-0.057,0.003-0.084,0.008c-0.135,0.065-0.263,0.134-0.225,0.29l-0.687,0.663c-0.064-0.017-0.14-0.004-0.224,0.034
              c-0.072,0.041-0.135,0.094-0.188,0.157c-0.058,0.087-0.095,0.178-0.072,0.277l-0.001,0c-0.264-0.01-0.428,0.198-0.604,0.377
              c-0.009,0.009-0.016,0.02-0.023,0.029c-0.069-0.048-0.147-0.081-0.229-0.109l-0.605-0.588c-0.16-0.236-0.335-0.458-0.604-0.587
              l-1.718-1.671c-0.031-0.095-0.09-0.171-0.204-0.213c-0.187-0.176-0.372-0.353-0.559-0.529l-0.856-0.833
              c0.365-1.179-0.763-1.484-1.277-2.125c-0.276-0.343-0.192-0.473,0.06-0.715c0.674-0.65,1.332-1.318,1.988-1.986
              c0.226-0.231,0.507-0.439,0.492-0.819c0.171-0.282,0.117-0.558-0.023-0.832c-0.29-0.821-1.111-1.589-1.666-1.576
              c-1.563,0.037-1.998-1.244-2.832-1.994c-1.646-1.483-3.158-3.115-4.731-4.68c-0.265-0.264-0.553-0.69-0.958-0.275
              c-0.404,0.413,0.03,0.694,0.293,0.957c2.075,2.082,4.155,4.16,6.235,6.237c0.327,0.327,0.691,0.535,0.127,1.083
              c-0.556,0.539-0.768,0.263-1.145-0.118c-2.047-2.071-4.112-4.124-6.171-6.185c-0.317-0.318-0.618-0.685-1.116-0.281
              c-0.371,0.403-0.116,0.724,0.164,1.003c1.903,1.898,3.738,3.867,5.723,5.684c0.226,0.207,0.383,0.47,0.617,0.67
              c0.247,0.21,0.281,0.471,0.087,0.74c-0.24,0.333-0.475,0.148-0.71-0.016c-0.136-0.095-0.278-0.182-0.448-0.206l0.043-0.046
              l-0.045,0.044c-0.387-0.896-1.208-1.414-1.847-2.083c-1.11-1.161-2.268-2.276-3.399-3.417c-0.157-0.159-0.32-0.312-0.567-0.311
              c-0.388-0.159-0.65-0.953-1.15-0.425C8.339,8.663,9.077,8.968,9.38,9.276c1.984,2.022,3.996,4.018,6.002,6.019
              c0.332,0.332,0.696,0.545,0.121,1.084c-0.502,0.47-0.703,0.325-1.086-0.064c-2.028-2.058-4.078-4.093-6.122-6.135
              c-0.157-0.157-0.311-0.32-0.483-0.458C7.618,9.569,7.397,9.551,7.205,9.728c-0.22,0.203-0.219,0.443-0.038,0.667
              c0.139,0.172,0.304,0.325,0.46,0.482c2.063,2.063,4.116,4.134,6.195,6.179c0.371,0.365,0.432,0.575,0.019,0.96
              c-0.377,0.351-0.569,0.515-1.021,0.049c-2.031-2.093-4.11-4.139-6.173-6.201c-0.157-0.157-0.309-0.323-0.48-0.461
              c-0.223-0.178-0.462-0.191-0.667,0.033c-0.174,0.192-0.165,0.411-0.009,0.605c0.139,0.173,0.302,0.325,0.459,0.482
              c2.022,2.024,4.041,4.052,6.074,6.065c0.377,0.374,0.667,0.587,0.122,1.149c-0.603,0.623-0.815,0.133-1.137-0.187
              c-1.968-1.96-3.93-3.926-5.895-5.89c-0.156-0.157-0.313-0.314-0.472-0.47c-0.238-0.233-0.504-0.397-0.79-0.103
              c-0.284,0.292-0.117,0.558,0.117,0.792c0.863,0.865,1.744,1.713,2.588,2.598c0.839,0.879,1.813,1.631,2.511,2.644
              c0.098,0.462,0.505,0.681,0.788,0.995c0.291,0.321,0.611,0.616,0.65,1.085c0.013,0.366,0.153,0.678,0.402,0.944
              c0.07,0.091,0.146,0.179,0.223,0.266c0.349,0.396,0.59,0.929,1.22,0.973c0.074-0.01,0.147-0.019,0.221-0.026
              c0.063,0.028,0.126,0.057,0.188,0.086c0.074-0.028,0.149-0.057,0.225-0.085c0.078,0.009,0.156,0.025,0.234,0.05
              c0.114,0.019,0.226,0.012,0.336-0.025c0.374-0.196,0.603-0.573,0.968-0.78c0.397-0.257,0.663-0.645,0.968-0.991
              c0.87-0.99,0.867-0.993,1.79-0.057c0.382,0.381,0.746,0.783,1.369,0.736c0.226-0.017,0.376,0.191,0.549,0.318l0,0l2.387,2.437
              c0.005,0.013,0.007,0.026,0.012,0.039c0.161,0.323,0.422,0.545,0.729,0.718l0.775,0.792c0.083,0.404-0.181,0.735-0.569,1.046
              c-0.188,0.151-0.347,0.341-0.527,0.505c-0.189,0.167-0.387,0.326-0.573,0.497c-0.053,0.046-0.101,0.098-0.144,0.153
              c-0.099,0.138-0.205,0.269-0.343,0.372c-0.116,0.087-0.173,0.208-0.201,0.347c0.054,0.351-0.257,0.468-0.438,0.652
              c-1.09,1.109-2.193,2.206-3.294,3.303c-0.205,0.204-0.38,0.514-0.739,0.183c-0.127-0.117-0.376-0.101-0.461,0.032
              c-0.601,0.927-1.646,0.633-2.473,0.93c-0.095,0.01-0.189,0.021-0.285,0.031c-2.529,0.146-4.683,1.045-6.284,3.091
              c-0.273,0.35-0.319,0.615,0.001,0.936c2.459,2.454,4.917,4.908,7.367,7.371c0.4,0.403,0.662,0.233,1.054-0.059
              c2.246-1.678,2.731-4.13,3.022-6.679c0.011-0.094,0.021-0.188,0.031-0.283c0.205-0.697,0.158-1.507,0.896-1.965
              c0.057-0.035,0.114-0.172,0.09-0.219c-0.463-0.89,0.354-1.199,0.779-1.633c1.824-1.869,3.694-3.693,5.522-5.559
              c0.372-0.379,0.573-0.41,0.967-0.008c2.258,2.301,4.547,4.57,6.829,6.846c0.217,0.216,0.462,0.399,0.169,0.734
              c-0.104,0.12-0.062,0.305,0.045,0.38c0.73,0.517,0.645,1.354,0.84,2.08l0.193,1.296c0.108,2.176,1.209,3.804,2.827,5.154
              c0.33,0.275,0.59,0.216,0.866-0.061c2.295-2.298,4.589-4.596,6.892-6.887c0.418-0.416,0.175-0.682-0.104-1.051
              c-1.593-2.12-3.924-2.535-6.325-2.812l0,0c-0.097-0.01-0.192-0.021-0.289-0.031c-0.592-0.178-1.271-0.167-1.66-0.786
              c-0.05-0.08-0.237-0.169-0.286-0.138c-0.749,0.481-1.006-0.237-1.359-0.587c-1.653-1.637-3.278-3.303-4.913-4.959
              c-0.347-0.631-0.909-1.078-1.374-1.607c-0.19-0.209-0.151-0.416-0.053-0.626l0.824-0.835c0.21-0.116,0.341-0.384,0.541-0.52
              c0.033-0.038,0.062-0.078,0.089-0.119l2.472-2.503c0.231-0.135,0.402-0.361,0.608-0.536c0.434-0.471,0.84-0.949,1.608-0.807
              c0.348,0.064,0.598-0.249,0.835-0.482c0.572-0.564,1.153-1.122,1.702-1.709c0.376-0.403,0.675-0.376,1.029,0.018
              c0.573,0.638,1.08,1.338,1.732,1.904c0.134,0.129,0.258,0.266,0.358,0.424c0.126,0.19,0.271,0.365,0.429,0.53
              c0.769,0.659,0.911,0.656,1.629-0.039c0.216-0.208,0.427-0.421,0.638-0.635c0.234-0.252,0.503-0.48,0.604-0.831
              c1.02-1.116,2.093-2.191,2.741-3.592c0.451-0.978,0.867-1.965,0.934-3.059c0.011-0.09,0.021-0.18,0.031-0.27
              C47.823,12.537,47.819,11.554,47.598,10.571z M36.864,38.898c0.036-0.633,0.315-1.054,0.986-1.115
              c1.232-0.113,2.362,0.229,3.473,0.711c-1.265,1.267-2.474,2.478-3.741,3.748C37.141,41.129,36.797,40.06,36.864,38.898z
               M36.599,38.666c0.025,0.005,0.051,0.009,0.076,0.014c0,0.364,0,0.728,0,1.092c-0.025-0.001-0.051-0.003-0.076-0.005
              C36.599,39.399,36.599,39.032,36.599,38.666z M15.727,39.14l-0.022,0.294c-0.29,0.857-0.371,1.784-0.759,2.436
              c-1.323-1.324-2.658-2.66-3.984-3.987c0.696-0.301,1.518-0.533,2.364-0.677C15.123,36.9,15.654,37.331,15.727,39.14z"
      />
    </svg>
  ),
  [convertToSlug("Architecture & Engineering")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            d="M9.229,15.941H27.94c0.353,0,0.706-0.353,0.706-0.706c0-0.423-0.354-0.706-0.706-0.706h-0.988
                       c0.211-4.167-2.612-7.343-5.86-8.333V4.925c0-0.353-0.282-0.706-0.707-0.706h-3.53c-0.423,0-0.707,0.353-0.707,0.706v1.271
                       c-3.459,1.059-6.213,4.307-5.86,8.333H9.229c-0.354,0-0.706,0.282-0.706,0.706C8.523,15.587,8.875,15.941,9.229,15.941z"
          />
        </g>
        <g>
          <path d="M18.62,23.778c3.459,0,6.354-2.753,6.99-6.496H11.56C12.194,21.024,15.16,23.778,18.62,23.778z" />
        </g>
        <g>
          <path
            d="M28.787,33.522c-0.211-0.565-0.281-1.271,0.212-2.047c4.237-4.307,2.754-2.825,3.106-3.107
              c0.283-0.282,0.565-0.495,0.919-0.564c-0.282-0.918-0.919-1.695-1.765-2.19c-2.119-1.2-4.237-2.048-6.355-2.612
              c-0.282-0.069-0.637,0.071-0.777,0.354l-4.307,7.697h-2.33l-4.236-7.626c-0.142-0.282-0.495-0.423-0.777-0.352
              c-2.118,0.564-4.237,1.482-6.355,2.613c-1.2,0.635-1.977,1.906-2.048,3.247L3.015,42.56c0,0.212,0.071,0.423,0.213,0.564
              c0.14,0.142,0.281,0.212,0.494,0.212h25.065c-0.281-0.564-0.211-1.2,0-1.765c-0.281-0.141-0.564-0.282-0.777-0.494
              c-0.423-0.423-0.635-0.918-0.635-1.483c0.142-3.46-0.423-4.59,0.635-5.578C28.293,33.804,28.506,33.664,28.787,33.522z"
          />
        </g>
        <g>
          <path
            d="M47.993,35.076c-0.141-0.141-0.282-0.213-0.495-0.213H45.45c-0.069-0.071-0.069-0.14-0.141-0.282
              l1.413-1.413c0.282-0.283,0.282-0.705,0-0.988l-2.754-2.895c-0.282-0.283-0.706-0.283-0.988,0l-1.412,1.412
              c-0.07-0.07-0.141-0.07-0.282-0.07v-2.048c0-0.423-0.282-0.705-0.706-0.705h-4.095c-0.212,0-0.354,0.07-0.495,0.211
              c-0.141,0.141-0.212,0.282-0.212,0.495v2.048c-0.07,0-0.141,0.071-0.212,0.071l-1.413-1.413c-0.283-0.282-0.705-0.282-0.988,0
              c-0.07,0.141-2.824,2.825-2.895,2.896c-0.213,0.423-0.283,0.776,0.07,1.059c0.565,0.565,0.988,1.06,1.342,1.342
              c0,0.07-0.07,0.141-0.07,0.282h-2.048c-0.212,0-0.353,0.071-0.495,0.212c-0.142,0.141-0.211,0.282-0.211,0.494
              c0,0.07,0,3.742,0,4.095l0,0c0,0.212,0.069,0.353,0.211,0.494c0.213,0.213,0.565,0.213,0.777,0.213h1.766
              c0,0.07,0.07,0.141,0.07,0.211l-1.412,1.412c-0.283,0.283-0.283,0.707,0,0.989l2.895,2.895l0,0c0.283,0.283,0.705,0.283,0.988,0
              l0.071-0.071l1.342-1.341c0.07,0,0.141,0.07,0.282,0.14v1.978c0,0.352,0.282,0.705,0.706,0.705h4.095
              c0.212,0,0.353-0.07,0.495-0.212c0.14-0.141,0.211-0.282,0.211-0.494v-2.048c0.07,0,0.142-0.071,0.213-0.071l1.411,1.413
              c0.142,0.14,0.283,0.211,0.495,0.211s0.353-0.07,0.494-0.211l2.895-2.896c0.283-0.282,0.283-0.705,0-0.988l-1.412-1.413
              c0-0.069,0.071-0.14,0.071-0.211h2.047c0.424,0,0.706-0.282,0.706-0.706v-3.813v-0.283C48.205,35.428,48.135,35.216,47.993,35.076
              z M42.485,38.042c-0.141,1.059-0.636,2.046-1.482,2.682c-0.705,0.565-1.553,0.847-2.471,0.847c-2.4,0-4.308-2.118-4.025-4.519
              c0.282-2.19,2.259-3.813,4.448-3.531C41.215,33.875,42.768,35.852,42.485,38.042z"
          />
        </g>
      </g>
    </svg>
  ),
  [convertToSlug("Managers & Contractors")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      xmlns="http://www.w3.org/2000/svg"
      width="36.354"
      height="29.655"
      viewBox="0 0 36.354 29.655"
    >
      <g
        id="Group_8874"
        data-name="Group 8874"
        transform="translate(18.074 -64.599)"
      >
        <g
          id="Group_8435"
          data-name="Group 8435"
          transform="translate(-18.074 64.599)"
        >
          <path
            id="Path_11330"
            data-name="Path 11330"
            d="M18.7,34.768H17.644l-1.817,3.156L14.1,34.768H13.052a2.329,2.329,0,0,0-2.3,2.3v6.313H20.991V37.065A2.328,2.328,0,0,0,18.7,34.768Zm-2.87-10.81a9.138,9.138,0,0,1,7.079-6.983.488.488,0,1,1,.191.956,8.191,8.191,0,0,0-6.218,5.931l.956-.766c.478-.382,1.148.384.67.861L16.113,25.87,14.2,23.477c-.479-.478.383-1.148.764-.668Zm25.16-.1a8.2,8.2,0,0,0-6.218-5.931c-.67-.094-.384-1.148.287-.956a9.153,9.153,0,0,1,6.984,6.983l.863-1.148c.477-.478,1.243.19.861.67l-2.008,2.391-2.393-1.913c-.479-.478.191-1.244.67-.859Zm-11.671-2.01.191,1.436-.574.957-.477-.957.1-1.436c-.67-.765-.574-1.433.382-1.433C29.985,20.42,30.081,21.088,29.316,21.853Zm-.383-8.13a2.917,2.917,0,1,1,0,5.833,2.917,2.917,0,0,1,0-5.833ZM26.158,20.9h.289l2.486,4.4,2.487-4.4H31.8a2.328,2.328,0,0,1,2.3,2.295v6.313H23.861V23.192A2.328,2.328,0,0,1,26.158,20.9Zm13.01,13.871h1.053l1.818,3.156,1.818-3.156h.957a2.329,2.329,0,0,1,2.3,2.3v6.313H36.874V37.065A2.328,2.328,0,0,1,39.168,34.768Zm2.87-6.792a2.967,2.967,0,0,1,0,5.931,2.966,2.966,0,0,1,0-5.931Zm-26.212,0a2.966,2.966,0,1,0,2.966,2.967A2.962,2.962,0,0,0,15.827,27.977Z"
            transform="translate(-10.756 -13.723)"
          />
        </g>
      </g>
    </svg>
  ),
  [convertToSlug("Clothes/Shoes & Accesories")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // width="509.093"
      // height="326.524"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 509.093 326.524"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <path
        id="Path_19056"
        data-name="Path 19056"
        d="M484.883,301.091,274.371,183.245v-8.852c0-5.23,3-9.444,9.449-13.244.8-.474,1.733-.982,2.775-1.549,10.226-5.6,31.511-17.256,31.511-43.525,0-27.285-25.158-48.222-49.491-52.707-25.622-4.756-48.857.82-63.988,15.665-13.8,13.49-17.668,24.729-18.482,42.975-.066,1.32-.1,4.163-.142,6.939-.038,3.833,8.426,6.949,18.88,6.949h3.787c10.453,0,18.946-3.1,18.966-6.931.009-3.165.038-6.347.066-6.949.578-12.584,4.365-19.99,18.737-22.587,8.938-1.608,18.586.627,24.239,5.577,2.566,2.234,3.92,6.11,3.541,10.114a14.488,14.488,0,0,1-7.158,11.129l-18.037,10.977c-11.5,6.584-16.4,14.463-16.4,26.337v19.71L24.235,300.676C-6.651,317.7-.26,346.74,2.439,355.237c2.472,7.744,13.71,33,54.633,33h397.55c28.917,0,47.209-16.807,52.54-33.48C512.682,337.329,506.7,312.584,484.883,301.091ZM463.493,342.1a9.661,9.661,0,0,1-9.469,6.474H56.57c-5.511-.017-8.616-3.249-9.6-6.008-1.259-3.546.369-7.168,4.119-9.546l203.7-113.961L459.621,333.5C464.687,336.339,464.015,340.46,463.493,342.1Z"
        transform="translate(0 -61.713)"
      />
    </svg>
  ),
  [convertToSlug("Sports & Outdoors")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      // width="50"
      // height="32.414"
      // viewBox="0 0 50 32.414">
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 32.414"
      xmlSpace="preserve"
    >
      <path
        id="Path_19060"
        data-name="Path 19060"
        d="M36,26,24,40H10l7-14H36Z"
        transform="translate(0 -17.586)"
        stroke="#c8c8c6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        id="Path_19061"
        data-name="Path 19061"
        d="M29,19c1,1,1.016,1,6,1a107.608,107.608,0,0,0,5,20"
        transform="translate(0 -17.586)"
        stroke="#c8c8c6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <line
        id="Line_1456"
        data-name="Line 1456"
        x2="9.327"
        y2="18.635"
        transform="translate(14.673 3.779)"
        fill="none"
        stroke="#c8c8c6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        id="Path_19062"
        data-name="Path 19062"
        d="M20,20.575c0,.717-1.113,1.371-3.673,1.371-2.653,0-5.327,2.688-5.327-.52C11,20.5,11.209,20,12.346,20h6.442C19.511,20,20,20.021,20,20.575Z"
        transform="translate(0 -17.586)"
        fill="#c8c8c6"
      />
      <circle
        id="Ellipse_1199"
        data-name="Ellipse 1199"
        cx="9"
        cy="9"
        r="9"
        transform="translate(1 13.414)"
        stroke="#c8c8c6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <circle
        id="Ellipse_1198"
        data-name="Ellipse 1198"
        cx="9"
        cy="9"
        r="9"
        transform="translate(31 13.414)"
        stroke="#c8c8c6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  ),
  [convertToSlug("Babies & Kids")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      // width="368.253" height="484.222"
      viewBox="0 0 468.253 584.222"
      // >
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      // viewBox="0 0 51.469 50.9"
      // enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <path
        id="Path_19066"
        data-name="Path 19066"
        d="M244,250.594c53.838,0,97.484-53.012,97.484-118.407S327.155,13.778,244,13.778s-97.488,53.013-97.488,118.409S190.16,250.594,244,250.594Z"
        transform="translate(-59.868 -13.778)"
      />
      <path
        id="Path_19067"
        data-name="Path 19067"
        d="M427.915,426.607C426.109,312.688,411.23,280.227,297.38,259.68c0,0-16.026,20.422-53.381,20.422s-53.383-20.422-53.383-20.422C78.006,280,62.223,311.983,60.147,422.907c-.17,9.058-.249,9.534-.279,8.482.006,1.97.015,5.613.015,11.968,0,0,27.1,54.643,184.115,54.643s184.116-54.643,184.116-54.643c0-4.082,0-6.922.007-8.853C428.091,435.155,428.029,433.895,427.915,426.607Z"
        transform="translate(-59.868 -13.778)"
      />
    </svg>
  ),
  [convertToSlug("Hosptiatlity & Care")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M27.758,48.533c1.572,0,2.847-1.273,2.847-2.847V23.123l1.271-5.866c0.614,0.462,1.323,0.963,2.12,1.468
          c2.797,1.759,6.641,3.598,10.979,3.611c0.237,0,0.474-0.007,0.708-0.018c1.017-0.046,1.802-0.91,1.754-1.925
          c-0.046-1.017-0.908-1.801-1.925-1.755c-0.185,0.01-0.363,0.013-0.538,0.013c-3.241,0.013-6.547-1.474-9.009-3.04
          c-1.23-0.777-2.248-1.562-2.946-2.145c-0.351-0.291-0.621-0.532-0.801-0.695c-0.091-0.083-0.156-0.146-0.199-0.186
          c-0.021-0.022-0.036-0.037-0.045-0.044c-0.009-0.007-0.009-0.008-0.009-0.007c-0.123-0.122-0.258-0.221-0.401-0.3
          c-0.009-0.005-0.017-0.01-0.025-0.015c-0.012-0.006-0.021-0.012-0.031-0.017c-0.365-0.208-0.798-0.33-1.265-0.33H29.68
          c-0.789,2.164-2.867,3.714-5.303,3.714c-2.433,0-4.513-1.551-5.301-3.714h-0.563c-0.312,0-0.606,0.054-0.877,0.152
          c-0.016,0.004-0.032,0.006-0.049,0.011h-0.001c-0.053,0.018-1.475,0.425-2.998,1.14c-0.766,0.362-1.559,0.791-2.271,1.379
          c-0.355,0.298-0.697,0.638-0.989,1.089c-0.078,0.12-0.152,0.251-0.219,0.393c-0.181,0.378-0.31,0.826-0.31,1.321
          c0,0.303,0.05,0.617,0.146,0.912l0,0l0,0c0.229,0.692,0.671,1.229,1.177,1.657c0.771,0.641,1.736,1.117,3.041,1.586
          c1.304,0.463,2.951,0.896,5.057,1.314c0.12,0.025,0.241,0.035,0.36,0.035c0.859,0,1.629-0.607,1.804-1.484
          c0.197-0.997-0.452-1.967-1.449-2.165c-2.644-0.521-4.422-1.067-5.455-1.535c-0.303-0.135-0.535-0.262-0.708-0.371
          c0.094-0.074,0.206-0.153,0.33-0.231c0.029-0.018,0.062-0.037,0.092-0.055c0.423-0.263,0.955-0.521,1.473-0.742l0.32,1.478
          c0.916,0.297,2.211,0.645,4.038,1.005c0.604,0.12,1.124,0.467,1.466,0.978c0.341,0.511,0.465,1.125,0.345,1.728
          c-0.214,1.074-1.162,1.854-2.255,1.854c-0.151,0-0.303-0.014-0.452-0.044c-0.73-0.145-1.407-0.292-2.041-0.442l0.062,0.288v22.563
          c0,1.573,1.274,2.847,2.847,2.847s2.847-1.273,2.847-2.847V24.902h1.067v20.784C24.911,47.258,26.187,48.533,27.758,48.533z
           M24.378,16.719c0.445,0,0.806,0.362,0.806,0.806c0,0.444-0.361,0.805-0.806,0.805c-0.445,0-0.806-0.36-0.806-0.805
          C23.572,17.081,23.933,16.719,24.378,16.719z M24.378,21.497c-0.445,0-0.806-0.361-0.806-0.807c0-0.443,0.36-0.804,0.806-0.804
          s0.806,0.361,0.806,0.804C25.184,21.136,24.822,21.497,24.378,21.497z"
        />
        <path
          d="M24.376,15.127c2.861,0,5.182-2.319,5.182-5.181c0-0.632-0.113-1.239-0.32-1.799
          c-0.642,0.356-2.689,1.395-4.861,1.395c-2.17,0-4.219-1.039-4.858-1.395c-0.208,0.56-0.321,1.166-0.321,1.799
          C19.197,12.808,21.516,15.127,24.376,15.127z"
        />
        <path
          d="M19.926,7.854c0,0,1.919,1.113,4.45,1.113c2.533,0,4.452-1.113,4.452-1.113V3.868
          c-0.735,0.402-2.451,0.687-4.452,0.687c-1.999,0-3.714-0.283-4.45-0.687V7.854z"
        />
        <ellipse cx="24.376" cy="3.288" rx="4.511" ry="0.921" />
      </g>
    </svg>
  ),
  [convertToSlug("Custom Production")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <path
        d="M25.037,2.453L5.12,13.951v22.997l19.917,11.499l19.915-11.499V13.951L25.037,2.453z
       M26.945,7.961l12.324,7.116l-12.324,7.098V7.961z M23.129,22.137l-12.273-7.09l12.273-7.085V22.137z M21.246,25.457l-12.311,7.09
      V18.346L21.246,25.457z M23.129,28.775v14.164l-12.281-7.09L23.129,28.775z M26.945,28.75l12.284,7.096l-12.284,7.093V28.75z
       M28.883,25.462l12.254-7.058v14.137L28.883,25.462z"
      />
    </svg>
  ),
  [convertToSlug("Hospitality & Care")]: (
    <svg
      className=" category-menu-parent-icon remove-border"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 51.469 50.9"
      enableBackground="new 0 0 51.469 50.9"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M27.758,48.533c1.572,0,2.847-1.273,2.847-2.847V23.123l1.271-5.866c0.614,0.462,1.323,0.963,2.12,1.468
       c2.797,1.759,6.641,3.598,10.979,3.611c0.237,0,0.474-0.007,0.708-0.018c1.017-0.046,1.802-0.91,1.754-1.925
       c-0.046-1.017-0.908-1.801-1.925-1.755c-0.185,0.01-0.363,0.013-0.538,0.013c-3.241,0.013-6.547-1.474-9.009-3.04
       c-1.23-0.777-2.248-1.562-2.946-2.145c-0.351-0.291-0.621-0.532-0.801-0.695c-0.091-0.083-0.156-0.146-0.199-0.186
       c-0.021-0.022-0.036-0.037-0.045-0.044c-0.009-0.007-0.009-0.008-0.009-0.007c-0.123-0.122-0.258-0.221-0.401-0.3
       c-0.009-0.005-0.017-0.01-0.025-0.015c-0.012-0.006-0.021-0.012-0.031-0.017c-0.365-0.208-0.798-0.33-1.265-0.33H29.68
       c-0.789,2.164-2.867,3.714-5.303,3.714c-2.433,0-4.513-1.551-5.301-3.714h-0.563c-0.312,0-0.606,0.054-0.877,0.152
       c-0.016,0.004-0.032,0.006-0.049,0.011h-0.001c-0.053,0.018-1.475,0.425-2.998,1.14c-0.766,0.362-1.559,0.791-2.271,1.379
       c-0.355,0.298-0.697,0.638-0.989,1.089c-0.078,0.12-0.152,0.251-0.219,0.393c-0.181,0.378-0.31,0.826-0.31,1.321
       c0,0.303,0.05,0.617,0.146,0.912l0,0l0,0c0.229,0.692,0.671,1.229,1.177,1.657c0.771,0.641,1.736,1.117,3.041,1.586
       c1.304,0.463,2.951,0.896,5.057,1.314c0.12,0.025,0.241,0.035,0.36,0.035c0.859,0,1.629-0.607,1.804-1.484
       c0.197-0.997-0.452-1.967-1.449-2.165c-2.644-0.521-4.422-1.067-5.455-1.535c-0.303-0.135-0.535-0.262-0.708-0.371
       c0.094-0.074,0.206-0.153,0.33-0.231c0.029-0.018,0.062-0.037,0.092-0.055c0.423-0.263,0.955-0.521,1.473-0.742l0.32,1.478
       c0.916,0.297,2.211,0.645,4.038,1.005c0.604,0.12,1.124,0.467,1.466,0.978c0.341,0.511,0.465,1.125,0.345,1.728
       c-0.214,1.074-1.162,1.854-2.255,1.854c-0.151,0-0.303-0.014-0.452-0.044c-0.73-0.145-1.407-0.292-2.041-0.442l0.062,0.288v22.563
       c0,1.573,1.274,2.847,2.847,2.847s2.847-1.273,2.847-2.847V24.902h1.067v20.784C24.911,47.258,26.187,48.533,27.758,48.533z
        M24.378,16.719c0.445,0,0.806,0.362,0.806,0.806c0,0.444-0.361,0.805-0.806,0.805c-0.445,0-0.806-0.36-0.806-0.805
       C23.572,17.081,23.933,16.719,24.378,16.719z M24.378,21.497c-0.445,0-0.806-0.361-0.806-0.807c0-0.443,0.36-0.804,0.806-0.804
       s0.806,0.361,0.806,0.804C25.184,21.136,24.822,21.497,24.378,21.497z"
        />
        <path
          d="M24.376,15.127c2.861,0,5.182-2.319,5.182-5.181c0-0.632-0.113-1.239-0.32-1.799
       c-0.642,0.356-2.689,1.395-4.861,1.395c-2.17,0-4.219-1.039-4.858-1.395c-0.208,0.56-0.321,1.166-0.321,1.799
       C19.197,12.808,21.516,15.127,24.376,15.127z"
        />
        <path
          d="M19.926,7.854c0,0,1.919,1.113,4.45,1.113c2.533,0,4.452-1.113,4.452-1.113V3.868
       c-0.735,0.402-2.451,0.687-4.452,0.687c-1.999,0-3.714-0.283-4.45-0.687V7.854z"
        />
        <ellipse cx="24.376" cy="3.288" rx="4.511" ry="0.921" />
      </g>
    </svg>
  ),
};
export default CatIcons;
