import React, { useState } from "react";
import { GlassBtn } from "../buttons";
import TooltipDots from "../toolTipDots/toolTipDots";
import { EyeIcon, EyeSlashIcon } from "../icons";

const TransactionHistoryCard = ({ transaction }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="transaction-card">
      {/* Top Row with Button and Campaign Name */}
      <div className="campaign-name">
        <span className="transactionHeaders">Campaign Name:</span>{" "}
        <TooltipDots text={transaction.campaignName} maxLength={50} />
      </div>

      {/* Second Row with Points Used and Points Earned */}
      <div className="details-vertical">
        <div className="details-row">
          <span className="transactionHeaders">Points Used:</span>{" "}
          {transaction.rewardPointsUsed}
          <span className="transactionHeaders">Points Earned:</span>{" "}
          {transaction.rewardPointsEarned}
        </div>
        <div className="button-container">
          <GlassBtn
            glassBtnImage={
              expanded ? (
                <EyeSlashIcon
                  height="25px"
                  width="25px"
                  fillColor="#e2b645" // Use white color for EyeSlashIcon
                />
              ) : (
                <EyeIcon
                  height="25px"
                  width="25px"
                  fillColor="#fdfd96" // Use yellow color for EyeIcon
                />
              )
            }
            extraStyles={{
              height: "30px",
            }}
            glassBtnTextFontSize="1rem"
            glassBtnText={expanded ? "Hide Details" : "View More Details"} // Change text dynamically
            glassBtnFontWeight="700"
            glassBtnFontColor={expanded ? "#e2b645" : "#fdfd96"}
            glassBtnTextDecorationColor={expanded ? "#e2b645" : "#fdfd96"}
            onClick={(e) => {
              e.preventDefault();
              setExpanded(!expanded); // Toggle expanded state
            }}
          />
        </div>
      </div>
      {/* Expanded Details */}
      {expanded && (
        <div className="card-details">
          <p>
            <span className="transactionHeaders">Payment Method Type:</span>{" "}
            {transaction.paymentMethodType}
          </p>
          <p>
            <span className="transactionHeaders">Payment Method:</span>{" "}
            {transaction.paymentMethod}
          </p>
          <p>
            <span className="transactionHeaders">
              Beginning Reward Points Balance:
            </span>{" "}
            {transaction.beginingRewardPointsBalance}
          </p>
          <p>
            <span className="transactionHeaders">
              Ending Reward Points Balance:
            </span>{" "}
            {transaction.endingRewardPointsBalance}
          </p>
          <p>
            <span className="transactionHeaders">
              Payment Transaction Type:
            </span>{" "}
            {transaction.paymentTransactionType}
          </p>
          <p>
            <span className="transactionHeaders">Order Total Due:</span>{" "}
            {transaction.orderTotalDue}
          </p>
          <p>
            <span className="transactionHeaders">Order Grand Total:</span>{" "}
            {transaction.orderGrandTotal}
          </p>
          <p>
            <span className="transactionHeaders">Date:</span>{" "}
            {new Date(transaction.createdAt).toLocaleString()}
          </p>
        </div>
      )}
    </div>
  );
};

export default TransactionHistoryCard;
