// Helper function to capitalize the first letter of a string
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
  // Function to capitalize the first letter of each word in a string
  export const getCapitalizedLocationDetail = (detail) => {
    if (!detail) return "";
    return detail.split(' ').map(capitalizeFirstLetter).join(' ');
  };

  // This will extract number from a string value.
export const removestring = (str)  =>{
  const matches = str.match(/(\d+)/);
   
  if (matches) {
    return parseInt(matches[0]);
  }
}
export function convertToSlug(text) {
  if (!text) return ""; 
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

