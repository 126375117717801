import React from "react";
import { CheckMark } from "../icons";
import "./checkBox.scss";

const Checkbox = ({
  label,
  name,
  checked,
  onChange,
  strokeColor = "#e2b645",
  checkmarkColor = "#e2b645",
  className,
}) => {
  return (
    <div className={`custom-checkbox ${className}`}>
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={name}>
        <div className="checkmark-container">
          {checked && <CheckMark fill={checkmarkColor} stroke={strokeColor} />}
        </div>
        <span className="checkbox-label">{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
