import React, { useState, useEffect } from "react";
import { useAuth } from "../../components/context/authContext.jsx";
import PhoneInput from "react-phone-input-2";
import { InsetBlendTVButton } from "../buttons";
import { CheckMarkIcon } from "../icons/index.jsx";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import { handleAcctValidationErrors } from "../../controller/errorHandlers/accountValidationErrorHandler.js";
import { handlePhoneInputChange } from "../../controller/watcherAndClearFunctions/accountVerificationClearsWatchersFn.js";
import { CellPhoneIcon, SkitsOlympicLogoIcon } from "../icons/index.jsx";
import { formatPhoneNumber } from "../../utils/formatNumber.js";
import { apiVerifyUserByPhoneNumber } from "../../api/backend/auth.js";
import { errorMessages } from "../../constants/errorMessages.js";
import Loader from "../loader/loader.jsx";
import "react-phone-input-2/lib/material.css";
import "./accountVerification.scss";
const AccountVerification = ({
  contextType,
  onCancel,
  onVerifyAccount,
  setContextType,
}) => {
  const { setUserInfo } = useAuth();
  const currentContext = contextType ? "resetPassword" : "completeSignup";
  const [phoneNumberFocused, setPhoneNumberFocused] = useState(false);
  const [mobielEntryAttempted, setMobielEntryAttempted] = useState(false);
  const [absentPhoneNumberError, setAbsentPhoneNumberError] = useState("");
  const [acctPhoneNumberErrors, setAcctPhoneNumberErrors] = useState("");
  const [acctPhoneNumberValue, setAcctPhoneNumberValue] = useState(
    localStorage.getItem(getStorageKey("verifyAccountValue", currentContext)) ||
      ""
  );
  const [loading, setLoading] = useState(false);

  const onVerifyUserAccount = async (e) => {
    e.preventDefault();
    setMobielEntryAttempted(true); // Trigger validation
    // Don't include form submission logic here
    // It will be handled in the useEffect
  };

  useEffect(() => {
    if (mobielEntryAttempted) {
      const { errors } = handleAcctValidationErrors(
        acctPhoneNumberValue,
        setAcctPhoneNumberErrors,
        currentContext
      );
      if (!errors.acctPhoneNumberErrors) {
        setLoading(true); // Show loader
        const formattedPhoneNumber = formatPhoneNumber(acctPhoneNumberValue); // Format the phone number and binding to pass the new paramter with the new format that matches the backend from the helper function
        apiVerifyUserByPhoneNumber(formattedPhoneNumber)
          .then((response) => {
            setLoading(false); // Hide loader
            const user = response.data.user;
            setUserInfo(user.id); // Store userId in cookies via context instead of localStorage
            // localStorage.setItem("userId", user.id); // Store userId in local storage
            if (
              response.data.status === "OK" &&
              response.data.type === "AccountVerified"
            ) {
              // User is fully verified, proceed with reset password or complete signup
              if (currentContext === "resetPassword") {
                setContextType("resetPassword"); // Set context type to reset password
                onVerifyAccount(user, "resetPassword"); // Pass user object and resetPassword context
              } else {
                setContextType("completeSignup"); // Set context type to complete signup
                onVerifyAccount(user, "completeSignup"); // Pass user object and completeSignup context
              }
            } else if (response.data.status === "IncompleteSignup") {
              // User has not completed signup, proceed with incomplete signup process
              setContextType("completeSignup"); // Set context type to complete signup
              onVerifyAccount(user, "completeSignup"); // Pass user object and completeSignup context
            } else {
              setAbsentPhoneNumberError("User not found");
            }
          })
          .catch((error) => {
            setLoading(false); // Hide loader
            console.error("Verification failed:", error);
            // Extract error message from response
            const errorMessage =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : "An error occurred during verification.";

            setAbsentPhoneNumberError(errorMessage);
          });
      } else {
        console.log("Validation errors present, not proceeding to next form.");
      }
      setMobielEntryAttempted(false);
    }
  }, [mobielEntryAttempted, acctPhoneNumberValue, onVerifyAccount]);

  return (
    <div className="form-wrapper client-form">
      {loading && <Loader className="acct-verify-loader-position" />}
      <form id="forgot-password-form">
        <div id="logo-header">
          <SkitsOlympicLogoIcon />
          <h3>Skits Olympic</h3>
        </div>
        <div className="input-group blinker-wrapper">
          <PhoneInput
            id="acctVerifyMobile"
            type="text"
            country={"us"}
            onlyCountries={["us"]} // to start off for version 1
            disableDropdown={true} // remove this when we accept other country codes but for version 1 launch it stays to us only
            autoComplete="off"
            placeholder={
              acctPhoneNumberErrors
                ? acctPhoneNumberErrors
                : phoneNumberFocused
                  ? ""
                  : "...Enter your mobile here"
            }
            className={acctPhoneNumberErrors ? "red-placeholder" : ""}
            inputClass={`acctVerification-control-phonelibrary-placeholder ${
              acctPhoneNumberErrors ? "field-background-color-error" : ""
            } ${phoneNumberFocused ? "focused" : ""}`}
            value={acctPhoneNumberValue}
            onChange={(value) =>
              handlePhoneInputChange(
                value,
                currentContext,
                acctPhoneNumberErrors,
                setAcctPhoneNumberValue,
                setAcctPhoneNumberErrors
              )
            }
            onFocus={() => setPhoneNumberFocused(true)}
            onBlur={() => setPhoneNumberFocused(false)}
            error={!!acctPhoneNumberErrors.phone_number}
            disableCountryCode={true} // Disable the country code
            inputStyle={{
              width: "100%",
              borderColor: acctPhoneNumberErrors ? "red" : "",
            }}
            helperText={
              acctPhoneNumberErrors.phone_number && "Invalid phone number"
            }
          />
          {!(phoneNumberFocused || acctPhoneNumberValue) && (
            <>
              <CellPhoneIcon
                fillColor="var(--color-white)"
                style={{
                  opacity: 0.68,
                  transition: "opacity 0.2s ease-in-out",
                  position: "absolute",
                  left: "50px", // Position relative to the input field
                  top: "40%",
                  transform: "translateY(-50%)", // Center the icon vertically
                }}
              />
              <span
                className="blinking-cursor-black-color acctverification-mobile-cursor"
                style={{
                  position: "absolute",
                  left: "77px", // Position relative to the input field
                  top: "40%",
                  transform: "translateY(-50%)", // Center the cursor vertically
                }}
              >
                |
              </span>
            </>
          )}
        </div>
        {acctPhoneNumberErrors &&
        acctPhoneNumberErrors !== errorMessages.phone ? (
          <div className="reset-phone-error-message">
            {acctPhoneNumberErrors}
          </div>
        ) : (
          absentPhoneNumberError &&
          absentPhoneNumberError !== errorMessages.phone && (
            <div className="reset-phone-error-message">
              {absentPhoneNumberError}
            </div>
          )
        )}
        <div className="cta-section">
          <InsetBlendTVButton
            id="cancel-button"
            type="button"
            btnText="X Cancel"
            style={{
              width: "fit-content",
              fontWeight: "900",
              fontFamily: "Nunito Sans",
              color: "var(--color-bloodred)",
              padding: "5px",
            }}
            onClick={onCancel}
          />
          <InsetBlendTVButton
            id="update-button"
            type="button"
            image={<CheckMarkIcon width="20" height="20" fillColor="#b03a2e" />}
            btnText="Verify Account"
            style={{
              width: "fit-content",
              fontWeight: "900",
              fontFamily: "Nunito Sans",
              color: "var(--color-bloodred)",
              padding: "5px",
            }}
            onClick={(e) => {
              onVerifyUserAccount(e, contextType);
            }}
          />
        </div>
      </form>
    </div>
  );
};
export default AccountVerification;
