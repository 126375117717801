import React, { useEffect, useState } from "react";
import { useAuth } from "../../components/context/authContext";
import { useNavigate } from "react-router-dom";
import { LeftArrow } from "../../components/icons";
import { SolidColorButton } from "../../components/buttons";
import CurrentAveragedRating from "../../components/starRating/dashboardStarRating";
import { apiFetchRatedSkitsForCurrentUserDashboard } from "../../api/backend/skits";
import { categories } from "../../constants/categories";
import TooltipDots from "../../components/toolTipDots/toolTipDots";
import {
  CatagoriesIcon,
  PencilIcon,
  LightBulbIcon,
  SkitsOlympicLogoIcon,
} from "../../components/icons/index";
import "./currentUserSkitDashboardMediumSmallDevice.scss";

const getCategoryById = (id) => {
  return categories.find((category) => category.id === id);
};

const CurrentUserSkitDashboardMediumSmallDevice = () => {
  const [ratedSkits, setRatedSkits] = useState([]);
  const { currentUser } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    const fetchRatedSkits = async () => {
      if (currentUser) {
        try {
          const targetId = currentUser;
          const skits =
            await apiFetchRatedSkitsForCurrentUserDashboard(targetId);
          setRatedSkits(skits);
        } catch (error) {
          console.error("Error fetching rated skits:", error);
        }
      }
    };

    fetchRatedSkits();
  }, [currentUser]);

  return (
    <div
      id="top"
      className="skitDashboardMediumSmallDevicecurrent-user-skit-dashboard med-small-device-view"
    >
      <SolidColorButton
        solidColorBtnImage={
          <LeftArrow
            height="15px"
            width="15px"
            fillColor="#964b00"
            strokeWidth="4"
            strokeColor="#964b00"
          />
        }
        className="gold-btn"
        solidColorBtnText="Back"
        solidColorBtnFontWeight="800"
        solidColorBtnFontFamily="helvetica"
        solidColorBtnTextFontSize=".75rem"
        solidColorBtnFontColor="#964b00"
        solidColorBtnWidth="100px"
        solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
        onClick={() => navigate(-1)}
      />
      <div
        id="small-med-dashboard-list"
        className="skitDashboardMediumSmallDeviceskits-list"
      >
        {ratedSkits.length > 0 ? (
          ratedSkits.map((skit) => {
            const category = getCategoryById(skit.categoryid);
            return (
              <div
                key={skit.id}
                className="skitDashboardMediumSmallDeviceskit-item"
              >
                <div className="skitDashboardMediumSmallDeviceskit-title">
                  <TooltipDots text={skit.title} maxLength={39} />
                </div>
                <div className="skitDashboardMediumSmallDeviceskit-details">
                  <div className="skitDashboardMediumSmallDevicedetail-item">
                    <div className="skitdash-medsmall-icon-container">
                      <PencilIcon
                        fillColor="var(--color-offWhite)"
                        height="25"
                        width="25"
                      />
                    </div>
                    <p className="skitDashboardMediumSmallDevicedetail-title">
                      Story Line
                    </p>
                    <CurrentAveragedRating
                      rating={skit.storyLineAverageScore}
                    />
                  </div>
                  <div className="skitDashboardMediumSmallDevicedetail-item">
                    <div className="skitdash-medsmall-icon-container">
                      <SkitsOlympicLogoIcon
                        fillColor="var(--color-offWhite)"
                        height="25"
                        width="25"
                        strokeColor="var(--color-offWhite)" // Adjust to your desired stroke color
                        strokeWidth="2" // Increase this value to make the border thicker
                      />
                    </div>
                    <p className="skitDashboardMediumSmallDevicedetail-title">
                      Actor's/(s') Performance
                    </p>
                    <CurrentAveragedRating
                      rating={skit.performanceAverageScore}
                    />
                  </div>
                  <div className="skitDashboardMediumSmallDevicedetail-item">
                    <div className="skitdash-medsmall-icon-container">
                      <LightBulbIcon
                        fillColor="var(--color-offWhite)"
                        height="25"
                        width="25"
                        strokeColor="var(--color-offWhite)" // Adjust to your desired stroke color
                        strokeWidth="2" // Increase this value to make the border thicker
                      />
                    </div>
                    <p className="skitDashboardMediumSmallDevicedetail-title">
                      Originality
                    </p>
                    <CurrentAveragedRating
                      rating={skit.originalityAverageScore}
                    />
                  </div>
                  <div className="skitDashboardMediumSmallDevicedetail-item">
                    <div className="skitdash-medsmall-icon-container">
                      <CatagoriesIcon fillColor="var(--color-offWhite)" />
                    </div>
                    <p className="skitDashboardMediumSmallDevicedetail-title">
                      {category.title}
                    </p>
                    <CurrentAveragedRating rating={skit.categoryAverageScore} />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="skitDashboardMediumSmallDeviceno-skits-found">
            <h2>No Skit Metrics Found..</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrentUserSkitDashboardMediumSmallDevice;
