import React from "react";
import { commaNumberFormat } from "../../../utils/commaNumberFormat";
import { convertPointsToDollars } from "../../../constants/rewardConstants";
import { ImgTopBtn } from "../../buttons";
import { SummationIcon, CalculatedReceiptIcon } from "../../icons";
import CheckBox from "../../checkBoxComponent/checkBox";
import "./checkout.scss"; // Assuming there's a CSS/SCSS file

const PlaceOrderSummary = ({
  orderSubtotal,
  merchantFee,
  orderGrandTotal,
  pointsToUse,
  maxOrderPoints,
  currentAvailablePoints,
  pointsValueInCentsPerDollar,
  agreeToTerms,
  setAgreeToTerms,
  termsError,
  setTermsError,
  userPointsBalance,
  onSliderChange,
  onPlaceOrder,
}) => {
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setAgreeToTerms(isChecked);
    if (isChecked) {
      setTermsError(false); // Reset the error state when checkbox is checked
    }
  };
  return (
    <div className="order-summary">
      {/* Header Section */}
      <div className="order-summary-header-icon">
        <CalculatedReceiptIcon />
        <h2>Order Summary</h2>
      </div>

      {/* Subtotal Section */}
      <div className="ItemSub">
        <h2>Items Subtotal:</h2>
        <span className="subtotal_price">{`$${commaNumberFormat(orderSubtotal)}`}</span>
      </div>

      {/* Merchant Fee Section */}
      <div className="Item_reffer_fee">
        <h2>Merchant Fee:</h2>
        <span className="item_subtotal_fee">
          {`$${commaNumberFormat((orderSubtotal * merchantFee).toFixed(2))} (${(merchantFee * 100).toFixed(0)}%)`}
        </span>
        <hr className="underline_fee" />
      </div>

      {/* Grand Total Section */}
      <div className="Item_grand_total orderSummaryHeader">
        <h2>Grand Total:</h2>
        <span className="Item_grand_total_price">{`$${commaNumberFormat(orderGrandTotal)}`}</span>
      </div>
      {/* Points Slider Section */}
      {currentAvailablePoints > 0 && (
        <>
          <div className="rangeSlider">
            Current Available Points: {currentAvailablePoints} <br />
            <div className="wrapper_points">
              {/* Points Display */}
              <div className="points-container">
                <span className="points-display">
                  {/* ? `${pointsToUse} points applied ($${convertPointsToDollars(pointsToUse).toFixed(2)})` */}
                  {pointsToUse > 0
                    ? `${pointsToUse} points applied`
                    : "No points applied"}
                </span>
              </div>
              {/* Slider and Progress Bar */}
              <div className="slider_points">
                <div className="slider-track-wrapper">
                  <div className="track" />
                  <div
                    className="progress-bar_points"
                    style={{
                      width: `${(pointsToUse / (maxOrderPoints || userPointsBalance)) * 100}%`,
                    }}
                  />
                  <input
                    type="range"
                    onChange={(e) => onSliderChange(Number(e.target.value))}
                    min="0"
                    value={pointsToUse}
                    id="points_balance_range"
                    max={maxOrderPoints || userPointsBalance}
                    step="1"
                  />
                </div>
                {/* Money Display */}
                <div className="money-display-wrapper">
                  <span className="money-display">
                    {`-$${convertPointsToDollars(pointsToUse).toFixed(2)}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Guide Section */}
          <div className="text_guide_range">
            <p>Slide right to apply points</p>
          </div>
        </>
      )}
      <hr className="underLine" />

      {/* Total Due Section */}
      <div className="total_due">
        <h1 id="totalDue">Total Due:</h1>
        <h4 className="due_amount">{`$${commaNumberFormat(
          (orderGrandTotal - pointsToUse * pointsValueInCentsPerDollar).toFixed(
            2
          )
        )}`}</h4>
      </div>

      {/* Place Order Button */}
      <div className="button-wrapper">
        <ImgTopBtn
          image={<SummationIcon style={{ paddingRight: "5px" }} />}
          imgTopBtnText="Place Your Order"
          onClick={onPlaceOrder}
          imgTopBtnWidth="200px"
          imgTopBtnFontColor="var(--color-bloodred)"
          position="relative"
          extraImgTopBtnStyles={{
            backgroundImage: "linear-gradient(120deg, #e0e0e0 0%, #fefefe 80%)",
          }}
        />
      </div>
      {/* Terms Agreement Section */}
      <div className="termsCheckboxContainer">
        {!agreeToTerms && termsError && (
          <p className="errorText">You must agree to the terms to proceed.</p>
        )}
        <CheckBox
          label="I agree to the terms and conditions: card on file will be used if split payment is not available. Points need to cover 50% of the order total for split payment; otherwise, points will be applied if they cover the full amount. You can cancel autopay anytime in the campaign settings."
          name="agreeToTerms"
          checked={agreeToTerms}
          onChange={handleCheckboxChange}
          className={termsError ? "terms-border-error error-border" : ""}
          />  
      </div>
      <div className="termContainer_subtotalSection">
        <p className="termsPaymentDisclaimer">
          By placing your order you agree to SkitsOlympic's
          <a className="paymentTermsLink" href="#">
            {" "}
            privacy notice, conditions of use{" "}
          </a>{" "}
          and all the terms found <a href="#">here</a>.
        </p>
      </div>
    </div>
  );
};

export default PlaceOrderSummary;
