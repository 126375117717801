import {
  ComedyIcon,
  DramaIcon,
  ActionIcon,
  MusicalIcon,
  RomanceIcon,
  FantasyIcon,
  SuspenseIcon,
  WesternIcon,
} from "../components/icons";

export const channelToCategoryMap = {
  1: 500, // Comedy
  2: 200, // Drama
  3: 100, // Action
  4: 700, // Musical
  5: 600, // Romance
  6: 50, // Fantasy
  7: 10, // Suspense
  8: 330, // Western
};

export const categories = [
  {
    id: 600,
    title: "Romance",
    description: "(How relatable?/Heartstrings pulled)",
  },
  {
    id: 100,
    title: "Action",
    description: "(Was it action packed?)",
  },
  {
    id: 10,
    title: "Suspense",
    description: "(How suspenseful?)",
  },
  {
    id: 700,
    title: "Musical",
    description: "(How emotional/catchy?)",
  },
  {
    id: 330,
    title: "Western",
    description: "(How believable?)",
  },
  {
    id: 50,
    title: "Fantasy",
    description: "(How imaginative?)",
  },
  {
    id: 200,
    title: "Drama",
    description: "(How dramatic?)",
  },
  {
    id: 500,
    title: "Comedy",
    description: "(How funny?)",
  },
];

export const channels = [
  { id: 1, name: "Comedy", Icon: ComedyIcon },
  { id: 2, name: "Drama", Icon: DramaIcon },
  { id: 3, name: "Action", Icon: ActionIcon },
  { id: 4, name: "Musical", Icon: MusicalIcon },
  { id: 5, name: "Romance", Icon: RomanceIcon },
  { id: 6, name: "Fantasy", Icon: FantasyIcon },
  { id: 7, name: "Suspense", Icon: SuspenseIcon },
  { id: 8, name: "Western", Icon: WesternIcon },
];
