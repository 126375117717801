import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import eventBus from "../../utils/eventBus";
import { useNotifications } from "../../components/context/notificationContext";
import Remote from "../../components/remote/remote";
import UploadVideoForm from "../../components/forms/uploadVideoForm";
import { useAuth } from "../../components/context/authContext";
import {
  apiGetListFromSourceID,
  apiRecordUserSkitNotInterested,
  apiFetchUserSkitNotInterested,
  apiRecordUserSusbcribeActivity,
  apiUnSusbcribeUser,
  apiFetchUserSubscriptionStatus,
} from "../../api/backend/user";
import {
  apiRewardSkitSubscriberPromo,
  apiDeleteSkitIdFromSourceIdAction,
} from "../../api/backend/skits";
import { useUserSourceDetails } from "../../components/hooks/useUserDetails";
import { useCurrentUserAcctDetails } from "../../components/hooks/useUserDetailsProfileClick";
import { useVideoFormStateCallBackController } from "../../controller/videoFormCallBackController";
import { fetchUserDetails } from "../../controller/profileUpdateController";
import { renderUserThumbnail } from "../../controller/userThumbnail";
import Loader from "../../components/loader/loader";
import ClapperBoard from "../../components/clapperBoard/animatedClapperBoard";
import {
  SmallPlainInsetBtn,
  GlassBtn,
  StripeAnimationBtn,
} from "../../components/buttons";
import {
  MegaphoneIcon,
  PencilIcon,
  BellIcon,
  DownArrowIcon,
  NoBellIcon,
  EyeIcon,
  LineChartIcon,
  AdsDashboardManagerIcon,
} from "../../components/icons";
import SkitAdCard from "../../components/skitAd/skitAdCard";
import AcctHolderUpdateModal from "../../components/forms/acctHolderUpdateModal";
import Tv from "../../components/tv/tv";
import { SingularPlural } from "../../utils/wordUtils";
import "react-phone-input-2/lib/material.css";
import "./userProfilePage.scss";

const UserProfilePage = () => {
  const { currentUser, handleLogout } = useAuth(); // the person who is currently logged in
  const { fetchNotifications } = useNotifications(); // when user subscribes we will refetch notifications for current user set in the context folder for current user that is imported in the notification context for higher level easy passing props
  const {
    sourceUserAcctDetails,
    getSourceAccountHolderDetailsById, // get the account details from the user id and will later compare with current user id to see who is the account holder for permissions
  } = useUserSourceDetails();
  const { fetchDetailsOnProfileClick } = useCurrentUserAcctDetails(); // this does not need to be compared for account holder becaause the current user is the account holder for clicking on the profile button
  const {
    openSkitUploadForm,
    setOpenSkitUploadForm,
    openVideoForm,
    afterUploadCloseModal,
  } = useVideoFormStateCallBackController();
  const [accountHolder, setAccountHolder] = useState(false);
  const { userId } = useParams(); // This user id comes from the slider button that is passed, user can be logged in or not logged in
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isClapping, setIsClapping] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [skitsData, setSkitsData] = useState([]);
  const [colorPaletteVisible, setColorPaletteVisible] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [noSkitsAvailable, setNoSkitsAvailable] = useState(false);
  const [notInterested, setNotInterested] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState("subscribe");
  const [showDropdown, setShowDropdown] = useState(false);
  const [thumbnailPreference, setThumbnailPreference] = useState(null);
  const [currentEmotionId, setCurrentEmotionId] = useState(null);
  const onLogoutClick = () => {
    handleLogout();
  };
  const fetchSubscriptionStatus = async () => {
    if (!currentUser || !userId) return; // Ensure both IDs are present
    try {
      const response = await apiFetchUserSubscriptionStatus({
        sourceId: currentUser,
        targetId: userId,
      });
      const data = response.data; // Correctly extract data from the response object
      if (data.success) {
        setSubscriptionStatus(data.isSubscribed ? "subscribed" : "subscribe");
      } else {
        console.error("Failed to fetch subscription status: ", data.message);
        // Handle the case where fetching status fails but the server responds (e.g., 500 error)
        setError("Failed to load subscription status");
      }
    } catch (error) {
      console.error("Error fetching subscription status: ", error);
      setError("Failed to load subscription status");
      // Handle the case where the server does not respond or the request is malformed
    }
  };
  useEffect(() => {
    fetchSubscriptionStatus();
  }, [currentUser, userId]); // Depend on currentUser and userId to refetch when they change

  const handleSubscribeClick = async () => {
    setLoading(true);
    if (subscriptionStatus === "subscribe") {
      try {
        const subscriptionResponse = await apiRecordUserSusbcribeActivity({
          sourceId: currentUser,
          activity: "subscribed",
          targetId: userId,
          notificationMessage: "You have a new subscriber!", // Pass notificationMessage here
        });
        if (subscriptionResponse.data && subscriptionResponse.data.success) {
          const promoResponse = await apiRewardSkitSubscriberPromo({
            sourceId: currentUser,
            targetId: userId,
          });
          if (promoResponse.data && promoResponse.data.success) {
            setSubscriptionStatus("subscribed");
            fetchNotifications();
            fetchUserDetailsController();
            if (promoResponse.data.skit) {
              eventBus.emit("skitPromoted", null, promoResponse.data.skit); // Emit the event only if a skit is promoted
              // updateSkitsData(); // Optionally invoke the update function
            }
          } else {
            console.error(
              "Failed to promote skit:",
              promoResponse.data.message
            );
          }
        } else {
          console.error(
            "Failed to subscribe:",
            subscriptionResponse.data.message
          );
        }
      } catch (error) {
        console.error("Error during subscription process:", error);
      }
    } else if (subscriptionStatus === "subscribed") {
      setShowDropdown(true);
    }
    setLoading(false);
  };

  const handleUnsubscribeClick = async () => {
    try {
      const response = await apiUnSusbcribeUser({
        sourceId: currentUser,
        activity: "unsubscribed",
        targetId: userId,
      });
      if (response.data && response.data.success) {
        setSubscriptionStatus("subscribe");
        setShowDropdown(false); // Always hide dropdown after action
        fetchUserDetailsController(); // Re-fetch user details to update profileData
      } else {
        console.error("Failed to unsubscribe:", response.message);
      }
    } catch (error) {
      console.error("Error unsubscribing:", error);
    }
  };

  // this function will handle file size and type allowed

  // this function will prevent the skit(s) from displaying to the current user since it is recorded as not interested in the user's activity log
  const fetchCurrentUserNotInterestedActivityLogs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiFetchUserSkitNotInterested({
        sourceId: currentUser,
      });
      if (response && response.data) {
        setNotInterested(response.data.map((log) => log.relatedId));
      }
    } catch (error) {
      console.error("Failed to fetch 'not interested' logs:", error);
      setError("Failed to load 'not interested' logs");
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  // // Use useEffect to call the function on mount or when the notInterested parameter chaanges
  useEffect(() => {
    console.log("notInterested updated", notInterested);
  }, [notInterested]);

  // allows the user to record not interested in the database from a button
  const handleNotInterested = useCallback(
    async (skit) => {
      try {
        const response = await apiRecordUserSkitNotInterested({
          sourceId: currentUser,
          activity: "notInterested",
          relatedId: skit.id,
          targetId: skit.sourceId,
        });
        console.log("Response from not interested API:", response);
        if (response.data && response.data.success) {
          // Adjusted to check response.data.success
          console.log("Current notInterested before update:", notInterested);
          setNotInterested((prev) => [...prev, skit.id]); // Adds the skit ID to the notInterested array
          console.log(
            "Skit is now marked as not interested and should be hidden:",
            skit.id
          );
        }
      } catch (error) {
        console.error("Error marking skit as not interested:", error);
        setError("Failed to mark skit as not interested");
      }
    },
    [currentUser]
  );

  const fetchUserDetailsController = async () => {
    await fetchUserDetails({
      currentUser,
      userId,
      setProfileData,
      setAccountHolder,
      setThumbnailPreference,
      setCurrentEmotionId,
      setLoading,
      fetchDetailsOnProfileClick,
      getSourceAccountHolderDetailsById,
      setError,
      setSkitsData,
      setNoSkitsAvailable,
    });
  };

  // fetch user details on mount or when userId/currentUser changes
  useEffect(() => {
    fetchUserDetailsController();
  }, [
    userId,
    currentUser,
    fetchDetailsOnProfileClick,
    getSourceAccountHolderDetailsById,
  ]);

  // Use the fetched data to decide which user's details to display
  // also target user when current user is subscribing or unsubscribing so in this context current user is source id for subscribed activity log
  useEffect(() => {
    // If viewing another user's profile and their details are loaded
    // user id is not equal to current user
    if (userId !== currentUser && sourceUserAcctDetails) {
      setProfileData(sourceUserAcctDetails);
      setThumbnailPreference(sourceUserAcctDetails.thumbnailPreference);
      setCurrentEmotionId(sourceUserAcctDetails.emotionconId);
      setAccountHolder(false);
    }
  }, [userId, currentUser, sourceUserAcctDetails]);

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleClap = async () => {
    setIsClapping(true);
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const response = await apiGetListFromSourceID(profileData.id);
      if (response.data && Array.isArray(response.data.skits)) {
        setSkitsData(response.data.skits); // Use response.data.skits, not response.data
        setNoSkitsAvailable(response.data.skits.length === 0);
      } else {
        console.error("Unexpected response structure:", response);
        setNoSkitsAvailable(true);
      }
    } catch (error) {
      console.error("Failed to fetch skits:", error);
      setSkitsData([]); // Fallback to an empty array
    } finally {
      setIsClapping(false);
      setLoading(false);
    }
  };
  // Toggle edit mode function
  const toggleEditMode = () => {
    setEditMode((prevMode) => !prevMode);
  };
  const handleDeleteSourceIdRelatedIdSkit = (e, skitId) => {
    console.log("Attempting to delete skit with ID:", skitId); // Log skitId

    apiDeleteSkitIdFromSourceIdAction({ skitId })
      .then((response) => {
        if (response.status === 200) {
          console.log("Skit deleted successfully:", skitId); // Log success message
          // Assuming a successful delete operation
          const updatedSkitsData = skitsData.filter(
            (skit) => skit.id !== skitId
          );
          setSkitsData(updatedSkitsData);
        } else {
          console.error("Failed to delete skit:", response); // Log failure response
          alert("Failed to delete the skit");
        }
      })
      .catch((error) => {
        console.error("Error deleting skit:", error); // Log error details
        alert("An error occurred while deleting the skit");
      });
  };

  // Use useEffect to call the function on mount or when currentUser changes
  useEffect(() => {
    fetchCurrentUserNotInterestedActivityLogs();
  }, [fetchCurrentUserNotInterestedActivityLogs]);

  // console.log(profileData); // Right before the return statement in the Skits component

  const toggleColorPaletteVisibility = () => {
    setColorPaletteVisible(!colorPaletteVisible);
  };
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setSticky(scrollTop > 100); // You might adjust the scroll threshold as needed
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (profileData) {
      console.log("profileData:", profileData);
      console.log("profileData userId:", profileData.id);
    }
  }, [profileData]);

  return (
    <div id="userProfilePage">
      <div
        className={`${colorPaletteVisible ? "glassTray" : "topRemoteUserProfile"} ${sticky ? "sticky" : "topRemoteUserProfile"}`}
      >
        <Remote
          showChannels={false}
          onUploadClick={openVideoForm}
          toggleColorPaletteVisibility={toggleColorPaletteVisibility}
          colorPaletteVisible={colorPaletteVisible}
          setColorPaletteVisible={setColorPaletteVisible}
          onClick={onLogoutClick}
        />
      </div>
      {openSkitUploadForm && (
        <UploadVideoForm
          key={Date.now()} // Each time the button is clicked, a new key is generated
          isUploadVideoFormOpen={openSkitUploadForm}
          closeModal={() => setOpenSkitUploadForm(false)} // Directly setting state to close
          currentUser={currentUser}
          afterModalClose={afterUploadCloseModal}
        />
      )}
      {profileData && !accountHolder && (
        <div className="usernameAndSubscribeWrapper">
          <p className="username">{profileData.username}</p>
          <div className="subscribeContainer">
            <StripeAnimationBtn
              stripeAnimationBtnImage={
                <BellIcon
                  height="20px"
                  width="20px"
                  style={{ marginRight: "3px" }}
                />
              }
              extraStyles={{
                width: "fit-content",
                color: "rgb(176, 58, 46)",
                textShadow: "black 0px 0px",
                fontSize: "1rem",
                lineHeight: "14px",
                fontWeight: "700",
                fontFamily: "Roboto, sans-serif",
                textDecoration: "none",
                border: "1.5px solid var(--color-gold)",
                borderRadius: "30px",
                whiteSpace: "nowrap",
                backgroundImage:
                  "linear-gradient(0deg, rgb(216, 217, 219) 0px, rgb(255, 255, 255) 80%, rgb(253, 253, 253))",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                height: "30px",
                padding: "5px",
              }}
              stripeAnimationBtnText={
                subscriptionStatus === "subscribed"
                  ? "Subscribed"
                  : subscriptionStatus === "confirm-unsubscribe"
                    ? "Unsubscribe"
                    : "Subscribe"
              }
              icon={
                subscriptionStatus === "subscribed" ? (
                  <DownArrowIcon
                    fillColor="var(--color-bloodred)"
                    width="15" // Updated from 8.906 to 17.812 (doubled)
                    height="23" // Updated from 12.592 to 25.184 (doubled)
                    style={{ marginLeft: "5px", marginTop: "5px" }} // Ensuring it aligns nicely with the text
                  />
                ) : null
              }
              width="fit-content"
              onClick={(e) => {
                handleSubscribeClick(e);
              }}
            />
            {showDropdown && subscriptionStatus === "subscribed" && (
              <div
                style={{
                  display: "flex", // Ensures flexbox layout
                  alignItems: "center", // Vertically centers the content within the container
                  justifyContent: "center", // Horizontally centers the content
                  position: "absolute",
                  // borderRadius: "30px",
                  backgroundImage:
                    "linear-gradient(0deg, rgb(216, 217, 219) 0px, rgb(255, 255, 255) 80%, rgb(253, 253, 253))",
                  width: "fit-content",
                  border: "1.5px solid var(--color-gold)",
                  //www.facebook.com/photo/?fbid=830182742297524&set=a.659199489395851&__cft__%5B0%5D=AZXrJnl_nLirHQ0ySHxwwpfJq-xE3A6e8Y5d8iL-5Xx7N685-c3sccmhoXASfLxBBrRl4qPbxU-U3LLpUfMZ7Szbo1Pg_hxWvtQgA6MrdAnzKb9kLymD80_igjxtMdd8LWbESlGk5hJl9QBFW25CJHIVnc5WgDu8ZahF_ZW4YDuxzMqqGWCDKNKxEwnuL6vHBnuyvRX-kALOOsOCYnY46kbp&__tn__=EH-R backgroundImage:
                  https:
                    "linear-gradient(0deg, rgb(216, 217, 219) 0px, rgb(255, 255, 255) 80%, rgb(253, 253, 253))",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  zIndex: 7,
                  color: "var(--color-bloodred)", // Apply text color to all text inside
                  fontSize: "1rem",
                  fontWeight: "700",
                  textShadow: "0px 0px black",
                  padding: "5px", // Adds padding around the content for better spacing
                }}
              >
                <NoBellIcon
                  height="20px"
                  width="20px"
                  style={{ marginRight: "3px" }} // Adds spacing between the icon and text
                />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handleUnsubscribeClick}
                >
                  Unsubscribe
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {accountHolder && (
        <div className="glassBtnUserProfileDiv">
          <HashLink
            to={`/user-profile/${currentUser}/skit-dashboard#top`}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
          >
            <GlassBtn
              glassBtnImage={
                <LineChartIcon
                  height="15px"
                  width="15px"
                  fillColor="var(--color-gold)"
                />
              }
              className="glass-btn"
              glassRefClassName="userProfileGlassBtnRef"
              glassBtnText="Skit Dashboard"
              glassBtnWidth="150px"
              glassBtnTextFontSize="1rem"
              glassBtnFontWeight="900"
              glassBtnHeight="20px"
              glassBtnFontColor="var(--color-gold)"
              glassBtnTextDecorationColor="var(--color-gold)"
              hi2Style={{
                transform: "rotate(90deg) translate(-60px, 75px)",
              }}
              hi3Style={{
                transform: "rotate(90deg) translate(-60px, -75px)",
              }}
            />
          </HashLink>
          <HashLink
            to={`/user-profile/${currentUser}/ads-dashboard#adsManagerTop`}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
          >
            <GlassBtn
              glassBtnImage={
                <AdsDashboardManagerIcon
                  height="15px"
                  width="15px"
                  fillColor="var(--color-yellow)"
                />
              }
              className="glass-btn"
              glassRefClassName="adsManagerGlassBtnRef"
              glassBtnText="Ads Manager"
              glassBtnWidth="150px"
              glassBtnHeight="20px"
              glassBtnTextFontSize="1rem"
              glassBtnFontWeight="900"
              glassBtnFontColor="var(--color-yellow)"
              glassBtnTextDecorationColor="var(--color-yellow)"
              hi2Style={{
                transform: "rotate(90deg) translate(-60px, 75px)",
              }}
              hi3Style={{
                transform: "rotate(90deg) translate(-60px, -75px)",
              }}
            />
          </HashLink>
        </div>
      )}
      {accountHolder && (
        <div className="acctHldrProfileControlWrapper">
          <HashLink
            to={`/user/subscribers#subscriptionsTop`}
            scroll={(el) => scrollWithOffset(el, 50)}
            style={{
              textDecoration: "none",
              color: "var(--color-gold)", // Your chosen styles
            }}
          >
            <SmallPlainInsetBtn
              image={
                <EyeIcon
                  height="15px"
                  width="15px"
                  fillColor="var(--color-bloodred)"
                  style={{ marginRight: "3px" }}
                />
              }
              text="View Subscriptions"
              width="fit-content"
              style={{
                fontSize: "1rem",
              }}
            />
          </HashLink>
          <SmallPlainInsetBtn
            image={
              <PencilIcon
                height="15px"
                width="15px"
                style={{ marginRight: "3px" }}
              />
            }
            text="Edit Profile"
            width="fit-content"
            style={{
              fontSize: "1rem",
            }}
            onClick={toggleEditMode}
          />
        </div>
      )}
      {profileData && <h2 className="username">{profileData.userNameValue}</h2>}
      {profileData && (
        <>
          <div className="tv-wrapper">
            <div className="subscribersListLink">
              <Tv thumbnail={renderUserThumbnail(profileData)} />
            </div>
            <div className="Action">
              <ClapperBoard isClapping={isClapping} />
              <SmallPlainInsetBtn
                image={
                  <MegaphoneIcon
                    height="15px"
                    width="15px"
                    style={{ marginRight: "3px" }}
                  />
                }
                text="Action"
                width="fit-content"
                style={{
                  fontSize: "1rem",
                }}
                onClick={handleClap}
              />
            </div>
          </div>
          <HashLink
            to={`/user/${userId}/subscribers#subscribersTop`}
            scroll={(el) => scrollWithOffset(el, 50)}
            style={{
              textDecoration: "none",
            }}
          >
            <SmallPlainInsetBtn
              image={
                <EyeIcon
                  height="15px"
                  width="15px"
                  fillColor="var(--color-bloodred)"
                  style={{ marginRight: "3px" }}
                />
              }
              text={`${profileData?.totalSubscribers ?? 0} ${SingularPlural(
                profileData?.totalSubscribers ?? 0,
                "Subscriber"
              )}`}
              width="fit-content"
              style={{
                fontSize: "1rem",
                color: "var(--color-gold)", // Match your styles
              }}
            />
          </HashLink>
          {accountHolder && (
            <AcctHolderUpdateModal
              userId={userId}
              show={editMode} // Use the existing `editMode` to control modal visibility
              onClose={() => setEditMode(false)} // Close modal by updating editMode
              profileData={profileData}
              setProfileData={setProfileData}
              thumbnailPreference={thumbnailPreference}
              setThumbnailPreference={setThumbnailPreference}
              currentUser={currentUser}
              setEditMode={setEditMode}
              setLoading={setLoading} // Using the actual setLoading function
            >
              <div className="modalContent">
                {profileData && (
                  <p className="username">Username: {profileData.username}</p>
                )}
                {/* Add the rest of your modal content and buttons here */}
              </div>
            </AcctHolderUpdateModal>
          )}
          {loading && <Loader />}
          <div className="skit-list-wrapper">
            <div id="skitList">
              {noSkitsAvailable ? (
                <div className="no-skits-message">
                  Skits coming soon to a device near you...
                </div>
              ) : (
                skitsData
                  .filter((skit) => !notInterested.includes(skit.id))
                  .map((skit) => (
                    <SkitAdCard
                      key={skit.id}
                      specificSkitData={skit}
                      currentUser={currentUser}
                      onDelete={
                        () => handleDeleteSourceIdRelatedIdSkit(null, skit.id) // Corrected line
                      } // Passing skit.id to the delete handler
                      onNotInterested={() => handleNotInterested(skit)}
                      isUploadVideoFormOpen={openSkitUploadForm} // Pass the prop here
                    />
                  ))
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default UserProfilePage;
