import React, { useState, useEffect, useRef } from "react";
import { SmileyBubblyChat, XIcon } from "../icons/index";
import {
  SmallPlainInsetBtnEventListener,
  ImgTopBtn,
  PlainBtn,
} from "../buttons";
import AllEmoteSelection from "../emoji/allEmoteSelection";
import { renderCommentWithEmotes } from "../../utils/emoteUtils";
import "./skitCommentBox.scss";

const SkitCommentBox = ({
  onSubmit,
  skitCommentError,
  setSkitCommentError,
  messageValue,
  setMessageValue,
}) => {
  const [showEmotePicker, setShowEmotePicker] = useState(false);
  const overlayRef = useRef(null);
  const inputRef = useRef(null);
  const [videoCommentFocused, setVideoCommentFocused] = useState(false);

  useEffect(() => {
    const storedEmote = localStorage.getItem("selectedEmote");
    if (storedEmote) {
      const emote = JSON.parse(storedEmote);
      console.log("Stored Emote Retrieved:", emote);
      localStorage.removeItem("selectedEmote");
    }
  }, [showEmotePicker]);

  const createSkitComment = (e) => {
    e.preventDefault();
    if (messageValue.trim() === "") {
      setSkitCommentError("Comment cannot be empty");
      return;
    }
    onSubmit(messageValue); // Pass the current message value to the parent onSubmit handler
    // Note: clearing the message value key value pairs in local storage in the parent handler after the success response
    setMessageValue("");
    setSkitCommentError("");
    setShowEmotePicker(false);
  };

  const cancelSkitComment = () => {
    localStorage.removeItem("messageValue");
    setMessageValue("");
    setSkitCommentError("");
    setShowEmotePicker(false);
  };

  const skitCommentInputWatcher = (e) => {
    const value = e.target.value;
    console.log("Input Changed:", value);
    setMessageValue(value);
    localStorage.setItem("messageValue", value); // Store the input value in local storage
  };

  const handleSelectEmote = (emoteName) => {
    console.log("Emote Selected:", emoteName);
    const updatedMessage = `${messageValue} ${emoteName}`;
    setMessageValue(updatedMessage);
    localStorage.setItem("messageValue", updatedMessage);
    setShowEmotePicker(false);
  };

  const currentPlaceholder = skitCommentError || "Write a comment...";

  const renderOverlay = () => {
    const parts = renderCommentWithEmotes(messageValue);
    console.log("Overlay Parts:", parts);
    return parts.map((part, index) => (
      <span key={index} className="overlay-part">
        {part === " " ? "\u00A0" : part}
      </span>
    ));
  };

  return (
    <div className="skit-comment-box-container blinker-wrapper">
      <form>
        <div className="textarea-wrapper" style={{ position: "relative" }}>
          <textarea
            ref={inputRef}
            value={messageValue}
            onChange={skitCommentInputWatcher}
            placeholder={videoCommentFocused ? "" : currentPlaceholder} // Clear placeholder on focus
            className={`comment-box ${skitCommentError ? "error-placeholder" : ""}`}
            style={{ color: "transparent", caretColor: "transparent" }}
            onFocus={() => {
              setVideoCommentFocused(true);
              setSkitCommentError(""); // Clear the error message on focus
            }}
            onBlur={() => setVideoCommentFocused(false)}
          />
          {!messageValue && !videoCommentFocused && (
            <span className="blinking-cursor-gold-color video-comment-box-cursor">
              |
            </span>
          )}
          <div
            className="overlay"
            ref={overlayRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              pointerEvents: "none",
              color: "black",
              whiteSpace: "pre-wrap",
            }}
          >
            {renderOverlay()}
          </div>
        </div>
        {showEmotePicker && (
          <div className="emote-picker">
            <AllEmoteSelection
              onSelectEmote={handleSelectEmote}
              showAllEmotes={showEmotePicker}
            />
          </div>
        )}
        <div className="comment-buttons">
          <div className="skit-commentBtns-wrapper">
            <SmallPlainInsetBtnEventListener
              className="comment-submit-btn"
              image={
                <SmileyBubblyChat
                  fillColor="#b03a2e"
                  style={{ position: "relative", bottom: "-7px" }}
                />
              }
              style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "1.3rem",
                fontWeight: "900",
                lineHeight: "14px",
              }}
              width="fit-content"
              text="Comment"
              onClick={createSkitComment}
            />
            <PlainBtn
              plainBtnText={showEmotePicker ? "Cancel Reaction" : "Reaction"}
              plainBtnTextFontSize="1.3rem"
              plainBtnHeight="fit-content"
              plainBtnFontFamily="Roboto"
              plainBtnFontWeight="bold"
              plainBtnFontColor="#b03a2e"
              plainBtnBorderRadius="30px"
              onClick={() => setShowEmotePicker(!showEmotePicker)}
              image={
                showEmotePicker ? (
                  <XIcon fillColor="var(--color-bloodred)" />
                ) : (
                  <span role="img" aria-label="heart" className="heart-beat">
                    ❤️
                  </span>
                )
              }
              imageOnTop={false}
              extraStyles={{
                paddingRight: "10px",
                paddingLeft: "10px",
              }}
            />
            <ImgTopBtn
              id="cancelSkitComment"
              imgTopBtnBorderRadius="25px"
              imgTopBtnText="X Cancel"
              imgTopBtnWidth="fit-content"
              imgTopBtnTextFontSize="1.3rem"
              imgTopBtnFontFamily="Roboto-Slab"
              imgTopBtnFontWeight="900"
              imgTopBtnFontColor="var(--color-bloodred)"
              paddingRight="5px"
              paddingLeft="5px"
              extraImgTopBtnStyles={{
                left: "10px",
              }}
              onClick={cancelSkitComment}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SkitCommentBox;
