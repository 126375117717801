import React, { useState, useEffect } from "react";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";
import { commaNumberFormat } from "../../utils/commaNumberFormat";
import { calculateTotalCostAndItems } from "../../api/backend/auth";
import "./neumorphismField.scss";

const NeumorphismFieldMoney = ({
  placeholder,
  label,
  value,
  onChange,
  className,
  contextType,
  totalBudget,
  costPerImpression,
  costPerClick,
  paymentType,
  paymentSubscription,
  setPaymentType,
  setTotalBudget,
  error,
  onFocus,
  onBlur,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [formattedInput, setFormattedInput] = useState(value || "");
  const [totalCost, setTotalCost] = useState(0);
  const [dailyItems, setDailyItems] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  // Function to call backend and update state
  // Function to call backend and update state
  const calculatedCampaignDataBackend = async (budget) => {
    try {
      const expirationDate = localStorage.getItem(
        getStorageKey("campaignExpirationDate", contextType)
      );

      if (!budget || !paymentType || !expirationDate) {
        console.warn("Missing required fields for calculation.");
        return;
      }

      const payload = {
        totalBudget: parseFloat(budget) || 0,
        paymentType,
        costPerImpression: parseFloat(costPerImpression) || 0.01,
        costPerClick: parseFloat(costPerClick) || 0.5,
        paymentSubscription,
        campaignExpirationDate: expirationDate,
      };

      console.log("Payload sent to backend:", payload);

      const response = await calculateTotalCostAndItems(payload);

      if (response?.data) {
        const { dailyCostLimit, calculatedDailyItems, calculatedTotalItems } =
          response.data;

        setTotalCost(Number(dailyCostLimit) || 0);
        setDailyItems(Number(calculatedDailyItems) || 0);
        setTotalItems(Number(calculatedTotalItems) || 0);

        // Save the data to localStorage
        localStorage.setItem(
          getStorageKey("dailyCostLimit", contextType),
          (Number(dailyCostLimit) || 0).toFixed(2)
        );

        if (paymentType === "impressions") {
          localStorage.setItem(
            getStorageKey("dailyImpressionsLimit", contextType),
            calculatedDailyItems || 0
          );
          localStorage.setItem(
            getStorageKey("totalBudgetImpressions", contextType),
            calculatedTotalItems || 0
          );
          localStorage.setItem(
            getStorageKey("costPerImpression", contextType),
            (parseFloat(costPerImpression) || 0.01).toFixed(2)
          );

          // Set clicks-related values to 0
          localStorage.setItem(
            getStorageKey("dailyClicksLimit", contextType),
            0
          );
          localStorage.setItem(
            getStorageKey("totalBudgetClicks", contextType),
            0
          );
          localStorage.setItem(getStorageKey("costPerClick", contextType), 0);
        } else if (paymentType === "clicks") {
          localStorage.setItem(
            getStorageKey("dailyClicksLimit", contextType),
            calculatedDailyItems || 0
          );
          localStorage.setItem(
            getStorageKey("totalBudgetClicks", contextType),
            calculatedTotalItems || 0
          );
          localStorage.setItem(
            getStorageKey("costPerClick", contextType),
            (parseFloat(costPerClick) || 0.5).toFixed(2)
          );

          // Set impressions-related values to 0
          localStorage.setItem(
            getStorageKey("dailyImpressionsLimit", contextType),
            0
          );
          localStorage.setItem(
            getStorageKey("totalBudgetImpressions", contextType),
            0
          );
          localStorage.setItem(
            getStorageKey("costPerImpression", contextType),
            0
          );
        }
      } else {
        console.error("Invalid response from backend.");
      }
    } catch (error) {
      console.error("Error fetching calculated data:", error.message || error);
    }
  };

  // Backend call when paymentType or budget changes
  useEffect(() => {
    if (totalBudget > 0 && paymentType) {
      calculatedCampaignDataBackend(totalBudget);
    }
  }, [paymentType, totalBudget]);

  // Handle input changes
  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/[^\d]/g, "");
    const formattedValue = commaNumberFormat(inputValue);
    onChange(inputValue); // Update the parent state
    setFormattedInput(formattedValue); // Update the local state
    localStorage.setItem(
      getStorageKey("totalBudget", contextType),
      formattedValue || 0
    );
  };

  // Initial load from localStorage
  useEffect(() => {
    const storedBudget = parseFloat(
      localStorage.getItem(getStorageKey("totalBudget", contextType)) || "0"
    );
    const storedPaymentType = localStorage.getItem(
      getStorageKey("paymentType", contextType)
    );

    if (storedBudget > 0 && storedPaymentType) {
      setTotalBudget(storedBudget);
      setPaymentType(storedPaymentType);
      calculatedCampaignDataBackend(storedBudget); // Trigger backend call
    }
  }, []);

  return (
    <div className={`neumorphic-field ${className}`}>
      <label>
        {label}
        <input
          type="text"
          value={
            isFocused || !formattedInput ? formattedInput : `$${formattedInput}`
          }
          onChange={handleInputChange}
          placeholder={isFocused || value ? "" : error || placeholder}
          onFocus={() => {
            setIsFocused(true);
            if (onFocus) onFocus();
          }}
          onBlur={() => {
            setIsFocused(false);
            if (onBlur) onBlur();
          }}
          className={`${error && !isFocused ? "input-error " : ""}`}
        />
      </label>
      {paymentSubscription === "1" && (
        <p className="subtotalCost">
          Daily Cost: ${commaNumberFormat((totalCost || 0).toFixed(2))}
        </p>
      )}
      <p className="campaignTypeServiceItemTotal">
        {paymentType === "impressions"
          ? `Daily Impressions: ${commaNumberFormat(dailyItems)}`
          : `Daily Clicks: ${commaNumberFormat(dailyItems)}`}
      </p>
      {paymentType === "impressions" && (
        <p className="campaignTypeServiceItemTotal">
          Total Impressions: {commaNumberFormat(totalItems)}
        </p>
      )}
      {paymentType === "clicks" && (
        <p className="campaignTypeServiceItemTotal">
          Total Clicks: {commaNumberFormat(totalItems)}
        </p>
      )}
    </div>
  );
};

export default NeumorphismFieldMoney;
