import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormType } from "../../constants/contextState";
import categoryData from "../../constants/many-manyCat.json";
import ModalControlRouterWrapper from "../../components/forms/campaignAuthorizationForm/modalControlRouterWrapper";
import CampaignStateManagerFlowForms from "../../components/forms/campaignAuthorizationForm/campaignStateManagerFlowForms.jsx";
import DatePicker from "../../components/datePicker/datePicker";
import PointsDashBoard from "../../components/rewardPoints/pointsDashBoard.jsx";
import { useAuth } from "../../components/context/authContext.jsx";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import {
  GlassBtn,
  ImgTopBtn,
  SolidColorButton,
  SmallPlainInsetBtn,
} from "../../components/buttons";
import {
  BroadCastTowerIcon,
  ClipBoardCheckIcon,
  DesktopComputerIcon,
  CatagoriesIcon,
  CashRegisterIcon,
  MagnifyingGlassIcon,
  XIcon,
  CalendarIcon,
  EyeIcon,
  EyeSlashIcon,
  LeftArrow,
} from "../../components/icons";
import {
  apiFetchCurrentUserDetails,
  apiFetchCampaigns,
} from "../../api/backend/user.js";
import CampaignMetricsCard from "../../components/campaignMetricsCard/campaignMetricsCard.jsx";
import ErrorMessageModal from "../../components/errorMessageModal/errorMessageModal.jsx";
import Loader from "../../components/loader/loader.jsx";
import useDebounce from "../../components/hooks/useDebounce.js";
import "./adsManagerDashboard.scss";

const CurrentUserAdsManagerDashboard = ({ updateCampaign }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const navigate = useNavigate();
  const [lastStartedAt, setStartedCreatedAt] = useState(null); // Tracks the last createdAt value
  const [hasMore, setHasMore] = useState(true); // Whether there are more campaigns to fetch
  const { userAttributes, setUserAttributes, currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // the context is initialized based on the updateCampaign prop, which is set to false so by default it is set on createCampaign
  //   If updateCampaign is true, the context is set to "updateCampaign".
  // If updateCampaign is false, the context is set to "createCampaign"
  const currentContext = updateCampaign ? "updateCampaign" : "createCampaign";
  const [contextType, setContextType] = useState(currentContext);
  const [searchTermValue, setSearchTermValue] = useState("");
  const debouncedSearchTerm = useDebounce(searchTermValue, 300); // this is a hook imported used for filtering
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [isCampaignSearchFocused, setCampaignSearchFocused] = useState(false);
  const [dateRange, setDateRange] = useState({ start: "", end: "" });
  const [currentForm, setCurrentForm] = useState(FormType.CampaignAdModal);
  const [formType, setFormType] = useState(FormType.CampaignAdModal);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [categories, setCategories] = useState(categoryData.parents); // Assuming your parent categories are in the "parents" array
  const [modalTitle, setModalTitle] = useState("");
  const [modalIcon, setModalIcon] = useState(null);

  // Fetching and setting initial values from local storage for the date filters
  const [startDateValue, setStartDateValue] = useState(""); // Initialize as an empty string
  const [endDateValue, setEndDateValue] = useState(""); // Initialize as an empty string
  const [showDates, setShowDates] = useState(false); // parent is managing the filters so if it is false then you will see the button that says dates once you press it then you will see the fields

  const [dateOrderError, setDateOrderError] = useState(false);

  const [subCatMenuData, setSubCatMenuData] = useState({
    subCatMenuOpen: false,
    data: { subcategories: [] },
    categoryName: "",
    categoryIcon: "",
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await apiFetchCurrentUserDetails(currentUser); // Fetch user details from API

        if (response && response.data) {
          setUserAttributes((prevAttributes) => ({
            ...prevAttributes, // Preserve existing attributes
            pointsBalance: parseFloat(
              response.data.currentPointsBalance
            ).toFixed(2), // Format points balance to 2 decimals
            currentRewardLevel: response.data.currentRewardLevel, // Update reward level
          }));
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false); // Ensure loading stops in both success and error cases
      }
    };

    fetchUserDetails(); // Invoke the function to fetch user details
  }, [currentUser]); // Only run this effect when currentUser changes
  // Only run this effect when currentUser changes

  useEffect(() => {
    const storedData = localStorage.getItem("subCatMenuData");
    if (storedData) {
      setSubCatMenuData(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    setCategories(categoryData.parents);
  }, []);

  const searchValuesCollected = (event) => {
    setSearchTermValue(event.target.value);
  };

  const handleSaveAd = (updatedAd) => {
    setCampaign((prevCampaigns) => {
      // Check if this is an existing campaign (by id) or a new one
      const isExistingCampaign = prevCampaigns.some(
        (campaign) => campaign.id === updatedAd.id
      );

      if (isExistingCampaign) {
        // If it's an existing campaign, update the matching campaign
        return prevCampaigns.map(
          (campaign) =>
            campaign.id === updatedAd.id
              ? { ...campaign, ...updatedAd } // Merge updates into the matching campaign
              : campaign // Keep other campaigns unchanged
        );
      } else {
        // If it's a new campaign (no matching id), add it to the list
        return [
          ...prevCampaigns,
          { id: Date.now(), ...updatedAd }, // Assign a new id if the campaign is new
        ];
      }
    });

    setCurrentForm(FormType.CategoryNameFormatSearch);
    setFormType(FormType.CategoryNameFormatSearch);
  };

  const saveSetCampaignAmount = (paymentDetails) => {
    setCampaign((prevCampaigns) => {
      // If there are no campaigns yet, create the first campaign
      if (prevCampaigns.length === 0) {
        return [{ ...paymentDetails, id: Date.now() }]; // Create the first campaign with a new id
      }

      // Otherwise, update an existing campaign or add a new one
      const campaignExists = prevCampaigns.some(
        (campaign) => campaign.id === paymentDetails.id
      );

      if (campaignExists) {
        // Update the existing campaign
        return prevCampaigns.map((campaign) =>
          campaign.id === paymentDetails.id
            ? { ...campaign, ...paymentDetails }
            : campaign
        );
      } else {
        // Add a new campaign if it doesn't already exist
        return [...prevCampaigns, { ...paymentDetails, id: Date.now() }];
      }
    });

    // Move to the next form step (Checkout)
    setCurrentForm(FormType.Checkout);
    setFormType(FormType.Checkout);
  };

  const handleCategoryClick = (categoryName, categoryId) => {
    const category = categoryData.parents.find(
      (parent) => parent.id === categoryId
    );
    const subcategories = categoryData.childs.filter((child) =>
      child.parent_categories.includes(categoryId)
    );
    if (category) {
      localStorage.setItem(
        "subCatMenuData",
        JSON.stringify({
          idCategory: categoryId,
          subCatMenuOpen: true,
          data: { subcategories },
          categoryName: category.name,
          categoryIcon: category.iconPath,
        })
      );
      setSubCatMenuData({
        idCategory: categoryId,
        subCatMenuOpen: true,
        data: { subcategories },
        categoryName: category.name,
        categoryIcon: category.iconPath,
      });
      setCurrentForm(FormType.RelationalCategoryMenuPopup);
      setFormType(FormType.RelationalCategoryMenuPopup);
    }
  };

  const handleSubcategoryClick = (subcategoryId, subcategoryName) => {
    const key = getStorageKey("adCategoryPromo", contextType);
    // Store both subcategoryId and subcategoryName in local storage
    localStorage.setItem(
      key,
      JSON.stringify({ subcategoryId, subcategoryName }) // Do not overwrite this
    );

    setSubCatMenuData({ subCatMenuOpen: false, data: { subcategories: [] } });
    setCurrentForm(FormType.CampaignAmount);
    setFormType(FormType.CampaignAmount);
  };

  const handlePromoteAllClick = (categoryId, categoryName) => {
    // Log to trace categoryId and categoryName before saving to local storage
    console.log("categoryId:", categoryId, "categoryName:", categoryName);
    const key = getStorageKey("adCategoryPromo", contextType);
    // Store both categoryId and categoryName in local storage
    localStorage.setItem(
      key,
      JSON.stringify({
        relationalCategoryId: categoryId,
        relationalCategoryName: categoryName,
      })
    );
    setCurrentForm(FormType.CampaignAmount);
    setFormType(FormType.CampaignAmount);
  };

  const handleCancelClick = () => {
    const currentContextPrefixes = ["createCampaign", "updateCampaign"];

    // Loop through all keys in localStorage and remove keys that match our context prefix types
    // for (let i = 0; i < localStorage.length; i++) {
    //   const key = localStorage.key(i);
    //   if (currentContextPrefixes.some(contextType => key.startsWith(`${contextType}_`))) {
    //     localStorage.removeItem(key);
    //   }
    // }
    // Loop through all keys in localStorage and remove keys that match our context types in more human readable form
    for (let key in localStorage) {
      currentContextPrefixes.forEach((prefix) => {
        if (key.startsWith(prefix)) {
          localStorage.removeItem(key);
        }
      });
    }
    // Specific key removal (if needed)
    localStorage.removeItem("subCatMenuData");
    setCurrentForm("createCampaign");
    setShowCreateModal(false);
  };

  const saveAdCategory = () => {
    setCurrentForm(FormType.CampaignAmount);
    setFormType(FormType.CampaignAmount);
  };
  const resetStateOnCampaignSuccess = () => {
    console.log("resetStateOnCampaignSuccess called in Parent!"); // Debugging
    const contextPrefixes = [
      "createCampaign",
      "createCampaign",
      "updateCampaign",
      "updateCampaignPayment",
    ];

    for (let key in localStorage) {
      contextPrefixes.forEach((prefix) => {
        if (key.startsWith(prefix)) {
          console.log(`Clearing key: ${key}`); // Debugging
          localStorage.removeItem(key);
        }
      });
    }

    localStorage.removeItem("subCatMenuData");
    setShowCreateModal(false); // Ensure this properly closes the modal
  };

  const cancelCategoryBackToParentCat = () => {
    localStorage.removeItem("subCatMenuData");
    setCurrentForm(FormType.CategoryNameFormatSearch);
    setFormType(FormType.CategoryNameFormatSearch);
  };

  const handleBackClick = () => {
    switch (currentForm) {
      case FormType.CategoryNameFormatSearch:
        setCurrentForm(FormType.CampaignAdModal);
        setFormType(FormType.CampaignAdModal);
        break;
      case FormType.RelationalCategoryMenuPopup:
        const selectedCategoryKey = getStorageKey(
          "selectedCategory",
          contextType
        );
        const storedCategory = localStorage.getItem(selectedCategoryKey);
        if (storedCategory) {
          const { categoryName, categoryId } = JSON.parse(storedCategory);
          handleCategoryClick(categoryName, categoryId);
        } else {
          setCurrentForm(FormType.CategoryNameFormatSearch);
          setFormType(FormType.CategoryNameFormatSearch);
        }
        break;
      case FormType.CampaignAmount:
        const selectedSubcategoryKey = getStorageKey(
          "selectedSubcategory",
          contextType
        );
        const storedSubcategory = localStorage.getItem(selectedSubcategoryKey);
        if (storedSubcategory) {
          const { subcategoryId, subcategoryName } =
            JSON.parse(storedSubcategory);
          handleSubcategoryClick(subcategoryId, subcategoryName);
        } else {
          const storedCategoryKey = getStorageKey(
            "selectedCategory",
            contextType
          );
          const storedCategoryData = localStorage.getItem(storedCategoryKey);
          if (storedCategoryData) {
            const { categoryName, categoryId } = JSON.parse(storedCategoryData);
            handleCategoryClick(categoryName, categoryId);
          }
        }
        setCurrentForm(FormType.RelationalCategoryMenuPopup);
        setFormType(FormType.RelationalCategoryMenuPopup);
        break;
      case FormType.Checkout:
        setCurrentForm(FormType.CampaignAmount);
        setFormType(FormType.CampaignAmount);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    switch (currentForm) {
      case FormType.CampaignAdModal:
        setModalIcon(
          <DesktopComputerIcon
            width="20"
            height="20"
            fillColor="var(--color-black)"
          />
        );
        setModalTitle("Create New Ad");
        break;
      case FormType.CategoryNameFormatSearch:
        setModalIcon(
          <CatagoriesIcon
            width="20"
            height="20"
            fillColor="var(--color-black)"
          />
        );
        setModalTitle("Select Ad Category");
        break;
      case FormType.RelationalCategoryMenuPopup:
        setModalIcon(
          <CatagoriesIcon
            width="20"
            height="20"
            fillColor="var(--color-black)"
          />
        );
        setModalTitle("Select Ad Sub/Category");
        break;
      case FormType.CampaignAmount:
        setModalIcon(
          <ClipBoardCheckIcon
            width="20"
            height="20"
            fillColor="var(--color-black)"
          />
        );
        setModalTitle("Set Campaign Type & Budget");
        break;
      case FormType.Checkout:
        setModalIcon(
          <CashRegisterIcon width="50" height="50" fillColor="#000" />
        );
        setModalTitle("Checkout, Enter Payment Details");
        break;
      default:
        setModalTitle("Create New Ad");
        break;
    }
  }, [currentForm]);
  const handleStartDateChange = (startDate) => {
    const dateString = startDate ? startDate.toISOString() : "";
    const startDateKey = getStorageKey("startDateValue", currentContext);
    setStartDateValue(startDate);
    localStorage.setItem(startDateKey, dateString);
  };
  const handleEndDateChange = (endDate) => {
    const dateString = endDate ? endDate.toISOString() : "";
    const endDateKey = getStorageKey("endDateValue", currentContext);
    setEndDateValue(endDate); // Update state
    localStorage.setItem(endDateKey, dateString); // Update localStorage
  };
  const clearStartDate = () => {
    const startDateKey = getStorageKey("startDateValue", "eventsList");
    localStorage.removeItem(startDateKey);
    setStartDateValue("");
  };
  const clearEndDate = () => {
    const endDateKey = getStorageKey("endDateValue", "eventsList");
    localStorage.removeItem(endDateKey);
    setEndDateValue("");
  };
  const handleDateErrorChange = (hasError) => {
    console.log("Date order error status:", hasError); // This will log the error status
    setDateOrderError(hasError);
  };
  const toggleShowDates = () => {
    //created independent state active state since both can be active together
    setShowDates((prev) => !prev);
    // Clear local storage and update state when hiding the date pickers
    if (showDates) {
      const startDateKey = getStorageKey("startDateValue", currentContext);
      localStorage.removeItem(startDateKey);
      const endDateKey = getStorageKey("endDateValue", currentContext);
      localStorage.removeItem(endDateKey);
      // Assuming you have state variables like `startDateValue` and `endDateValue`
      setStartDateValue("");
      setEndDateValue("");
      // If additional handlers to notify changes, call them here
    }
  };
  const handleEditCampaign = (campaign) => {
    setContextType("updateCampaign");
    setCampaign(campaign);
    setCurrentForm(FormType.CampaignAdModal);
    setFormType(FormType.CampaignAdModal);
    setShowCreateModal(true);
  };
  const handleDeleteCampaign = (campaignId) => {
    console.log("Delete campaign with id:", campaignId);
    // Add delete logic here
  };
  const handleAutoPayToggle = (campaignId) => {
    setCampaigns((prevCampaigns) =>
      prevCampaigns.map((campaign) =>
        campaign.id === campaignId
          ? { ...campaign, autoPay: !campaign.autoPay }
          : campaign
      )
    );
  };
  // Fetch campaigns on component mount
  const fetchingCampaigns = async () => {
    if (!hasMore || loading) return; // Prevent fetching if no more campaigns or loading

    setLoading(true);
    try {
      const response = await apiFetchCampaigns(currentUser, {
        lastStartedAt, // Pass `null` on the first request
        limit: 10, // Default limit of 10 campaigns
      });

      if (response?.data?.campaigns?.length) {
        setCampaigns((prevCampaigns) => [
          ...prevCampaigns,
          ...response.data.campaigns,
        ]);
        const lastCampaign =
          response.data.campaigns[response.data.campaigns.length - 1];
        setStartedCreatedAt(lastCampaign.startedAt); // Update for subsequent requests
      } else {
        setHasMore(false); // No more campaigns
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    } finally {
      setLoading(false);
    }
  };
  // Load more campaigns
  const fetchMoreCampaigns = () => {
    fetchingCampaigns();
  };

  // Initial load on mount or when `currentUser` changes
  useEffect(() => {
    if (currentUser) {
      fetchingCampaigns();
    }
  }, [currentUser]);

  const filteredCampaigns = campaigns.filter((campaign) => {
    const matchesName = campaign.campaignName
      .toLowerCase()
      .includes(debouncedSearchTerm.toLowerCase());

    const campaignStartDate = new Date(campaign.startedAt);
    const campaignEndDate = campaign.endDate
      ? new Date(campaign.endDate)
      : null;

    const withinDateRange =
      (!dateRange.start || campaignStartDate >= new Date(dateRange.start)) &&
      (!dateRange.end ||
        (campaignEndDate && campaignEndDate <= new Date(dateRange.end)));

    return matchesName && withinDateRange;
  });
  useEffect(() => {
    setDateRange({ start: startDateValue, end: endDateValue });
  }, [startDateValue, endDateValue]);

  return (
    <div id="adsManagerTop" className="adsManagerDashboardContainer">
      <SolidColorButton
        solidColorBtnImage={
          <LeftArrow
            height="15px"
            width="15px"
            fillColor="#964b00"
            strokeWidth="4"
            strokeColor="#964b00"
          />
        }
        className="gold-btn"
        solidColorBtnText="Back"
        solidColorBtnFontWeight="800"
        solidColorBtnFontFamily="helvetica"
        solidColorBtnTextFontSize=".75rem"
        solidColorBtnFontColor="#964b00"
        solidColorBtnWidth="100px"
        solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
        onClick={() => navigate(-1)}
      />
      <PointsDashBoard
        pointsBalance={userAttributes?.pointsBalance || 0} // Use dynamic points balance from userAttributes
        setPointsBalance={(newBalance) =>
          setUserAttributes({ ...userAttributes, pointsBalance: newBalance })
        } // Update points balance logic
        userId={currentUser}
      />
      {/* Additional content for the dashboard */}
      {/* <PointsDashBoard
        pointsBalance={14010} // Pass current points balance (mock value for now)
        setPointsBalance={(newBalance) => console.log(newBalance)} // Update handler (replace with actual logic)
      /> // this is frontend testing just uncomment and change the points to test*/}
      {showCreateModal && (
        <ModalControlRouterWrapper
          title={modalTitle}
          icon={modalIcon}
          // whole expression will be true only if currentForm is not equal to
          // FormType.CampaignAdModal and currentForm is not equal to FormType.RelationalCategoryMenuPopup.
          // Therefore, when currentForm is equal to the other form types, then the back button will display
          onBack={
            currentForm !== FormType.CampaignAdModal &&
            currentForm !== FormType.RelationalCategoryMenuPopup
              ? handleBackClick
              : null
          }
          onCancel={handleCancelClick}
        >
          <CampaignStateManagerFlowForms
            currentForm={currentForm}
            setCurrentForm={setCurrentForm}
            formType={formType}
            setFormType={setFormType}
            updateCampaignAdModal={handleSaveAd}
            categories={categories}
            subCatMenuData={subCatMenuData}
            updateCategoryNameFormatSearch={handleCategoryClick}
            updateCampaignAmount={saveSetCampaignAmount}
            cancelCategoryBackToParentCat={cancelCategoryBackToParentCat}
            onResetStateOnCampaignSuccess={resetStateOnCampaignSuccess}
            contextType={contextType}
            setContextType={setContextType}
            saveAdCategory={saveAdCategory}
            handleSubcategoryClick={handleSubcategoryClick} // Pass this instead
            handlePromoteAllClick={handlePromoteAllClick}
            setErrorMessage={setErrorMessage} // Pass setErrorMessage here
          />
        </ModalControlRouterWrapper>
      )}
      {errorMessage && (
        <ErrorMessageModal
          message={errorMessage}
          onClose={() => setErrorMessage("")} // Clear the error
        />
      )}
      <div className="controls">
        <GlassBtn
          glassBtnImage={
            <BroadCastTowerIcon
              height="15px"
              width="15px"
              fillColor="var(--color-yellow)"
            />
          }
          onClick={() => {
            setShowCreateModal(true);
            setCurrentForm(FormType.CampaignAdModal);
            setFormType(FormType.CampaignAdModal);
            setContextType("createCampaign");
          }}
          glassHoverStyle={{ height: "58px" }}
          className="glass-btn"
          glassRefClassName="createAdGlassBtnRef"
          glassBtnText="Create Campaign"
          glassBtnWidth="200px"
          glassBtnTextFontSize="1rem"
          glassBtnFontWeight="900"
          glassBtnHeight="30px"
          glassBtnFontColor="var(--color-yellow)"
          glassBtnTextDecorationColor="var(--color-yellow)"
          hi2Style={{
            transform: "rotate(90deg) translate(-40px, 100px)",
          }}
          hi3Style={{
            transform: "rotate(90deg) translate(-40px, -100px)",
          }}
        />
        <div className="campaign-search-wrapper blinker-wrapper">
          <input
            id="campaignSearch"
            type="text"
            placeholder="Enter Campaign name and press enter to search..."
            value={searchTermValue}
            onChange={searchValuesCollected}
            onFocus={() => setCampaignSearchFocused(true)}
            onBlur={() => setCampaignSearchFocused(false)}
            className={isCampaignSearchFocused ? "focused" : ""}
          />
          {!(isCampaignSearchFocused || searchTermValue) && (
            <>
              <MagnifyingGlassIcon
                fillColor="var(--color-yellow)"
                width="24px"
                height="24px"
                className="search-icon"
              />
              <span className="blinking-cursor-gold-color campaign-cursor">
                |
              </span>
            </>
          )}
        </div>
        {!showDates && (
          <ImgTopBtn
            image={
              <CalendarIcon
                height="20px"
                width="20px"
                style={{ paddingRight: "5px" }}
              />
            }
            onClick={() => setShowDates(true)}
            imgTopBtnText="Press to Filter by Date Range"
            imgTopBtnWidth="250px"
            imgTopBtnFontColor="var(--color-bloodred)"
            position="relative"
          />
        )}
        {showDates && ( // if show dates is not false then show this
          <div id="campaignDashboard" className="date-picker-section">
            <DatePicker
              currentContext={currentContext}
              onClick={() => setDatePickerOpen(true)}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              clearStartDate={clearStartDate}
              clearEndDate={clearEndDate}
              setStartDateValue={setStartDateValue}
              setEndDateValue={setEndDateValue}
              startDateValue={startDateValue}
              endDateValue={endDateValue}
              onDateErrorChange={handleDateErrorChange}
              showAdditionalDate={false}
              isClearable={true}
              showYearDropdown={true} // show past years
              showMonthDropdown={true}
              numYears={10} // Show the last 10 years
              dateFormat="MMMM d, yyyy Date"
              placeholder="Enter/Select  Date"
            />
            <GlassBtn
              glassBtnImage={
                <XIcon
                  height="10px"
                  width="10px"
                  fillColor="var(--color-darkGold)"
                />
              }
              onClick={toggleShowDates}
              reverseOrder={true}
              glassBtnText="Cancel Date Search"
              glassBtnFontColor="var(--color-darkGold)"
              glassBtnWidth="130px"
              glassBtnFontWeight="800"
              glassBtnFontFamily="roboto-slab"
              glassBtnHeight="30px"
              glassBtnTextFontSize=".75rem"
              textLocalStyle={{
                position: "absolute",
                bottom: "15%",
              }}
              hi2Style={{
                transform: "rotate(90deg) translate(-70px, 65px)",
              }}
              hi3Style={{
                transform: "rotate(90deg) translate(-70px, -65px)",
              }}
              glassHoverStyle={{ height: "70px" }}
            />
          </div>
        )}
      </div>
      {filteredCampaigns.length > 0 ? (
        <div className="campaign-container">
          {filteredCampaigns.map((campaign) => (
            <CampaignMetricsCard
              key={campaign.id}
              campaign={campaign}
              onEdit={handleEditCampaign}
              onDelete={handleDeleteCampaign}
              onAutoPayToggle={handleAutoPayToggle}
            />
          ))}
          {loading && <Loader />}
          {hasMore && (
            <SmallPlainInsetBtn
              image={
                hasMore ? (
                  <EyeIcon
                    height="15px"
                    width="15px"
                    style={{ marginRight: "3px" }}
                    fillColor="var(--color-bloodred)"
                  />
                ) : (
                  <EyeSlashIcon
                    height="15px"
                    width="15px"
                    style={{ marginRight: "3px" }}
                    fillColor="var(--color-bloodred)"
                  />
                )
              }
              className="load-more-button"
              text={hasMore ? "View More" : "Hide More"}
              onClick={fetchMoreCampaigns}
            />
          )}
        </div>
      ) : (
        !loading && <p>No campaigns found.</p>
      )}
    </div>
  );
};
export default CurrentUserAdsManagerDashboard;
