
import React from "react";
import ReactDom from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/context/authContext";
import { NotificationProvider } from "./components/context/notificationContext";
import App from "./main";

const root = ReactDom.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <NotificationProvider>
          <App />
      </NotificationProvider>
    </AuthProvider>
  </BrowserRouter>
);
