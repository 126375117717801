import { validateUserNameValue } from "../../constants/validate";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";
import {
  apiUpdateMemberEmail,
  apiUpdateMemberMobile,
  apiUpdateMemberUserName,
} from "../../api/backend/auth";

export const handlePhoneNumberSubmit = async ({
  phoneNumberValue,
  setPhoneNumberValueError,
  setPhoneNumberSuggestionError,
  setPhoneNumberValue,
  setLoading,
  setProfileData, // Use the setter function here instead
  currentUser,
  formatPhoneNumber,
  setUserAttributes,
  currentContext,
}) => {
  if (!phoneNumberValue) {
    setPhoneNumberValueError("Phone number is required");
    return;
  }
  setLoading(true);
  const formattedPhoneNumber = formatPhoneNumber(phoneNumberValue);
  const phoneNumberStorageKey = getStorageKey("phoneNumberValue", currentContext); // Using currentContext
  try {
    const response = await apiUpdateMemberMobile(
      currentUser,
      formattedPhoneNumber
    );
    console.log("Server response:", response.data);

    if (response.data.message === "Phone number updated successfully") {
      setPhoneNumberSuggestionError("");
      setProfileData((prevProfileData) => ({
        ...prevProfileData,
        phoneNumber: formattedPhoneNumber,
      }));
      setUserAttributes((prevAttributes) => ({
        ...prevAttributes,
        phoneNumber: formattedPhoneNumber,
      }));
      setPhoneNumberValueError("");
      setPhoneNumberValue("");
      localStorage.removeItem(phoneNumberStorageKey); // Clear phone number from storage
    } else {
      setPhoneNumberSuggestionError(response.data.message);
    }
  } finally {
    setLoading(false);
  }
};
export const handleEmailSubmit = async ({
  emailValue,
  setEmailValueError,
  setEmailExistError,
  setEmailValue,
  setLoading,
  setProfileData,
  currentUser,
  setUserAttributes,
  currentContext,
}) => {
  if (!emailValue) {
    setEmailValueError("Email is required");
    return;
  }

  setLoading(true);
  const emailStorageKey = getStorageKey("emailValue", currentContext);
  console.log("Generated email storage key:", emailStorageKey);

  try {
    const response = await apiUpdateMemberEmail(currentUser, emailValue);
    console.log("Server response:", response.data);

    if (response.data.message === "Email updated successfully") {
      try {
        console.log("Success block entered. Clearing storage and updating UI.");
        setEmailExistError(""); 
        setEmailValue(""); 
        setProfileData((prevData) => ({ ...prevData, email: emailValue }));
        setUserAttributes((prev) => ({ ...prev, email: emailValue }));
        localStorage.removeItem(emailStorageKey); // Clear email from storage
      } catch (innerError) {
        console.error("Error within success block:", innerError);
      }
    } else if (response.data.message === "Email already exists") {
      console.log("Server indicates 'Email already exists'.");
      setEmailExistError("Email already exists");
    } else {
      console.log("Unexpected message from server. Setting fallback error.");
      setEmailExistError("Unexpected error updating email");
    }
  } catch (error) {
    console.log("Error encountered in catch block:", error);
    if (error.response && error.response.data?.message === "Email already exists") {
      console.log("Catch block: 'Email already exists' error.");
      setEmailExistError("Email already exists");
    } else {
      console.log("Catch block fallback: Removing email with key due to error.");
      localStorage.removeItem(emailStorageKey); // Fallback cleanup
    }
  } finally {
    console.log("Loading set to false in finally block.");
    setLoading(false);
  }
};




export const handleUsernameSubmit = async ({
  userNameValue,
  setUsernameError,
  setUserNameValue,
  setSuggestedUsernames,
  setLoading,
  setProfileData,
  currentUser,
  setUserAttributes,
  currentContext,
}) => {
  if (!userNameValue) {
    setUsernameError("Username is required");
    return;
  }
  setLoading(true);
  const usernameStorageKey = getStorageKey("userNameValue", currentContext); // Using currentContext
  try {
    const response = await apiUpdateMemberUserName(currentUser, userNameValue);
    console.log("Server response:", response.data);

    if (response.data.message === "Username updated successfully") {
      setUsernameError("");
      setSuggestedUsernames([]);
      setProfileData((prevData) => ({
        ...prevData,
        username: userNameValue,
      }));
      setUserAttributes((prev) => ({
        ...prev,
        username: userNameValue,
      }));
      setUserNameValue("");
      localStorage.removeItem(usernameStorageKey); // Clear username from storage
    }
  } catch (error) {
    // Handle the 400 status code explicitly here
    if (error.response && error.response.status === 400) {
      const { message, suggestedUsernames } = error.response.data;

      // Display the message and suggested usernames (if any)
      setUsernameError(message);
      if (suggestedUsernames) {
        setSuggestedUsernames(suggestedUsernames);
      }
    } else {
      // Handle other unexpected errors
      setUsernameError("Error updating username");
    }
  } finally {
    setLoading(false);
  }
};

// Clear function for clearing the input and triggering validation
export const handleClearUsername = ({
  setUserNameValue,
  setUserNameValidationRules,
  setUserNameToolTipVisible,
}) => {
  setUserNameValue(""); // Clear the input
  const validationResults = validateUserNameValue(""); // Validate empty input
  setUserNameValidationRules(validationResults); // Set validation for empty field
  // Show tooltip since it's cleared and invalid
  setUserNameToolTipVisible(true);
};
