import React, { useState, useEffect, useCallback } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { formatPhoneNumber } from "../../utils/formatNumber.js";
import { useAuth } from "../context/authContext.jsx";
import {
  apiSignUp,
  apiCheckPhoneNumberAndUsername,
} from "../../api/backend/auth.js";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import {
  handleUserNameValueChange,
  handlePhoneInputChange,
  handleCurrentPasswordValueChange,
  handleConfirmPasswordChange,
  onCurrentPasswordValueFocused,
  onCurrentPasswordValueBlur,
  onUserNameValueFocused,
  onUserNameValueBlur,
} from "../../controller/watcherAndClearFunctions/signupSettingsClearsWatchersFunctions.js";
import { signupErrorHandler } from "../../controller/errorHandlers/signupSettingsErrorHandler.js";
import {
  validatePasswordValue,
  validateUserNameValue,
} from "../../constants/validate.js";
import ToolTip from "../tooltip/tooltip.jsx";
import {
  EyeSlashIcon,
  EyeIcon,
  CellPhoneIcon,
  PeopleIcon,
  LockIcon,
  SkitsOlympicLogoIcon,
} from "../icons/index.jsx";
import KeyLockIcon from "../keyLockIconSwitches/keyLockIconSwitches.jsx";
import {
  InsetButtonEventListener,
  ImgTopBtn,
  FieldButton,
} from "../buttons/index.jsx";
import { errorMessages } from "../../constants/errorMessages.js";
import Loader from "../loader/loader.jsx";
import BlinkingLight from "../blinkingLight/blinkingLight.jsx";
import "./signupForm.scss";

const SignupForm = ({
  onCancel,
  onVerifyAccount,
  setContextType,
  contextType = "signUp",
}) => {
  const { setUserInfo } = useAuth();
  const [signupAttempted, setSignupAttempted] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentContext = contextType ? "signUp" : "completeSignup";
  const [userNameValueError, setUserNameValueError] = useState("");
  const [phoneNumberValueError, setPhoneNumberValueError] = useState("");
  const [phoneNumberFocused, setPhoneNumberFocused] = useState(false); // Track focus state for username
  const [phoneNumberSuggestionError, setPhoneNumberSuggestionError] =
    useState("");
  const [confirmPasswordValueError, setConfirmPasswordValueError] =
    useState("");
  const [currentPasswordValueError, setCurrentPasswordValueError] =
    useState("");
  const [userNameToolTipVisible, setUserNameToolTipVisible] = useState(false);
  const [currentPasswordToolTipVisible, setCurrentPasswordToolTipVisible] =
    useState(false);
  const [suggestedUsernames, setSuggestedUsernames] = useState([]);
  const [userNameValidationRules, setUserNameValidationRules] = useState([
    false,
  ]);
  const [userNameValueFocused, setUserNameValueFocused] = useState(false);
  const [currentPasswordValueFocused, setCurrentPasswordValueFocused] =
    useState(false);
  const [showCurrentPasswordValue, setShowCurrentPasswordValue] =
    useState(false);
  const [confirmPasswordValueFocused, setConfirmPasswordValueFocused] =
    useState(false);
  const [showConfirmPasswordValue, setShowConfirmPasswordValue] =
    useState(false);
  const [passwordValidationRules, setPasswordValidationRules] = useState([
    false,
  ]);
  const [errors, setErrors] = useState({});
  const [userNameValue, setUserNameValue] = useState(
    localStorage.getItem(getStorageKey("userNameValue", currentContext)) || ""
  );
  const [phoneNumberValue, setPhoneNumberValue] = useState(
    localStorage.getItem(getStorageKey("phoneNumberValue", currentContext)) ||
      ""
  );
  const [confirmPasswordValue, setConfirmPasswordValue] = useState(
    localStorage.getItem(
      getStorageKey("confirmPasswordValue", currentContext)
    ) || ""
  );
  const [currentPasswordValue, setCurrentPasswordValue] = useState(
    localStorage.getItem(
      getStorageKey("currentPasswordValue", currentContext)
    ) || ""
  );

  const checkPhoneNumberAndUsername = useCallback(
    async (phoneNumber, username) => {
      try {
        const phoneNumberWithPrefix = formatPhoneNumber(phoneNumber);
        const response = await apiCheckPhoneNumberAndUsername(
          phoneNumberWithPrefix,
          username
        );
        console.log("Phone number and username check response:", response.data);
        return response.data;
      } catch (error) {
        console.error("Error checking phone number and username:", error);
        return {
          phoneExists: false,
          usernameResult: { exists: false, suggestedUsernames: [] },
        };
      }
    },
    []
  );
  useEffect(() => {
    if (signupAttempted) {
      console.log("Signup attempted, starting validation...");
      setLoading(true);

      const validateAndSignup = async () => {
        try {
          const validation = signupErrorHandler(
            userNameValue,
            phoneNumberValue,
            confirmPasswordValue,
            currentPasswordValue,
            currentContext,
            setUserNameValueError,
            setUserNameToolTipVisible,
            setPhoneNumberValueError,
            setConfirmPasswordValueError,
            setCurrentPasswordValueError,
            setCurrentPasswordToolTipVisible
          );
          if (Object.keys(validation.errors).length > 0) {
            console.log("Validation errors found:", validation.errors);
            return;
          }
          console.log("Validation passed.");
          const result = await checkPhoneNumberAndUsername(
            phoneNumberValue.replace(/\D/g, ""),
            userNameValue
          );
          console.log("Combined check result:", result);
          if (result.phoneExists && result.phoneSignupIncomplete) {
            await signupUser();
            setContextType("completeSignup"); // Update context type
            setLoading(false);
            return;
          } else if (!result.phoneExists && result.usernameResult.exists) {
            console.log(
              "Username exists but phone number does not. Suggesting usernames."
            );
            setUserNameValueError("Create a unique username");
            setSuggestedUsernames(result.usernameResult.suggestedUsernames);
            setLoading(false);
            return; // Do not proceed further; prompt user to choose a new username
          } else if (!result.phoneExists && !result.usernameResult.exists) {
            console.log(
              "Phone number and username are new, proceeding with signup."
            );
            await signupUser();
          } else if (result.phoneExists && !result.phoneSignupIncomplete) {
            console.log("Phone number exists and signup is complete.");
            setPhoneNumberSuggestionError(
              "Account already registered with this phone number"
            );
          }
        } catch (error) {
          console.error("Error during validation and signup:", error);
        } finally {
          setLoading(false);
          setSignupAttempted(false);
        }
      };

      validateAndSignup();
    }
  }, [
    signupAttempted,
    currentContext,
    userNameValue,
    phoneNumberValue,
    confirmPasswordValue,
    currentPasswordValue,
    setUserNameValueError,
    setUserNameToolTipVisible,
    setPhoneNumberValueError,
    setConfirmPasswordValueError,
    setCurrentPasswordValueError,
    setCurrentPasswordToolTipVisible,
    checkPhoneNumberAndUsername,
    contextType,
  ]);

  const onSignup = async (e) => {
    e.preventDefault();
    // Trim the phone number to remove non-digit characters and spaces
    const formattedPhoneNumber = phoneNumberValue.replace(/\D/g, "");
    // Frontend validation for phone number length
    if (formattedPhoneNumber.length > 0 && formattedPhoneNumber.length < 10) {
      setPhoneNumberSuggestionError(
        "Please enter a valid 10-digit phone number."
      );
      return;
    } else {
      setPhoneNumberSuggestionError(""); // Clear any previous errors
    }
    // Proceed to check phone number and username against the backend
    setSignupAttempted(true);
  };
  const signupUser = async () => {
    const phoneNumberWithPrefix = formatPhoneNumber(phoneNumberValue);
    try {
      const response = await apiSignUp({
        phoneNumber: phoneNumberWithPrefix,
        username: userNameValue,
        password: currentPasswordValue,
      });
      console.log("API Response:", response.data);
      if (
        response.data &&
        response.data.status === "OK" &&
        response.data.user
      ) {
        console.log("Signup successful, user created:", response.data.user);
        // localStorage.setItem("userId", response.data.user.id);
        setUserInfo(response.data.user.id); // Store user ID in cookies instead of local storage
        setContextType("signIn");
        onVerifyAccount(response.data.user, "signUp");
        setLoading(false);
      } else if (response.data.status === "IncompleteSignup") {
        console.log(
          "Incomplete signup, continuing the process.",
          response.data.message
        );
        setContextType("completeSignup");
        onVerifyAccount(response.data.user, "completeSignup");
        // Handle different types of responses
        if (response.data.type === "updateResumeSignup") {
          console.log("User information updated during resume signup.");
          // Handle UI updates or messages indicating user info was updated
        } else if (response.data.type === "resumeSignup") {
          console.log("Resuming signup with no updates.");
          // Handle UI updates or messages indicating no updates were made
        }
        setLoading(false);
      } else {
        console.error("Unexpected signup response. Setting error.");
        setErrors("Signup failed. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Signup error:", error);
      if (
        error.response &&
        error.response.data.message ===
          "Account already registered with this phone number and signup is complete."
      ) {
        setContextType("signIn");
      }
      setErrors("Signup failed. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div id="signupForm" className="signup-modal-content slideDown">
      <div className="signup-modal-body">
        <form action="" style={{ position: "relative" }}>
          <div className="client-form">
            <div id="logo-header">
              <SkitsOlympicLogoIcon />
              <h3>Skits Olympic</h3>
            </div>
            <div className="input-field ">
              <div className="input-group blinker-wrapper">
                <div className="input-wrapper">
                  <PhoneInput
                    id="signUpMobile"
                    type="text"
                    country={"us"}
                    onlyCountries={["us"]}
                    disableDropdown={true}
                    autoComplete="off"
                    placeholder={
                      phoneNumberValueError
                        ? phoneNumberValueError
                        : phoneNumberFocused ||
                            (phoneNumberValue && phoneNumberValue !== "1")
                          ? ""
                          : "...Enter your U.S. mobile here"
                    }
                    value={phoneNumberValue}
                    onChange={(value) =>
                      handlePhoneInputChange(
                        value,
                        setPhoneNumberValue,
                        currentContext,
                        getStorageKey,
                        phoneNumberValueError,
                        setPhoneNumberValueError
                      )
                    }
                    inputClass={`signup-control-phonelibrary-placeholder ${
                      phoneNumberValueError
                        ? "custom-required-placeholder-overide-phonelibrary-style field-background-color-error"
                        : "signup-control-phonelibrary-placeholder"
                    } ${phoneNumberFocused ? "focused" : ""}`}
                    inputStyle={{
                      width: "100%",
                      borderColor: phoneNumberValueError ? "red" : "",
                    }}
                    onFocus={() => {
                      setPhoneNumberFocused(true);
                      setPhoneNumberSuggestionError("");
                    }}
                    onBlur={() => setPhoneNumberFocused(false)}
                    error={!!phoneNumberValueError}
                    disableCountryCode={true} // Disable the country code
                    helperText={phoneNumberValueError && "Invalid phone number"}
                  />
                </div>
                {!(phoneNumberFocused || phoneNumberValue) && (
                  <>
                    <CellPhoneIcon
                      fillColor="var(--color-white)"
                      style={{
                        opacity: 0.68,
                        transition: "opacity 0.2s ease-in-out",
                        position: "absolute",
                        left: "50px", // Position relative to the input field
                        top: "40%",
                        transform: "translateY(-50%)", // Center the icon vertically
                      }}
                    />
                    <span
                      className="blinking-cursor-black-color"
                      style={{
                        position: "absolute",
                        left: "77px", // Position relative to the input field
                        top: "40%",
                        transform: "translateY(-50%)", // Center the cursor vertically
                      }}
                    >
                      |
                    </span>
                  </>
                )}
                {phoneNumberSuggestionError && (
                  <div className="signup-phone-error-message">
                    {phoneNumberSuggestionError}
                  </div>
                )}
              </div>
              <div className="input-grid2">
                <div className="input-group-with-tooltip">
                  <div className="input-group blinker-wrapper">
                    <div className="input-wrapper">
                      <input
                        id="userName"
                        autoComplete="off"
                        type={userNameValue ? "text" : "userName"}
                        placeholder={
                          userNameValueError
                            ? userNameValueError
                            : "...Create a username, enter here"
                        }
                        className={`${userNameValueFocused ? "focused" : ""} ${
                          userNameValueError
                            ? "required-placeholder border-error field-background-color-error"
                            : ""
                        }`}
                        value={userNameValue}
                        onChange={(e) =>
                          handleUserNameValueChange(
                            e.target.value,
                            setUserNameValue,
                            currentContext,
                            validateUserNameValue,
                            setUserNameValidationRules,
                            setUserNameToolTipVisible,
                            userNameValueError,
                            setUserNameValueError
                          )
                        }
                        onFocus={() =>
                          onUserNameValueFocused(
                            userNameValue,
                            validateUserNameValue,
                            setUserNameValueFocused,
                            setUserNameValidationRules,
                            setUserNameToolTipVisible
                          )
                        }
                        onBlur={() =>
                          onUserNameValueBlur(
                            setUserNameValueFocused,
                            setUserNameToolTipVisible
                          )
                        }
                      />
                      {!(userNameValueFocused || userNameValue) && (
                        <>
                          <PeopleIcon
                            className="input-icon"
                            fillColor="var(--color-white)"
                            style={{
                              opacity: 0.68,
                              transition: "opacity 0.2s ease-in-out",
                            }}
                          />
                          <span className="blinking-cursor-black-color signup-username-cursor">
                            |
                          </span>
                        </>
                      )}
                      {userNameValue && (
                        <FieldButton onClick={() => setUserNameValue("")}>
                          Clear
                        </FieldButton>
                      )}
                    </div>
                  </div>
                  {(userNameValueFocused || userNameValueError) && (
                    <ToolTip
                      rules={userNameValidationRules}
                      toolTipVisible={userNameToolTipVisible}
                      setToolTipVisible={setUserNameToolTipVisible}
                    />
                  )}
                </div>
              </div>
              {suggestedUsernames.length > 0 && (
                <div className="suggestions">
                  <p className="userNameMesssage">
                    Username is Taken Suggested Usernames Below:
                  </p>
                  <ul>
                    {suggestedUsernames.map((name) => (
                      <li key={name}>{name}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="input-grid2">
                <div className="input-group-with-tooltip">
                  <div className="input-group blinker-wrapper">
                    <div className="input-wrapper">
                      <input
                        type={showCurrentPasswordValue ? "text" : "password"}
                        id="currentPassword"
                        placeholder={
                          currentPasswordValueError
                            ? currentPasswordValueError
                            : "...Create a password, enter here"
                        }
                        className={`${currentPasswordValueFocused ? "focused" : ""} ${
                          currentPasswordValueError
                            ? "required-placeholder border-error field-background-color-error"
                            : ""
                        }`}
                        value={currentPasswordValue}
                        onChange={(e) =>
                          handleCurrentPasswordValueChange(
                            e.target.value,
                            setCurrentPasswordValue,
                            currentContext,
                            getStorageKey,
                            validatePasswordValue,
                            setPasswordValidationRules,
                            setCurrentPasswordToolTipVisible,
                            currentPasswordValueError,
                            setCurrentPasswordValueError
                          )
                        }
                        onFocus={() =>
                          onCurrentPasswordValueFocused(
                            currentPasswordValue,
                            validatePasswordValue,
                            setCurrentPasswordValueFocused,
                            setPasswordValidationRules,
                            setCurrentPasswordToolTipVisible
                          )
                        }
                        onBlur={() =>
                          onCurrentPasswordValueBlur(
                            setCurrentPasswordValueFocused,
                            setCurrentPasswordToolTipVisible
                          )
                        }
                      />
                    </div>
                    {!(currentPasswordValueFocused || currentPasswordValue) && (
                      <>
                        <LockIcon
                          className="lock-icon"
                          fillColor="var(--color-white)"
                          locked={!showCurrentPasswordValue}
                          style={{
                            opacity: 0.68,
                            transition: "opacity 0.2s ease-in-out",
                          }}
                        />
                        <span className="blinking-cursor-black-color signup-password-cursor">
                          |
                        </span>
                      </>
                    )}
                    {currentPasswordValue && currentPasswordValue !== "" && (
                      <div
                        className="show-hide-password-toggle"
                        onClick={(e) => {
                          setShowCurrentPasswordValue((prev) => !prev);
                        }}
                        style={{
                          position: "absolute",
                          right: "10px", // Position it on the right inside the input field
                          top: "50%",
                          transform: "translateY(-50%)", // Center it vertically
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {showCurrentPasswordValue ? (
                          <EyeSlashIcon 
                          width="20" 
                          height="15"
                          fillColor ="var(--color-lightyellow)"
                           />
                        ) : (
                          <EyeIcon 
                          width="20" 
                          height="15" 
                          fillColor ="var(--color-lightyellow)"
                          />
                        )}
                        <span className="show-hide-label">
                          {showCurrentPasswordValue ? "Hide" : "Show"}
                        </span>
                      </div>
                    )}
                  </div>
                  {(currentPasswordValueFocused ||
                    currentPasswordValueError) && (
                    <ToolTip
                      rules={passwordValidationRules}
                      toolTipVisible={currentPasswordToolTipVisible}
                      setToolTipVisible={setCurrentPasswordToolTipVisible}
                    />
                  )}
                </div>
                <div className="input-group blinker-wrapper">
                  <div className="input-wrapper">
                    <input
                      type={showConfirmPasswordValue ? "text" : "password"} // Correct input type here
                      id="confirmPassword"
                      placeholder={
                        confirmPasswordValueError
                          ? confirmPasswordValueError
                          : "...Re-enter your password here"
                      }
                      className={`${confirmPasswordValueFocused ? "focused" : ""} ${
                        confirmPasswordValueError
                          ? "required-placeholder border-error field-background-color-error"
                          : ""
                      }`}
                      value={confirmPasswordValue}
                      onChange={(e) =>
                        handleConfirmPasswordChange(
                          e.target.value,
                          confirmPasswordValueError,
                          currentContext,
                          setConfirmPasswordValue,
                          setConfirmPasswordValueError
                        )
                      }
                      errors={errors}
                      onFocus={() => setConfirmPasswordValueFocused(true)}
                      onBlur={() => setConfirmPasswordValueFocused(false)}
                    />
                  </div>
                  {confirmPasswordValueError &&
                    confirmPasswordValueError !==
                      errorMessages.confirmPassword && (
                      <div className="error-message">
                        {confirmPasswordValueError}
                      </div>
                    )}

                  {!(confirmPasswordValueFocused || confirmPasswordValue) && (
                    <>
                      <KeyLockIcon
                        className="lock-icon"
                        fillColor="var(--color-white)"
                        locked={!showConfirmPasswordValue}
                        style={{
                          opacity: 0.68,
                          transition: "opacity 0.2s ease-in-out",
                        }}
                      />
                      <span className="blinking-cursor-black-color signup-confirmpassword-cursor">
                        |
                      </span>
                    </>
                  )}
                  {confirmPasswordValue && confirmPasswordValue !== "" && (
                    <div
                      className="show-hide-password-toggle"
                      onClick={() =>
                        setShowConfirmPasswordValue((prev) => !prev)
                      }
                      style={{
                        position: "absolute",
                        right: "10px", // Position it on the right inside the input field
                        top: "30%",
                        transform: "translateY(-50%)", // Center it vertically
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {showConfirmPasswordValue ? (
                        <EyeSlashIcon 
                        width="20" 
                        height="15" 
                        fillColor ="var(--color-lightyellow)"
                        />
                      ) : (
                        <EyeIcon 
                        width="20" 
                        height="15"
                        fillColor ="var(--color-lightyellow)" 
                        />
                      )}
                      <span
                        className="show-hide-label"
                        style={{ marginLeft: "5px" }}
                      >
                        {showConfirmPasswordValue ? "Hide" : "Show"}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="position-relative">
              <ImgTopBtn
                image={
                  <BlinkingLight blinkerLightStyles={{ marginRight: "10px" }} />
                }
                imgTopBtnWidth="100%"
                imgTopBtnTextFontSize="1.3rem"
                imgTopBtnFontColor="#b03a2e"
                imgTopBtnText={contextType ? "Verify Account" : "Resume Signup"}
                textClassName="responsiveBtnText"
                onClick={(e) => {
                  // Call onSignup and pass contextType
                  onSignup(e, currentContext);
                }}
              />
            </div>
            {loading && <Loader className="signup-loader-position" />}
            <InsetButtonEventListener
              btnText="X Cancel"
              className="white-gold"
              textClassName="responsiveBtnText"
              style={{
                width: "150px",
                fontSize: "1.3rem",
              }}
              onClick={onCancel}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default SignupForm;
