import React, { useState, useEffect } from "react";

const BlinkingLight = ({ blinkerLightStyles = {} }) => {
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    // Start blinking
    const intervalId = setInterval(() => {
      setIsOn((prev) => !prev); // Toggle the light state
    }, 1000); // Change the light state every 1000ms (1 second)

    // Return a cleanup function that clears the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array means this effect runs only once on mount

  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        backgroundColor: isOn
          ? "var(--color-redish)"
          : "var(--color-corveteRed)",
        border: "1px solid #ffff",
        boxShadow: "2px 2px 10px 0 rgba(99, 106, 119, 0.6)",
        ...blinkerLightStyles, // Spread the custom styles over the default styles
      }}
    />
  );
};

export default BlinkingLight;
