import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../context/authContext";
import { formatTimeElapsed } from "../../utils/formatTimeElapsed";
import {
  apiCreateComment,
  apiFetchComments,
  apiCreateThread,
  apiFetchThreads,
  apiCreateResponseToThread,
  apiFetchThreadResponses,
  apiCheckCurrentUserHeartedItems,
  apiCheckMessageOwnership,
  apiDeleteSkitComment,
  apiDeleteCommentThread,
  apiDeleteThreadMemberResponse,
} from "../../api/backend/comment";
import SkitCommentResults from "./skitCommentResults";
import NewThreadResults from "./newThreadResultsResponseComments";
import ResponseThreadResults from "./ResponseThreadTargetUserResults";
import SkitCommentBox from "./skitCommentBox";
import { ImgTopBtn } from "../buttons";
import { EyeIcon, EyeSlashIcon } from "../icons";
import Loader from "../../components/loader/loader";
import { SingularPlural } from "../../utils/wordUtils";
import "./commentThreads.scss"; // Import the CSS for styling

const CommentThreads = ({ skitId, commentId }) => {
  const { currentUser } = useAuth();
  const [comments, setComments] = useState([]);
  const [threads, setThreads] = useState({});
  const [responses, setResponses] = useState({});
  const [showThreads, setShowThreads] = useState({}); // To manage visibility of threads
  const [showResponses, setShowResponses] = useState({}); // To manage visibility of responses
  const [heartedItems, setHeartedItems] = useState([]);   //  The heartedItems[\comment-${comment.id}`]` dynamically retrieves the correct hearted status for each item based on its type and ID for the children as well
  const [activeReply, setActiveReply] = useState({ type: null, id: null }); // New state for active reply and type is defined on the (comment, thread, response) components, this just controls state, ids are defined from the backend to here and passed to the children
  const [messageValue, setMessageValue] = useState("");
  const [threadValue, setThreadValue] = useState("");
  const [replyValue, setReplyValue] = useState(""); // State for reply message
  const [skitCommentError, setSkitCommentError] = useState("");
  const [newThreadError, setNewThreadError] = useState("");
  const [responseError, setResponseError] = useState(""); // Separate error state for responses
  const [commentOffset, setCommentOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const commentLimit = 10;
  const [replyingTo, setReplyingTo] = useState(null); // State to store the user being replied to
  
  
  

  const [messageOwner, setMessageOwner] = useState({
    comments: [],
    threads: [],
    responses: [],
  });
  // Calculate the total comments count
  const totalCommentsCount = comments.length; // Only count top-level comments
  // if i want to include threads for testing in the future
  // const totalCommentsCount =
  //   comments.length + Object.values(threads).flat().length;
  // Initial fetch of comments and its ownership for permisions
  useEffect(() => {
    fetchComments(skitId, 0, commentLimit);
    fetchMessagesOwnership(currentUser);
  }, [skitId]);

  useEffect(() => {
    if (commentId && comments.length > 0) {
      const commentElement = document.getElementById(`comment-${commentId}`);
      if (commentElement) {
        commentElement.scrollIntoView({ behavior: "smooth" });
        commentElement.classList.add("highlight");
        setTimeout(() => commentElement.classList.remove("highlight"), 3000);
      }
    }   
  }, [commentId, comments]); // Include comments in the dependency array, which is triggered when commentId changes
// hearted items is used to map and show which buttons are hearted to the source id
// where current user = source id of the hearted item, therefore, member can unheart
// the item of the member is the source of the activity id from the activity logs
  const fetchCurrentUserHeartedItems = useCallback(async () => {
    try {
      console.log("Step 1: Starting fetchCurrentUserHeartedItems");
  
      console.log(
        "Step 2: Fetching hearted items for sourceId (current user):",
        currentUser
      );
      // Make the API call to fetch hearted items for the current user
      const response = await apiCheckCurrentUserHeartedItems(currentUser); 
      console.log("Step 3: API response received:", response.data);
      // Check if the API response indicates success
      if (response.data.success) {
        console.log(
          "Step 4: Hearted items fetch was successful. Processing hearted items..."
        );
        // Reduce the heartedItems array into a map (keyed by `type-activityId`)
        const heartedMap = response.data.heartedItems.reduce((map, item) => {
          console.log("Processing item:", item);
          // Generate a unique key for each item using type and activityId
          const key = `${item.type}-${item.activityId}`;
          console.log(`Generated key for item: ${key}`);
          // Map the hearted status and other details for the current item
          map[key] = {
            hearted: item.hearted,
            activityId: item.activityId,
            relatedId: item.relatedId, // Useful for debugging or additional logic
            type: item.type,
            sourceId: item.sourceId,
          };
          console.log(`Added item to heartedMap:`, map[key]);
          return map; // Return the updated map
        }, {});
        console.log("Step 5: Final heartedMap after reduce:", heartedMap);
        // Update state with the hearted map
        setHeartedItems(heartedMap);
        console.log("Step 6: heartedItems state updated");
      } else {
        console.log(
          "Step 4: Hearted items fetch was unsuccessful. No action taken."
        );
      }
    } catch (error) {
      // Log any errors encountered during the fetch
      console.error("Step 7: Error fetching hearted items:", error);
    }
  }, [currentUser]);
  
  useEffect(() => {
    const fetchHeartedItems = async () => {
      try {
        console.log("Step 1: useEffect - Fetching hearted items");
        // Fetch hearted items for the current user
        const response = await apiCheckCurrentUserHeartedItems(currentUser);
        console.log("Step 2: API response in useEffect:", response);
        // Process the response if successful
        if (response.success) {
          console.log("Step 3: Hearted items fetch was successful. Processing...");
          const heartedMap = response.heartedItems.reduce((map, item) => {
            console.log("Processing item in useEffect:", item);
            const key = `${item.type}-${item.activityId}`;
            console.log(`Generated key for item in useEffect: ${key}`);
            map[key] = {
              hearted: item.hearted,
              activityId: item.activityId,
              relatedId: item.relatedId, // For debugging
              type: item.type,
              sourceId: item.sourceId,
            };
            console.log(`Added item to heartedMap in useEffect:`, map[key]);
            return map;
          }, {});
          console.log(
            "Step 4: Final heartedMap after reduce in useEffect:",
            heartedMap
          );
          setHeartedItems(heartedMap);
          console.log("Step 5: heartedItems state updated in useEffect");
        } else {
          console.log("Step 3: Hearted items fetch failed in useEffect.");
        }
      } catch (error) {
        // Log any errors encountered during the fetch
        console.error("Step 6: Error fetching hearted items in useEffect:", error);
      }
    };
    if (currentUser) {
      console.log("Step 0: useEffect - Current user is defined. Fetching...");
      fetchHeartedItems();
    } else {
      console.log("Step 0: useEffect - Current user is undefined. Skipping fetch.");
    }
  }, [currentUser]);
  


  const fetchMessagesOwnership = useCallback(async (currentUser) => {
    try {
      const response = await apiCheckMessageOwnership(currentUser); // Assuming this is your API function
      if (response.data.success) {
        setMessageOwner(response.data.data); // Update state with ownership data
      }
    } catch (error) {
      console.error("Error fetching ownership data:", error);
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchMessagesOwnership(currentUser); // Fetch ownership when the user changes
    }
  }, [currentUser, fetchMessagesOwnership]);

  useEffect(() => {
    console.log("Frontend: Comments, Threads, and Responses changed.");
    console.log("Frontend: Current Comments:", comments);
    console.log("Frontend: Current Threads:", threads);
    console.log("Frontend: Current Responses:", responses);

    const commentIds = comments.map((comment) => ({
      id: comment.id,
      type: "comment",
    }));
    const threadIds = Object.values(threads)
      .flat()
      .map((thread) => ({ id: thread.id, type: "thread" }));
    const responseIds = Object.values(responses)
      .flat()
      .map((response) => ({ id: response.id, type: "response" }));

    const allItems = [...commentIds, ...threadIds, ...responseIds];
    console.log(
      "Frontend: Combined allItems to fetch hearted status:",
      allItems
    );
    fetchCurrentUserHeartedItems(allItems);
  }, [comments, threads, responses, fetchCurrentUserHeartedItems]);

  const deleteMessagePermissions = (id, type) => {
    if (type === "comment") return messageOwner.comments.includes(id);
    if (type === "thread") return messageOwner.threads.includes(id);
    if (type === "response") return messageOwner.responses.includes(id);
    return false;
  };

  const toggleShowThreads = (commentId) => {
    setShowThreads((prev) => {
      const newShowThreads = {};
      Object.keys(prev).forEach((key) => {
        newShowThreads[key] = false;
      });
      return {
        ...newShowThreads,
        [commentId]: !prev[commentId],
      };
    });
  };

  const toggleShowResponses = (threadId) => {
    setShowResponses((prev) => ({
      ...prev,
      [threadId]: !prev[threadId],
    }));
  };

  const fetchComments = useCallback(
    async (relatedId, offset = 0, limit = 10) => {
      setLoading(true);
      try {
        const response = await apiFetchComments(relatedId, offset, limit);
        const formattedComments = response.data.map((comment) => ({
          ...comment,
          formattedTime: formatTimeElapsed(comment.createdAt),
          heartCount: comment.heartCount, // Include heart count
        }));
        setComments((prevComments) => {
          const newComments = formattedComments.filter(
            (newComment) =>
              !prevComments.some(
                (prevComment) => prevComment.id === newComment.id
              )
          );
          return [...prevComments, ...newComments];
        });
        setCommentOffset(offset + limit);
        response.data.forEach((comment) => fetchThreads(comment.id));
      } catch (error) {
        setSkitCommentError("Failed to fetch comments");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  // Handler for the "View More" button
  const handleViewMoreComments = () => {
    fetchComments(skitId, commentOffset, commentLimit);
  };

  const fetchThreads = useCallback(async (commentId) => {
    try {
      // Make an API call to fetch threads related to the given commentId
      const response = await apiFetchThreads(commentId);
      const formattedThreads = response.data.map((thread) => ({
        ...thread,
        formattedTime: formatTimeElapsed(thread.createdAt),
      }));
      // Update the threads state with the fetched data
      setThreads((prevThreads) => ({
        ...prevThreads, // Keep the existing threads
        [commentId]: formattedThreads, // Add/update the threads for the specific commentId with the new data
      }));
      // For each fetched thread, fetch its responses
      response.data.forEach((thread) => {
        fetchResponses(thread.id);
      });
    } catch (error) {
      // If there is an error during the API call, log the error and set the error state
      console.error("Error fetching threads:", error);
      setNewThreadError("Failed to fetch threads");
    }
  }, []); // The empty dependency array means this function is only created once and won't change

  const fetchResponses = useCallback(async (threadId) => {
    try {
      // Make an API call to fetch responses related to the given threadId
      const response = await apiFetchThreadResponses(threadId);
      const formattedResponses = response.data.map((response) => ({
        ...response,
        formattedTime: formatTimeElapsed(response.createdAt),
      }));
      // Update the responses state with the fetched data
      setResponses((prevResponses) => ({
        ...prevResponses,
        [threadId]: formattedResponses,
      }));
    } catch (error) {
      // If there is an error during the API call, log the error and set the error state
      console.error("Error fetching responses:", error);
      setResponseError("Failed to fetch responses");
    }
  }, []); // The empty dependency array means this function is only created once and won't change

  const handleCreateComment = useCallback(async () => {
    const storedMessageValue = localStorage.getItem("messageValue");
    if (messageValue.trim() === "") {
      setSkitCommentError("Comment cannot be empty");
      return;
    }
    try {
      const response = await apiCreateComment({
        relatedId: skitId,
        sourceId: currentUser,
        message: storedMessageValue,
      });

      setMessageValue("");
      setSkitCommentError("");
      // Refetch comments to include the newly created comment
      fetchComments(skitId, 0, commentLimit);
      fetchMessagesOwnership(currentUser); // Refetch ownership data
    } catch (error) {
      console.error("Error creating comment:", error);
      setSkitCommentError("Failed to create comment");
    }
  }, [currentUser, messageValue, skitId, fetchComments]);

  const handleCreateInitialThread = async (commentId) => {
    if (threadValue.trim() === "") {
      setNewThreadError("Thread message cannot be empty");
      return;
    }
    try {
      const response = await apiCreateThread({
        commentId,
        sourceId: currentUser,
        message: threadValue,
      });
      setThreads((prevThreads) => ({
        ...prevThreads,
        [commentId]: [...(prevThreads[commentId] || []), response.data],
      }));
      setThreadValue("");
      setNewThreadError("");
      fetchThreads(commentId); // Fetch threads again to ensure UI update
      fetchMessagesOwnership(currentUser); // Refetch ownership data
      setActiveReply({ type: null, id: null }); // Close the reply input after submission
      toggleShowThreads(commentId); // Toggle to show the thread
    } catch (error) {
      console.error("Error creating thread:", error);
      setNewThreadError("Failed to create thread");
    }
  };

  const handleCreateResponseToThread = async (threadId, sourceId) => {
    if (replyValue.trim() === "") {
      setResponseError("Reply message cannot be empty");
      return;
    }
    try {
      const response = await apiCreateResponseToThread({
        threadId,
        sourceId,
        responderId: currentUser,
        message: replyValue,
      });
      setResponses((prevResponses) => ({
        ...prevResponses,
        [threadId]: [...(prevResponses[threadId] || []), response.data],
      }));
      setReplyValue("");
      setResponseError("");
      fetchResponses(threadId); // Fetch responses again to ensure UI update
      fetchMessagesOwnership(currentUser); // Refetch ownership data
      setActiveReply({ type: null, id: null }); // Close the reply input after submission
      toggleShowResponses(threadId); // Toggle to show the responses
    } catch (error) {
      console.error("Error creating response to thread:", error);
      setResponseError("Failed to create response");
    }
  };

  const handleCancelReply = (type, id) => {
    if (type === activeReply.type && id === activeReply.id) {
      setActiveReply({ type: null, id: null });
      setReplyingTo(null);
    }
  };
  const deleteSkitComment = async (commentId) => {
    try {
      await apiDeleteSkitComment({ commentId });
      setComments(comments.filter((comment) => comment.id !== commentId));
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const deleteCommentThread = async (threadId) => {
    try {
      await apiDeleteCommentThread({ threadId });
      setThreads((prevThreads) => {
        const updatedThreads = { ...prevThreads };
        for (const commentId in updatedThreads) {
          updatedThreads[commentId] = updatedThreads[commentId].filter(
            (thread) => thread.id !== threadId
          );
        }
        return updatedThreads;
      });
    } catch (error) {
      console.error("Error deleting thread:", error);
    }
  };

  const deleteThreadMemberResponse = async (responseId) => {
    try {
      await apiDeleteThreadMemberResponse({ responseId });
      setResponses((prevResponses) => {
        const updatedResponses = { ...prevResponses };
        for (const threadId in updatedResponses) {
          updatedResponses[threadId] = updatedResponses[threadId].filter(
            (response) => response.id !== responseId
          );
        }
        return updatedResponses;
      });
    } catch (error) {
      console.error("Error deleting response:", error);
    }
  };

  return (
    <div className="comment-threads-container">
      {/* Creat a Skit Comment Box */}
      <SkitCommentBox
        onSubmit={handleCreateComment}
        messageValue={messageValue}
        setMessageValue={setMessageValue}
        skitCommentError={skitCommentError}
        setSkitCommentError={setSkitCommentError}
      />
      {/* Comments Section */}
      {totalCommentsCount > 0 && (
        <div className="comments-header">
          <h3>
            {totalCommentsCount} {SingularPlural(totalCommentsCount, "Comment")}
          </h3>
        </div>
      )}
      {comments.map((comment) => (
        <div
          key={comment.id}
          className="comment-section"
          id={`comment-${comment.id}`}
        >
          {/* Comment Component */}
          <SkitCommentResults
            comment={comment}
            heartCount={comment.heartCount} // Pass heart count
            currentUser={currentUser}
            // Adding the ?.hearted ?? false ensures that even if a key doesn't exist in the heartedItems, the UI gracefully defaults to false.
            isHearted={heartedItems[`comment-${comment.id}`]?.hearted ?? false} // Use derived boolean
            newThreadError={newThreadError}
            setNewThreadError={setNewThreadError}
            activeCommentId={
              activeReply.type === "comment" ? activeReply.id : null
            }
            setActiveCommentId={(id) => setActiveReply({ type: "comment", id })}
            handleCancelReply={handleCancelReply}
            handleCreateInitialThread={handleCreateInitialThread}
            threadValue={threadValue}
            setThreadValue={setThreadValue}
            replyingTo={replyingTo}
            setReplyingTo={setReplyingTo}
            deleteMessagePermissions={deleteMessagePermissions(
              comment.id,
              "comment"
            )} // Pass ownership info
            deleteSkitComment={deleteSkitComment}
            formattedTime={comment.formattedTime} // Pass formatted time
          />
          {/* Toggle Threads Button */}
          {threads[comment.id]?.length > 0 && (
            <ImgTopBtn
              className="toggle-threads-btn"
              imgTopBtnBorderRadius="25px"
              imgTopBtnWidth="fit-content"
              imgTopBtnTextFontSize="1.3rem"
              imgTopBtnFontFamily="Roboto-Slab"
              imgTopBtnFontWeight="900"
              imgTopBtnFontColor="var(--color-bloodred)"
              paddingRight="5px"
              paddingLeft="5px"
              extraImgTopBtnStyles={{
                bottom: "20px",
                left: "10px",
              }}
              onClick={() => {
                toggleShowThreads(comment.id);
                fetchThreads(comment.id);
              }}
              imgTopBtnText={
                showThreads[comment.id]
                  ? `Hide ${threads[comment.id]?.length || 0} ${SingularPlural(threads[comment.id]?.length || 0, "Thread")}`
                  : `View ${threads[comment.id]?.length || 0} ${SingularPlural(threads[comment.id]?.length || 0, "Thread")}`
              }
              image={
                showThreads[comment.id] ? (
                  <EyeSlashIcon fillColor="var(--color-bloodred)" />
                ) : (
                  <EyeIcon fillColor="var(--color-bloodred)" />
                )
              }
            />
          )}
          {/* Threads Section */}
          {showThreads[comment.id] && (
            <div className="threads-section">
              {(threads[comment.id] || []).map((thread) => (
                <div
                  key={thread.id}
                  className="thread-section"
                  id={`thread-${thread.id}`}
                >
                  <NewThreadResults
                    thread={thread}
                    heartCount={thread.heartCount} // Pass heart count
                    commentSourceUsername={comment.source.username} // because the source of thread id is addressing the source id of comment id
                    currentUser={currentUser}
                    isHearted={heartedItems[`thread-${thread.id}`]?.hearted ?? false} // Updated
                    responseError={responseError}
                    setResponseError={setResponseError}
                    activeThreadId={
                      activeReply.type === "thread" ? activeReply.id : null
                    }
                    setActiveThreadId={(id) =>
                      setActiveReply({ type: "thread", id })
                    }
                    handleCancelReply={handleCancelReply}
                    handleCreateResponseToThread={handleCreateResponseToThread}
                    replyValue={replyValue}
                    setReplyValue={setReplyValue}
                    replyingTo={replyingTo}
                    setReplyingTo={setReplyingTo}
                    deleteMessagePermissions={deleteMessagePermissions(
                      thread.id,
                      "thread"
                    )} // Pass ownership info
                    deleteCommentThread={deleteCommentThread}
                    formattedTime={thread.formattedTime} // Pass formatted time
                  />
                  {/* Toggle Responses Button */}
                  {responses[thread.id]?.length > 0 && (
                    <ImgTopBtn
                      className="toggle-responses-btn"
                      imgTopBtnWidth="fit-content"
                      imgTopBtnTextFontSize="1.3rem"
                      imgTopBtnFontFamily="Roboto-Slab"
                      imgTopBtnFontWeight="900"
                      imgTopBtnFontColor="var(--color-bloodred)"
                      paddingRight="5px"
                      paddingLeft="5px"
                      extraImgTopBtnStyles={{
                        bottom: "20px",
                        left: "10px",
                      }}
                      onClick={() => {
                        toggleShowResponses(thread.id);
                        fetchResponses(thread.id);
                      }}
                      imgTopBtnText={
                        showResponses[thread.id]
                          ? `Hide ${responses[thread.id]?.length || 0} ${SingularPlural(responses[thread.id]?.length || 0, "Response")}`
                          : `View ${responses[thread.id]?.length || 0} ${SingularPlural(responses[thread.id]?.length || 0, "Response")}`
                      }
                      image={
                        showResponses[thread.id] ? (
                          <EyeSlashIcon fillColor="var(--color-bloodred)" />
                        ) : (
                          <EyeIcon fillColor="var(--color-bloodred)" />
                        )
                      }
                    />
                  )}
                  {/* Responses Section */}
                  {showResponses[thread.id] && (
                    <div className="responses-section">
                      {(responses[thread.id] || []).map((response) => (
                        <ResponseThreadResults
                          key={response.id}
                          heartCount={response.heartCount} // Pass heart count
                          currentUser={currentUser}
                          isHearted={heartedItems[`response-${response.id}`]?.hearted ?? false} // Updated
                          response={response}
                          responseError={responseError}
                          setResponseError={setResponseError}
                          activeResponseId={
                            activeReply.type === "response"
                              ? activeReply.id
                              : null
                          }
                          setActiveResponseId={(id) =>
                            setActiveReply({ type: "response", id })
                          }
                          handleCancelReply={handleCancelReply}
                          handleCreateResponseToThread={
                            handleCreateResponseToThread
                          }
                          replyValue={replyValue}
                          setReplyValue={setReplyValue}
                          replyingTo={replyingTo}
                          setReplyingTo={setReplyingTo}
                          deleteMessagePermissions={deleteMessagePermissions(
                            response.id,
                            "response"
                          )} // Pass ownership info
                          deleteThreadMemberResponse={
                            deleteThreadMemberResponse
                          }
                          formattedTime={response.formattedTime} // Pass formatted time
                        />
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      {totalCommentsCount > 0 && (
        <>
          {loading && <Loader className="View-More-comments" />}
          <div className="view-more-container">
            <ImgTopBtn
              className="toggle-threads-btn"
              imgTopBtnBorderRadius="25px"
              imgTopBtnWidth="fit-content"
              imgTopBtnTextFontSize="1.3rem"
              imgTopBtnFontFamily="Roboto-Slab"
              imgTopBtnFontWeight="900"
              imgTopBtnFontColor="var(--color-bloodred)"
              paddingRight="5px"
              paddingLeft="5px"
              extraImgTopBtnStyles={{
                margin: "20px auto",
              }}
              onClick={handleViewMoreComments}
              imgTopBtnText="View More Comments"
              image={<EyeSlashIcon fillColor="var(--color-bloodred)" />}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default CommentThreads;
