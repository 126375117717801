import React from 'react';
import './animatedPencil.scss';

const AnimatedPencil = () => {
  return (
    <>
      <div className="animatedPencil">
        <div className="rubber"></div>
        <div className="ferrule">
          <div className="stripe"></div>
          <div className="stripe"></div>
        </div>
        <div className="stick">
          <div className="side"></div>
          <div className="side"></div>
          <div className="side"></div>
          <div className="face"></div>
        </div>
        <div className="bottom"></div>
        <div className="graphite"></div>
      </div>
      <div className="AnimatedPeniclline"></div>
    </>
  );
}

export default AnimatedPencil;
