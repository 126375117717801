import React from "react";
import { OvalNeumorphicBtn } from "../../buttons";
import { XIcon, LeftArrowIcon } from "../../../components/icons/index";
import "./modalControlRouterWrapper.scss";

const ModalControlRouterWrapper = ({ title, onBack, onCancel, icon, children }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          {onBack && ( // setting the onback to a conditional prop to control visiblity. I had to nest the modal for a conditional form where the back button is not shown in the first step form, see admanager dasboard to understand visiblity
            <OvalNeumorphicBtn
              onClick={onBack}
              icon={
                <LeftArrowIcon
                  width="15"
                  height="15"
                  fillColor="var(--color-gold)"
                  style={{ paddingRight: "5px" }}
                />
              }
              text="Back"
              fontSize="18px"
              fontFamily="Roboto, sans-serif"
              fontWeight="bold"
            />
          )}
          <div className="modal-title-icon-wrapper">
            {icon && <span className="modal-icon">{icon}</span>}
            <h1 className="modal-title">{title}</h1>
          </div>
          <OvalNeumorphicBtn
            onClick={onCancel}
            icon={
              <XIcon
                width="15"
                height="15"
                fillColor="var(--color-gold)"
                style={{ paddingRight: "5px" }}
              />
            }
            text="Cancel"
            fontSize="18px"
            fontFamily="Roboto, sans-serif"
            fontWeight="bold"
          />
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default ModalControlRouterWrapper;
