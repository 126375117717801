import Resizer from "react-image-file-resizer";

const type = ["image/jpeg", "image/png"];
export default type;

export function getImageFileType(file) {
  if (file.type === "image/jpeg") return "jpeg";
  if (file.type === "image/png") return "png";
  return false;
}

export function getVideoFileType(file) {
  if (file.type.includes("video")) {
    return file.type.split("/")[1]; // This will return 'mp4', 'quicktime', etc.
  }
  return false;
}

export const resizeFile = (file, type) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      250,
      250,
      type,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
