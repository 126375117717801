import React, { useState, useRef } from "react";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";
import BlinkingVerticalTwoBarsIcon from "../../components/blinkingLight/blinkingVerticalTwoBarsIcon";
import { RoundFlatBtns } from "../../components/buttons";
import { UpdateAccountHolderForm } from "../../components/forms";
import "./acctHolderUpdateModal.scss";

const AcctHolderUpdateModal = ({
  show,
  onClose,
  profileData,
  currentUser,
  fetchUserDetails,
  setLoading,
  setProfileData,
}) => {
  // Define all states required for the modal here
  const [emailValue, setEmailValue] = useState("");
  const [emailExistError, setEmailExistError] = useState("");
  const [emailValueError, setEmailValueError] = useState("");
  const [userNameValue, setUserNameValue] = useState("");
  const [userNameValueError, setUsernameError] = useState("");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [phoneNumberValueError, setPhoneNumberValueError] = useState("");
  const [phoneNumberSuggestionError, setPhoneNumberSuggestionError] =
    useState("");
  const [missingPhoneNumberDigitsError, setMissingPhoneNumberDigitsError] =
    useState("");
  const [suggestedUsernames, setSuggestedUsernames] = useState([]);
  const [userNameValidationRules, setUserNameValidationRules] = useState([]);
  const [userNameToolTipVisible, setUserNameToolTipVisible] = useState(false);
  const [resizeError, setResizeError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [presignedImgUrlResponse, setPresignedImgUrlResponse] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [thumbnailPreference, setThumbnailPreference] = useState(null);
  const [showAllEmojis, setShowAllEmojis] = useState(false);
  const [currentEmotionId, setCurrentEmotionId] = useState(null);
  const [backendErrorMessage, setBackendErrorMessage] = useState("");
  const fileInputRef = useRef(null);

  // Clear all states when closing the modal
  const closeClearUploadState = (currentContext =  "accountHolderUpdate") => {
    // Clear the file input if it exists
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  
    console.log("Current Context:", currentContext);
    // Generate and log storage keys
    const emailStorageKey = getStorageKey("emailValue", currentContext);
    const usernameStorageKey = getStorageKey("userNameValue", currentContext);
    const phoneNumberStorageKey = getStorageKey(
      "phoneNumberValue",
      currentContext
    );
    const thumbnailPreferenceKey = getStorageKey(
      "thumbNailPreference",
      currentContext
    );

    console.log(
      "Removing keys:",
      emailStorageKey,
      usernameStorageKey,
      phoneNumberStorageKey,
      thumbnailPreferenceKey
    );

    // Remove items from localStorage
    localStorage.removeItem(emailStorageKey, currentContext);
    localStorage.removeItem(usernameStorageKey, currentContext);
    localStorage.removeItem(phoneNumberStorageKey, currentContext);
    localStorage.removeItem(thumbnailPreferenceKey, currentContext);
    setEmailValue("");
    setEmailExistError("");
    setEmailValueError("");
    setUserNameValue("");
    setUsernameError("");
    setPhoneNumberValue("");
    setPhoneNumberValueError("");
    setEmailExistError("");
    setEmailValueError("");
    setUserNameValue("");
    setUsernameError("");
    setPhoneNumberValue("");
    setPhoneNumberSuggestionError("");
    setMissingPhoneNumberDigitsError("");
    setSuggestedUsernames([]);
    setUserNameValidationRules([]);
    setUserNameToolTipVisible(false);
    setResizeError(false);
    setFileError(false);
    setPresignedImgUrlResponse(null);
    setFileUpload(null);
    setImagePreviewUrl("");
    setThumbnailPreference(null);
    setShowAllEmojis(false);
    setCurrentEmotionId(null);
    setBackendErrorMessage("");
    // Close the modal
    // Clear relevant localStorage keys

    onClose();
  };

  if (!show) {
    return null;
  }

  return (
    <div className="acct-holder-update-modal-backdrop">
      <div className="acct-holder-update-modal-content">
        <RoundFlatBtns
          onClick={closeClearUploadState} // Use the clear function to handle close
          Icon={BlinkingVerticalTwoBarsIcon}
          title="Close"
          iconProps={{ height: "20", width: "20" }}
          titleStyle={{
            fontSize: "1.3rem",
            margin: "0",
            textAlign: "center",
            fontWeight: "800",
            color: "var(--color-gold)",
          }}
          extraStyles={{
            width: "80px",
            height: "80px",
            padding: "1px",
          }}
        />

        {/* Embed UpdateAccountHolderForm */}
        <UpdateAccountHolderForm
          setProfileData={setProfileData}
          profileData={profileData}
          currentUser={currentUser}
          fetchUserDetails={fetchUserDetails}
          setLoading={setLoading}
          phoneNumberSuggestionError={phoneNumberSuggestionError}
          setPhoneNumberSuggestionError={setPhoneNumberSuggestionError}
          missingPhoneNumberDigitsError={missingPhoneNumberDigitsError}
          setMissingPhoneNumberDigitsError={setMissingPhoneNumberDigitsError}
          emailValue={emailValue}
          setEmailValue={setEmailValue}
          emailExistError={emailExistError}
          setEmailExistError={setEmailExistError}
          emailValueError={emailValueError}
          setEmailValueError={setEmailValueError}
          userNameValue={userNameValue}
          setUserNameValue={setUserNameValue}
          userNameValueError={userNameValueError}
          setUsernameError={setUsernameError}
          phoneNumberValue={phoneNumberValue}
          setPhoneNumberValue={setPhoneNumberValue}
          phoneNumberValueError={phoneNumberValueError}
          setPhoneNumberValueError={setPhoneNumberValueError}
          suggestedUsernames={suggestedUsernames}
          setSuggestedUsernames={setSuggestedUsernames}
          userNameValidationRules={userNameValidationRules}
          setUserNameValidationRules={setUserNameValidationRules}
          userNameToolTipVisible={userNameToolTipVisible}
          setUserNameToolTipVisible={setUserNameToolTipVisible}
          resizeError={resizeError}
          setResizeError={setResizeError}
          fileError={fileError}
          setFileError={setFileError}
          presignedImgUrlResponse={presignedImgUrlResponse}
          setPresignedImgUrlResponse={setPresignedImgUrlResponse}
          fileUpload={fileUpload}
          setFileUpload={setFileUpload}
          imagePreviewUrl={imagePreviewUrl}
          setImagePreviewUrl={setImagePreviewUrl}
          thumbnailPreference={thumbnailPreference}
          setThumbnailPreference={setThumbnailPreference}
          showAllEmojis={showAllEmojis}
          setShowAllEmojis={setShowAllEmojis}
          currentEmotionId={currentEmotionId}
          setCurrentEmotionId={setCurrentEmotionId}
          backendErrorMessage={backendErrorMessage}
          setBackendErrorMessage={setBackendErrorMessage}
          fileInputRef={fileInputRef}
        />
      </div>
    </div>
  );
};

export default AcctHolderUpdateModal;
