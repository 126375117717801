import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiFetchUserSubscribers } from "../../api/backend/user";
import SubscribersList from "../../components/subscriptions/subscribersList";
import { LeftArrow } from "../../components/icons";
import { SolidColorButton } from "../../components/buttons";
import Loader from "../../components/loader/loader";
import { SingularPlural } from "../../utils/wordUtils"; // Import the helper function

const SubscribersListPage = () => {
  let navigate = useNavigate();
  const { userId } = useParams(); // Extract userId from URL parameters
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10; // Number of subscribers to fetch per request

  useEffect(() => {
    const fetchSubscribers = async () => {
      setLoading(true);
      try {
        const response = await apiFetchUserSubscribers({
          targetId: userId,
          offset,
          limit,
        });
        console.log("API Response:", response);
        const subscribersList = response.data ? response.data : response;
        if (subscribersList.length) {
          setSubscribers((prev) => [...prev, ...subscribersList]);
          setMessage("");
          if (subscribersList.length < limit) setHasMore(false);
        } else {
          setSubscribers([]);
          setMessage("Create Skits that Vibe, so Users Subscribe");
          setHasMore(false);
        }
      } catch (err) {
        console.error("Failed to fetch subscribers:", err);
        setMessage("Failed to load subscribers");
      } finally {
        setLoading(false);
      }
    };
    fetchSubscribers();
  }, [userId, offset]);

  const handleViewMore = () => {
    setOffset((prevOffset) => prevOffset + limit);
  };

  return (
    <div id="subscribersTop">
      <SolidColorButton
        solidColorBtnImage={
          <LeftArrow
            height="15px"
            width="15px"
            fillColor="#964b00"
            strokeWidth="4"
            strokeColor="#964b00"
          />
        }
        className="gold-btn"
        solidColorBtnText="Back"
        solidColorBtnFontWeight="800"
        solidColorBtnFontFamily="helvetica"
        solidColorBtnTextFontSize=".75rem"
        solidColorBtnFontColor="#964b00"
        solidColorBtnWidth="100px"
        solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
        onClick={() => navigate(-1)}
      />
      {subscribers.length > 0 && (
        <h2>
          {subscribers.length}{" "}
          {SingularPlural(subscribers.length, "Subscriber")}
        </h2>
      )}
      {loading && offset === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <SubscribersList
          subscribers={subscribers}
          hasMore={hasMore}
          handleViewMore={handleViewMore}
          loading={loading}
          message={message}
        />
      )}
    </div>
  );
};
export default SubscribersListPage;
