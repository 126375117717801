import './loader.scss'; // Assuming you have an SCSS file for the loader styles

const Loader = ({ className = '' }) => (
    // the loader wrapper needs to be position relative or it won't show, the classname will be custom for each component
    // do not use loader wrapper for specifity reasons. Look at my examples on how I changed the class name and set the position styles locally
    <div className={`loader-wrapper ${className}`}>
    <div className="loader" />
  </div>
);

export default Loader;
