import React, { useState, useEffect } from "react";

const BlinkingXIcon = ({ width = "12.728", height = "12.727", style = {} }) => {
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsOn(oldIsOn => !oldIsOn); // Toggle the state to switch colors
    }, 1000); // Change colors every second

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const fillColor =  isOn
  ? "var(--color-redish)"
  : "var(--color-corveteRed)"; 
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} style={style} viewBox="0 0 12.728 12.727">
      <g transform="translate(-8.514 -8.518)">
        <path
          d="M19.377,10.38a6.364,6.364,0,1,0,0,9A6.365,6.365,0,0,0,19.377,10.38Zm-2.271,7.628-2.229-2.229-2.229,2.229-.9-.9,2.229-2.229-2.228-2.228.9-.9,2.228,2.228,2.228-2.229.9.9L15.777,14.88l2.229,2.229Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

export default BlinkingXIcon;