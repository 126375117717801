import { useState, useEffect, useCallback } from "react";
import { apiFetchLiveVideoAdCampaigns } from "../../api/backend/campaign";

export const useAdCampaigns = () => {
  const [ads, setAds] = useState(
    () => JSON.parse(localStorage.getItem("ads")) || []
  );
  const [adPointer, setAdPointer] = useState(
    () => parseInt(localStorage.getItem("adPointer"), 10) || 0
  );

  // Persist ads and pointer in localStorage
  useEffect(() => {
    localStorage.setItem("ads", JSON.stringify(ads));
  }, [ads]);

  useEffect(() => {
    localStorage.setItem("adPointer", adPointer.toString());
  }, [adPointer]);

  // If no campaigns are returned (campaigns.length === 0),
  // the function recursively calls itself without a lastId
  // since last id is hard coded and set to null on backend then
  // the database will call the first record of the ad
  const fetchCampaigns = useCallback(async (lastId = null) => {
    try {
      const campaigns = await apiFetchLiveVideoAdCampaigns(lastId);
      if (campaigns.length > 0) {
        const newAds = campaigns.map((ad) => ({
          adId: ad.id,
          mediaAdUrl: ad.mediaAdUrl,
        }));
        setAds((prevAds) => (lastId ? [...prevAds, ...newAds] : newAds));
        if (!lastId) setAdPointer(0); // Reset pointer for a fresh fetch
      } else {
        console.log("No campaigns found. Reinitializing...");
        fetchCampaigns(); // Reinitialize --> (campaigns.length === 0),
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  }, []);

  // Get the next ad
  const getNextAd = useCallback(() => {
    if (ads.length === 0) {
      console.warn("No ads in local storage.");
      return null;
    }
    // Get the current pointer from localStorage if needed
    const storedPointer =
      parseInt(localStorage.getItem("adPointer"), 10) || adPointer;
    const nextAd = ads[storedPointer];
    const newPointer = (storedPointer + 1) % ads.length;

    console.log("Before Increment:", adPointer);

    // Fetch next batch of ads when pointer loops back
    if (newPointer === 0) {
      fetchCampaigns(ads[ads.length - 1]?.adId); // Fetch new ads
    }

    setAdPointer(newPointer); // Increment pointer globally
    console.log("After Increment:", newPointer);

    localStorage.setItem("adPointer", newPointer.toString()); // Sync with local storage

    return nextAd;
  }, [ads, adPointer, fetchCampaigns]);

  // Initialize ads on mount if empty
  useEffect(() => {
    if (ads.length === 0) fetchCampaigns();
  }, [fetchCampaigns, ads]);

  return { ads, getNextAd, fetchCampaigns };
};
