import React from 'react';
import "./animatedStar.scss";

const AnimatedanimatedStar = () => (
  <div className="animatedStarWrapper">
    <div className="animatedStar">
      <div className="animatedStar-line"></div>
      <div className="animatedStar-default-face">
        <div className="animatedStar-default-expression">
          <div className="animatedStar-default-eyes animatedStar-default-eye-left"></div>
          <div className="animatedStar-default-eyes animatedStar-default-eye-right"></div>
          <div className="animatedColorStarGlasses ">
            <div className="lens left-lens"></div>
            <div className="lens right-lens"></div>
            <div className="animatedTongue-mouth">
              <div className="animatedTongue"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AnimatedanimatedStar;
