import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";


export const handlePhoneInputChange = (
  value,
  currentContext,
  acctPhoneNumberErrors,
  setAcctPhoneNumberValue,
  setAcctPhoneNumberErrors
) => {
  setAcctPhoneNumberValue(value); // Update state
  const Key = getStorageKey("verifyAccountValue", currentContext);
  console.log("Generated localStorage Key:", Key); // Debugging the generated key
  console.log("Phone Input Value:", value); // Debugging the input value
  localStorage.setItem(Key, value);
  if (acctPhoneNumberErrors) {
    setAcctPhoneNumberErrors("");
  }
};
