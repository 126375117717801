import React, { useState, useEffect } from "react";
import { renderUserThumbnail } from "../../controller/userThumbnail";
import { SmallPlainInsetBtnEventListener, PlainBtn } from "../buttons";
import CurrentUserReply from "./currentUserReply";
import { SmileyBubblyChat, XIcon } from "../icons/index";
import { renderCommentWithEmotes } from "../../utils/emoteUtils";
import { apiHeartComment, apiUnheartComment } from "../../api/backend/comment";
import "./skitCommentResults.scss";

const SkitCommentResults = ({
  comment,
  currentUser,
  activeCommentId,
  setActiveCommentId,
  handleCancelReply,
  handleCreateInitialThread,
  threadValue,
  setThreadValue,
  replyingTo,
  setReplyingTo,
  newThreadError,
  setNewThreadError,
  isHearted, // linked to item which is comment type and a bolean that checks the list from the backend and is defined with this prop which sets state here to help control the heart button ui for the user
  deleteMessagePermissions,
  deleteSkitComment,
  formattedTime,
}) => {
  const [hearted, setHearted] = useState(isHearted);
  // the heart count will count the index in the array
  const [heartCount, setHeartCount] = useState(comment.heartCount); // Initialize based on backend if a user unhearts we destroy the heart record for the related id after count is decremented on the correct comment or message type table
  // Sync `hearted` state with `isHearted` prop
  useEffect(() => {
    console.log(
      `Updating hearted state for comment ${comment.id}: isHearted=${isHearted}`
    );
    setHearted(isHearted);
  }, [isHearted, comment.id]);

  const handleHeartClick = async () => {
    try {
      if (hearted) {
        const response = await apiUnheartComment(comment.id, currentUser);
        if (response.data.success) {
          setHeartCount(response.data.heartCount);
          setHearted(false);
        }
      } else {
        const response = await apiHeartComment(comment.id, currentUser);
        if (response.data.success) {
          setHeartCount(response.data.heartCount);
          setHearted(true);
        }
      }
    } catch (error) {
      console.error("Error updating heart status:", error);
      setNewThreadError("Failed to update heart status. Please try again.");
    }
  };

  if (!comment || !comment.source) {
    console.error("Comment or Comment source is undefined", comment);
    return null;
  }
  const handleCancel = () => {
    handleCancelReply("comment", comment.id);
    setThreadValue(""); // Clear the input value
    setNewThreadError(""); // Clear the error message
  };

  const renderMessage = () => {
    const parts = renderCommentWithEmotes(comment.message);
    return parts.map((part, index) => (
      <span key={index} className="message-part">
        {part}
      </span>
    ));
  };

  return (
    <div key={comment.id} id="skit-comment" className="comment-container">
      <div className="comment-bubble-container">
        <div className="comment-bubble-wrapper">
          {renderUserThumbnail(comment.source)}
          <div className="comment-bubble">
            <span className="messageValue">
              {renderMessage()} &nbsp; &nbsp;{" "}
              <span className="username">
                {" "}
                - {comment.source.username}&nbsp;
                <span className="formatted-time">·{formattedTime}</span>
              </span>
            </span>
          </div>
        </div>
        {/* Position the formattedTime here */}
        <div className="reply-actions-wrapper">
          <SmallPlainInsetBtnEventListener
            image={
              <SmileyBubblyChat
                fillColor="#b03a2e"
                style={{ position: "relative", bottom: "-7px" }}
              />
            }
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "1.3rem",
              fontWeight: "900",
              lineHeight: "14px",
            }}
            width="fit-content"
            className="start-thread-btn"
            text="Reply" // replying to comment starts a new thread a comment can have many threads
            onClick={() => {
              setActiveCommentId(comment.id);
              setReplyingTo(comment.source);
              setNewThreadError(""); // Clear error when starting a new reply
            }}
          />
          {deleteMessagePermissions && (
            <SmallPlainInsetBtnEventListener
              image={
                <XIcon
                  fillColor="#b03a2e"
                  style={{ position: "relative", paddingRight: "5px" }}
                />
              }
              style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "1.3rem",
                fontWeight: "900",
                lineHeight: "14px",
              }}
              width="fit-content"
              className="delete-comment-btn"
              text="Delete" // allows the current user who as permissions to delete the comment, and all thread and thread response children
              onClick={() => deleteSkitComment(comment.id)}
            />
          )}
          <PlainBtn
            plainBtnText={hearted ? "UnHeart" : "Heart"}
            plainBtnTextFontSize="1.3rem"
            plainBtnHeight="fit-content"
            plainBtnFontFamily="Roboto"
            plainBtnFontWeight="bold"
            plainBtnFontColor="#b03a2e"
            plainBtnBorderRadius="30px"
            onClick={handleHeartClick}
            image={
              <span
                role="img"
                aria-label="heart"
                className={hearted ? "broken-heart" : "heart-beat"}
              >
                {hearted ? "💔" : "❤️"}
              </span>
            }
            imageOnTop={false} // Set to true if you want the emoji above the text
            extraStyles={{
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
          />
          {heartCount > 0 && (
            <span className="heart-count"> ❤️ {heartCount}</span>
          )}
        </div>
        {activeCommentId === comment.id && (
          <div>
            <CurrentUserReply
              currentUser={currentUser}
              type="comment"
              id={comment.id}
              replyingTo={replyingTo}
              onSubmitReply={() => handleCreateInitialThread(comment.id)}
              onCancel={handleCancel}
              value={threadValue}
              setValue={setThreadValue}
              placeholder="Write a thread message..."
              newThreadError={newThreadError} // Pass error state
              setNewThreadError={setNewThreadError} // Pass setter for error state
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default SkitCommentResults;
