import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { WhiteGoldButton } from "../buttons";
import { statesList } from "../../constants/statesList";
import "./stateDropdown.scss";

const StateDropdown = ({
  selectedState,
  setSelectedState,
  isOpen,
  onToggle,
  clearState,
  className,
  error,
  placeholder,
}) => {
  const dropdownRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false); // Track if keyboard navigation is active

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      onToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const index = statesList.findIndex((state) =>
        new RegExp(`^${searchTerm}`, "i").test(state)
      );
      if (index !== -1) {
        setFocusedIndex(index);
      }
    }
  }, [searchTerm]);

  const handleDropdownKeyNavigation = (e) => {
    const updateIndex = (currentIndex, increment) => {
      const newIndex = currentIndex + increment;
      return newIndex < 0
        ? statesList.length - 1
        : newIndex >= statesList.length
          ? 0
          : newIndex;
    };
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusedIndex(updateIndex(focusedIndex, 1));
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex(updateIndex(focusedIndex, -1));
    } else if (e.key === "Enter") {
      setSelectedState(statesList[focusedIndex]);
      onToggle(false);
    } else if (/^[a-z0-9]$/i.test(e.key)) {
      // If the key is a letter or number
      setSearchTerm((prev) => prev + e.key);
      // Find the first state that matches the current search term and set that as the focused item
      const index = statesList.findIndex((state) =>
        new RegExp(`^${searchTerm}`, "i").test(state)
      );
      if (index !== -1) {
        setFocusedIndex(index);
      }
      // Reset the search term after a short delay
      setTimeout(() => {
        setSearchTerm("");
      }, 1000); // 1 second delay
    }
  };
  const handleMouseOver = (index) => {
    if (!isKeyboardNavigation) {
      setFocusedIndex(index);
    }
  };

  const handleMouseLeave = () => {
    if (!isKeyboardNavigation) {
      setFocusedIndex(-1);
    }
  };

  const handleOptionClick = (state) => {
    setSelectedState(state);
    onToggle(false);
    setIsKeyboardNavigation(false); // Reset keyboard navigation state
  };

  const selectedStateStyles = selectedState
    ? {
        color: "var(--color-black)",
        fontSize: "1.3rem",
      }
    : {};

  // Move DownArrow component here to solve the import problem it was having
  const DownArrow = ({
    fillColor = "#964b00",
    width = "12.592",
    height = "8.906",
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12.592 8.906"
      style={{ marginLeft: "5px" }} // Since we're moving styles here
    >
      <path
        id="arrow_down-01_1_"
        data-name="arrow down-01 (1)"
        d="M4.4,7.45l6.3,8.906,6.3-8.906Z"
        transform="translate(-4.396 -7.449)"
        fill={fillColor}
      />
    </svg>
  );

  return (
    <div
      className={`state-select ${
        selectedState ? "state-selected" : ""
      } ${className}`}
      ref={dropdownRef}
      onKeyDown={handleDropdownKeyNavigation}
      style={selectedStateStyles}
    >
      {selectedState && (
        <div className="state-label-container">
          <WhiteGoldButton
            onClick={clearState}
            whiteGoldBtnText="Clear State"
            whiteGoldBtnFontColor="var(--color-bloodred)"
            whiteGoldBtnWidth="fit-content"
            whiteGoldBtnFontWeight="800"
            whiteGoldBtnFontFamily="roboto-slab"
            whiteGoldBtnTextFontSize="1rem"
          />
        </div>
      )}
      <div onClick={() => onToggle(!isOpen)}>
        <div className={`state-select-content ${error ? "error" : ""}`}>
          {selectedState ? (
            <span className="selected-state">{selectedState}</span>
          ) : (
            <span className="state-placeholder">
              {error || placeholder || "Select State"}
            </span>
          )}
          <DownArrow fillColor="var(--color-bloodred)" />
        </div>
      </div>

      {isOpen && (
        <div className="state-options">
          {statesList.map((state, index) => (
            <div
              key={state}
              onClick={() => handleOptionClick(state)}
              onMouseOver={() => handleMouseOver(index)}
              onMouseLeave={handleMouseLeave}
              tabIndex={-1}
              ref={(el) => {
                if (focusedIndex === index && el) el.focus();
              }}
              className={focusedIndex === index ? "focused" : ""}
            >
              {state}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

StateDropdown.propTypes = {
  selectedState: PropTypes.string,
  setSelectedState: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  clearState: PropTypes.func,
  className: PropTypes.string,
};

export default StateDropdown;
