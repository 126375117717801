import React, { useState, useRef, useEffect } from "react";
import "./toggleSlider.scss";

const ToggleSlider = ({
  leftLabel = "",
  rightLabel = "",
  defaultPosition = "right",
  handleChangeSlider,
  activeContent = "ON",
  inactiveContent = "OFF",
  sliderWrapperStyle = {},
  sliderTextStyle = {},
  className = "",
}) => {
  const [active, setActive] = useState(defaultPosition === "right");
  const sliderRef = useRef(null);
  const toggleRef = useRef(null);
  const isDragging = useRef(false);
  const [posX, setPosX] = useState(active ? 47 : 0); // Initial position

  // Clamp function ensures the toggle stays within bounds
  const clamp = (min, value, max) => Math.max(min, Math.min(value, max));

  const handlePointerDown = (e) => {
    e.preventDefault();
    isDragging.current = true;
  };


  const handlePointerMove = (e) => {
    if (!isDragging.current || !sliderRef.current || !toggleRef.current) return;

    const sliderBounds = sliderRef.current.getBoundingClientRect();
    const pointerX = e.clientX || e.touches[0].clientX;

    // Calculate and clamp position
    const pos =
      pointerX - sliderBounds.left - toggleRef.current.offsetWidth / 2;
    const clampedPos = clamp(
      0,
      pos,
      sliderBounds.width - toggleRef.current.offsetWidth
    );

    setPosX(clampedPos);

    // Dynamically update the `active` state during drag
    const sliderWidth = sliderBounds.width;
    const threshold = sliderWidth / 2;

    if (clampedPos >= threshold - 20) {
      setActive(true); // Update to "ON" as slider approaches the right
    } else {
      setActive(false); // Update to "OFF" as slider approaches the left
    }
  };

  const handlePointerUp = () => {
    if (!sliderRef.current) return;
    isDragging.current = false;

    // Snap to ON/OFF based on position
    const sliderWidth = sliderRef.current.offsetWidth;
    const threshold = sliderWidth / 2;

    if (posX >= threshold - 20) {
      setActive(true);
      setPosX(47); // Right position for "ON"
      if (handleChangeSlider) handleChangeSlider("right");
    } else {
      setActive(false);
      setPosX(0); // Left position for "OFF"
      if (handleChangeSlider) handleChangeSlider("left");
    }
  };

  // Global pointer/touch listeners
  useEffect(() => {
    document.addEventListener("pointermove", handlePointerMove);
    document.addEventListener("pointerup", handlePointerUp);
    document.addEventListener("touchmove", handlePointerMove);
    document.addEventListener("touchend", handlePointerUp);

    return () => {
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerup", handlePointerUp);
      document.removeEventListener("touchmove", handlePointerMove);
      document.removeEventListener("touchend", handlePointerUp);
    };
  }, [posX]);

  return (
    <div className={`slider-wrapper ${className}`} style={sliderWrapperStyle}>
      {/* Label for clarity */}
      <span
        className="slider-label"
        style={{
          fontWeight: "bold",
          fontSize: "1rem",
          color: "var(--color-offWhite)",
          fontFamily: "robo-slab",
        }}
      >
        Auto Pay Slider
      </span>
      <div>
        <label className="checkbox-slider" ref={sliderRef}>
          <span style={sliderTextStyle}>{active ? rightLabel : leftLabel}</span>
          <input
            type="checkbox"
            checked={active}
            readOnly
            aria-checked={active}
          />
          <div className={`inner ${active ? "active" : ""}`}>
            <div
              className="toggle"
              style={{ left: `${posX}px` }}
              data-content={active ? activeContent : inactiveContent}
              onPointerDown={handlePointerDown}
              onTouchStart={handlePointerDown}
              ref={toggleRef}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export default ToggleSlider;