import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { apifetchCurrentUserNotifications, apiMarkNotificationAsRead, apiMarkAllNotificationsAsRead } from '../../api/backend/user';
import { useAuth } from './authContext';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchNotifications = useCallback(async () => {
    console.log("Fetching notifications started for user:", currentUser);
    if (!currentUser) {
      console.warn("fetchNotifications called without a userId.");
      return;
    }
    try {
      const response = await apifetchCurrentUserNotifications({ userId: currentUser });
      console.log("Fetched notifications successfully:", response.data);
      setNotifications(response.data);
      setUnreadCount(
        response.data.filter(
          (notification) => !notification.read_by_targetId
        ).length
      );
    } catch (error) {
      console.error("Error during notification fetch:", error);
    } finally {
      console.log("Fetching notifications finished.");
    }
  }, [currentUser]);
  
  

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const markNotificationAsRead = async (notificationId) => {
    try {
      await apiMarkNotificationAsRead(notificationId);
      setNotifications((prev) => prev.filter((n) => n.id !== notificationId));
      setUnreadCount((prev) => prev - 1);
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  const clearAllNotifications = async () => {
    try {
      await apiMarkAllNotificationsAsRead(currentUser);
      setNotifications([]);
      setUnreadCount(0);
    } catch (error) {
      console.error("Failed to clear all notifications:", error);
    }
  };

  return (
    <NotificationContext.Provider value={{ notifications, unreadCount, fetchNotifications, markNotificationAsRead, clearAllNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);
