import React, { useState, useEffect } from 'react';
import './starDashboardRating.scss'; // Make sure to create this CSS file

const CurrentAveragedRating = ({ rating }) => {
  const [averageScore, setAverageScore] = useState(0);

  useEffect(() => {
    setAverageScore(parseFloat(rating));
  }, [rating]);

  return (
    <div className="starRatingDashboard">
      {[...Array(5)].map((_, index) => {
        const isFilled = index + 1 <= averageScore;
        return (
          <div
            key={index}
            style={{
              color: isFilled ? '#FFD700' : '#E0E0E0', // Gold for filled, grey for unfilled
            }}
          >
            {isFilled ? '★' : '☆'}
          </div>
        );
      })}
      <span>{averageScore.toFixed(2)}</span>
    </div>
  );
};
export default CurrentAveragedRating;
