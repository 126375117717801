import React, { useState } from "react";
import "./neumorphismField.scss";

const NeumorphismField = ({
  label,
  type,
  value,
  onChange,
  className,
  placeholder, // Accept placeholder as a prop
  onFocus, // pass this out onFocus to the component on the parent for a call back from there to here
  onBlur,  // Parent onBlur callback (same description as above)
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e) => {
    onChange({ target: { value: e.target.value } });
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(); // Call parent onFocus callback if provided
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(); // Call parent onBlur callback if provided
    }
  };

  return (
    <div className={`neumorphic-field ${className}`}>
      <label>
        {label}
        <input
          type={type}
          value={value}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={className} // Make sure the error class is passed to input
          placeholder={placeholder} // Add the placeholder prop here
        />
        {/* Blinking cursor if not focused and no value */}
        {!isFocused && !value && (
          <span className="blinking-cursor neumorphismfield-cursor">|</span>
        )}
      </label>
    </div>
  );
};

export default NeumorphismField;
