import React from "react";
import { SkitsOlympicLogoIcon } from "../icons";
import "./footer.scss";

function Footer() {
  return (
    <div id="footer">
      <div className="footer-links">
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
        <a href="#faq">Faq</a>
        <a href="#terms">Terms</a>
        <a href="#privacy">Privacy</a>
      </div>
      <p className="siteOwner">A Laura Phipps production</p>
      <p className="logo-text">
        <SkitsOlympicLogoIcon
          fillColor="var(--color-darkGold)"
          width="15px"
          height="15px"
        />
        <span className="trademark">™</span>
        <span className="copyright">© 2023</span>
      </p>
      <p className="copyRightName">Skits Olympic © 2023</p>
    </div>
  );
}

export default Footer;
