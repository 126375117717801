import React, { useState } from 'react';
import './starRating.scss'; // Make sure to create this CSS file

const StarRating = ({ rating, setRating }) => {
  const [hover, setHover] = useState(0);

  return (
    <div className="star-rating">
      {[...Array(5)].map((_, index) => {
        const isFilled = index + 1 <= rating || index + 1 <= hover;
        return (
          <button
            type="button"
            key={index}
            className={`star-button ${isFilled ? 'on' : 'off'}`}
            onClick={() => setRating(index + 1)}
            onMouseEnter={() => setHover(index + 1)}
            onMouseLeave={() => setHover(rating)}
          >
            {/* The star will be filled or outlined based on isFilled */}
            {isFilled ? '★' : '☆'}
          </button>
        );
      })}
    </div>
  );
};

export default StarRating;
