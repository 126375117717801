export const rewardLevelIcons = (level) => {
  switch (level) {
    case "star":
      return "/starlevel.svg";
    case "silver":
      return "/silverlevel.svg";
    case "gold":
      return "/goldlevel.svg";
    case "platinum":
      return "/platinumlevel.svg";
    case "diamond":
      return "/diamondlevel.svg";
    default:
      return "/starlevel.svg";
  }
};


