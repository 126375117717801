import React from 'react'
import './spotLight.scss'

function SpotLight() {
  return (
    <div className="ag-spotlight-moving_box">
        <div className="ag-spotlight-moving_spotlight ag-spotlight-moving_spotlight-right"></div>
        <div className="ag-spotlight-moving_spotlight ag-spotlight-moving_spotlight-left"></div>
    </div>
  )
}

export default SpotLight