export const checkVideoFile = (fileName, mimeType, fileSize) => {
  // Define allowed video MIME types
  const allowedMimeTypes = ['video/mp4', 'video/webm', 'video/ogg', 'video/quicktime'];
  // const allowedMimeTypes = ["video/mp4"];
  const allowedExtensions = {
    "video/mp4": ["mp4"],
    // will get an unsupported in the browser, since we are doing a pre-assign url, ffmpeg does not make sense right now, next version we will auto convert
    'video/webm': ['webm'],
    'video/ogg': ['ogg'],
    'video/quicktime': ['mov'],
  };
  const extension = fileName
    .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
    .toLowerCase();
  // Check for supported MIME type
  if (!allowedMimeTypes.includes(mimeType)) {
    throw new Error("Unsupported media type for file, please upload either an .mp4 or a .mov file");
  }
  // Ensure the file's extension matches one of the MIME type's allowed extensions
  if (!allowedExtensions[mimeType]?.includes(extension)) {
    throw new Error("File extension does not match MIME type");
  }
  // Check if the file size exceeds the predefined limit (e.g., 10MB)
  // if (fileSize > 10 * 1024 * 1024) {
  //   throw new Error('File size exceeds 10MB');
  // } maybe add this later
};
