import React, { useState, useEffect, useRef } from "react";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";
import { DownArrow } from "../icons";
import "./yearDropdown.scss";

function YearSelect({
  value,
  onChange,
  options,
  isOpen,
  onToggle,
  currentContext = "createCampaign",
  error,
  className,
  resetYearDropdown, // Trigger reset from the parent
  onResetYearComplete, // Notify parent when reset is complete
}) {
  const storageKey = getStorageKey("ccExpYear", currentContext); // Use the passed currentContext
  const [selectedYear, setSelectedYear] = useState(value);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false); // Track keyboard navigation state

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      onToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);
    // Reset logic
    useEffect(() => {
      if (resetYearDropdown) {
        clearYear(); // Trigger reset when `resetDropdown` is true
        if (onResetYearComplete) onResetYearComplete(); // Notify parent that reset is complete
      }
    }, [resetYearDropdown, onResetYearComplete]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const index = options.findIndex((year) =>
        new RegExp(`^${searchTerm}`, "i").test(year.toString())
      );
      if (index !== -1) {
        setFocusedIndex(index);
      }
    }
  }, [searchTerm, options]);

  const handleDropdownKeyNavigation = (e) => {
    const updateIndex = (currentIndex, increment) => {
      const newIndex = currentIndex + increment;
      return newIndex < 0
        ? options.length - 1
        : newIndex >= options.length
          ? 0
          : newIndex;
    };

    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusedIndex(updateIndex(focusedIndex, 1));
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex(updateIndex(focusedIndex, -1));
    } else if (e.key === "Enter") {
      const selected = options[focusedIndex];
      handleYearChange(selected); // Centralized state update logic
      onToggle(false);
    } else if (/^[0-9]$/.test(e.key)) {
      setSearchTerm((prev) => prev + e.key);
      const index = options.findIndex((year) =>
        new RegExp(`^${searchTerm}`, "i").test(year.toString())
      );
      if (index !== -1) {
        setFocusedIndex(index);
      }
      setTimeout(() => {
        setSearchTerm("");
      }, 1000);
    }
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    onChange({ target: { value: year } });
    localStorage.setItem(storageKey, year); // Store the selected year in local storage
  };

  const handleMouseOver = (index) => {
    if (!isKeyboardNavigation) {
      setFocusedIndex(index);
    }
  };

  const handleMouseLeave = () => {
    if (!isKeyboardNavigation) {
      setFocusedIndex(-1);
    }
  };

  // Function to clear selected year
  const clearYear = () => {
    setSelectedYear(""); // Reset the selected year
    onChange({ target: { value: "" } }); // Clear the parent state as well
    localStorage.removeItem(storageKey); // Remove the year from local storage
  };

  return (
    <div
    className={`year-select ${className}`} 
      ref={dropdownRef}
      onKeyDown={handleDropdownKeyNavigation}
    >
      <div className="year-select-header" onClick={() => onToggle(!isOpen)}>
        <div className="year-select-content">
          {!selectedYear && (
            <div className="year-placeholder">
              {error ? error : "Select Year"}
            </div>
          )}
          {selectedYear && <div className="selected-year">{selectedYear}</div>}
          <DownArrow
            fillColor="var(--color-bloodred)"
            onClick={() => onToggle(!isOpen)}
          />
        </div>
      </div>

      {isOpen && (
        <div className="year-dropdown-options">
          {options.map((year, index) => (
            <div
              key={year}
              className={index === focusedIndex ? "focused" : ""}
              onClick={() => {
                handleYearChange(year); // Centralized handling for year selection
                onToggle(false);
              }}
              onMouseOver={() => handleMouseOver(index)}
              onMouseLeave={handleMouseLeave}
              tabIndex={-1}
              ref={(el) => {
                if (focusedIndex === index && el) el.focus();
              }}
            >
              {year}
            </div>
          ))}
        </div>
      )}

      {/* Show the "Clear" button only when a year is selected */}
      {selectedYear && (
        <button type="button" onClick={clearYear} className="clear-yr-btn">
          Clear
        </button>
      )}
    </div>
  );
}

export default YearSelect;
