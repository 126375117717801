import "./television.scss";
import AuthorizationForm from "../forms/authorizationForm";

const Television = () => {
  const text = () => {
    return (
      <div className="tv_text">
        <p>
          <span className="boldNineHun">
            Win a chance to star in a feature skit and earn up to $10,000!
          </span>
          <br />
          Skits $kills are averaged from member ratings on the following:
          <br />
          1.Story Line (meaning, sequence, events) 40% weighted
          <br />
          2.Actors Performance, 50% weighted
          <br />
          3.Category, 5% weighted
          <br />
          4.Originality, 5% weighted
          <br />
        </p>
      </div>
    );
  };

  return (
    <div id="tv_container">
      <div id="monitor">
        <div className="monitor-column">
          <AuthorizationForm />
        </div>
        <div className="monitor-column">
          {text()}
          <div className="signup-contact">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="55.038" height="57.854" viewBox="0 0 55.038 57.854" className="chat-icon">
  <g id="Chat_icon-01" data-name="Chat icon-01" transform="translate(0.523 0.523)">
    <path id="Path_16" data-name="Path 16" d="M85.471,54.842A21.441,21.441,0,1,1,64.029,33.4,21.441,21.441,0,0,1,85.471,54.842Z" transform="translate(-36.805 -27.865)" fill="#c79f27"/>
    <path id="Path_17" data-name="Path 17" d="M108.418,87.474A11.782,11.782,0,1,1,96.636,99.256,11.783,11.783,0,0,1,108.418,87.474Z" transform="translate(-81.482 -72.116)" fill="#fff"/>
    <path id="Path_18" data-name="Path 18" d="M37.995,64.545c-22.275,17.429-8.455-7.327-8.455-7.327h0a29.338,29.338,0,0,1-5.247-12.859,26.989,26.989,0,1,1,13.7,20.186Z" transform="translate(-24.071 -13.93)" fill="none" stroke="#9b9c9b" strokeMiterlimit="10" strokeWidth="1"/>
    <g id="Group_24" data-name="Group 24" transform="translate(20.015 20.088)">
      <g id="Group_19" data-name="Group 19" transform="translate(9.85 8.225)">
        <g id="Group_18" data-name="Group 18">
          <path id="Path_19" data-name="Path 19" d="M170.846,151.6l-1.737-1.736a1.258,1.258,0,0,0-1.737,0l-.289.29,3.473,3.473.29-.289A1.229,1.229,0,0,0,170.846,151.6Z" transform="translate(-167.083 -149.512)" fill="#b03a2e"/>
        </g>
      </g>
      <g id="Group_21" data-name="Group 21" transform="translate(0 1.24)">
        <g id="Group_20" data-name="Group 20">
          <path id="Path_20" data-name="Path 20" d="M129.17,124.249a.922.922,0,0,1-1.221-.063l-3.433-3.436a.921.921,0,0,1-.063-1.221l-3.465-3.465a4.42,4.42,0,0,0,.213,6l5.434,5.436a4.393,4.393,0,0,0,6,.212Z" transform="translate(-119.916 -116.064)" fill="#b03a2e"/>
        </g>
      </g>
      <g id="Group_23" data-name="Group 23" transform="translate(1.627)">
        <g id="Group_22" data-name="Group 22">
          <path id="Path_21" data-name="Path 21" d="M131.47,112.209l-1.737-1.737a1.258,1.258,0,0,0-1.737,0l-.289.289,3.474,3.474.289-.29A1.227,1.227,0,0,0,131.47,112.209Z" transform="translate(-127.707 -110.125)" fill="#b03a2e"/>
        </g>
      </g>
    </g>
  </g>
</svg> */}

            {/* <p id= "white" className="contract-text">
                <a href="" className="click-here-link">Click Here</a> to contact Tech Support for
                Questions/Issues. By signing up you consent to opt-in to receive SMS messages from Skitsolympic.com.
                We send transactional messages for verification and security purposes only.
              </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Television;


