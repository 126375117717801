import React from "react";
import "./errorMessageModal.scss"; // Import styles
import { OvalNeumorphicBtn } from "../buttons";
import { XIcon } from "../icons";

const ErrorMessageModal = ({ message, onClose }) => {
  return (
    <div className="error-modal-overlay">
      <div className="error-modal-content">
        <div className="error-modal-header">
          <h2>Error</h2>
        </div>
        <div className="error-modal-body">
          <p className="color">{message}</p>
        </div>
        <div className="error-modal-footer">
          <OvalNeumorphicBtn
            onClick={onClose}
            className="ok-button-error-modal"
            icon={
              <XIcon width="15" height="15" fillColor="var(--color-hotRed)" />
            }
            text="K"
            color="var(--color-hotRed)"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorMessageModal;
