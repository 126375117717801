import { useState, useCallback } from "react";
export const useVideoFormStateCallBackController = () => {
  const [openSkitUploadForm, setOpenSkitUploadForm] = useState(false);

  // This function now explicitly opens the form
  const openVideoForm = useCallback(() => {
    setOpenSkitUploadForm(true);
  }, []);

  const afterUploadCloseModal= useCallback(() => {
    console.log("Modal has been closed after upload success");
    setOpenSkitUploadForm(false); // Closes the form after an action, like upload success
  }, []);

  return {
    openSkitUploadForm,
    setOpenSkitUploadForm,
    openVideoForm,
    afterUploadCloseModal,
  };
};
