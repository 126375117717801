import React, { useState, useEffect } from "react";
import { SkitsOlympicLogoIcon } from "../icons";
import { FieldButton } from "../buttons";
import "./search.scss";

const SubscriptionsSearchBar = ({ onSearch, initialQuery = "" }) => {
  const [userNameValue, setUserNameQuery] = useState(initialQuery);
  const [isFocused, setIsFocused] = useState(false);
  const [lightBeamOn, setLightBeamOn] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(userNameValue); // Removed the if condition to always call onSearch and when state changes it would re-initalize at the proper time or call what it needs to call
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [userNameValue, onSearch]);

  const handleChange = (event) => {
    setUserNameQuery(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(userNameValue);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <form id="searchBar" onSubmit={handleSubmit}>
      <SkitsOlympicLogoIcon
        className="search-bar-icon"
        fillColor="var(--color-gold)"
      />
      <input
        type="text"
        className="search-input"
        placeholder={isFocused ? "" : "Search Username On Skits Olympic..."}
        value={userNameValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {userNameValue && (
        <FieldButton onClick={() => setUserNameQuery("")}>Clear</FieldButton>
      )}
    </form>
  );
};

export default SubscriptionsSearchBar;
