

const LightBeam = ({ lightBeamStyles = {} }) => {
    // Default styles for LightBeam
    const defaultStyles = {
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      border: "1px solid #ffff",
      boxShadow: "2px 2px 10px 0 rgba(99, 106, 119, 0.6)"
    };

    return (
      <div
        style={{
          ...defaultStyles, // Spread the default styles first
          ...lightBeamStyles, // Then spread the custom styles, which will override if there's a conflict
        }}
      />
    );
  };
  
  export default LightBeam;
  