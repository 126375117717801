// Tv.jsx
import React, { useState } from "react";
import "./tv.scss"; // Make sure this path is correct to include your TV styles

const Tv = ({ thumbnail }) => {
  // State to handle the TV power (on/off)
  const [isOn, setIsOn] = useState(true);

  const handlePowerSwitch = () => {
    setIsOn(!isOn);
  };

  return (
    <div className="tv-container">
      <div className="tv">
        {/* The input checkbox acts as the switch */}
        <input
          type="checkbox"
          className="switchinput"
          checked={isOn}
          onChange={handlePowerSwitch}
        />
        {/* <div className="switch"></div> */}
        <div className="video">
          {isOn && (
            // Conditionally render children if TV is "on"
            <div id="monitorscreen">{thumbnail}</div>
          )}
        </div>
        <div className="cover"></div>
      </div>
    </div>
  );
};

export default Tv;
