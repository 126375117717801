import React, { useState } from "react";
import "./tooltipDots.scss"; // We'll create this SCSS file for styling

const TooltipDots = ({ text, maxLength }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const truncatedText =
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  return (
    <div
      className="custom-tooltip-container"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {truncatedText}
      {showTooltip && text.length > maxLength && (
        <div className="custom-tooltip">{text}</div>
      )}
    </div>
  );
};

export default TooltipDots;
