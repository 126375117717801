
// This will be used for notifications as well.
// Logic for displaying time values like "a month ago," "a day ago," and "an 
// hour ago" is handled in your code by the utility function formatTimeElapsed.
import { SingularPlural } from "./wordUtils";

export const formatTimeElapsed = (date) => {
  const now = new Date();
  const elapsed = now - new Date(date);

  const seconds = Math.floor(elapsed / 1000);
  if (seconds < 60) {
    return `${seconds} ${SingularPlural(seconds, "second")} ago`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes} ${SingularPlural(minutes, "minute")} ago`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours} ${SingularPlural(hours, "hour")} ago`;
  }

  const days = Math.floor(hours / 24);
  if (days < 7) {
    return `${days} ${SingularPlural(days, "day")} ago`;
  }

  const weeks = Math.floor(days / 7);
  if (weeks < 4) {
    return `${weeks} ${SingularPlural(weeks, "week")} ago`;
  }

  const months = Math.floor(days / 30);
  if (months < 12) {
    return `${months} ${SingularPlural(months, "month")} ago`;
  }

  const years = Math.floor(days / 365);
  return `${years} ${SingularPlural(years, "year")} ago`;
};
