export const CONTEXT_TYPES = {
    SIGN_UP: "signUp",
    SIGN_IN: "signIn",
    VERIFY_ACCOUNT: "verifyAccountOtp",
    ACCOUNT_VERIFICATION: "accountHoldrMobile",
    RESET_PASSWORD: "resetPassword",
    BUILD_PROFILE: "buildProfileSignupForm",
    COMPLETE_SIGNUP: "completeSignup", // Add this line
    ACCOUNT_HOLDER_UPDATE: "accountHolderUpdate", // New context type for updating account holder
  };
  