import React, { useState, useRef, useEffect } from "react";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";
import { DownArrow } from "../icons";
import "./monthDropdown.scss";

function MonthSelect({
  value,
  onChange,
  isOpen,
  onToggle,
  error,
  className,
  currentContext = "createCampaign",
  resetMonthDropdown, // New prop to trigger reset like firing a gun, which will set state to true
  onResetMonthComplete, // Callback to notify parent when reset is done and then we reset it back to false, so release the trigger from the gun back to original position, we have to manually reset state, since no click or event listener that does it for us
}) {
  const hardcodedMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dropdownRef = useRef(null);
  const [selectedMonth, setSelectedMonth] = useState(value);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false); // Track keyboard navigation state

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);
  useEffect(() => { // wrapp the event listner to be triggered when one of the call back dependencies change 
    if (resetMonthDropdown) {
      clearMonth(); // Trigger reset when `resetDropdown` is true
      if (onResetMonthComplete) onResetMonthComplete(); // Notify parent that reset is complete
    }
  }, [resetMonthDropdown, onResetMonthComplete]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const index = hardcodedMonths.findIndex((month) =>
        new RegExp(`^${searchTerm}`, "i").test(month)
      );
      if (index !== -1) {
        setFocusedIndex(index);
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    // Ensure the focused item is visible in the dropdown
    if (dropdownRef.current) {
      const activeItem = dropdownRef.current.querySelector(".focused");
      if (activeItem) {
        activeItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [focusedIndex]);

  // Highlight the selected month when the dropdown opens
  useEffect(() => {
    if (isOpen && selectedMonth !== "") {
      setFocusedIndex(selectedMonth - 1); // Set focusedIndex to the selected month when the dropdown opens
    }
  }, [isOpen, selectedMonth]);

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      onToggle(false);
    }
  };

  const handleDropdownKeyNavigation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const updateIndex = (currentIndex, increment) => {
      const newIndex = currentIndex + increment;
      return newIndex < 0
        ? hardcodedMonths.length - 1
        : newIndex >= hardcodedMonths.length
          ? 0
          : newIndex;
    };
    if (e.key === "ArrowDown") {
      setIsKeyboardNavigation(true); // Mark that keyboard navigation is in use
      setFocusedIndex(updateIndex(focusedIndex, 1));
    } else if (e.key === "ArrowUp") {
      setIsKeyboardNavigation(true); // Mark that keyboard navigation is in use
      setFocusedIndex(updateIndex(focusedIndex, -1));
    } else if (e.key === "Enter") {
      handleMonthChange(focusedIndex);
      onToggle(false);
    } else if (/^[a-z0-9]$/i.test(e.key)) {
      setSearchTerm(e.key);
      setTimeout(() => {
        setSearchTerm("");
      }, 1000);
    }
  };

  const handleMonthChange = (monthIndex) => {
    setSelectedMonth(monthIndex);
    onChange(monthIndex);
  };
  const clearMonth = () => {
    setSelectedMonth("");
    console.log("Removing key:", getStorageKey("ccExpMonth", currentContext));
    localStorage.removeItem(getStorageKey("ccExpMonth", currentContext)); // Ensure key is removed directly
  };
  const handleMouseOver = (index) => {
    if (!isKeyboardNavigation) {
      setFocusedIndex(index);
    }
  };

  const handleMouseLeave = () => {
    if (!isKeyboardNavigation) {
      setFocusedIndex(-1);
    }
  };

  return (
    <div
      className={`month-select ${className}`}
      onKeyDown={handleDropdownKeyNavigation}
      ref={dropdownRef}
    >
      <div className="month-select-header" onClick={() => onToggle(!isOpen)}>
        <div className="year-select-content">
          {!selectedMonth && (
            <div className="month-placeholder">
              {/* if and only if error return error else show the default dropdown placeholder */}
              {error ? error : "Select a Month"}
            </div>
          )}
          {selectedMonth !== "" && (
            <div className="selected-month">
              {hardcodedMonths[selectedMonth - 1]}
            </div>
          )}
          <DownArrow fillColor="var(--color-bloodred)" />
        </div>
      </div>
      {isOpen && (
        <div className="month-dropdown-options">
          {hardcodedMonths.map((month, index) => (
            <div
              key={month}
              onClick={() => {
                handleMonthChange(index + 1);
                onToggle(false);
              }}
              tabIndex={0}
              ref={(el) => {
                if (focusedIndex === index && el) el.focus();
              }}
              className={index === focusedIndex ? "focused" : ""}
              onMouseOver={() => handleMouseOver(index)}
              onMouseLeave={handleMouseLeave}
            >
              {month}
            </div>
          ))}
        </div>
      )}
      {/* Show clear button only if a month is selected */}
      {selectedMonth !== "" && (
        <button type="button" onClick={clearMonth} className="clear-month-btn">
          Clear
        </button>
      )}
    </div>
  );
}

export default MonthSelect;
