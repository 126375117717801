import React, { useState } from "react";
import { useNotifications } from "../context/notificationContext";
import NotificationBell from "./notificationBell";
import NotificationItem from "./notificationItem";
import { RoundFlatBtns, ImgTopBtn } from "../buttons";
import BlinkingVerticalTwoBarsIcon from "../blinkingLight/blinkingVerticalTwoBarsIcon";
import { BroomIcon } from "../icons";
import "./notificationDropDown.scss";

const NotificationDropdown = () => {
  const {
    notifications,
    unreadCount,
    fetchNotifications,
    markNotificationAsRead,
    clearAllNotifications,
  } = useNotifications();
  const [open, setOpen] = useState(false);

  const handleToggleDropdown = async () => {
    // Fetch notifications whenever the dropdown is opened
    if (!open) {
      await fetchNotifications(); // Fetch new notifications
    }
    setOpen(!open);
  };

  const handleClearAllNotifications = async () => {
    await clearAllNotifications();
  };

  const handleMarkAsRead = async (id) => {
    await markNotificationAsRead(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      id="notification"
      style={{
        position: "relative",
        float: "right",
        marginRight: "20px",
        marginTop: "20px",
      }}
    >
      <button className="notification-button" onClick={handleToggleDropdown}>
        <NotificationBell unreadCount={unreadCount} />
      </button>
      {open && (
        <div className="notification-open">
          {notifications.length > 0 && (
            <ImgTopBtn
              image={<BroomIcon fillColor="#b03a2e" width="40" height="40" />}
              imgTopBtnWidth="100%"
              imgTopBtnFontColor="#b03a2e"
              imgTopBtnText="Clear All"
              textClassName="responsiveBtnText"
              imgTopBtnHeight="40px"
              onClick={(e) => {
                e.preventDefault();
                handleClearAllNotifications();
              }}
            />
          )}
          <RoundFlatBtns
            onClick={handleClose}
            Icon={BlinkingVerticalTwoBarsIcon}
            title="Close"
            iconProps={{ height: "20", width: "20" }}
            titleStyle={{
              fontSize: "1.3rem",
              margin: "0",
              textAlign: "center",
              fontWeight: "800",
              color: "var(--color-gold)",
            }}
            extraStyles={{
              width: "80px",
              height: "80px",
              padding: "1px",
            }}
          />
          <div className="notification-content">
            {notifications.length === 0 ? (
              <div style={{ padding: "10px" }}>No new notifications</div>
            ) : (
              notifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  markAsRead={handleMarkAsRead}
                />
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default NotificationDropdown;
