import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BigRemoteButton } from "../buttons/index";
import LightBeam from "../lightBeam/lightBeam";
import { SkitsOlympicLogoIcon } from "../icons";
import { FieldButton } from "../buttons/index";
import "./search.scss";

const SearchBar = ({ onSearch, initialQuery = "" }) => {
  const navigate = useNavigate();
  const [userNameValue, setUserNameQuery] = useState(initialQuery);
  const [isFocused, setIsFocused] = useState(false);
  const [lightBeamOn, setLightBeamOn] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (userNameValue) {
        onSearch(userNameValue);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [userNameValue, onSearch]);

  const handleChange = (event) => {
    setUserNameQuery(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(userNameValue);
  };
  const handleFocus = () => {
    setIsFocused(true);
    navigate("/search-accounts", { state: { userNameValue } });
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  // Define your arrow click handlers if necessary
  const handleLeftArrowClick = () => {
    console.log("Left arrow clicked");
  };
  const handleRightArrowClick = () => {
    console.log("Right arrow clicked");
  };
  const handleUpArrowClick = () => {
    console.log("Up arrow clicked");
  };
  const handleDownArrowClick = () => {
    console.log("Down arrow clicked");
  };
  // Trigger the light beam and then turn it off after 500ms
  const triggerLightBeam = () => {
    setLightBeamOn(true);
    setTimeout(() => setLightBeamOn(false), 500);
  };

  return (
    <form id="searchBar" onSubmit={handleSubmit}>
      <SkitsOlympicLogoIcon
        className="search-bar-icon"
        fillColor="var(--color-gold)"
      />
      <input
        type="text"
        className="search-input"
        placeholder={isFocused ? "" : "Enter username to search..."}
        value={userNameValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {userNameValue && (
        <FieldButton onClick={() => setUserNameQuery("")}>Clear</FieldButton>
      )}
      <BigRemoteButton
        onMainClick={() => triggerLightBeam("big-main-btn")}
        onLeftClick={handleLeftArrowClick}
        onRightClick={handleRightArrowClick}
        onUpClick={handleUpArrowClick}
        onDownClick={handleDownArrowClick}
        mainBtnText="Search"
      />
      <LightBeam
        lightBeamStyles={{
          transform: "translateX(2%) translateY(-100%)",
          backgroundColor: lightBeamOn
            ? "var(--color-corveteRed)"
            : "var(--color-redish)",
        }}
      />
    </form>
  );
};
export default SearchBar;
