import { formatTime } from "../../utils/formatTime";
import "./tooltipNumbers.scss";

const ToolTipNumbers = ({ visible, time, position }) => {
  if (!visible) return null; // Render nothing if not visible

  return (
    <div
      className="numbersToolTip"
      style={{
        left: `${position}px`, // Dynamically position tooltip
        transform: "translateX(-50%)",
      }}
    >
      {formatTime(time)}
    </div>
  );
};
export default ToolTipNumbers;
