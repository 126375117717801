import "./animatedPencilEmojis.scss";

const PencilAnimatedEmoji = ({ rating }) => {
  const renderExpression = () => {
    switch (rating) {
      case 5:
        return (
          <div className="faceYay">
            <div className="lefteye"></div>
            <div className="righteye"></div>
            <div className="nose"></div>
            <div className="pencilYayMouth"></div>
          </div>
        );
      case 4:
        return (
          <div className="faceSmirk">
            <div className="lefteye"></div>
            <div className="righteye"></div>
            <div className="nose"></div>
            <div className="pencilSmirkMouth"></div>
          </div>
        );
      case 3:
        return (
          <div className="faceWinkSmile">
            <div className="lefteye"></div>
            <div className="righteye"></div>
            <div className="nose"></div>
            <div className="winkSmileMouth"></div>
          </div>
        );
      case 2:
        return (
          <div className="faceGrinSmile">
            <div className="lefteye"></div>
            <div className="righteye"></div>
            <div className="nose"></div>
            <div className="grinSmileMouth"></div>
          </div>
        );
      case 1:
        return (
          <div className="facePencilSob">
            <div className="lefteye"></div>
            <div className="righteye"></div>
            <div className="nose"></div>
            <div className="sadMouthGrin"></div>
          </div>
        );
      case 0:
      default:
        return (
          <div className="faceDefault">
            <div className="lefteye"></div>
            <div className="righteye"></div>
            <div className="nose"></div>
            <div className="pencilDefaultMouth "></div>
            {/* Include the line in default face */}
          </div>
        );
      // or
      // return null; // Default to no face
    }
  };

  return (
    <div className={`pencil rank-${rating}`} key={`rating-${rating}`}>
      <div id={`rating-${rating}`}></div>
      {renderExpression()}
      {/* Other parts of the pencil */}
      <div className="rubber"></div>
      <div className="ferrule">
        <div className="stripe"></div>
        <div className="stripe"></div>
      </div>
      <div className="stick">
        <div className="side"></div>
        <div className="side"></div>
        <div className="side"></div>
      </div>
      <div className="bottom"></div>
      <div className="graphite"></div>
      <div className="line"></div>
    </div>
  );
};

export default PencilAnimatedEmoji;
