import React, { useRef, useEffect } from "react";
import EmotionEmoji from "../emoji/emoji";
import "./memberSlider.scss";

function MemberSlider({
  className,
  user,
  slideBtnText,
  onHandleSlideEnd,
  style,
  slidBtnFontSize,
  slideBtnWidth,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  top,
}) {
  const slideRef = useRef(null);
  const slideBtnRef = useRef(null);

  let slideBounds;
  let btnBounds;

  function onSlide(ev) {
    if (!slideBtnRef.current) return; // Safeguard
    ev.stopPropagation();

    const pointerX = ev.clientX || (ev.touches && ev.touches[0]?.clientX);
    const pos = pointerX - btnBounds.x - btnBounds.width / 2;

    const minPos = 0;
    const maxPos =
      slideBounds.width - btnBounds.width - slideBounds.width * 0.04;

    const clampedPos = clamp(minPos, pos, maxPos);

    slideBtnRef.current.style.left = `${clampedPos}px`;

    // Trigger the event handler if the slider reaches the end
    if (clampedPos >= maxPos) {
      onHandleSlideEnd(user.id);

      // Reset slider after the slide event
      resetSlider();
    }
  }

  function onSlideBtnPointerDown(ev) {
    if (!slideRef.current || !slideBtnRef.current) return;

    ev.preventDefault();
    slideBounds = slideRef.current.getBoundingClientRect();
    btnBounds = slideBtnRef.current.getBoundingClientRect();

    slideBtnRef.current.setPointerCapture(ev.pointerId);
    slideBtnRef.current.addEventListener("pointermove", onSlide);
    slideBtnRef.current.addEventListener("touchmove", onSlide);
  }

  function onSlideBtnPointerUp(ev) {
    if (!slideBtnRef.current) return;

    ev.preventDefault();
    slideBtnRef.current.releasePointerCapture(ev.pointerId);
    slideBtnRef.current.removeEventListener("pointermove", onSlide);
    slideBtnRef.current.removeEventListener("touchmove", onSlide);

    resetSlider();
  }

  // Reset slider to its original position
  function resetSlider() {
    if (!slideBtnRef.current) return; // Ensure the button still exists

    const animation = slideBtnRef.current.animate(
      { left: "0" },
      { duration: 200, easing: "ease-out" }
    );

    animation.finished.then(() => {
      if (slideBtnRef.current) {
        slideBtnRef.current.style.left = "0"; // Ensure the button still exists
      }
    });
  }

  function clamp(min, val, max) {
    return Math.max(min, Math.min(val, max));
  }

  useEffect(() => {
    const slideButton = slideBtnRef.current;
    if (!slideButton) return;
  
    // Add event listeners
    slideButton.addEventListener("pointerdown", onSlideBtnPointerDown); // Non-passive
    slideButton.addEventListener("pointerup", onSlideBtnPointerUp); // Non-passive
    slideButton.addEventListener("touchstart", onSlideBtnPointerDown, { passive: false }); // Non-passive
    slideButton.addEventListener("touchend", onSlideBtnPointerUp, { passive: true }); // Passive
    slideButton.addEventListener("touchmove", onSlide, { passive: false }); // Non-passive
  
    // Cleanup listeners to avoid memory leaks and null references
    return () => {
      if (slideButton) {
        slideButton.removeEventListener("pointerdown", onSlideBtnPointerDown);
        slideButton.removeEventListener("pointerup", onSlideBtnPointerUp);
        slideButton.removeEventListener("touchstart", onSlideBtnPointerDown);
        slideButton.removeEventListener("touchend", onSlideBtnPointerUp);
        slideButton.removeEventListener("touchmove", onSlide);
      }
    };
  }, []);
  

  return (
    <div
      ref={slideRef}
      className={`slide ${className}`}
      style={{
        ...style,
        width: slideBtnWidth,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        top,
      }}
    >
      <div ref={slideBtnRef} className="slidebtn">
        {user.thumbnailPreference === "picture" && user.profileImageUrl ? (
          <img
            src={user.profileImageUrl}
            alt={`${user.username}'s Profile`}
            className="badgepic"
          />
        ) : (
          <div className="emoji-wrapper">
            <EmotionEmoji currentEmotionId={user.emotionconId} />
          </div>
        )}
      </div>
      <div className="label" style={{ fontSize: slidBtnFontSize }}>
        {slideBtnText}
      </div>
    </div>
  );
}

export default MemberSlider;

// Key Fixes
// 'fixed the slider debounce issue by adding a null check 
// since the slider may not bounce back fully when the user is redirected to the page'
// Null Checks:

// Added checks for slideBtnRef.current before accessing properties like style or calling methods.
// Cleanup Logic:

// Ensures that event listeners are removed when the component unmounts to avoid accessing null.
// Animation Safety:

// Checks if slideBtnRef.current exists in the animation.finished callback before modifying the button style.
// Error Prevention:

// Prevents event propagation in edge cases to avoid unintended side effects.
