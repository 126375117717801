import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { SmallPlainInsetBtn, RoundFlatBtns } from "../buttons/index";
import { BallotIcon, VerticalTwoBarsIcon } from "../icons/index";
import PencilAnimatedEmoji from "../animatedEmojis/animatedPencilEmojis";
import StarRating from "../starRating/StarRating";
import LightBulbAnimatedEmoji from "../animatedEmojis/lightBulbAnimatedEmoji";
import AnimatedStarEmoji from "../animatedEmojis/animatedStarEmojis";
import AnimatedHeartEmoji from "../animatedEmojis/animatedHeartEmoji";
import AnimatedEmotionEmotesBlondeSpikeBoy from "../animatedEmojis/animatedEmotionEmotesBlondeSpikeBoy";
import AnimatedEmotionEmotesBoyShortHair from "../animatedEmojis/animatedEmotionEmotesBoyShortHair";
import AnimatedEmotionEmotesBeard from "../animatedEmojis/animatedEmotionEmotesBeard";
import AnimatedEmotionEmotesMustache from "../animatedEmojis/animatedEmotionEmotesMustache";
import AnimatedEmotionEmotesBeardCrown from "../animatedEmojis/animatedEmotionEmotesBeardCrown";
import AnimatedEmotionEmotesLongHairGirls from "../animatedEmojis/animatedEmotionsEmotesLongHairGirls";
import AnimatedEmotionEmotesShortHairGirls from "../animatedEmojis/animatedEmotionEmotesShortHairGirls";
import AnimatedEmotionEmotesShortestHair from "../animatedEmojis/animatedEmotionEmotesShortestHair";
import AnimatedEmotionEmotesHairless from "../animatedEmojis/animatedEmotionEmotesHairless";
import BallotModalFooter from "./ballotModalFooter";
import "./ballotModal.scss";

// leave the array here instead of a constaants since all the emojis are in this file
const categories = [
  {
    id: 1,
    EmotionCon: PencilAnimatedEmoji,
    title: "Story Line",
    description: "(meaning, sequence, events)",
  },
  {
    id: 2,
    EmotionCon: LightBulbAnimatedEmoji,
    title: "Originality",
    description: "(How original is the skit)",
  },
  {
    id: 3,
    EmotionCon: AnimatedStarEmoji,
    title: "Peformance",
    description: "(Believable Character(s), Reactional timing)",
  },
  {
    id: 600,
    EmotionCon: AnimatedHeartEmoji,
    title: "Romance",
    description: "(How relatable?/Heartstrings pulled)",
  },
  {
    id: 100,
    EmotionCon: AnimatedEmotionEmotesBlondeSpikeBoy,
    title: "Action",
    description: "(Was it action packed?)",
  },
  {
    id: 10,
    EmotionCon: AnimatedEmotionEmotesBoyShortHair,
    title: "Suspense",
    description: "(How suspenseful?)",
  },
  {
    id: 700,
    EmotionCon: AnimatedEmotionEmotesBeard,
    title: "Musical",
    description: "(How emotional/catchy?)",
  },
  {
    id: 330,
    EmotionCon: AnimatedEmotionEmotesMustache,
    title: "Western",
    description: "(How believable?)",
  },
  {
    id: 50,
    EmotionCon: AnimatedEmotionEmotesBeardCrown,
    title: "Fantasy",
    description: "(How imaginative?)",
  },
  {
    id: 200,
    EmotionCon: AnimatedEmotionEmotesLongHairGirls,
    title: "Drama",
    description: "(How dramatic?)",
  },
  {
    id: 500,
    EmotionCon: AnimatedEmotionEmotesShortHairGirls,
    title: "Comedy",
    description: "(How funny?)",
  },
];

const BallotModal = ({ skitId, categoryid, onVote, onClose }) => {
  // Define standard categories
  const standardCategories = categories.filter(
    (cat) => cat.id === 1 || cat.id === 2 || cat.id === 3
  );
  // Find the specific category for the skit
  const specificCategory = categories.find((cat) => cat.id === categoryid);
  // Combine standard with specific category, ensuring the specific category is valid
  const relevantCategories = specificCategory
    ? [...standardCategories, specificCategory]
    : standardCategories;

  const [ratings, setRatings] = useState({});

  useEffect(() => {
    const initialRatings = JSON.parse(localStorage.getItem("ratings") || "{}");
    setRatings(initialRatings);
  }, []);

  const handleRating = (id, rating) => {
    const newRatings = { ...ratings, [id]: rating };
    setRatings(newRatings);
    localStorage.setItem("ratings", JSON.stringify(newRatings));
  };

  const handleSubmitVote = () => {
    const scores = {
      storyLine: ratings[1] || 0,
      originality: ratings[2] || 0,
      performance: ratings[3] || 0,
      category: ratings[categoryid] || 0,
    };
    console.log("Skit ID:", skitId);
    console.log("Ratings:", ratings);
    console.log("Scores:", scores);
    onVote(skitId, ratings, categoryid, scores); // passing the scores object to be extracted later and the rating to be caclulated with the utils function in the back for weighted user average rating
    onClose();
  };

  const allRated = relevantCategories.every(
    (category) => ratings[category.id] > 0
  );

  // Determine if the next button should be highlighte
  return ReactDOM.createPortal(
    <div className="ballot-modal-overlay">
      <div className="ballot-modal-content">
        <div
          className="button-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <RoundFlatBtns
            name="cancelButton"
            onClick={onClose}
            Icon={VerticalTwoBarsIcon} // Pass the component, not an instance
            iconProps={{ fillColor: "#B03A2E" }} // Example of passing props to the Icon
            title="CANCEL"
            backgroundColor="#1c1c1c"
            extraStyles={{
              padding: "10px",
            }}
            width="80px"
            height="80px"
            titleStyle={{
              color: "var(--color-bloodred)",
              fontFamily: "Roboto Slab",
              fontWeight: "800",
              fontSize: ".75rem",
            }} // Styling for the button text
            border="0.2px solid #c79f27"
            borderRadius="50%" // Making the button round
            boxShadow="0 0 10px 0px rgba(0, 0, 0, 0.5)" // Box shadow for the button
            transition="background-color 1s cubic-bezier(0.075, 0.82, 0.165, 1)"
          />
        </div>
        <div className="categories-grid">
          {relevantCategories.map(({ id, EmotionCon, title, description }) => (
            <div className="category" key={id}>
              <EmotionCon rating={ratings[id]} />
              <StarRating
                rating={ratings[id]}
                setRating={(rating) => handleRating(id, rating)}
              />
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <SmallPlainInsetBtn
            image={
              <BallotIcon
                height="20px"
                width="20px"
                style={{ marginRight: "3px" }}
              />
            }
            text="Vote"
            width="88px"
            style={{
              fontSize: "1rem",
            }}
            onClick={handleSubmitVote} // Using the handleSubmitVote directly
            disabled={!allRated}
          />
        </div>
      </div>
    </div>,
    document.getElementById("modal-root") // reference to the portal's container
  );
};
export default BallotModal;
