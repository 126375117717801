import axios from "axios";

/**
 * Uploads a file to AWS S3 using a pre-signed URL.
 *
 * @param {string} presignedImgUrl - The pre-signed URL for the S3 bucket.
 * @param {File} file - The file to upload.
 * @param {Function} onSuccess - Callback function to execute upon successful upload.
 * @param {Function} onError - Callback function to execute upon an error.
 */
export const uploadFileToS3 = async (
  presignedImgUrl,
  file,
  onSuccess,
  onError
) => {
  try {
    // Perform the PUT request to the pre-signed URL with the file as the body
    const response = await axios.put(presignedImgUrl, file, {
      headers: {
        "Content-Type": file.type, // Set to the file's MIME type
      },
    });
    // S3 should respond with 200 OK or 204 No Content upon successful upload.
    if (response.status === 200 || response.status === 204) {
      console.log("File successfully uploaded to S3.");
      if (onSuccess) onSuccess();
    } else {
      console.error("File upload to S3 failed with status:", response.status);
      if (onError)
        onError(`Failed to upload file to S3 with status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error during file upload to S3:", error);
    if (onError) onError(error);
  }
};


export default {
  uploadFileToS3,
};
