export const paymentTypeOptions = [
  { value: "impressions", label: "Impressions" },
  { value: "clicks", label: "Clicks" },
];

export const paymentMethodOptions = [
  { value: "visa", label: "Visa" },
  { value: "mastercard", label: "Mastercard" },
  { value: "americanExpress", label: "American Express" },
  { value: "discover", label: "Discover" },
];
export const periodChargeThresholdType = [
  { name: "Lifetime limit", value: "0" },
  { name: "Daily limit", value: "1" },
];

// export const periodMultipliers = [
//   { name: "Monthly", value: "30" },
//   { name: "Weekly", value: "7" },
//   { name: "Daily", value: "1" },
//   { name: "Every three months", value: "90" },
//   { name: "Every six months", value: "180" },
// ];
