import React, { useState, useEffect } from "react";
import { renderUserThumbnail } from "../../controller/userThumbnail";
import { SmallPlainInsetBtnEventListener, PlainBtn } from "../buttons";
import CurrentUserReply from "./currentUserReply";
import { SmileyBubblyChat, XIcon } from "../icons/index";
import { renderCommentWithEmotes } from "../../utils/emoteUtils";
import { apiHeartThread, apiUnheartThread } from "../../api/backend/comment";
import "./newThreadResults.scss";

const NewThreadResults = ({
  thread,
  commentSourceUsername, // Passing this to display who the thread source id is addressing below
  currentUser,
  activeThreadId,
  setActiveThreadId,
  handleCancelReply,
  handleCreateResponseToThread,
  replyValue,
  setReplyValue,
  replyingTo,
  setReplyingTo,
  responseError, // New prop for error state
  setResponseError, // New prop for setting error state
  isHearted, // linked to item which is comment type and a bolean that checks the list from the backend and is defined with this prop which sets state here to help control the heart button ui for the user
  deleteMessagePermissions,
  deleteCommentThread,
  formattedTime,
}) => {
  const [hearted, setHearted] = useState(isHearted);
  const [heartCount, setHeartCount] = useState(thread.heartCount); // Initialize based on backend if a user unhearts we destroy the heart record for the related id after count is decremented on the correct comment or message type table

  // Sync `hearted` state with `isHearted` prop
  useEffect(() => {
    console.log(
      `NewThreadResults: Updating hearted state for thread ${thread.id}`
    );
    setHearted(isHearted);
  }, [isHearted, thread.id]);

  const handleHeartClick = async () => {
    try {
      if (hearted) {
        const response = await apiUnheartThread(thread.id, currentUser);
        if (response.data.success) {
          setHeartCount(response.data.heartCount);
          setHearted(false);
        }
      } else {
        const response = await apiHeartThread(thread.id, currentUser);
        if (response.data.success) {
          setHeartCount(response.data.heartCount);
          setHearted(true);
        }
      }
    } catch (error) {
      console.error("Error updating heart status:", error);
      setResponseError("Failed to update heart status. Please try again.");
    }
  };

  if (!thread || !thread.source) {
    console.error("Thread or Thread source is undefined", thread);
    return null;
  }
  const handleCancel = () => {
    handleCancelReply("thread", thread.id);
    setReplyValue(""); // Clear the input value
    setResponseError(""); // Clear the error message
  };
  const renderMessage = () => {
    const parts = renderCommentWithEmotes(thread.message);
    return parts.map((part, index) => (
      <span key={index} className="message-part">
        {part}
      </span>
    ));
  };

  return (
    <div key={thread.id} id="skit-thread" className="thread-container">
      <div className="bubble-container">
        <div className="bubble-wrapper">
          {renderUserThumbnail(thread.source)}
          <div className="bubble">
            <span className="messageValue">
              <span className="addressing-username">
                {commentSourceUsername}, &nbsp;
              </span>
              &nbsp;{renderMessage()} &nbsp; &nbsp;{" "}
              <span className="username">
                {" "}
                - {thread.source.username}&nbsp;
                <span className="formatted-time">·{formattedTime}</span>
              </span>
            </span>
          </div>
        </div>
        <div className="reply-actions-wrapper">
          <SmallPlainInsetBtnEventListener
            className="reply-thread-btn"
            image={
              <SmileyBubblyChat
                fillColor="#b03a2e"
                style={{ position: "relative", bottom: "-7px" }}
              />
            }
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "1.3rem",
              fontWeight: "900",
              lineHeight: "14px",
            }}
            width="fit-content"
            text="Reply"
            onClick={() => {
              setActiveThreadId(thread.id);
              setReplyingTo(thread.source);
            }}
          />
          {deleteMessagePermissions && (
            <SmallPlainInsetBtnEventListener
              image={
                <XIcon
                  fillColor="#b03a2e"
                  style={{ position: "relative", paddingRight: "5px" }}
                />
              }
              style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "1.3rem",
                fontWeight: "900",
                lineHeight: "14px",
              }}
              width="fit-content"
              className="delete-comment-btn"
              text="Delete" // allows the current user who as permissions to delete the comment, and all thread and thread response children
              onClick={() => deleteCommentThread(thread.id)}
            />
          )}
          <PlainBtn
            plainBtnText={hearted ? "Unheart" : "Heart"}
            plainBtnTextFontSize="1.3rem"
            plainBtnHeight="fit-content"
            plainBtnFontFamily="Roboto"
            plainBtnFontWeight="bold"
            plainBtnFontColor="#b03a2e"
            plainBtnBorderRadius="30px"
            onClick={handleHeartClick}
            image={
              <span
                role="img"
                aria-label="heart"
                className={hearted ? "broken-heart" : "heart-beat"}
              >
                {hearted ? "💔" : "❤️"}
              </span>
            }
            imageOnTop={false}
            extraStyles={{
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
          />
          {heartCount > 0 && (
            <span className="heart-count"> ❤️ {heartCount}</span>
          )}
        </div>
        {activeThreadId === thread.id && (
          <CurrentUserReply
            currentUser={currentUser}
            type="thread"
            id={thread.id}
            replyingTo={replyingTo}
            onSubmitReply={() =>
              handleCreateResponseToThread(thread.id, thread.source.id)
            }
            onCancel={handleCancel}
            value={replyValue}
            setValue={setReplyValue}
            placeholder="Write a reply message..."
            responseError={responseError}
            setResponseError={setResponseError}
          />
        )}
      </div>
    </div>
  );
};

export default NewThreadResults;
