import React from "react";
import "./animatedLightBulb.scss";
const AnimatedLightBulb = () => (
  <div className="animatedLightBulb">
    <div className="animatedBulb"></div>
    <div className="defaultLightBulbFace">
      <div className="default-surprise-expression">
        <div className="default-eyebrows default-eyebrow-right"></div>
        <div className="default-eyebrows default-eyebrow-left"></div>
        <div className="default-eyes default-eye-left"></div>
        <div className="default-eyes default-eye-right"></div>
        <div className="default-mouth"></div>
      </div>
    </div>
  </div>
);

export default AnimatedLightBulb;
