// import React from "react";
// import "./animatedStarEmojis.scss";
import "./animatedStarEmojis.scss";
const AnimatedStarEmoji = ({ rating }) => {
  const renderExpression = () => {
    switch (rating) {
      case 5:
        return (
          <div className="star-default-face">
            <div className="teeth-laugh-expression">
              <div className="archedEyes left-eye"></div>
              <div className="archedEyes right-eye"></div>
              <div className="FadeInglasses">
                <div className="FadeInlens left-lens"></div>
                <div className="FadeInlens right-lens"></div>
                <div className="happy-teeth-mouth"></div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="star-default-face">
            <div className="star-happy-expression">
              <div className="judging">
                <div className="star-default-eyes star-default-eye-left"></div>
                <div className="star-default-eyes star-default-eye-right"></div>
                <div className="star-happy-mouth"></div>
                {/* Use the new grin-mouth class */}
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="star-default-face">
            <div className="star-grin-expression star-awkward-expression">
              <div className="judging">
                <div className="grineyes left-grin-eye"></div>
                <div className="grineyes right-grin-eye"></div>
                <div className="colorGlasses">
                  <div className="lens left-lens"></div>
                  <div className="lens right-lens"></div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="star-default-face">
            <div className="star-awkward-expression">
              <div className="judging">
                <div className="star-awkward-eyes  star-awkward-eye-left"></div>
                <div className="star-awkward-eyes star-awkward-eye-right"></div>
                <div className="star-mouth"></div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="star-default-face">
            <div className="star-mad-expression">
              <div className="star-mad-eyes  star-mad-eye-left"></div>
              <div className="star-mad-eyes  star-mad-eye-right"></div>
              <div className="star-mouth madmouth"></div>
              {/* Added madmouth class */}
            </div>
          </div>
        );
      case 0:
      default:
        return (
          <div className="star-default-face">
            <div className="star-default-expression">
              <div className="judging">
                <div className="star-default-eyes star-default-eye-left"></div>
                <div className="star-default-eyes star-default-eye-right"></div>
                <div className="colorGlasses">
                  <div className="lens left-lens"></div>
                  <div className="lens right-lens"></div>
                  <div className="tongue-mouth">
                    <div className="tongue"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      // or
      // return null; // Default to no face
    }
  };

  return (
    <div className={`Star rank-${rating}`} key={`rating-${rating}`}>
      <div id={`rating-${rating}`}></div>
      {renderExpression()}
      <div className="star-container">
        <div className="star">
          <div className="star-line"></div> {/* Added star-line */}
        </div>
        {/* This element will receive the fade-in effect */}
      </div>
    </div>
  );
};

export default AnimatedStarEmoji;
