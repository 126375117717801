import React, { useState, useEffect, useRef } from "react";
import CatIcons from "../../icons/categoryIcons";
import { convertToSlug } from "../../../utils/stringUtils";
import { WhiteBtn } from "../../buttons";
import GlobalThreeLineAnimation from "../../../globalViews/globalThreeLineAnimation";
import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator";
import "./catmenu.scss";

const RelationalCategoryMenuPopup = ({
  subCatMenuData,
  onCancelCategory,
  handleSubcategoryClick, // Use this correctly
  handlePromoteAllClick,
  contextType,
}) => {
  const [subcategories, setSubcategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryIcon, setCategoryIcon] = useState("");

  useEffect(() => {
    if (subCatMenuData && subCatMenuData.data) {
      setSubcategories(subCatMenuData.data.subcategories || []);
      setCategoryName(subCatMenuData.categoryName || "");
      setCategoryIcon(subCatMenuData.categoryIcon || "");
    }
  }, [subCatMenuData]);

  const alphabet = Array.from(Array(26)).map((_, i) =>
    String.fromCharCode(i + 65)
  );
  const [alphabetActive, setAlphabetActive] = useState("");
  const iconKey = convertToSlug(categoryName);
  const CategoryIcon = CatIcons[iconKey] || null;

  const refCategorylist = useRef();
  const refSubcategories = useRef({});

  useEffect(() => {
    const newRefs = alphabet.reduce((acc, char) => {
      acc[char] = React.createRef();
      return acc;
    }, {});
    Object.assign(refSubcategories.current, newRefs);
  }, [alphabet, subcategories]);

  const handleAlphabetClick = (char) => {
    setAlphabetActive(char);
    const elementId = `subcat-${char}`;
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const handleSubcategoryClickLocal = (subcategoryId, subcategoryName) => {
    const key = getStorageKey("adCategoryPromo", contextType);
    // Store both subcategoryId and subcategoryName
    localStorage.setItem(
      key,
      JSON.stringify({ subcategoryId, subcategoryName })
    );
    handleSubcategoryClick(subcategoryId, subcategoryName);
  };

  const handleCancelCatClick = () => {
    onCancelCategory();
  };

  return (
    <div
      id="relationalCatMenu"
      className="categories-menu-wrapper modal-content"
      style={{ posiion: "relative", left: "10%" }}
    >
      <div className="popup-wrapper popup--wrapper event-attached">
        <div className="subcatmenu-header">
          <div className="subcategory-info">
            <WhiteBtn
              whiteBtnImage={
                <GlobalThreeLineAnimation
                  fillColor="var(--color-bloodred)"
                  height="25px"
                  width="70px"
                  lineWidth="25px"
                  lineHeight="5px"
                  animationType="6"
                  marginLeft="12px" // Add marginLeft prop
                  marginBottom="40px" // Add marginBottom prop
                />
              }
              whiteBtnText="Clear & Back"
              whiteBtnFontColor="var(--color-bloodred)"
              whiteBtnfontSize=".75rem"
              whiteBtnBorderColor="var(--color-bloodred)"
              whiteBtnmarginRight="0px"
              whiteBtnPosition="absolute"
              whiteBtnheight="50px"
              textStyle={{
                position: "absolute",
                top: "25px",
                left: "12px",
              }}
              right="45px"
              onClick={handleCancelCatClick} // Use the new handler
            />
            <div className="icon-container">
              <div>{CategoryIcon}</div>
              <h2 className="subcategory-title">{categoryName}</h2>
            </div>
            <div className="header-content">
              <div className="subcategory-count">
                <a
                  href="#"
                  onClick={() => {
                    // Log the correct category values
                    console.log(
                      "Triggering handlePromoteAllClick - idCategory:",
                      subCatMenuData.idCategory,
                      "categoryName:",
                      subCatMenuData.categoryName
                    );

                    handlePromoteAllClick(
                      subCatMenuData.idCategory, // Correct reference for the category ID
                      subCatMenuData.categoryName // Correct reference for the category name
                    );
                  }}
                >
                  Click here if you want to promote to all{" "}
                  {subcategories.length} subcategories
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="subcatmenu-popup-body ">
          {/* this will be used to pass the service to the input */}
          <div className="subcategories-container">
            <div className="subcategories-column">
              <ul className="subcategories-list" ref={refCategorylist}>
                {alphabet.map((char) => {
                  const filteredSubcategories = subcategories.filter(
                    (subcategory) =>
                      subcategory.name.toUpperCase().startsWith(char)
                  );
                  const isActive = alphabetActive === char;
                  return (
                    <li key={char} id={`subcat-${char}`}>
                      <h3
                        id={`subcat-${char}`}
                        className={
                          isActive ? "active-letter" : "inactive-letter"
                        }
                      >
                        {char}
                      </h3>
                      {filteredSubcategories.length > 0 ? (
                        filteredSubcategories.map((subcategory) => (
                          <div
                            key={subcategory.id}
                            className="subcategory-item"
                          >
                            <a
                              href="#"
                              onClick={() =>
                                handleSubcategoryClickLocal(
                                  subcategory.id,
                                  subcategory.name
                                )
                              }
                            >
                              {subcategory.name}
                            </a>
                          </div>
                        ))
                      ) : (
                        <div className="subcategory-item">Unavailable</div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="alphabet-index-column">
              <nav className="subcategories-alphabet">
                <span
                  style={{ color: "black", fontWeight: "800" }}
                  className="subcategories-alphabet-prefix"
                >
                  INDEX
                </span>
                {alphabet.map((char) => {
                  const isActive = alphabetActive === char;
                  // console.log(
                  //   `Processing character: ${char}, Active: ${isActive}`
                  // ); // Debugging log
                  return (
                    <a
                      key={char}
                      className={`subcategories-alphabet-char subcategories-alphabet--char ${
                        alphabetActive === char ? "active" : ""
                      }`}
                      href="#"
                      data-char={char}
                      onClick={(e) => {
                        e.preventDefault();
                        handleAlphabetClick(char);
                      }}
                    >
                      {char}
                    </a>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RelationalCategoryMenuPopup;
