import Television from "../../components/television/television";
import SpotLight from "../../components/spotlight/spotight";
import Footer from "../../components/footer/footer";
import "./landing.scss";
import "../../components/television/television.scss"

export default function Landing() {
  return (
    <div id="landing">
      <Television/>
      <Footer />
      <SpotLight/>
    </div>
  );
}
