import { HashLink } from "react-router-hash-link";
import { ImgTopBtn } from "../buttons";
import PointsCircle from "./rewardsCircle";
import "./pointsDashBoard.scss";

const PointsDashBoard = ({ pointsBalance, setPointsBalance, userId }) => {
  
  // Function to adjust the scroll offset
  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({ top: offsetPosition, behavior: "smooth" });
  };

  return (
    <div className="dashboardContainer">
      <div className="dashboardHeader">
        <h1 className="dashboardCheckoutHeader">Dashboard</h1>
        <HashLink
          to={`/user/${userId}/transactionHistoryPage#paymentTransactionsTop`}
          scroll={(el) => scrollWithOffset(el, 50)}
          style={{
            textDecoration: "none", // Avoid default link styling
          }}
        >
          <ImgTopBtn
            imgTopBtnText="Transaction History"
            imgTopBtnFontColor="var(--color-bloodred)"
            className="history_btn"
          />
        </HashLink>
      </div>
      {/* Points Circle for visual representation of balance */}
      <div className="mini-dashboard">
        <PointsCircle
          pointsBalance={pointsBalance}
          setPointsBalance={setPointsBalance}
          NameLevelValueStyle={{
            color: "var(--color-darkGold)",
            fontFamily: "Nunito",
            fontWeight: "600",
          }}
          CurrentPntsBalStyle={{
            color: "var(--color-darkGold)",
            fontFamily: "Nunito",
            fontWeight: "600",
          }}
          unlockLevelStyle={{
            color: "var(--color-darkGold)",
            fontFamily: "Nunito",
            fontWeight: "600",
          }}
          pointsToGoStyle={{
            color: "var(--color-darkGold)",
            fontFamily: "Nunito",
            fontWeight: "600",
          }}
          className="positionUnlockContent"
        />
      </div>
    </div>
  );
};
export default PointsDashBoard;
