import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNotifications } from "../context/notificationContext";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useAuth } from "../context/authContext";
import { useAdCampaigns } from "../hooks/useAdCampaigns";
import BallotModal from "../ballotModal/ballotModal";
import { SmallPlainInsetBtn, OvalNeumorphicBtn } from "../buttons/index";
import {
  BallotIcon,
  WhistleIcon,
  TrashCanIcon,
  NotInterestedIcon,
  XIcon,
} from "../icons";
import ConnectionBtn from "../memberSlider/memberSlider";
import VideoPlayerControls from "../videoPlayer/videoPlayerControls";
import VideoMonitor from "../videoPlayer/videoMonitor";
import HandClap from "../handClap/handClap";
import useSourceIdDeletePermission from "../hooks/useSourceIdDeletePermission";
import {
  apiRecordSourceIDActivityView,
  apiFetchUserViewedSkitActivity,
  apiRecordSourceIDActivityRatings,
  apiRecordSourceIDActivityClap,
} from "../../api/backend/user";
import { SingularPlural } from "../../utils/wordUtils";
import { cancelVoteTrasnaction } from "../../controller/cancelBallotModalTrasaction";
import TimeRemainingAd from "./timeRemainingAd";
import { getSkitTime,  saveSkitTime, getSkitTimeArray } from "../../utils/skitAd";
import "./skitAdCard.scss"; // Assuming you have a separate SCSS file for styling

const SkitAdCard = ({
  specificSkitData = {},
  onDelete,
  onNotInterested,
  updateSkitsData, // updateSkitsData prop to call the function when needed, such as after a clap is recorded.
  isUploadVideoFormOpen, // This prop is passed from the parent
}) => {
  const navigate = useNavigate();
  const { currentUser, playCount, setPlayCount } = useAuth(); // Destructure currentUser here
  const updateProgressRef = useRef(0);
  const { getNextAd } = useAdCampaigns(); // Hook for fetching next ad
  const { fetchNotifications } = useNotifications();
  const hasDeletePermission = useSourceIdDeletePermission(
    currentUser,
    specificSkitData.sourceId
  );
  const videoRef = useRef(null); // Reference to the video element
  const durationRef = useRef(30); // Ref to store total duration
  const [volume, setVolume] = useState(5);
  const [views, setViews] = useState(specificSkitData?.views || 0);
  // Default to 1 to prevent division by 0
  const [skitDuration, setSkitDuration] = useState(
    specificSkitData?.duration || 1
  ); // Default skit duration
  const [adDuration, setAdDuration] = useState(null); // Ad duration will be updated dynamically
  const [isAdPlaying, setIsAdPlaying] = useState(false); // State to track if an ad is playing
  const [isSkitPlaying, setIsSkitPlaying] = useState(false);
  const [currentMediaUrl, setCurrentMediaUrl] = useState(
    specificSkitData.mediaUrl
  ); // Initialize with skit URL
  // Calculate duration dynamically based on current playback state
  const duration = isAdPlaying ? adDuration : skitDuration;
  const [rating, setRating] = useState(
    specificSkitData?.weightedAverageRating || 0
  );
  const [votes, setVotes] = useState(specificSkitData?.ratingCount || 0);
  const [claps, setClaps] = useState(specificSkitData?.claps || 0); // Add state for claps
  // State to manage vote animation visibility
  const [showBallotModal, setShowBallotModal] = useState(false);
  const [viewError, setViewError] = useState("");
  const [clapsError, setClapsError] = useState("");
  const [ratingError, setRatingError] = useState("");
  // Dummy advertisement URL
  const [notInterestedError, setNotInterestedError] = useState("");
  const cancelVote = cancelVoteTrasnaction(setShowBallotModal);
  const skitZIndex = isUploadVideoFormOpen ? -1 : 1; // Conditionally set z-index
  const handleNotInterestedClick = () => {
    onNotInterested(specificSkitData.id); // Pass the skit id to the handler
  };
  const handleClap = useCallback(async () => {
    if (!currentUser || !specificSkitData) {
      console.error("Missing data: currentUser or specificSkitData");
      return;
    }
    try {
      // Optimistically update the local state
      setClaps((prevClaps) => prevClaps + 1);
      const responseData = await apiRecordSourceIDActivityClap(
        currentUser,
        "clapped",
        specificSkitData.id,
        specificSkitData.sourceId
      );
      console.log("Response data check:", responseData);
      if (responseData.type === "skitClapUpdated") {
        console.log(
          "Backend clap count updated successfully, here is the new claps value for the skit:",
          responseData.claps
        );
        setClaps(responseData.claps); // Update claps if type is skitClapUpdated
        updateSkitsData(); // Update the list of skits
      } else if (responseData.type === "activityLogged") {
        console.log(
          "Clap already counted, not updated again, current claps:",
          responseData.claps
        );
        setClaps(responseData.claps); // Ensure the correct count is displayed
      }
    } catch (error) {
      console.error("Error updating clap count:", error);
      setClapsError("Failed to update clap count due to server error.");
      // Revert optimistic update in case of error
      setClaps((prevClaps) => prevClaps - 1);
    }
  }, [currentUser, specificSkitData, claps, setClaps, updateSkitsData]);

  // Update duration when media metadata is loaded
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleLoadedMetadata = () => {
        if (isAdPlaying) {
          setAdDuration(Math.floor(video.duration)); // Update ad duration
        } else {
          setSkitDuration(Math.floor(video.duration)); // Update skit duration
        }
      };

      video.addEventListener("loadedmetadata", handleLoadedMetadata);
      return () =>
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
    }
  }, [isAdPlaying, currentMediaUrl]);
  // Handler for vote button click
  const placeVote = async (event) => {
    event.preventDefault(); // Prevent form submit default action
    event.stopPropagation(); // Stop propagation to parent elements
    try {
      const result = await apiFetchUserViewedSkitActivity({
        sourceId: currentUser,
        activity: "viewed",
        relatedId: specificSkitData.id,
      });

      if (result.success) {
        setShowBallotModal(true); // Open the ballot modal if check is successful
        setViewError(""); // Clear any previous errors if the check is successful
      } else {
        setViewError(
          result.message || "You must watch the full video before voting."
        ); // Set the error message from the server or a default one
      }
    } catch (error) {
      console.error("Error checking voting eligibility:", error);
      let errorMessage = "Error checking voting eligibility."; // Default error message

      if (error.response && error.response.status === 403) {
        errorMessage = "Watching the full video is required to vote."; // Specific error for 403 status
      } else if (error.message) {
        errorMessage = error.message; // Use the error message from the exception if available
      }
      setViewError(errorMessage); // Update the viewError state with the appropriate message
    }
  };

  const handleVote = useCallback(
    // onvote is a call back passed into the ballot modal and recieves this parameters
    async (skitId, ratings, categoryid, scores) => {
      // scores object includes parameter and score, rating is just the score will be used for weighted average calculation on backed later
      if (!currentUser || !specificSkitData) {
        console.error("Missing data: currentUser or specificSkitData");
        setRatingError("Missing user or skit data.");
        return;
      }
      setRatingError("");
      try {
        const recordSkitRating = {
          sourceId: currentUser,
          activity: "rated",
          relatedId: skitId,
          targetId: specificSkitData.sourceId,
          ratings,
          categoryid,
          scores,
        };
        const responseData =
          await apiRecordSourceIDActivityRatings(recordSkitRating);
        console.log("Response data check:", responseData);
        if (responseData.success) {
          console.log("New rating average:", responseData.rating);
          setRating(responseData.rating); // Update the average rating displayed
          setVotes(responseData.votes); // Update the votes count displayed
          fetchNotifications(); // Re-fetch notifications to update the notifications list
          setShowBallotModal(false); // Close the modal on successful update
          localStorage.removeItem("voteScore");
          localStorage.removeItem("ratings");
        } else {
          console.error("Rating update failed:", responseData.message);
          setRatingError(responseData.message); // Set error message from backend response
        }
      } catch (error) {
        console.error("Error updating rating count:", error);
        setRatingError("You can only vote once per skit."); // Set error message
      }
    },
    [currentUser, specificSkitData]
  );

  // Handler for report button click
  const handleReport = () => {
    // Logic to handle report action
    alert("Reported"); // Placeholder action
  };
  // Function to handle delete action
  // Assuming this function is defined within the component that has access to `skitsData` and `setSkitsData`

  console.log(
    `Current user: ${currentUser}, Skit owner: ${specificSkitData.sourceId}`
  );

  const handleProfileThumbnailSlideEnd = (userId) => {
    if (userId) {
      console.log("Navigating to user profile:", userId);
      navigate(`/user-profile/${userId}`);
    } else {
      console.log("User ID is undefined, not navigating.");
      // Handle the undefined case, e.g., show a message or do nothing
    }
  };

  // Define the function outside of the useEffect if it doesn't depend on the effect logic
  const scrollWithOffset = (el, additionalOffset = 0) => {
    const offset = 100; // The offset value should be equal to the height of your fixed header
    const elementPosition = el.offsetTop;
    const offsetPosition = elementPosition - offset - additionalOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  // Inside your component or logic file
  const logView = useCallback(async () => {
    if (!currentUser || !specificSkitData) {
      console.error("Missing data: currentUser or specificSkitData"); // Correct method to log errors
      return;
    }
    setViewError(""); // Clear any existing errors related to video viewing eligibility
    try {
      const responseData = await apiRecordSourceIDActivityView(
        currentUser,
        "viewed",
        specificSkitData.id,
        specificSkitData.sourceId
      );
      console.log("Response data check:", responseData);
      if (responseData.type === "skitViewUpdated") {
        console.log(
          "Backend view count updated successfully here is new views value for the skit",
          responseData.views
        );
        setViews(responseData.views); // Update views if type is skitViewUpdated
      } else if (responseData.type === "activityLogged") {
        console.log(
          "View already counted, not updated again, current views:",
          responseData.views
        );
        // Optionally refresh or update some other part of the UI to reflect this status
      }
    } catch (error) {
      console.error("Error updating view count:", error);
      setViewError("Failed to update view count due to server error."); // Use setViewError to display error messages
    }
  }, [currentUser, specificSkitData, setViews]); // Make sure setViews is included in the dependency array if it's a state setter to avoid a re-render, this reflects the update right away

  const handleDragUpdate = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time; // Sync video position with drag
    }
  };
  const handleVolumeChange = (newVolume) => {
    // console.log("Volume Change Triggered:", newVolume);
    const video = videoRef.current;
    if (video) {
      video.volume = newVolume / 100; // Convert from 0-100 to 0-1
      console.log("Video Volume Set To:", video.volume);
    }
    setVolume(newVolume); // Update the volume state
  };

  const handlePlayTypeLogic = async () => {
    const video = videoRef.current;
    if (!video) return;

    if (!isSkitPlaying && !isAdPlaying) {
      // Play Logic
      const updatedPlayCount = playCount + 1;
      setPlayCount(updatedPlayCount);

      if (updatedPlayCount % 3 === 0) {
        console.log("Switching to Ad...");
        saveSkitTime(specificSkitData.id, video.currentTime); // Save current skit time
        const nextAd = getNextAd();
        if (nextAd) {
          setCurrentMediaUrl(nextAd.mediaAdUrl);
          setIsAdPlaying(true);
          setIsSkitPlaying(false);
        } else {
          console.warn("No ads available.");
        }
      } else {
        console.log("Playing Skit...");
        setCurrentMediaUrl(specificSkitData.mediaUrl);
        setIsSkitPlaying(true);
        setIsAdPlaying(false);
      }

      video
        .play()
        .catch((error) => console.error("Error playing video:", error));
    } else {
      // Pause Logic
      console.log("Pausing...");
      video.pause();
      setIsSkitPlaying(false);
      setIsAdPlaying(false);
    }
  };

// Inside SkitAdCard
let skitResumeTime = 0;

if (!isAdPlaying && videoRef.current) {
  skitResumeTime = videoRef.current.currentTime; // Save skit's current time
}

useEffect(() => {
  const video = videoRef.current;

  const handleAdEndOrSkitEnd = () => {
    if (isAdPlaying) {
      console.log("Ad ended. Checking skit state...");

      const skitTimes = getSkitTimeArray(); // Fetch the entire skitTimes array
      const skitExists = skitTimes.some((entry) => entry.skitId === specificSkitData.id);

      setCurrentMediaUrl(specificSkitData.mediaUrl); // Set skit media URL regardless of state
      setIsAdPlaying(false);

      if (!skitExists) {
        console.log(`Skit '${specificSkitData.id}' not found in skitTimes. Resetting to 0.`);
        setIsSkitPlaying(false); // Prevent auto-play
        if (video) {
          video.currentTime = 0; // Reset video to the start
          console.log("Video reset to start.");
        }
      } else {
        const savedTime = getSkitTime(specificSkitData.id); // Get saved time for this skit
        console.log(`Skit '${specificSkitData.id}' found. Resuming at time: ${savedTime}`);
        setIsSkitPlaying(true);
        if (video) {
          video.addEventListener("loadedmetadata", () => {
            video.currentTime = savedTime;
            console.log(`Set video.currentTime to ${savedTime}`);
            video
              .play()
              .catch((error) => console.error("Error resuming skit:", error));
          });
        }
      }
    } else {
      if (updateProgressRef.current) {
        updateProgressRef.current(0, duration); // Reset progress bar
      }
      console.log("Skit ended. Resetting state...");
      setIsSkitPlaying(false); // Allow user to press play again
      if (video) {
        video.currentTime = 0; // Reset the video to the start
      }
    }
  };

  if (video) {
    video.addEventListener("ended", handleAdEndOrSkitEnd);
  }
  return () => {
    if (video) {
      video.removeEventListener("ended", handleAdEndOrSkitEnd);
    }
  };
}, [isAdPlaying, specificSkitData, duration, setIsSkitPlaying]);
  
  

  // console.log("find missing attributes", specificSkitData.User);
  return (
    <div
      id="skit-card"
      key={specificSkitData.id}
      style={{ zIndex: skitZIndex }}
      className={`skit-card ${isAdPlaying ? "ad-playing" : ""}`}
    >
      <VideoMonitor
        videoRef={videoRef}
        src={currentMediaUrl} // Dynamically update media URL
        autoPlay={isAdPlaying} // Automatically play ads
        title={isAdPlaying ? "" : specificSkitData.title} // Pass an empty string for ads and ensure no undefined values
        skitId={isAdPlaying ? null : specificSkitData.id}
        isAd={isAdPlaying}
        onLoadedMetadata={(duration) =>
          console.log("Duration loaded:", duration)
        }
      />
      {isAdPlaying && (
        <TimeRemainingAd
          videoRef={videoRef}
          isAdPlaying={isAdPlaying}
          adDuration={adDuration} // Pass ad duration dynamically
        />
      )}
      {/* <HandClap onClap={handleClap} claps={claps} /> */}
      {isAdPlaying ? (
        <div className="sponsored-container">
          <div className="sponsored-blur"></div>
          {/* For the blurred background */}
          <span className="sponsored-text">Sponsored</span> {/* For the text */}
        </div>
      ) : (
        <VideoPlayerControls
          videoRef={videoRef}
          duration={duration}
          playCount={playCount}
          setPlayCount={setPlayCount}
          autoPlay={isAdPlaying} // Automatically play ads
          onDragUpdate={handleDragUpdate} // Handle drag updates
          onPlayTypeChange={handlePlayTypeLogic} // Pass logic handler down
          logView={logView} // Pass the logView function
          onVolumeChange={handleVolumeChange}
          isSkitPlaying={isSkitPlaying} // Pass down the state
          setIsSkitPlaying={setIsSkitPlaying} // Allow child to update the state
          savedTime={getSkitTime(specificSkitData.id)} // Pass saved time
          updateProgressCallback={(currentTime, totalDuration) => {
            console.log(`Progress: ${currentTime}/${totalDuration}`);
          }}
        />
      )}
      {/* Interaction Section */}
      <div className="skit-actions">
        {!isAdPlaying && (
          <>
            <HandClap onClap={handleClap} claps={claps} />
            <div className="viewsCount">
              <span>
                {views} {SingularPlural(views, "View")}
              </span>
            </div>
            {specificSkitData.User && (
              <ConnectionBtn
                user={specificSkitData.User}
                slideBtnText="Slide to View Profile"
                className="slider-btn-spacer"
                onHandleSlideEnd={handleProfileThumbnailSlideEnd}
              />
            )}
            {/* Error Messages Container */}
            <div className="skit-card-error-message-container">
              {viewError && (
                <div className="skit-card-error-message">
                  <span>{viewError}</span>
                  <OvalNeumorphicBtn
                    onClick={() => setViewError("")} // Close on click
                    className="ok-button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    icon={
                      <XIcon
                        width="15"
                        height="15"
                        fillColor="var(--color-hotRed)"
                      />
                    }
                    text="K"
                  />
                </div>
              )}
              {ratingError && (
                <div className="skit-card-error-message">
                  <span>{ratingError}</span>
                  <OvalNeumorphicBtn
                    onClick={() => setRatingError("")} // Close on click
                    className="ok-button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    icon={
                      <XIcon
                        width="15"
                        height="15"
                        fillColor="var(--color-hotRed)"
                      />
                    }
                    text="K"
                  />
                </div>
              )}
            </div>
            {/* Show UsersWhoRatedList popup based on showUsersWhoRated state */}
            <div
              className={`skit-actions-section ${isAdPlaying ? "ad-playing" : ""}`}
            >
              {showBallotModal && (
                <BallotModal
                  skitId={specificSkitData.id}
                  categoryid={specificSkitData.categoryid} // Pass the category ID to BallotModal
                  onVote={handleVote}
                  onClose={cancelVote}
                />
              )}
              {hasDeletePermission && (
                <SmallPlainInsetBtn
                  image={
                    <TrashCanIcon
                      height="20px"
                      width="20px"
                      style={{ marginRight: "3px" }}
                    />
                  }
                  text="Delete"
                  width="88px"
                  style={{
                    fontSize: "1rem",
                  }}
                  onClick={() => onDelete(specificSkitData.id)}
                />
              )}
              <SmallPlainInsetBtn
                image={
                  <BallotIcon
                    height="20px"
                    width="20px"
                    style={{ marginRight: "3px" }}
                  />
                }
                text="Vote"
                width="88px"
                style={{
                  fontSize: "1rem",
                }}
                onClick={(event) => placeVote(event)}
              />
              <SmallPlainInsetBtn
                image={
                  <WhistleIcon
                    height="20px"
                    width="20px"
                    style={{ marginRight: "3px" }}
                  />
                }
                text="Report"
                width="88px"
                style={{
                  fontSize: "1rem",
                }}
                onClick={(event) => handleReport(event)}
              />
              {!hasDeletePermission && (
                <SmallPlainInsetBtn
                  image={
                    <NotInterestedIcon
                      width="30px"
                      fillColor="var(--color-bloodred)"
                    />
                  }
                  text="Hide"
                  width="88px"
                  style={{
                    fontSize: "1rem",
                  }}
                  onClick={handleNotInterestedClick}
                />
              )}
              {notInterestedError && (
                <div className="error-message">{notInterestedError}</div>
              )}
            </div>
          </>
        )}
      </div>
      {/* Only add HashLink if you have a page to navigate to for detailed ratings */}
      <HashLink
        to={`/skit/${specificSkitData.id}/ratings#skitRatersTop`}
        scroll={(el) => scrollWithOffset(el, 150)} // the function above helps me position it correctly that the listing hasing section with an awesome scroll effect
        style={{
          textDecoration: "none",
          color: "var(--color-gold)",
          position: "relative",
        }}
      >
        <div className="rating-link">
          {votes > 0 ? (
            <div className="rating-link">
              <span>
                {(rating * 5).toFixed(1)} · rating average · {votes}{" "}
                {SingularPlural(votes, "vote")}
              </span>
            </div>
          ) : null}
        </div>
      </HashLink>
    </div>
  );
};
export default SkitAdCard;
