import React, { useRef, useEffect } from "react";
import "./animatedClapperBoard.scss";

const ClapperBoard = ({ isClapping }) => {
  // Ref to the div that should have the clapping animation
  const clapperRef = useRef(null);

  useEffect(() => {
    // If isClapping is true, add the animation
    if (isClapping) {
      clapperRef.current.style.animation = "open .25s ease-out alternate";
    } else {
      // Otherwise, remove the animation
      clapperRef.current.style.animation = "";
    }
  }, [isClapping]); // Run this effect when isClapping changes

  return (
    <div className="clapper">
      <div ref={clapperRef} className="clapper-image"></div>
      <div className="clapper-text">
        <p>Press action to see skits</p>
      </div>
    </div>
  );
};
export default ClapperBoard;
