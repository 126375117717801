import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from "react";
import {
  apiUpdateRemoteColor,
  apiFetchCurrentUserRemoteColorSetting,
  apiFetchCurrentUserAttributes,
} from "../../api/backend/user";
import { useAdCampaigns } from "../hooks/useAdCampaigns";
import { getCookie, setCookie, clearCookie } from "../../utils/cookies";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => getCookie("userId") || null);
  const [remoteColor, setRemoteColor] = useState("glass"); // Default value same as backend
  const [userAttributes, setUserAttributes] = useState(null);
  const [playCount, setPlayCount] = useState(
    () => parseInt(localStorage.getItem("playCount"), 10) || 0
  );
  const { fetchCampaigns } = useAdCampaigns(); // Add this within your AuthProvider function

  // Unified function to handle user setup
  const setUserInfo = useCallback(async (userId) => {
    if (!userId) return;
    try {
      setCurrentUser(userId);
      setCookie("userId", userId);
      localStorage.setItem("playCount", 0);
      setPlayCount(0);
      const remoteColorResponse = await apiFetchCurrentUserRemoteColorSetting(userId);
      setRemoteColor(remoteColorResponse.data.remoteColor || "glass");

      const userAttributesResponse = await apiFetchCurrentUserAttributes(userId);
      setUserAttributes(userAttributesResponse.data);

    } catch (error) {
      console.error("Failed to set user info:", error);
    }
  }, []);
  const resetPlayCount = useCallback(() => {
    setPlayCount(0);
    localStorage.setItem("playCount", 0);
  }, []);

  // Automatically set user info on mount
  useEffect(() => {
    const userId = getCookie("userId");
    if (userId) {
      setUserInfo(userId); // Set user info
    }
  }, [setUserInfo]);

    /**
   * Dedicated useEffect to fetch ads when the user logs in
   */
    useEffect(() => {
      if (currentUser) {
        console.log("Fetching initial ad campaigns...");
        fetchCampaigns(); // Trigger initial ad fetching when the user logs in
      }
    }, [currentUser, fetchCampaigns]);

  const remoteColorUserSetting = async (userId, remoteColor) => {
    try {
      const response = await apiUpdateRemoteColor(userId, remoteColor);
      setRemoteColor(response.data.remoteColor);
    } catch (error) {
      console.error("Failed to update remote color:", error);
    }
  };

  const handleLogout = useCallback(() => {
    clearCookie("userId"); // Clear the cookie on logout
    localStorage.clear();
    setCurrentUser(null);
    setPlayCount(0);
    setRemoteColor("glass"); // Reset remote color
    setUserAttributes(null); // Clear user attributes
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userAttributes,
        setUserAttributes,
        setUserInfo,
        remoteColor,
        remoteColorUserSetting,
        handleLogout,
        playCount,
        setPlayCount,
        resetPlayCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
