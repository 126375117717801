import React from "react";
import NeumorphismField from "../../neumorphismField/neumorphismField";
import YearSelect from "../../yearDropdown/yearDropdown";
import MonthSelect from "../../monthDropDown/monthDropdown";
import StateDropdown from "../../stateDropdown/stateDropdown";
import NeumorphicDropDwn from "../../neumorphicDropDwn/neumorphicDropDwn";
import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator";
import { paymentMethodOptions } from "../../../constants/dropdownOptions";

const BillingForm = ({
    billingDetails,
    setBillingDetails,
    paymentMethod,
    onPaymentMethodChange,
    isPaymentMethodOpen,
    setPaymentMethodOpen,
    isMonthDropdownOpen,
    setMonthDropdownOpen,
    errors,
    expirationMonth,
    setExpirationMonth,
    expirationYear,
    setExpirationYear,
    resetMonthDropdown,
    resetYearDropdown,
    onMonthDropdownReset,
    onYearDropdownReset,
    creditCardNumber,
    setCreditCardNumber,
    onFieldChange,
    selectedState,
    clearState,
    setSelectedState,
    isYearDropdownOpen,
    setYearDropdownOpen,
    isStateDropdownOpen,
    setStateDropdownOpen,
    yearOptions,
    contextType,
  }) => {
    return (
      <div className="payment-details">
        {/* Payment Method Dropdown */}
        <NeumorphicDropDwn
          options={paymentMethodOptions}
          selectedOption={paymentMethod}
          onChange={onPaymentMethodChange}
          placeholder={errors.paymentMethod || "Select Payment Method"}
          isOpen={isPaymentMethodOpen}
          setOpen={setPaymentMethodOpen}
          className={`textBox ${
            errors.paymentMethod && !isPaymentMethodOpen
              ? "error error-border"
              : ""
          }`}
        />
  
        {/* Expiration Fields */}
        <div className="credit-card-expiry-container">
          <label htmlFor="expiration-month" className="expiry-label">
            Expiration Month
          </label>
          <MonthSelect
            value={expirationMonth}
            onChange={(month) => {
              setExpirationMonth(month);
              localStorage.setItem(
                getStorageKey("ccExpMonth", contextType),
                month
              );
            }}
            isOpen={isMonthDropdownOpen}
            onToggle={setMonthDropdownOpen}
            resetMonthDropdown={resetMonthDropdown}
            onResetMonthComplete={onMonthDropdownReset}
            error={errors.expirationMonth}
            className={`textBox ${
              errors.expirationMonth && !isMonthDropdownOpen
                ? "error error-border"
                : ""
            }`}
          />
  
          <label htmlFor="expiration-year" className="expiry-year-label">
            Expiration Year
          </label>
          <YearSelect
            value={expirationYear}
            onChange={(e) => setExpirationYear(e.target.value)}
            options={yearOptions}
            isOpen={isYearDropdownOpen}
            onToggle={setYearDropdownOpen}
            resetYearDropdown={resetYearDropdown}
            onResetYearComplete={onYearDropdownReset}
            error={errors.expirationYear}
            className={`textBox ${
              errors.expirationYear && !isYearDropdownOpen
                ? "error error-border"
                : ""
            }`}
          />
        </div>
        {/* Credit Card Number Field */}
        <NeumorphismField
          className={`creditcard-number-neumorphism-field ${
            errors.creditCardNumber ? "error error-border" : ""
          }`}
          placeholder={errors.creditCardNumber || "Enter Credit Card Number"}
          type="text"
          value={creditCardNumber}
          onChange={(e) => {
            setCreditCardNumber(e.target.value);
            localStorage.setItem(
              getStorageKey("creditCardNumber", contextType),
              e.target.value
            );
          }}
        />
        {/* Billing Details Fields */}
        <NeumorphismField
          className={`name-neumorphism-field ${
            errors.firstName ? "error error-border" : ""
          }`}
          placeholder={errors.firstName || "Enter First Legal Name"}
          type="text"
          value={billingDetails.firstName}
          onChange={(e) => onFieldChange("firstName", e.target.value)}
        />
        <NeumorphismField
          className={`name-neumorphism-field ${
            errors.lastName ? "error error-border" : ""
          }`}
          placeholder={errors.lastName || "Enter Last Legal Name"}
          type="text"
          value={billingDetails.lastName}
          onChange={(e) => onFieldChange("lastName", e.target.value)}
        />
        <h4 className="checkoutHeader">Enter Billing</h4>
        <NeumorphismField
          className={`name-neumorphism-field ${
            errors.address1 ? "error error-border" : ""
          }`}
          placeholder={
            errors.address1 || "Address Line 1 (ex. Street and house number)"
          }
          type="text"
          value={billingDetails.address1}
          onChange={(e) => onFieldChange("address1", e.target.value)}
        />
        <NeumorphismField
          className="name-neumorphism-field"
          placeholder="Address Line 2 (ex. Apartment, suite, unit, building, or floor numbers)"
          type="text"
          value={billingDetails.address2}
          onChange={(e) => onFieldChange("address2", e.target.value)}
        />
        <NeumorphismField
          className={`name-neumorphism-field ${
            errors.city ? "error error-border" : ""
          }`}
          placeholder={errors.city || "Enter City"}
          type="text"
          value={billingDetails.city}
          onChange={(e) => onFieldChange("city", e.target.value)}
        />
        <NeumorphismField
          className={`name-neumorphism-field ${
            errors.postalCode ? "error error-border" : ""
          }`}
          placeholder={errors.postalCode || "Enter billing zipcode"}
          type="text"
          value={billingDetails.postalCode}
          onChange={(e) => onFieldChange("postalCode", e.target.value)}
        />
        <NeumorphismField
          className={`name-neumorphism-field ${
            errors.country ? "error error-border" : ""
          }`}
          placeholder={errors.country || "Enter billing country"}
          type="text"
          value={billingDetails.country}
          onChange={(e) => onFieldChange("country", e.target.value)}
        />
        <StateDropdown
          selectedState={selectedState}
          clearState={clearState}
          setSelectedState={(state) => {
            console.log("StateDropdown selected:", state);
            setSelectedState(state); // Update selectedState
            localStorage.setItem(
              getStorageKey("selectedState", contextType),
              state
            );
            // Update billingDetails to include the selected state
            setBillingDetails((prevDetails) => {
              const updatedDetails = {
                ...prevDetails,
                state: state, // Add/update the state field in billingDetails
              };
              console.log("Updated billingDetails:", updatedDetails); // Log updated billingDetails
              return updatedDetails;
            });
          }}
          isOpen={isStateDropdownOpen}
          onToggle={setStateDropdownOpen}
          placeholder={errors.selectedState || "Select Billing State"}
          className={`billing-statedropdown ${
            errors.selectedState && !isStateDropdownOpen
              ? "error error-border"
              : ""
          }`}
        />
      </div>
    );
  };
  
  export default BillingForm;
  