import React, { useState, useEffect } from "react";
import NeumorphicDropDwn from "../neumorphicDropDwn/neumorphicDropDwn";
import NeumorphismFieldMoney from "../neumorphismField/neumorphismFieldMoney";
import { paymentTypeOptions } from "../../constants/dropdownOptions";
import { ImgTopBtn } from "../buttons/index";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";
import { periodChargeThresholdType } from "../../constants/dropdownOptions";
import { campaignAmountTypeErrorMessages } from "../../constants/errorMessages";
import "./campaignAmount.scss";

const CampaignAmount = ({ onSaveAmount, contextType, setErrorMessage }) => {
  // State Management
  const [totalBudget, setTotalBudget] = useState(() => {
    const storedTotalBudget = localStorage.getItem(
      getStorageKey("totalBudget", contextType)
    );
    console.log("Loaded totalBudget from localStorage:", storedTotalBudget);
    return storedTotalBudget ? parseFloat(storedTotalBudget) : 0; // Parse to float if value exists, default to 0 otherwise
  });
  const [paymentType, setPaymentType] = useState("");
  const [paymentSubscription, setPaymentSubscription] = useState("");
  const [costPerImpression, setCostPerImpression] = useState(0.01);
  const [costPerClick, setCostPerClick] = useState(0.5);
  const [selectedPeriodName, setSelectedPeriodName] = useState("");
  const [errors, setErrors] = useState({
    totalBudget: "",
    paymentType: "",
    paymentSubscription: "",
  });

  // Focus and Dropdown States
  const [isCampaignBudgetFocused, setCampaignBudgetFocus] = useState(false);
  const [isCampaignTypeFocused, setCampaignTypeFocus] = useState(false);
  const [isBudgetPeriodFocused, setBudgetPeriodFocus] = useState(false);
  const [isPaymentTypeOpen, setPaymentTypeOpen] = useState(false);
  const [isPaymentSubscriptionOpen, setPaymentSubscriptionOpen] =
    useState(false);

  // Load Saved Data on Mount
  useEffect(() => {
    const storedBudget = parseFloat(
      localStorage.getItem(getStorageKey("totalBudget", contextType)) || 0
    );
    const storedPaymentType = localStorage.getItem(
      getStorageKey("paymentType", contextType)
    );
    const storedSubscription = localStorage.getItem(
      getStorageKey("paymentSubscription", contextType)
    );

    if (storedBudget) setTotalBudget(storedBudget);
    if (storedPaymentType) setPaymentType(storedPaymentType);
    if (storedSubscription) setPaymentSubscription(storedSubscription);
  }, [contextType]);

  // Handlers for Inputs and Dropdowns
  const handlePaymentTypeChange = (e) => {
    const selectedValue = e.target.value;
    setPaymentType(selectedValue);

    // Set default cost per type and reset the other
    if (selectedValue === "impressions") {
      setCostPerImpression(0.01);
      setCostPerClick(0);
    } else if (selectedValue === "clicks") {
      setCostPerClick(0.5);
      setCostPerImpression(0);
    }

    localStorage.setItem(
      getStorageKey("paymentType", contextType),
      selectedValue
    );
  };

  const handlePaymentSubscriptionChange = (e) => {
    const selectedValue = e.target.value;
    setPaymentSubscription(selectedValue);

    const selectedPeriod = periodChargeThresholdType.find(
      (option) => option.value === selectedValue
    );
    if (selectedPeriod) {
      setSelectedPeriodName(selectedPeriod.name);
    }

    localStorage.setItem(
      getStorageKey("paymentSubscription", contextType),
      selectedValue
    );
  };

  const handleCampaignBudgetFocus = () => {
    setCampaignBudgetFocus(true);
    setErrors((prev) => ({ ...prev, totalBudget: "" }));
  };

  const handleCampaignBudgetBlur = () => {
    setCampaignBudgetFocus(false);
  };

  const handleCampaignTypeFocus = () => {
    setCampaignTypeFocus(true);
    setErrors((prevErrors) => ({ ...prevErrors, paymentType: "" }));
  };

  const handleBudgetPeriodFocus = () => {
    setBudgetPeriodFocus(true);
    setErrors((prevErrors) => ({ ...prevErrors, paymentSubscription: "" }));
  };

  const saveAmount = () => {
    const newErrors = {
      totalBudget: "",
      paymentType: "",
      paymentSubscription: "",
    };

    let isValid = true;

    // Validate total budget
    if (!totalBudget || totalBudget <= 0) {
      newErrors.totalBudget = campaignAmountTypeErrorMessages.campaignBudget;
      console.log("Validation Error: Total Budget is invalid:", totalBudget);
      isValid = false;
    } else {
      console.log("Total Budget is valid:", totalBudget);
    }

    // Validate payment type
    if (!paymentType) {
      newErrors.paymentType = campaignAmountTypeErrorMessages.paymentType;
      console.log("Validation Error: Payment Type is missing.");
      isValid = false;
    } else {
      console.log("Payment Type is valid:", paymentType);
    }

    // Validate payment subscription
    if (!paymentSubscription) {
      newErrors.paymentSubscription =
        campaignAmountTypeErrorMessages.paymentSubscription;
      console.log("Validation Error: Payment Subscription is missing.");
      isValid = false;
    } else {
      console.log("Payment Subscription is valid:", paymentSubscription);
    }

    // Update the error state after initial validation
    setErrors(newErrors);
    if (!isValid) {
      console.log("Validation failed at the first stage. Exiting.");
      return; // Exit if the initial validation fails
    }

    // Fetch necessary data from localStorage
    const dailyCostLimit = parseFloat(
      localStorage.getItem(getStorageKey("dailyCostLimit", contextType)) || "0"
    );
    const campaignExpirationDate = new Date(
      localStorage.getItem(getStorageKey("campaignExpirationDate", contextType))
    );
    const costPerImpression = parseFloat(
      localStorage.getItem(getStorageKey("costPerImpression", contextType)) ||
        "0"
    );
    const costPerClick = parseFloat(
      localStorage.getItem(getStorageKey("costPerClick", contextType)) || "0"
    );

    const currentDate = new Date();
    const timeDifference =
      campaignExpirationDate.getTime() - currentDate.getTime();
    const campaignDuration = Math.max(
      Math.ceil(timeDifference / (1000 * 3600 * 24)),
      1
    );

    console.log("Fetched Data:");
    console.log("Daily Cost Limit:", dailyCostLimit);
    console.log("Campaign Expiration Date:", campaignExpirationDate);
    console.log("Current Date:", currentDate);
    console.log("Campaign Duration (days):", campaignDuration);

    const minimalDailyCost =
      paymentType === "impressions" ? costPerImpression : costPerClick;
    const minimalTotalBudget = minimalDailyCost * campaignDuration;

    console.log("Minimal Daily Cost:", minimalDailyCost);
    console.log("Minimal Total Budget:", minimalTotalBudget);

    // Budget validation logic
    if (dailyCostLimit === 0) {
      const minimalCost = campaignDuration * minimalDailyCost;

      console.log(
        `Validation Error: Daily Cost Limit is 0. Budget (${totalBudget}) cannot sustain the campaign for ${campaignDuration} days.`
      );
      setErrorMessage(
        `Your daily cost limit is too low for the selected campaign duration. To run your campaign for ${campaignDuration} days, you need at least $${minimalCost.toFixed(
          2
        )} for minimal coverage (1 ${paymentType} per day).`
      );
      isValid = false;
    } else if (totalBudget < minimalTotalBudget) {
      const shortfall = minimalTotalBudget - totalBudget;

      console.log(
        `Validation Error: Total Budget (${totalBudget}) is less than Required Budget (${minimalTotalBudget}).`
      );
      setErrorMessage(
        `Your total budget is insufficient. You need $${shortfall.toFixed(
          2
        )} more to run your campaign for ${campaignDuration} days with minimal coverage (1 ${paymentType} per day).`
      );
      isValid = false;
    } else {
      console.log("Budget validation passed.");
      // Save `totalBudget` to local storage
      localStorage.setItem(
        getStorageKey("totalBudget", contextType),
        totalBudget.toString()
      );
      console.log("Total Budget saved to localStorage:", totalBudget);

      // Concatenate `totalBudget` and pass it to the next process
      console.log("All validations passed. Proceeding to save the amount.");
      onSaveAmount({
        totalBudget, // Only passing totalBudget for now
      });
    }

    if (!isValid) {
      console.log("Validation failed at the second stage. Exiting.");
      return; // Exit if the new validation fails
    }

    // Proceed to save the amount
    console.log("All validations passed. Proceeding to save the amount.");
    onSaveAmount({
      totalBudget,
      paymentType,
      paymentSubscription,
      costPerImpression,
      costPerClick,
    });
  };

  return (
    <div id="SetCampaignTypeAmount" className="createCampaignPopup">
      <div className="createCampaignContent">
        <NeumorphismFieldMoney
          label="Total Budget:"
          value={totalBudget}
          placeholder="Enter Amount" // Default placeholder text
          onChange={(newValue) => setTotalBudget(newValue)}
          contextType={contextType}
          totalBudget={totalBudget}
          setTotalBudget={setTotalBudget}
          setPaymentType={setPaymentType}
          costPerImpression={costPerImpression}
          paymentSubscription={paymentSubscription}
          costPerClick={costPerClick}
          paymentType={paymentType}
          error={errors.totalBudget} // Pass the error message
          className={`campaign-budget-input ${errors.totalBudget && !isCampaignBudgetFocused ? "error-message error-border" : ""}`}
          onFocus={handleCampaignBudgetFocus}
          onBlur={handleCampaignBudgetBlur}
        />
        {(() => {
          switch (
            paymentType.toLowerCase() // Ensure it's case-insensitive
          ) {
            case "impressions":
              return (
                <p className="impressionContent">
                  Cost Per Impression: ${costPerImpression.toFixed(2)}
                </p>
              );

            case "clicks":
              return (
                <p className="clickContent">
                  Cost Per Click: ${costPerClick.toFixed(2)}
                </p>
              );

            default:
              return <p className="emptySpace"></p>;
          }
        })()}
        <div className="dropdown-wrapper">
          <NeumorphicDropDwn
            options={paymentTypeOptions}
            selectedOption={paymentType}
            setPaymentType={setPaymentType}
            setCostPerImpression={setCostPerImpression}
            setCostPerClick={setCostPerClick}
            onChange={handlePaymentTypeChange}
            placeholder={errors.paymentType || "Select Campaign Type"} // Use error message as placeholder if present
            isOpen={isPaymentTypeOpen}
            setOpen={setPaymentTypeOpen}
            onFocus={handleCampaignTypeFocus}
            className={`campaign-type-dropdown ${errors.paymentType && !isCampaignTypeFocused ? "error-message-style error-border" : ""}`} // Apply styles dynamically
            error={errors.paymentType} // Pass the error for dynamic value handling
          />
        </div>
        <div className="dropdown-wrapper">
          <NeumorphicDropDwn
            options={periodChargeThresholdType.map((option) => ({
              label: option.name,
              value: option.value,
            }))}
            selectedOption={paymentSubscription}
            onChange={handlePaymentSubscriptionChange}
            placeholder="Select Budget Period"
            isOpen={isPaymentSubscriptionOpen}
            setOpen={setPaymentSubscriptionOpen}
            onFocus={handleBudgetPeriodFocus}
            error={errors.paymentSubscription} // Pass the error message for dynamic placeholder
            className={`campaign-budgetPeriod-dropdown ${errors.paymentSubscription ? "error-border" : ""}`} // Dynamically apply error-border class
          />
        </div>
        <div className="amount-button">
          <ImgTopBtn
            imgTopBtnText="Save Amount and Checkout"
            onClick={saveAmount}
            imgTopBtnWidth="200px"
            imgTopBtnFontColor="var(--color-bloodred)"
            position="relative"
            extraImgTopBtnStyles={{
              backgroundImage:
                "linear-gradient(120deg, #e0e0e0 0%, #fefefe 80%)",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default CampaignAmount;
