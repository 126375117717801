// Corrected import statement
import Axios, { REQUEST_METHOD } from "../axios";

// skipVerification function using the custom Axios instance
export function apiSkipVerification(userId) {
  return Axios.fetch({
    url: "/users/skip-verification",
    method: REQUEST_METHOD.POST,
    data: {
      userId: userId,
      skipVerified: true,
    },
  });
}

export async function apiCheckUsername(username) {
  return Axios.fetch({
    url: "/users/suggest-username-if-it-exist",
    method: REQUEST_METHOD.GET,
    data: { username },
  }).catch((error) => {
    console.error("Error checking username:", error);
    throw error;
  });
}

export async function apiVerifyUserByPhoneNumber(phoneNumber) {
  const params = { phoneNumber };
  const queryString = new URLSearchParams(params).toString();
  console.log(`Request URL: /users/accountholder-mobile-verify?${queryString}`);

  return Axios.fetch({
    url: "/users/accountholder-mobile-verify",
    method: REQUEST_METHOD.GET,
    params, // Axios should automatically handle this as a query string
  });
}

export function apiCurrentUserPswrdReset(userId, newPassword) {
  return Axios.fetch({
    url: "/users/reset-password",
    method: REQUEST_METHOD.POST,
    data: {
      userId,
      newPassword,
    },
  });
}

export function apiAddAcctAtributes(data) {
  return Axios.fetch({
    url: "/users/add-acct-attributes",
    method: REQUEST_METHOD.POST,
    data,
  });
}

export function apiCanceledSignupDeleteUserRecord(userId) {
  return Axios.fetch({
    url: `/users/canceled-signup/${userId}`,
    method: REQUEST_METHOD.DELETE,
  });
}

// signUp function using the custom Axios instance
export function apiSignUp(userData) {
  return Axios.fetch({
    url: "/users/sign-up",
    method: REQUEST_METHOD.POST,
    data: userData,
  });
}

// Function to check phone number and username
export async function apiCheckPhoneNumberAndUsername(phoneNumber, username) {
  return Axios.fetch({
    url: "/users/check-phone-and-username",
    method: REQUEST_METHOD.GET,
    params: { phoneNumber, username },
  }).catch((error) => {
    console.error("Error checking phone number and username:", error);
    throw error;
  });
}
export function apiSigninUsername(username, password) {
  return Axios.fetch({
    url: "/users/sign-in-username",
    method: REQUEST_METHOD.POST,
    data: {
      username,
      password,
    },
  });
}

export async function apiSigninMobile(phoneNumber, password) {
  return Axios.fetch({
    url: "/users/sign-in-mobile",
    method: REQUEST_METHOD.POST,
    data: {
      phoneNumber,
      password,
    },
  });
}

export async function apiSigninEmail(email, password) {
  return Axios.fetch({
    url: "/users/sign-in-email",
    method: REQUEST_METHOD.POST,
    data: {
      email,
      password,
    },
  });
}
export function apiCheckEmail(email) {
  return Axios.fetch({
    url: `/users/check-email?email=${encodeURIComponent(email)}`,
    method: REQUEST_METHOD.GET,
  });
}

export function apiUpdateMemberEmail(userId, email) {
  return Axios.fetch({
    url: `/users/update-email`,
    method: REQUEST_METHOD.POST,
    data: { userId, email },
  });
}

export function apiUpdateMemberUserName(userId, username) {
  return Axios.fetch({
    url: `/users/update-username`,
    method: REQUEST_METHOD.POST,
    data: { userId, username },
  });
}

export async function apiUpdateMemberMobile(userId, phoneNumber) {
  return Axios.fetch({
    url: `/users/update-member-mobile`,
    method: REQUEST_METHOD.POST,
    data: { userId, phoneNumber },
  }).catch((error) => {
    console.error("Error checking phone number:", error);
    throw error;
  });
}

export async function apiDeleteProfileImage(userId) {
  return Axios.fetch({
    url: "/uploads/delete-profile-image",
    method: REQUEST_METHOD.DELETE,
    data: { userId },
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// export async function apiDeleteAdMediaS3DataBaseRecord(userId) {
//   return Axios.fetch({
//     url: "/uploads/delete-ad-media-s3-database",
//     method: REQUEST_METHOD.DELETE,
//     data: { userId },
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
// }
export async function apiDeleteAdMediaOnlyS3(sourceId) {
  return Axios({
    url: "/uploads/delete-ad-media-only-s3",
    method: "DELETE",
    data: { sourceId }, // Pass the sourceId (userId or equivalent) as required
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// Function to call campaign payment API
// Corrected apiCampaignPayment function using your Axios wrapper
export async function apiCampaignPayment(data) {
  return Axios.fetch({
    url: "/payment/campaign-payment-type",
    method: REQUEST_METHOD.POST,
    data, // Pass the entire payload here
  });
}
export async function calculateTotalCostAndItems(data) {
  return Axios.fetch({
    url: "/payment/calculate-total-cost-and-items",
    method: REQUEST_METHOD.POST,
    data,
  });
}

export const apiFetchInitialTransactionHistory = (sourceId, limit = 20) => {
  return Axios.fetch({
    url: "/payment/get-inital-array-transaction-history",
    method: REQUEST_METHOD.GET,
    params: {
      sourceId,
      limit, // No `lastCreatedAt` for the initial load
    },
  });
};

export const apiFetchMoreTransactionHistory = (sourceId, lastCreatedAt, limit = 20) => {
  return Axios.fetch({
    url: "/payment/fetch-view-moretransaction-history",
    method: REQUEST_METHOD.GET,
    params: {
      sourceId,
      lastCreatedAt, // Use `lastCreatedAt` for pagination
      limit,
    },
  });
};


export default {
  apiFetchInitialTransactionHistory,
  apiFetchMoreTransactionHistory,
  apiCampaignPayment,
  apiDeleteAdMediaOnlyS3,
  calculateTotalCostAndItems,
  // apiDeleteAdMediaS3DataBaseRecord,
  apiUpdateMemberEmail,
  apiUpdateMemberUserName,
  apiUpdateMemberMobile,
  apiCheckEmail,
  apiDeleteProfileImage,
  apiSigninUsername,
  apiSigninMobile,
  apiSigninEmail,
  apiCheckPhoneNumberAndUsername,
  apiCheckUsername,
  apiSignUp,
  apiCanceledSignupDeleteUserRecord,
  apiAddAcctAtributes,
  apiCurrentUserPswrdReset,
  apiVerifyUserByPhoneNumber,
  apiSkipVerification,
};

// reaasons for post signin
// Security: Sensitive information such as passwords should not be
// sent in the URL, which is what happens with a GET request. URLs can be
// logged, bookmarked, or stored in browser history, which can expose sensitive
// information. POST requests, on the other hand, send data in the body of the request,
// which is not logged or stored in the same way.
