import { useCallback, useState } from 'react';
import { useAuth } from '../context/authContext';
import { apiFetchCurrentUserDetails} from '../../api/backend/user';

// passing the currentUser id from the remote and then
// passing this call back to the profile page to run the call back on mount
// when the user clicks on the remote profile button we will call the current user's
// data, then we will define the current user as account holder so user can edit
// the profile
export const useCurrentUserAcctDetails = () => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const fetchDetailsOnProfileClick = useCallback(async () => {
      setLoading(true);
      try {
        const response = await apiFetchCurrentUserDetails(currentUser);
        if (response && response.data) {
          return response.data; // Directly return the user details
        }
        return null; // Return null if no response or no data in the response
      } catch (error) {
        console.error("Failed to fetch current user details:", error);
        setError(error);
        return null; // Return null in case of error
      } finally {
        setLoading(false);
      }
    }, [currentUser]);
  
    return {
      loading,
      error,
      fetchDetailsOnProfileClick, // This function now returns user details or null
      useCurrentUserAcctDetails,
    };
  };
  