import { errorMessages } from "../../constants/errorMessages.js";
import {
  validatePasswordValue,
  validateUserNameValue,
} from "../../constants/validate.js";
export const signupErrorHandler = (
  userNameValue,
  phoneNumberValue,
  confirmPasswordValue,
  currentPasswordValue,
  currentContext,
  setUserNameValueError,
  setUserNameToolTipVisible,
  setPhoneNumberValueError,
  setConfirmPasswordValueError,
  setCurrentPasswordValueError,
  setCurrentPasswordToolTipVisible
) => {
  let errors = {};
  if (userNameValue.length === 0) {
    if (currentContext === "signUp" || currentContext === "completeSignup" ) {
      errors.userNameValueError = errorMessages.username;
      setUserNameValueError(errorMessages.username);
    } else if (currentContext === "signIn") {
      errors.userNameValueError = "";
      setUserNameValueError("");
    }
  } else {
    // This part runs for both 'signup' and 'settings' when userNameValue is not empty
    const userNameResults = validateUserNameValue(userNameValue);
    const userNameValid = userNameResults.every((rule) => rule.isValid);
    if (!userNameValid) {
      errors.userNameValueError = "Username validation failed";
      setUserNameValueError("Username validation failed");
      setUserNameToolTipVisible(true);
    } else {
      setUserNameToolTipVisible(false);
      setUserNameValueError("");
    }
  }

  if (phoneNumberValue.length === 0 && (currentContext === "signUp" || currentContext === "completeSignup")) {
    errors.phoneNumberValueError = errorMessages.phone;
  }
  setPhoneNumberValueError(errors.phoneNumberValueError || "");

  if (confirmPasswordValue.length === 0 && (currentContext === "signUp" || currentContext === "completeSignup")) {
    errors.confirmPasswordValueError = errorMessages.confirmPassword;
    setConfirmPasswordValueError(errors.confirmPasswordValueError);
  } else if (confirmPasswordValue !== currentPasswordValue) {
    errors.confirmPasswordValueError = "Passwords do not match";
    setConfirmPasswordValueError(errors.confirmPasswordValueError);
  } else {
    setConfirmPasswordValueError(""); // Clear the error message if the passwords match
  }
  if (currentPasswordValue.length === 0 && (currentContext === "signUp" || currentContext === "completeSignup")) {
    errors.currentPasswordValueError = errorMessages.password;
    setCurrentPasswordValueError(errorMessages.password);
  } else {
    const currentPasswordValidationResults =
      validatePasswordValue(currentPasswordValue);
    const isAnyRuleInvalid = currentPasswordValidationResults.some(
      (rule) => !rule.isValid
    );
    if (isAnyRuleInvalid) {
      errors.currentPasswordValueError = "Password validation failed";
      setCurrentPasswordValueError("Password validation failed");
      setCurrentPasswordToolTipVisible(true); // Show tooltip
    } else {
      setCurrentPasswordToolTipVisible(false); // Hide tooltip when all validation rules are met
      setCurrentPasswordValueError(""); // Clear the error message
    }
  }
  console.log("Errors:", errors);
  return { errors };
};
