// components/emoji/fullEmoteChoices.js
import React from "react";
import "./emotes.scss";

export const renderExpression = (emoteChoice) => {
  switch (emoteChoice) {
    case "angry":
      return (
        <div className="emotes-angry-face">
          <div className="emotes-angry-expression">
            <div className="emotes--angry-eyebrows"></div>
            <div className="emotes-eye emotes-eye-left"></div>
            <div className="emotes-eye emotes-eye-right"></div>
            <div className="emotes-angry-mouth"></div>
          </div>
        </div>
      );
    case "wow":
      return (
        <div className="emotes-wow-face">
          <div className="emotes-wow-expression">
            <div className="emotes-wow-eyes emotes-wow-eye-left"></div>
            <div className="emotes-wow-eyes emotes-wow-eye-right"></div>
            <div className="emotes-wow-eyebrows emotes-wow-eyebrow-right"></div>
            <div className="emotes-wow-eyebrows emotes-wow-eyebrow-left"></div>
            <div className="emotes-wow-mouth"></div>
          </div>
        </div>
      );
    case "serious":
      return (
        <div className="emotes-serious-face">
          <div className="emotes-serious-expression">
            <div className="emotes-serious-eyebrows"></div>
            <div className="emotes-eye emotes-eye-left"></div>
            <div className="emotes-eye emotes-eye-right"></div>
            <div className="emotes-serious-mouth"></div>
          </div>
        </div>
      );
    case "teethy-laugh":
      return (
        <div className="emotes-teethy-laugh-face">
          <div className="emotes-teethy-laugh-expression">
            <div className="emotes-teethy-laugh-eyebrows"></div>
            <div className="emotes-teethy-laugh-eye emotes-teethy-laugh-eye-left"></div>
            <div className="emotes-teethy-laugh-eye emotes-teethy-laugh-eye-right"></div>
            <div className="emotes-teethy-laugh-mouth"></div>
          </div>
        </div>
      );
    case "tongue-laugh":
      return (
        <div className="emotes-tongue-laugh-face">
          <div className="emotes-tongue-laugh-expression">
            <div className="emotes-tongue-laugh-eyebrows"></div>
            <div className="emotes-tongue-laugh-eye emotes-tongue-laugh-eye-left"></div>
            <div className="emotes-tongue-laugh-eye emotes-tongue-laugh-eye-right"></div>
            <div className="emotes-tongue-laugh-mouth">
              <div className="emotes-tongue"></div>
            </div>
          </div>{" "}
        </div>
      );
    case "annoyed":
      return (
        <div className="emotes-annoyed-face">
          <div className="emotes-annoyed-eyes" id="annoyed-left-eye">
            <div id="left-pupil" className="pupil"></div>
            <div id="left-eyelid" className="eyelid"></div>
          </div>
          <div className="emotes-annoyed-eyes" id="annoyed-right-eye">
            <div id="right-pupil" className="pupil"></div>
            <div id="right-eyelid" className="eyelid"></div>
            <div className="emotes-annoyed-mouth"></div>
          </div>{" "}
        </div>
      );
    case "sad":
      return (
        <div className="emotes-sad-face">
          <div className="emotes-sad-pupil"></div>
          <div id="sad-left-eye" className="emotes-sad-eye">
            <div id="sad-left-droop" className="emotes-sad-droop"></div>
            <div className="emotes-sad-pupil"></div>
          </div>
          <div id="sad-right-eye" className="emotes-sad-eye">
            <div id="sad-right-droop" className="emotes-sad-droop"></div>
            <div className="emotes-sad-pupil"></div>
          </div>
          <div className="emotes-sad-mouth"></div>{" "}
        </div>
      );
    case "zipper-face":
      return (
        <div className="emotes-zipper-face">
          <div className="emotes-zipper-face-eye emotes-zipper-face-eye-left"></div>
          <div className="emotes-zipper-face-eye emotes-zipper-face-eye-right"></div>
          <div className="zipper">
            <div className="zipper-track" style={{ "--delay": "0s", zIndex: "1" }}></div>
            <div className="zipper-track" style={{ "--delay": "0.2s", zIndex: "1" }}></div>
            <div className="zipper-track" style={{ "--delay": "0.4s", zIndex: "1" }}></div>
            <div className="zipper-track" style={{ "--delay": "0.6s", zIndex: "1" }}></div>
            <div className="zipper-track" style={{ "--delay": "0.8s", zIndex: "1" }}></div>
            <div className="zipper-line"></div>
            <div className="zipper-pull"></div>
            <div className="zipper-pull-hook"></div>
            <div className="zipper-pull-attacher"></div>{" "}
          </div>
        </div>
      );
    case "heart-eyes-face":
      return (
        <div className="emotes-heart-eyes-face">
          <div className="emotes-heart-eyes-expression">
            <div className="emotes-heart-eye emotes-heart-eye-left">❤</div>
            <div className="emotes-heart-eye emotes-heart-eye-right">❤</div>
            <div className="emotes-heart-eyes-mouth"></div>
          </div>
        </div>
      );
    case "wink-heart-kiss":
      return (
        <div className="emotes-wink-heart-kiss-face">
          <div className="emotes-wink-heart-kiss-expression">
            <div className="emotes-wink-eye emotes-wink-eye-left" />
            <div className="emotes-wink-eye emotes-wink-eye-right"></div>
            {/* leave the double dash for nomenclature conventions reason */}
            <div className="emotes--heart-mouth-kiss" />
            <div className="floating-heart">❤️</div>
          </div>
        </div>
      );
    case "yay":
      return (
        <div className="emotes-yay-face">
          <div className="emotes-yay-expression">
            <div className="emotes-yay-eye emotes-yay-eye-left"></div>
            <div className="emotes-yay-eye emotes-yay-eye-right"></div>
            <div className="emotes-yay-mouth"></div>
          </div>
        </div>
      );
  }
};
export default renderExpression;
