import "./lightBulbAnimatedEmoji.scss";

const LightBulbAnimatedEmoji = ({ rating }) => {
  const renderExpression = () => {
    switch (rating) {
      case 5:
        return (
          <div className="bulb-on-face">
            <div className="bulb-on-expression">
              {/* Corrected class names for eyes */}
              <div className="yay-eyes yay-eye-left"></div>
              <div className="yay-eyes yay-eye-right"></div>
              <div className="yay-eyebrows yay-eyebrow-right"></div>
              <div className="yay-eyebrows yay-eyebrow-left"></div>
              <div className="yay-mouth"></div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="smile-face">
            <div className="smile-expression">
              {/* Add elements for eyebrows, eyes, and mouth */}
              <div className="smile-eyebrows smile-eyebrow-right"></div>
              <div className="smile-eyebrows smile-eyebrow-left"></div>
              <div className="smile-eyes smile-eye-left"></div>
              <div className="smile-eyes smile-eye-right"></div>
              <div className="smile-mouth"></div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="grin-face">
            <div className="grin-expression">
              {/* Add elements for eyebrows, eyes, and mouth */}
              <div className="grin-eyebrows grin-eyebrow-right"></div>
              <div className="grin-eyebrows grin-eyebrow-left"></div>
              <div className="grin-eyes grin-eye-left"></div>
              <div className="grin-eyes grin-eye-right"></div>
              <div className="grin-mouth"></div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="sad-face">
            <div className="sad-expression">
              <div className="sad-eyebrows sad-eyebrow-right"></div>
              <div className="sad-eyebrows sad-eyebrow-left"></div>
              <div className="sad-eyes sad-eye-left">
                <div className="tear tear-left"></div> {/* Left tear */}
              </div>
              <div className="sad-eyes sad-eye-right">
                <div className="tear tear-right"></div> {/* Right tear */}
              </div>
              <div className="sad-mouth"></div>
            </div>
          </div>
        );

      case 1:
        return (
          <div className="sleeping-face">
            <div className="snores">
              <div className="snore snore1">Z</div>
              <div className="snore snore2">Z</div>
              <div className="snore snore3">Z</div>
            </div>
            <div className="sleeping-expression">
              <div className="sleeping-eyebrows sleeping-eyebrow-right"></div>
              <div className="sleeping-eyebrows sleeping-eyebrow-left"></div>
              <div className="sleeping-eyes sleeping-eye-left"></div>
              <div className="sleeping-eyes sleeping-eye-right"></div>
              <div className="sleeping-mouth"></div>
            </div>
          </div>
        );
      case 0:
      default:
        return (
          <div className="default-face">
            <div className="default-surprise-expression">
              <div className="default-eyebrows default-eyebrow-right"></div>
              <div className="default-eyebrows default-eyebrow-left"></div>
              <div className="default-eyes default-eye-left"></div>
              <div className="default-eyes default-eye-right"></div>
              <div className="default-mouth"></div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className={`lightBulb rank-${rating}`} key={`rating-${rating}`}>
      <div id={`rating-${rating}`}></div>
      {renderExpression()}
      <div className="bulb"></div>
    </div>
  );
};

export default LightBulbAnimatedEmoji;
