import React from "react";
import "./animatedEmotionEmotes.scss";
// drama category
const AnimatedEmotionEmotesBoyShortHair = ({ rating }) => {
  const renderExpression = () => {
    switch (rating) {
      case 5:
        return (
          <div className="emoji-happy-face">
            <div className="shortHair">
              <div className="shortSide-left"></div>
              <div className="shortSide-right"></div>
            </div>
            <div className="emoji-happy-expression">
              <div className="emoji-eye emoji-eye-left"></div>
              <div className="emoji-eye emoji-eye-right"></div>
              <div className="emoji-happy-mouth"></div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="emoji-smile-face">
            <div className="shortHair">
              <div className="shortSide-left"></div>
              <div className="shortSide-right"></div>
            </div>
            <div className="emoji-smile-expression">
              <div className="emoji-eye emoji-eye-left"></div>
              <div className="emoji-eye emoji-eye-right"></div>
              <div className="emoji-smile-mouth"></div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="emoji-sincere-face">
            <div className="shortHair">
              <div className="shortSide-left"></div>
              <div className="shortSide-right"></div>
            </div>
            <div className="emoji-sincere-expression">
              <div className="emoji-eye emoji-eye-left"></div>
              <div className="emoji-eye emoji-eye-right"></div>
              <div className="emoji-mouth"></div>
              {/* Other facial features if needed */}
            </div>
          </div>
        );
      case 2:
        return (
          <div className="emoji-angry-face">
            <div className="shortHair">
              <div className="shortSide-left"></div>
              <div className="shortSide-right"></div>
            </div>
            <div className="emoji-angry-expression">
              <div className="emoji-eye angry emoji-eye-left"></div>
              <div className="emoji-eye angry emoji-eye-right"></div>
              <div className="emoji-mad-mouth"></div>{" "}
              {/* Make sure this matches the class in your CSS */}
            </div>
          </div>
        );
      case 1:
        return (
          <div className="emoji-cry-face">
            <div className="shortHair">
              <div className="shortSide-left"></div>
              <div className="shortSide-right"></div>
            </div>
            <div className="emoji-cry-expression">
              <div className="emoji-cry-eye emoji-cry-eye-left">
                <div className="tear-stream"></div>{" "}
                {/* Water stream container */}
              </div>
              <div className="emoji-cry-eye emoji-cry-eye-right">
                {" "}
                <div className="tear-stream"></div>{" "}
                {/* Water stream container */}
              </div>
              <div className="emoji-sad-mouth"></div>
            </div>
          </div>
        );
      case 0:
      default:
        return (
          <div className="emoji-default-face">
            <div className="shortHair">
              <div className="shortSide-left"></div>
              <div className="shortSide-right"></div>
            </div>
            <div className="emoji-default-expression">
              <div className="emoji-eye emoji-eye-left"></div>
              <div className="emoji-eye emoji-eye-right"></div>
              <div className="glasses">
                <div className="lens emote-lens-left"></div>
                <div className="lens emote-lens-right"></div>
              </div>
              <div className="emoji-mouth"></div>
            </div>
          </div>
        );
      // or
      // return null; // Default to no face
    }
  };
  return (
    <div className={`emoji rank-${rating}`} key={`rating-${rating}`}>
      <div id={`rating-${rating}`}></div>
      {renderExpression()}
      {/* This element will receive the fade-in effect */}
    </div>
  );
};
export default AnimatedEmotionEmotesBoyShortHair;
