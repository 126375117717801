import React, { useState, useEffect, useRef } from "react";
import {
  ComedyIcon,
  DramaIcon,
  ActionIcon,
  MusicalIcon,
  RomanceIcon,
  FantasyIcon,
  SuspenseIcon,
  WesternIcon,
  SearchGlassIcon,
  VerticalTwoBarsIcon,
} from "../icons/index";
import BlinkingSidewaysRightTriangleIcon from "../blinkingLight/blinkingSidewaysRightTriangleIcon";
import { InsetBlendTVButton, SquareFlatBtns } from "../buttons";
import {
  getPresignedVideoUrl,
  apiRecordVideoDataDatabase,
} from "../../api/backend/user";
import { uploadFileToS3 } from "../../api/backend/uploadToS3";
import { getVideoFileType } from "../../constants/fileUpload";
import { checkVideoFile } from "../../utils/checkVideoFile";
import VideoMonitor from "../videoPlayer/videoMonitor";
import VideoPlayerControls from "../videoPlayer/videoPlayerControls";
import Loader from "../loader/loader";
import { formatDuration } from "../../utils/formatTime";
import "./uploadVideoForm.scss";

const UploadVideoForm = ({
  isUploadVideoFormOpen, // state prop passed in for the remote to allow users to upload a skit
  closeModal, // state used to pass back to the parent pages to close the modal such as after uploading skit successfully also used for the remote to allow users to cancel which will close the modal
  currentUser, // this will be used to define the source user id to the current user id
  afterModalClose, // this parameter will be used as a function below passed from the skits listing component and when it is invoked here the function will run in the skitst listing page or wherever else it is called back
}) => {
  console.log("At render, currentUser is", currentUser);
  const [titleError, setTitleError] = useState(false);
  const [isSkitPlaying, setIsSkitPlaying] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [fileTypeErrorMessage, setFileTypeErrorMessage] = useState("");
  const [categoryErr, setCategoryErr] = useState(false);
  const [durationError, setDurationError] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [fileUpload, setFileUpload] = useState();
  const [BackendErrorMessage, setBackendErrorMessage] = useState(""); // Add state for error message
  const [presignedVideoUrlResponse, setPresignedVideoUrlResponse] =
    useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState("");
  const [previewVideoModal, setPreviewVideoModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [videoMetadata, setVideoMetadata] = useState({
    duration: 0,
    width: 0,
    height: 0,
  });
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0);
  const fileInputRef = useRef(null); // Ref for the hidden file input
  const videoRef = useRef(null); // Create a ref for the video element
  const modalRef = useRef(null); // Ref for the modal container used so scrolling

  const triggerFileInputClick = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };

  useEffect(() => {
    console.log("UploadVideoForm: currentUser is", currentUser);
    // Use currentUser as sourceId
  }, [currentUser]);
  useEffect(() => {
    const handleScroll = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        e.stopPropagation(); // Stop scroll propagation if inside the modal
      }
    };

    document.addEventListener("scroll", handleScroll, true);

    return () => {
      document.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  // this function will handle file size and type allowed
  const handleUploadVideo = async (e) => {
    console.log("handleUploadVideo: event triggered");
    const file = e?.target?.files[0];
    if (!file) {
      console.log("No file selected");
      return;
    }
    // Clear previous error messages and reset state
    setBackendErrorMessage("");
    setFileTypeErrorMessage("");
    setFileError(false);
    setDurationError("");
    getVideoFileType(file); // Determines if the file is 'mp4', 'mov', etc.
    console.log("Selected file:", file);
    console.log("File MIME type:", file.type);
    console.log("File size:", file.size);
    // Reset state values for video metadata
    setVideoMetadata({
      duration: 0,
      width: 0,
      height: 0,
    });
    // Perform file validation
    try {
      checkVideoFile(file.name, file.type, file.size); // Update this function for video validation
      // Create a video element to load metadata
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.src = URL.createObjectURL(file); // Assuming file is your video file
      // Add event listener for 'loadedmetadata' event
      videoElement.addEventListener("loadedmetadata", () => {
        console.log("Loaded metadata for video, preparing preview...");
        // Extract metadata from video
        const duration = videoElement.duration;
        const width = videoElement.videoWidth;
        const height = videoElement.videoHeight;
        const durationInMinutes = (duration / 60).toFixed(2);
        // Update state with video metadata
        setVideoMetadata({ duration, width, height, durationInMinutes });
        // Perform duration check
        if (duration < 1 || duration > 1200) {
          console
            .error
            // "Video duration needs to be between 2 to 5 minutes long."
            ();
          setDurationError(
            "Video duration needs to be between 2 to 5 minutes long."
          );
          setFileError(true); // Update your state to reflect the error
          setFileTypeErrorMessage(
            "Video duration must be between 1 second and 20 minutes."
          );
          // Alert the user or handle the error as needed
        } else {
          console.log("About to prepare video preview and show modal.");
          // If all checks pass, set the file for upload
          setFileUpload(file);
          prepareVideoPreviewAndShowModal(file); // This should correctly prepare and trigger the modal
          setDurationError("");
          setFileError(false);
        }
      });
      // In case there's an error loading the metadata
      videoElement.addEventListener("error", (event) => {
        console.error("Error loading video metadata:", event);
        setFileError(true); // Update your state to reflect the error
      });
    } catch (error) {
      setFileTypeErrorMessage(error.message); // Pass the error message to display
      setFileError(true); // Update your state to reflect the error
    }
  };

  // Function to prepare and show the modal with the image preview
  const prepareVideoPreviewAndShowModal = (file) => {
    const previewVideoUrl = URL.createObjectURL(file);
    setVideoPreviewUrl(previewVideoUrl);
    console.log(`Video Preview URL set: ${previewVideoUrl}`);
    setPreviewVideoModal(true);
    console.log(`Preview Video Modal set to true`);
  };

  const handleModalClose = () => {
    // Check if there's a preview URL to revoke
    if (videoPreviewUrl) {
      URL.revokeObjectURL(videoPreviewUrl);
      setVideoPreviewUrl(null); // Clear the preview URL state
    }
    setPreviewVideoModal(false); // Close the modal
  };

  const handleSubmitUpload = async () => {
    // Reset error states on each attempt
    setTitleError(false);
    setFileError(false);
    setCategoryErr(false);
    setBackendErrorMessage(""); // Reset error message state
    let hasError = false;
    // Direct checks for errors
    if (!titleValue || titleValue.trim() === "") {
      console.error("Title validation failed");
      setTitleError(true);
      hasError = true;
    }
    if (!fileUpload) {
      console.error("File validation failed");
      setFileError("Please select a valid video file to upload.");
      setFileError(true);
      hasError = true;
    }
    if (!selectedCategory.id) {
      console.error("Category validation failed");
      setCategoryErr(true);
      hasError = true;
    }
    if (hasError) {
      console.log("Validation failed, aborting upload");
      return;
    }
    setLoading(true); // Set loading to true before starting the upload process
    // Construct formData for backend notification
    const formData = {
      title: titleValue,
      categoryid: selectedCategory.id,
      sourceId: currentUser,
      videoMetaData: videoMetadata,
      fileName: fileUpload.name,
    };
    console.log("Form data constructed:", formData);
    localStorage.setItem("formData", JSON.stringify(formData));
    // Checking if currentUser exists
    if (!currentUser) {
      console.error(
        "No current user. Please sign in or sign up to upload files."
      );
      setLoading(false); // Set loading to false if no current user
      // You can add logic here to prompt the user to sign in, if they're not already
      // This could include showing a message, redirecting to a sign-in page, etc.
      return; // Abort the upload process if no current user is found
    }
    try {
      console.log("Requesting pre-signed URL for:", {
        fileName: fileUpload.name,
        mimeType: fileUpload.type,
        fileSize: fileUpload.size,
        currentUser, // This is the sourceId for the Skit
      });
      // Collecting File Details: then make a call to your backend endpoint to request a pre-signed URL, passing along these file details.
      // see function for how it is done on backend by clicking on the getPresigedVideoUrl
      const presignedVideoUrlResponse = await getPresignedVideoUrl(
        fileUpload.name,
        fileUpload.type,
        fileUpload.size,
        currentUser // Pass the currentUser as the sourceId
      );
      if (presignedVideoUrlResponse.presignedVideoUrl) {
        // If the pre-signed URL is present, consider it a success
        console.log(
          "Pre-signed URL obtained, URL:",
          presignedVideoUrlResponse.presignedVideoUrl
        );
        setPresignedVideoUrlResponse(presignedVideoUrlResponse);
      } else {
        console.error("Request for pre-signed URL failed");
        setFileError("Failed to generate an upload URL. Please try again.");
        setLoading(false); // Set loading to false if the request fails
      }
    } catch (err) {
      console.error("Error during file upload process:", err);
      if (err.response && err.response.data && err.response.data.message) {
        setBackendErrorMessage(err.response.data.message); // Set error message from the backend
      } else {
        setBackendErrorMessage("An error occurred during the upload process."); // Fallback error message
      }
      setLoading(false);
    }
  };
  useEffect(() => {}, [currentUser]);
  useEffect(() => {
    if (videoRef.current) {
      const handleTimeUpdate = () => {
        setDuration(videoRef.current.duration || 0);
      };

      videoRef.current.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        }
      };
    }
  }, [videoRef]);

  // Using the Pre-signed URL for Upload: If the response contains the pre-signed URL and indicates
  // success, you proceed to use this URL to directly upload the file to the S3 bucket:
  // The actual upload is handled in a separate step, where you make a PUT request to the
  // pre-signed URL with the file content as the request body.
  useEffect(() => {
    console.log("useEffect triggered", {
      presignedVideoUrlResponse,
      fileUpload,
    });
    if (presignedVideoUrlResponse?.presignedVideoUrl && fileUpload) {
      // this response will invoke the following below
      console.log(
        "Attempting upload with URL:",
        presignedVideoUrlResponse.presignedVideoUrl
      );
      console.log("Uploading file details:", {
        URL: presignedVideoUrlResponse.presignedVideoUrl,
        "File MIME type": fileUpload.type,
        File: fileUpload,
      });
      // using the file object directly
      uploadFileToS3(
        presignedVideoUrlResponse.presignedVideoUrl,
        fileUpload, // this is the actual file object that needs to be uploaded
        handleUploadSuccess, // Direct reference, not invoking here instead of a successs response message commented out below
        // () => console.log("Upload Success")
        (error) => console.error("Upload Error:", error)
      );
    }
  }, [presignedVideoUrlResponse, fileUpload]);

  const handleUploadSuccess = async () => {
    // Retrieve skit details from local storage
    const formDataString = localStorage.getItem("formData");
    if (formDataString) {
      let skitData = JSON.parse(formDataString);
      // Optionally include the presignedVideoUrl in your skitData
      const cloudFrontDomain = "media.skitsolympic.com"; // Your CloudFront domain
      skitData.mediaUrl = `https://${cloudFrontDomain}/videos/${skitData.sourceId}/${encodeURIComponent(skitData.fileName)}`;
      // Optionally remove unnecessary fields from skitData if they exist
      delete skitData.videoMetaData; // Assuming you want to remove this before sending
      // Log the constructed CloudFront URL for verification
      console.log("Constructed CloudFront URL:", skitData.mediaUrl);
      // Invoke the API function to notify the backend of upload completion
      try {
        console.log(
          "Invoking recordDataInDatabase with skit data from local storage"
        );
        const response = await apiRecordVideoDataDatabase(skitData);
        if (response.status === 200 || response.data.success) {
          console.log("Skit data recorded successfully:", response.data);
          // Only clear local storage upon successful backend response
          // Clear the form data from local storage and state upon successful upload
          localStorage.removeItem("formData");
          setTitleValue("");
          setSelectedCategory({ id: "", name: "" }); // Reset to initial category state
          setVideoMetadata({ width: 0, height: 0, size: 0 }); // Reset image metadata
          setFileUpload(null); // Clear the uploaded file
          closeModal();
          afterModalClose();
          handleModalClose();
        } else {
          // Handle case where backend response is not successful
          console.error("Failed to record skit data:", response.data);
          // Optionally retry or inform the user of the failure without clearing the local storage
        }
      } catch (error) {
        console.error("Error recording skit data:", error);
        // Handle error, maybe retry or inform the user, without clearing the local storage
      }
    } else {
      console.error("No skit data found in local storage.");
      // Handle the case where local storage does not contain the expected data
    }
  };

  const handleTitleChange = (event) => {
    setTitleValue(event.target.value);
    if (event.target.value.trim() !== "") {
      setTitleError(false);
    }
  };

  const categories = [
    { id: 500, name: "Comedy", Icon: ComedyIcon },
    { id: 200, name: "Drama", Icon: DramaIcon },
    { id: 100, name: "Action", Icon: ActionIcon },
    { id: 700, name: "Musical", Icon: MusicalIcon },
    { id: 600, name: "Romance", Icon: RomanceIcon },
    { id: 50, name: "Fantasy", Icon: FantasyIcon },
    { id: 10, name: "Suspense", Icon: SuspenseIcon },
    { id: 330, name: "Western", Icon: WesternIcon },
  ];
  const handleCategorySelect = (categoryName, categoryId) => {
    setSelectedCategory({ name: categoryName, id: categoryId });
    setCategoryErr(false);
  };

  const handleDragUpdate = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time; // Sync video position with drag
    }
  };

  useEffect(() => {
    console.log("Current selected category state:", selectedCategory);
  }, [selectedCategory]);

  const uploadSkitActionBtns = () => {
    if (fileUpload) {
      // switch the buttons on the frontend if we collect a file
      return (
        <div className="button-container">
          <SquareFlatBtns
            name="changeSkitButton"
            onClick={triggerFileInputClick} // Click the hidden file input to change the skit
            Icon={SearchGlassIcon} // Use the same style button
            iconWidth="20px"
            iconHeight="20px"
            title="CHANGE"
            SqaureBtnbackgroundColor="#1c1c1c" // Button background color
            extraStyles={{
              marginLeft: "20px", // Adjust margin as needed to position the button
              padding: "10px",
            }} // Any additional styles you want to apply
            titleStyle={{
              color: "var(--color-bloodred)",
              fontFamily: "Roboto Slab",
              fontWeight: "800",
              fontSize: ".75rem",
            }} // Styling for the button text
            border="0.2px solid #c79f27" // Button border properties
            borderRadius="4px" // Making the button square with rounded corners
            boxShadow="0 0 10px 0px rgba(0, 0, 0, 0.5)" // Box shadow for the button
            transition="background-color 1s cubic-bezier(0.075, 0.82, 0.165, 1)" // Transition effect for the button
          />
          {loading && <Loader />}
          <SquareFlatBtns
            name="competeButton"
            onClick={handleSubmitUpload} // Change the handler to submit the form
            Icon={BlinkingSidewaysRightTriangleIcon} // Pass the component, not an instance
            iconWidth="20px"
            iconHeight="20px"
            title="BROADCAST"
            SqaureBtnbackgroundColor="#1c1c1c" // Button background color
            extraStyles={{
              marginLeft: "100px",
              padding: "10px",
            }} // Any additional styles you want to apply
            titleStyle={{
              color: "var(--color-bloodred)",
              fontFamily: "Roboto Slab",
              fontWeight: "800",
              fontSize: ".75rem",
            }} // Styling for the button text
            border="0.2px solid #c79f27" // Button border properties
            borderRadius="4px" // Making the button square with rounded corners
            boxShadow="0 0 10px 0px rgba(0, 0, 0, 0.5)" // Box shadow for the button
            transition="background-color 1s cubic-bezier(0.075, 0.82, 0.165, 1)" // Transition effect for the button
          />
        </div>
      );
    } else {
      // If no video has been selected, show "CHOOSE VIDEO"
      return (
        <div>
          {fileError && (
            <div className="campiagn-file-error-message">
              {fileTypeErrorMessage}
            </div>
          )}
          <InsetBlendTVButton
            image={<SearchGlassIcon />}
            btnText="CHOOSE SKIT"
            textStyles={{
              color: "var(--color-bloodred)",
              fontFamily: "Roboto Slab",
              fontWeight: "800",
              fontSize: "1.3rem",
            }}
            onClick={triggerFileInputClick} // Keep the original handler to trigger file selection
          />
        </div>
      );
    }
  };
  const skitCategoryButtons = (categoriesToRender) => {
    return (
      <>
        {categoriesToRender.map((category) => (
          <SquareFlatBtns
            key={category.id}
            name={category.name}
            Icon={category.Icon}
            title={category.name}
            className="remote-btn2"
            buttonWidth="50px"
            iconWidth="30px"
            iconHeight="30px"
            onClick={() => handleCategorySelect(category.name, category.id)}
            titleStyle={{
              fontSize: ".6rem",
              fontWeight: "800",
              color:
                selectedCategory.id === category.id
                  ? "var(--color-ivory)"
                  : "var(--color-gold)",
            }}
          />
        ))}
      </>
    );
  };

  return (
    <div
      className={`upload-video-modal ${isUploadVideoFormOpen ? "is-open" : "is-closed"}`}
    >
      <div
        className="upload-video-modal-content"
        onScroll={(e) => e.stopPropagation()} // Prevents scroll event propagation inside the modal
        ref={modalRef}
      >
        <SquareFlatBtns
          name="cancelButton"
          onClick={closeModal}
          Icon={VerticalTwoBarsIcon}
          iconWidth="10px"
          title="CANCEL"
          extraStyles={{
            paddingTop: "1px",
          }}
          titleStyle={{
            color: "var(--color-bloodred)",
            fontFamily: "Roboto Slab",
            fontWeight: "800",
            fontSize: ".75rem",
          }}
          border="0.2px solid #c79f27"
          borderRadius="4px"
          boxShadow="0 0 10px 0px rgba(0, 0, 0, 0.5)"
          transition="background-color 1s cubic-bezier(0.075, 0.82, 0.165, 1)"
        />
        {BackendErrorMessage && (
          <div className="backend-uploadSkit-error-message">
            {BackendErrorMessage}
          </div>
        )}
        <div className={`form-group ${titleError ? "error-border" : ""}`}>
          <h3>Skit Title:</h3>
          <div className="input-container blinker-wrapper">
            <input
              id="videoTitle"
              type="text"
              value={titleValue}
              onChange={handleTitleChange}
              placeholder={
                isFocused || titleValue
                  ? ""
                  : titleError
                    ? "Required"
                    : "Type video title here"
              }
              className={titleError ? "red-placeholder" : ""}
            />
            {!isFocused && titleValue === "" && (
              <span className="blinking-cursor-gold">|</span>
            )}
          </div>
        </div>
        <div className={`form-group ${fileError ? "error-border" : ""}`}>
          <input
            type="file"
            name="my-video"
            accept="video/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleUploadVideo}
          />
          <div className="categories"></div>
          <h4>Chose a Category:</h4>
          <div
            className={`categories-container ${categoryErr ? "error-border" : ""}`}
          >
            <div className="category-row">
              {skitCategoryButtons(categories.slice(0, 4))}
            </div>
            {categoryErr && <div className="required-message">Required</div>}
            <div className="category-row">
              {skitCategoryButtons(categories.slice(4))}
            </div>
          </div>
          {uploadSkitActionBtns()}
          <div>
            {videoMetadata.duration > 0 && (
              <div className="video-details-container">
                <h3>Video Details:</h3>
                <p>Duration: {formatDuration(videoMetadata.duration)}</p>
                {/* <p>Duration: {videoMetadata.duration.toFixed(2)} seconds</p> */}
                {/* <p>
                  Resolution: {videoMetadata.width} x {videoMetadata.height}
                </p> */}
                {durationError && (
                  <div className="error-message">{durationError}</div>
                )}
              </div>
            )}
          </div>
          {previewVideoModal && (
            <div className="video-preview-container">
              <VideoMonitor
                videoRef={videoRef}
                src={videoPreviewUrl}
                title="Video Preview"
                autoPlay={false}
                onLoadedMetadata={() => {
                  if (videoRef.current) {
                    setDuration(videoRef.current.duration || 0); // Update duration when metadata loads
                  }
                }}
              />
              <div id="controlsAndActionsRow">
                <VideoPlayerControls
                  videoRef={videoRef}
                  duration={duration}
                  volume={volume}
                  setVolume={setVolume}
                  onDragUpdate={handleDragUpdate} // Handle drag updates
                  isSkitPlaying={isSkitPlaying} // Pass down the state
                  setIsSkitPlaying={setIsSkitPlaying} // Allow child to update the state
                  showVolumeControl={false} // Hide volume control here
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default UploadVideoForm;

// Understanding the user's identity and linking it correctly to
// actions within your application is crucial for many features,
// such as tracking who uploaded a video. In a production environment,
// you'd typically handle user authentication and identification through
// a secure method, such as OAuth, OpenID Connect, or JWT (JSON Web Tokens),
// which involves the following steps:

// User Authentication: When a user logs in, authenticate their credentials
// against a user store (e.g., database, LDAP). Upon successful authentication,
// generate a secure token (e.g., JWT) that includes the user's identity (userId)
// and possibly other claims.

// Token Issuance: Return this token to the client (e.g., browser, mobile app).
// The client must include this token in the Authorization header of subsequent
// requests to protected endpoints.

// Middleware for Token Verification: Implement middleware on your server
// that intercepts requests to protected routes, extracts the token from
// the Authorization header, and verifies its validity (e.g., signature, expiration).
// If the token is valid, extract the userId from it.

// Bind userId to sourceId: Once you have the userId from the verified token,
// you can treat it as the sourceId in the context of your application.
// This sourceId can be included in the request to your video upload function
// or any other function that needs to associate actions with a specific user.

// Secure Storage of Tokens: Ensure secure storage of tokens on the client side
// (e.g., using HTTPS only cookies, secure storage on mobile).

// Session Management: Implement secure session management, including token
// refresh mechanisms and session invalidation upon logout or token expiration.

// By following these steps, you can securely identify users in your application
// and accurately associate their actions, such as video uploads, with their
// user accounts. This approach not only enhances security but also enables
// personalized user experiences and accurate tracking of user activities.
