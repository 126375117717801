import React from "react";
import { SquareFlatBtns } from "../buttons";
import "./videoPlayer.scss";
import {
  TwoRightArrowsIcon,
  TwoLeftArrowsIcon,
  VerticalTwoBarsIcon,
  SidewaysRightTriangleIcon, // Play icon
} from "../icons";

const VideoPlayerBtns = ({
  isSkitPlaying,
  onPlayPause,
  onRewind,
  onForward,
  videoRef,
}) => {
  return (
    <div className="video-player-controls-btns">
      {/* Show rewind button only if there is more than 15 seconds played */}
      <SquareFlatBtns
        name="rewind"
        onClick={() => onRewind(-15)} // Assuming onRewind expects the number of seconds to rewind
        Icon={TwoLeftArrowsIcon}
        iconProps={{
          fillColor: "var(--color-gold)",
          width: "20px",
          height: "20px",
        }}
        title="15 sec"
        titleStyle={{
          fontSize: ".9rem",
          margin: "0",
          textAlign: "center",
          fontWeight: "800",
          color: "var(--color-gold)",
        }}
        extraStyles={{
          width: "50px",
          height: "50px",
          padding: "1px",
        }}
      />

      {/* Play/Pause button */}
      <SquareFlatBtns
        name="play-pause"
        onClick={() => {
          if (!isSkitPlaying || videoRef.current.paused) {
            console.log("Attempting to play...");
          } else {
            console.log("Attempting to pause...");
          }
          onPlayPause();
        }} // Simply calls togglePlayPause
        Icon={isSkitPlaying ? VerticalTwoBarsIcon : SidewaysRightTriangleIcon}
        iconProps={{
          fillColor: "var(--color-bloodred)",
          width: "20px",
          height: "20px",
        }}
        title={isSkitPlaying ? "Pause" : "Play"}
        titleStyle={{
          fontSize: ".9rem",
          margin: "0",
          textAlign: "center",
          fontWeight: "800",
          color: "var(--color-gold)",
        }}
        extraStyles={{
          width: "50px",
          height: "50px",
          padding: "1px",
        }}
      />
      {/* Show forward button only if there is at least 15 seconds left */}
      <SquareFlatBtns
        name="forward"
        onClick={() => onForward(15)} // Assuming onForward expects the number of seconds to forward
        Icon={TwoRightArrowsIcon}
        iconProps={{
          fillColor: "var(--color-gold)",
          width: "20px",
          height: "20px",
        }}
        title="15 sec"
        titleStyle={{
          fontSize: ".9rem",
          margin: "0",
          textAlign: "center",
          fontWeight: "800",
          color: "var(--color-gold)",
        }}
        extraStyles={{
          width: "50px",
          height: "50px",
          padding: "1px",
        }}
      />
    </div>
  );
};
export default VideoPlayerBtns;
// ok it is time...i need to give this to you and we need to do a code read so i can give you the next task you have to understand the components, functions, parameters, and where state is set-->
// response
// Purpose: Provides video control buttons such as play/pause, rewind, and fast forward.
// Props:
// isSkitPlaying: Indicates whether the video is currently playing.
// onPlayPause: Toggles play/pause state.
// onRewind, onForward: Functions to handle rewind and fast forward actions.
// currentTime, duration: Current time of the video and its total duration.
// Details and Data Flow:
// VideoPlayerBtns displays buttons that control the video's playback, allowing the user to play/pause, rewind, and fast forward.
