// utils/wordUtils.js

/**
 * Return the singular or plural form of a word based on the count.
 *
 * @param {number} count - The count to check.
 * @param {string} singular - The singular form of the word.
 * @param {string} [plural] - The plural form of the word. (optional, defaults to appending 's' to the singular form)
 * @returns {string} - The appropriate singular or plural form.
 */
export const SingularPlural = (count, singular, plural = singular + "s") => {
  return count === 1 ? singular : plural;
};
