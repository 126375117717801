import { errorMessages } from "../../constants/errorMessages";

export const handleAcctValidationErrors = (
  acctPhoneNumberValue,
  setAcctPhoneNumberErrors,
  currentContext
) => {
  let errors = {};

  // Check if the phone number is empty
  if (currentContext === "resetPassword" && acctPhoneNumberValue.length === 0) {
    errors.acctPhoneNumberErrors = errorMessages.phone;
  } 
  // Check if the phone number is not exactly 10 digits
  else if (currentContext === "resetPassword" && acctPhoneNumberValue.length !== 10) {
    errors.acctPhoneNumberErrors = "Please enter a valid 10-digit phone number.";
  }

  setAcctPhoneNumberErrors(errors.acctPhoneNumberErrors || "");
  return { errors }; // Ensure that an object is returned in all cases
};
