// components/emoji/AllEmoteSelection.js
import React, { useState, useEffect } from "react";
import { AllEmoteOptions } from "../../constants/emoteOptions";
import renderExpression from "./fullEmoteChoices";
import "./emotes.scss";

const AllEmoteSelection = ({ onSelectEmote, showAllEmotes }) => {
  const [showAll, setShowAll] = useState(showAllEmotes);

  const chooseEmote = (emoteId) => {
    console.log(`Emote clicked: ${emoteId}`);
    const selectedEmote = AllEmoteOptions.find((option) => option.id === emoteId);
    if (onSelectEmote) {
      onSelectEmote(selectedEmote.name);
      localStorage.setItem("selectedEmote", JSON.stringify(selectedEmote));
      setShowAll(false);
    }
  };

  useEffect(() => {
    setShowAll(showAllEmotes);
  }, [showAllEmotes]);

  const renderAllEmotes = () => {
    return (
      <div className="emotes-container">
        {AllEmoteOptions.map((option) => (
          <div
            key={option.id}
            className="emotes-item"
            onClick={() => chooseEmote(option.id)}
          >
            {renderExpression(option.emoteChoice)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="emotes-selection-container thumbnail-content">
      {showAll ? renderAllEmotes() : null}
    </div>
  );
};

export default AllEmoteSelection;
