import React from "react";
import "./animatedTrophy.scss";

const AnimatedTrophy = () => {
  return (
    <svg
      className="animated-trophy-svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="none"
      x="0px"
      y="0px"
      width="31.25px" /* Reduced from 62.5px */
      height="50px" /* Reduced from 100px */
      viewBox="0 0 500 800" /* Keep the same viewBox to maintain proportions */
    >
      <defs>
        <radialGradient
          id="Gradient_1"
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          r="211.4"
          fx="0"
          fy="0"
          gradientTransform="matrix( 1, 0, 0, 1, 0,0) "
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#9293D6" />
          <stop offset="100%" stopColor="#9293D6" stopOpacity="0" />
        </radialGradient>

        <radialGradient
          id="Gradient_2"
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          r="134.5"
          fx="0"
          fy="0"
          gradientTransform="matrix( 1.0000000000000002, 0, 0, 1.0000000000000002, 37.7,-44.75) "
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#C9EBFA" />
          <stop offset="100%" stopColor="#57ABDE" />
        </radialGradient>

        <radialGradient
          id="Gradient_3"
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          r="66.10000000000002"
          fx="0"
          fy="0"
          gradientTransform="matrix( 0.9999999999999998, 0, 0, 0.9999999999999998, 23.6,-99.85) "
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#C9E5FA" />
          <stop offset="100%" stopColor="#57A0DE" />
          <stop offset="100%" stopColor="#5CBDE0" />
        </radialGradient>

        <radialGradient
          id="Gradient_4"
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          r="59.9"
          fx="0"
          fy="0"
          gradientTransform="matrix( 1, 0, 0, 1, 17.85,-147.3) "
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#BBD5E8" />
          <stop offset="100%" stopColor="#529FCE" />
        </radialGradient>

        <radialGradient
          id="Gradient_5"
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          r="107.75"
          fx="0"
          fy="0"
          gradientTransform="matrix( 1, 0, 0, 1, -21.9,21.95) "
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#A8D4F1" />
          <stop offset="100%" stopColor="#57A0DE" />
        </radialGradient>

        <radialGradient
          id="Gradient_6"
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          r="242.49999999999997"
          fx="0"
          fy="0"
          gradientTransform="matrix( 1.0000000000000002, 0, 0, 1.0000000000000002, 36.4,-58.55) "
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#C9E9FA" />
          <stop offset="100%" stopColor="#57A0DE" />
        </radialGradient>

        <linearGradient
          id="Gradient_7"
          gradientUnits="userSpaceOnUse"
          x1="-131.25"
          y1="-258.1"
          x2="131.25"
          y2="-258.1"
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#5599FF" />
          <stop offset="100%" stopColor="#D5E6FF" />
        </linearGradient>

        <radialGradient
          id="Gradient_8"
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          r="137.70000000000005"
          fx="0"
          fy="0"
          gradientTransform="matrix( 0.9999999999999997, 0, 0, 0.9999999999999997, 76.45,-43.95) "
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#A8D4F1" />
          <stop offset="100%" stopColor="#57A0DE" />
        </radialGradient>

        <radialGradient
          id="Gradient_9"
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          r="77.75"
          fx="0"
          fy="0"
          gradientTransform="matrix( 1, 0, 0, 1, 26.6,-22.8) "
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#FFF774" />
          <stop offset="100%" stopColor="#FFCC00" />
        </radialGradient>

        <radialGradient
          id="Gradient_10"
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          r="51.1"
          fx="0"
          fy="0"
          gradientTransform="matrix( 1, 0, 0, 1, -19.85,10.05) "
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#FFF774" />
          <stop offset="100%" stopColor="#FFCC00" />
        </radialGradient>

        <radialGradient
          id="Gradient_11"
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          r="10.5"
          fx="0"
          fy="0"
          gradientTransform="matrix( 1, 0, 0, 1, 0.15,-1.95) "
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#FFF774" />
          <stop offset="100%" stopColor="#FFCC00" />
        </radialGradient>

        <g id="Symbol_1_0_Layer0_0_FILL">
          <path
            fill="url(#Gradient_1)"
            stroke="none"
            d="M -0.05 -209.05 Q -86.6 -209.05 -147.85 -147.8 -209.05 -86.55 -209.05 0 -209.05 86.6 -147.85 147.8 -86.6 209.05 -0.05 209.05 86.55 209.05 147.8 147.8 209.05 86.6 209.05 0 209.05 -86.55 147.8 -147.8 86.55 -209.05 -0.05 -209.05 Z"
          />
        </g>

        <g id="confetti_0_Layer0_0_FILL">
          <path
            fill="#F0A8F9"
            stroke="none"
            d="M -0.45 -11.9 Q -0.45 -12.35 -0.4 -12.8 L -12.35 -12.8 Q -12.4 -12.4 -12.4 -11.95 -12.4 -1.7 -5.15 5.55 2.15 12.85 12.4 12.85 L 12.45 12.85 12.45 0.95 Q 7.1 0.95 3.3 -2.8 -0.45 -6.6 -0.45 -11.9 Z"
          />
        </g>

        <g id="star_0_Layer0_0_FILL">
          <path
            fill="#C30DDB"
            stroke="none"
            d="M -6.65 -13.25 L -22.3 -14.5 -16.3 0.05 -22.3 14.55 -6.65 13.3 5.3 23.5 9 8.25 22.35 0.05 9 -8.2 5.3 -23.45 -6.65 -13.25 Z"
          />
        </g>

        <g id="base_0_Layer0_0_FILL">
          <path
            fill="url(#Gradient_2)"
            stroke="none"
            d="M 94.8 -17.5 Q 46.3 -42.45 40.4 -70.4 L -40.9 -70.4 Q -55.9 -29.8 -94 -17.45 L 94.8 -17.5 Z"
          />
          <path
            fill="url(#Gradient_3)"
            stroke="none"
            d="M 40.8 -87.9 Q 34.6 -101.15 40.4 -114.4 L -40.9 -114.4 Q -35.65 -102.7 -40 -87.85 L 40.8 -87.9 Z"
          />
          <path
            fill="#5599FF"
            stroke="none"
            d="M 100.75 -2.6 Q 103.35 -5.15 103.35 -8.75 103.35 -12.35 100.75 -14.95 98.45 -17.25 95.3 -17.45 95.05 -17.5 94.8 -17.5 L -94 -17.45 Q -94.3 -17.5 -94.6 -17.5 -98.2 -17.5 -100.8 -14.95 -103.35 -12.35 -103.35 -8.75 -103.35 -5.15 -100.8 -2.6 -98.4 -0.2 -95.2 0 -95.05 0 -94.9 0 L 94.4 0 Q 94.5 0 94.6 0 98.2 0 100.75 -2.6 M 40.6 -70.4 Q 44.2 -70.4 46.75 -73 49.35 -75.55 49.35 -79.15 49.35 -82.75 46.75 -85.35 44.45 -87.65 41.3 -87.85 41.05 -87.9 40.8 -87.9 L -40 -87.85 Q -40.3 -87.9 -40.6 -87.9 -44.2 -87.9 -46.8 -85.35 -49.35 -82.75 -49.35 -79.15 -49.35 -75.55 -46.8 -73 -44.4 -70.6 -41.2 -70.4 -41.05 -70.4 -40.9 -70.4 L 40.4 -70.4 Q 40.5 -70.4 40.6 -70.4 M 40.6 -114.4 Q 44.2 -114.4 46.75 -117 49.35 -119.55 49.35 -123.15 49.35 -126.75 46.75 -129.35 44.45 -131.65 41.3 -131.85 41.05 -131.9 29.55 -131.9 L -30.35 -131.9 Q -40.3 -131.9 -40.6 -131.9 -44.2 -131.9 -46.8 -129.35 -49.35 -126.75 -49.35 -123.15 -49.35 -119.55 -46.8 -117 -44.4 -114.6 -41.2 -114.4 -41.05 -114.4 -40.9 -114.4 L 40.4 -114.4 Q 40.5 -114.4 40.6 -114.4 Z"
          />
          <path
            fill="url(#Gradient_4)"
            stroke="none"
            d="M 24.8 -171.4 Q 12.95 -168.85 0 -168.85 -12.5 -168.85 -24 -171.2 -21.4 -150 -30.35 -131.9 L 29.55 -131.9 Q 23.3 -146.9 24.8 -171.4 Z"
          />
        </g>

        <g id="handleL_0_Layer0_0_FILL">
          <path
            fill="url(#Gradient_5)"
            stroke="none"
            d="M -24.6 -65.55 L -24.55 -65.55 Q -17.3 -63.05 -9.65 -59.3 -7.8 -58.4 -5.85 -59.05 -3.9 -59.75 -3 -61.6 -2.05 -63.45 -2.75 -65.4 -3.4 -67.4 -5.25 -68.3 -13.5 -72.35 -21.25 -75 -62.5 -89.6 -74.1 -64.25 -81.45 -49.35 -75.2 -34.25 L -75.2 -34.3 Q -70.9 -23.7 -56.95 -8.9 -56.85 -8.85 -56.85 -8.8 -40.7 7.2 -32.8 16.05 -19.35 30.95 -13.65 44.25 -6.15 61.85 -16.5 67.8 -22.75 70.9 -30.6 70.25 -32.65 70.05 -34.25 71.4 -35.8 72.7 -36 74.8 -36.15 76.85 -34.85 78.45 -33.5 80 -31.45 80.2 -20.65 81.1 -12 76.75 L -12.05 76.75 Q 7.3 67.9 -4.45 40.35 -10.65 25.75 -25.35 9.35 -33.4 0.4 -49.8 -15.9 L -49.7 -15.75 Q -62 -28.8 -65.95 -38.1 -70.45 -49.05 -65.15 -59.8 -55.9 -76.65 -24.6 -65.55 Z"
          />
        </g>

        <g id="ribbonBack_0_Layer0_0_FILL">
          <path
            fill="#5599FF"
            stroke="none"
            d="M -40.05 7.15 L -40.05 7.2 -33.15 7.2 -48.1 -6.4 -40.05 7.15 Z"
          />
          <path
            fill="#0066FF"
            stroke="none"
            d="M 24.95 7.15 L 48.15 7.15 32.8 -7.15 24.95 7.15 Z"
          />
        </g>

        <g id="cup_0_Layer0_0_FILL">
          <path
            fill="url(#Gradient_6)"
            stroke="none"
            d="M 40.8 -7.3 Q 61.9 -15.5 79.2 -32.8 109.45 -63.05 111.8 -104.9 L 111.8 -249.35 -111.95 -249.35 -111.95 -115.6 Q -111.95 -115.4 -111.95 -115.2 -112 -113.6 -112 -112 -112 -65.6 -79.2 -32.8 -61.55 -15.15 -40 -7 -32.25 -4.1 -24 -2.35 -12.5 0 0 0 12.95 0 24.8 -2.55 33.05 -4.3 40.8 -7.3 Z"
          />
          <path
            fill="url(#Gradient_7)"
            stroke="none"
            d="M 122.3 -249.35 Q 122.4 -249.35 122.5 -249.35 126.1 -249.35 128.65 -251.95 131.25 -254.5 131.25 -258.1 131.25 -261.7 128.65 -264.3 126.35 -266.6 123.2 -266.8 122.95 -266.85 122.7 -266.85 L -121.9 -266.8 Q -122.2 -266.85 -122.5 -266.85 -126.1 -266.85 -128.7 -264.3 -131.25 -261.7 -131.25 -258.1 -131.25 -254.5 -128.7 -251.95 -126.3 -249.55 -123.1 -249.35 -122.95 -249.35 -122.8 -249.35 L -111.95 -249.35 111.8 -249.35 122.3 -249.35 Z"
          />
        </g>

        <g id="handleR_0_Layer0_0_FILL">
          <path
            fill="url(#Gradient_8)"
            stroke="none"
            d="M 24.55 -65.55 L 24.6 -65.55 Q 55.9 -76.65 65.15 -59.8 70.45 -49.05 65.95 -38.1 62 -28.8 49.7 -15.75 L 49.8 -15.9 Q 33.4 0.4 25.35 9.35 10.65 25.75 4.45 40.35 -7.3 67.9 12.05 76.75 L 12 76.75 Q 20.65 81.1 31.45 80.2 33.5 80 34.85 78.45 36.15 76.85 36 74.8 35.8 72.7 34.25 71.4 32.65 70.05 30.6 70.25 22.75 70.9 16.5 67.8 6.15 61.85 13.65 44.25 19.35 30.95 32.8 16.05 40.7 7.2 56.85 -8.8 56.85 -8.85 56.95 -8.9 70.9 -23.7 75.2 -34.3 L 75.2 -34.25 Q 81.45 -49.35 74.1 -64.25 62.5 -89.6 21.25 -75 13.5 -72.35 5.25 -68.3 3.4 -67.4 2.75 -65.4 2.05 -63.45 3 -61.6 3.9 -59.75 5.85 -59.05 7.8 -58.4 9.65 -59.3 17.3 -63.05 24.55 -65.55 Z"
          />
        </g>

        <g id="coin_0_Layer1_0_FILL">
          <path
            fill="#FFCD03"
            stroke="none"
            d="M -25.85 -52.85 L -33.25 -65.8 Q -38.95 -65.55 -38.85 -59.7 L -38.85 -17.25 Q -35.75 -24.15 -30 -29.95 -29.65 -30.3 -29.3 -30.6 -28.6 -31.3 -27.9 -31.9 -26.9 -32.8 -25.85 -33.6 L -25.85 -52.85 M 18 -65.85 L 24.95 -52.85 24.95 -34.3 Q 27.55 -32.35 29.95 -29.95 34.95 -24.95 37.95 -19.1 L 37.95 -61.5 Q 37.7 -65.6 33.6 -65.85 L 30.15 -65.85 18 -65.85 Z"
          />
          <path
            fill="#0066FF"
            stroke="none"
            d="M 24.95 -52.85 L 18 -65.85 -3.25 -105.55 -12.35 -122.5 -12.35 -122.55 -20.4 -136.1 -72.7 -136.1 -65.6 -122.5 -33.25 -65.8 -25.85 -52.85 24.95 -52.85 Z"
          />
          <path
            fill="#5599FF"
            stroke="none"
            d="M 52.65 -122.55 L 60.5 -136.85 10.55 -136.85 -3.25 -105.55 18 -65.85 30.15 -65.85 52.65 -122.55 Z"
          />
        </g>

        <g id="coin_0_Layer0_0_FILL">
          <path
            fill="url(#Gradient_9)"
            stroke="none"
            d="M 37.95 -19.15 Q 34.95 -25 29.95 -30 27.55 -32.4 24.95 -34.35 15.7 -41.2 4 -42.2 2.05 -42.4 0 -42.4 -12.3 -42.4 -22.1 -36.25 -24 -35.1 -25.85 -33.65 -26.9 -32.85 -27.9 -31.95 -28.6 -31.35 -29.3 -30.65 -29.65 -30.35 -30 -30 -35.75 -24.2 -38.85 -17.3 -42.4 -9.4 -42.4 0 -42.4 17.55 -30 29.95 -17.55 42.4 0 42.4 2.05 42.4 4 42.25 19 40.95 29.95 29.95 42.4 17.55 42.4 0 42.4 -10.5 37.95 -19.15 M 2.7 -28.35 Q 12.8 -27.5 20.15 -20.15 28.55 -11.8 28.55 0 28.55 11.8 20.15 20.15 12.8 27.55 2.7 28.45 1.4 28.55 0 28.55 -11.8 28.55 -20.15 20.15 -21.2 19.1 -22.1 18.05 -26.55 12.7 -27.9 6.1 -28.5 3.15 -28.5 0 -28.5 -3.15 -27.9 -6.05 -26.55 -12.7 -22.1 -18 -21.2 -19.1 -20.15 -20.15 -11.8 -28.5 0 -28.5 1.4 -28.5 2.7 -28.35 Z"
          />
          <path
            fill="url(#Gradient_10)"
            stroke="none"
            d="M 20.15 -20.15 Q 12.8 -27.5 2.7 -28.35 1.4 -28.5 0 -28.5 -11.8 -28.5 -20.15 -20.15 -21.2 -19.1 -22.1 -18 -26.55 -12.7 -27.9 -6.05 -28.5 -3.15 -28.5 0 -28.5 3.15 -27.9 6.1 -26.55 12.7 -22.1 18.05 -21.2 19.1 -20.15 20.15 -11.8 28.55 0 28.55 1.4 28.55 2.7 28.45 12.8 27.55 20.15 20.15 28.55 11.8 28.55 0 28.55 -11.8 20.15 -20.15 M -3.4 -18.05 L -3.05 -19.05 3.25 -19.05 15.25 14.35 13.9 14.35 9.45 14.35 7.05 7.55 -6.9 7.55 -7.25 8.55 -9.3 14.35 -9.95 14.35 -15 14.35 -3.4 -18.05 Z"
          />
          <path
            fill="#FFCE05"
            stroke="none"
            d="M -7.25 8.55 L -7.9 8.55 -9.95 14.35 -9.3 14.35 -7.25 8.55 Z"
          />
          <path
            fill="#FFF570"
            stroke="none"
            d="M -7.25 8.55 L -6.9 7.55 7.05 7.55 9.45 14.35 13.9 14.35 2.25 -18.05 -3.4 -18.05 -15 14.35 -9.95 14.35 -7.9 8.55 -7.25 8.55 M -0.9 -11.45 L -0.55 -10.5 0.1 -12.45 5.5 3.1 4.15 3.1 4.5 4.1 -6.4 4.1 -0.9 -11.45 Z"
          />
          <path
            fill="url(#Gradient_11)"
            stroke="none"
            d="M 4.15 3.1 L 5.5 3.1 0.1 -12.45 -0.55 -10.5 -5.4 3.1 4.15 3.1 Z"
          />
          <path
            fill="#FFCD03"
            stroke="none"
            d="M -0.55 -10.5 L -0.9 -11.45 -6.4 4.1 4.5 4.1 4.15 3.1 -5.4 3.1 -0.55 -10.5 M -3.05 -19.05 L -3.4 -18.05 2.25 -18.05 13.9 14.35 15.25 14.35 3.25 -19.05 -3.05 -19.05 Z"
          />
        </g>
      </defs>

      <g id="shadow" transform="matrix( 1, 0, 0, 1, 250,742.25) ">
        <g transform="matrix( 1, 0, 0, 0.1714935302734375, 0.1,0.05) ">
          <g transform="matrix( 1, 0, 0, 1, 0,0) ">
            <use xlinkHref="#Symbol_1_0_Layer0_0_FILL" />
          </g>
        </g>
      </g>

      <g
        id="confetti1"
        transform="matrix( 0.258819580078125, 0.965911865234375, -0.965911865234375, 0.258819580078125, 179.6,389.35) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#confetti_0_Layer0_0_FILL" />
        </g>
      </g>

      <g id="confetti2" transform="matrix( 1, 0, 0, 1, 210.4,392.1) ">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#confetti_0_Layer0_0_FILL" />
        </g>
      </g>

      <g
        id="confetti3"
        transform="matrix( 0.86602783203125, 0.4999847412109375, -0.4999847412109375, 0.86602783203125, 192.1,376.55) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#confetti_0_Layer0_0_FILL" />
        </g>
      </g>

      <g
        id="confetti4"
        transform="matrix( 0.258819580078125, 0.965911865234375, -0.965911865234375, 0.258819580078125, 216.25,373.3) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#confetti_0_Layer0_0_FILL" />
        </g>
      </g>

      <g
        id="confetti5"
        transform="matrix( 0.86602783203125, -0.4999847412109375, 0.4999847412109375, 0.86602783203125, 207.45,416.7) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#confetti_0_Layer0_0_FILL" />
        </g>
      </g>

      <g
        id="confetti6"
        transform="matrix( 0.70709228515625, -0.70709228515625, 0.70709228515625, 0.70709228515625, 273.15,383.75) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#confetti_0_Layer0_0_FILL" />
        </g>
      </g>

      <g
        id="confetti7"
        transform="matrix( 0.5, -0.8660125732421875, 0.8660125732421875, 0.5, 335.9,408.9) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#confetti_0_Layer0_0_FILL" />
        </g>
      </g>

      <g
        id="confetti8"
        transform="matrix( 0.70709228515625, -0.70709228515625, 0.70709228515625, 0.70709228515625, 176.15,423.05) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#confetti_0_Layer0_0_FILL" />
        </g>
      </g>

      <g
        id="confetti9"
        transform="matrix( 0.258819580078125, -0.965911865234375, 0.965911865234375, 0.258819580078125, 317.85,429.15) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#confetti_0_Layer0_0_FILL" />
        </g>
      </g>

      <g id="confetti10" transform="matrix( 0, 1, -1, 0, 248.45,409.15) ">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#confetti_0_Layer0_0_FILL" />
        </g>
      </g>

      <g
        id="confetti11"
        transform="matrix( -0.9659271240234375, 0.2587890625, -0.2587890625, -0.9659271240234375, 236,421.95) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#confetti_0_Layer0_0_FILL" />
        </g>
      </g>

      <g
        id="star2"
        transform="matrix( 0.5, -0.8660125732421875, 0.8660125732421875, 0.5, 210.4,404.9) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#star_0_Layer0_0_FILL" />
        </g>
      </g>

      <g
        id="star3"
        transform="matrix( 0.7775726318359375, -0.6287841796875, 0.6287841796875, 0.7775726318359375, 266.1,374.05) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#star_0_Layer0_0_FILL" />
        </g>
      </g>

      <g
        id="star1"
        transform="matrix( 0.6024169921875, 0.79815673828125, -0.79815673828125, 0.6024169921875, 296,429.35) "
      >
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#star_0_Layer0_0_FILL" />
        </g>
      </g>

      <g id="base" transform="matrix( 1, 0, 0, 1, 250,747.2) ">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#base_0_Layer0_0_FILL" />
        </g>
      </g>

      <g id="handleL" transform="matrix( 1, 0, 0, 1, 147.35,429.3) ">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#handleL_0_Layer0_0_FILL" />
        </g>
      </g>

      <g id="medalBack" transform="matrix( 1, 0, 0, 1, 307.85,317.15) ">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#ribbonBack_0_Layer0_0_FILL" />
        </g>
      </g>

      <g id="cup" transform="matrix( 1, 0, 0, 1, 250,578.35) ">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#cup_0_Layer0_0_FILL" />
        </g>
      </g>

      <g id="handleR" transform="matrix( 1, 0, 0, 1, 348.4,430.85) ">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#handleR_0_Layer0_0_FILL" />
        </g>
      </g>

      <g id="medal" transform="matrix( 1, 0, 0, 1, 280.15,446.9) ">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#coin_0_Layer1_0_FILL" />
        </g>
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#coin_0_Layer0_0_FILL" />
        </g>
      </g>
    </svg>
  );
};

export default AnimatedTrophy;
