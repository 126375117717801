import React, { useEffect, useState, useRef } from "react";
import "./volumeControl.scss";

function VolumeControl({ className, volume, setVolume }) {
  const wave1Ref = useRef(null);
  const wave2Ref = useRef(null);
  const wave3Ref = useRef(null);
  const [mute, setMute] = useState(volume === 0);

  const toggleMute = () => {
    if (mute) {
      setVolume(50); // Restore to a default volume when unmuting
    } else {
      setVolume(0); // Mute by setting volume to 0
    }
    setMute(!mute); // Toggle the mute state
  };
  
  // Update the mute state whenever the volume changes
  useEffect(() => {
    setMute(volume === 0);
  }, [volume]);

  useEffect(() => {
    // Only try to access the style property if the elements exist
    if (wave1Ref.current && wave2Ref.current && wave3Ref.current) {
      const waveBorder = mute ? "none" : "2px solid #C79F27";
      wave1Ref.current.style.border = waveBorder;
      wave2Ref.current.style.border = volume > 35 ? waveBorder : "none";
      wave3Ref.current.style.border = volume > 70 ? waveBorder : "none";
    }
  }, [volume, mute]);

  return (
    <div className={`volume-wrapper ${className}`}>
      <div className="speaker-and-waves">
        <div className="speaker" onClick={toggleMute}>
          <div className="speaker_square"></div>
          <div className="speaker_triangle"></div>
        </div>
        {/* Conditionally render waves or mute symbol */}
        {!mute ? (
          <div className="waves">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        ) : (
          <h5 id="muteX">x</h5>
        )}
      </div>
      <div className="volume-control">
        <input
          className="volume-slider"
          type="range"
          min="0"
          max="100"
          value={volume}
          style={{
            accentColor: "rgb(199, 159, 39)",
            position: " relative",
          }}
          onChange={(e) => setVolume(parseInt(e.target.value, 10))}
        />
      </div>
    </div>
  );
}
export default VolumeControl;
